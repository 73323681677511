import {
  Chart,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

Chart.register(ArcElement, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend);

export const defaultPieChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: false
    },
    legend: {
      position: 'right' as const,
      labels: {
        padding: 10,
        color: 'black',
        usePointStyle: true,
        pointStyle: 'rectRounded',
        boxWidth: 10,
        boxHeight: 10
      }
    }
  },
  cutout: '50%'
};

export const defaultBarChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  indexAxis: 'y' as const,
  plugins: {
    title: {
      display: false
    },
    legend: {
      display: true,
      position: 'top' as const,
      labels: {
        color: 'black',
        usePointStyle: false,
        boxWidth: 15,
        boxHeight: 15
      }
    }
  },
  scales: {
    x: {
      type: 'linear' as const,
      stacked: true,
      beginAtZero: true,
      max: 100,
      ticks: {
        stepSize: 10,
        autoSkip: false,
        callback: (tickValue: string | number) => (Number(tickValue) === 100 ? '100%' : tickValue),
        color: 'black',
        padding: 10
      },
      grid: {
        display: true,
        drawOnChartArea: false,
        drawTicks: true,
        tickLength: 6,
        color: 'black'
      },
      border: {
        display: true,
        color: 'black',
        lineWidth: 2
      }
    },
    y: {
      type: 'category' as const,
      stacked: true,
      ticks: { color: 'black' },
      grid: { display: false },
      border: {
        display: true,
        color: 'black',
        lineWidth: 2
      }
    }
  }
};

export const defaultLineChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: false
    },
    legend: {
      display: true,
      position: 'top' as const,
      labels: {
        color: 'black',
        usePointStyle: false,
        boxWidth: 10,
        boxHeight: 10
      }
    }
  },
  elements: {
    point: {
      borderWidth: 2,
      radius: 4,
      hoverRadius: 6
    }
  },
  scales: {
    x: {
      ticks: {
        display: true,
        color: 'black'
      },
      grid: {
        display: true,
        drawOnChartArea: false,
        drawTicks: true,
        tickLength: 6,
        color: 'black'
      },
      border: {
        color: 'black',
        lineWidth: 1
      }
    },
    y: {
      beginAtZero: true,
      min: 0,
      max: 100,
      ticks: {
        color: 'black'
      },
      grid: {
        display: true,
        drawOnChartArea: false,
        drawTicks: true,
        tickLength: 6,
        color: 'black'
      },
      border: {
        color: 'black',
        lineWidth: 1
      }
    }
  }
};
