import { IStringDictionary } from 'shared/utils/LocalizationUtils';

const langStrings: IStringDictionary = {};

langStrings['Absent_Destinations'] = 'Destinations';
langStrings['Absent_Transfer'] = 'Absent Transfer';
langStrings['Error'] = 'Error:';
langStrings['AccountManagerName'] = 'Account Manager Name';
langStrings['AddBranchDialogTitle_AddNewBranch'] = 'Add New Branch';
langStrings['AddBranchDialogTitle_AddNewBranch'] = 'Add New Branch';
langStrings['Additional_AccessControl_Options_Dialog'] = 'Looking for additional access control options?';
langStrings['AdvancedSettings_AdminId_Desc'] = 'The Admin ID of this device.';
langStrings['AdvancedSettings_AdminId_Title'] = 'Admin ID';
langStrings['AdvancedSettings_AdminPass_Desc'] = 'The Admin Password of this device.';
langStrings['AdvancedSettings_AdminPass_Title'] = 'Admin Password';
langStrings['AdvancedSettings_AudioBufferMaximumPackets_Desc'] = 'Audio Buffer Maximum Packets';
langStrings['AdvancedSettings_AudioBufferMaximumPackets_Title'] = 'Audio Buffer Maximum Packets';
langStrings['AdvancedSettings_AudioBufferStartingPackets_Desc'] = 'Audio Buffer Starting Packets';
langStrings['AdvancedSettings_AudioBufferStartingPackets_Title'] = 'Audio Buffer Starting Packets';
langStrings['AdvancedSettings_AudioEncoder_Desc'] = 'The audio encoder of this device.';
langStrings['AdvancedSettings_AudioEncoder_Title'] = 'Audio Encoder';
langStrings['AdvancedSettings_AudioTosValue_Desc'] = 'Audio TOS Value';
langStrings['AdvancedSettings_AudioTosValue_Title'] = 'Audio TOS Value';
langStrings['AdvancedSettings_ButtonFeedback_Desc'] = 'Button Feedback Tone';
langStrings['AdvancedSettings_ButtonFeedback_Title'] = 'Button Feedback Tone';
langStrings['AdvancedSettings_DaylightSavings_Desc'] = 'Toggle to enable or disable automatic daylight savings.';
langStrings['AdvancedSettings_DaylightSavings_Title'] = 'Daylight Savings';
langStrings['AdvancedSettings_Dialog'] = 'Go to Advanced Setting to access more settings for the station';
langStrings['AdvancedSettings_Error_UpdateDevice'] = 'Error updating device details';
langStrings['AdvancedSettings_ExternalMicVolume_Desc'] = 'External Microphone Volume';
langStrings['AdvancedSettings_ExternalMicVolume_Title'] = 'External Microphone Volume';
langStrings['AdvancedSettings_ExternalSpeakerVolume_Desc'] = 'External Speaker Volume';
langStrings['AdvancedSettings_ExternalSpeakerVolume_Title'] = 'External Speaker Volume';
langStrings['AdvancedSettings_HostName_Desc'] = 'The Host Name of this device.';
langStrings['AdvancedSettings_HostName_Title'] = 'Host Name';
langStrings['AdvancedSettings_IpAddressMethod_Desc'] = 'The IP Address Method of this device.';
langStrings['AdvancedSettings_IpAddressMethod_Title'] = 'IP Address Method';
langStrings['AdvancedSettings_InternetConnection_Title'] = 'Internet Connection';
langStrings['AdvancedSettings_InternetConnection_Desc'] = 'The Internet Connection of this device.';
langStrings['AdvancedSettings_IPPhoneVolumeAdjustment_Desc'] = 'VoIP Phone Volume Adjustment';
langStrings['AdvancedSettings_IPPhoneVolumeAdjustment_Title'] = 'VoIP Phone Volume Adjustment';
langStrings['AdvancedSettings_IpV4Address_Desc'] = 'The IPV4 Address of this device.';
langStrings['AdvancedSettings_IpV4Address_Title'] = 'IPV4 Address';
langStrings['AdvancedSettings_IpV4DefaultGateway_Desc'] = 'The IPV4 Default Gateway of this device.';
langStrings['AdvancedSettings_IpV4DefaultGateway_Title'] = 'IPV4 Default Gateway';
langStrings['AdvancedSettings_IpV4MulticastAddress_Desc'] = 'The IPV4 Multicast Address of this device.';
langStrings['AdvancedSettings_IpV4MulticastAddress_Title'] = 'IPV4 Multicast Address';
langStrings['AdvancedSettings_IpV4PrimaryDns_Desc'] = 'The IPV4 Primary DNS of this device.';
langStrings['AdvancedSettings_IpV4PrimaryDns_Title'] = 'IPV4 Primary DNS';
langStrings['AdvancedSettings_IpV4SecondaryDns_Desc'] = 'The IPV4 Secondary DNS of this device.';
langStrings['AdvancedSettings_IpV4SecondaryDns_Title'] = 'IPV4 Secondary DNS';
langStrings['AdvancedSettings_IpV6Address_Desc'] = 'The IPV6 Address of this device.';
langStrings['AdvancedSettings_IpV6Address_Title'] = 'IPV6 Address';
langStrings['AdvancedSettings_IpV6DefaultGateway_Desc'] = 'The IPV6 Default Gateway of this device.';
langStrings['AdvancedSettings_IpV6DefaultGateway_Title'] = 'IPV6 Default Gateway';
langStrings['AdvancedSettings_IpV6MulticastAddress_Desc'] = 'The IPV6 Multicast Address of this device.';
langStrings['AdvancedSettings_IpV6MulticastAddress_Title'] = 'IPV6 Multicast Address';
langStrings['AdvancedSettings_IpV6PrimaryDns_Desc'] = 'The IPV6 Primary DNS of this device.';
langStrings['AdvancedSettings_IpV6PrimaryDns_Title'] = 'IPV6 Primary DNS';
langStrings['AdvancedSettings_IpV6SecondaryDns_Desc'] = 'The IPV6 Secondary DNS of this device.';
langStrings['AdvancedSettings_IpV6SecondaryDns_Title'] = 'IPV6 Secondary DNS';
langStrings['AdvancedSettings_IpVersion_Desc'] = 'The IP Version of this device.';
langStrings['AdvancedSettings_IpVersion_Title'] = 'IP Version';
langStrings['AdvancedSettings_MacAddress_Desc'] = 'The MAC address of this device.';
langStrings['AdvancedSettings_MacAddress_Error_Invalid'] = 'Invalid MAC Address.';
langStrings['AdvancedSettings_MacAddress_Title'] = 'MAC Address';
langStrings['AdvancedSettings_MasterStationVideoBitrate_Desc'] = 'Master Station Video Bitrate Description';
langStrings['AdvancedSettings_MasterStationVideoBitrate_Title'] = 'Master Station Video Bitrate';
langStrings['AdvancedSettings_MasterStationVideoFramerate_Desc'] = 'Master Station Video Framerate Description';
langStrings['AdvancedSettings_MasterStationVideoFramerate_Title'] = 'Master Station Video Framerate';
langStrings['AdvancedSettings_MasterStationVideoIPictureInterval_Desc'] = 'Master Station Video I Picture Interval[H.264/AVC] Description';
langStrings['AdvancedSettings_MasterStationVideoIPictureInterval_Title'] = 'Master Station Video I Picture Interval[H.264/AVC]';
langStrings['AdvancedSettings_NtpPrimaryServerIpV4Address_Desc'] = 'NTP Primary Server IPv4 Address';
langStrings['AdvancedSettings_NtpPrimaryServerIpV4Address_Title'] = 'NTP Primary Server IPv4 Address';
langStrings['AdvancedSettings_NtpPrimaryServerIpV6Address_Desc'] = 'NTP Primary Server IPv6 Address';
langStrings['AdvancedSettings_NtpPrimaryServerIpV6Address_Title'] = 'NTP Primary Server IPv6 Address';
langStrings['AdvancedSettings_NtpPrimaryServerPort_Desc'] = 'NTP Primary Server Port';
langStrings['AdvancedSettings_NtpPrimaryServerPort_Title'] = 'NTP Primary Server Port';
langStrings['AdvancedSettings_NtpSecondaryServerIpV4Address_Desc'] = 'NTP Secondary Server IPv4 Address';
langStrings['AdvancedSettings_NtpSecondaryServerIpV4Address_Title'] = 'NTP Secondary Server IPv4 Address';
langStrings['AdvancedSettings_NtpSecondaryServerIpV6Address_Desc'] = 'NTP Secondary Server IPv6 Address';
langStrings['AdvancedSettings_NtpSecondaryServerIpV6Address_Title'] = 'NTP Secondary Server IPv6 Address';
langStrings['AdvancedSettings_NtpSecondaryServerPort_Desc'] = 'NTP Secondary Server Port';
langStrings['AdvancedSettings_NtpSecondaryServerPort_Title'] = 'NTP Secondary Server Port';
langStrings['AdvancedSettings_NtpSettings_Desc'] = 'NTP Settings';
langStrings['AdvancedSettings_NtpSettings_Title'] = 'NTP Settings';
langStrings['AdvancedSettings_NtpSyncInterval_Desc'] = 'NTP Sync Interval';
langStrings['AdvancedSettings_NtpSyncInterval_Title'] = 'NTP Sync Interval';
langStrings['AdvancedSettings_OnvifId_Desc'] = 'The Onvif ID of this device.';
langStrings['AdvancedSettings_OnvifId_Title'] = 'Onvif ID';
langStrings['AdvancedSettings_OnvifPass_Desc'] = 'The Onvif Password of this device.';
langStrings['AdvancedSettings_OnvifPass_Title'] = 'Onvif Password';
langStrings['AdvancedSettings_PagingRingtoneVolume_Desc'] = 'Incoming Paging Ringtone Volume';
langStrings['AdvancedSettings_PagingRingtoneVolume_Title'] = 'Incoming Paging Ringtone Volume';
langStrings['AdvancedSettings_ReceiveVolumeHandset_Desc'] = 'Receive and Ringback Tone Volume (Handset)';
langStrings['AdvancedSettings_ReceiveVolumeHandset_Title'] = 'Receive and Ringback Tone Volume (Handset)';
langStrings['AdvancedSettings_ReceiveVolumeSpeaker_Desc'] = 'Receive and Ringback Tone Volume (Speaker)';
langStrings['AdvancedSettings_ReceiveVolumeSpeaker_2C7_Desc'] = 'Receive and Ringback Tone Volume';
langStrings['AdvancedSettings_ReceiveVolumeSpeaker_Title'] = 'Receive and Ringback Tone Volume (Speaker)';
langStrings['AdvancedSettings_ReceiveVolumeSpeaker_2C7_Title'] = 'Receive and Ringback Tone Volume';
langStrings['AdvancedSettings_RingtoneVolume_Desc'] = 'Incoming Call Ringtone Volume';
langStrings['AdvancedSettings_RingtoneVolume_Title'] = 'Incoming Call Ringtone Volume';
langStrings['AdvancedSettings_RtspId_Desc'] = 'The Rtsp ID of this device.';
langStrings['AdvancedSettings_RtspId_Title'] = 'Rtsp ID';
langStrings['AdvancedSettings_RtspPass_Desc'] = 'The Rtsp Password of this device.';
langStrings['AdvancedSettings_RtspPass_Title'] = 'Rtsp Password';
langStrings['AdvancedSettings_SipTosValue_Desc'] = 'SIP TOS Value';
langStrings['AdvancedSettings_SipTosValue_Title'] = 'SIP TOS Value';
langStrings['AdvancedSettings_StationLocation_Desc'] = 'The location of this device.';
langStrings['AdvancedSettings_StationLocation_Title'] = 'Station Location';
langStrings['AdvancedSettings_StationName_Desc'] = 'The station name will be shown to the recipient when called, displayed in address books, and used when searching for stations by name.';
langStrings['AdvancedSettings_StationName_Title'] = 'Station Name';
langStrings['AdvancedSettings_StationNumber_Desc'] = 'A unique number used to identify this device in your system.';
langStrings['AdvancedSettings_StationNumber_Title'] = 'Station Number';
langStrings['AdvancedSettings_SubnetMask_Desc'] = 'The Subnet Mask of this device.';
langStrings['AdvancedSettings_SubnetMask_Title'] = 'Subnet Mask';
langStrings['AdvancedSettings_Success_UpdateDevice'] = 'The Device Details were successfully updated';
langStrings['AdvancedSettings_SystemExtendMode_Desc'] = 'Toggle to enable or disable system extend mode.';
langStrings['AdvancedSettings_SystemExtendMode_Title'] = 'System Extend Mode';
langStrings['AdvancedSettings_Tab_Audio'] = 'Audio';
langStrings['AdvancedSettings_Tab_StationIdentification'] = 'Station Identification';
langStrings['AdvancedSettings_Tab_PhoneSettings'] = 'Phone Settings';
langStrings['AdvancedSettings_Tab_Tooltip_IpAddress'] = 'IP Address, DNS';
langStrings['AdvancedSettings_Tab_Video'] = 'Video';
langStrings['AdvancedSettings_Tab_Volume'] = 'Volume';
langStrings['AdvancedSettings_Contact_Output_Unlock_Key_Desc'] = 'The unlock key for the contact output.';
langStrings['AdvancedSettings_Contact_Output_1_Unlock_Key_Title'] = 'Contact Output 1 Code';
langStrings['AdvancedSettings_Contact_Output_2_Unlock_Key_Title'] = 'Contact Output 2 Code';
langStrings['AdvancedSettings_TransmitVolumeHandset_Desc'] = 'Microphone Volume (Handset)';
langStrings['AdvancedSettings_TransmitVolumeHandset_Title'] = 'Microphone Volume (Handset)';
langStrings['AdvancedSettings_TransmitVolumeSpeaker_Desc'] = 'Microphone Volume (Speaker)';
langStrings['AdvancedSettings_TransmitVolumeSpeaker_Desc'] = 'Microphone Volume (Speaker)';
langStrings['AdvancedSettings_TransmitVolumeSpeaker_2C7_Desc'] = 'Microphone Volume';
langStrings['AdvancedSettings_TransmitVolumeSpeaker_Title'] = 'Microphone Volume (Speaker)';
langStrings['AdvancedSettings_TransmitVolumeSpeaker_2C7_Title'] = 'Microphone Volume';
langStrings['AdvancedSettings_UserId_Desc'] = 'The User ID of this device.';
langStrings['AdvancedSettings_UserId_Title'] = 'User ID';
langStrings['AdvancedSettings_UserPass_Desc'] = 'The User Password of this device.';
langStrings['AdvancedSettings_UserPass_Title'] = 'User Password';
langStrings['AdvancedSettings_VideoDualStream_Desc'] = 'Toggle to enable or disable video dual stream.';
langStrings['AdvancedSettings_VideoDualStream_Title'] = 'Video Dual Stream';
langStrings['AdvancedSettings_VideoEncoder1Bitrate_Desc'] = 'Video Encoder 1 Bitrate Description';
langStrings['AdvancedSettings_VideoEncoder1Bitrate_Title'] = 'Video Encoder 1 Bitrate';
langStrings['AdvancedSettings_VideoEncoder1Framerate_Desc'] = 'Video Encoder 1 Framerate';
langStrings['AdvancedSettings_VideoEncoder1Framerate_Title'] = 'Video Encoder 1 Framerate Description';
langStrings['AdvancedSettings_VideoEncoder1IPictureInterval_Desc'] = 'Video Encoder 1 I Picture Interval[H.264/AVC] Description';
langStrings['AdvancedSettings_VideoEncoder1IPictureInterval_Title'] = 'Video Encoder 1 I Picture Interval[H.264/AVC]';
langStrings['AdvancedSettings_VideoEncoder2Bitrate_Desc'] = 'Video Encoder 2 Bitrate Description';
langStrings['AdvancedSettings_VideoEncoder2Bitrate_Desc'] = 'Video Encoder 2 Bitrate Description';
langStrings['AdvancedSettings_VideoEncoder2Bitrate_Title'] = 'Video Encoder 2 Bitrate';
langStrings['AdvancedSettings_VideoEncoder2Bitrate_Title'] = 'Video Encoder 2 Bitrate';
langStrings['AdvancedSettings_VideoEncoder2Framerate_Desc'] = 'Video Encoder 2 Framerate Description';
langStrings['AdvancedSettings_VideoEncoder2Framerate_Title'] = 'Video Encoder 2 Framerate';
langStrings['AdvancedSettings_VideoEncoder2H264Quality_Desc'] = 'Video Encoder 2 H.264 Quality Description';
langStrings['AdvancedSettings_VideoEncoder2H264Quality_Title'] = 'Video Encoder 2 H.264 Quality';
langStrings['AdvancedSettings_VideoEncoder2IPictureInterval_Desc'] = 'Video Encoder 2 I Picture Interval[H.264/AVC] Description';
langStrings['AdvancedSettings_VideoEncoder2IPictureInterval_Title'] = 'Video Encoder 2 I Picture Interval[H.264/AVC]';
langStrings['AdvancedSettings_VideoEncoder2JpegQuality_Desc'] = 'Video Encoder 2 JPEG Quality Description';
langStrings['AdvancedSettings_VideoEncoder2JpegQuality_Title'] = 'Video Encoder 2 JPEG Quality';
langStrings['AdvancedSettings_VideoEncoder2Method_Desc'] = 'Video Encoder 2 Method Description';
langStrings['AdvancedSettings_VideoEncoder2Method_Title'] = 'Video Encoder 2 Method';
langStrings['AdvancedSettings_VideoEncoder2Resolution_Desc'] = 'Video Encoder 2 Resolution Description';
langStrings['AdvancedSettings_VideoEncoder2Resolution_Title'] = 'Video Encoder 2 Resolution';
langStrings['AdvancedSettings_VideoEncoder2RtpEndPort_Desc'] = 'Video Encoder 2 RTP End Port Description';
langStrings['AdvancedSettings_VideoEncoder2RtpEndPort_Title'] = 'Video Encoder 2 RTP End Port';
langStrings['AdvancedSettings_VideoEncoder2RtpStartPort_Desc'] = 'Video Encoder 2 RTP Start Port Description';
langStrings['AdvancedSettings_VideoEncoder2RtpStartPort_Title'] = 'Video Encoder 2 RTP Start Port';
langStrings['AdvancedSettings_VideoTosValue_Desc'] = 'Video TOS Value';
langStrings['AdvancedSettings_VideoTosValue_Title'] = 'Video TOS Value';
langStrings['AdvancedSettings_VlanId_Desc'] = 'VLAN ID';
langStrings['AdvancedSettings_VlanId_Title'] = 'VLAN ID';
langStrings['AdvancedSettings_VlanPriority_Desc'] = 'VLAN Priority';
langStrings['AdvancedSettings_VlanPriority_Title'] = 'VLAN Priority';
langStrings['AdvancedSettings_VlanSettings_Desc'] = 'Enable VLAN Settings';
langStrings['AdvancedSettings_VlanSettings_Title'] = 'VLAN Settings';
langStrings['Aiphone_Wire'] = 'Aiphone Wire';
langStrings['All_Page'] = 'All Page';
langStrings['Branch_Name'] = 'Branch Name';
langStrings['BranchInformation_Dialog'] = 'Branch Information';
langStrings['BranchName'] = 'Branch Name';
langStrings['Building_Page'] = 'Building Page';
langStrings['Button_Add_Ac_Series_To_Gt_Spec'] = 'Click here to add the AC Series to your GT Specification';
langStrings['Button_Add_Ac_Series_To_Ix_Spec'] = 'Click here to add the AC Series to your IX | IXG Specification';
langStrings['Button_Add_Ac_Series_To_Jp_Spec'] = 'Click here to add the AC Series to your JP Specification';
langStrings['Button_AddNewBranch'] = 'Add New Branch';
langStrings['Button_BranchToolBar_AddBranch'] = 'Add Branch';
langStrings['Button_Close'] = 'Close';
langStrings['Button_CloudAccount_Register'] = 'Register';
langStrings['Button_CompaniesToolBar_AddCompany'] = 'Add Company';
langStrings['Button_CompaniesToolBar_DeleteCompany'] = 'Delete Company';
langStrings['Button_Compare_Licenses'] = 'Compare Licenses'; 
langStrings['Button_Confirm'] = 'Confirm';
langStrings['Button_CookiePolicy'] = 'Cookie Policy';
langStrings['Button_CreateCompany'] = 'Create Company';
langStrings['Button_Delete_Device'] = 'Delete Device';
langStrings['Button_ForgotPassword'] = 'Forgot your password?';
langStrings['Button_Import'] = 'Import';
langStrings['Button_LogIn'] = 'Log In';
langStrings['Button_NeedAnAccount'] = 'Need an account?';
langStrings['Button_PrivacyPolicy'] = 'Privacy Policy';
langStrings['Button_RegisterHere'] = 'Register here.';
langStrings['Button_Resend'] = 'Resend';
langStrings['Button_Reset'] = 'Reset';
langStrings['Button_Return_To_System_Page'] = 'Click here to return to the {0} System page';
langStrings['Button_Save'] = 'Save';
langStrings['Button_SkipForNow'] = 'Skip For Now';
langStrings['Button_Submit'] = 'Submit';
langStrings['Button_TermsConditions'] = 'Terms & Conditions';
langStrings['Button_Update_Company'] = 'Update Company';
langStrings['Button_VerifyAccount'] = 'Verify Account';
langStrings['Button_VerifyAccount'] = 'Verify Account';
langStrings['C2CompanyID_Dialog'] = 'Does the branch have a C2 Company ID? If so, please enter it below to search for the branch and import the data:';
langStrings['Call_Destination'] = 'Call Destination';
langStrings['CGI_Label'] = 'CGI';
langStrings['Clicking_RegisterDescription'] = "By clicking the Register button, you agree to Aiphone Corporation's";
langStrings['CloudRegistration_AlreadyHaveAnAccount'] = 'Already have an account?';
langStrings['CloudRegistration_SignIn'] = 'Sign In';
langStrings['Company_Name'] = 'Company Name';
langStrings['CompanyInformation'] = 'Company Information';
langStrings['Confirmation_Screen_Dialog'] = 'Please review the information below and click "Create Site" to complete the process.';
langStrings['CreateSite_NextButton'] = 'Next';
langStrings['CSR_Dialog'] = 'We will update Aiphone Cloud for CSR soon!';
langStrings['CSR_Label'] = 'CSR';
langStrings['CustomerNumber'] = 'Customer Number';
langStrings['Delay_Destinations'] = 'Destinations';
langStrings['Delay_Transfer'] = 'Delay Transfer';
langStrings['Description'] = 'Description';
langStrings['DeviceCard_ModelNumber'] = 'Model Number:';
langStrings['DeviceCard_StationName'] = 'Station Name:';
langStrings['DeviceCard_StationNumber'] = 'Station Number:';
langStrings['DidNot_Receive_Code'] = "Didn't receive the code?";
langStrings['Digit'] = 'digit';
langStrings['Display_Mode'] = 'Display Mode';
langStrings['Edit_Advanced_Settings'] = 'Edit Advanced Settings';
langStrings['EmailVerification_Body'] = 'A 6-digit code has been sent to your registered email address for verification.';
langStrings['Enter_CompanyDetails'] = 'Enter the company details below';
langStrings['Error_Description'] = 'There was an error creating the site. Please try again';
langStrings['Error_Loading_Device'] = 'Error Loading Device';
langStrings['Essential_License_Description'] = 'The Essential license can manage a system of up to 40 doors and cameras. It can be installed on a local server PC or on the AC-HOST embedded server.'; 
langStrings['Export_As_PDF'] = 'Export as PDF';
langStrings['Field.Error.MinLen'] = '{0} must be at least {1} characters.';
langStrings['Firmware_Update'] = 'Firmware Update';
langStrings['ID_Password_Label'] = 'ID & Password';
langStrings['Import_Branch'] = 'Import';
langStrings['ImportBranchData_Title'] = 'Import Branch Data';
langStrings['Include_Ac_Host'] = 'Would you like to include the AC-HOST embedded server?'; 
langStrings['Incoming_Call'] = 'Incoming Call';
langStrings['Initialization'] = 'Initialization';
langStrings['Input_Function'] = 'Input Function';
langStrings['Input_Polarity'] = 'Input Polarity';
langStrings['IX|IXG_Additional_AccessControl_Options_Dialog'] = 'Looking for additional access control options?';
langStrings['IXG_Support_Tool'] = 'IXG Support Tool';
langStrings['Job_Name'] = 'Job Name';
langStrings['Location'] = 'Location';
langStrings['LowerCase'] = 'lowercase';
langStrings['Market_Type'] = 'Market Type';
langStrings['Miliseconds_Text'] = 'ms';
langStrings['Model'] = 'Model';
langStrings['Need_To_Go_Back_Dialog'] = 'Need to go back?';
langStrings['Network_Camera_Integration'] = 'Network Camera Integration';
langStrings['New'] = 'New';
langStrings['No_Devices_Text'] = 'No devices';
langStrings['No_Intercoms_Added'] = 'No intercoms have been added to this category.';
langStrings['No_Relay_Selected'] = 'No relay selected';
langStrings['No_Relays'] = 'No relays';
langStrings['No_Selected_Devices'] = 'No selected devices.';
langStrings['Notes'] = 'Notes';
langStrings['Option_Input_Page'] = 'Option Input Page';
langStrings['Outbound_Call_Settings'] = 'Outbound Call Settings';
langStrings['Output_Function'] = 'Output Function';
langStrings['Password_Error_Confirm_Match'] = 'Passwords must match.';
langStrings['Password_Error_MissingItem'] = 'Password must contain at least one {0}.';
langStrings['PhysicalAddress_2'] = 'Physical Address 2';
langStrings['PhysicalAddress'] = 'Physical Address';
langStrings['Please_Select_Device'] = 'Please select device';
langStrings['PrivacyPolicy'] = 'Privacy Policy';
langStrings['Quantity'] = 'Qty';
langStrings['Recording'] = 'Recording';
langStrings['Relay_Name'] = 'Relay Name';
langStrings['Relay_Number'] = 'Relay Number';
langStrings['Relay_Output'] = 'Relay Output';
langStrings['Seconds_Text'] = 's';
langStrings['Select_Ringback_Tone'] = 'Select ringback tone';
langStrings['Select_Ringtone'] = 'Select ringtone';
langStrings['SelectCompany_Dialog'] = 'Select the Company to add a Branch to:';
langStrings['SIF'] = 'SIF';
langStrings['SIP_Settings'] = 'SIP Settings';
langStrings['SiteInfo_SiteZip_Title'] = 'Zip';
langStrings['SiteSettings_Text'] = 'Site Settings';
langStrings['Small_Business_License_Description'] = 'The Small Business license can manage up to 80 doors and cameras, as well as elevators and unmanaged doors.This license allows up to five partitions and also supports Lightweight Directory Access Protocol(LDAP) integration.'; 
langStrings['Status'] = 'Status';
langStrings['Status'] = 'Status';
langStrings['Syslog_Settings'] = 'Syslog Settings';
langStrings['Table_Header_Cameras_40'] = '40'; 
langStrings['Table_Header_Cameras_80'] = '80'; 
langStrings['Table_Header_Cameras'] = 'Cameras'; 
langStrings['Table_Header_Doors_40'] = '40'; 
langStrings['Table_Header_Doors_80'] = '80'; 
langStrings['Table_Header_Doors'] = 'Doors'; 
langStrings['Table_Header_Elevator_No'] = 'No'; 
langStrings['Table_Header_Elevator_Yes'] = 'Yes'; 
langStrings['Table_Header_Elevator'] = 'Elevator'; 
langStrings['Table_Header_Essential'] = 'Essential'; 
langStrings['Table_Header_Features'] = 'Features'; 
langStrings['Table_Header_LDAP_No'] = 'No'; 
langStrings['Table_Header_LDAP_Yes'] = 'Yes'; 
langStrings['Table_Header_LDAP'] = 'LDAP'; 
langStrings['Table_Header_Partitions_1'] = '1'; 
langStrings['Table_Header_Partitions_5'] = '5'; 
langStrings['Table_Header_Partitions'] = 'Partitions'; 
langStrings['Table_Header_SmallBusiness'] = 'Small Business'; 
langStrings['Table_Header_UnmanagedDoors_No'] = 'No'; 
langStrings['Table_Header_UnmanagedDoors_Yes'] = 'Yes'; 
langStrings['Table_Header_UnmanagedDoors'] = 'Unmanaged Doors'; 
langStrings['TempPassword_Verified'] = 'Temporary password has been verified! Finish your account setup using the form below.';
langStrings['Terms_And_Conditions'] = 'Terms & Conditions';
langStrings['Terms_And_Conditions'] = 'Terms & Conditions';
langStrings['Time'] = 'Time';
langStrings['Timeout_Dialog'] = 'Timeout';
langStrings['Timeout_Seconds'] = 'seconds';
langStrings['Title_Absent_Transfer'] = 'Absent Transfer';
langStrings['Title_Confirmation_Screen'] = 'Confirmation Screen';
langStrings['Title_Delay_Transfer'] = 'Delay Transfer';
langStrings['Title_Edit_Company_/_Branch'] = 'Edit Company';
langStrings['Title_EditBranch'] = 'Edit Branch';
langStrings['Title_EmailVerification'] = 'Email Verification';
langStrings['Title_Essential_License'] = 'Essential License'; 
langStrings['Title_General_System_Information'] = 'General System Information';
langStrings['Title_Quikspec_Summary'] = 'QuikSpec Summary';
langStrings['Title_Selected_Spec'] = 'Selected Spec';
langStrings['Title_SmallBusiness_License'] = 'Small Business License'; 
langStrings['Update_Success'] = '{0} has been successfully updated.';
langStrings['Wizard_Mobile_Apps_Title'] = 'Mobile Apps';
langStrings['Wizard_Mobile_Apps_Desc'] = 'Enter the number of mobile apps to add to the site (up to 8)';
langStrings['WireInfo_Capacitance'] = 'Capacitance/Loop Res.';
langStrings['WireInfo_Description'] = 'Description';
langStrings['WireInfo_Jacket'] = 'Jacket/Nom. O.D.';
langStrings['WireInfo_Max_Distance'] = 'Max. Distance';
langStrings['WireInfo_System'] = 'System';
langStrings['WireInfo_Wire#'] = 'Wire #';
langStrings['Work_In_Progress'] = 'Work in Progress';

export default langStrings;
