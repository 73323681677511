import React from 'react';
import InfoScreen from '../../../layouts/components/InfoScreen';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/utils/StringUtils';

export const PwdSuccessfullyChanged: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const loginStr = t('Shared.Login');
  const returnToItemStr = t('Shared.ReturnToItem');
  const returnToLoginStr = StringUtils.format(returnToItemStr, loginStr);
  const passwordChangedSuccess = t('Field.Shared.Password.ChangeSuccess');

  return (
    <InfoScreen
      title={passwordChangedSuccess}
      message={passwordChangedSuccess}
      iconComponent={<CheckCircleRoundedIcon sx={{ fontSize: '48px', color: 'success.main' }} />}
      button={<Button onClick={() => navigate('/auth/login')}>{returnToLoginStr}</Button>}
    />
  );
};
