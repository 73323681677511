import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Autocomplete,
  Typography,
  InputAdornment,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { Field, Form, Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { CA, US } from 'country-flag-icons/react/3x2';
import { useCreateBranchMutation, useGetCompaniesQuery, useLazyGetBranchWithC2IdQuery } from 'services/aiphoneCloud';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { EnumList, ICountryValue, IStateValue, fetchEnumList } from 'shared/utils/EnumUtils';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/utils/StringUtils';
import getStateList from 'shared/utils/GetStateList';
import { BranchRecord } from '../Datagrids/BranchesDataGrid';
import { NANP_PHONE_REGEX } from 'shared/constants/regex';
import PhoneField from 'shared/components/forms/fields/PhoneField';
import ZipPostalCodeField from 'shared/components/forms/fields/ZipPostalCodeField';
import { convertPhoneNumberToE164Format, formatPhoneNumber } from 'shared/utils/helperFunctions';
import { RegexPostalCode, RegexZipCode } from 'features/RemoteManagement/types/Types';
import isUsaOrCanada from 'shared/utils/IsUsaOrCanada';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setErrorMessage: (errorMessage: string) => void;
  setIsError: (isError: boolean) => void;
  setSuccessMessage: (successMessage: string) => void;
  setIsSuccess: (isSuccess: boolean) => void;
  setBranchList: React.Dispatch<React.SetStateAction<{ [key: string]: BranchRecord }>>;
}

type GetCompaniesResponse = {
  companyList: { [key: string]: CompanyEntry };
  perPage: number;
  pageNumber: number;
  totalPages: number;
  totalCompanies: number;
};

interface CompanyEntry {
  name: string;
  publicId: string;
  dealerCompanyPublicId: string;
  stateId: number;
  postalCode: string;
  companyTypeId: number;
  createdOn: string;
  lastUpdatedOn: string;
}

type C2BranchData = {
  accountmanagername: string;
  annualsales: string;
  businesscategories: string;
  companyname: string;
  companyphone: string;
  customernumber: string;
  discountlvl: string;
  financialcondition: string;
  hasprofilepic: number;
  id: string;
  industrycategory: string;
  lasttaskcategory: string;
  lasttaskdate: string;
  lasttaskpurpose: string;
  lasttaskstatus: string;
  location: string;
  marketingcategories: string;
  pacity: string;
  papostalcode: string;
  parentcompany: string;
  pastate: string;
  physicaladdress: string;
  physicaladdress2: string;
  salesnum: string;
  status: string;
};

const AddBranchDialog = ({
  isOpen,
  setIsOpen,
  setErrorMessage,
  setIsError,
  setSuccessMessage,
  setIsSuccess,
  setBranchList
}: Props) => {
  const [createBranch, { isLoading: isBranchLoading }] = useCreateBranchMutation();
  const { data: companies } = useGetCompaniesQuery({
    qty: '1000',
    page: '0'
  }) as { data: GetCompaniesResponse };

  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isCompanySelected, setIsCompanySelected] = useState<boolean>(false);
  const [c2Input, setC2Input] = useState<boolean>(false);
  const [companyPublicId, setCompanyPublicId] = useState('');
  const [searchBranch] = useLazyGetBranchWithC2IdQuery();
  const [isC2BranchDialogOpen, setIsC2BranchDialogOpen] = useState<boolean>(false);
  const [c2BranchData, setC2BranchData] = useState<C2BranchData | null>(null);
  const companyList = companies?.companyList || {};
  const [enumList, setEnumList] = useState<EnumList>({ country: {}, state: {} });
  const canadaCountryId = Object.keys(enumList.country).find((key) => {
    return (enumList.country[key] as ICountryValue).alpha2Code === 'CA';
  });
  const { t } = useTranslation();
  const cancelStr = t('Shared.Cancel');
  const selectAItemStr = t('Shared.SelectAItem');
  const countryStr = t('Shared.Country');
  const stateStr = t('Shared.State');
  const provinceStr = t('Shared.Province');
  const zipCodeStr = t('Shared.ZipCode');
  const postalCodeStr = t('Shared.PostalCode');
  const phoneNumberStr = t('Shared.PhoneNumber');
  const addressStr = t('Shared.Address');
  const cityStr = t('Shared.City');
  const zipOrPostalCodeStr = t('Shared.ZipOrPostalCode');
  const stateOrProvinceStr = t('Shared.StateOrProvince');
  const phoneNumberRequiredStr = t('Field.Error.Required', { field: phoneNumberStr });
  const selectACountryStr = StringUtils.format(selectAItemStr, countryStr);
  const selectAStateStr = StringUtils.format(selectAItemStr, stateStr);
  const selectAProvinceStr = StringUtils.format(selectAItemStr, provinceStr);
  const branchNotFoundStr = t('Branch_Not_Found');
  const zipCodeRequiredStr = t('Field.Error.Required', { field: zipCodeStr });
  const zipCodeInvalidStr = t('Field.Error.Invalid', { field: zipCodeStr });
  const postalCodeRequiredStr = t('Field.Error.Required', { field: postalCodeStr });
  const postalCodeInvalidStr = t('Field.Error.Invalid', { field: postalCodeStr });
  const minLengthCharsStr = t('Field.Error.MinLen');
  const maxLengthCharsStr = t('Field.Error.MaxLen');
  const zipCodeMinLenStr = StringUtils.format(minLengthCharsStr, zipCodeStr, '5');
  const zipCodeMaxLenStr = StringUtils.format(maxLengthCharsStr, zipCodeStr, '5');
  const postalCodeMinLenStr = StringUtils.format(minLengthCharsStr, postalCodeStr, '6');
  const postalCodeMaxLenStr = StringUtils.format(maxLengthCharsStr, postalCodeStr, '7');
  const branchNameStr = t('Branch.Name');
  const branchNameIsRequiredStr = t('Field.Error.Required', { field: branchNameStr });
  const branchNameMaxLenStr = StringUtils.format(maxLengthCharsStr, branchNameStr, '100');
  const cityMaxLenStr = StringUtils.format(maxLengthCharsStr, cityStr, '100');

  useEffect(() => {
    fetchEnumList().then((data) => {
      setEnumList(data);
    });
  }, []);

  const initialForm = {
    companyName: '',
    c2CompanyId: '',
    branchName: '',
    address: '',
    address2: '',
    countryId: '',
    stateId: '',
    city: '',
    postalCode: '',
    phoneNumber: ''
  };

  const handleCreateBranch = async (values: any) => {
    const branchRequest = {
      branchData: {
        companyPublicId: companyPublicId,
        address: values.address,
        address2: values.address2,
        address3: null,
        city: values.city,
        branchName: values.branchName.length > 0 ? values.branchName : '　',
        c2CompanyId: values.c2CompanyId || null,
        aiphoneCloudAccountNumber: null,
        phoneNumber: convertPhoneNumberToE164Format(values.phoneNumber),
        stateId: parseInt(values.stateId),
        postalCode: values.postalCode,
        statusId: 1
      }
    };

    createBranch(branchRequest)
      .unwrap()
      .then((response) => {
        if (response.error) {
          setIsError(true);
          setErrorMessage(response.error.message);
          return;
        }
        setIsOpen(false);
        setIsSuccess(true);
        const newlyCreatedBranchPublicId = response[0].newly_created_branch_public_id;
        setBranchList((prevBranchList) => {
          return {
            ...prevBranchList,
            [newlyCreatedBranchPublicId]: {
              id: newlyCreatedBranchPublicId,
              company: {
                name: companyList[companyPublicId].name
              },
              branchName: branchRequest.branchData.branchName,
              address: branchRequest.branchData.address,
              address2: branchRequest.branchData.address2,
              city: branchRequest.branchData.city,
              state: enumList.state[branchRequest.branchData.stateId],
              stateId: branchRequest.branchData.stateId,
              zipCode: branchRequest.branchData.postalCode,
              country:
                enumList.country[(enumList.state[branchRequest.branchData.stateId] as IStateValue).countryId].value,
              phoneNumber: branchRequest.branchData.phoneNumber
            }
          };
        });
        setSuccessMessage(t('Branch_Created_Success'));
      })
      .catch((error) => {
        let errMsg = JSON.parse(error.data)?.errorDetails;
        if (errMsg === 'invalid input syntax for type uuid: ""') {
          errMsg = t('Company_Doesnt_Exist');
        }
        setErrorMessage(errMsg || t('Unexpected_Error'));
        setIsError(true);
      });
  };

  const validationSchema = yup.object().shape({
    address: yup.string().required(t('Validations.Address_Required')),
    branchName: yup.string().required(branchNameIsRequiredStr).max(100, branchNameMaxLenStr),
    countryId: yup.string().required(t('Validations.Country_Required')),
    stateId: yup.string().required(t('Validations.State_Required')),
    city: yup.string().required(t('Validations.City_Required')).max(100, cityMaxLenStr),
    postalCode: yup.string().when('countryId', {
      is: '2', // Note: Make sure the type of the value compared to is properly added here
      then: (schema) =>
        schema
          .min(5, zipCodeMinLenStr)
          .max(5, zipCodeMaxLenStr)
          .matches(RegexZipCode, zipCodeInvalidStr)
          .required(zipCodeRequiredStr),
      otherwise: (schema) =>
        schema
          .min(6, postalCodeMinLenStr)
          .max(7, postalCodeMaxLenStr)
          .matches(RegexPostalCode, postalCodeInvalidStr)
          .required(postalCodeRequiredStr)
    }),
    companyName: yup.string().required(t('Validations.Company_Name_Required')),
    phoneNumber: yup
      .string()
      .required(phoneNumberRequiredStr)
      .min(10, t('Phone_number_must_be_10_digits'))
      .matches(NANP_PHONE_REGEX, t('NANP_Description'))
  });

  const options: CompanyEntry[] = Object.keys(companyList || []).map((key) => {
    return companyList[key];
  });

  return (
    <Dialog onClose={() => setIsOpen(false)} open={isOpen} disableEscapeKeyDown={true} maxWidth="md">
      <Paper>
        <Grid container spacing={0}>
          <Grid item xs={11}>
            <DialogTitle>{t('Add_New_Branch')}</DialogTitle>
          </Grid>
          <Grid item xs={1} container justifyContent="flex-end">
            <IconButton color="default" aria-label="cancel" onClick={() => setIsOpen(false)}>
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Box>
          <Formik
            initialValues={initialForm}
            onSubmit={handleCreateBranch}
            validationSchema={validationSchema}
            validateOnBlur={true}
          >
            {(props) => {
              const {
                handleChange,
                values,
                errors,
                setTouched,
                isValid,
                isValidating,
                isSubmitting,
                touched,
                setFieldValue
              } = props;
              const handleImportClick = async () => {
                if (c2BranchData) {
                  const stateId =
                    Object.keys(enumList.state).find(
                      (key) => (enumList.state[key] as IStateValue).isoCode === c2BranchData?.pastate
                    ) || '';
                  const countryId = stateId ? (enumList.state[stateId] as IStateValue).countryId.toString() : '';

                  await setFieldValue('branchName', c2BranchData.companyname);
                  await setFieldValue('countryId', countryId);
                  await setFieldValue('stateId', stateId);
                  await setFieldValue('address', c2BranchData.physicaladdress);
                  await setFieldValue('address2', c2BranchData.physicaladdress2);
                  await setFieldValue('city', c2BranchData.location);
                  await setFieldValue('postalCode', c2BranchData.papostalcode);
                  await setFieldValue('phoneNumber', c2BranchData.companyphone);
                }
              };
              return (
                <Form>
                  <DialogContent>
                    <Box sx={styles.my2}>
                      <Grid item lg={6} md={6} xs={12}>
                        <Box sx={styles.my2}>
                          <Typography variant="body1">{t('Select_Company_Dialog')}</Typography>
                        </Box>
                        <Autocomplete
                          id="companyName"
                          options={options}
                          getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                          onChange={async (_, value) => {
                            let newVal: { name: string; publicId: string };
                            if (typeof value === 'string' || !value) {
                              newVal = {
                                name: value || '',
                                publicId: ''
                              };
                            } else {
                              newVal = {
                                name: value.name,
                                publicId: value.publicId
                              };
                            }

                            await setFieldValue('companyName', newVal.name);
                            setCompanyPublicId(newVal.publicId || '');
                            setIsCompanySelected(!!value);
                          }}
                          freeSolo
                          renderInput={(params) => (
                            <Field
                              as={TextField}
                              {...params}
                              sx={styles.inputField}
                              label={t('Company_Name')}
                              size="small"
                              name="companyName"
                              helperText={touched.companyName && errors.companyName ? errors.companyName : ''}
                              error={touched.companyName && !!errors.companyName}
                            />
                          )}
                        />
                      </Grid>
                      {isCompanySelected && (
                        <>
                          <Box sx={styles.my2}>
                            <Typography
                              variant="body1"
                              sx={{ ...styles.my2, display: props.values.companyName ? 'block' : 'none' }}
                            >
                              {t('C2CompanyID_Dialog')}
                            </Typography>
                          </Box>
                          <Grid container spacing={1} sx={{ display: props.values.companyName ? 'block' : 'none' }}>
                            <Grid item xs={12}>
                              <Field
                                name="c2CompanyId"
                                as={TextField}
                                sx={styles.inputField}
                                label={t('C2CompanyID')}
                                size="small"
                                disabled={!c2Input}
                                onChange={(e: any) => {
                                  handleChange(e);
                                  if (e.target.value.length === 32) {
                                    setIsSearching(true);
                                    searchBranch(e.target.value).then((res) => {
                                      if (res.data) {
                                        setIsC2BranchDialogOpen(true);
                                        setIsSearching(false);
                                        setC2BranchData(res.data);
                                      } else {
                                        setIsSearching(false);
                                        setErrorMessage(branchNotFoundStr);
                                        setIsError(true);
                                        setTimeout(() => {
                                          setIsError(false);
                                        }, 3000);
                                      }
                                    });
                                  }
                                }}
                                InputProps={{
                                  endAdornment: !c2Input ? (
                                    <InputAdornment position="end">
                                      <IconButton onClick={() => setC2Input(!c2Input)}>
                                        <EditIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ) : (
                                    <InputAdornment position="end">
                                      <IconButton onClick={() => setC2Input(!c2Input)}>
                                        {isSearching ? <CircularProgress size={20} /> : <SaveIcon color="primary" />}
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Box sx={{ display: props.values.companyName ? 'block' : 'none' }}>
                            <Box sx={styles.my2}>
                              <Typography variant="h6">{t('BranchInformation_Dialog')}</Typography>
                            </Box>
                            <Grid container spacing={1}>
                              <Grid item lg={6} md={6} xs={12}>
                                <Field
                                  name="branchName"
                                  as={TextField}
                                  sx={styles.inputField}
                                  label={t('Branch_Name')}
                                  size="small"
                                  helperText={touched.branchName && errors.branchName ? errors.branchName : ''}
                                  error={touched.branchName && !!errors.branchName}
                                />
                              </Grid>
                              <Grid item md={6} xs={12}>
                                <FormControl
                                  sx={styles.inputField}
                                  size="small"
                                  error={touched.countryId && !!errors.countryId}
                                >
                                  <InputLabel id="country-label">{selectACountryStr}</InputLabel>
                                  <Field
                                    name="countryId"
                                    id="countryId"
                                    as={Select}
                                    onChange={async (e: React.ChangeEvent<{ value: string }>) => {
                                      await setFieldValue('stateId', '');
                                      await setFieldValue('city', '');
                                      await setFieldValue('postalCode', '');
                                      await setFieldValue('countryId', e.target.value);
                                      // NOTE: Followed what was in SiteInformationSetup for handling this
                                      await setTouched({
                                        ...touched,
                                        countryId: true,
                                        city: false,
                                        stateId: false,
                                        postalCode: false
                                      });
                                      handleChange(e);
                                    }}
                                    labelId="country-label"
                                    label={selectACountryStr + ' *'}
                                    helperText={touched.countryId && errors.countryId ? errors.countryId : ''}
                                  >
                                    {Object.keys(enumList.country).map((key) => {
                                      const validCountries = ['CA', 'US'];
                                      const countryWalker = enumList.country[key] as ICountryValue;

                                      if (!validCountries.includes(countryWalker.alpha2Code)) {
                                        return null;
                                      }

                                      const flagComponent: JSX.Element =
                                        countryWalker.alpha2Code === 'CA' ? (
                                          <CA header={countryWalker.defaultLanguageName} style={styles.flagStyle} />
                                        ) : (
                                          <US header={countryWalker.defaultLanguageName} style={styles.flagStyle} />
                                        );

                                      return (
                                        <MenuItem key={key} value={key}>
                                          <Grid sx={styles.field}>
                                            {flagComponent}
                                            {countryWalker.value}
                                          </Grid>
                                        </MenuItem>
                                      );
                                    })}
                                  </Field>
                                </FormControl>
                              </Grid>
                              <Grid item lg={6} md={6} xs={12}>
                                <Field
                                  component={PhoneField}
                                  label={phoneNumberStr}
                                  name={'phoneNumber'}
                                  size="small"
                                  fullWidth={true}
                                  helperText={touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : ''}
                                  error={touched.phoneNumber && !!errors.phoneNumber}
                                  placeholder={formatPhoneNumber(values.phoneNumber)}
                                  maxDigits={11}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Field
                                  name="address"
                                  as={TextField}
                                  sx={styles.inputField}
                                  label={addressStr}
                                  size="small"
                                  helperText={touched.address && errors.address ? errors.address : ''}
                                  error={touched.address && !!errors.address}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Field
                                  name="address2"
                                  as={TextField}
                                  sx={styles.inputField}
                                  label={t('Address_2')}
                                  size="small"
                                  helperText={touched.address2 && errors.address2 ? errors.address2 : ''}
                                  error={touched.address2 && !!errors.address2}
                                />
                              </Grid>
                              <Grid item md={4} xs={12}>
                                <Field
                                  name="city"
                                  as={TextField}
                                  sx={styles.inputField}
                                  label={cityStr}
                                  size="small"
                                  helperText={touched.city && errors.city ? errors.city : ''}
                                  error={touched.city && !!errors.city}
                                />
                              </Grid>
                              <Grid item md={4} xs={12}>
                                <FormControl sx={styles.inputField} size="small">
                                  <InputLabel id="state-label">
                                    {values.countryId !== canadaCountryId ? selectAStateStr : selectAProvinceStr}
                                  </InputLabel>
                                  <Field
                                    name="stateId"
                                    disabled={!isUsaOrCanada(values.countryId)}
                                    as={Select}
                                    labelId="state-label"
                                    label={values.countryId !== canadaCountryId ? selectAStateStr : selectAProvinceStr}
                                    placeholder={
                                      values.countryId !== canadaCountryId ? selectAStateStr : selectAProvinceStr
                                    }
                                    error={errors.stateId && touched.stateId}
                                    helperText={errors.stateId && touched.stateId ? errors.stateId : ''}
                                  >
                                    {getStateList(enumList, values.countryId, selectACountryStr)}
                                  </Field>
                                </FormControl>
                              </Grid>

                              <Grid item md={4} xs={12}>
                                <Field
                                  name="postalCode"
                                  as={TextField}
                                  component={ZipPostalCodeField}
                                  sx={styles.inputField}
                                  disabled={!isUsaOrCanada(values.countryId)}
                                  label={values.countryId !== canadaCountryId ? zipCodeStr : postalCodeStr}
                                  size="small"
                                  fullWidth={true}
                                  countryId={values.countryId}
                                  placeholder={values.countryId !== canadaCountryId ? zipCodeStr : postalCodeStr}
                                  helperText={touched.postalCode && errors.postalCode}
                                  error={touched.postalCode && !!errors.postalCode}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </>
                      )}
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button variant="contained" onClick={() => setIsOpen(false)}>
                      {cancelStr}
                    </Button>
                    {/* Now I am properly disabling the submit button */}
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      loading={isBranchLoading}
                      disabled={!isValid || isSubmitting || isValidating || !isCompanySelected || isBranchLoading}
                    >
                      {t('Add_New_Branch')}
                    </LoadingButton>
                  </DialogActions>
                  <Dialog open={isC2BranchDialogOpen} maxWidth="md">
                    <DialogTitle>{t('Import_Branch_Data_Title')}</DialogTitle>
                    <DialogContent>
                      {c2BranchData ? (
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 650 }}>
                            <TableHead>
                              <TableRow>
                                <TableCell>{t('accountmanagername')}</TableCell>
                                <TableCell>{t('branchName')}</TableCell>
                                <TableCell>{phoneNumberStr}</TableCell>
                                <TableCell>{t('customerNumber')}</TableCell>
                                <TableCell>{t('physicalAddress')}</TableCell>
                                <TableCell>{t('physicalAddress2')}</TableCell>
                                <TableCell>{t('location')}</TableCell>
                                <TableCell>{stateOrProvinceStr}</TableCell>
                                <TableCell>{zipOrPostalCodeStr}</TableCell>
                                <TableCell>{t('status')}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>{c2BranchData.accountmanagername}</TableCell>
                                <TableCell>{c2BranchData.companyname}</TableCell>
                                <TableCell>{c2BranchData.companyphone}</TableCell>
                                <TableCell>{c2BranchData.customernumber}</TableCell>
                                <TableCell>{c2BranchData.physicaladdress}</TableCell>
                                <TableCell>{c2BranchData.physicaladdress2}</TableCell>
                                <TableCell>{c2BranchData.location}</TableCell>
                                <TableCell>{c2BranchData.pastate}</TableCell>
                                <TableCell>{c2BranchData.papostalcode}</TableCell>
                                <TableCell>{c2BranchData.status}</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : null}
                      <DialogActions>
                        <Button onClick={() => setIsC2BranchDialogOpen(false)} variant="contained">
                          {cancelStr}
                        </Button>
                        <Button
                          onClick={async () => {
                            setIsC2BranchDialogOpen(false);
                            await handleImportClick();
                          }}
                          variant="contained"
                        >
                          {t('Import_Branch')}
                        </Button>
                      </DialogActions>
                    </DialogContent>
                  </Dialog>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Paper>
    </Dialog>
  );
};

const styles = {
  my2: {
    marginY: 2
  },
  inputField: {
    width: '100%',
    '& .MuiInputBase-input': {
      backgroundColor: '#ffffff'
    },
    '&.MuiFormHelperText-root': {
      color: 'red'
    },
    '& .MuiInputLabel-root': {
      color: 'red',
      '&.Mui-focused': {
        color: 'black'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: '#003366'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0071ce'
      }
    }
  },
  field: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  flagStyle: {
    width: '33px',
    minWidth: '33px',
    height: '22px',
    paddingRight: '10px',
    paddingTop: 0,
    paddingBottom: 0,
    mt: 0,
    mb: 0
  },
  spinnerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

export default AddBranchDialog;
