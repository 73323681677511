import { Alert, AlertTitle, Box, List, ListItem, Typography } from '@mui/material';
import GeneralInfo from '../../UiParts/GeneralInfo';
import { axMasterStations, axDoorStations, axAdaptors, axHidden } from '../../../Types/AXTypes';
import QuikspecCategoryTabs from '../../UiParts/QuikspecCategoryTabs';
import { updateFormValuesAX } from 'features/QuikSpec/Utils/QuikspecUpdateFormValuesAX';
import { Form, Formik } from 'formik';
import QuikspecSubmitButton from '../../UiParts/QuikspecSubmitButton';
import { useNavigate, useLocation } from 'react-router-dom';
import QuikspecNote from 'features/QuikSpec/Components/UiParts/QuikspecNote';
import { FormValues } from '../../../Types/QuikspecFormTypes';
import getInitialFormValues from 'features/QuikSpec/Utils/QuikspecInitialFormValues';
import { createQuikSpecAndNavigate, updateQuikspecAndNavigate } from '../../../Utils/QuikspecActionAndNavigate';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useQuikSpecFormValidationSchema from 'features/QuikSpec/Hooks/useQuikSpecFormValidationSchema';
import { useQuikSpecStandardSystems } from 'features/QuikSpec/Hooks/quikSpecDevices';
import useC2 from 'features/QuikSpec/Hooks/useC2';

const QuikspecAX = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { C2ApiFailed } = useC2();
  const StandardSystems = useQuikSpecStandardSystems();
  const { t } = useTranslation();
  const state = location.state || {};
  const siteName: string = location.state.siteName as string;
  const siteIndex = StandardSystems.findIndex((system) => system.siteName === siteName);
  const systemName = StandardSystems[siteIndex].seriesName;
  const systemforC2 = StandardSystems[siteIndex].systemforC2;

  const categories = [...axMasterStations, ...axDoorStations, ...axAdaptors, ...axHidden];

  const stateFormValues = state && Object.keys(state).length > 1 ? state.formValues : undefined;
  const initialValues = getInitialFormValues(systemName, categories, stateFormValues, systemforC2);
  const axAlertError = t('Shared.Error');

  const handleSubmit = React.useCallback(
    async (values: FormValues, actions: any) => {
      try {
        actions.setSubmitting(true);
        //filter out items with quantity set to zero in order to submit quikspec
        values = updateFormValuesAX(values);

        //determine if form is in edit mode
        const editing = stateFormValues != undefined;
        if (editing) {
          //update QuikSpec and navigate to QuikSpec Summary page
          await updateQuikspecAndNavigate(values, actions, navigate);

          actions.setSubmitting(false);
          return;
        } else {
          //create QuikSpec and navigate to QuikSpec Summary page
          await createQuikSpecAndNavigate(values, actions, navigate);

          actions.setSubmitting(false);
        }
      } catch (error) {
        actions.setSubmitting(false);
        actions.setErrors({ submit: t('Something_went_wrong_try_again') });
      }
    },
    [t]
  );

  const { ax } = useQuikSpecFormValidationSchema();

  return (
    <Box>
      {C2ApiFailed && (
        <Alert severity="warning">
          <AlertTitle>{t('Missing_C2_Data')}</AlertTitle>
          {t('C2_Data_Missing_Detail')}
        </Alert>
      )}
      <Formik initialValues={initialValues} validationSchema={ax} onSubmit={handleSubmit}>
        {({ errors, touched, isSubmitting }) => {
          return (
            <Form>
              <GeneralInfo editing={state.formValues != undefined} />
              {/* Render QuikspecCategoryTabs for each category */}
              <QuikspecCategoryTabs categories={axMasterStations} />
              <QuikspecCategoryTabs categories={axDoorStations} />
              <QuikspecCategoryTabs categories={axAdaptors} />
              {(stateFormValues
                ? Object.keys(errors).length > 0
                : Object.keys(touched).length > 0 && Object.keys(errors).length > 0) && (
                <Alert severity="error">
                  <AlertTitle>{axAlertError}</AlertTitle>
                  <List>
                    {Object.keys(errors).map((field) => (
                      <ListItem key={field}>
                        <Typography variant="body2" color="error">
                          {errors[field]}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Alert>
              )}
              <QuikspecSubmitButton
                editing={state.formValues != undefined}
                errors={errors}
                submitting={isSubmitting}
                C2ApiFailed={C2ApiFailed}
              />
              <QuikspecNote systemName={initialValues.systemName} />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default QuikspecAX;
