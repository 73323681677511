import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams, useLocation } from 'react-router-dom';
import { Box, Button, IconButton, Tooltip, Typography, Checkbox, FormControlLabel } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// #region import LicenseManagement  //response key step?
// Hooks
import { useLicenseByIdByLongPolling } from 'features/LicenseManagement/hooks/useLicense';
import { useDeploymentById } from 'features/LicenseManagement/hooks/useDeployments';
// Shared Components
import LoadingSpinner from 'features/LicenseManagement/components/common/LoadingSpinner';
import Header from 'features/LicenseManagement/components/common/Header';
import ErrorAlert from 'features/LicenseManagement/components/common/ErrorAlert';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
// Styles
import { LicenseManagementCommonStyles } from 'features/LicenseManagement/styles/CommonStyles';
// Types
import { DeploymentSteps } from 'features/LicenseManagement/components/common/DeploymentSteps';
import { PaymentMethod, steps } from 'features/LicenseManagement/components/common/Types';
import { getCurrentUser } from 'store/slices/usersSlice';
import { useSelector } from 'react-redux';
import { useLazyGetUserBillingInformationQuery } from 'services/aiphoneCloud';
// #endregion

const ResponseKeyStep = () => {
  const { t } = useTranslation('acNioLicenseManagement');
  const activatingLicenseErrorStr = t('Error_Loading_Activated_License');
  const deploymentErrStr = t('Error_Loading_Deployment');
  const activateLicenseText = t('Activate_License_Text');
  const activateLicenseInstructionsText = t('License_Activation_Instructions');
  const checkboxStr = t('Response_Key_CheckBox_Text');

  const { deploymentId } = useParams();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const licenseCardImageUrl = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/ResponseKey.png`;

  // Extract responseKey and isRearmFlow from state if available
  const { responseKey: stateResponseKey, isRearmFlow } = location.state || {};

  const licenseIdFromUrl = searchParams.get('licenseId');
  const aiphoneBranchPublicId = searchParams.get('aiphoneBranchPublicId');
  const isFromStripeCheckout = !!licenseIdFromUrl && !!aiphoneBranchPublicId;
  const [licenseId, setLicenseId] = useState<string | null>(licenseIdFromUrl);
  const [responseKey, setResponseKey] = useState<string>(stateResponseKey || '');
  const [isChecked, setIsChecked] = useState(false);
  const currentUser = useSelector(getCurrentUser);
  const userId = localStorage.getItem('userId');
  const [getUserBillingInformation] = useLazyGetUserBillingInformationQuery();

  if (!deploymentId) {
    return <Typography>{t('Error_Loading_Deployment_ID')}</Typography>;
  }

  const {
    data: license,
    isLoading: isLoadingLicense,
    isError: isFetchLicenseError,
    refetch: refetchLicense
  } = useLicenseByIdByLongPolling(licenseId || '', aiphoneBranchPublicId);

  const shouldFetchDeployment = !isFromStripeCheckout || (isFromStripeCheckout && !!license && !isFetchLicenseError);

  const {
    data: deployment,
    isLoading: isLoadingDeployment,
    error: deploymentError,
    isError: isFetchDeploymentError,
    refetch: refetchDeployment
  } = useDeploymentById(
    deploymentId || '',
    isFromStripeCheckout ? aiphoneBranchPublicId : undefined,
    shouldFetchDeployment
  );

  useEffect(() => {
    if (isFromStripeCheckout && license) {
      refetchDeployment();
    }
  }, [isFromStripeCheckout, license, refetchDeployment]);

  useEffect(() => {
    if (isFromStripeCheckout && currentUser && !currentUser.stripeCustomerId) {
      const fetchBillingInfo = async () => {
        try {
          await getUserBillingInformation(userId).unwrap();
        } catch (error) {
          // Absorb error
        }
      };

      fetchBillingInfo();
    }
  }, [isFromStripeCheckout]);

  useEffect(() => {
    if (!licenseId && deployment?.licenseId) {
      setLicenseId(deployment.licenseId);
    }
  }, [deployment, licenseId]);

  useEffect(() => {
    // Only update responseKey if it's empty (i.e., user visited page directly)
    if (!responseKey && license?.responseKey) {
      setResponseKey(license.responseKey);
    }
  }, [license, responseKey]);

  const handleCopy = () => {
    navigator.clipboard.writeText(responseKey);
  };

  const handleDone = () => {
    navigate(`/acniolicensing/deployments/${deploymentId}/licenseDetails`, { replace: true });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  if (isLoadingLicense || isLoadingDeployment) {
    return <LoadingSpinner />;
  }
  if (isFetchLicenseError && !license) {
    return (
      <ErrorAlert
        errorMessage={activatingLicenseErrorStr}
        customMessage={t('Please_Try_Again_Response_Key')}
        onRetry={refetchLicense}
      />
    );
  }

  if (deploymentError) {
    return <ErrorAlert errorMessage={deploymentErrStr} onRetry={refetchDeployment} />;
  }

  return (
    <>
      <Box sx={styles.header}>
        <Header deployment={deployment} status={deployment?.licenseStatus} />
        <Box sx={styles.container}>
          <Typography variant="h6" sx={styles.title}>
            {isRearmFlow ? t('Rearm_License_Deployment') : t('Finalize_License_Deployment')}
          </Typography>
          <DeploymentSteps steps={steps(PaymentMethod.Online, !!isRearmFlow)} activeStep={3} />
          <Box sx={styles.textContainer}>
            <Typography variant="h6" sx={styles.responseKeyText}>
              {t('Response_Key')} : <span style={styles.responsekey}>{responseKey}</span>
              <Tooltip title={t('Copy_To_Clipboard')}>
                <IconButton onClick={handleCopy} size="small" sx={styles.copyButton}>
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </Typography>
            <Typography variant="body1" paragraph>
              {activateLicenseText}
            </Typography>
            <Typography variant="body1" paragraph>
              {activateLicenseInstructionsText}
            </Typography>
            <Box component="img" src={licenseCardImageUrl} alt="License Card" sx={styles.licenseCardImage} />
          </Box>
          <Box sx={styles.checkboxContainer}>
            <FormControlLabel
              control={<Checkbox checked={isChecked} color="primary" size="medium" onChange={handleCheckboxChange} />}
              labelPlacement="end"
              label={checkboxStr}
            />
          </Box>
          <Box sx={LicenseManagementCommonStyles.buttonContainer}>
            <Button
              variant="contained"
              onClick={handleDone}
              sx={LicenseManagementCommonStyles.button}
              disabled={!isChecked}
            >
              {t('Done')}
            </Button>
          </Box>
        </Box>
      </Box>
      {(isFetchDeploymentError || isFetchLicenseError) && (
        <ErrorAlert
          errorMessage={'Timeout: License Info not received'}
          customMessage={t('Please_Try_Again_Response_Key')}
          onRetry={refetchDeployment}
        />
      )}
      {!isFetchDeploymentError && !isFetchLicenseError && !responseKey && <LoadingSpinner />}
    </>
  );
};

const styles = {
  header: {
    pl: 5,
    pr: 3,
    pt: 2,
    maxWidth: '90%'
  },
  container: {
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    padding: 2,
    backgroundColor: '#f9f9f9',
    marginTop: '20px',
    alignItems: 'flex-start'
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 2,
    marginTop: '20px',
    marginLeft: '25px'
  },
  title: {
    margin: 0,
    padding: 0
  },
  responseKeyText: {
    padding: 1,
    marginTop: 2
  },
  responsekey: {
    backgroundColor: '#f0f0f0',
    padding: '2px 4px',
    borderRadius: '4px',
    marginLeft: '8px'
  },
  licenseCardImage: {
    flex: '0 0 auto',
    maxWidth: '300px',
    maxHeight: '300px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    padding: '10px',
    height: 'auto',
    objectFit: 'contain'
  },
  copyButton: {
    marginLeft: '8px'
  },
  checkboxContainer: {
    border: '1px solid lightgrey',
    borderRadius: ' 0.25rem',
    marginTop: 2,
    padding: '0 .50rem'
  }
};

export default ResponseKeyStep;
