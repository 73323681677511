/**
 * @constant IXGPortalLoginFormStyles
 * @description This is a style object for the Form elements.
 *  It is meant to match the original [IXG Portal Login](https://portal.ixg.aiphone-app.net/index.html) page form.
 */
export const IXGPortalLoginFormStyles = {
  inputField: {
    marginBottom: 1,
    width: '100%',
    padding: 0,
    '& input[type="text" i]': {
      backgroundColor: '#ffffff',
      paddingTop: '0.375rem',
      paddingBottom: '0.375rem',
      paddingRight: '0.75rem',
      paddingLeft: '0.75rem'
    },
    '& input[type="password" i]': {
      backgroundColor: '#ffffff',
      paddingTop: '0.375rem',
      paddingBottom: '0.375rem',
      paddingRight: '0.75rem',
      paddingLeft: '0.75rem'
    },
    '& input[type="email" i]': {
      backgroundColor: '#ffffff',
      paddingTop: '0.375rem',
      paddingBottom: '0.375rem',
      paddingRight: '0.75rem',
      paddingLeft: '0.75rem'
    },
    '& input[type="tel" i]': {
      backgroundColor: '#ffffff',
      paddingTop: '0.375rem',
      paddingBottom: '0.375rem',
      paddingRight: '0.75rem',
      paddingLeft: '0.75rem'
    },
    '& .MuiInputLabel-root': {
      color: 'grey',
      padding: 0,
      '&.Mui-focused': {
        color: 'black'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: '#003366'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0071ce'
      }
    },
    '&.MuiFormHelperText-root': {
      color: '#d32f2f'
    }
  }
};

/** Cleaning up some of the styles for Forms */
export const BaseLoginStyles = {
  loginButton: {
    fontSize: '1.5rem',
    fontWeight: '700',
    borderRadius: '0.25 rem',
    width: '40%'
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center'
  },
  inputField: {
    marginBottom: 1,
    width: '100%',
    '& .MuiInputBase-input': {
      backgroundColor: '#ffffff'
    },
    '&.MuiFormHelperText-root': {
      color: 'red'
    },
    '& .MuiInputLabel-root': {
      color: 'red',
      '&.Mui-focused': {
        color: 'black'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: '#003366'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0071ce'
      }
    }
  },
  otherOptions: {
    paddingTop: '0 !important'
  },
  otherOptionContainer: {
    alignItems: 'center'
  },
  forgotPasswordButton: {
    color: '#0071CE',
    float: 'right',
    fontFamily: "'Roboto Condensed', sans-serif"
  },
  registrationButton: {
    color: '#0071CE',
    fontFamily: "'Roboto Condensed', sans-serif"
  },
  loginButtonContainer: {
    mt: 2
  },
  createAccountContainer: {
    mb: 2
  }
};
