import React from 'react';
import { FieldProps } from 'formik';

import { TextField } from '@mui/material';

const EmailField: React.FC<FieldProps> = ({ field, form, ...props }) => {
  const handleFocus = async () => {
    await form.setTouched({ ...form.touched, [field.name]: true });
  };

  const handleBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    await form.setFieldValue(field.name, value);
    return;
  };

  return <TextField {...field} {...props} type="email" onBlur={handleBlur} onFocus={handleFocus} value={field.value} />;
};

export default EmailField;
