import { Box, Grid, Step, StepLabel, Stepper, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { ReactElement, useContext, useEffect, useState } from 'react';
import RegisterGatewayDialog from '../Dialogs/Wizard/RegisterGateway/RegisterGatewayDialog';
import useSharedStyles from 'styles/useSharedStyles';
import { DeviceTypesModel, GatewayCommands } from 'features/RemoteManagement/types/Types';
import AddUnitDialog from 'features/RemoteManagement/SiteDashboard/Dialogs/AddUnitDialog';
import { useAppSelector } from 'store/hooks';
import AddStationsModal from 'features/RemoteManagement/SiteDashboard/Dialogs/AddStationsDialog';
import { LoadingButton } from '@mui/lab';
import AssignDevicesToUnitsDialog from '../../../Dialogs/AssignDevicesToUnitsDialog';
import AssociateDevicesDialog from '../../../Dialogs/AssociateDevicesDialog';
import { IDevice } from 'store/slices/devicesSlice';
import { fetchLocalEnumList } from 'shared/utils/EnumUtils';
import { SyncDevicePayload } from 'features/RemoteManagement/SiteDashboard/DeviceDataGrid';
import { ActivityMessageType, RemoteManagementContext } from 'context/RemoteManagementContext';

const SiteWizard = () => {
  const sharedStyle = useSharedStyles();
  const { syncCommand, setIsSyncing, addCustomMessageToHistory, isSyncing } = useContext(RemoteManagementContext);
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const siteInfo = useAppSelector((state) => state.site.siteInfo);
  const unitList = useAppSelector((state) => state.units.UnitList);
  const deviceList = useAppSelector((state) => state.devices.DeviceList);
  const needsAssociationList = Object.values(deviceList).map(
    (device: IDevice) => device.networkSettings?.needsAssociation
  );

  const enumList = fetchLocalEnumList();
  const gwDeviceType = parseInt(
    Object.keys(enumList.deviceType).find((key) => enumList.deviceType[key].value === 'IXGW-GW') as string
  );
  const lcDeviceType = parseInt(
    Object.keys(enumList.deviceType).find((key) => enumList.deviceType[key].value === 'IXGW-LC') as string
  );
  const maDeviceType = parseInt(
    Object.keys(enumList.deviceType).find((key) => enumList.deviceType[key].value === 'IXW-MA') as string
  );
  const noUnitDeviceTypes = [lcDeviceType, gwDeviceType, maDeviceType];

  const { t } = useTranslation();
  const wizardProgressTitleStr = t('Site.Configure.Progress.Title');
  const wizardProgressSubtitleStr = t('Site.Configure.Progress.Subtitle');
  const registerGatewayStr = t('Site.Configure.RegisterGateway.Title');
  const registerGatewayCompletedStr = t('Site.Configure.RegisterGateway.Completed');
  const registerGatewayButtonStr = t('Site.Configure.RegisterGateway.Button');
  const registerGatewayCallToActionStr = t('Site.Configure.RegisterGateway.CallToAction');
  const associateDevicesStr = t('Site.Configure.AssociateStations.Title');
  const associateDevicesCompletedStr = t('Site.Configure.AssociateStations.Completed');
  const associateDevicesButtonStr = t('Site.Configure.AssociateStations.Button');
  const associateDevicesCallToActionStr = t('Site.Configure.AssociateStations.CallToAction');
  const assignDevicesStr = t('Site.Configure.AssignStations.Title');
  const assignDevicesCompletedStr = t('Site.Configure.AssignStations.Completed');
  const assignDevicesButton = t('Site.Configure.AssignStations.Button');
  const assignDevicesCallToActionStr = t('Site.Configure.AssignStations.CallToAction');
  const syncStationsStr = t('Site.Configure.SyncStations.Title');
  const syncStationsButtonStr = t('Site.Configure.SyncStations.Button');
  const syncStationsCallToActionStr = t('Site.Configure.SyncStations.CallToAction');
  const addUnitsStr = t('Site.Configure.AddUnits.Title');
  const addUnitsCompletedStr = t('Site.Configure.AddUnits.Completed');
  const addUnitsButtonStr = t('Site.Configure.AddUnits.Button');
  const addUnitsCallToActionStr = t('Site.Configure.AddUnits.CallToAction');
  const addDevicesStr = t('Site.Configure.AddStations.Title');
  const addDevicesCompletedStr = t('Site.Configure.AddStations.Completed');
  const addDevicesButtonStr = t('Site.Configure.AddStations.Button');
  const addDevicesCallToAction = t('Site.Configure.AddStations.CallToAction');

  useEffect(() => {
    // do not updated the active step if the wizard is open
    if (!isWizardOpen) {
      setActiveStep(getCurrentStep());
      setStepperStep(getCurrentStep(true));
    }
  }, [siteInfo, unitList, deviceList, isWizardOpen]);

  interface IWizardStep {
    title: string;
    completedText?: string;
    buttonText: string;
    callToAction: string;
    endIcon?: React.ReactNode;
    completed: () => boolean;
    supportedSiteTypes: number[];
  }

  const enum WizardSteps {
    RegisterGateway,
    AddUnits,
    AddDevices,
    AssociateDevices,
    AssignDevices,
    SyncStations
  }

  const wizardSteps: IWizardStep[] = [
    {
      title: registerGatewayStr,
      completedText: registerGatewayCompletedStr,
      buttonText: registerGatewayButtonStr,
      callToAction: registerGatewayCallToActionStr,
      completed: () => {
        return siteInfo.registeredGatewayPublicId !== null;
      },
      supportedSiteTypes: [1, 2]
    },
    {
      title: addUnitsStr,
      completedText: addUnitsCompletedStr,
      buttonText: addUnitsButtonStr,
      callToAction: addUnitsCallToActionStr,
      endIcon: <AddIcon />,
      completed: () => {
        return Object.keys(unitList).length > 0;
      },
      supportedSiteTypes: [2]
    },
    {
      title: addDevicesStr,
      completedText: addDevicesCompletedStr,
      buttonText: addDevicesButtonStr,
      callToAction: addDevicesCallToAction,
      endIcon: <AddIcon />,
      completed: () => {
        return Object.values(deviceList).some((device) => {
          return device.basicInfo.deviceType !== 18;
        });
      },
      supportedSiteTypes: [1, 2]
    },
    {
      title: associateDevicesStr,
      completedText: associateDevicesCompletedStr,
      buttonText: associateDevicesButtonStr,
      callToAction: associateDevicesCallToActionStr,
      completed: () => {
        return (
          wizardSteps[WizardSteps.AddDevices].completed() &&
          needsAssociationList.every((needsAssociation) => needsAssociation !== true)
        );
      },
      supportedSiteTypes: [1, 2]
    },
    {
      title: assignDevicesStr,
      completedText: assignDevicesCompletedStr,
      buttonText: assignDevicesButton,
      callToAction: assignDevicesCallToActionStr,
      completed: () => {
        return (
          wizardSteps[WizardSteps.AddDevices].completed() &&
          !Object.values(deviceList)
            .filter((device) => {
              return !noUnitDeviceTypes.includes(device.basicInfo.deviceType);
            })
            .some((device) => {
              return device.unitPublicId === null;
            })
        );
      },
      supportedSiteTypes: [2]
    },
    {
      title: syncStationsStr,
      buttonText: syncStationsButtonStr,
      callToAction: syncStationsCallToActionStr,
      endIcon: <ArrowUpwardIcon />,
      completed: () => {
        return (
          wizardSteps[WizardSteps.AssignDevices].completed() &&
          !Object.values(deviceList).some((device) => {
            return device.lastSyncedOn === null;
          })
        );
      },
      supportedSiteTypes: [1, 2]
    }
  ];

  function getCurrentStep(getStepperStep = false): number {
    let supportedWalker = 0;
    for (let i = 0; i < wizardSteps.length; i++) {
      if (wizardSteps[i].supportedSiteTypes.includes(siteInfo.typeId)) {
        if (!wizardSteps[i].completed()) {
          return getStepperStep ? supportedWalker : i;
        }
        ++supportedWalker;
      }
    }

    return wizardSteps.length;
  }

  const [activeStep, setActiveStep] = useState(getCurrentStep());
  const [stepperStep, setStepperStep] = useState(getCurrentStep(true));

  function handleSubmitStep() {
    setIsWizardOpen(false);
  }

  function renderWizardDialog(): ReactElement {
    switch (activeStep) {
      case WizardSteps.AddUnits:
        return <AddUnitDialog isOpen={isWizardOpen} setIsOpen={setIsWizardOpen} handleNextStep={handleSubmitStep} />;
      case WizardSteps.AddDevices:
        return (
          <AddStationsModal
            isOpen={isWizardOpen}
            allowAppConfig={siteInfo.typeId === 1}
            setIsOpen={setIsWizardOpen}
            handleNextStep={handleSubmitStep}
          />
        );
      case WizardSteps.AssociateDevices:
        return (
          <AssociateDevicesDialog isOpen={isWizardOpen} setIsOpen={setIsWizardOpen} handleNextStep={handleSubmitStep} />
        );
      case WizardSteps.AssignDevices:
        return (
          <AssignDevicesToUnitsDialog
            isOpen={isWizardOpen}
            setIsOpen={setIsWizardOpen}
            handleNextStep={handleSubmitStep}
          />
        );
      case WizardSteps.RegisterGateway:
        return (
          <RegisterGatewayDialog isOpen={isWizardOpen} setIsOpen={setIsWizardOpen} handleNextStep={handleSubmitStep} />
        );
      default:
        return <></>;
    }
  }

  const generateSyncPayload = () => {
    const payload: Record<string, SyncDevicePayload> = {};
    Object.entries(deviceList).forEach(([publicId, device]) => {
      if (!device.networkSettings?.ipV4Address) {
        return;
      }

      if (device.basicInfo.deviceType == 18) {
        return;
      }

      payload[device.networkSettings?.ipV4Address] = {
        mac_addr: device.basicInfo.macAddress,
        station_type: DeviceTypesModel[device.basicInfo.deviceType],
        devicePublicId: publicId
      };
    });
    return payload;
  };

  const syncStationsHandler = () => {
    setIsSyncing(true);
    const payload = generateSyncPayload();
    const transactionId = crypto.randomUUID();
    addCustomMessageToHistory({
      transactionId,
      command: GatewayCommands.SYNC,
      type: ActivityMessageType.ACTION,
      message: 'Syncing stations',
      sitePublicId: siteInfo.publicId,
      commandPayload: payload
    });
    syncCommand(payload, transactionId);
  };

  function renderButtonAction(): () => void {
    switch (activeStep) {
      case WizardSteps.SyncStations:
        return () => {
          syncStationsHandler();
        };
      default:
        return () => {
          setIsWizardOpen(true);
        };
    }
  }

  return activeStep < wizardSteps.length ? (
    <>
      <Box sx={[sharedStyle.infoBox, sharedStyle.common.width.full, sharedStyle.common.padding.xs]}>
        <Grid container sx={sharedStyle.gridContainer}>
          <Grid item sx={[sharedStyle.gridContainer, sharedStyle.common.gap.xs]}>
            <Typography variant="h6">{wizardProgressTitleStr}</Typography>
            <Typography variant="body2" color="textSecondary">
              {wizardProgressSubtitleStr}
            </Typography>
          </Grid>
          <Grid item sx={sharedStyle.gridContainer}>
            <Stepper activeStep={stepperStep}>
              {wizardSteps
                .filter((step) => step.supportedSiteTypes.includes(siteInfo.typeId))
                .map((stepWalker, index) => {
                  const labelProps: {
                    optional?: React.ReactNode;
                  } = {};
                  if (index < stepperStep) {
                    labelProps.optional = <Typography variant="caption">{stepWalker.completedText}</Typography>;
                  }
                  return (
                    <Step key={index} completed={stepWalker.completed()}>
                      <StepLabel {...labelProps}>{stepWalker.title}</StepLabel>
                    </Step>
                  );
                })}
            </Stepper>
            <Grid container sx={[sharedStyle.gridContainer, sharedStyle.common.gap.sm, sharedStyle.common.width.fit]}>
              <Typography variant="body2" color="textSecondary">
                {wizardSteps[activeStep].callToAction}
              </Typography>
              <LoadingButton
                variant="contained"
                color="primary"
                size="large"
                sx={sharedStyle.common.width.fit}
                endIcon={wizardSteps[activeStep].endIcon || <ArrowForwardIcon />}
                onClick={renderButtonAction()}
                loading={isSyncing}
              >
                {wizardSteps[activeStep].buttonText}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {renderWizardDialog()}
    </>
  ) : (
    <></> // No more steps to show
  );
};

export default SiteWizard;
