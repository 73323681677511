import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import { accountDeletedEmail } from 'shared/api/Aws/RemoteManagementApi';
import { useDeleteUserMutation } from 'services/aiphoneCloud';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { CloudUser, getCurrentUser } from 'store/slices/usersSlice';
import { GridRowSelectionModel } from '@mui/x-data-grid';

interface DeleteAccountDialogProps {
  isOpen: boolean;
  onClose: () => void;
  selectionModel?: GridRowSelectionModel;
}

type AlertPayload = {
  message: string;
  type: 'error' | 'success' | 'warning' | 'info';
  isOpen: boolean;
};

const DeleteAccountDialog = ({ isOpen, onClose, selectionModel }: DeleteAccountDialogProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const { t } = useTranslation();
  const enterDeleteToConfirmStr = t('Shared.EnterDeleteToConfirm');
  const anotherProfileStr = t('User.Delete.Reason.AnotherProfile');
  const dataSafetyStr = t('User.Delete.Reason.DataSafety');
  const otherStr = t('User.Delete.Reason.Other');
  const policyViolationStr = t('User.Delete.Reason.PolicyViolation');
  const userRequestStr = t('User.Delete.Reason.UserRequest');
  const inactiveStr = t('User.Delete.Reason.Inactive');
  const incorrectUserIdStr = t('User.Delete.Error.IncorrectUserId');
  const userDeleteSuccessStr = t('User.Delete.Success');
  const userDeleteFailedStr = t('User.Delete.Error.Failed');
  const dialogTitleStr = t('User.Delete.Dialog.Title');
  const dialogDescStr = t('User.Delete.Dialog.Desc');
  const dialogAskReasonStr = t('User.Delete.Dialog.AskReason');
  const dialogAdditionalCommentsStr = t('User.Delete.Dialog.AdditionalComments');
  const deleteStr = t('Shared.Delete');
  const cancelStr = t('Shared.Cancel');

  const isSelfDeletion = useMemo(() => location.pathname === '/profile', [location.pathname]);

  const reasonsList = useMemo(
    () =>
      isSelfDeletion
        ? {
            anotherProfile: anotherProfileStr,
            safetyOfData: dataSafetyStr,
            otherReason: otherStr
          }
        : {
            policyViolation: policyViolationStr,
            userRequest: userRequestStr,
            inactiveAccount: inactiveStr,
            otherReason: otherStr
          },
    [t, isSelfDeletion]
  );

  const userInfo: CloudUser | null = useSelector(getCurrentUser);
  const [deleteUser, { isLoading: isDeleting }] = useDeleteUserMutation();
  const [alert, setAlert] = useState<AlertPayload>({ message: '', type: 'success', isOpen: false });
  const [reason, setReason] = useState('');
  const [isDeletionConfirmed, setIsDeletionConfirmed] = useState(false);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  const handleDeleteAccount = async () => {
    try {
      if (!userInfo || (!isSelfDeletion && !selectionModel)) {
        throw new Error(incorrectUserIdStr);
      }
      const targetUserPublicId = !isSelfDeletion && selectionModel ? selectionModel[0] : userInfo.publicId;

      await deleteUser({ targetUserPublicId, accountDeletionReason: reason }).unwrap();

      if (isSelfDeletion) {
        await accountDeletedEmail({
          userEmail: userInfo.email,
          userName: `${userInfo.firstName} ${userInfo.lastName}`
        });
      }

      setAlert({ message: userDeleteSuccessStr, type: 'success', isOpen: true });
      timeoutRef.current = setTimeout(() => {
        if (isSelfDeletion) {
          navigate('/auth/login');
        }
      }, 2000);
    } catch {
      setAlert({ message: userDeleteFailedStr, type: 'error', isOpen: true });
    }
  };

  const renderReasons = () =>
    Object.entries(reasonsList).map(([key, label]) => (
      <FormControlLabel key={key} value={label} control={<Radio />} label={label} />
    ));

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <SnackbarAlert
        type={alert.type}
        time={6000}
        text={alert.message}
        isOpen={alert.isOpen}
        onClose={() => setAlert({ ...alert, isOpen: false })}
      />
      <DialogTitle sx={styles.dialogTitle}>
        {dialogTitleStr}
        <IconButton aria-label="close" onClick={onClose} sx={styles.closeIconStyle}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Formik initialValues={{ confirmation: '' }} onSubmit={handleDeleteAccount}>
        {({ handleChange, errors, touched }) => (
          <Form>
            <DialogContent>
              <DialogContentText>{dialogDescStr}</DialogContentText>
              <Box my={2}>
                <Field
                  name="confirmation"
                  as={TextField}
                  label={enterDeleteToConfirmStr}
                  fullWidth
                  error={touched.confirmation && !!errors.confirmation}
                  helperText={touched.confirmation && errors.confirmation}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);
                    setIsDeletionConfirmed(e.target.value === 'DELETE');
                  }}
                />
              </Box>
              <Box mt={2}>
                <FormControl component="fieldset">
                  <FormLabel>{dialogAskReasonStr}</FormLabel>
                  <RadioGroup name="deleteReason" onChange={(e) => setReason(e.target.value)}>
                    {renderReasons()}
                  </RadioGroup>
                </FormControl>
              </Box>
              <TextField
                label={dialogAdditionalCommentsStr}
                multiline
                rows={4}
                variant="filled"
                fullWidth
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                sx={{ mt: 2 }}
              />
            </DialogContent>
            <DialogActions sx={styles.dialogActions}>
              <Button onClick={onClose}>{cancelStr}</Button>
              <LoadingButton type="submit" color="error" loading={isDeleting} disabled={!isDeletionConfirmed}>
                {deleteStr}
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

const styles = {
  closeIconStyle: { position: 'absolute', right: 8, top: 8 },
  dialogTitle: { borderBottom: '1px solid silver' },
  dialogActions: { borderTop: '1px solid silver' }
};

export default DeleteAccountDialog;
