import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import React, { SyntheticEvent } from 'react';
import DeviceDataGrid from './DeviceDataGrid';
import { DeviceList } from 'store/slices/devicesSlice';
import { useTranslation } from 'react-i18next';
import { IUnitList } from 'store/slices/unitsSlice';
import { ITenantList } from 'store/slices/tenantsSlice';
import UnitDatagrid from './UnitDatagrid';
import TenantDataGrid from './Tenants/TenantDataGrid';

interface SiteDataGridProps {
  deviceList: DeviceList;
  unitList: IUnitList;
  tenantList: ITenantList;
  latestFirmwareList: any;
  setIsAddDeviceModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SiteDataGrid = ({
  deviceList,
  unitList,
  tenantList,
  latestFirmwareList,
  setIsAddDeviceModalOpen
}: SiteDataGridProps) => {
  const [tabIndex, setTabIndex] = React.useState('1');
  const deviceCount = Object.keys(deviceList).length;
  const unitCount = Object.keys(unitList).length;
  const tenantCount = Object.keys(tenantList).length;

  const { t } = useTranslation();
  const stationTabLabelStr = t('Station.Station', { count: 2 });
  const unitTabLabelStr = t('Unit.Unit', { count: 2 });
  const tenantTabLabelStr = t('Tenant.Tenant', { count: 2 });

  const handleTabChange = (_: SyntheticEvent, newValue: string) => {
    setTabIndex(newValue);
  };

  return (
    <>
      <TabContext value={tabIndex}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabChange}>
            <Tab label={`${stationTabLabelStr} (${deviceCount})`} value="1" />
            <Tab label={`${unitTabLabelStr} (${unitCount})`} value="2" />
            <Tab label={`${tenantTabLabelStr} (${tenantCount})`} value="3" />
          </TabList>
        </Box>
        <TabPanel
          sx={{
            paddingX: 0,
            height: 512,
            width: '100%'
          }}
          value="1"
        >
          <DeviceDataGrid latestFirmwareList={latestFirmwareList} setIsAddDeviceModalOpen={setIsAddDeviceModalOpen} />
        </TabPanel>
        <TabPanel
          sx={{
            paddingX: 0,
            height: 512,
            width: '100%'
          }}
          value="2"
        >
          <UnitDatagrid />
        </TabPanel>
        <TabPanel
          sx={{
            paddingX: 0,
            height: 512,
            width: '100%'
          }}
          value="3"
        >
          <TenantDataGrid />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default SiteDataGrid;
