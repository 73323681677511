import { Box, Typography, Grid, Button } from '@mui/material';
import { useState } from 'react';
import LineChartGraph from 'shared/components/graphs/LineChartGraph';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import BarChartGraph from 'shared/components/graphs/BarChartGraph';
import { useTranslation } from 'react-i18next';
import { TimeframeOptions } from 'shared/types/chartDataItem';
import useSharedStyles from 'styles/useSharedStyles';
import { FeatureSummaryItem } from 'shared/components/home/statsView/FeatureSummaryView';
import {
  getRemoteManagementData,
  getSimBillingData,
  getQuickSpecData
} from 'shared/components/home/statsView/MockData';
import { useNavigate } from 'react-router-dom';

export const filterDataByTimeframe = (t, key: 'remoteManagement' | 'quickSpec', timeframe: string) => {
  const data = key === 'remoteManagement' ? getRemoteManagementData(t) : getQuickSpecData(t);

  switch (timeframe) {
    case TimeframeOptions.SIX_MONTHS:
      return data.map((dataset) => ({
        ...dataset,
        data: dataset.data.slice(-6)
      }));

    case TimeframeOptions.THIS_MONTH: {
      const currentMonth = new Date().toLocaleString('default', { month: 'long' });
      return data.map((dataset) => ({
        ...dataset,
        data: dataset.data.filter((d) => d.label === currentMonth)
      }));
    }

    case TimeframeOptions.ALL_TIME:
    default:
      return data;
  }
};

const DashboardStatsView = () => {
  const sharedStyle = useSharedStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  /* Translation strings */
  const simBillingTitle = t('DashboardStats.SimBilling.Title');
  const simBillingButtonStr = t('DashboardStats.SimBilling.ButtonText');
  const simBillingChartTitle = t('DashboardStats.SimBilling.ChartTitle');

  const handleNavigation = (path: string) => () => navigate(path);

  const lineChartGraphSections = [
    {
      title: t('DashboardStats.RemoteManagement.Title'),
      buttonText: t('DashboardStats.RemoteManagement.ButtonText'),
      chartTitle: t('DashboardStats.RemoteManagement.ChartTitle'),
      key: 'remoteManagement',
      path: '/remotemanagement'
    },
    {
      title: t('DashboardStats.QuickSpecs.Title'),
      buttonText: t('DashboardStats.QuickSpecs.ButtonText'),
      chartTitle: t('DashboardStats.QuickSpecs.ChartTitle'),
      key: 'quickSpec',
      path: '/quikspec'
    }
  ];

  const [timeframes, setTimeframes] = useState({
    remoteManagement: TimeframeOptions.ALL_TIME,
    quickSpec: TimeframeOptions.ALL_TIME
  });

  const handleTimeframeChange = (key: 'remoteManagement' | 'quickSpec', newTimeframe: string) => {
    setTimeframes((prev) => ({ ...prev, [key]: newTimeframe }));
  };

  return (
    <>
      <FeatureSummaryItem />
      <Grid container spacing={2} sx={sharedStyle.spacingTopBottom}>
        {lineChartGraphSections.map(({ title, buttonText, chartTitle, key, path }) => {
          const typedKey = key as 'remoteManagement' | 'quickSpec';
          return (
            <Grid item xs={12} md={6} key={key}>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography variant="h6">{title}</Typography>
                <Button size="small" variant="text" endIcon={<ArrowForwardIosIcon />} onClick={handleNavigation(path)}>
                  {buttonText}
                </Button>
              </Box>
              <LineChartGraph
                title={chartTitle}
                dataItems={filterDataByTimeframe(t, typedKey, timeframes[typedKey])}
                onTimeframeChange={(newTimeframe) => handleTimeframeChange(typedKey, newTimeframe)}
              />
            </Grid>
          );
        })}
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box justifyContent="space-between" display="flex" width="100%">
            <Typography variant="h6">{simBillingTitle}</Typography>
            <Button
              size="small"
              variant="text"
              endIcon={<ArrowForwardIosIcon />}
              onClick={handleNavigation('simbilling')}
            >
              {simBillingButtonStr}
            </Button>
          </Box>
          <BarChartGraph dataItems={getSimBillingData(t)} title={simBillingChartTitle} />
        </Grid>
      </Grid>
    </>
  );
};
export default DashboardStatsView;
