import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

// Components
import { UnactivatedLocalLicense } from 'features/LicenseManagement/components/license/LicenseDetail/Unactivated/Local/UnactivatedLocalLicenseDetail';
import { UnactivatedCloudLicense } from 'features/LicenseManagement/components/license/LicenseDetail/Unactivated/Cloud/UnactivatedCloudLicenseDetail';
import { ActivatedLicenseDetail } from 'features/LicenseManagement/components/license/LicenseDetail/Activated/ActivatedLicenseDetail';
// Hooks
import { useDeploymentById } from 'features/LicenseManagement/hooks/useDeployments';
import { useCurrentBranchWithReactQuery } from 'features/LicenseManagement/hooks/useCurrentBranchWithReactQuery';
import { useLicenseByIdByLongPolling } from 'features/LicenseManagement/hooks/useLicense';
// Shared Components
import LoadingSpinner from 'features/LicenseManagement/components/common/LoadingSpinner';
import ErrorAlert from 'features/LicenseManagement/components/common/ErrorAlert';
import { LicenseManagementCommonStyles } from 'features/LicenseManagement/styles/CommonStyles';
import Header from 'features/LicenseManagement/components/common/Header';
import { PermissionsContextType } from 'permissions/utils';
import { usePermission } from 'context/PermissionContext';
// Types
import { LicenseStatus, DeploymentType } from 'features/LicenseManagement/components/common/Types';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'store/slices/usersSlice';
import { useLazyGetUserBillingInformationQuery } from 'services/aiphoneCloud';

// #endregion

const LicenseDetails = () => {
  const { t } = useTranslation('acNioLicenseManagement');
  const { deploymentId } = useParams();
  const { isAllowedTo } = usePermission();
  const { data: currentBranch } = useCurrentBranchWithReactQuery();
  const currentUser = useSelector(getCurrentUser);
  const userId = localStorage.getItem('userId');
  const [getUserBillingInformation] = useLazyGetUserBillingInformationQuery();
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const urlLicenseId = searchParams.get('licenseId');
  const aiphoneBranchPublicId = searchParams.get('aiphoneBranchPublicId');

  const renewLocalLicense = location.state?.renewLocalLicense || false;
  const rearamLicense = location.state?.rearmLicense || false;

  const isFromStripeCheckout = !!urlLicenseId && !!aiphoneBranchPublicId;
  const licenseQuery = useLicenseByIdByLongPolling(urlLicenseId || '', aiphoneBranchPublicId);

  const license = isFromStripeCheckout ? licenseQuery.data : null;
  const isLoadingLicense = isFromStripeCheckout ? licenseQuery.isLoading : false;
  const isFetchLicenseError = isFromStripeCheckout ? licenseQuery.isError : false;
  const refetchLicense = isFromStripeCheckout ? licenseQuery.refetch : () => Promise.resolve();

  const shouldFetchDeployment = !isFromStripeCheckout || (!!license && !isFetchLicenseError);
  const deploymentQuery = useDeploymentById(
    deploymentId || '',
    aiphoneBranchPublicId || undefined,
    shouldFetchDeployment
  );

  useEffect(() => {
    if (isFromStripeCheckout && currentUser && !currentUser.stripeCustomerId) {
      const fetchBillingInfo = async () => {
        try {
          await getUserBillingInformation(userId).unwrap();
        } catch (error) {
          // Absorb error
        }
      };

      fetchBillingInfo();
    }
  }, [isFromStripeCheckout]);

  const {
    data: deployment,
    isLoading: isLoadingDeployment,
    isError: isFetchDeploymentError,
    refetch: refetchDeployment
  } = deploymentQuery;

  const [status, setStatus] = useState(LicenseStatus.Unknown);
  const canViewDeployment = isAllowedTo('deployment:view', currentBranch?.publicId, PermissionsContextType.BRANCH);

  useEffect(() => {
    if (deployment) {
      setStatus(deployment.licenseStatus);
    }
  }, [deployment]);
  // #endregion

  // #region Conditional Rendering Logic
  if (!currentBranch) return <LoadingSpinner />;
  if (!canViewDeployment) return <Typography>{t('Missing_Permission_To_View_Deployment')}</Typography>;
  if (!deploymentId) return <Typography>{t('Error_Loading_Deployment_ID')}</Typography>;

  if (isFromStripeCheckout && isLoadingLicense) return <LoadingSpinner />;

  if (isFromStripeCheckout && isFetchLicenseError) {
    return <ErrorAlert errorMessage={t('Error_Loading_Activated_License')} onRetry={refetchLicense} />;
  }

  if (isLoadingDeployment) return <LoadingSpinner />;

  if (isFetchDeploymentError || !deployment) {
    return <ErrorAlert errorMessage={t('Error_Loading_Deployment')} onRetry={refetchDeployment} />;
  }
  // #endregion

  // Determine which component to render based on status and deploymentType
  const renderContent = () => {
    if (renewLocalLicense && deployment?.deploymentType === DeploymentType.Local) {
      return (
        <UnactivatedLocalLicense
          accountNumber={deployment.hartmannAccountNumber}
          deployment={deployment}
          renewLicense={true}
        />
      );
    }
    if (rearamLicense && deployment?.deploymentType === DeploymentType.Local && status === LicenseStatus.Activated) {
      return (
        <UnactivatedLocalLicense
          accountNumber={deployment.hartmannAccountNumber}
          deployment={deployment}
          rearmLicense={true}
        />
      );
    }
    if (status === LicenseStatus.Unactivated) {
      if (deployment?.deploymentType === DeploymentType.Local) {
        return <UnactivatedLocalLicense accountNumber={deployment.hartmannAccountNumber} deployment={deployment} />;
      } else if (deployment.deploymentType === DeploymentType.Cloud) {
        return <UnactivatedCloudLicense deployment={deployment} />;
      }
    } else if (
      status === LicenseStatus.Activated ||
      status === LicenseStatus.Expired ||
      status === LicenseStatus.Pending
    ) {
      return <ActivatedLicenseDetail deployment={deployment} />;
    }
    return null;
  };

  return (
    <Box sx={LicenseManagementCommonStyles.pageHeaderContainer}>
      <Header deployment={deployment} status={status} />
      {renderContent()}
    </Box>
  );
};

export default LicenseDetails;
