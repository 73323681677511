import { Box, List, Tab, Tabs } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { IDevice } from 'store/slices/devicesSlice';
import DeviceRelayItem from './DeviceRelayItem';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';

interface IDeviceRelaysProps {
  handleSelectRelay: (id: string) => void;
  selectedRelayId: string;
  handleTabChange: (newValue: string) => void;
}

const DeviceRelays = ({ handleSelectRelay, selectedRelayId, handleTabChange }: IDeviceRelaysProps) => {
  const selectedDevicePublicId: string = useSelector((state: RootState) => state.devices.SelectedDevice) || '';
  const selectedDevice = useSelector((state: RootState) => state.devices.DeviceList[selectedDevicePublicId]);
  const [selectedOption, setSelectedOption] = React.useState('output');
  const noRelays = 'No relays found'; // Temp placeholder because this was previously undefined

  const getOutputList = (device: IDevice) => {
    if (!device?.contactOutputList?.length) {
      return <Box sx={styles.noRelaysWrapper}>{noRelays}</Box>;
    }

    return device.contactOutputList.map((relay) => {
      return (
        <DeviceRelayItem
          key={relay.publicId}
          relay={relay}
          handleSelectRelay={handleSelectRelay}
          isSelected={selectedRelayId === relay.publicId}
        />
      );
    });
  };

  const getInputList = (device: IDevice) => {
    if (!device.contactInputList?.length) {
      return <Box sx={styles.noRelaysWrapper}>{noRelays}</Box>;
    }

    return device.contactInputList.map((relay) => {
      return (
        <DeviceRelayItem
          key={relay.publicId}
          relay={relay}
          handleSelectRelay={handleSelectRelay}
          isSelected={selectedRelayId === relay.publicId}
        />
      );
    });
  };

  const onTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedOption(newValue);
    handleTabChange(newValue);
  };

  return (
    <Box sx={styles.deviceRelaysWrapper}>
      <Box sx={styles.deviceRelaysListWrapper}>
        <Box sx={styles.relaySelectWrapper}>
          <Box sx={styles.centerContent}>
            <Tabs value={selectedOption} onChange={onTabChange} aria-label="Input output tabs">
              <Tab icon={<ArrowCircleUpIcon />} label="Outputs" value="output" />
              <Tab icon={<ArrowCircleDownIcon />} label="Inputs" value="input" />
            </Tabs>
          </Box>
        </Box>
        <List sx={styles.relayListWrapper}>
          <Box>{selectedOption === 'output' ? getOutputList(selectedDevice) : getInputList(selectedDevice)}</Box>
        </List>
      </Box>
    </Box>
  );
};

const styles = {
  deviceRelaysWrapper: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center'
  },
  deviceRelaysListWrapper: {
    width: '360px'
  },
  relaySelectWrapper: {
    display: 'flex',
    position: 'sticky',
    top: 0,
    left: 0,
    width: '100%',
    height: '75px'
  },
  relayListWrapper: {
    marginBottom: '25px'
  },
  noRelaysWrapper: {
    padding: 3
  },
  centerContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  }
};

export default DeviceRelays;
