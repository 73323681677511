import EngineeringIcon from '@mui/icons-material/Engineering';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Button, Drawer, IconButton, Toolbar } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import LanguageSelector from 'localization/LanguageSelector';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { resetUsersState } from 'store';
import { getCurrentUser } from 'store/slices/usersSlice';
import GatewayTest from 'test/TestGateway';
import CurrentBranchSelect from './components/CurrentBranchSelect';

interface AppHeaderProps {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  toggled: boolean;
  setToggled: (toggled: boolean) => void;
}

const AppHeader: React.FC<AppHeaderProps> = ({ collapsed, setCollapsed, toggled, setToggled }) => {
  const { t } = useTranslation();
  const logoutStr = t('Shared.LogOut');

  const [isGatewayDrawerOpen, setIsGatewayDrawerOpen] = useState(false);
  const currentUser = useSelector(getCurrentUser);
  const hasGlobalRole = currentUser?.permissions.global.roleList !== null;
  const hasBranchRole = currentUser?.permissions.branch !== null;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMenuClick = () => {
    setCollapsed(!collapsed);
    if (window.innerWidth < 600) {
      setToggled(!toggled);
    }
  };

  const handleLogout = () => {
    dispatch(resetUsersState());
    localStorage.clear();
    sessionStorage.clear();
    navigate('/');
    window.location.reload();
  };

  return (
    <AppBar position="sticky" sx={styles.appBar}>
      <Toolbar sx={styles.toolBar}>
        <IconButton onClick={handleMenuClick}>
          <MenuIcon fontSize="medium" htmlColor="white" />
        </IconButton>
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Link to="/">
            <Box component="img" sx={styles.appLogo} src="/AiphoneCloud-Logo_Header.png" />
          </Link>
        </Box>
        {hasBranchRole && (
          <Box sx={styles.branchSelector}>
            <CurrentBranchSelect />
          </Box>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <LanguageSelector />
        {hasGlobalRole && (
          <Tooltip title={t('Aiphone_TechTools')}>
            <IconButton
              aria-label={t('Aiphone_TechTools')}
              onClick={() => setIsGatewayDrawerOpen(!isGatewayDrawerOpen)}
            >
              <EngineeringIcon fontSize="medium" htmlColor="white" />
            </IconButton>
          </Tooltip>
        )}
        <Link to="/">
          <Button startIcon={<LogoutIcon htmlColor="white" />} onClick={handleLogout} sx={styles.appBarButton}>
            {logoutStr.toLocaleUpperCase()}
          </Button>
        </Link>
      </Toolbar>
      <Drawer anchor="right" open={isGatewayDrawerOpen} onClose={() => setIsGatewayDrawerOpen(false)}>
        <Box sx={styles.drawer}>
          <GatewayTest />
        </Box>
      </Drawer>
    </AppBar>
  );
};

const styles = {
  branchSelector: {
    width: '20%',
    marginLeft: '2rem'
  },
  appBar: {
    bgcolor: 'primary',
    paddingX: '24px',
    paddingY: '10px',
    height: '64px',
    display: 'flex',
    justifyContent: 'center'
  },
  appBarButton: {
    color: 'primary.contrastText'
  },
  appLogo: {
    ml: 2,
    cursor: 'pointer',
    width: '10rem',
    marginTop: '.5rem'
  },
  toolBar: {
    paddingLeft: '12px',
    '@media (min-width: 600px)': {
      paddingLeft: '12px'
    }
  },
  drawer: {
    padding: '2rem',
    width: '20rem'
  }
};

export default AppHeader;
