import { useState, useEffect } from 'react';
import { ErrorType, ErrorTypeValues } from 'shared/api/ApiError';
import LoginPanel from 'features/RemoteManagement/SiteDashboard/Dialogs/LoginPanel';
import Dashboard from 'features/SimBilling/Components/Entrypoints/Home/Children/DashBoard';
import Spinner from 'features/SimBilling/Components/UiParts/Spinner';
import { useAclAuth, useReduxAclUser, useProcessAclUser } from 'features/SimBilling/Hooks';
import useRefetchData from 'features/Dashboard/Hooks/useRefetchData';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import SimBillingWithNoBranchRole from './SimBillingWithNoBranchRole';
import { hasCapability, PermissionsContextType } from 'permissions/utils';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'store/slices/usersSlice';
import { RootState } from 'store';
import useSharedStyles from 'styles/useSharedStyles';

const SimBilling = () => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isUserDataFetched, setIsUserDataFetched] = useState<boolean>(false);
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const sharedStyle = useSharedStyles();
  const descriptionStr = t('SimBilling.Description');
  const loginTitleStr = t('SimBilling.LoginTitle');

  const currentUser = useSelector(getCurrentUser);
  const currentBranch = useSelector((state: RootState) => state.branches.currentBranch);
  const canAccessSimBilling = currentUser
    ? hasCapability('simBilling', currentBranch?.publicId, PermissionsContextType.BRANCH, currentUser)
    : false;

  const apiContext = {
    loading,
    setLoading,
    errorMessage,
    setErrorMessage
  };

  /**If, for some reason, the user and organization info isn't in the Redux store, we will refetch it and save it again.*/
  useRefetchData();

  const { aclToken, aclUserName } = useAclAuth();
  const { user, error } = useReduxAclUser();
  const { initialize, processUserData } = useProcessAclUser();

  const fetchAndUpsertACLUser = async (userName: string, token: string) => {
    await initialize();
    setIsUserDataFetched(true);
    localStorage.setItem('acltoken', token);
    localStorage.setItem('aclUserName', userName);
    await processUserData(userName, token);
  };

  const syncCurrentAclUser = async () => {
    if (aclUserName && aclToken) {
      initialize();
      await processUserData(aclUserName, aclToken);
    } else {
      setShouldNavigate(true);
    }
  };

  useEffect(() => {
    const isValidErrorKey = (key: any): key is keyof typeof ErrorTypeValues => {
      return key in ErrorTypeValues;
    };

    const errorMessage =
      error && isValidErrorKey(error)
        ? ErrorTypeValues[error].message1
        : ErrorTypeValues[ErrorType.UNEXPECTED].message1;

    if (error) {
      setErrorMessage(errorMessage);
      setLoading(false);
      setShouldNavigate(true);
    }
  }, [error]);

  const loginPanelElem: React.ReactNode = (
    <Grid container sx={styles.parentGrid}>
      <Grid item>
        <Card variant="outlined" sx={sharedStyle.cardContainer}>
          <CardContent sx={sharedStyle.cardContainer.cardContent}>
            <Box>
              <Typography variant="cardTitle">{loginTitleStr}</Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {descriptionStr}
              </Typography>
            </Box>
            <LoginPanel setUsernameAndToken={fetchAndUpsertACLUser} apiContext={apiContext} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );

  if (!canAccessSimBilling) {
    return <SimBillingWithNoBranchRole />;
  }

  if (shouldNavigate) {
    return loginPanelElem;
  }

  return (
    <>
      {aclToken && aclUserName ? (
        user ? (
          <Dashboard />
        ) : isUserDataFetched ? (
          loginPanelElem
        ) : (
          <Spinner onMount={syncCurrentAclUser} />
        )
      ) : (
        loginPanelElem
      )}
    </>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  parentGrid: {
    justifyContent: 'center'
  }
};

export default SimBilling;
