import React from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface PricingRowProps {
  label: string;
  quantity: number;
  unitPrice: number;
  total: number;
}

interface DoorPriceDetails {
  qty: number;
  price: number;
  total: number;
  discount: number;
  rangeTotalDiscount: number;
}

interface PackageDetailProps {
  doorPriceDetails: DoorPriceDetails[];
  numCabs: number;
  numExpanders: number;
  expandersCost: number;
  cabsCost: number;
  totalDiscount: number;
  grandTotal: number;
}
const PricingRow: React.FC<PricingRowProps> = ({ label, quantity, unitPrice, total }) => (
  <TableRow>
    <TableCell>{label}</TableCell>
    <TableCell>{quantity}</TableCell>
    <TableCell>${unitPrice}</TableCell>
    <TableCell>${total}</TableCell>
  </TableRow>
);

const PackageDetail: React.FC<PackageDetailProps> = ({
  doorPriceDetails,
  numCabs,
  numExpanders,
  expandersCost,
  cabsCost,
  totalDiscount,
  grandTotal
}) => {
  const { t } = useTranslation('acNioLicenseManagement');

  return (
    <Box sx={styles.tableWrapper}>
      <Typography variant="h6" sx={styles.tableHeading}>
        {t('Package_Value')}
      </Typography>
      <Typography sx={styles.tableSubheading}>{t('Package_Value_Desc')}</Typography>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>{t('Items')}</strong>
              </TableCell>
              <TableCell>
                <strong>{t('Quantity')}</strong>
              </TableCell>
              <TableCell>
                <strong>{t('Unit_Price')}</strong>
              </TableCell>
              <TableCell>
                <strong>{t('Total')}</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {doorPriceDetails.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{t('Doors')}</TableCell>
                <TableCell>
                  <Typography>{item.qty}</Typography>
                  {item.discount > 0 && <Typography sx={styles.discountText}>{t('Discount_Applied')}</Typography>}
                </TableCell>
                <TableCell>
                  <Typography>${item.price}</Typography>
                  {item.discount > 0 && <Typography sx={styles.discountText}>{`-$${item.discount}`}</Typography>}
                </TableCell>
                <TableCell>
                  <Typography>${item.total}</Typography>
                  {item.discount > 0 && (
                    <Typography sx={styles.discountText}>{`-$${item.rangeTotalDiscount}`}</Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
            {expandersCost > 0 && (
              <PricingRow
                label={t('I/O_Expanders')}
                quantity={numExpanders}
                unitPrice={expandersCost / numExpanders}
                total={expandersCost}
              />
            )}
            {cabsCost > 0 && (
              <PricingRow
                label={t('Elevator_Cabs')}
                quantity={numCabs}
                unitPrice={cabsCost / numCabs}
                total={cabsCost}
              />
            )}
          </TableBody>
          <TableBody>
            {totalDiscount >= 1 && (
              <TableRow>
                <TableCell colSpan={3} align="left">
                  {t('Total_Discount')}
                </TableCell>
                <TableCell sx={styles.discountText}>-${totalDiscount}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell colSpan={3} align="left">
                <strong>{t('Grand_Total')}</strong>
              </TableCell>
              <TableCell>${grandTotal}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const styles = {
  tableWrapper: {
    flex: 2,
    marginTop: -2,
    border: '1px solid lightgray',
    borderRadius: 2,
    padding: 1
  },
  tableHeading: {
    textAlign: 'center',
    fontWeight: 'bold'
  },
  tableSubheading: {
    textAlign: 'center',
    padding: 1,
    borderBottom: '1px solid lightgray'
  },
  discountText: {
    color: '#006400'
  }
};

export default PackageDetail;
