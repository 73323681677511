import React, { createContext, ReactNode, useState, useCallback, useEffect } from 'react';
import { useGetSitesQuery } from '../services/aiphoneCloud';

interface SiteProviderProps {
  children?: ReactNode;
}

interface ISiteContext {
  siteName: string;
  setSiteName: (newState: string) => void;
  sites: Record<string, any> | null;
  loading: boolean;
  error: any;
  getCachedBranchIds: (contextUuid: string) => any | null;
  cacheBranchData: (contextUuid: string, dealerBranchPublicId: string, propertyManagerBranchPublicId: string) => void;
  sitesQuery: any; // Full response from useGetSitesQuery
  publicId: string;
  setPublicId: (newState: string) => void;
}

const initialValue = {
  siteName: '',
  setSiteName: () => undefined,
  sites: null,
  loading: false,
  error: null,
  getCachedBranchIds: () => null,
  cacheBranchData: () => {
    console.warn('cacheBranchData is not implemented yet'); // Provide a placeholder function to avoid ESLint errors
  },
  sitesQuery: null,
  publicId: '',
  setPublicId: () => undefined
};

const SiteContext = createContext<ISiteContext>(initialValue);

export interface CustomMessageEvent<T> {
  absoluteTimeStamp: number;
  timeStamp?: number;
  data: T;
}

const SiteProvider = ({ children }: SiteProviderProps) => {
  const [siteName, setSiteName] = useState(initialValue.siteName);
  const [publicId, setPublicId] = useState(initialValue.publicId);
  const [branchCache, setBranchCache] = useState<Record<string, any>>({}); // Cache for branch data
  const sitesQuery = useGetSitesQuery({}); // Use full response here
  const { data, error, isLoading } = sitesQuery;

  // Function to cache branch data
  const cacheBranchData = useCallback(
    (contextUuid: string, dealerBranchPublicId: string, propertyManagerBranchPublicId: string) => {
      setBranchCache((prevCache) => ({
        ...prevCache,
        [contextUuid]: { dealerBranchPublicId, propertyManagerBranchPublicId }
      }));
    },
    []
  );

  // Function to retrieve cached branch data
  const getCachedBranchIds = useCallback(
    (contextUuid: string) => {
      return branchCache[contextUuid] || null;
    },
    [branchCache]
  );

  // Automatically cache data when sites are fetched
  useEffect(() => {
    if (data) {
      Object.keys(data).forEach((contextUuid) => {
        const site = data[contextUuid];
        if (site?.dealerBranchPublicId && site?.propertyManagerBranchPublicId) {
          cacheBranchData(contextUuid, site.dealerBranchPublicId, site.propertyManagerBranchPublicId);
        }
      });
    }
  }, [data, cacheBranchData]);

  return (
    <SiteContext.Provider
      value={{
        siteName,
        setSiteName,
        sites: data ?? null,
        loading: isLoading,
        error,
        getCachedBranchIds,
        cacheBranchData,
        sitesQuery,
        publicId,
        setPublicId
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};

export { SiteContext, SiteProvider };
