import { blue, green, grey, orange, purple } from '@mui/material/colors';
import { barChartDataItem } from 'shared/types/chartDataItem';

// TODO: Remove this file when we implement the real data fetching

export const getMonthLabels = (t) =>
  Object.fromEntries(
    [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ].map((month) => [month, t(`DashboardStats.MonthLabels.${month}`)])
  );

export const getRemoteManagementData = (t) => {
  const MonthLabelStr = getMonthLabels(t);

  const ixgStr = t('DashboardStats.DeviceLabels.IXG');
  const acStr = t('DashboardStats.DeviceLabels.AC');
  const gtStr = t('DashboardStats.DeviceLabels.GT');

  const remoteManagementData = [
    {
      datasetLabel: ixgStr,
      lineColor: blue[500],
      data: [
        { label: MonthLabelStr.January, value: 20 },
        { label: MonthLabelStr.February, value: 30 },
        { label: MonthLabelStr.March, value: 40 },
        { label: MonthLabelStr.April, value: 50 },
        { label: MonthLabelStr.May, value: 50 },
        { label: MonthLabelStr.June, value: 60 },
        { label: MonthLabelStr.July, value: 70 },
        { label: MonthLabelStr.August, value: 75 },
        { label: MonthLabelStr.September, value: 78 },
        { label: MonthLabelStr.October, value: 80 },
        { label: MonthLabelStr.November, value: 90 },
        { label: MonthLabelStr.December, value: 100 }
      ]
    },
    {
      datasetLabel: acStr,
      lineColor: green[400],
      data: [
        { label: MonthLabelStr.January, value: 0 },
        { label: MonthLabelStr.February, value: 10 },
        { label: MonthLabelStr.March, value: 30 },
        { label: MonthLabelStr.April, value: 20 },
        { label: MonthLabelStr.May, value: 30 },
        { label: MonthLabelStr.June, value: 35 },
        { label: MonthLabelStr.July, value: 40 },
        { label: MonthLabelStr.August, value: 40 },
        { label: MonthLabelStr.September, value: 50 },
        { label: MonthLabelStr.October, value: 60 },
        { label: MonthLabelStr.November, value: 70 },
        { label: MonthLabelStr.December, value: 80 }
      ]
    },
    {
      datasetLabel: gtStr,
      lineColor: purple[400],
      data: [
        { label: MonthLabelStr.January, value: 10 },
        { label: MonthLabelStr.February, value: 15 },
        { label: MonthLabelStr.March, value: 32 },
        { label: MonthLabelStr.April, value: 40 },
        { label: MonthLabelStr.May, value: 30 },
        { label: MonthLabelStr.June, value: 20 },
        { label: MonthLabelStr.July, value: 10 },
        { label: MonthLabelStr.August, value: 10 },
        { label: MonthLabelStr.September, value: 30 },
        { label: MonthLabelStr.October, value: 10 },
        { label: MonthLabelStr.November, value: 90 },
        { label: MonthLabelStr.December, value: 80 }
      ]
    }
  ];
  return remoteManagementData;
};

export const getQuickSpecData = (t) => {
  const MonthLabelStr = getMonthLabels(t);

  const ixgStr = t('DashboardStats.DeviceLabels.IXG');
  const gtStr = t('DashboardStats.DeviceLabels.GT');
  const joStr = t('DashboardStats.DeviceLabels.JO');

  const quickSpecData = [
    {
      datasetLabel: ixgStr,
      lineColor: blue[500],
      data: [
        { label: MonthLabelStr.January, value: 20 },
        { label: MonthLabelStr.February, value: 30 },
        { label: MonthLabelStr.March, value: 40 },
        { label: MonthLabelStr.April, value: 50 },
        { label: MonthLabelStr.May, value: 50 },
        { label: MonthLabelStr.June, value: 60 },
        { label: MonthLabelStr.July, value: 70 },
        { label: MonthLabelStr.August, value: 75 },
        { label: MonthLabelStr.September, value: 78 },
        { label: MonthLabelStr.October, value: 80 },
        { label: MonthLabelStr.November, value: 90 },
        { label: MonthLabelStr.December, value: 100 }
      ]
    },
    {
      datasetLabel: gtStr,
      lineColor: purple[400],
      data: [
        { label: MonthLabelStr.January, value: 0 },
        { label: MonthLabelStr.February, value: 10 },
        { label: MonthLabelStr.March, value: 30 },
        { label: MonthLabelStr.April, value: 20 },
        { label: MonthLabelStr.May, value: 30 },
        { label: MonthLabelStr.June, value: 35 },
        { label: MonthLabelStr.July, value: 40 },
        { label: MonthLabelStr.August, value: 40 },
        { label: MonthLabelStr.September, value: 50 },
        { label: MonthLabelStr.October, value: 60 },
        { label: MonthLabelStr.November, value: 70 },
        { label: MonthLabelStr.December, value: 80 }
      ]
    },
    {
      datasetLabel: joStr,
      lineColor: orange[400],
      data: [
        { label: MonthLabelStr.January, value: 10 },
        { label: MonthLabelStr.February, value: 15 },
        { label: MonthLabelStr.March, value: 32 },
        { label: MonthLabelStr.April, value: 40 },
        { label: MonthLabelStr.May, value: 30 },
        { label: MonthLabelStr.June, value: 20 },
        { label: MonthLabelStr.July, value: 10 },
        { label: MonthLabelStr.August, value: 10 },
        { label: MonthLabelStr.September, value: 30 },
        { label: MonthLabelStr.October, value: 10 },
        { label: MonthLabelStr.November, value: 90 },
        { label: MonthLabelStr.December, value: 80 }
      ]
    }
  ];
  return quickSpecData;
};

export const getSimBillingData = (t) => {
  const simBillingChartLabel1 = t('DashboardStats.SimBilling.Labels.WithSimSubsc');
  const simBillingChartLabel2 = t('DashboardStats.SimBilling.Labels.WithoutSimSubsc');
  const simBillingNoSiteLabel = t('DashboardStats.SimBilling.Labels.SiteWithoutSim');
  const simBilling1GBLabel = t('DashboardStats.SimBilling.Labels.1GB');
  const simBilling2GBLabel = t('DashboardStats.SimBilling.Labels.2GB');
  const simBilling5GBLabel = t('DashboardStats.SimBilling.Labels.5GB');

  const simBillingData: barChartDataItem[] = [
    {
      category: simBillingChartLabel1,
      labels: [simBilling1GBLabel, simBilling2GBLabel, simBilling5GBLabel],
      values: [30, 20, 10],
      colors: [blue[100], blue[300], blue[500]]
    },
    {
      category: simBillingChartLabel2,
      labels: [simBillingNoSiteLabel],
      values: [50],
      colors: [grey[400]]
    }
  ];
  return simBillingData;
};
