import { Alert, Box, Grid, Typography } from '@mui/material';
import useSharedStyles from 'styles/useSharedStyles';

const UpdateGatewayFirmware = () => {
  const sharedStyle = useSharedStyles();
  const updateGatewayFirmwareTitleStr = 'Mandatory Firmare Update';
  const updateGatewayFirmwareDescriptionStr =
    'The Cloud Gateway firmware must be updated before you can continue using Remote Management. This will take between 3-5 minutes to complete.';
  const updateGatewayFirmwareCtaStr =
    'Click Update Firmware below. After the Cloud Gateway has restarted, click Check Status to verify the update.';

  return (
    <Box sx={[sharedStyle.infoBox, sharedStyle.common.width.full, sharedStyle.common.padding.xs]}>
      <Grid container sx={sharedStyle.gridContainer}>
        <Grid item sx={[sharedStyle.gridContainer, sharedStyle.common.gap.xs]}>
          <Typography variant="h6">{updateGatewayFirmwareTitleStr}</Typography>
          <Typography variant="body2" color="textSecondary">
            {updateGatewayFirmwareDescriptionStr}
          </Typography>
        </Grid>
        <Grid item sx={[sharedStyle.gridContainer, sharedStyle.common.gap.xs]}>
          <Alert severity="warning">{updateGatewayFirmwareCtaStr}</Alert>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UpdateGatewayFirmware;
