import { useEffect, useMemo, useState } from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCurrentUser, IUserPermissions } from 'store/slices/usersSlice';
import useRefetchData from './Hooks/useRefetchData';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import { useTranslation } from 'react-i18next';
import MFASettingsDialog from 'shared/components/dialogs/MFASettingsDialog';
import { FeatureCard } from './FeatureCard';
import useSharedStyles from 'styles/useSharedStyles';
import DashboardStatsView from './DashboardStatsView';
import { RootState } from 'store';
import { BranchAssociateDashboardView } from './BranchAssociateDashboard';
import LoadingSpinner from 'shared/components/spinner/LoadingSpinner';
import { hasCapability, PermissionsContextType } from 'permissions/utils';
import { useCanAccessRemoteManagement } from 'features/RemoteManagement/Hooks/useCanAccessRemoteManagement';
import { checkIsProdEnv } from 'shared/utils/EnvUtils';

export const hasNonAssociateAccessInBranch = (roleList: any): boolean => {
  const allowedRoles = ['Member', 'Manager', 'Admin'];
  return roleList.some((role: any) => allowedRoles.includes(role.roleName));
};

const Home = () => {
  useRefetchData();
  const navigate = useNavigate();
  const sharedStyle = useSharedStyles();
  const currentUser = useSelector(getCurrentUser);

  const { t } = useTranslation(); // Use the useTranslation hook for translations

  const title = t('MainDashboard.Title');
  const descriptionStr = t('MainDashboard.Description');
  /*Feature Cards Strings*/
  const quikSpecTitle = t('MainDashboard.FeatureCards.QuikSpec.Title');
  const quikSpecDescStr = t('MainDashboard.FeatureCards.QuikSpec.Description');
  const quickSpecImage = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/quickspecLogo.jpeg`;

  const remoteManagementTitle = t('MainDashboard.FeatureCards.RemoteManagement.Title');
  const remoteManagementDescStr = t('MainDashboard.FeatureCards.RemoteManagement.Description');
  const remoteManagementImage = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/remote-management.png`;
  const remoteManagementButtonText = t('MainDashboard.FeatureCards.Buttons.GetStarted');

  const simBillingTitle = t('MainDashboard.FeatureCards.SimBilling.Title');
  const simBillingDescStr = t('MainDashboard.FeatureCards.SimBilling.Description');
  const simBillingImage = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/sim-billing.png`;
  const simBillingButtonText = t('MainDashboard.FeatureCards.Buttons.ManageData');

  const acNioTitle = t('MainDashboard.FeatureCards.ACNio.Title');
  const acNioDescStr = t('MainDashboard.FeatureCards.ACNio.Description');
  const acNioImage = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/acNioImage.png`;

  const previewButtonText = t('MainDashboard.FeatureCards.Buttons.Preview');
  const quikSpecButtonText = t('MainDashboard.FeatureCards.Buttons.Design');

  /*State*/
  const [isMFASettingsDialogOpen, setIsMFASettingsDialogOpen] = useState(false);

  /*Permissions*/
  const currentBranch = useSelector((state: RootState) => state.branches.currentBranch);
  const canAccessRemoteManagement = useCanAccessRemoteManagement();
  const canAccessSimBilling = currentUser
    ? hasCapability('simBilling', currentBranch?.publicId, PermissionsContextType.BRANCH, currentUser)
    : false;
  const permissions: IUserPermissions | undefined = currentUser?.permissions;

  const isBranchLoading = useSelector((state: RootState) => state.branches.Loading);
  const currentBranchPublicId = currentBranch?.publicId;
  const currentBranchPermission =
    currentBranchPublicId && permissions?.branch ? permissions.branch[currentBranchPublicId] : undefined;
  const hasNonAssociateAccessInCurrentBranch =
    currentBranchPermission && currentBranchPermission.roleList
      ? hasNonAssociateAccessInBranch(currentBranchPermission.roleList)
      : false;

  const hasAssociateRole =
    currentBranchPermission && currentBranchPermission.roleList.some((role: any) => role.roleName === 'Associate');

  const hasGlobalRole = Boolean(permissions?.global?.roleList);

  const isRoleDataLoading =
    isBranchLoading || (permissions && permissions.branch && !hasGlobalRole && currentBranchPermission === undefined);

  const isProdEnv = checkIsProdEnv();

  const shouldShowFeatureCards =
    isProdEnv || !(hasAssociateRole || hasNonAssociateAccessInCurrentBranch || hasGlobalRole);

  /*Handlers*/
  const handleSimBillingClick = () => {
    navigate('/simbilling');
  };
  const handleRemoteManagementClick = () => {
    navigate('/remotemanagement');
  };
  const handleQuickSpecClick = () => {
    navigate('/quikspec');
  };
  const handleAcNioClick = () => {
    navigate('/acniolicensing');
  };

  useEffect(() => {
    const TIMEOUT_DURATION = 1000;
    const mfaDialogTimeout = setTimeout(() => {
      if (currentUser?.mfaEnabled === null) {
        setIsMFASettingsDialogOpen(true);
      }
    }, TIMEOUT_DURATION);
    return () => clearTimeout(mfaDialogTimeout);
  }, []);

  const features = useMemo(
    () => [
      {
        title: quikSpecTitle,
        description: quikSpecDescStr,
        buttonText: quikSpecButtonText,
        imageSrc: quickSpecImage,
        imageStyle: styles.quickSpecImage,
        onClick: handleQuickSpecClick
      },
      {
        title: remoteManagementTitle,
        description: remoteManagementDescStr,
        buttonText: canAccessRemoteManagement ? remoteManagementButtonText : previewButtonText,
        imageSrc: remoteManagementImage,
        onClick: handleRemoteManagementClick
      },
      {
        title: simBillingTitle,
        description: simBillingDescStr,
        buttonText: canAccessSimBilling ? simBillingButtonText : previewButtonText,
        imageSrc: simBillingImage,
        onClick: handleSimBillingClick
      },
      {
        title: acNioTitle,
        description: acNioDescStr,
        buttonText: previewButtonText,
        imageSrc: acNioImage,
        onClick: handleAcNioClick,
        isNewFeature: true
      }
    ],
    [canAccessRemoteManagement, canAccessSimBilling]
  );

  if (isRoleDataLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Container sx={{ ...sharedStyle.mainContainer, gap: '0', maxHeight: '300px' }}>
      <Box sx={styles.titleContainer}>
        <Typography variant="h5">{title}</Typography>
      </Box>
      {shouldShowFeatureCards ? (
        <>
          <Typography variant="h6" sx={sharedStyle.spacingBottom}>
            {descriptionStr}
          </Typography>
          <Grid container spacing={4}>
            {features.map((feature, index) => (
              <Grid item xs={12} sm={6} md={6} key={index}>
                <FeatureCard
                  title={feature.title}
                  description={feature.description}
                  buttonText={feature.buttonText}
                  imageSrc={feature.imageSrc}
                  onClick={feature.onClick}
                  isNewFeature={feature.isNewFeature}
                  imageStyle={feature.imageStyle}
                />
              </Grid>
            ))}
          </Grid>
        </>
      ) : hasAssociateRole ? (
        <BranchAssociateDashboardView />
      ) : (
        <DashboardStatsView />
      )}

      {
        // The mfaEnabled can be null only for newly registered users. In this case, we need to show the MFA settings dialog.
        // After that, mfaEnabled will be set to either true or false.
        currentUser?.mfaEnabled === null && (
          <MFASettingsDialog
            dialogIsOpen={isMFASettingsDialogOpen}
            setDialogIsOpen={setIsMFASettingsDialogOpen}
            setBaseCondition={true}
          />
        )
      }
    </Container>
  );
};

const styles = {
  titleContainer: {
    marginBottom: '1rem'
  },
  subheading: {
    color: 'text.secondary'
  },
  quickSpecImage: {
    width: '300px',
    height: '200px'
  }
};

export default Home;
