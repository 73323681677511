import { Add, Edit } from '@mui/icons-material';
import { Grid, Box, Button } from '@mui/material';
import { GridColDef, DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { RemoteManagementContext } from 'context/RemoteManagementContext';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDeleteUnitMutation } from 'services/aiphoneCloud';
import { EnumList, fetchLocalEnumList } from 'shared/utils/EnumUtils';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { removeUnit } from 'store/slices/unitsSlice';
import useSharedStyles from 'styles/useSharedStyles';
import AddUnitDialog from './Dialogs/AddUnitDialog';
import { useGenerateDeviceCard } from './utils/GenerateDeviceCard';
import AssignDevicesToUnitsDialog from './Dialogs/AssignDevicesToUnitsDialog';

interface unitRow {
  id: number;
  publicId: string;
  unitType: number;
  unitName: string;
  unitNumber: string;
  devices: string[];
  tenants: number;
  apps: number;
}

interface unitRowParams {
  row: unitRow;
}

enum ThumbnailSize {
  sm,
  md
}

const UnitDatagrid = () => {
  const { setErrorMessage, setSuccessMessage } = useContext(RemoteManagementContext);
  const unitList = useAppSelector((state) => state.units.UnitList);
  const deviceList = useAppSelector((state) => state.devices.DeviceList);
  const tenantList = useAppSelector((state) => state.tenants.TenantList);
  const appList = useAppSelector((state) => state.apps.AppList);
  const sitePublicId = useAppSelector((state) => state.site.siteInfo.publicId);
  const enumList: EnumList = fetchLocalEnumList();
  const sharedStyle = useSharedStyles();
  const navigate = useNavigate();
  const [deleteUnit] = useDeleteUnitMutation();
  const dispatch = useAppDispatch();
  const [isAddUnitModalOpen, setIsAddUnitModalOpen] = useState(false);
  const [isAssignToUnitModalOpen, setIsAssignToUnitModalOpen] = useState(false);
  const generateDeviceCard = useGenerateDeviceCard();

  const unitListKeys = Object.keys(unitList).sort((a, b) => {
    return unitList[a].unitNumber.localeCompare(unitList[b].unitNumber);
  });
  const deviceListKeys = Object.keys(deviceList).sort((a, b) => {
    return deviceList[a].basicInfo.stationNumber.localeCompare(deviceList[b].basicInfo.stationNumber);
  });
  const tenantListKeys = Object.keys(tenantList);
  const appListKeys = Object.keys(appList);

  const [unitRows, setUnitRows] = useState<unitRow[]>(
    unitListKeys.map((key, index) => {
      return {
        id: index,
        publicId: key,
        unitType: unitList[key].unitType,
        unitName: unitList[key].unitName,
        unitNumber: unitList[key].unitNumber,
        devices: deviceListKeys.filter((deviceKey) => deviceList[deviceKey].unitPublicId === key),
        tenants: tenantListKeys.filter((tenantKey) => tenantList[tenantKey].unitPublicId === key).length,
        apps: appListKeys.filter((appKey) => appList[appKey].unitPublicId === key).length
      };
    })
  );

  useEffect(() => {
    setUnitRows(
      unitListKeys.map((key, index) => {
        return {
          id: index,
          publicId: key,
          unitType: unitList[key].unitType,
          unitName: unitList[key].unitName,
          unitNumber: unitList[key].unitNumber,
          devices: deviceListKeys.filter((deviceKey) => deviceList[deviceKey].unitPublicId === key),
          tenants: tenantListKeys.filter((tenantKey) => tenantList[tenantKey].unitPublicId === key).length,
          apps: appListKeys.filter((appKey) => appList[appKey].unitPublicId === key).length
        };
      })
    );
  }, [unitList, deviceList, tenantList, appList]);

  const { t } = useTranslation();
  const unitNameStr = t('Unit.Name');
  const unitNumberStr = t('Unit.Number');
  const unitTypeStr = t('Unit.Type');
  const stationsStr = t('Station.Station', { count: 2 });
  const tenantsStr = t('Tenant.Tenant', { count: 2 });
  const appsStr = t('App.App', { count: 2 });
  const addUnitStr = t('RemoteManagement.Dashboard.UnitGrid.AddUnit');
  const assignToUnitStr = t('RemoteManagement.Dashboard.UnitGrid.AssignToUnit');
  const deleteUnitStr = t('API.Action.DeleteUnit');
  const unitDeletedStr = t('API.ActionSuccess', { action: deleteUnitStr });
  const assignedContainerStyle = [
    sharedStyle.gridContainer,
    sharedStyle.gridContainer.row,
    sharedStyle.common.gap.none,
    {
      flexWrap: 'nowrap',
      justifyContent: 'space-between'
    }
  ];
  const cardContainerStyle = [sharedStyle.flexContainer.fillWithNoOverflow];
  const dataGridStyle = {
    '& .MuiDataGrid-cell': {
      minHeight: '52px !important', // Datagrid is adding a min-height inline style, so make this important
      whiteSpace: 'nowrap'
    }
  };

  const deleteUnitHandler = async (unitId: string) => {
    try {
      await deleteUnit(unitId).unwrap();
      dispatch(removeUnit(unitId));
      setSuccessMessage(unitDeletedStr);
    } catch (error) {
      setErrorMessage(error as string);
    }
  };

  const viewUnitHandler = (unitId: string) => {
    navigate(`/site/${sitePublicId}/units/${unitId}/unitinformation`);
  };

  const UnitDatagridToolbar = () => {
    return (
      <>
        <Box
          sx={{
            paddingY: '12px',
            paddingX: '16px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
              alignItems: 'center',
              gap: '16px'
            }}
          >
            <Button startIcon={<Edit />} onClick={() => setIsAssignToUnitModalOpen(true)}>
              {assignToUnitStr}
            </Button>

            <Button startIcon={<Add />} onClick={() => setIsAddUnitModalOpen(true)}>
              {addUnitStr}
            </Button>
          </Box>
        </Box>
      </>
    );
  };

  const unitGridColumns: GridColDef[] = [
    { field: 'unitNumber', headerName: unitNumberStr, flex: 0.47 },
    { field: 'unitName', headerName: unitNameStr, flex: 0.43 },
    {
      field: 'unitType',
      headerName: unitTypeStr,
      flex: 0.45,
      valueGetter: (params: unitRowParams) => {
        return enumList.unitType[params.row.unitType].value.replace(/([A-Z])/g, ' $1').trim();
      }
    },
    {
      field: 'devices',
      headerName: stationsStr,
      flex: 1,
      renderCell: (params: unitRowParams) => {
        return (
          <Grid container sx={[sharedStyle.gridContainer, sharedStyle.common.gap.sm, { paddingY: '14px' }]}>
            {params.row.devices.map((deviceKey) => (
              <Grid key={deviceKey} container sx={assignedContainerStyle}>
                <Grid item sx={cardContainerStyle}>
                  {generateDeviceCard(deviceKey, deviceList, ThumbnailSize.sm)}
                </Grid>
              </Grid>
            ))}
          </Grid>
        );
      }
    },
    { field: 'tenants', headerName: tenantsStr, flex: 0.36 },
    { field: 'apps', headerName: appsStr, flex: 0.29 },
    {
      field: 'actions',
      type: 'actions',
      flex: 0.41,
      sortable: false,
      filterable: false,
      getActions: (params: unitRowParams) => [
        <GridActionsCellItem
          label={t('RemoteManagement.Dashboard.UnitGrid.ViewUnit')}
          onClick={() => {
            viewUnitHandler(params.row.publicId);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label={t('RemoteManagement.Dashboard.UnitGrid.DeleteUnit')}
          onClick={() => {
            deleteUnitHandler(params.row.publicId);
          }}
          showInMenu
        />
      ]
    }
  ];

  return (
    <>
      <DataGrid
        columns={unitGridColumns}
        rows={unitRows}
        getRowHeight={() => 'auto'}
        sx={dataGridStyle}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 }
          }
        }}
        pageSizeOptions={[5, 10, 25]}
        slots={{ toolbar: UnitDatagridToolbar }}
      />
      <AssignDevicesToUnitsDialog isOpen={isAssignToUnitModalOpen} setIsOpen={setIsAssignToUnitModalOpen} />
      <AddUnitDialog isOpen={isAddUnitModalOpen} setIsOpen={setIsAddUnitModalOpen} />
    </>
  );
};

export default UnitDatagrid;
