import { Card, CardContent, CardMedia, Button, Typography, Chip, SxProps, Theme } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';
import useSharedStyles from 'styles/useSharedStyles';
import theme from 'styles/theme';

interface FeatureCardProps {
  title: string;
  description: string;
  buttonText: string;
  imageSrc: string;
  onClick: () => void;
  isNewFeature?: boolean;
  imageStyle?: SxProps<Theme>;
}

export const FeatureCard = ({
  title,
  description,
  buttonText,
  imageSrc,
  onClick,
  isNewFeature = false,
  imageStyle = {}
}: FeatureCardProps) => {
  const { t } = useTranslation();
  const newTextStr = t('MainDashboard.FeatureCards.ACNio.New');
  const sharedStyle = useSharedStyles();

  return (
    <Card variant="outlined" sx={{ ...sharedStyle.cardContainer, gap: '0.1rem' }}>
      <CardMedia component="img" sx={{ ...styles.cardMedia, ...imageStyle }} image={imageSrc} alt={title} />
      <CardContent sx={{ ...sharedStyle.cardContainer.cardContent, gap: '0.1rem' }}>
        <Typography variant="body2" color="text.secondary">
          {title}
          {isNewFeature && <Chip label={newTextStr} size="small" variant="filled" sx={styles.chip} />}
        </Typography>
        <Typography variant="h6">{description}</Typography>
        <Button
          variant="outlined"
          color="primary"
          size="medium"
          fullWidth
          onClick={onClick}
          endIcon={<ArrowForwardIosIcon />}
        >
          <Typography variant="h6">{buttonText}</Typography>
        </Button>
      </CardContent>
    </Card>
  );
};

const styles = {
  cardMedia: {
    width: '400px',
    height: '200px',
    borderRadius: '4px',
    objectFit: 'contain',
    mt: 2,
    margin: 'auto'
  },
  chip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
    padding: '3px 4px',
    marginLeft: '8px'
  }
};
