import React, { useEffect, useState } from 'react';
import { DndContext, closestCenter, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Card, Box, CardContent, CardHeader, List, ListItem } from '@mui/material';
import { IUnit } from 'store/slices/unitsSlice';
import theme from 'styles/theme';
import { UnitsIcon } from 'shared/components/icons/icons';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ICallDirectoryList } from './EntranceDisplay.tsx';
import { UUID } from 'crypto';

interface IDraggableItem {
  id: UUID; //id is the unit public id
  unitName: string;
  level: number; // id of call directory become level
}
interface DraggableListProps {
  unitAddressBookList: IUnit[];
  callDirectoryList: ICallDirectoryList[];
  onUpdateItems: (unitList: ICallDirectoryList[]) => void;
  setNeedCallDestinationSave: (value: boolean) => void;
}
interface SortableItemProps {
  id: UUID;
  unitName: string;
  handleRemoveFromList: () => void;
  setNeedCallDestinationSave: (value: boolean) => void;
}
/** Drag and Drop list component */
const SortableItem: React.FC<SortableItemProps> = ({ id, unitName, handleRemoveFromList }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <Box
      ref={setNodeRef}
      sx={{
        display: 'flex',
        width: '100%',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '10px',
        backgroundColor: '#fff',
        cursor: 'grab',
        '&:hover': {
          borderColor: '#003366',
          backgroundColor: 'rgba(0, 51, 102, 0.05)',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
        },
        ...style
      }}
      {...attributes}
      {...listeners}
    >
      <UnitsIcon sx={{ width: '20px', height: '25px', marginX: '30px' }} />
      {unitName}
      <CloseOutlinedIcon sx={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={handleRemoveFromList} />
    </Box>
  );
};

const DirectoryDraggableList: React.FC<DraggableListProps> = ({
  callDirectoryList,
  unitAddressBookList,
  onUpdateItems,
  setNeedCallDestinationSave
}) => {
  const [items, setItems] = useState<IDraggableItem[]>([]);

  useEffect(() => {
    const populateCallDirectoryList = () => {
      const filteredList = callDirectoryList
        .map((callDirectoryUnit) => {
          const unit = unitAddressBookList.find((unit) => unit.publicId === callDirectoryUnit.unitPublicId);
          return unit ? { id: unit.publicId, unitName: unit.unitName, level: callDirectoryUnit.level } : null;
        })
        .filter((unit) => unit !== null);
      setItems(filteredList);
    };

    populateCallDirectoryList();
  }, [callDirectoryList]);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5
      }
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5
      }
    })
  );

  const handleDragEnd = (event: any) => {
    setNeedCallDestinationSave(true);
    const { active, over } = event;
    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item?.id === active.id);
        const newIndex = items.findIndex((item) => item?.id === over.id);
        const newItems = arrayMove(items, oldIndex, newIndex);
        const updatedCallDirectoryList = newItems.map((item) => ({
          level: item.level,
          unitPublicId: item.id
        }));
        onUpdateItems(updatedCallDirectoryList);
        return newItems;
      });
    }
  };

  return (
    <Card>
      <Box sx={{ paddingX: '20px' }}>
        <CardHeader
          title="Call Directory List"
          titleTypographyProps={{
            align: 'center',
            sx: {
              width: '100%',
              justifyContent: 'center'
            }
          }}
        />
        Please drag and drop to reorder the list
      </Box>

      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={items.filter((item) => item !== null)} strategy={verticalListSortingStrategy}>
          <CardContent sx={styles.callDirectoryWrapper}>
            <Box sx={styles.contentMainWrapper}>
              <List>
                {items.length > 0 ? (
                  items.map((item) => (
                    <ListItem key={item.id} disablePadding>
                      <SortableItem
                        id={item.id}
                        unitName={item.unitName}
                        handleRemoveFromList={() => {
                          setNeedCallDestinationSave(true);
                          setItems((prevItems) => prevItems.filter((prevItem) => prevItem.id !== item.id));
                          onUpdateItems(
                            callDirectoryList.filter((callDirectoryUnit) => callDirectoryUnit.unitPublicId !== item.id)
                          );
                        }}
                      />
                    </ListItem>
                  ))
                ) : (
                  <>Empty list</>
                )}
              </List>
            </Box>
          </CardContent>
        </SortableContext>
      </DndContext>
    </Card>
  );
};

const styles = {
  contentMainWrapper: {
    marginTop: '10px',
    backgroundColor: theme.palette.neutral.medium,
    padding: '10px'
  },
  callDirectoryWrapper: {
    height: '610px',
    overflow: 'auto'
  }
};

export default DirectoryDraggableList;
