import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LicenseManagementCommonStyles } from 'features/LicenseManagement/styles/CommonStyles';
import { useParams } from 'react-router-dom';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';
import { useCurrentBranchWithReactQuery } from 'features/LicenseManagement/hooks/useCurrentBranchWithReactQuery';
import { useDeploymentById } from 'features/LicenseManagement/hooks/useDeployments';
import ErrorAlert from 'features/LicenseManagement/components/common/ErrorAlert';
import LoadingSpinner from 'features/LicenseManagement/components/common/LoadingSpinner';
import { formatDate } from 'features/LicenseManagement/components/common/utils';
import { LicenseManagementCommonStyles as CommonStyles } from 'features/LicenseManagement/styles/CommonStyles';
import Header from 'features/LicenseManagement/components/common/Header';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import { DataGrid, GridColDef, GridCellParams, GridColumnHeaderParams } from '@mui/x-data-grid';
import { VALID_MAC_ADDRESS_REGEX } from 'shared/constants/regex';
import { useAddPanel, useRemovePanel } from 'features/LicenseManagement/hooks/usePanels';
import { AddPanelRequest, RemovePanelRequest } from 'features/LicenseManagement/components/common/Types';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import { formatToMACAddress } from 'shared/utils/helperFunctions';
import AddIcon from '@mui/icons-material/Add';

const isValidMacAddress = (mac) => VALID_MAC_ADDRESS_REGEX.test(mac);

const CloudDeploymentPanels = () => {
  const { t } = useTranslation('acNioLicenseManagement');
  // Translation strings
  const macAddressStr = t('Mac_Address');
  const createdOnStr = t('Created_On');
  const addPanelStr = t('Add_Panel.Btn');
  const successStr = t('Add_Panel.Success_Msg');
  const errorStr = t('Add_Panel.Error_Msg');
  const deploymentErrorStr = t('Error_Loading_Deployment_ID');
  const missingPermissionStr = t('Missing_Permission_To_View_Deployment');
  const textFieldLabel = t('Add_Panel.Input_Label');
  const noPanelStr = t('Add_Panel.No_Panel_Msg');
  const deletePanelErrorStr = t('Error_Deleting_Panel');
  const deletePanelSuccessStr = t('Panel_Deleted_Successfully');
  const invalidMacAddressStr = t('Invalid_MAC_Address');

  const [macAddress, setMacAddress] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [message, setMessage] = useState<{
    type: 'success' | 'error';
    text: string;
  } | null>(null);
  const defaultPageSize = 5;
  const [pagination, setPagination] = useState({ page: 0, pageSize: defaultPageSize });

  const { deploymentId } = useParams();
  const { data: currentBranch } = useCurrentBranchWithReactQuery();
  const { isAllowedTo } = usePermission();

  if (!deploymentId) {
    return <Typography>{deploymentErrorStr}</Typography>;
  }

  const canViewDeployment = isAllowedTo('deployment:view', currentBranch?.publicId, PermissionsContextType.BRANCH);

  const canCreateDeployment = isAllowedTo('deployment:create', currentBranch?.publicId, PermissionsContextType.BRANCH);
  const canDeleteDeployment = isAllowedTo('deployment:delete', currentBranch?.publicId, PermissionsContextType.BRANCH);

  const {
    data: deployment,
    isLoading: isDeploymentLoading,
    isFetching: isDeploymentFetching,
    error: deploymentError,
    refetch: refetchDeployments
  } = useDeploymentById(deploymentId || '');

  const { mutate: addPanel, isPending: isAddingPanel } = useAddPanel(
    () => {
      setMessage({
        type: 'success',
        text: successStr
      });
      setMacAddress('');
    },
    (error: any) => {
      setMessage({
        type: 'error',
        text: error?.message || errorStr
      });
    }
  );

  const { mutate: removePanelMutation, isPending: isRemovingPanel } = useRemovePanel();

  const renderStyledHeader = (params: GridColumnHeaderParams) => (
    <Typography variant="body2" sx={CommonStyles.gridColumnHeader}>
      {params.colDef.headerName}
    </Typography>
  );

  const panelRows = Object.keys(deployment?.panelDetails ?? {}).map((key) => ({
    id: key,
    macAddress: deployment?.panelDetails?.[key]?.macAddress || '',
    createdOn: deployment?.panelDetails?.[key]?.createdOn ? formatDate(deployment.panelDetails[key].createdOn) : ''
  }));

  const panelColumns = (): GridColDef[] => [
    { field: 'macAddress', headerName: macAddressStr, flex: 1, renderHeader: renderStyledHeader },
    { field: 'createdOn', headerName: createdOnStr, flex: 1, renderHeader: renderStyledHeader },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <IconButton
          size="small"
          onClick={() => handleRemovePanel(params.row.id, params.row.macAddress)}
          disabled={!canDeleteDeployment}
        >
          <DeleteIcon />
        </IconButton>
      )
    }
  ];

  const handleMacAddressChange = (event) => {
    const formattedMac = formatToMACAddress(event.target.value);
    setMacAddress(formattedMac);
    if (!isValidMacAddress(formattedMac)) {
      setErrorMessage(invalidMacAddressStr);
    } else {
      setErrorMessage('');
    }
  };

  const handleBlur = () => {
    if (!isValidMacAddress(macAddress)) {
      setMacAddress('');
      setErrorMessage('');
    }
  };

  const handleAddPanelClick = () => {
    if (!deployment) {
      return;
    }
    const addPanelRequest: AddPanelRequest = {
      macAddress: macAddress,
      hartmannBranchId: deployment.hartmannBranchId,
      hartmannDeploymentId: deployment.hartmannDeploymentId
    };

    addPanel({
      aiphoneBranchPublicId: currentBranch?.publicId || '',
      deploymentId: deploymentId || '',
      data: addPanelRequest
    });
  };

  const handleRemovePanel = (hartmannPanelId: string, panelMacAddress: string) => {
    const removePanelRequest: RemovePanelRequest = {
      macAddress: panelMacAddress,
      hartmannBranchId: deployment?.hartmannBranchId || 0,
      hartmannDeploymentId: deployment?.hartmannDeploymentId || 0
    };

    removePanelMutation(
      {
        aiphoneBranchPublicId: currentBranch?.publicId || '',
        deploymentId: deployment?.deploymentId || '',
        hartmannPanelId: Number(hartmannPanelId),
        data: removePanelRequest
      },
      {
        onSuccess: () => setMessage({ type: 'success', text: deletePanelSuccessStr }),
        onError: () => setMessage({ type: 'error', text: deletePanelErrorStr })
      }
    );
  };

  if (!canViewDeployment) {
    return <Typography>{missingPermissionStr}</Typography>;
  }

  if (deploymentError) {
    return (
      <Box>
        <ErrorAlert
          errorMessage={deploymentError.message}
          customMessage={t('Error_Loading_Data')}
          onRetry={refetchDeployments}
        />
      </Box>
    );
  }

  if (isDeploymentLoading || isAddingPanel || isRemovingPanel || isDeploymentFetching) {
    return <LoadingSpinner />;
  }

  return (
    <Box sx={CommonStyles.pageHeaderContainer}>
      <Header deployment={deployment} status={deployment?.licenseStatus} />
      <Box sx={styles.sectionContainerStyle}>
        <Typography variant="h6">{t('Panels')}</Typography>
        {canCreateDeployment && (
          <Box sx={styles.inputContainer}>
            <TextField
              label={textFieldLabel}
              variant="outlined"
              size="small"
              value={macAddress}
              onChange={handleMacAddressChange}
              onBlur={handleBlur}
              sx={styles.textField}
              error={!!errorMessage}
              helperText={errorMessage}
            />
            <Button
              variant="contained"
              color="primary"
              sx={LicenseManagementCommonStyles.button}
              startIcon={<AddIcon />}
              onClick={handleAddPanelClick}
              disabled={!isValidMacAddress(macAddress)}
            >
              {addPanelStr}
            </Button>
          </Box>
        )}

        {panelRows.length === 0 ? (
          <Box sx={styles.tableContainer}>
            <Typography>{noPanelStr}</Typography>
          </Box>
        ) : (
          <Box sx={styles.container}>
            <DataGrid
              rows={panelRows}
              columns={panelColumns()}
              disableRowSelectionOnClick
              pageSizeOptions={[5, 10, 25]}
              paginationModel={pagination}
              onPaginationModelChange={(model) => setPagination(model)}
              pagination
              autoHeight={panelRows.length > 0}
            />
          </Box>
        )}
      </Box>
      <SnackbarAlert
        type={message?.type || 'info'}
        time={5000}
        text={message?.text || ''}
        isOpen={!!message}
        onClose={() => setMessage(null)}
      />
    </Box>
  );
};

const styles = {
  sectionContainerStyle: {
    p: 2,
    position: 'relative'
  },
  inputContainer: {
    maxWidth: '1040px',
    height: '40px',
    gap: '16px',
    display: 'flex',
    justifyContent: 'flex-end',
    mb: 4
  },
  textField: {
    width: '300px'
  },
  tableContainer: {
    maxWidth: '1040px',
    maxHeight: '200px',
    borderRadius: '4px',
    border: '1px solid #e0e0e0',
    p: 1
  },
  container: {
    maxWidth: '1040px'
  }
};

export default CloudDeploymentPanels;
