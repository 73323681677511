import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const usePortValidationSchema = (fieldName: string): Yup.NumberSchema => {
  const { t } = useTranslation(); // Initialize the translation hook
  const formattedFieldRequired = t('Field.Error.Required', { field: fieldName });
  const portMin = 1;
  const portMax = 65535;
  const fieldErrorMin = t('Field.Error.Min', { field: fieldName, min: portMin });
  const fieldErrorMax = t('Field.Error.Max', { field: fieldName, max: portMax });

  return Yup.number().required(formattedFieldRequired).min(portMin, fieldErrorMin).max(portMax, fieldErrorMax);
};

export default usePortValidationSchema;
