import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export const useCanAccessRemoteManagement = (): boolean => {
  const sitePermissionsList = useSelector((state: RootState) => state.users.currentUser?.permissions?.site ?? {});
  const currentBranchPublicId = useSelector((state: RootState) => state.branches.currentBranch?.publicId ?? '');
  const { isAllowedTo } = usePermission();

  if (sitePermissionsList && Object.keys(sitePermissionsList).length > 0) {
    return Object.keys(sitePermissionsList).some((sitePublicId) =>
      isAllowedTo('site:view', sitePublicId, PermissionsContextType.SITE)
    );
  }

  return isAllowedTo('site:view', currentBranchPublicId, PermissionsContextType.BRANCH);
};
