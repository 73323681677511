import { useState } from 'react';
import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import LoginPanel from '../SiteDashboard/Dialogs/LoginPanel';
import CreatePanel from '../SiteDashboard/Dialogs/CreatePanel';
import ActivationPanel from '../SiteDashboard/Dialogs/ActivationPanel';
import { useTranslation } from 'react-i18next';
import GroupIcon from '@mui/icons-material/Group';
import DialogWrapper from 'shared/components/dialogs/DialogWrapper';
import StringUtils from 'shared/utils/StringUtils';
import { getSetUsernameAndToken } from '../SiteDashboard/utils/utils';
import useSharedStyles from 'styles/useSharedStyles';

interface Props {
  setAclToken: React.Dispatch<React.SetStateAction<string | null>>;
}

const IXGLoginContainer = ({ setAclToken }: Props) => {
  const [isLoginPanelOpen, setIsLoginPanelOpen] = useState(false);
  const [isRegisterDialogOpen, setIsRegisterDialogOpen] = useState(false);
  const [isActivationPanelOpen, setIsActivationPanelOpen] = useState(false);

  const dispatch = useDispatch();
  const sharedStyle = useSharedStyles();

  const handleClose = () => {
    setIsLoginPanelOpen(false);
    setIsRegisterDialogOpen(false);
    setIsActivationPanelOpen(false);
  };
  const setUsernameAndToken = getSetUsernameAndToken(setIsLoginPanelOpen, dispatch);

  const { t } = useTranslation();
  const cardTitle = t('IXGLogin.Title');
  const companyAccountInfoStr = t('IXGLogin.CompanyAccountInfo');
  const oneAccountPerBranchStr = t('IXGLogin.OneAccountPerBranch');
  const loginBtnStr = t('IXGLogin.LoginBtn');
  const noAccountYetStr = t('IXGLogin.NoAccountYet');
  const registerAccount = t('IXGLogin.RegisterAccount');
  const loginPanelTitle = t('IXGLogin.LoginPanel.ManageSitesTitle');
  const createANewItemStr = t('Shared.CreateANewItem');
  const administratorIdStr = t('IXGLogin.AdministratorId');
  const ixgCloudRegistrationStr = t('IXGLogin.CreatePanel.IXGCloudRegistration');
  const activationPanelTitle = t('IXGLogin.ActivationPanel.Title');
  const activationPanelSubtitle = t('IXGLogin.ActivationPanel.Subtitle');
  const createANewAdministratorIdStr = StringUtils.format(createANewItemStr, administratorIdStr);
  const inlineBoxStyle = sharedStyle.inlineBox;

  return (
    <Card variant="outlined" sx={sharedStyle.cardContainer}>
      <CardContent sx={sharedStyle.cardContainer.cardContent}>
        <Typography variant="h6">{cardTitle}</Typography>
        <Typography variant="body2" color="textSecondary">
          {companyAccountInfoStr}
        </Typography>
        <Box sx={inlineBoxStyle}>
          <GroupIcon sx={inlineBoxStyle.iconSecondary} />
          <Typography variant="body2" color="textSecondary">
            {oneAccountPerBranchStr}
          </Typography>
        </Box>
        <Box>
          <Button variant="contained" color="primary" size="medium" onClick={() => setIsLoginPanelOpen(true)}>
            {loginBtnStr}
          </Button>
        </Box>
        <Box sx={inlineBoxStyle}>
          <Typography variant="body2" color="textSecondary">
            {noAccountYetStr}
          </Typography>
          <Button variant="text" color="primary" size="small" onClick={() => setIsRegisterDialogOpen(true)}>
            {registerAccount}
          </Button>
        </Box>
        <DialogWrapper
          header={loginPanelTitle}
          setIsOpen={setIsLoginPanelOpen}
          open={isLoginPanelOpen}
          onClose={handleClose}
          fullWidth
          maxWidth="sm"
        >
          <LoginPanel
            setToken={setAclToken}
            setUsernameAndToken={setUsernameAndToken}
            setIsLoginPanelOpen={setIsLoginPanelOpen}
          />
        </DialogWrapper>
        <DialogWrapper
          header={ixgCloudRegistrationStr}
          subheader={createANewAdministratorIdStr}
          setIsOpen={setIsRegisterDialogOpen}
          open={isRegisterDialogOpen}
          onClose={handleClose}
          fullWidth
          maxWidth="sm"
        >
          <CreatePanel
            setIsActivationPanelOpen={setIsActivationPanelOpen}
            setIsRegisterDialogOpen={setIsRegisterDialogOpen}
          />
        </DialogWrapper>
        <DialogWrapper
          header={activationPanelTitle}
          subheader={activationPanelSubtitle}
          setIsOpen={setIsActivationPanelOpen}
          open={isActivationPanelOpen}
          onClose={handleClose}
          fullWidth
          maxWidth="sm"
        >
          <ActivationPanel onClose={handleClose} />
        </DialogWrapper>
      </CardContent>
    </Card>
  );
};

export default IXGLoginContainer;
