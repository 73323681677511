import { useQuery } from '@tanstack/react-query';
import { DeploymentType } from '../components/common/Types';
import { fetchStripeProducts } from '../lib/api/LicenseManagementApi';
import { useCurrentBranchWithReactQuery } from './useCurrentBranchWithReactQuery';

export const useFetchStripeProducts = (countryId: string, deploymentType: DeploymentType) => {
  const enabled = !!countryId && deploymentType !== undefined && deploymentType !== null;

  const { data: currentBranch } = useCurrentBranchWithReactQuery();
  const branchId = currentBranch?.publicId;

  return useQuery({
    queryKey: ['stripeProducts', countryId, deploymentType],
    queryFn: () => fetchStripeProducts(countryId, deploymentType, branchId ?? ''),
    enabled: enabled,
    staleTime: Infinity
  });
};
