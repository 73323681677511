import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';

// #region import LicenseManagement
// Components
import { PlanSelector } from './PlanSelector';
// Hooks
import { useFetchStripeProducts } from 'features/LicenseManagement/hooks/useFetchStripeProducts';
// SharedComponents
import { handleContinue, handleExit } from 'features/LicenseManagement/components/common/navigationUtils';
import LoadingSpinner from 'features/LicenseManagement/components/common/LoadingSpinner';
import ErrorAlert from 'features/LicenseManagement/components/common/ErrorAlert';
// Styles
import { LicenseManagementCommonStyles } from 'features/LicenseManagement/styles/CommonStyles';
// Types
import {
  DeploymentDoorSize,
  DeploymentType,
  PaymentMethod,
  Product,
  steps
} from 'features/LicenseManagement/components/common/Types';
import { useCurrentBranchAddress } from 'features/LicenseManagement/hooks/useCurrentBranchAddress';
// #endregion

interface OnlinePaymentViewProps {
  planType: DeploymentDoorSize;
  setPlanType: React.Dispatch<React.SetStateAction<DeploymentDoorSize>>;
  selectedPlan: string;
  setSelectedPlan: React.Dispatch<React.SetStateAction<string>>;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  onProductSelect: (product: Product) => void;
}

export const OnlinePaymentView: React.FC<OnlinePaymentViewProps> = ({
  planType,
  setPlanType,
  selectedPlan,
  setSelectedPlan,
  activeStep,
  setActiveStep,
  onProductSelect
}) => {
  const { t } = useTranslation('acNioLicenseManagement');

  const { countryNumericCode } = useCurrentBranchAddress();

  const {
    data: products = [],
    isLoading,
    error,
    isError: isFetchStripeProductsError,
    refetch: refetchStripeProducts
  } = useFetchStripeProducts(countryNumericCode, DeploymentType.Local);

  const groupedProducts = useMemo(() => {
    return products.reduce((groupedByDoorSize: Record<string, Product[]>, product: Product) => {
      const { doorSize } = product;
      if (!groupedByDoorSize[doorSize]) groupedByDoorSize[doorSize] = [];
      groupedByDoorSize[doorSize].push(product);
      return groupedByDoorSize;
    }, {});
  }, [products]);

  const filteredPlans = useMemo(() => {
    return (groupedProducts[planType] || []).filter((product) => !product.isAddOn);
  }, [groupedProducts, planType]);

  useEffect(() => {
    if (!selectedPlan && filteredPlans.length > 0) {
      setSelectedPlan(filteredPlans[0].id);
      onProductSelect(filteredPlans[0]);
    }
  }, [filteredPlans, selectedPlan, setSelectedPlan, onProductSelect]);

  const handlePlanSelect = (planId: string) => {
    const selectedProduct = filteredPlans.find((product: Product) => product.id === planId);
    setSelectedPlan(planId);
    if (selectedProduct) {
      onProductSelect(selectedProduct);
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isFetchStripeProductsError) {
    return (
      <Box>
        <ErrorAlert
          errorMessage={error.message}
          customMessage={t('Error_Loading_Data')}
          onRetry={refetchStripeProducts}
        />
      </Box>
    );
  }

  return (
    <>
      <Box sx={styles.container}>
        <PlanSelector
          plans={filteredPlans}
          selectedPlan={selectedPlan}
          onPlanSelect={handlePlanSelect}
          planType={planType}
          setPlanType={setPlanType}
        />
      </Box>
      <Box sx={LicenseManagementCommonStyles.buttonContainer}>
        <Button variant="text" sx={LicenseManagementCommonStyles.buttonBack} onClick={handleExit}>
          {t('Exit')}
        </Button>
        <Button
          variant="contained"
          sx={LicenseManagementCommonStyles.button}
          onClick={() => handleContinue(activeStep, setActiveStep, steps(PaymentMethod.Online, false).length)}
          disabled={selectedPlan == ''}
        >
          {t('Continue')}
        </Button>
      </Box>
    </>
  );
};

const styles = {
  container: {
    marginLeft: '50px',
    display: 'flex'
  }
};
