import { useMemo } from 'react';
import { useFetchStripeProducts } from './useFetchStripeProducts';
import { DeploymentType } from '../components/common/Types';
import {
  calculateDoorsPrice,
  calculateGrandTotal
} from '../components/license/LicenseDetail/Unactivated/Cloud/PackagePrice';
import { useCurrentBranchAddress } from './useCurrentBranchAddress';

export const useCloudDeploymentPackagePricing = (numDoors: number, numExpanders: number, numCabs: number) => {
  const { countryNumericCode } = useCurrentBranchAddress();

  // Fetch Stripe products
  const {
    data: products,
    error,
    isError: isFetchStripeProductsError,
    refetch: refetchStripeProducts,
    isLoading
  } = useFetchStripeProducts(countryNumericCode, DeploymentType.Cloud);

  // Calculate pricing
  const {
    doorPriceDetails,
    totalDoorCost,
    totalDiscount,
    stripeCheckoutItems: doorItems
  } = useMemo(() => {
    if (!products) return { doorPriceDetails: [], totalDoorCost: 0, totalDiscount: 0, stripeCheckoutItems: [] };
    return calculateDoorsPrice(
      numDoors,
      products.filter((p) => p.productType === 'door')
    );
  }, [numDoors, products]);

  const {
    expandersCost,
    cabsCost,
    grandTotal,
    stripeCheckoutItems: expanderCabItems
  } = useMemo(() => {
    if (!products) return { expandersCost: 0, cabsCost: 0, grandTotal: 0, stripeCheckoutItems: [] };
    return calculateGrandTotal(products, numExpanders, numCabs);
  }, [numExpanders, numCabs, products]);

  const combinedStripeCheckoutItems = useMemo(() => [...doorItems, ...expanderCabItems], [doorItems, expanderCabItems]);

  return {
    doorPriceDetails,
    expandersCost,
    cabsCost,
    totalDiscount,
    grandTotal: grandTotal + totalDoorCost,
    isFetchStripeProductsError,
    error,
    refetchStripeProducts,
    combinedStripeCheckoutItems,
    isLoading
  };
};
