import { Box, Chip, Link, Typography } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef, GridColumnHeaderParams, GridRenderCellParams } from '@mui/x-data-grid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { InvoiceDetailResponse } from '../../common/Types';
import { LicenseManagementCommonStyles } from 'features/LicenseManagement/styles/CommonStyles';
import { formatDate } from '../../common/utils';

interface BillingHistoryProps {
  invoices: InvoiceDetailResponse[];
}
const BillingHistory = ({ invoices }: BillingHistoryProps) => {
  const { t } = useTranslation('acNioLicenseManagement');

  const paymentStatusStr = t('Payment_Status');
  const invoiceNumberStr = t('Invoice_Number');
  const amountStr = t('Amount');
  const paymentDateStr = t('Payment_Date');
  const invoiceDetailStr = t('Invoice_Detail');

  const emptyValue = '-';
  const defaultPageSize = 5;
  const [pagination, setPagination] = useState({ page: 0, pageSize: defaultPageSize });

  const renderStyledHeader = (params: GridColumnHeaderParams) => (
    <Typography variant="body2" sx={LicenseManagementCommonStyles.gridColumnHeader}>
      {params.colDef.headerName}
    </Typography>
  );

  const computeRows = (invoices: InvoiceDetailResponse[]) => {
    return invoices.map((invoice) => ({
      id: invoice.invoiceNumber ?? emptyValue,
      invoiceNumber: invoice.stripeInvoiceNumber ?? emptyValue,
      total: invoice.total ? invoice.total / 100 : emptyValue,
      paidOn: invoice.paidOn ? formatDate(invoice.paidOn) : emptyValue,
      status: invoice.status ?? emptyValue,
      invoiceLink: invoice.invoiceLink
    }));
  };

  const computeColumns = (): GridColDef[] => [
    { field: 'invoiceNumber', headerName: invoiceNumberStr, flex: 1, renderHeader: renderStyledHeader },
    {
      field: 'total',
      headerName: amountStr,
      flex: 1,
      renderHeader: renderStyledHeader,
      valueFormatter: (params) => (params.value !== emptyValue ? `$${params.value}` : emptyValue)
    },
    { field: 'paidOn', headerName: paymentDateStr, flex: 1, renderHeader: renderStyledHeader },
    {
      field: 'status',
      headerName: paymentStatusStr,
      flex: 1,
      renderHeader: renderStyledHeader,
      renderCell: (params: GridCellParams) =>
        params.value ? (
          <Box display="flex" alignItems="center">
            <Chip
              label={<Typography variant="body2">{params.value as string}</Typography>}
              color={params.value === 'paid' ? 'success' : 'error'}
              size="medium"
              variant="outlined"
            />
          </Box>
        ) : null
    },
    {
      field: 'invoiceLink',
      headerName: invoiceDetailStr,
      flex: 1,
      renderHeader: renderStyledHeader,
      renderCell: (params: GridRenderCellParams) =>
        params.value ? (
          <Link target="_blank" href={params.value} variant="body2" sx={styles.linkContainer}>
            <OpenInNewIcon color="primary" /> <span>{t('Go_to_Invoice')}</span>
          </Link>
        ) : (
          emptyValue
        )
    }
  ];

  return (
    <Box sx={styles.container}>
      <DataGrid
        rows={computeRows(invoices)}
        columns={computeColumns()}
        disableRowSelectionOnClick
        pageSizeOptions={[3, 5, 10]}
        paginationModel={pagination}
        onPaginationModelChange={(model) => setPagination(model)}
        pagination
        autoHeight={invoices.length > 0}
      />
    </Box>
  );
};

const styles = {
  container: {
    height: '100%',
    width: '100%',
    '& .MuiDataGrid-cell': {
      borderBottom: '0.0625rem solid #e0e0e0'
    },
    postion: 'relative',
    mt: 2
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem'
  }
};

export default BillingHistory;
