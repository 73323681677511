import { Box, Grid, Typography } from '@mui/material';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import { EnumList, fetchLocalEnumList, IDeviceModelValue } from 'shared/utils/EnumUtils';
import { DeviceList } from 'store/slices/devicesSlice';
import useSharedStyles from 'styles/useSharedStyles';

export enum ThumbnailSize {
  sm,
  md
}

export const useGenerateDeviceCard = (): ((
  key: string,
  deviceList: DeviceList,
  imgSize: ThumbnailSize
) => JSX.Element) => {
  const enumList: EnumList = fetchLocalEnumList();
  const sharedStyle = useSharedStyles();

  function generateDeviceCard(key: string, deviceList: DeviceList, imgSize: ThumbnailSize): JSX.Element {
    const deviceCardStyle = [sharedStyle.gridContainer, sharedStyle.gridContainer.row, sharedStyle.common.gap.sm];
    const availableDeviceTextStyle = [sharedStyle.flexContainer.fillWithNoOverflow];

    const deviceModel = (
      (Object.values(enumList.deviceModel) as IDeviceModelValue[]).find((device) => {
        return (
          device.deviceType === deviceList[key].basicInfo.deviceType &&
          device.modelNumber === deviceList[key].basicInfo.deviceModel
        );
      }) as IDeviceModelValue
    ).value;

    return (
      <Grid container sx={deviceCardStyle}>
        <Grid item sx={sharedStyle.gridContainer.centeredImgCell}>
          <Box
            sx={[
              sharedStyle.imgThumbnail,
              imgSize === ThumbnailSize.md ? sharedStyle.imgThumbnail.md : sharedStyle.imgThumbnail.sm
            ]}
          >
            <Box
              component="img"
              src={`${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/${deviceModel}.png`}
              alt={deviceModel}
            />
          </Box>
        </Grid>
        <Grid item sx={availableDeviceTextStyle}>
          <Typography variant="body1" noWrap>
            {deviceList[key].basicInfo.stationName}
          </Typography>
          <Typography variant="body2" noWrap color="textSecondary">
            {deviceList[key].basicInfo.macAddress}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return generateDeviceCard;
};
