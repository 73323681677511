import React from 'react';
import {
  DataGrid,
  GridCellModesModel,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridRowId,
  GridRowModel,
  GridRowsProp,
  MuiEvent,
  useGridApiRef
} from '@mui/x-data-grid';
import {
  TimeSelectorType,
  handleRowClick,
  handleCellModesModelChange,
  handleCellClickToView,
  DEFAULT_RELAYS,
  basicContactOutputs,
  defaultContactOutputState,
  ParentAlert
} from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/common';
import { useTranslation } from 'react-i18next';
import CheckedCheckbox from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/cells/CheckedCheckbox';
import StationNameNumberCell from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/cells/StationNameNumberCell';
import CustomGridToolbarWithToggle from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/headers/CustomGridToolbarWithToggle';
import { RelayDelayColHeader } from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/headers/RelayDelayColHeader';
import { Check } from '@mui/icons-material';
import { IDevice, IDeviceContactOutput, ILiftControlUnitOperation } from 'store/slices/devicesSlice';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { Button } from '@mui/material';
import { useBatchUpdateDevicesMutation } from 'services/aiphoneCloud';
import { IUnit } from 'store/slices/unitsSlice';

type UpdateUnitDeviceLCPayload = {
  device: {
    publicId: string; // Get from the selectedDevicePublicId
    sitePublicId: string;
    liftControlSettings: {
      devicePublicId: string;
      goOutUse: boolean;
    };
  };
};

interface BuildingExitLCProps {
  setSnackbarState: React.Dispatch<React.SetStateAction<ParentAlert>>;
  isSubmitting: boolean;
  selectedDevicePublicId: string;
  updateLiftControlDevice: (payload: any) => Promise<void>;
  handleUpdateRowField: (
    id: GridRowId,
    field: string,
    newValue: any,
    setRows: (
      value: ((prevState: readonly GridRowModel[]) => readonly GridRowModel[]) | readonly GridRowModel[]
    ) => void | undefined
  ) => void;
  buildingPublicId: string;
  handleUpdateRelayDelay(
    relaysRef: React.MutableRefObject<IDeviceContactOutput[]>,
    params: GridColumnHeaderParams,
    newRelayMS: number
  ): void;
}

const BuildingExitLC = ({
  setSnackbarState,
  isSubmitting,
  selectedDevicePublicId,
  updateLiftControlDevice,
  handleUpdateRowField,
  handleUpdateRelayDelay
}: BuildingExitLCProps) => {
  const dataGridRef = React.useRef<HTMLDivElement>(null);
  const apiRef = useGridApiRef();
  const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState<boolean>(false);
  const initialData = React.useRef<GridRowsProp>([]);
  const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});
  const [batchUpdateDevices] = useBatchUpdateDevicesMutation();

  const { t } = useTranslation();
  const saveChangesStr = t('Shared.SaveChanges');

  const [timeSelectorType, setTimeSelectorType] = React.useState<TimeSelectorType>('s');

  const unitsList = useSelector((state: RootState) => state.units.UnitList);
  const unitsListByType = useSelector((state: RootState) => state.units.UnitListByType) ?? {
    Guard: [],
    Residential: [],
    Commercial: [],
    Entrance: []
  };
  const deviceList = useSelector((state: RootState) => state.devices.DeviceList);

  const selectedDevice = deviceList[selectedDevicePublicId ?? ''];

  const liftControlSettings = selectedDevice.liftControlSettings;
  const residentialUnits: string[] = unitsListByType['Residential'] ?? [];
  const goOut: ILiftControlUnitOperation[] = liftControlSettings?.operation.goOut ?? [];
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const relays = React.useMemo(() => {
    return (selectedDevice.contactOutputList as IDeviceContactOutput[]) ?? DEFAULT_RELAYS;
    // Force update should be triggered by the update to deviceList
  }, [selectedDevice.contactOutputList, deviceList]);

  const relaysRef = React.useRef<IDeviceContactOutput[]>(relays);

  const buildingExitFloorData = (): ILiftControlUnitOperation[] => {
    return residentialUnits
      .map((unitId) => {
        const unit = unitsList[unitId];
        const unitPublicId = unit.publicId;
        const rowUnit = goOut.find((liftControlUnit) => liftControlUnit.targetUnitPublicId === unitPublicId);

        // We need to check the row unit.
        // First we check if there are devicePublicIds on it.
        // If not, we skip it, returning null
        // If there is, then we need to loop through each of the devicePublicIds,
        //  and go through the deviceList to check the basicInfo.deviceType for 14. If so, then we add it to it.
        //  If not, return null.

        if (!unit.devicePublicIds || unit.devicePublicIds.length === 0) {
          return null;
        }

        const devicePublicIds = unit.devicePublicIds;

        let unitContains2C7 = false;

        for (const devicePublicId of devicePublicIds) {
          const device = deviceList[devicePublicId];

          if (device.basicInfo.deviceType === 14) {
            unitContains2C7 = true;
            break;
          }
        }

        if (!unitContains2C7) {
          return null;
        }

        if (rowUnit) {
          return {
            ...rowUnit,
            id: unitPublicId,
            unitName: unit.unitName,
            unitNumber: unit.unitNumber
          };
        } else {
          return {
            ...defaultContactOutputState,
            targetUnitPublicId: unitPublicId,
            id: unitPublicId,
            unitName: unit.unitName,
            unitNumber: unit.unitNumber
          };
        }
      })
      .filter((row) => row !== null) as ILiftControlUnitOperation[];
  };

  const [rows, setRows] = React.useState<GridRowsProp>(buildingExitFloorData());

  const columns: GridColDef[] = React.useMemo(() => {
    const firstColumn: GridColDef = {
      field: 'Units',
      editable: false,
      hideable: false,
      disableColumnMenu: true,
      minWidth: 160,
      pinnable: true,
      groupable: false,
      sortable: false,
      disableReorder: true,
      hideSortIcons: true,
      filterable: false,
      headerClassName: 'font-bold unselectable pl_16px',
      headerName: t('Unit.Unit', { count: 2 }),
      renderCell: (params: GridRenderCellParams) => {
        return <StationNameNumberCell title={params.row.unitName} subtitle={params.row.unitNumber} />;
      },
      renderEditCell: (params: GridRenderCellParams) => {
        return <StationNameNumberCell title={params.row.unitName} subtitle={params.row.unitNumber} />;
      },
      cellClassName: 'unselectable m-0 p-0'
    };

    const relayCols: GridColDef[] = relays.map((relay, index) => {
      const relayNum = index + 1;
      return {
        field: `contactOutput${relayNum}Enabled`,
        sortable: false,
        disableReorder: true,
        hideSortIcons: true,
        filterable: false,
        pinnable: true,
        groupable: false,
        disableColumnMenu: true,
        type: 'boolean',
        editable: true,
        headerClassName: 'm-0 p-0',
        hideable: false,
        cellClassName: 'm-0 p-0',
        renderHeader: (params: GridColumnHeaderParams) => {
          return (
            <RelayDelayColHeader
              params={params}
              timeType={timeSelectorType}
              headerLabel={`${t('Relay')} ${relayNum}`}
              value={relay.timer}
              onValueChange={(newValue: number) => {
                handleUpdateRelayDelay(relaysRef, params, newValue);
              }}
              suffix={timeSelectorType.toString()}
            />
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CheckedCheckbox
              key={params.id}
              initialChecked={params.row[params.field] || false}
              onToggle={async (newChecked: boolean) => {
                handleUpdateRowField(params.id, params.field, newChecked, setRows);
                // Update the DataGrid state
                await params.api.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: newChecked
                });
              }}
              {...params}
            />
          );
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return (
            <CheckedCheckbox
              key={params.id}
              initialChecked={params.row[params.field] || false}
              onToggle={async (newChecked: boolean) => {
                // Update the state of rows

                handleUpdateRowField(params.id, params.field, newChecked, setRows);
                // Update the DataGrid state
                await params.api.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: newChecked
                });
              }}
              {...params}
            />
          );
        }
      };
    });

    return [firstColumn, ...relayCols];
  }, [handleUpdateRelayDelay, handleUpdateRowField, t, timeSelectorType, relays]);

  const submitUpdateDevice = React.useCallback(async () => {
    const devicesToEnableDisable: UpdateUnitDeviceLCPayload[] = [];

    const goOut = [];
    // Go through the rows and get the Unit Public Id, and the contactOutput<n>Enabled setting
    const rows = apiRef.current?.getRowModels();

    // Track if any relays are enabled
    let goOutUse = false;

    // Prep payloads
    if (rows) {
      // Each row is a unit, so I have to iterate through them and pull the devicePublicId from each one.
      // Then I need to check each device and get the deviceType and check if it is 14.
      // If it is, then I check if there is any relay enabled.
      // I will then build the payload object for that
      for (const row of rows.values()) {
        const targetUnitPublicId = row.targetUnitPublicId as string;
        const contactOutputs: Record<string, string | boolean> = {};

        // Get and assign whether each contact output key is enabled (true/false) for the Lift Control device.
        for (const contactOutputKey of basicContactOutputs) {
          const isEnabled = row[contactOutputKey] as boolean;

          contactOutputs[contactOutputKey] = isEnabled;

          if (isEnabled) {
            goOutUse = true;
          }
        }

        contactOutputs['targetUnitPublicId'] = targetUnitPublicId;
        goOut.push(contactOutputs);

        const unitPublicId = row.id as string;
        // Get the unit from the unitsList
        const unit = unitsList[unitPublicId] as IUnit;
        // Grab the devicePublicIds from the Unit
        const unitDevicePublicIds = unit.devicePublicIds;

        // Now that I have a list of the string/UUIDs of the devices in the unit,
        // I have to iterate through the devices to see if this one has deviceType of 14.
        //
        // We need to include a check to see if unitDevicePublicIds is even (a) a valid object that exists, (b) an array.
        if (unitDevicePublicIds && Array.isArray(unitDevicePublicIds) && unitDevicePublicIds.length > 0) {
          for (const devicePublicId of unitDevicePublicIds) {
            const device = deviceList[devicePublicId] as IDevice;

            // If this is the 2C7 (type of 14), we need to assemble the payload
            if (device.basicInfo.deviceType === 14) {
              const payload: UpdateUnitDeviceLCPayload = {
                device: {
                  publicId: devicePublicId,
                  sitePublicId: device.sitePublicId,
                  liftControlSettings: {
                    devicePublicId: selectedDevicePublicId,
                    goOutUse
                  }
                }
              };
              // Push the new payload to the array
              devicesToEnableDisable.push(payload);
            }
          }
        }
      }
    }

    const existingDevice = deviceList[selectedDevicePublicId];

    const payload = {
      device: {
        publicId: selectedDevicePublicId,
        contactOutputList: relaysRef.current,
        liftControlSettings: {
          operation: {
            ...existingDevice.liftControlSettings.operation,
            goOut
          },
          devicePublicId: selectedDevicePublicId,
          // If any of the relays are enabled, then we set goOutUse to true.
          // Else, it will be false (default).
          goOutUse
        }
      }
    };

    try {
      // Fixing the batchUpdateDevices payload issue with the "device" key.
      // This removes the "device" key from each of the entries.
      await batchUpdateDevices({ devices: devicesToEnableDisable.map((device) => device.device) }).unwrap();
      await updateLiftControlDevice(payload);
    } catch (_) {
      // Finally, the .unwrap() on batchUpdateDevices triggers the catch here, which will show the error.
      setSnackbarState({
        text: t('Error_Updating_Lift_Control_Settings'),
        type: 'error',
        isOpen: true,
        duration: 3000
      });
    }
  }, [updateLiftControlDevice, selectedDevicePublicId, relaysRef, apiRef, batchUpdateDevices, t]);

  React.useEffect(() => {
    setIsLoading(true);
    const initialRowData = buildingExitFloorData();
    initialData.current = initialRowData;
    setRows(initialRowData);
    setIsLoading(false);
  }, [deviceList]);

  return (
    <>
      <DataGrid
        sx={{
          overflowX: 'scroll'
        }}
        components={{
          Toolbar: () => (
            <CustomGridToolbarWithToggle
              isSubmitting={isSubmitting}
              description={t('Select_output_Trigger_IXG')}
              valueToggle={
                // We need to set the opposite value of the current timeSelectorType
                // because the toggle will change the value after the state is updated
                [
                  { value: 's', label: t('Seconds_Text') },
                  { value: 'ms', label: t('Milliseconds_Text') }
                ]
              }
              label={''}
              buttonText={saveChangesStr}
              buttonLeftIcon={<Check />}
              submitHandler={async () => await submitUpdateDevice()}
              selectedValue={timeSelectorType === 's' ? t('Seconds_Text') : t('Milliseconds_Text')}
              handleSelectChange={(event) => {
                setTimeSelectorType(event.target.value as TimeSelectorType);
              }}
            />
          )
        }}
        cellModesModel={cellModesModel}
        onRowClick={(params, event) => handleRowClick(params, event as unknown as MuiEvent<MouseEvent>)}
        onCellModesModelChange={(cellModesModel) => handleCellModesModelChange(cellModesModel, setCellModesModel)}
        showCellVerticalBorder={true}
        showColumnVerticalBorder={true}
        columns={columns}
        autoHeight={true}
        rows={rows}
        ref={dataGridRef}
        loading={isLoading}
        apiRef={apiRef}
        onCellClick={(params, event) => handleCellClickToView(params, event as React.MouseEvent, setCellModesModel)}
      />
      <Dialog disableEscapeKeyDown={true} open={hasUnsavedChanges} onBackdropClick={undefined}>
        <DialogTitle>{t('Save_the_changes')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('Discard_text')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setHasUnsavedChanges(false);
            }}
            variant={'outlined'}
          >
            {t('Discard_Changes')}
          </Button>
          <Button
            onClick={(event) => {
              setHasUnsavedChanges(false);
              event.preventDefault();
            }}
            variant={'contained'}
          >
            {t('Keep_Editing')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BuildingExitLC;
