import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { EnumList, fetchLocalEnumList } from 'shared/utils/EnumUtils';

const useFramerateValidationSchema = (fieldName: string): Yup.StringSchema => {
  const enumList: EnumList = fetchLocalEnumList();
  const { t } = useTranslation(); // Initialize the translation hook
  const formattedFieldRequired = t('Field.Error.Required', { field: fieldName });
  const formattedFieldInvalid = t('Field.Error.Invalid', { field: fieldName });

  return Yup.string()
    .required(formattedFieldRequired)
    .test('isValidFramerate', formattedFieldInvalid, (value: string) => {
      return Object.keys(enumList.videoEncoderFramerate).includes(value);
    });
};

export default useFramerateValidationSchema;
