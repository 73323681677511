import Dashboard from '../features/Dashboard/Dashboard';
import Quikspec from '../features/QuikSpec/Components/Entrypoints/Home/QuikSpec';
import QuikspecAC from 'features/QuikSpec/Components/Entrypoints/AC/QuikspecAC';
import QuikspecGT from 'features/QuikSpec/Components/Entrypoints/GT/QuikspecGT';
import QuikspecIXIXG from 'features/QuikSpec/Components/Entrypoints/IXIXG/QuikspecIXIXG';
import QuikspecJO from 'features/QuikSpec/Components/Entrypoints/JO/QuikspecJO';
import QuikspecJP from 'features/QuikSpec/Components/Entrypoints/JP/QuikspecJP';
import QuikspecJV from 'features/QuikSpec/Components/Entrypoints/JV/QuikspecJV';
import QuikspecAX from 'features/QuikSpec/Components/Entrypoints/AX/QuikspecAX';
import QuikspecKB from 'features/QuikSpec/Components/Entrypoints/KB/QuikspecKB';
import QuikspecNHX from 'features/QuikSpec/Components/Entrypoints/NHX/QuikspecNHX';
import QuikspecTwoWire from 'features/QuikSpec/Components/Entrypoints/TwoWire/QuikspecTwoWire';
import QuikspecLEF from 'features/QuikSpec/Components/Entrypoints/LEF/QuikspecLEF';
import Calendar from '../features/Next-IX/Calendar/Calendar';
import CallGroup from '../features/Next-IX/Group/CallGroup';
import DeviceSettingNext from '../features/Next-IX/DeviceSettings/DeviceSettings';
import RemoteManagement from '../features/RemoteManagement/RemoteManagement';
import { Routes, Route, BrowserRouter, Navigate, Outlet, useNavigate, useLocation } from 'react-router-dom';
import Home from '../features/Dashboard/Home';
import Legal from '../features/Legal/Legal';
import Support from '../features/Support/Support';
import { AuthProvider } from 'context/AuthContext';
import { SiteProvider } from 'context/SiteContext';
import { PermissionProvider } from 'context/PermissionContext';
import ReDirectedPage from 'pages/ReDirectPage';
import SiteLoader from 'features/RemoteManagement/SiteDashboard/SiteLoader';
import Users from 'features/Users/Users';
import Units from 'features/RemoteManagement/SiteDashboard/TenantManagement/Units/Units';
import NetworkInfo from 'features/RemoteManagement/DeviceDashboard/networkInfo/NetworkInfo';
import StationInfo from 'features/RemoteManagement/DeviceDashboard/stationInfo/StationInfo';
import CallSettings from 'features/RemoteManagement/DeviceDashboard/callSettings/CallSettings';
import InputOutput from 'features/RemoteManagement/DeviceDashboard/inputOutput/InputOutput';
import Paging from 'features/RemoteManagement/DeviceDashboard/paging/Paging';
import SoundSettings from 'features/RemoteManagement/DeviceDashboard/soundSettings/SoundSettings';
import Integrations from 'features/RemoteManagement/DeviceDashboard/integrations/Integrations';
import Maintenance from 'features/RemoteManagement/DeviceDashboard/maintenance/Maintenance';
import Transfer from 'features/RemoteManagement/DeviceDashboard/transfer/Transfer';
import SecuritySettings from 'features/RemoteManagement/DeviceDashboard/securitySettings/SecuritySettings';
import UnitInformation from 'features/RemoteManagement/SiteDashboard/TenantManagement/Units/UnitDashboard/UnitInformation';
import LiftControl from 'features/RemoteManagement/DeviceDashboard/liftControl/LiftControl';
import SiteSettings from 'features/RemoteManagement/SiteDashboard/Settings/SiteSettings';
import SimBilling from 'features/SimBilling/Components/Entrypoints/Home/SimBilling';
import Site from 'features/SimBilling/Components/Entrypoints/Site';
import GWDevices from 'features/SimBilling/Components/Entrypoints/Gwdevices/GWDevices';
import SimBillingingInvoices from 'features/SimBilling/Components/Entrypoints/Billing/Billing';
import QuikspecSummary from 'features/QuikSpec/Components/UiParts/QuikspecSummary';
import DealerCompanies from 'features/DealerCompanies/Components/Entrypoints/DealerCompanies';
import Login from '../features/Auth/components/Login';
import Registration from '../features/Auth/components/Registration';
import ConfirmAccount from '../features/Auth/components/ConfirmAccount';
import ResetPassword from '../features/Auth/components/PasswordReset/ResetPassword';
import AuthLayout from '../features/Auth/AuthLayout';
import { PwdSuccessfullyChanged } from 'features/Auth/components/PwdSuccessfullyChanged';
import MfaVerificationForm from '../features/Auth/components/mfa/MfaVerificationForm';
import { ProfileSuccessfullyVerified } from 'features/Auth/components/ProfileSuccessfullyVerified';
import NewPassword from 'features/Auth/components/PasswordReset/NewPassword';
import { getCurrentUser } from 'store/slices/usersSlice';
import { useSelector } from 'react-redux';
import {
  useLazyGetBranchWithPublicIdQuery,
  useLazyGetUserWithPublicIdQuery,
  useLazyGetUserBillingInformationQuery
} from 'services/aiphoneCloud';
import { useEffect, useMemo } from 'react';
import UserProfile from 'UserProfile/UserProfile';
import AdminResetChallenge from 'features/Auth/components/PasswordReset/AdminResetChallenge';
import ErrorBoundaryWrapper from 'shared/utils/ErrorBoundaryWrapper';
import SetupWizard from 'features/RemoteManagement/NewSiteWizard/entries/SetupWizard';
import { EnumList, fetchEnumList, fetchLocalEnumList } from 'shared/utils/EnumUtils';
import AddressBook from '../features/RemoteManagement/DeviceDashboard/addressBook/AddressBook';
import DoorRelease from '../features/RemoteManagement/DeviceDashboard/subMaster/DoorRelease';
import QRAccessCodes from 'features/RemoteManagement/SiteDashboard/TenantManagement/Units/QRAccessCodes';
import EntranceSettings from 'features/RemoteManagement/DeviceDashboard/entrance/EntranceSettings';
import PhoneSettings from 'features/RemoteManagement/DeviceDashboard/gateway/PhoneSettings';
import { Box, CircularProgress } from '@mui/material';
import SiteUsers from 'features/RemoteManagement/SiteDashboard/SiteUsers/SiteUsers';
import { RootState } from 'store';
import { LoginAttemptLimitReached } from 'features/Auth/components/LoginAttemptLimitReached';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import DeploymentInformation from 'features/LicenseManagement/pages/[deploymentId]/deploymentInformation';
import LicenseHistory from 'features/LicenseManagement/pages/[deploymentId]/licenseHistory';
import LicenseDetails from 'features/LicenseManagement/pages/[deploymentId]/licenseDetail';
import ResponseKeyStep from 'features/LicenseManagement/pages/[deploymentId]/activateLicense/responseKeyStep';
import DeploymentBilling from 'features/LicenseManagement/pages/[deploymentId]/deploymentBilling';
import useReduxBranches from 'features/Dashboard/Hooks/useReduxBranches';
import LicenseManagement from 'features/LicenseManagement/pages';
import { useFetchAiphoneToHartmannBranchMapping } from 'features/LicenseManagement/hooks/useAiphoneToHartmannBranchMapping';
import CloudDeploymentPanels from 'features/LicenseManagement/pages/[deploymentId]/panels';
import SiteDashboard from 'features/RemoteManagement/SiteDashboard/SiteDashboard';

const PrivateRoutes = () => {
  const [getUser] = useLazyGetUserWithPublicIdQuery();
  const [getUserBillingInformation] = useLazyGetUserBillingInformationQuery();
  const currentUser = useSelector(getCurrentUser);
  const userId = localStorage.getItem('userId');
  const token = localStorage.getItem('token');
  const cookieConsent = localStorage.getItem('cookieConsent');
  const { currentBranch } = useReduxBranches();
  const branchPermissions = useSelector((state: RootState) => state.users.currentUser?.permissions.branch);

  const navigate = useNavigate();
  const location = useLocation();

  const branchList = useMemo(() => Object.keys(branchPermissions || {}), [branchPermissions]);
  const [getBranch] = useLazyGetBranchWithPublicIdQuery();

  useEffect(() => {
    const fetchUserData = async () => {
      if (!currentUser && userId) {
        const user = await getUser(userId).unwrap();
        if (user) {
          await getUserBillingInformation(userId)
            .unwrap()
            .catch(() => {
              // Error absorbed silently
            });
        }
      }
    };

    fetchUserData();
  }, [currentUser, userId, getUser, getUserBillingInformation]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const hasBranchParam = params.has('aiphoneBranchPublicId');
    if (location.pathname.includes('/acniolicensing') && !hasBranchParam) {
      navigate('/acniolicensing');
    }
  }, [currentBranch]);

  useEffect(() => {
    const fetchBranch = async () => {
      const params = new URLSearchParams(location.search);
      const aiphoneBranchPublicId = params.get('aiphoneBranchPublicId');

      const savedBranchId = sessionStorage.getItem('selectedBranchPublicId');
      const targetBranchId =
        (location.pathname.includes('/acniolicensing') && aiphoneBranchPublicId) || savedBranchId || branchList[0];

      if (!currentBranch && targetBranchId) {
        const result = await getBranch(targetBranchId).unwrap();
        queryClient.setQueryData(['currentBranch'], result);
      }
    };

    if (!currentBranch && branchList.length > 0) {
      fetchBranch();
    }
  }, [currentBranch, branchList, getBranch, location]);

  // #region acniolicensing
  useEffect(() => {
    if (currentBranch) {
      queryClient.setQueryData(['currentBranch'], currentBranch);
    }
  }, [currentBranch, queryClient]);

  useFetchAiphoneToHartmannBranchMapping(currentBranch);

  const shouldNavigateToLogin = (!currentUser && !userId && !cookieConsent) || !token;

  if (shouldNavigateToLogin) {
    localStorage.clear();
    return <Navigate to="/auth/login" replace />;
  }

  if (!currentUser) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}
      >
        <CircularProgress />
      </Box>
    ); // Show a loading state until the user is fetched
  }

  return (
    <PermissionProvider user={currentUser}>
      <SiteProvider>
        <Outlet />
      </SiteProvider>
    </PermissionProvider>
  );
};

const queryClient = new QueryClient();

function AppRoutes() {
  const enumList: EnumList = fetchLocalEnumList();

  useEffect(() => {
    if (enumList.isLocal) {
      const fetchEnums = async () => {
        await fetchEnumList();
      };
      fetchEnums().then();
    }
  }, [enumList]);

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthProvider>
          <ErrorBoundaryWrapper fallback={<div>Oops! Something went wrong. Redirecting...</div>}>
            <Routes>
              <Route path="/auth/*" element={<AuthLayout />}>
                <Route path="login" element={<Login />} />
                <Route path="mfa-verification" element={<MfaVerificationForm />} />
                <Route path="registration" element={<Registration />} />
                <Route path="confirm-account" element={<ConfirmAccount />} />
                <Route path="reset-password" element={<ResetPassword />} />
                <Route path="reset-required" element={<AdminResetChallenge />} />
                <Route path="new-password" element={<NewPassword />} />
                <Route path="pwd-successfully-updated" element={<PwdSuccessfullyChanged />} />
                <Route path="profile-successfully-verified" element={<ProfileSuccessfullyVerified />} />
                <Route path="login-attempt-limit" element={<LoginAttemptLimitReached />} />
              </Route>
              <Route element={<PrivateRoutes />}>
                <Route path="/" element={<Dashboard />}>
                  <Route path="/" element={<Home />} />
                  <Route path="/profile" element={<UserProfile />} />
                  <Route path="/quikspec" element={<Quikspec />} />
                  <Route path="/simbilling" element={<SimBilling />} />
                  {/* TODO: Implement the acNio component */}
                  <Route path="/simbilling/sites/:publicId/site" element={<Site />} />
                  <Route path="/simbilling/sites/:publicId/billing" element={<SimBillingingInvoices />} />
                  <Route path="/simbilling/sites/:publicId/gwdevices" element={<GWDevices />} />
                  <Route path="/acniolicensing" element={<LicenseManagement />} />
                  <Route
                    path="/acniolicensing/deployments/:deploymentId/deploymentInformation"
                    element={<DeploymentInformation />}
                  />
                  <Route
                    path="/acniolicensing/deployments/:deploymentId/activateLicense/responseKeyStep"
                    element={<ResponseKeyStep />}
                  />
                  <Route path="/acniolicensing/deployments/:deploymentId/panels" element={<CloudDeploymentPanels />} />
                  <Route path="/acniolicensing/deployments/:deploymentId/licenseDetails" element={<LicenseDetails />} />
                  <Route path="/acniolicensing/deployments/:deploymentId/licenseHistory" element={<LicenseHistory />} />
                  <Route
                    path="/acniolicensing/deployments/:deploymentId/billingDetails"
                    element={<DeploymentBilling />}
                  />
                  <Route path="/quikspec/newIXIXGSystem" element={<QuikspecIXIXG />} />
                  <Route path="/quikspec/IXIXGSystem/:id" element={<QuikspecIXIXG />} />
                  <Route path="/quikspec/newACSystem" element={<QuikspecAC />} />
                  <Route path="/quikspec/ACSystem/:id" element={<QuikspecAC />} />
                  <Route path="/quikspec/newJPSystem" element={<QuikspecJP />} />
                  <Route path="/quikspec/JPSystem/:id" element={<QuikspecJP />} />
                  <Route path="/quikspec/newLEFSystem" element={<QuikspecLEF />} />
                  <Route path="/quikspec/LEFSystem/:id" element={<QuikspecLEF />} />
                  <Route path="/quikspec/newGTSystem" element={<QuikspecGT />} />
                  <Route path="/quikspec/GTSystem/:id" element={<QuikspecGT />} />
                  <Route path="/quikspec/newJOSystem" element={<QuikspecJO />} />
                  <Route path="/quikspec/JOSystem/:id" element={<QuikspecJO />} />
                  <Route path="/quikspec/newJVSystem" element={<QuikspecJV />} />
                  <Route path="/quikspec/JVSystem/:id" element={<QuikspecJV />} />
                  <Route path="/quikspec/newAXSystem" element={<QuikspecAX />} />
                  <Route path="/quikspec/AXSystem/:id" element={<QuikspecAX />} />
                  <Route path="/quikspec/newKBSystem" element={<QuikspecKB />} />
                  <Route path="/quikspec/KBSystem/:id" element={<QuikspecKB />} />
                  <Route path="/quikspec/newNHXSystem" element={<QuikspecNHX />} />
                  <Route path="/quikspec/NHXSystem/:id" element={<QuikspecNHX />} />
                  <Route path="/quikspec/newTwoWireSystem" element={<QuikspecTwoWire />} />
                  <Route path="/quikspec/TwoWireSystem/:id" element={<QuikspecTwoWire />} />
                  <Route path="/quikspec/newLEFSystem" element={<QuikspecLEF />} />
                  <Route path="/quikspec/LEFSystem/:id" element={<QuikspecLEF />} />
                  <Route path="/quikspec-summary" element={<QuikspecSummary />} />
                  <Route path="/remotemanagement" element={<RemoteManagement />} />
                  <Route path="/appCalendar" element={<Calendar />} />
                  <Route path="/CallGroup" element={<CallGroup />} />
                  <Route path="/deviceSettingNext" element={<DeviceSettingNext />} />
                  <Route path="/site" element={<SiteLoader />}>
                    <Route path="/site/:id" element={<SiteDashboard />} />
                    <Route path="/site/:id/dashboard" element={<SiteDashboard />} />
                    <Route path="/site/:id/users" element={<SiteUsers />} />
                    <Route path="/site/:id/wizard" element={<SetupWizard />} />
                    <Route path="/site/:id/settings" element={<SiteSettings />} />
                    <Route path="/site/:id/units" element={<Units />} />
                    <Route path="/site/:id/devices/:deviceid/stationinfo" element={<StationInfo />} />
                    <Route path="/site/:id/devices/:deviceid/networkinfo" element={<NetworkInfo />} />
                    <Route path="/site/:id/devices/:deviceid/callSettings" element={<CallSettings />} />
                    <Route path="/site/:id/devices/:deviceid/addressbook" element={<AddressBook />} />
                    <Route path="/site/:id/devices/:deviceid/doorrelease" element={<DoorRelease />} />
                    <Route path="/site/:id/devices/:deviceid/inputoutput" element={<InputOutput />} />
                    <Route path="/site/:id/devices/:deviceid/securitysettings" element={<SecuritySettings />} />
                    <Route path="/site/:id/devices/:deviceid/entrancesettings" element={<EntranceSettings />} />
                    <Route path="/site/:id/devices/:deviceid/phonesettings" element={<PhoneSettings />} />
                    <Route path="/site/:id/devices/:deviceid/soundsettings" element={<SoundSettings />} />
                    <Route path="/site/:id/devices/:deviceid/integrations" element={<Integrations />} />
                    <Route path="/site/:id/devices/:deviceid/liftcontrol" element={<LiftControl />} />
                    <Route path="/site/:id/devices/:deviceid/maintenance" element={<Maintenance />} />
                    <Route path="/site/:id/devices/:deviceid/transfer" element={<Transfer />} />
                    <Route path="/site/:id/devices/:deviceid/paging" element={<Paging />} />
                    <Route path="/site/:id/units/:unitid/unitinformation" element={<UnitInformation />} />
                    <Route path="/site/:id/units/:unitid/qraccesscodes" element={<QRAccessCodes />} />
                  </Route>
                  <Route path="/users" element={<Users />} />
                  <Route path="/dealercompanies" element={<DealerCompanies />} />
                  <Route path="/legal" element={<Legal />} />
                  <Route path="/support" element={<Support />} />
                  <Route path="*" element={<ReDirectedPage />} />
                </Route>
              </Route>
            </Routes>
          </ErrorBoundaryWrapper>
        </AuthProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default AppRoutes;
