import { Alert, Box, Typography, List, ListItem, AlertTitle, Button } from '@mui/material';
import GeneralInfo from '../../UiParts/GeneralInfo';
import { ixgHidden } from '../../../Types/IXGTypes';
import { ixDoorStations, ixEmergencyStations } from '../../../Types/IXTypes';
import { ixixgMasterStations, ixixgEntranceStations, ixixgMobile, ixixgAdaptors } from '../../../Types/IXIXGTypes';
import { acControlKits, acLicenses, acReaderKits, acHidden, acSystemConstraints } from '../../../Types/ACTypes';
import QuikspecCategoryTabs from '../../UiParts/QuikspecCategoryTabs';
import QuikspecCategoryIxgMobile from '../../UiParts/QuikspecCategoryIxgMobile';
import QuikspecCategoryAcControllers from '../../UiParts/QuikspecCategoryAcControllers';
import QuikspecCategoryAcLicenses from '../../UiParts/QuikspecCategoryAcLicenses';
import QuikspecSystemConstraints from '../../UiParts/QuikspecSystemConstraints';
import { updateFormValuesIXIXG } from '../../../Utils/QuikspecUpdateFormValuesIXIXG';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import QuikspecSubmitButton from '../../UiParts/QuikspecSubmitButton';
import { useNavigate, useLocation } from 'react-router-dom';
import QuikspecNote from 'features/QuikSpec/Components/UiParts/QuikspecNote';
import { FormValues } from '../../../Types/QuikspecFormTypes';
import getInitialFormValues from 'features/QuikSpec/Utils/QuikspecInitialFormValues';
import { createQuikSpecAndNavigate, updateQuikspecAndNavigate } from '../../../Utils/QuikspecActionAndNavigate';
import { analyticsService } from 'services/googleAnalyticsService';
import { useTranslation } from 'react-i18next';
import useQuikSpecFormValidationSchema from 'features/QuikSpec/Hooks/useQuikSpecFormValidationSchema';
import { useQuikspecFeaturedSystems } from 'features/QuikSpec/Hooks/quikSpecDevices';
import useC2 from 'features/QuikSpec/Hooks/useC2';

const QuikspecIXIXG = () => {
  const { t } = useTranslation();
  const FeaturedSystems = useQuikspecFeaturedSystems();
  const { C2ApiFailed } = useC2();
  const navigate = useNavigate();
  const [acOpen, setACOpen] = useState(false);
  const location = useLocation();
  const state = location.state || {};
  const siteName: string = location.state ? (location.state.siteName as string) : '';
  const siteIndex = FeaturedSystems.findIndex((system) => system.siteName === siteName);
  const system = FeaturedSystems[siteIndex];
  const systemName = system ? system.seriesName : '';
  const systemforC2 = system ? system.systemforC2 : '';
  const alertError = t('Shared.Error');
  const additionalAccessControl = t('IX|IXG_Additional_AccessControl_Options_Dialog');
  const addAcSeries = t('Button_Add_Ac_Series_To_Ix_Spec');
  const goBack = t('Need_To_Go_Back_Dialog');
  const returnToIx = t('Button_Return_To_System_Page', { page: 'IX/IXG' });

  const categories = [
    ...ixDoorStations,
    ...ixEmergencyStations,
    ...ixgHidden,
    ...ixixgMasterStations,
    ...ixixgEntranceStations,
    ...ixixgMobile,
    ...ixixgAdaptors,
    ...acControlKits,
    ...acLicenses,
    ...acReaderKits,
    ...acHidden
  ];

  const stateFormValues = state && Object.keys(state).length > 1 ? state.formValues : undefined;

  const initialValues = getInitialFormValues(systemName, categories, stateFormValues, systemforC2);
  const { ix } = useQuikSpecFormValidationSchema();

  const handleSubmit = async (values: FormValues, actions: any) => {
    try {
      actions.setSubmitting(true);
      //update and filter form values to only include items with quantity greater than 0
      values = updateFormValuesIXIXG(values);

      const filteredItems = values.items.filter((item) => item.quantity > 0);
      const IXGItems = filteredItems.filter(
        (item) =>
          item.itemName === 'SBX-IXGDM7' ||
          item.itemName === 'IXG-DM7-BOX' ||
          item.itemName === 'IXG-FMK-2C7' ||
          item.itemName === 'IXGW-TGW' ||
          item.itemName === 'IXG-APP' ||
          item.itemName === 'LIFETIME_LICENSE' ||
          item.itemName === 'IXG-MK' ||
          item.itemName === 'IXG-2C7' ||
          item.itemName === 'IXG-2C7-L' ||
          item.itemName === 'IXG-DM7-HIDA'
      );

      if (IXGItems.length > 0) {
        values.systemforC2 = 'IXG';
      } else {
        values.systemforC2 = 'IX';
      }

      //determine if form is in edit mode
      const editing = stateFormValues != undefined;
      if (editing) {
        //update QuikSpec and navigate to QuikSpec Summary page
        await updateQuikspecAndNavigate(values, actions, navigate);
        actions.setSubmitting(false);
        return;
      } else {
        //create QuikSpec and navigate to QuikSpec Summary page
        await createQuikSpecAndNavigate(values, actions, navigate);
        actions.setSubmitting(false);
      }
    } catch (error) {
      actions.setSubmitting(false);
      actions.setErrors({ submit: t('Something_went_wrong_try_again') });
    }
  };

  const handleAddACSeriesClick = () => {
    setACOpen(true); // This sets the AC Series section to be open
    // Track the event with your analytics tracking service
    analyticsService.trackButtonClick('QuikSpec', 'Add AC Series');
  };

  // Check if at least one controller has quantity > 0 for relevant items
  const elevatorOrIoSelectionCheck = (values: any) => {
    const items = Object.values(values?.items);
    const hasElevatorOrIo = items.some(
      (item: any) =>
        (item.itemName === 'NUMBER_OF_FLOORS' ||
          item.itemName === 'NUMBER_OF_INPUTS' ||
          item.itemName === 'NUMBER_OF_OUTPUTS') &&
        item.quantity > 0
    );
    return hasElevatorOrIo;
  };

  return (
    <Box>
      {C2ApiFailed && (
        <Alert severity="warning">
          <AlertTitle>{t('Missing_C2_Data')}</AlertTitle>
          {t('C2_Data_Missing_Detail')}
        </Alert>
      )}
      <Formik initialValues={initialValues} validationSchema={ix} onSubmit={handleSubmit}>
        {({ values, errors, touched, isSubmitting }) => {
          return (
            <Form>
              <GeneralInfo editing={state.formValues != undefined} />
              {/* Render QuikspecCategoryTabs for each category */}
              {!acOpen && (
                <Box>
                  <QuikspecCategoryTabs categories={ixixgMasterStations} />
                  <QuikspecCategoryIxgMobile categories={ixixgMobile} />
                  <QuikspecCategoryTabs categories={ixixgEntranceStations} />
                  <QuikspecCategoryTabs categories={ixDoorStations} />
                  <QuikspecCategoryTabs categories={ixEmergencyStations} />
                  <QuikspecCategoryTabs categories={ixixgAdaptors} />
                  <Box alignItems="center">
                    <Typography variant="h4" align="center">
                      {additionalAccessControl}
                    </Typography>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleAddACSeriesClick} // Use the new handler here
                      disabled={isSubmitting}
                    >
                      {addAcSeries}
                    </Button>
                  </Box>
                </Box>
              )}
              {acOpen && (
                <Box>
                  <QuikspecSystemConstraints systemConstraints={acSystemConstraints} />
                  <QuikspecCategoryAcControllers categories={acControlKits} />
                  <QuikspecCategoryAcLicenses
                    categories={acLicenses}
                    isESSDisabled={elevatorOrIoSelectionCheck(values)}
                  />
                  <QuikspecCategoryTabs categories={acReaderKits} />
                  <Box alignItems={'center'}>
                    <Typography variant="h4" align="center">
                      {goBack}
                    </Typography>
                    <Button variant="contained" color="secondary" onClick={() => setACOpen(false)}>
                      {returnToIx}
                    </Button>
                  </Box>
                </Box>
              )}
              {(stateFormValues
                ? Object.keys(errors).length > 0
                : Object.keys(touched).length > 0 && Object.keys(errors).length > 0) && (
                <Alert severity="error">
                  <AlertTitle>{alertError}</AlertTitle>
                  <List>
                    {Object.keys(errors).map((field) => (
                      <ListItem key={field}>
                        <Typography variant="body2" color="error">
                          {errors[field]}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Alert>
              )}
              <QuikspecSubmitButton
                editing={state.formValues != undefined}
                errors={errors}
                submitting={isSubmitting}
                C2ApiFailed={C2ApiFailed}
              />
              <QuikspecNote systemName={initialValues.systemName} />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default QuikspecIXIXG;
