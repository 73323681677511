import axios, { AxiosError, isAxiosError } from 'axios';
import {
  AiphoneBranchToHartmannBranchMapping,
  CreateDeploymentRequest,
  DeleteDeploymentArgs,
  CreateHartmannBranchRequest,
  DeploymentType,
  CreateStripeCheckoutSessionRequest,
  SaveEulaRequest,
  ValidateLicenseCardRequest,
  CreateActivatedLicenseWithCardRequest,
  ValidateRequestKeyRequest,
  CloudDeploymentUpdateSubscriptionRequest,
  RearmLicenseRequest,
  AddPanelRequest,
  RemovePanelRequest
} from '../../components/common/Types';
import axiosApiInstance from 'shared/api/axiosConfig';
import CONFIG from '../../../../config';
import { ApiError, classifyAndThrowError, ErrorType } from 'shared/api/ApiError';
import { DEPLOYMENTS_PAGE_SIZE_DEFAULT } from 'features/LicenseManagement/components/deployment/DeploymentList/DeploymentDataGrid';

export const createCloudDeployment = async (
  aiphoneBranchPublicId: string | undefined,
  data: CreateDeploymentRequest
) => {
  try {
    const params = data;
    const response = await axiosApiInstance.post(
      CONFIG.licenseApiEndpoint + `/branches/${aiphoneBranchPublicId}/cloudDeployment`,
      params
    );
    if (response.status === 201) {
      return response.data;
    } else {
      throw new ApiError('Failed to fetch deployments', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const createLocalDeployment = async (
  aiphoneBranchPublicId: string | undefined,
  data: CreateDeploymentRequest
) => {
  try {
    const params = data;
    const response = await axiosApiInstance.post(
      CONFIG.licenseApiEndpoint + `/branches/${aiphoneBranchPublicId}/localDeployment`,
      params
    );
    if (response.status === 201) {
      return response.data;
    } else {
      throw new ApiError('Failed to fetch deployments', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const fetchDeploymentsForCloudUser = async (cloudUserPublicId: string) => {
  try {
    const params = { cloudUserPublicId };
    const response = await axiosApiInstance.get(CONFIG.licenseApiEndpoint + '/deployments', {
      params
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new ApiError('Failed to fetch deployments', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const fetchDeploymentsForBranch = async (
  aiphoneBranchPublicId: string,
  lastEvaluatedKey?: any,
  pageSize = DEPLOYMENTS_PAGE_SIZE_DEFAULT
) => {
  try {
    const params = new URLSearchParams();

    if (lastEvaluatedKey) {
      params.append('lastEvaluatedKey', encodeURIComponent(JSON.stringify(lastEvaluatedKey)));
    }

    params.append('pageSize', pageSize.toString());
    const response = await axiosApiInstance.get(
      `${CONFIG.licenseApiEndpoint}/branches/${aiphoneBranchPublicId}/deploymentsList?${params.toString()}`
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new ApiError('Failed to fetch deployments for branch', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const fetchDeploymentById = async (branchId: string, deploymentId: string) => {
  try {
    const response = await axiosApiInstance.get(
      `${CONFIG.licenseApiEndpoint}/branches/${branchId}/deployments/${deploymentId}`
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new ApiError('Failed to fetch deployment by ID', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const deleteDeployment = async ({
  aiphoneBranchPublicId,
  deploymentId,
  hartmannDeploymentId,
  cloudUserPublicId,
  hartmannBranchId
}: DeleteDeploymentArgs): Promise<void> => {
  try {
    const params = {
      hartmannBranchId,
      hartmannDeploymentId,
      cloudUserPublicId
    };
    const response = await axiosApiInstance.delete(
      `${CONFIG.licenseApiEndpoint}/branches/${aiphoneBranchPublicId}/deployments/${deploymentId}`,
      {
        params
      }
    );
    if (response.status !== 204) {
      throw new ApiError('Failed to delete deployment', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const updateDeploymentByDeploymentId = async (branchId: string, deploymentId: string, data: any) => {
  try {
    const response = await axiosApiInstance.put(
      `${CONFIG.licenseApiEndpoint}/branches/${branchId}/deployments/${deploymentId}`,
      data
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new ApiError('Failed to update deployment', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

// #region Aiphone To Hartmann Branch Mapping
export const fetchAiphoneToHartmannBranchMapping = async (
  aiphoneBranchPublicId: string
): Promise<AiphoneBranchToHartmannBranchMapping | undefined> => {
  const params = { aiphoneBranchPublicId };
  try {
    const response = await axiosApiInstance.get(CONFIG.licenseApiEndpoint + '/aiphoneToHartmannBranchMapping', {
      params
    });

    if (response.status === 200) {
      // If data is successfully retrieved, return the mapping data
      return {
        hartmannBranchId: response.data.hartmannBranchId,
        aiphoneBranchPublicId: response.data.aiphoneBranchPublicId
      };
    } else {
      // If the status is not 200, throw an error
      throw new ApiError('Failed to fetch Aiphone To HartmannBranchMapping', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    if (isAxiosError(error) && error.response?.status === 404) {
      // If the status is 404, it indicates that the data does not exist.
      // Instead of throwing an error, return null data.
      // Reason: In the case of 404, a subsequent API call for creating the data will be made,
      // so it is necessary to distinguish it from other errors (e.g., server or network errors).
      return { hartmannBranchId: null, aiphoneBranchPublicId: aiphoneBranchPublicId };
    }
    // For errors other than 404, React Query's retry mechanism will handle the retries.
    // Therefore, throw the appropriate error to allow React Query to manage the retry process.
    classifyAndThrowError(error);
  }
};

export const createAiphoneToHartmannBranchMapping = async (
  request: CreateHartmannBranchRequest
): Promise<AiphoneBranchToHartmannBranchMapping | undefined> => {
  try {
    const response = await axiosApiInstance.post(
      CONFIG.licenseApiEndpoint + '/aiphoneToHartmannBranchMapping',
      request
    );

    if (response.status === 201) {
      return response.data;
    } else {
      throw new ApiError('Failed to create Aiphone To HartmannBranchMapping', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};
// #endregion

export const fetchStripeProducts = async (
  countryNumericCode: string,
  deploymentType: DeploymentType,
  branchId: string
) => {
  try {
    const params = {
      countryNumericCode,
      deploymentType
    };
    const response = await axiosApiInstance.get(`${CONFIG.licenseApiEndpoint}/branches/${branchId}/stripe/products`, {
      params
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new ApiError('Failed to fetch stripe products', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const createStripeCheckoutSession = async (
  aiphoneBranchPublicId: string | undefined,
  deploymentId: string,
  deploymentType: DeploymentType,
  params: CreateStripeCheckoutSessionRequest
) => {
  try {
    const deploymentTypeString = deploymentType === DeploymentType.Cloud ? 'cloudDeployment' : 'localDeployment';
    //if isAddOn is true then append '/AddOns' to the url
    const isAddOn = params.isAddOn;
    const stripeCheckoutSessionUrl = `${
      CONFIG.licenseApiEndpoint
    }/branches/${aiphoneBranchPublicId}/${deploymentTypeString}/${deploymentId}${isAddOn ? '/AddOns' : ''}/checkout`;
    const response = await axiosApiInstance.post(stripeCheckoutSessionUrl, params);
    if (response.status === 201) {
      return response;
    } else {
      throw new ApiError('Failed to fetch stripe checkout session', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const validateLicenseCard = async (aiphoneBranchPublicId: string, request: ValidateLicenseCardRequest) => {
  try {
    const response = await axiosApiInstance.post(
      CONFIG.licenseApiEndpoint + `/branches/${aiphoneBranchPublicId}/localDeployment/validateLicenseCode`,
      request
    );
    if (response.status === 201) {
      return response.data;
    } else {
      throw new ApiError('Failed to validate license card', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const fetchLatestEula = async () => {
  try {
    const response = await axiosApiInstance.get(`${CONFIG.licenseApiEndpoint}/cloudDeployment/latestLicenseAgreement`);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new ApiError('Failed to fetch latest Eula', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const saveEula = async (aiphoneBranchPublicId: string, deploymentId: string, data: SaveEulaRequest) => {
  try {
    const response = await axiosApiInstance.post(
      CONFIG.licenseApiEndpoint + `/branches/${aiphoneBranchPublicId}/cloudDeployment/${deploymentId}/licenseAgreement`,
      data
    );
    if (response.status === 201) {
      return response.data;
    } else {
      throw new ApiError('Failed to save EULA', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const fetchLicenseById = async (branchId: string, licenseId: string) => {
  try {
    const response = await axiosApiInstance.get(
      `${CONFIG.licenseApiEndpoint}/branches/${branchId}/licenses/${licenseId}`
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new ApiError('Failed to fetch license by ID', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const fetchLicenseByIdUsingLongPolling = async (branchId: string, licenseId: string) => {
  try {
    const response = await axiosApiInstance.get(
      `${CONFIG.licenseApiEndpoint}/branches/${branchId}/licenses/${licenseId}/polling`
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new ApiError('Failed to fetch license by ID', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const createActivatedLicenseWithCard = async (
  aiphoneBranchPublicId: string,
  deploymentId: string,
  data: CreateActivatedLicenseWithCardRequest
) => {
  try {
    const response = await axiosApiInstance.post(
      CONFIG.licenseApiEndpoint +
        `/branches/${aiphoneBranchPublicId}/localDeployment/${deploymentId}/licenseCard/activate`,
      data
    );
    if (response.status === 201) {
      return response.data;
    } else {
      throw new ApiError('Failed to activate licenseCard', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const fetchInvoicesByDeploymentId = async (aiphoneBranchPublicId: string, deploymentId: string) => {
  try {
    const response = await axiosApiInstance.get(
      `${CONFIG.licenseApiEndpoint}/branches/${aiphoneBranchPublicId}/${deploymentId}/invoices`
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new ApiError('Failed to fetch invoices', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const fetchPaymentInfo = async (
  aiphoneBranchPublicId: string,
  subscriptionId: string,
  stripeCustomerId: string
) => {
  try {
    const response = await axiosApiInstance.get(
      `${CONFIG.licenseApiEndpoint}/branches/${aiphoneBranchPublicId}/stripe/${subscriptionId}/paymentInfo`,
      {
        params: {
          stripeCustomerId
        }
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new ApiError('Failed to fetch payment Info', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const cancelStripeSubscription = async (subscriptionId: string, aiphoneBranchPublicId: string | undefined) => {
  try {
    const response = await axiosApiInstance.delete(
      CONFIG.licenseApiEndpoint + `/branches/${aiphoneBranchPublicId}/stripe/${subscriptionId}/cancel`
    );
    if (response.status !== 204) {
      throw new ApiError('Failed to cancel subscription', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const validateRequestKey = async (aiphoneBranchPublicId: string, request: ValidateRequestKeyRequest) => {
  try {
    const response = await axiosApiInstance.post(
      CONFIG.licenseApiEndpoint + `/branches/${aiphoneBranchPublicId}/localDeployment/validateRequestKey`,
      request
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new ApiError('Failed to validate request key', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const updateCloudDeploymentSubscription = async (
  aiphoneBranchPublicId: string | undefined,
  deploymentId: string,
  params: CloudDeploymentUpdateSubscriptionRequest
) => {
  try {
    const response = await axiosApiInstance.post(
      CONFIG.licenseApiEndpoint +
        `/branches/${aiphoneBranchPublicId}/cloudDeployment/${deploymentId}/update/subscription`,
      params
    );

    if (response.status === 201) {
      return response.data;
    } else {
      throw new ApiError('Failed to update subscription for cloud deployment', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<{ paymentUpdateUrl?: string; message?: string }>;

      if (axiosError.response?.status === 402) {
        const paymentUpdateUrl = axiosError.response.data?.paymentUpdateUrl;

        if (paymentUpdateUrl) {
          return {
            error: axiosError.response.data?.message,
            paymentUpdateUrl
          };
        }
      }
    }
    classifyAndThrowError(error);
  }
};

export const rearmLicense = async (aiphoneBranchPublicId: string | undefined, request: RearmLicenseRequest) => {
  try {
    const response = await axiosApiInstance.post(
      CONFIG.licenseApiEndpoint + `/branches/${aiphoneBranchPublicId}/localDeployment/rearmLicense`,
      request
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new ApiError('Failed to validate request key', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const addPanel = async (aiphoneBranchPublicId: string, deploymentId: string, data: AddPanelRequest) => {
  try {
    const response = await axiosApiInstance.post(
      CONFIG.licenseApiEndpoint + `/branches/${aiphoneBranchPublicId}/cloudDeployment/${deploymentId}/panel`,
      data
    );
    if (response.status === 201) {
      return response.data;
    } else {
      throw new ApiError('Failed to create panel', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    console.log('error', error);
    classifyAndThrowError(error);
  }
};

export const removePanel = async (
  aiphoneBranchPublicId: string,
  deploymentId: string,
  hartmannPanelId: number,
  data: RemovePanelRequest
): Promise<void> => {
  try {
    const response = await axiosApiInstance.delete(
      `${CONFIG.licenseApiEndpoint}/branches/${aiphoneBranchPublicId}/cloudDeployment/${deploymentId}/panel/${hartmannPanelId}`,
      { data: data }
    );
    if (response.status !== 204) {
      throw new ApiError('Failed to remove panel', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};
