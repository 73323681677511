import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import InfoScreen from '../../../layouts/components/InfoScreen';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/utils/StringUtils';

export const ProfileSuccessfullyVerified: React.FC = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const loginStr = t('Shared.Login');
  const returnToItemStr = t('Shared.ReturnToItem');
  const returnToLoginStr = StringUtils.format(returnToItemStr, loginStr);

  return (
    <>
      <InfoScreen
        title={t('ProfileSuccessfullyVerified_Success')}
        message={t('ProfileSuccessfullyVerified_Message')}
        iconComponent={<CheckCircleRoundedIcon sx={{ fontSize: '48px', color: 'success.main' }} />}
      />
      <Grid container justifyContent="center">
        <Grid item>
          <Button sx={styles.returnToLogin} onClick={() => navigate('/auth/login')}>
            {returnToLoginStr}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  returnToLogin: {
    color: '#0071CE',
    fontFamily: "'Roboto Condensed', sans-serif",
    fontWeight: 'bold',
    marginTop: 2
  }
};
