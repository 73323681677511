export const REACT_QUERY_ONE_HOURS_STALE_TIME = 60 * (60 * 1000);

export const REACT_QUERY_TEN_MINUTES_STALE_TIME = 10 * (60 * 1000);

export interface LicenseItem {
  licenseId: string;
  name: string;
  duration: number;
  activationDate: string;
  expirationDate: string;
  status: LicenseStatus;
  hartmannDeploymentId: number;
  hartmannBranchId: number;
  updatedOn?: string;
  updatedByUserPublicId?: string;
  numOfDoors?: number;
  numOfExpanders?: number;
  numOfElevators?: number;
  numOfCameras?: number;
  stripeSubscriptionId?: string;
  createdOn: string;
  createdByUserPublicId: string;
  SK: string;
  PK: string;
  packageId?: number;
  stripeProductPriceId?: string;
  responseKey?: string;
  requestKey?: string;
  licenseCardCode?: string;
  hartmannAccountNumber: number;
  hasBeenRearmed?: boolean;
  aiphoneBranchPublicId: string;
}

export interface Deployment {
  deploymentId: string;
  hartmannDeploymentId: number;
  name: string;
  description: string;
  createdOn: string;
  updatedOn: string;
  features: any; // Can be updated to a specific type if known
  contact: Contact;
  address: Address;
  emailRecipients?: string[];
  eula: boolean;
  tenant?: Tenant;
  aiphoneBranchPublicId: string;
  hartmannBranchId: number;
  createdByUserPublicId: string;
  updatedByUserPublicId: string;
  linkedAiphoneSitePublicId?: string | null;
  deploymentType: number;
  subscriptionStatus?: SubscriptionStatus;
  licenseStatus: LicenseStatus;
  licenseId: string;
  licenseExpirationDate: string;
  licenseStripeSubscriptionId?: string;
  licenses: LicenseItem[];
}

export interface Contact {
  name: string;
  emailAddress: string;
  phoneNumber: string;
  companyName: string;
}

export interface Address {
  aptSuiteBuilding?: string | null;
  countryName: string;
  city: string;
  stateName: string;
  street: string;
  postalCode: string;
}

export enum LicenseStatus {
  Activated = 'Activated',
  Incomplete = 'Incomplete',
  Unactivated = 'Unactivated',
  Expired = 'Expired',
  Unknown = 'Unknown',
  Pending = 'Pending',
  Error = 'Error'
}

export interface ContactDB {
  emailAddress: string;
  name: string;
  phoneNumber: string;
}

export interface DeploymentDetailResponse {
  address: Address;
  aiphoneBranchPublicId: string;
  contact: ContactDB;
  deploymentId: string;
  deploymentType: DeploymentType;
  description: string;
  emailRecipients?: string[];
  eula: boolean;
  features: string[] | null;
  hartmannAccountNumber: number;
  hartmannBranchId: number;
  hartmannDeploymentId: number;
  name: string;
  Pk: string;
  Sk: string;
  tenant?: Tenant;
  licenses: LicenseItem[];
  licenseStatus: LicenseStatus;
  licenseId: string;
  licenseStripeSubscriptionId: string;
  licenseExpirationDate: string;
  licenseStripeInvoiceId?: string;
  licenseStripeProductPriceId?: string;
  linkedAiphoneSitePublicId: string;
  licenseDuration?: number;
  licensePackageId?: number;
  hasBeenRearmed?: boolean;
  createdAt: string;
  createdByUserPublicId: string;
  updatedAt: string;
  updatedByUserPublicId: string;
  subscriptionStatus?: SubscriptionStatus;
  licenseAddOns?: any[];
  panelDetails?: { [key: string]: { macAddress: string; createdOn: string } };
}

export enum SubscriptionStatus {
  Active = 'Active',
  Canceled = 'Canceled'
}

export enum DeploymentType {
  Local = 0,
  Cloud = 1
}

export interface DeleteDeploymentArgs {
  aiphoneBranchPublicId: string;
  deploymentId: string;
  hartmannDeploymentId: number;
  cloudUserPublicId: string;
  hartmannBranchId: number;
}

export interface CreateDeploymentArgs {
  deploymentData: any;
  aiphoneCompanyId: number;
}

export interface AiphoneBranchToHartmannBranchMapping {
  hartmannBranchId: number | null;
  aiphoneBranchPublicId: string;
}

export interface CreateHartmannBranchRequest {
  aiphoneBranchPublicId: string;
  branchName: string;
  email: string;
  address: string;
  city: string;
  postalCode: string;
  country: string;
  region: string;
}

export interface Product {
  id?: string;
  name?: string;
  description: string;
  duration: number;
  deploymentType: number;
  package: number;
  unitAmount?: number;
  currency?: string;
  active?: boolean;
  priceId?: string;
  doorSize: DeploymentDoorSize;
  maxDoorCount: number;
  isAddOn?: boolean;
}

// #region CreateDeploymentRequest
export interface Tenant {
  subdomain: string;
  activated?: boolean;
}
export interface CreateDeployment {
  name: string;
  description: string;
  EULA: boolean;
  type: number;
  emailRecipients: string[];
}

export interface Contact {
  companyName: string; // It is not required for Hartmann’s API calls.
  emailAddress: string;
  emailSubscriptions: number;
  name: string;
  phoneNumber: string;
}

export interface CreateDeploymentRequest {
  tenant: Tenant;
  deployment: CreateDeployment;
  contact: Contact;
  address: Address;
  hartmannBranchId: number;
  linkedAiphoneSitePublicId: string | null;
}

export const initializeCreateDeploymentRequest = (): CreateDeploymentRequest => {
  return {
    tenant: {
      subdomain: ''
    },
    deployment: {
      name: '',
      description: '',
      EULA: false,
      type: DeploymentType.Cloud,
      emailRecipients: []
    },
    contact: {
      companyName: '',
      emailAddress: '',
      emailSubscriptions: 0,
      name: '',
      phoneNumber: ''
    },
    address: {
      street: '',
      city: '',
      postalCode: '',
      stateName: '-',
      countryName: 'United States of America',
      aptSuiteBuilding: ''
    },
    hartmannBranchId: 0,
    linkedAiphoneSitePublicId: null
  };
};

export const initializeUpdateDeploymentRequest = (
  deployment: DeploymentDetailResponse,
  deploymentId: string,
  cloudUserPublicId: string
) => {
  const updatedDeploymentData = {
    deploymentId: deploymentId,
    hartmannBranchId: deployment?.hartmannBranchId || '',
    hartmannDeploymentId: deployment?.hartmannDeploymentId || '',
    accountNumber: deployment?.hartmannAccountNumber || '',
    cloudUserPublicId: cloudUserPublicId,
    deployment: {
      name: deployment?.name || '',
      description: deployment?.description || '',
      type: deployment?.deploymentType == DeploymentType.Local ? 'Local' : 'Cloud'
    },
    contact: {
      name: deployment?.contact?.name || '',
      emailAddress: deployment?.contact?.emailAddress || '',
      phoneNumber: deployment?.contact?.phoneNumber || ''
    },
    address: {
      street: deployment?.address?.street || '',
      aptSuiteBuilding: deployment?.address?.aptSuiteBuilding || '',
      city: deployment?.address?.city || '',
      postalCode: deployment?.address?.postalCode || '',
      stateName: deployment?.address?.stateName || '',
      countryName: deployment?.address?.countryName || ''
    },
    emailRecipients: deployment?.emailRecipients || []
  };
  return updatedDeploymentData;
};

// #endregion

// #region CreateDeploymentResponse
export interface CreateDeploymentResponse {
  PK: string;
  SK: string;
  deploymentId: string;
  aiphoneBranchPublicId: string;
  hartmannDeploymentId: number;
  hartmannBranchId: number;
  name: string;
  description: string;
  features: any;
  contact: Contact;
  address: Address;
  eula: boolean;
  createdOn: string;
  updatedOn: string;
  createdByUserPublicId: string;
  updatedByUserPublicId: string;
  deploymentType: DeploymentType;
  linkedAiphoneSitePublicId: string;

  // Optional fields to accommodate differences(cloud or local)
  tenant?: Tenant;
  hartmannAccountNumber?: number;
}
// #endregion

// #region LicenseDetailResponse

export interface LicenseDetailResponse {
  Pk: string;
  Sk: string;
  licenseId: string;
  deploymentId: string;
  activationDate: string;
  expirationDate: string;
  createdByUserPublicId: string;
  description: string;
  numOfDoors: number;
  numOfElevators: number;
  numOfExpanders: number;
  numOfCameras: number;
  hartmannDeploymentId: number;
  hartmannAccountNumber: number;
  licenseStatus: LicenseStatus;
  responseKey: string;
  name: string;
  aiphoneBranchPublicId: string;
  stripeSubscriptionId: string;
  hartmannLicenseId: number;
}
// #endregion
export enum DeploymentDoorSize {
  Basic = 'Basic',
  Growing = 'Growing',
  Enterprise = 'Enterprise'
}

export const steps = (selectedPaymentMethod: PaymentMethod, isRearmFlow: boolean) => {
  if (isRearmFlow) return ['Request Key', 'Response Key'];
  return [
    'Method',
    'Activation',
    ...(selectedPaymentMethod === PaymentMethod.Online ? ['Payment'] : []),
    'Response Key'
  ];
};

export const cloudDeploymentsteps = ['Package', 'End-User License Agreement', 'Payment'];

export enum PaymentMethod {
  Online = 'online',
  Prepaid = 'prepaid'
}

export interface StripeProduct {
  id: string;
  name: string;
  description: string;
  duration?: number;
  deploymentType: number;
  package?: number;
  unitAmount: number;
  currency: string;
  active: boolean;
  priceId: string;
  doorSize?: DeploymentDoorSize;
  maxDoorCount?: number;
  discount?: number;
  productType?: string;
}

export interface SelectedStripeProduct {
  priceId: string;
  quantity: number;
}

export interface AddressForTax {
  line1: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  countryNumericCode: string;
}

export interface CreateStripeCheckoutSessionRequest {
  selectedProducts?: SelectedStripeProduct[];
  priceId?: string;
  stripeCustomerId: string | null;
  customerEmail: string;
  invoiceId?: string;
  addressForTax: AddressForTax;
  taxExempt?: boolean;
  licenseName?: string;
  hartmannDeploymentId?: number;
  duration?: number;
  numOfElevators?: number;
  numOfExpanders?: number;
  numOfDoors?: number;
  numOfCameras?: number;
  hartmannBranchId?: number;
  cloudUserPublicId?: string;
  reactAppStage?: string;
  deploymentType: DeploymentType;
  licenseStripeProductPriceId?: string;
  licenseStripeInvoiceId?: string;
  licenseStatus?: LicenseStatus;
  licenseExpirationDate?: string;
  hartmannAccountNumber?: number;
  licenseId?: string;
  isAddOn?: boolean;
  hartmannLicenseId?: string;
  count?: number;
}

export interface LatestEula {
  version: number;
  eulaText: string;
  createdOn: string;
  createdByUserPublicId: string;
}

export interface SaveEulaRequest {
  version: number;
  createdByUserPublicId: string;
  signedOn: string;
}

export interface ValidateLicenseCardResponse {
  packageId: number;
  duration: number;
  code: string;
  licenseDescription: string;
  maxDoorCount: number;
  doorSize: DeploymentDoorSize;
}

export interface ValidateLicenseCardRequest {
  licenseCode: string;
}

export interface CreateActivatedLicenseWithCardRequest {
  hartmannDeploymentId: number;
  hartmannBranchId: number;
  requestKey: string;
  licenseCode: string;
  hartmannAccountNumber: number;
  packageId: number;
  duration: number;
  licenseDescription: string;
  maxDoorCount: number;
}

export interface InvoiceDetailResponse {
  invoiceNumber: string;
  dueDate: string;
  subTotal: number;
  total: number;
  stripeInvoiceId: string;
  status: string;
  paidOn: string;
  invoiceLink: string;
  stripeInvoiceNumber: string;
}

export interface PaymentInfo {
  billingAddress: {
    city: string;
    country: string;
    line1: string;
    line2: string;
    postalCode: string;
    state: string;
  };
  billingEmail: string | null;
  card: PaymentCard | null;
  paymentUpdateUrl: string | null;
}

export interface PaymentCard {
  brand: string;
  last4: string;
  expMonth: number;
  expYear: number;
}

export interface ValidateRequestKeyRequest {
  hartmannDeploymentId: number;
  hartmannBranchId: number;
  requestKey: string;
}

export interface ValidateRequestKeyResponse {
  isValid: boolean;
}

export interface CloudDeploymentUpdateSubscriptionRequest {
  stripeSubscriptionId: string;
  licenseId: string;
  selectedProducts: SelectedStripeProduct[];
  numOfDoors: number;
  numOfElevators: number;
  numOfExpanders: number;
  cloudUserPublicId: string;
  deploymentId: string;
  reactAppStage?: string;
  addressForTax: AddressForTax;
  taxExempt: boolean;
  callingUserStripeCustomerId: string | null;
}

export interface DeploymentPage {
  deployments: Deployment[];
  lastEvaluatedKey?: string;
  totalCount: number;
}

export interface RearmLicenseRequest {
  accountNumber: number;
  requestKey: string;
  hartmannBranchId: number;
  deploymentId: string;
  licenseId: string;
}

export interface RearmLicenseResponse {
  id: number;
  responseKey: string;
  deploymentId: number;
}

export interface AddPanelRequest {
  hartmannDeploymentId: number;
  macAddress: string;
  hartmannBranchId: number;
}

export interface PanelDetailsItem {
  macAddress: string;
  hartmannPanelId: number;
  deploymentId: string;
  createdOn: string;
}

export interface RemovePanelRequest {
  hartmannDeploymentId: number;
  macAddress: string;
  hartmannBranchId: number;
}
