import { useEffect } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

// #region import LicenseManagement
// Components
import PackageDetail from './PackageDetail';
// Hooks
import { useCloudDeploymentPackagePricing } from 'features/LicenseManagement/hooks/useCloudDeploymentPackagePricing';
// SharedComponents
import { handleContinue, handleExit } from 'features/LicenseManagement/components/common/navigationUtils';
import ErrorAlert from 'features/LicenseManagement/components/common/ErrorAlert';
// Styles
import { LicenseManagementCommonStyles } from 'features/LicenseManagement/styles/CommonStyles';
// Types
import { cloudDeploymentsteps, SelectedStripeProduct } from 'features/LicenseManagement/components/common/Types';

// #endregion

interface PackageStepProps {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  numDoors: number;
  setNumDoors: React.Dispatch<React.SetStateAction<number>>;
  numExpanders: number;
  setNumExpanders: React.Dispatch<React.SetStateAction<number>>;
  numCabs: number;
  setNumCabs: React.Dispatch<React.SetStateAction<number>>;
  setSelectedStripeProducts: React.Dispatch<React.SetStateAction<SelectedStripeProduct[] | null>>;
}

const PackageStep: React.FC<PackageStepProps> = ({
  activeStep,
  setActiveStep,
  numDoors,
  setNumDoors,
  numExpanders,
  setNumExpanders,
  numCabs,
  setNumCabs,
  setSelectedStripeProducts
}) => {
  const { t } = useTranslation('acNioLicenseManagement');

  const {
    doorPriceDetails,
    expandersCost,
    cabsCost,
    totalDiscount,
    grandTotal,
    error,
    isFetchStripeProductsError,
    refetchStripeProducts,
    combinedStripeCheckoutItems
  } = useCloudDeploymentPackagePricing(numDoors, numExpanders, numCabs);

  useEffect(() => {
    setSelectedStripeProducts((prev) => {
      if (JSON.stringify(prev) === JSON.stringify(combinedStripeCheckoutItems)) {
        return prev;
      }
      return combinedStripeCheckoutItems;
    });
  }, [combinedStripeCheckoutItems, setSelectedStripeProducts]);

  const isDisabled =
    (numDoors === 0 && numExpanders === 0 && numCabs === 0) || combinedStripeCheckoutItems.length === 0;

  const InputField = ({ label, value, onChange }) => (
    <TextField
      label={label}
      type="number"
      value={value}
      inputProps={{ min: 0 }}
      onChange={(e) => onChange(Number(e.target.value))}
      sx={styles.inputField}
    />
  );

  return (
    <Box sx={styles.container}>
      <Box sx={styles.formSection}>
        <Typography variant="h5">{t('Select_Items')}</Typography>
        <Typography variant="subtitle2" sx={styles.planSelector}>
          <span style={styles.text}>{t('Maximum_Discount')}</span>{' '}
          <span style={styles.plan_text}>{t('Time_Period')}</span>
        </Typography>
        <Box style={styles.formContainer}>
          <Box sx={styles.formWrapper}>
            <InputField label={t('Number_of_Doors')} value={numDoors} onChange={setNumDoors} />
            <InputField label={t('Number_of_I/O_Expanders')} value={numExpanders} onChange={setNumExpanders} />
            <InputField label={t('Number_of_Elevator_Cabs')} value={numCabs} onChange={setNumCabs} />
          </Box>

          {(numDoors > 0 || numExpanders > 0 || numCabs > 0) && (
            <PackageDetail
              doorPriceDetails={doorPriceDetails}
              numCabs={numCabs}
              numExpanders={numExpanders}
              expandersCost={expandersCost}
              cabsCost={cabsCost}
              totalDiscount={totalDiscount}
              grandTotal={grandTotal}
            />
          )}
        </Box>
      </Box>

      <Box sx={LicenseManagementCommonStyles.buttonContainer}>
        <Button variant="text" sx={LicenseManagementCommonStyles.buttonBack} onClick={handleExit}>
          {t('Exit')}
        </Button>
        <Button
          variant="contained"
          sx={LicenseManagementCommonStyles.button}
          onClick={() => handleContinue(activeStep, setActiveStep, cloudDeploymentsteps.length)}
          disabled={isDisabled}
        >
          {t('Continue')}
        </Button>
      </Box>
      {isFetchStripeProductsError && (
        <ErrorAlert
          errorMessage={error?.message || ''}
          customMessage={t('Error_Loading_Data')}
          onRetry={refetchStripeProducts}
        />
      )}
    </Box>
  );
};

const styles = {
  container: {
    padding: 4,
    fontFamily: 'Arial, sans-serif'
  },
  formSection: {
    mb: 6
  },
  planSelector: {
    marginTop: '10px'
  },
  text: {
    color: '#006400'
  },
  plan_text: {
    color: 'textSecondary'
  },
  formWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    marginLeft: -2
  },
  formContainer: {
    display: 'flex',
    padding: 2,
    marginTop: 10
  },
  inputField: {
    width: '300px'
  }
};

export default PackageStep;
