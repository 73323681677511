import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { CloudDeploymentUpdateSubscriptionRequest } from '../components/common/Types';
import { updateCloudDeploymentSubscription } from '../lib/api/LicenseManagementApi';

export const useUpdateCloudDeploymentSubscription = (): UseMutationResult<
  unknown,
  Error,
  { aiphoneBranchPublicId: string; deploymentId: string; data: CloudDeploymentUpdateSubscriptionRequest }
> => {
  return useMutation({
    mutationFn: async ({ aiphoneBranchPublicId, deploymentId, data }) => {
      return await updateCloudDeploymentSubscription(aiphoneBranchPublicId, deploymentId, data);
    }
  });
};
