import { LoadingButton } from '@mui/lab';
import { DialogContent, Box, Grid, TextField, DialogActions, Button, Switch, Typography } from '@mui/material';
import { Field, Form } from 'formik';
import { useTranslation } from 'react-i18next';

interface IEditTenantFormProps {
  formikProps: any;
  setIsOpen: (value: boolean) => void;
  isEditingTenant: boolean;
}

const EditTenantForm = ({ formikProps, setIsOpen, isEditingTenant }: IEditTenantFormProps) => {
  const { t } = useTranslation();
  const phoneNumberStr = t('Shared.PhoneNumber');
  const emailAddressStr = t('Shared.EmailAddress');
  const firstNameStr = t('Shared.FirstName');
  const lastNameStr = t('Shared.LastName');
  const primaryTenantStr = t('Tenant.PrimaryTenant');
  const cancelStr = t('Shared.Cancel');

  const handleClose = () => {
    formikProps.handleReset();
    setIsOpen(false);
  };

  return (
    <Form>
      <DialogContent>
        <Box sx={styles.descriptionWrapper}>
          <Box sx={styles.title}>{t('Tenant.EditTenant.Title')}</Box>
          <Box sx={styles.description}> {t('Tenant.EditTenant.Desc')}</Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Field
              as={TextField}
              label={firstNameStr}
              name="firstName"
              variant="outlined"
              fullWidth
              required
              error={formikProps.touched.firstName && Boolean(formikProps.errors.firstName)}
              helperText={formikProps.touched.firstName && formikProps.errors.firstName}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              as={TextField}
              label={lastNameStr}
              name="lastName"
              variant="outlined"
              fullWidth
              required
              error={formikProps.touched.lastName && Boolean(formikProps.errors.lastName)}
              helperText={formikProps.touched.lastName && formikProps.errors.lastName}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              as={TextField}
              label={emailAddressStr}
              name="email"
              variant="outlined"
              fullWidth
              error={formikProps.touched.email && Boolean(formikProps.errors.email)}
              helperText={formikProps.touched.email && formikProps.errors.email}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              as={TextField}
              label={phoneNumberStr}
              name="phoneNumber"
              variant="outlined"
              fullWidth
              error={formikProps.touched.phoneNumber && Boolean(formikProps.errors.phoneNumber)}
              helperText={formikProps.touched.phoneNumber && formikProps.errors.phoneNumber}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">{t('Primary_Tenant_Set')}</Typography>
            <Field
              as={Switch}
              label={primaryTenantStr}
              name="primaryTenant"
              color="primary"
              checked={formikProps.values.primaryTenant}
              onChange={formikProps.handleChange}
            />
            <Typography variant="caption">{t('Primary_Tenant_Warning')}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          {cancelStr}
        </Button>
        <LoadingButton type="submit" color="primary" variant="contained" loading={isEditingTenant}>
          {t('Button_Save')}
        </LoadingButton>
      </DialogActions>
    </Form>
  );
};

const styles = {
  descriptionWrapper: {
    width: '50%',
    marginBottom: '20px'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  description: {}
};

export default EditTenantForm;
