import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Branch } from 'store/slices/branchSlice';

export const useCurrentBranchWithReactQuery = () => {
  const queryClient = useQueryClient();

  return useQuery<Branch>({
    queryKey: ['currentBranch'],
    queryFn: async () => {
      const cachedBranch = queryClient.getQueryData<Branch>(['currentBranch']);
      if (!cachedBranch) {
        throw new Error('No branch in cache');
      }
      return cachedBranch;
    },
    staleTime: 60000
  });
};
