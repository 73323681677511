import { Box, ListItemIcon, Typography, ListItem, IconButton, Checkbox } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DevicesIcon from '@mui/icons-material/Devices';
import { IDevice } from 'store/slices/devicesSlice';
import { getUnitsById } from 'features/RemoteManagement/selectors/unit.selectors';
import { getDeviceTypeStringFromInt } from 'shared/utils/helperFunctions';
import { IUnit } from 'store/slices/unitsSlice';

interface IAddedDevicesItemProps {
  device: IDevice;
  isSelected: boolean;
  isMultiselect: boolean;
  handleDelete?: () => void;
  handleSelect: (id: string) => void;
  showDeleteButton?: boolean;
}

const AddedDevicesItem = ({
  device,
  isSelected,
  isMultiselect,
  handleDelete,
  handleSelect,
  showDeleteButton = true
}: IAddedDevicesItemProps) => {
  const defaultValue = '-';

  const { basicInfo, publicId } = device;
  const unitPublicId = device?.unitPublicId || null;

  const unit: IUnit | null = getUnitsById(unitPublicId);
  const deviceType = getDeviceTypeStringFromInt(basicInfo.deviceType);

  const handleSelectDevice = () => handleSelect(publicId);

  const handleDeleteDevice = () => {
    handleSelectDevice();
    handleDelete();
  };

  return (
    <ListItem
      key={publicId}
      secondaryAction={
        !isMultiselect &&
        showDeleteButton && (
          <IconButton edge="start" onClick={handleDeleteDevice}>
            <DeleteIcon />
          </IconButton>
        )
      }
      disablePadding
    >
      <ListItemButton onClick={handleSelectDevice} selected={!isMultiselect && isSelected}>
        <Box style={styles.iconWrapper}>
          <ListItemIcon>
            {isMultiselect ? <Checkbox edge="start" checked={isSelected} tabIndex={-1} /> : <DevicesIcon />}
          </ListItemIcon>
        </Box>
        <Box sx={styles.deviceDetailsWrapper}>
          <Typography variant="body2" sx={styles.deviceDetailsItem}>
            <Typography variant="h7" sx={styles.itemTitle}>
              Device:
            </Typography>
            {deviceType}
          </Typography>
          <Typography variant="body2" sx={styles.deviceDetailsItem}>
            <Typography variant="h7" sx={styles.itemTitle}>
              Station Name:
            </Typography>
            {device.basicInfo.stationName}
          </Typography>
          <Typography variant="body2" sx={styles.deviceDetailsItem}>
            <Typography variant="h7" sx={styles.itemTitle}>
              Unit Number:
            </Typography>
            {unit?.unitNumber || defaultValue}
          </Typography>
          <Typography variant="body2">
            <strong>Station Number: </strong>
            {device.basicInfo?.stationNumber}
          </Typography>
        </Box>
      </ListItemButton>
    </ListItem>
  );
};

const styles = {
  iconWrapper: {
    width: '15%',
    cursor: 'pointer'
  },
  deviceDetailsWrapper: {},
  deviceDetailsItem: {},
  itemTitle: {
    marginRight: '5px',
    fontWeight: 'bold'
  }
};

export default AddedDevicesItem;
