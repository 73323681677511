import { Alert, Box, Button, Grid, TextField, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState } from 'react';
import Login from 'shared/api/Acl/Login';
import Spinner from 'features/SimBilling/Components/UiParts/Spinner';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { areInputsEmpty, generateUserFriendlyErrorMessageForUsernameOrAlias } from 'shared/utils/helperFunctions';
import PasswordField from 'shared/components/forms/fields/PasswordField';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/utils/StringUtils';
import CreatePanel from './CreatePanel';
import ActivationPanel from './ActivationPanel';
import DialogWrapper from 'shared/components/dialogs/DialogWrapper';
import useSharedStyles from 'styles/useSharedStyles';
/* This component is used for both SIM Billing and Remote Management */
interface Props {
  // Both
  setUsernameAndToken?: (userName: string, token: string) => Promise<void>;
  // Remote Management
  setToken?: React.Dispatch<React.SetStateAction<string | null>>;
  setAclToken?: React.Dispatch<React.SetStateAction<string | null>>;
  setIsLoginPanelOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsNewSiteDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  // SIM Billing
  apiContext?: {
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    errorMessage: string;
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  };
}

interface FormValues {
  emailAddress: string;
  password: string;
}

const LoginPanel = ({
  setToken,
  setUsernameAndToken,
  setIsLoginPanelOpen,
  setIsNewSiteDialogOpen,
  apiContext
}: Props) => {
  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [isRegisterDialogOpen, setIsRegisterDialogOpen] = useState(false);
  const [isActivationPanelOpen, setIsActivationPanelOpen] = useState(false);

  const sharedStyle = useSharedStyles();
  const { t } = useTranslation();
  const emailAddressStr = t('Shared.EmailAddress');
  const emailAddressRequired = t('Field.Error.Required', { field: emailAddressStr });
  const passwordStr = t('Shared.Password');
  const passwordRequired = t('Field.Error.Required', { field: passwordStr });
  const loginSuccessfulStr = t('IXGLogin.LoginSuccessful');
  const dontHaveAccountStr = t('IXGLogin.DontHaveAccount');
  const signUpStr = t('Shared.SignUp');
  const loginStr = t('Shared.Login');
  const createANewItemStr = t('Shared.CreateANewItem');
  const administratorIdStr = t('IXGLogin.AdministratorId');
  const ixgCloudRegistrationStr = t('IXGLogin.CreatePanel.IXGCloudRegistration');
  const activationPanelTitle = t('IXGLogin.ActivationPanel.Title');
  const activationPanelSubtitle = t('IXGLogin.ActivationPanel.Subtitle');
  const createANewAdministratorIdStr = StringUtils.format(createANewItemStr, administratorIdStr);

  const handleClose = () => {
    setIsRegisterDialogOpen(false);
    setIsActivationPanelOpen(false);

    if (setIsLoginPanelOpen) {
      setIsLoginPanelOpen(false);
    }
  };

  const validationSchema = yup.object().shape({
    emailAddress: yup.string().required(emailAddressRequired),
    password: yup.string().required(passwordRequired)
  });

  useEffect(() => {
    setIsSubmitting(apiContext?.loading || false);
    if (apiContext?.errorMessage) {
      setError(true);
      setAlert(true);
      setAlertContent(apiContext?.errorMessage);
    }
  }, [apiContext]);

  const openRegistrationDialog = () => {
    setIsRegisterDialogOpen(true);
  };

  const handleLogin = async (values: FormValues) => {
    if (apiContext) {
      apiContext.setLoading(true);
      setError(false);
    }
    setAlert(false);
    setIsSubmitting(true);
    try {
      const { idToken, username } = await Login.getToken(values.emailAddress, values.password);
      setError(false);
      if (apiContext === undefined) setIsSubmitting(false);
      if (setUsernameAndToken) {
        // Removed unnecessary await
        setUsernameAndToken(username, idToken);
      }
      if (setToken) {
        setToken(idToken);
      }

      if (apiContext === undefined) {
        setError(false);
        setAlert(true);
        setAlertContent(loginSuccessfulStr);
      }

      if (setIsNewSiteDialogOpen) {
        setIsNewSiteDialogOpen(true);
      }
    } catch (e: any) {
      setIsSubmitting(false);
      setError(true);
      setAlert(true);
      const errorType = e.message.split(':')[0] || e.message;
      switch (errorType) {
        case 'UserNotConfirmedException':
          if (setIsLoginPanelOpen) setIsLoginPanelOpen(false);
          break;
        default:
          break;
      }
      // NOTE: We are already handling user not found.

      // Handle user friendly errors for username or alias
      if (errorType === 'InvalidParameterException') {
        setAlertContent(generateUserFriendlyErrorMessageForUsernameOrAlias(e.message));
      } else {
        // The code below gets the string in between the two colons. Example: Error: InvalidParameterException: 2
        // validation errors detected: Value at 'userAlias' failed to satisfy constraint: Member must satisfy regular
        // expression pattern: [\p{L}\p{M}\p{S}\p{N}\p{P}]+; Value at 'userName' failed to satisfy constraint: Member
        // must satisfy regular expression pattern: [\p{L}\p{M}\p{S}\p{N}\p{P}]+... will get "2 validation errors
        // detected".
        const actualMessage = e.message.split(': ')[1] || e.message;
        setAlertContent(actualMessage);
      }
    }
  };
  const isMarkdownContent = (content: string) => /\[.*]\(.*\)/.test(content);

  const handleAlertContent = () => {
    if (isMarkdownContent(alertContent)) {
      return <ReactMarkdown remarkPlugins={[remarkGfm]}>{alertContent}</ReactMarkdown>;
    } else {
      return alertContent;
    }
  };

  return (
    <>
      {isSubmitting && apiContext && <Spinner />}
      <Formik
        initialValues={{ emailAddress: '', password: '' }}
        onSubmit={handleLogin}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, errors, touched, values, isValid }) => (
          <Form>
            {alert ? (
              <Alert severity={error ? 'error' : 'success'} sx={sharedStyle.alert}>
                {handleAlertContent()}
              </Alert>
            ) : (
              <></>
            )}
            <Grid container direction={'column'}>
              <Grid item sx={sharedStyle.validatedFieldContainer}>
                <Field
                  as={TextField}
                  fullWidth
                  name="emailAddress"
                  margin="dense"
                  label={emailAddressStr}
                  helperText={touched.emailAddress && errors.emailAddress}
                  errors={touched.emailAddress && errors.emailAddress}
                />
              </Grid>
              <Grid item sx={sharedStyle.validatedFieldContainer}>
                <Field
                  as={PasswordField}
                  fullWidth
                  name="password"
                  label={passwordStr}
                  placeholder={passwordStr}
                  margin="dense"
                  helperText={touched.password && errors.password}
                  errors={touched.password && errors.password}
                />
              </Grid>
            </Grid>
            <Box sx={sharedStyle.dialogContainer.fullWidthActionsContainer}>
              <Button
                variant="contained"
                type="submit"
                sx={sharedStyle.common.width.full}
                disabled={!isValid || isSubmitting || areInputsEmpty(values)}
              >
                {isSubmitting && apiContext === undefined ? <CircularProgress size="25px" /> : loginStr}
              </Button>
              {setIsRegisterDialogOpen !== undefined && (
                <Box sx={sharedStyle.dialogContainer.footerContainer}>
                  <Typography variant="body2" color="textSecondary">
                    {dontHaveAccountStr}
                  </Typography>
                  <Box>
                    <Button variant="text" color="primary" size="medium" onClick={() => openRegistrationDialog()}>
                      {signUpStr}
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Form>
        )}
      </Formik>
      <DialogWrapper
        header={ixgCloudRegistrationStr}
        subheader={createANewAdministratorIdStr}
        setIsOpen={setIsRegisterDialogOpen}
        open={isRegisterDialogOpen}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
      >
        <CreatePanel
          setIsActivationPanelOpen={setIsActivationPanelOpen}
          setIsRegisterDialogOpen={setIsRegisterDialogOpen}
        />
      </DialogWrapper>
      <DialogWrapper
        header={activationPanelTitle}
        subheader={activationPanelSubtitle}
        setIsOpen={setIsActivationPanelOpen}
        open={isActivationPanelOpen}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
      >
        <ActivationPanel onClose={handleClose} />
      </DialogWrapper>
    </>
  );
};

export default LoginPanel;
