import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { RegexIpV6 } from 'features/RemoteManagement/types/Types';

export const ipV6AddressBlacklist: string[] = ['::'];

const useIpV6AddressValidationSchema = (fieldName: string): Yup.Schema => {
  const { t } = useTranslation(); // Initialize the translation hook
  const formattedFieldInvalid = t('Field.Error.Invalid', { field: fieldName });

  return Yup.string()
    .nullable()
    .notOneOf(ipV6AddressBlacklist, formattedFieldInvalid)
    .matches(RegexIpV6, formattedFieldInvalid);
};

export default useIpV6AddressValidationSchema;
