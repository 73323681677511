import { Box, Button, IconButton, Typography, Tooltip, Chip } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { DeviceList, setSelectedDevice } from 'store/slices/devicesSlice';
import { DeviceTypes, DeviceTypesModel, GatewayCommands, GatewayStatus } from '../types/Types';
import { getDeviceModelNumberFromModelType } from 'shared/utils/helperFunctions';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import { Add, Cached, Search, Upgrade } from '@mui/icons-material';
import { useContext, useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getLatestFirmwareVersions,
  generateFirmwareUpdatePayload,
  isLatestFirmware,
  getDeviceSyncStatus,
  getDeviceStatus
} from './utils/utils';
import { useTranslation } from 'react-i18next';
import { ActivityMessageType, RemoteManagementContext } from 'context/RemoteManagementContext';
import { useGetDeviceListWithSitePublicIdQuery, useUpdateDeviceMutation } from 'services/aiphoneCloud';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';
import { getColorFromStatus, Status } from 'shared/utils/Status';
import useSharedStyles from 'styles/useSharedStyles';

interface DeviceDataGridProps {
  latestFirmwareList: any;
  setIsAddDeviceModalOpen: (isOpen: boolean) => void;
}
export interface SyncDevicePayload {
  mac_addr: string;
  station_type: string;
  devicePublicId: string;
}

export interface ISearchResponse {
  mac_addr: string; // MAC address of the device
  ip_addr: string; // IP address of the device
  ip_subnet: string; // Subnet mask of the device
  ip_gateway: string; // Default gateway of the device
  ip_ver: string; // IP version (e.g., IPv4 or IPv6)
  conn_port: string; // Connection port
  device_type: string; // Type of the device (e.g., IX-RS)
  station_name: string; // Name of the station
  station_number: string; // Station number (optional)
  location: string; // Location of the device (optional)
  fw_ver: string; // Firmware version of the device
  sub_cpu_ver: string; // Sub CPU version (optional)
  building_number: string; // Building number (optional)
  room_number: string; // Room number (optional)
}

interface Row {
  id: string;
  macAddress: string;
  firmwareVersion: string;
  stationAvailability: Status;
  stationIPAddress?: string;
  [key: string]: any; // Add this if rows have additional dynamic properties
}

const DeviceDataGrid = ({ latestFirmwareList, setIsAddDeviceModalOpen }: DeviceDataGridProps) => {
  const [rows, setRows] = useState<any[]>([]);
  const [isSyncing, setIsSyncing] = useState(false);
  const {
    gatewayStatus,
    gatewayMacAddress,
    stationsFromSearch,
    isSearching,
    gatewayFirmwareVersion,
    searchCommand,
    syncCommand,
    updateFirmwareCommand,
    addCustomMessageToHistory
  } = useContext(RemoteManagementContext);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isAllowedTo } = usePermission();
  const sharedStyle = useSharedStyles();
  const sitePublicId = params.id;
  useGetDeviceListWithSitePublicIdQuery({ sitePublicId, qty: 500, page: 0 });
  const deviceList = useAppSelector((state) => state.devices.DeviceList);

  const [updateDevice] = useUpdateDeviceMutation();
  const hasEditPermission = isAllowedTo('site:edit', sitePublicId, PermissionsContextType.SITE);

  const { t } = useTranslation();
  const checkStationsStr = t('RemoteManagement.Dashboard.StationGrid.ToolbarButtons.CheckStations');
  const syncStationsStr = t('RemoteManagement.Dashboard.StationGrid.ToolbarButtons.SyncStations');
  const syncStationSoundsStr = t('RemoteManagement.Dashboard.StationGrid.ToolbarButtons.SyncStationSounds');
  const addStationsStr = t('RemoteManagement.Dashboard.StationGrid.ToolbarButtons.AddStations');
  const nameStr = t('Shared.Name');
  const numberStr = t('Shared.Number');
  const typeStr = t('Shared.Type');
  const modelStr = t('Station.BasicInfo.Model');
  const availabilityStr = t('Station.Availability');
  const statusStr = t('Station.Status');
  const firmwareVersionStr = t('Station.BasicInfo.FirmwareVersion');
  const gatewayOfflineStr = t('RemoteManagement.Dashboard.StationGrid.Tooltip.GatewayOffline');
  const checkStatusStr = t('RemoteManagement.Dashboard.StationGrid.Tooltip.CheckStatus');
  const stationOfflineStr = t('RemoteManagement.Dashboard.StationGrid.Tooltip.StationOffline');
  const syncStr = t('RemoteManagement.Dashboard.StationGrid.Tooltip.Sync');

  const updateDeviceFirmwareVersion = (devicePublicId: string, firmwareVersion: string) => {
    const updateDevicePayload = {
      device: {
        publicId: devicePublicId,
        basicInfo: {
          firmwareVersion: firmwareVersion
        }
      }
    };
    try {
      updateDevice(updateDevicePayload).unwrap();
    } catch (error) {
      console.error('Error updating device firmware version:', error);
    }
  };

  // Update rows by applying stationsFromSearch on top of newly generated rows
  const updateRows = (searchResults: ISearchResponse[], generatedRows: Row[]) => {
    const updatedRows: Row[] = [];

    generatedRows.forEach((row) => {
      const searchResult = searchResults.find((result) => result.mac_addr === row.macAddress);
      const updatedRow = { ...row };

      if (searchResult) {
        updatedRow.stationAvailability = Status.Online;
        updatedRow.stationIPAddress = searchResult.ip_addr;

        if (searchResult.fw_ver && searchResult.fw_ver !== row.firmwareVersion) {
          updatedRow.firmwareVersion = searchResult.fw_ver;
          updateDeviceFirmwareVersion(row.id, updatedRow.firmwareVersion);
        }
      } else {
        updatedRow.stationAvailability = Status.Offline;
      }

      updatedRows.push(updatedRow);
    });

    return updatedRows;
  };

  const generateRows = (deviceList: DeviceList) => {
    return Object.entries(deviceList).map(([, device]) => ({
      id: device.publicId,
      stationName: device.basicInfo.stationName,
      stationNumber: device.basicInfo.stationNumber,
      stationType: device.basicInfo.deviceType,
      stationModel: device.basicInfo.deviceModel,
      stationAvailability: Status.CheckStatus,
      stationStatus: getDeviceSyncStatus(device),
      firmwareVersion: device.basicInfo.firmwareVersion,
      macAddress: device.basicInfo.macAddress
    }));
  };

  // One effect to handle both deviceList and stationsFromSearch changes
  useEffect(() => {
    if (Object.keys(deviceList).length === 0) return;

    const generatedRows = generateRows(deviceList);

    const finalRows = stationsFromSearch.length > 0 ? updateRows(stationsFromSearch, generatedRows) : generatedRows;

    setRows(finalRows);
  }, [deviceList, stationsFromSearch]);

  const generateSyncPayload = () => {
    const payload: Record<string, SyncDevicePayload> = {};
    Object.entries(deviceList).forEach(([publicId, device]) => {
      if (!device.networkSettings?.ipV4Address) {
        return;
      }

      if (device.basicInfo.deviceType == 18) {
        return;
      }

      payload[device.networkSettings?.ipV4Address] = {
        mac_addr: device.basicInfo.macAddress,
        station_type: DeviceTypesModel[device.basicInfo.deviceType],
        devicePublicId: publicId
      };
    });
    return payload;
  };

  const generateSyncPayloadForDevice = (devicePublicId: string) => {
    const device = deviceList[devicePublicId];
    if (!device?.networkSettings?.ipV4Address) {
      return null;
    }
    return {
      [device.networkSettings?.ipV4Address]: {
        mac_addr: device.basicInfo.macAddress,
        station_type: DeviceTypesModel[device.basicInfo.deviceType],
        devicePublicId: device.publicId
      }
    };
  };

  const checkStationsHandler = () => {
    searchCommand();
  };

  const firmwareUpdateHandler = async (devicePublicId: string) => {
    const payload = await generateFirmwareUpdatePayload(devicePublicId, deviceList, latestFirmwareList);
    updateFirmwareCommand(payload);
  };

  const DeviceDataGridToolbar = () => {
    const syncStationsHandler = () => {
      setIsSyncing(true);
      const transactionId = crypto.randomUUID();
      const payload = generateSyncPayload();
      addCustomMessageToHistory({
        transactionId,
        command: GatewayCommands.SYNC,
        type: ActivityMessageType.ACTION,
        sitePublicId,
        commandPayload: payload
      });
      syncCommand(payload, transactionId);
      setIsSyncing(false);
    };

    return (
      <>
        <Box sx={[sharedStyle.toolbarContainer, sharedStyle.toolbarContainer.padded]}>
          <Box
            sx={{
              display: 'flex',
              ...sharedStyle.common.gap.md
            }}
          >
            <LoadingButton
              startIcon={<Search sx={{ color: 'text.secondary' }} />}
              variant="outlined"
              sx={{ color: 'text.secondary', borderColor: 'text.secondary' }}
              onClick={checkStationsHandler}
              loading={isSearching}
              disabled={!gatewayFirmwareVersion}
            >
              {checkStationsStr}
            </LoadingButton>
            <LoadingButton
              startIcon={<Cached sx={{ color: 'text.secondary' }} />}
              variant="outlined"
              sx={{ color: 'text.secondary', borderColor: 'text.secondary' }}
              onClick={syncStationsHandler}
              loading={isSyncing}
              disabled={!gatewayFirmwareVersion}
            >
              {syncStationsStr}
            </LoadingButton>
            <Button
              startIcon={<Cached sx={{ color: 'text.secondary' }} />}
              variant="outlined"
              sx={{ color: 'text.secondary', borderColor: 'text.secondary' }}
              disabled={!gatewayFirmwareVersion}
            >
              {syncStationSoundsStr}
            </Button>
          </Box>
          <Box>
            <Button
              startIcon={<Add />}
              onClick={() => setIsAddDeviceModalOpen(true)}
              disabled={!gatewayFirmwareVersion}
            >
              {addStationsStr}
            </Button>
          </Box>
        </Box>
      </>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'stationName',
      headerName: nameStr,
      flex: 0.97,
      renderCell: (params) => {
        return (
          <Box sx={{ flexDirection: 'column', overflow: 'hidden' }}>
            <Typography noWrap variant="body2" color="textPrimary" display="block" fontWeight={500}>
              {params.value}
            </Typography>
            <Typography noWrap variant="body2" color="textPrimary" display="block">
              {params.row.macAddress}
            </Typography>
          </Box>
        );
      }
    },
    {
      field: 'stationNumber',
      headerName: numberStr,
      flex: 0.8
    },
    {
      field: 'stationType',
      headerName: typeStr,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box sx={{ overflow: 'hidden' }}>
            <Typography noWrap variant="body2" color="textPrimary" display="block">
              {DeviceTypes[params.row.stationType]}
            </Typography>
          </Box>
        );
      }
    },
    {
      field: 'stationModel',
      headerName: modelStr,
      flex: 1,
      renderCell: (params) => {
        const modelNumber = getDeviceModelNumberFromModelType(params.row.stationModel, params.row.stationType);
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
              gap: '8px'
            }}
          >
            <Box
              sx={{
                width: '34px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <img
                style={{ maxWidth: '34px' }}
                src={`${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/${modelNumber}.png`}
                alt="station image"
              />
            </Box>
            <Box sx={{ overflow: 'hidden' }}>
              <Typography noWrap variant="body2" color="textPrimary" display="block">
                {modelNumber}
              </Typography>
            </Box>
          </Box>
        );
      }
    },
    {
      field: 'stationAvailability',
      headerName: availabilityStr,
      flex: 1,
      renderCell: (params) => {
        if (params.row.macAddress === gatewayMacAddress) {
          const capitalizeFirstLetter = (input: string): string => {
            return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
          };
          return (
            <Box display="flex" alignItems="center">
              <Chip
                sx={{
                  ...sharedStyle.chip,
                  borderColor: sharedStyle.gatewayStatusColors[gatewayStatus],
                  pointerEvents: !hasEditPermission ? 'none' : 'auto',
                  marginTop: 0
                }}
                label={
                  <Typography
                    variant="body2"
                    sx={{
                      color: sharedStyle.gatewayStatusColors[gatewayStatus]
                    }}
                  >
                    <span style={{ cursor: 'default' }}>{capitalizeFirstLetter(gatewayStatus)}</span>
                  </Typography>
                }
                color="primary"
                size="medium"
                variant="outlined"
              />
            </Box>
          );
        }
        return (
          <Box display="flex" alignItems="center">
            <Tooltip
              title={
                gatewayStatus === GatewayStatus.OFFLINE || gatewayStatus === GatewayStatus.CHECKING
                  ? t('Check_Gateway_Status_Tooltip')
                  : ''
              }
              onClick={(event) => {
                event.stopPropagation();
                params.value === Status.CheckStatus ? checkStationsHandler() : undefined;
              }}
            >
              <span>
                <Chip
                  sx={{
                    ...sharedStyle.chip,
                    borderColor: !hasEditPermission ? 'grey' : 'default',
                    pointerEvents: !hasEditPermission ? 'none' : 'auto',
                    marginTop: 0
                  }}
                  label={
                    <Typography
                      variant="body2"
                      sx={{
                        color: !hasEditPermission ? 'grey' : 'default'
                      }}
                    >
                      <span style={{ cursor: params.value !== Status.StandBy ? 'pointer' : 'default' }}>
                        {params.value}
                      </span>
                    </Typography>
                  }
                  color={getColorFromStatus(params.value)}
                  disabled={
                    gatewayStatus === GatewayStatus.OFFLINE ||
                    gatewayStatus === GatewayStatus.CHECKING ||
                    isSearching ||
                    !gatewayFirmwareVersion
                  }
                  size="medium"
                  variant="outlined"
                />
              </span>
            </Tooltip>
          </Box>
        );
      }
    },
    {
      field: 'stationStatus',
      headerName: statusStr,
      flex: 1,
      renderCell: (params) => {
        const getTooltipText = (params: any) => {
          if (gatewayStatus === GatewayStatus.OFFLINE) {
            return gatewayOfflineStr;
          }

          if (params.row.stationAvailability === Status.CheckStatus) {
            return checkStatusStr;
          }

          if (params.row.stationAvailability === Status.Offline) {
            return stationOfflineStr;
          }

          if (params.value === Status.NeedsSync) {
            return syncStr;
          }
        };

        return (
          <Box display="flex" alignItems="center" justifyContent="flex-start">
            <Tooltip
              title={getTooltipText(params)}
              onClick={(event) => {
                event.stopPropagation();
                if (hasEditPermission) {
                  if (params.value === Status.NeedsSync) {
                    const device = deviceList[params.row.id];
                    const devicePublicId = device.publicId;
                    const deviceName = device.basicInfo.stationName;

                    addCustomMessageToHistory({
                      transactionId: crypto.randomUUID(),
                      command: GatewayCommands.SYNC,
                      type: ActivityMessageType.ACTION,
                      target: deviceName,
                      sitePublicId,
                      devicePublicId,
                      deviceStatus: getDeviceStatus(device, params.row.stationIPAddress),
                      commandPayload: generateSyncPayloadForDevice(devicePublicId)
                    });
                  }
                }
              }}
            >
              <span>
                <Chip
                  sx={{
                    ...sharedStyle.chip,
                    borderColor: !hasEditPermission ? 'grey' : 'default',
                    pointerEvents: !hasEditPermission ? 'none' : 'auto',
                    marginTop: 0
                  }}
                  label={
                    <Typography
                      variant="body2"
                      sx={{
                        color: !hasEditPermission ? 'grey' : 'default'
                      }}
                    >
                      <span style={{ cursor: params.value !== Status.StandBy ? 'pointer' : 'default' }}>
                        {params.value}
                      </span>
                    </Typography>
                  }
                  color={getColorFromStatus(params.value)}
                  disabled={
                    gatewayStatus === GatewayStatus.OFFLINE ||
                    gatewayStatus === GatewayStatus.CHECKING ||
                    params.row.stationAvailability === Status.CheckStatus ||
                    params.row.stationAvailability === Status.Offline
                  }
                  size="medium"
                  variant="outlined"
                />
              </span>
            </Tooltip>
          </Box>
        );
      }
    },
    {
      field: 'firmwareVersion',
      headerName: firmwareVersionStr,
      flex: 1,
      renderCell: (params) => {
        if (!params.value) {
          return (
            <Typography variant="body2" color="error.main" display="block" fontWeight={500}>
              {t('Unknown')}
            </Typography>
          );
        }
        const modelNumber = getDeviceModelNumberFromModelType(params.row.stationModel, params.row.stationType) || '';
        const { latestStandardFirmware, latestEnhancedFirmware } = getLatestFirmwareVersions(
          modelNumber,
          latestFirmwareList
        );
        const currentFirmware = Number(params.value.replace('.', ''));
        const isLatest = isLatestFirmware(currentFirmware, latestStandardFirmware, latestEnhancedFirmware);

        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="body2"
              color={isLatest ? 'success.main' : 'warning.main'}
              display="block"
              fontWeight={isLatest ? 400 : 500}
              textAlign="center"
            >
              {params.value}
            </Typography>

            {!isLatest && (
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  firmwareUpdateHandler(params.row.id);
                }}
                sx={{
                  border: '0.5px solid',
                  borderColor: 'warning.main',
                  borderRadius: '4px',
                  padding: '0',
                  color: 'warning.main',
                  '&:hover': {
                    backgroundColor: 'rgba(255,165,0,0.1)'
                  },
                  marginX: '8px'
                }}
              >
                <Upgrade />
              </IconButton>
            )}
          </Box>
        );
      }
    }
  ];

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        slots={{ toolbar: DeviceDataGridToolbar }}
        onRowClick={(params) => {
          const devicePublicId = params.row.id;
          if (devicePublicId) {
            dispatch(setSelectedDevice(devicePublicId));
            navigate(`/site/${sitePublicId}/devices/${devicePublicId}/stationinfo`);
          }
        }}
      />
    </>
  );
};

export default DeviceDataGrid;
