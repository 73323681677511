import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  CircularProgress,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  FormHelperText
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import {
  useGetAssignableRolesQuery,
  useRemoveUserRoleMutation,
  useUpdateSiteUserRoleMutation
} from 'services/aiphoneCloud';
import { RootState } from 'store';
import { CloudUser, ContextType } from 'store/slices/usersSlice';
import { useTranslation } from 'react-i18next';
import SiteRoleDescriptions from '../SiteRoleDescriptions';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  selectedUser?: CloudUser;
  onSuccess: () => void;
}

interface FormValues {
  userRole: string;
}

interface IRole {
  publicId: string;
  roleName: string;
}

const EditSiteUserDialog: React.FC<Props> = ({ isOpen, setIsOpen, selectedUser, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const currentSite = useSelector((state: RootState) => state.site.siteInfo);
  const publicId = useSelector((state: RootState) => state.site.siteInfo?.publicId);
  const assignedSiteRole = selectedUser?.permissions?.site?.[publicId]?.roleList?.[0]?.publicId || '';

  const { t } = useTranslation();
  const cancelStr = t('Shared.Cancel');
  const saveChangesStr = t('Shared.SaveChanges');
  const userRoleStr = t('User.Role');
  const noRolesAvailableStr = t('User.NoRolesAvailable');
  const userRoleRequiredStr = t('Field.Error.Required', { field: userRoleStr });

  const { data: rolesData, isFetching: isRolesFetching } = useGetAssignableRolesQuery({
    contextPublicId: publicId,
    contextType: ContextType.SITE
  });

  const [updateSiteUserRole] = useUpdateSiteUserRoleMutation();
  const [removeUserRole] = useRemoveUserRoleMutation();

  // Reset success and error messages when the dialog opens
  useEffect(() => {
    if (isOpen) {
      setSuccessMessage(null);
      setErrorMessage(null);
    }
  }, [isOpen]);

  const validationSchema = Yup.object({
    userRole: Yup.string().required(userRoleRequiredStr)
  });

  const handleUpdateRole = async (
    values: FormValues,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    setLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    try {
      if (assignedSiteRole) {
        const removePayload = {
          userPublicId: selectedUser?.publicId,
          rolePublicIdToRemove: assignedSiteRole,
          rolePublicIdToAssign: values.userRole,
          sitePublicId: currentSite?.publicId
        };

        await updateSiteUserRole(removePayload).unwrap();
        setSuccessMessage(t('User_role_updated_successfully'));
        onSuccess();

        setTimeout(() => {
          setIsOpen(false);
        }, 3000);
      }
    } catch (error) {
      setErrorMessage(t('Failed_to_update_user_role_Please_try_again'));
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  const handleRemoveRole = async () => {
    setLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    if (!assignedSiteRole) {
      setErrorMessage(t('No_role_assigned_to_remove'));
      return;
    }

    try {
      const payload = {
        userPublicId: selectedUser?.publicId,
        rolePublicId: assignedSiteRole,
        sitePublicId: currentSite?.publicId
      };

      await removeUserRole(payload).unwrap();
      setSuccessMessage(t('User_role_removed_successfully'));
      onSuccess();

      setTimeout(() => {
        setIsOpen(false);
      }, 3000);
    } catch (error) {
      setErrorMessage(t('Failed_to_remove_user_role_Please_try_again'));
    } finally {
      setLoading(false);
    }
  };

  const handleCancelClick = () => {
    setIsOpen(false);
    setErrorMessage(null);
    setSuccessMessage(null);
  };

  return (
    <Dialog onClose={handleCancelClick} open={isOpen} maxWidth="sm" fullWidth>
      <Grid container spacing={1}>
        <Grid item xs={11}>
          <DialogTitle>{t('Edit_User_Role')}</DialogTitle>
        </Grid>
        <Grid item xs={1}>
          <IconButton color="default" aria-label="cancel" onClick={handleCancelClick} sx={styles.cancelButton}>
            <CancelIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Formik initialValues={{ userRole: '' }} validationSchema={validationSchema} onSubmit={handleUpdateRole}>
        {({ errors, touched, handleBlur, handleChange, values, isSubmitting }) => (
          <Form noValidate>
            <DialogContent>
              <Box sx={styles.mb2}>
                <Typography variant="body1" color="initial">
                  {t('Select_a_role_to_assign_to')} {selectedUser?.firstName} {selectedUser?.lastName}:
                </Typography>
                <Box sx={styles.mt2}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    error={touched.userRole && !!errors.userRole}
                    disabled={isRolesFetching || !rolesData || rolesData.length === 0}
                    sx={styles.inputField}
                  >
                    <InputLabel id="user-role-select-input-label">{t('Select_User_Role')}</InputLabel>
                    <Field
                      as={Select}
                      name="userRole"
                      label={t('Select_User_Role')}
                      fullWidth
                      variant="outlined"
                      size="small"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      labelId="user-role-select-input-label"
                    >
                      {Array.isArray(rolesData) && rolesData.length > 0 ? (
                        rolesData.map((role) => (
                          <MenuItem key={role.publicId} value={role.publicId}>
                            {role.roleName}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="" disabled>
                          {noRolesAvailableStr}
                        </MenuItem>
                      )}
                    </Field>
                    <FormHelperText>{touched.userRole && errors.userRole}</FormHelperText>
                  </FormControl>
                </Box>
                {/* Add SiteRoleDescriptions component to show role description */}
                {values.userRole && (
                  <Box sx={{ mt: 2 }}>
                    <SiteRoleDescriptions
                      roleName={rolesData?.find((role: IRole) => role.publicId === values.userRole)?.roleName || ''}
                    />
                  </Box>
                )}
              </Box>
              {errorMessage && (
                <Typography variant="body2" color="error" align="center">
                  {errorMessage}
                </Typography>
              )}
              {successMessage && (
                <Typography variant="body2" color="success" align="center">
                  {successMessage}
                </Typography>
              )}
            </DialogContent>
            <DialogActions sx={styles.dialogActions}>
              <Button onClick={handleCancelClick} variant="contained" color="primary">
                {cancelStr}
              </Button>
              <Box>
                <Button
                  onClick={handleRemoveRole}
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  startIcon={loading && <CircularProgress size={20} />}
                  sx={styles.buttonSpacing}
                >
                  {t('Remove_Role')}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting || loading}
                  startIcon={loading && <CircularProgress size={20} />}
                >
                  {saveChangesStr}
                </Button>
              </Box>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

const styles = {
  mb2: {
    marginBottom: 2
  },
  mt2: {
    marginTop: 2
  },
  inputField: {
    width: '100%',
    '& .MuiInputBase-input': {
      backgroundColor: '#ffffff'
    },
    '&.MuiFormHelperText-root': {
      color: 'red'
    },
    '& .MuiInputLabel-root': {
      color: 'gray',
      '&.Mui-focused': {
        color: 'black'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'gray'
      },
      '&:hover fieldset': {
        '& .enabled': {
          borderColor: '#003366'
        }
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0071ce'
      }
    }
  },
  dialogActions: {
    justifyContent: 'space-between'
  },
  cancelButton: {
    padding: 1
  },
  buttonSpacing: {
    marginRight: 1
  }
};

export default EditSiteUserDialog;
