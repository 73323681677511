/**
 * Note: I've substituted the **Select** component with the **ToggleButton** components for the selection feature.
 */
import React from 'react';
import { Box, BoxProps, ButtonProps, CircularProgress, Tooltip, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

/**
 * Interface representing properties for a custom grid toolbar component.
 *
 * @interface CustomGridToolbarProps
 *
 * @property {string} description
 * Description or title for the custom grid toolbar.
 *
 * @property {Object[]} valueToggle
 * Array of objects representing toggle options.
 * Each object contains a `value` and a `label` property.
 *
 * @property {string} label
 * Label associated with the custom grid toolbar.
 *
 * @property {string} buttonText
 * Text to be displayed on the button within the toolbar.
 *
 * @property {function} submitHandler
 * Function to be executed on button click.
 *
 * @property {string} selectedValue
 * Currently selected value in the custom grid toolbar.
 *
 * @property {function} handleSelectChange
 * Function to handle the selection change event.
 *
 * @property {number} [selectMinWidth]
 * Minimum width of the select component.
 */
export interface CustomGridToolbarWithToggleProps {
  isSubmitting: boolean;
  description: string;
  valueToggle?: { value: string; label: string }[];
  label: string;
  buttonText: string;
  submitHandler: () => void;
  selectedValue?: string;
  handleSelectChange?: (event: React.ChangeEvent<{ value: unknown }> | any) => void;
  buttonProps?: ButtonProps;
  containerProps?: BoxProps;
  buttonLeftIcon?: React.ReactNode;
}

const CustomGridToolbarWithToggle: React.FC<CustomGridToolbarWithToggleProps> = ({
  isSubmitting,
  description,
  label,
  buttonText,
  submitHandler,
  buttonProps,
  containerProps,
  buttonLeftIcon
}: CustomGridToolbarWithToggleProps) => {
  const { t } = useTranslation();
  const saveChangesStr = t('Shared.SaveChanges');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px',
        borderBottom: '1px solid #e0e0e0',
        ...containerProps?.sx
      }}
      {...containerProps}
    >
      <Typography sx={{ flexGrow: 1, textAlign: 'left' }} variant="body1">
        {description}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flex: '0 1 auto',
          alignItems: 'center',
          justifyContent: 'flex-end'
        }}
      >
        <Box sx={{ mr: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Typography sx={{ marginRight: 1, marginLeft: 1, fontSize: '0.875rem' }}>{label}</Typography>
        </Box>
        <Tooltip arrow placement={'top'} title={saveChangesStr}>
          <LoadingButton
            type={'submit'}
            startIcon={buttonLeftIcon}
            variant={'contained'}
            color="primary"
            size={'small'}
            loading={isSubmitting}
            disabled={isSubmitting}
            loadingIndicator={<CircularProgress size={'12px'} color={'primary'} />}
            {...buttonProps}
            onClick={submitHandler}
          >
            {buttonText}
          </LoadingButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default CustomGridToolbarWithToggle;
