import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import PasswordField from 'shared/components/forms/fields/PasswordField';
import { LoadingButton } from '@mui/lab';
import { useChangePasswordMutation } from 'services/aiphoneCloud';
import CloseIcon from '@mui/icons-material/Close';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/utils/StringUtils';

interface IChangePasswordDialogProps {
  isChangePasswordDialogOpen: boolean;
  setIsChangePasswordDialogOpen: (isOpen: boolean) => void;
  setErrorMessage: (errorMessage: string) => void;
  setIsError: (isError: boolean) => void;
  setSuccessMessage: (successMessage: string) => void;
  setIsSuccess: (isSuccess: boolean) => void;
}

const ChangePasswordDialog = ({
  isChangePasswordDialogOpen,
  setIsChangePasswordDialogOpen,
  setErrorMessage,
  setIsError,
  setSuccessMessage,
  setIsSuccess
}: IChangePasswordDialogProps) => {
  const [changePassword] = useChangePasswordMutation();
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [hasMinChar, setHasMinChar] = useState(false);
  const [hasMatchingPassword, setHasMatchingPassword] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { t } = useTranslation();
  const confirmItemStr = t('Shared.ConfirmItem');
  const passwordStr = t('Shared.Password');
  const confirmPasswordStr = StringUtils.format(confirmItemStr, passwordStr);

  // Password Match requirement is always satisfied at last
  useEffect(() => {
    if (hasLowerCase && hasUpperCase && hasNumber && hasSpecialChar && hasMinChar && hasMatchingPassword) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [hasMatchingPassword]);

  const handleValidPassword = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const targetID = target.id;

    if (targetID === 'newPassword') {
      const passwordCheck = document.getElementById('confirmPassword') as HTMLInputElement | null;

      if (passwordCheck !== null) {
        const hasLowerCase = /[a-z]/.test(target.value);
        const hasUpperCase = /[A-Z]/.test(target.value);
        const hasNumber = /[0-9]/.test(target.value);
        const hasSpecialChar = /[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]/.test(target.value);
        const hasMinChar = target.value.length >= 8;
        const hasMatchingPassword = target.value === passwordCheck.value;

        setHasLowerCase(hasLowerCase);
        setHasUpperCase(hasUpperCase);
        setHasNumber(hasNumber);
        setHasSpecialChar(hasSpecialChar);
        setHasMinChar(hasMinChar);
        setHasMatchingPassword(hasMatchingPassword);
      }
    }
  };

  const handlePasswordMatch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const passwordCheck = document.getElementById('newPassword') as HTMLInputElement | null;

    if (passwordCheck !== null) {
      const hasMatchingPassword = target.value === passwordCheck.value;
      setHasMatchingPassword(hasMatchingPassword);
    }
  };

  const handleChangePassword = (values: any) => {
    const changePasswordValues = {
      currentPassword: values.currentPassword,
      newPassword: values.newPassword
    };
    changePassword(changePasswordValues)
      .unwrap()
      .then((response) => {
        setSuccessMessage(response.message);
        setIsSuccess(true);
        setIsChangePasswordDialogOpen(false);
      })
      .catch((error) => {
        const errMsg = JSON.parse(error.data)?.errorDetails;
        setErrorMessage(errMsg || 'Unexpected error');
        setIsError(true);
      });
  };

  const validationSchema = yup.object().shape({
    currentPassword: yup.string().required('Current Password is required'),
    newPassword: yup.string().required('New Password is required'),
    confirmPassword: yup.string().required('Confirmation is required')
  });

  return (
    <Dialog open={isChangePasswordDialogOpen} onClose={() => setIsChangePasswordDialogOpen(false)} maxWidth="sm">
      <DialogTitle sx={styles.dialogTitle}>Change Password</DialogTitle>
      <IconButton aria-label="close" onClick={() => setIsChangePasswordDialogOpen(false)} sx={styles.closeButton}>
        <CloseIcon />
      </IconButton>
      <Formik
        initialValues={{
          currentPassword: '',
          newPassword: '',
          confirmPassword: ''
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleChangePassword(values);
        }}
      >
        {({ errors, isSubmitting, touched }) => {
          return (
            <DialogContent>
              <Form>
                <Box sx={styles.formContainer}>
                  <Field
                    as={PasswordField}
                    label="Current Password"
                    name="currentPassword"
                    fullWidth={true}
                    size="small"
                    margin="normal"
                    helperText={touched.currentPassword && errors.currentPassword ? errors.currentPassword : ''}
                    error={touched.currentPassword && !!errors.currentPassword}
                  />
                  <Field
                    as={PasswordField}
                    label="New Password"
                    name="newPassword"
                    id="newPassword"
                    fullWidth={true}
                    size="small"
                    margin="normal"
                    helperText={touched.newPassword && errors.newPassword ? errors.newPassword : ''}
                    error={touched.newPassword && !!errors.newPassword}
                    onKeyUp={handleValidPassword}
                  />
                  <Field
                    as={PasswordField}
                    label={confirmPasswordStr}
                    name="confirmPassword"
                    id="confirmPassword"
                    fullWidth={true}
                    size="small"
                    margin="normal"
                    helperText={touched.confirmPassword && errors.confirmPassword ? errors.confirmPassword : ''}
                    error={touched.confirmPassword && !!errors.confirmPassword}
                    onKeyUp={handlePasswordMatch}
                  />
                  <Grid container columnSpacing={0}>
                    <Grid item xs={6}>
                      <Typography id="lowerCase" sx={hasLowerCase ? styles.valid : styles.invalid}>
                        &#x2022; One Lower Case Letter
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography id="upperCase" sx={hasUpperCase ? styles.valid : styles.invalid}>
                        &#x2022; One Upper Case Letter
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography id="number" sx={hasNumber ? styles.valid : styles.invalid}>
                        &#x2022; One Number
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography id="specialChar" sx={hasSpecialChar ? styles.valid : styles.invalid}>
                        &#x2022; One Special Character
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography id="minLength" sx={hasMinChar ? styles.valid : styles.invalid}>
                        &#x2022; At least 8 Characters
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography id="match" sx={hasMatchingPassword ? styles.valid : styles.invalid}>
                        &#x2022; Passwords Match
                      </Typography>
                    </Grid>
                  </Grid>
                  <DialogActions>
                    <Button variant="contained" onClick={() => setIsChangePasswordDialogOpen(false)}>
                      Cancel
                    </Button>
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      color="primary"
                      disabled={isButtonDisabled}
                      loading={isSubmitting}
                    >
                      Change Password
                    </LoadingButton>
                  </DialogActions>
                </Box>
              </Form>
            </DialogContent>
          );
        }}
      </Formik>
    </Dialog>
  );
};

const styles = {
  formContainer: {
    mt: 2
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: 'grey'
  },
  dialogTitle: { m: 0, p: 2 },
  invalid: {
    color: '#d32f2f',
    fontSize: '0.8rem'
  },
  valid: {
    color: '#00BB31',
    fontSize: '0.8rem'
  }
};

export default ChangePasswordDialog;
