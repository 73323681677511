import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormControl, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadFilled from '@mui/icons-material/Download';

// #region import LicenseManagement
// Components
// Hooks
import useReduxUsers from 'features/Dashboard/Hooks/useReduxUsers';
import { useNavigate } from 'react-router-dom';
import { useCreateStripeCheckoutSession } from 'features/LicenseManagement/hooks/useCreateStripeCheckoutSession';
import { useCreateActivatedLicenseWithCard, useRearmLicense } from 'features/LicenseManagement/hooks/useLicense';
// SharedComponents
import { handleBack } from 'features/LicenseManagement/components/common/navigationUtils';
import LoadingSpinner from 'features/LicenseManagement/components/common/LoadingSpinner';
import ErrorAlert from 'features/LicenseManagement/components/common/ErrorAlert';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import {
  CreateActivatedLicenseWithCardRequest,
  DeploymentDetailResponse,
  DeploymentType,
  LicenseDetailResponse,
  PaymentMethod,
  Product,
  RearmLicenseRequest,
  RearmLicenseResponse,
  ValidateRequestKeyRequest
} from 'features/LicenseManagement/components/common/Types';
// Styles
import { LicenseManagementCommonStyles } from 'features/LicenseManagement/styles/CommonStyles';
import { useValidateRequestKey } from 'features/LicenseManagement/hooks/useValidateRequestKey';
import { useCurrentBranchAddress } from 'features/LicenseManagement/hooks/useCurrentBranchAddress';
import ConfirmationDialog from 'features/LicenseManagement/components/common/ConfirmationDialog';
// #endregion

interface ActivationStepProps {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  accountNumber: number;
  selectedProduct: Product;
  deployment: DeploymentDetailResponse;
  selectedPaymentMethod: PaymentMethod;
  licenseCode: string;
  renewLicense?: boolean;
  isRearmFlow?: boolean;
}

const ActivationStep: React.FC<ActivationStepProps> = ({
  activeStep,
  setActiveStep,
  accountNumber,
  selectedProduct,
  deployment,
  selectedPaymentMethod,
  licenseCode,
  renewLicense = false,
  isRearmFlow
}) => {
  const { t } = useTranslation('acNioLicenseManagement');
  const downloadAcNioSoftwareBtn = t('Download_AcNio_Software.Btn');
  const accountNumberStr = t('Activation_Step.Account_Number');
  const getRequestKeyStr = t('Activation_Step.Instructions.Get_Request_Key');
  const getResponseKeyStr = t('Activation_Step.Instructions.Get_Response_Key');
  const enterRequestKeyStr = t('Activation_Step.Instructions.Enter_Request_Key');
  const copyStr = t('Copy_To_Clipboard');
  const checkoutSessionErrorStr = t('Error_Creating_Checkout_Session');
  const step1Str = t('Activation_Step.Instructions.Step1');
  const step2Str = t('Activation_Step.Instructions.Step2');
  const invalidRequestKeyStr = t('Activation_Step.Invalid_Request_Key');

  const [requestKey, setRequestKey] = useState('');
  const [isRequestKeyValid, setIsRequestKeyValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [apiError, setApiError] = useState('');
  const [errorStatus, setErrorStatus] = useState(false);
  const [responseKey, setResponseKey] = useState<string | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [stripeSessionParams, setStripeSessionParams] = useState<any | null>(null);

  const navigate = useNavigate();
  const { currentUser } = useReduxUsers();

  const { line1, city, state, country, countryNumericCode, postalCode } = useCurrentBranchAddress();

  const deploymentId = deployment.deploymentId;
  const aiphoneBranchPublicId = deployment.aiphoneBranchPublicId ?? '';
  const s3ImageUrls = [
    `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/LicenseInfo.png`,
    `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/ACNioSoftware.png`
  ];

  const { mutateAsync: isValidRequestKey, isPending: isPendingValidationRequestKey, error } = useValidateRequestKey();

  if (error) {
    setApiError(error.message || t('Error_Validating_Request_Key'));
    setErrorStatus(true);
  }

  // Mutation hooks

  const { mutate: createStripeCheckoutSession, isPending: isPendingCreateStripeSession } =
    useCreateStripeCheckoutSession(
      () => {
        setErrorStatus(false);
        setApiError('');
      },
      (error: any) => {
        setErrorStatus(true);
        setApiError(error.message || checkoutSessionErrorStr);
        setTimeout(() => setErrorStatus(false), 3000);
      }
    );

  const { mutate: activateLicenseWithCard, isPending: isActivatingLicense } = useCreateActivatedLicenseWithCard(
    (response: LicenseDetailResponse, aiphoneBranchPublicId: string) => {
      setActiveStep((prevStep) => prevStep + 1);
      const newLicenseId = response?.licenseId;
      if (newLicenseId) {
        navigate(
          `/acniolicensing/deployments/${deploymentId}/activateLicense/responseKeyStep?aiphoneBranchPublicId=${aiphoneBranchPublicId}&licenseId=${newLicenseId}`
        );
      } else {
        setErrorMessage(t('Error_Creating_Activated_License'));
      }
    },
    (error: any) => {
      setErrorStatus(true);
      setApiError(error.message || t('Error_Creating_Activated_License'));
      setTimeout(() => setErrorStatus(false), 3000);
    }
  );

  const { mutate: rearmLicense, isPending: isPendingRearmLicense } = useRearmLicense(
    (data: RearmLicenseResponse) => {
      setActiveStep((prevStep) => prevStep + 1);
      setResponseKey(data.responseKey);
      navigate(`/acniolicensing/deployments/${deploymentId}/activateLicense/responseKeyStep`, {
        state: { responseKey: responseKey, isRearmFlow }
      });
    },
    (error: any) => {
      setErrorStatus(true);
      setApiError(error.message || t('Error_Rearming_License'));
      setTimeout(() => setErrorStatus(false), 3000);
    }
  );

  const validateRequestKey = (key: string): boolean => /^[A-Z0-9]{5}(-[A-Z0-9]{5}){4}$/.test(key);

  const handleRequestKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const key = event.target.value.toUpperCase();
    setRequestKey(key);
    setIsRequestKeyValid(validateRequestKey(key));
  };

  const handleConfirm = () => {
    if (stripeSessionParams) {
      createStripeCheckoutSession(
        {
          aiphoneBranchPublicId: deployment.aiphoneBranchPublicId ?? '',
          deploymentId: deployment.deploymentId ?? '',
          deploymentType: DeploymentType.Local,
          data: stripeSessionParams
        },
        {
          onSuccess: (response: any) => {
            if (response?.data?.message) {
              window.location.href = response.data.message;
            } else {
              setErrorMessage(checkoutSessionErrorStr);
            }
          }
        }
      );
    }
    setOpenDialog(false);
  };

  const handleContinue = async () => {
    if (isRequestKeyValid && isRearmFlow) {
      const request: RearmLicenseRequest = {
        accountNumber,
        requestKey,
        hartmannBranchId: deployment.hartmannBranchId,
        deploymentId: deployment.deploymentId,
        licenseId: deployment.licenseId
      };

      try {
        rearmLicense({
          aiphoneBranchPublicId: aiphoneBranchPublicId,
          data: request
        });
      } catch (error) {
        setApiError(t('Error_Rearming_License'));
        setErrorStatus(true);
      }
    } else if (isRequestKeyValid && selectedProduct) {
      if (selectedPaymentMethod === PaymentMethod.Prepaid) {
        const request: CreateActivatedLicenseWithCardRequest = {
          hartmannDeploymentId: deployment.hartmannDeploymentId,
          hartmannBranchId: deployment.hartmannBranchId,
          packageId: selectedProduct.package,
          duration: selectedProduct.duration,
          requestKey,
          hartmannAccountNumber: accountNumber,
          licenseCode,
          licenseDescription: selectedProduct.description,
          maxDoorCount: selectedProduct.maxDoorCount
        };

        try {
          activateLicenseWithCard({
            aiphoneBranchPublicId,
            deploymentId,
            data: request
          });
        } catch (error) {
          setApiError(t('Error_Creating_Activated_License'));
          setErrorStatus(true);
        }
      } else {
        try {
          // Step 1: Validate request key
          const validateRequestKeyRequest: ValidateRequestKeyRequest = {
            hartmannDeploymentId: deployment.hartmannDeploymentId,
            hartmannBranchId: deployment.hartmannBranchId,
            requestKey
          };

          const response = await isValidRequestKey({ aiphoneBranchPublicId, request: validateRequestKeyRequest });

          if (!response.isValid) {
            setApiError(invalidRequestKeyStr);
            setErrorStatus(true);
            return;
          }

          // Step 2: If request key is valid, open dialog before creating Stripe session
          if (response.isValid) {
            const params = {
              priceId: selectedProduct.priceId,
              stripeCustomerId: currentUser?.stripeCustomerId ?? null,
              customerEmail: currentUser?.email ?? '',
              licenseId: deployment.licenseId,
              licenseStatus: deployment.licenseStatus,
              licenseExpirationDate: deployment.licenseExpirationDate,
              licenseStripeInvoiceId: deployment.licenseStripeInvoiceId,
              licenseStripeProductPriceId: deployment.licenseStripeProductPriceId,
              licensePackageId: deployment.licensePackageId,
              licenseDuration: deployment.licenseDuration,
              renewLicense,
              addressForTax: {
                line1,
                city,
                state,
                country,
                countryNumericCode,
                postalCode
              },
              taxExempt: false, // TODO: Fetch from DB
              cloudUserPublicId: currentUser?.publicId,
              reactAppStage: 'localhost', // TODO: Will change back to dev
              deploymentType: DeploymentType.Local,
              numOfDoors: selectedProduct.maxDoorCount,
              numOfCameras: selectedProduct.maxDoorCount,
              licenseName: selectedProduct.name,
              packageId: selectedProduct.package,
              duration: selectedProduct.duration,
              hartmannDeploymentId: deployment.hartmannDeploymentId,
              hartmannBranchId: deployment.hartmannBranchId,
              hartmannAccountNumber: accountNumber,
              requestKey: requestKey
            };
            setStripeSessionParams(params);
            setOpenDialog(true);
          }
        } catch (error) {
          setApiError(checkoutSessionErrorStr);
          setErrorStatus(true);
        }
      }
    } else {
      setErrorMessage(invalidRequestKeyStr);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(accountNumber.toString());
  };

  const isLoading =
    isPendingCreateStripeSession || isActivatingLicense || isPendingValidationRequestKey || isPendingRearmLicense;

  return (
    <Box sx={styles.container}>
      <Typography variant="h6" sx={styles.accountNumberText}>
        {accountNumberStr} : <span style={styles.accountNumber}>{accountNumber}</span>
        <Tooltip title={copyStr}>
          <IconButton onClick={handleCopy} size="small" sx={styles.copyButton}>
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
      </Typography>
      {errorMessage && (
        <Typography color="error" sx={styles.errorMessage}>
          {errorMessage}
        </Typography>
      )}
      {errorStatus && apiError && <ErrorAlert errorMessage={apiError} customMessage={checkoutSessionErrorStr} />}
      <Box sx={styles.contentSection}>
        <Box sx={styles.textSection}>
          <Typography variant="h6" gutterBottom>
            {step1Str
              .split(/(Account Number)/)
              .map((part, index) => (part === 'Account Number' ? <strong key={index}>{part}</strong> : part))}
          </Typography>
          <Typography variant="body1" paragraph>
            {getRequestKeyStr}
          </Typography>
          <Box component="img" src={s3ImageUrls[0]} alt="License Info" sx={styles.licenseInfoImage} />
          <Typography variant="h6" gutterBottom>
            {step2Str
              .split(/(Request Key)/)
              .map((part, index) => (part === 'Request Key' ? <strong key={index}>{part}</strong> : part))}
          </Typography>
          <Typography variant="body1" paragraph>
            {getResponseKeyStr}
          </Typography>
          <FormControl sx={styles.form}>
            <TextField
              id="request-key"
              placeholder={enterRequestKeyStr}
              variant="outlined"
              fullWidth
              value={requestKey}
              onChange={handleRequestKeyChange}
              error={!isRequestKeyValid && requestKey.length > 0}
              helperText={!isRequestKeyValid && requestKey.length > 0 ? invalidRequestKeyStr : ' '}
              FormHelperTextProps={{ sx: styles.helperText }}
            />
          </FormControl>
        </Box>

        <Box sx={styles.imageSection}>
          <img src={s3ImageUrls[1]} alt="AC Nio Software" style={styles.acNioSoftwareImage} />
          {/* TODO: Add onClick handler to download AC Nio Software */}
          <Button
            variant="outlined"
            color="primary"
            sx={{ ...LicenseManagementCommonStyles.button, mt: 2 }}
            startIcon={<DownloadFilled />}
          >
            {downloadAcNioSoftwareBtn}
          </Button>
        </Box>
      </Box>
      <Box sx={LicenseManagementCommonStyles.buttonContainer}>
        <Button
          variant="text"
          sx={LicenseManagementCommonStyles.buttonBack}
          onClick={() => handleBack(activeStep, setActiveStep)}
        >
          {t('Back')}
        </Button>
        <Button
          variant="contained"
          sx={LicenseManagementCommonStyles.button}
          onClick={handleContinue}
          disabled={!isRequestKeyValid}
        >
          {isRearmFlow
            ? t('Continue_To_Rearm')
            : selectedPaymentMethod === PaymentMethod.Prepaid
            ? t('Activate_License')
            : t('Continue_To_Payment')}
        </Button>
      </Box>
      <ConfirmationDialog
        open={openDialog}
        title={t('Stripe_Dialog_Title')}
        content={t('Stripe_Dialog_Content')}
        onClose={() => setOpenDialog(false)}
        onConfirm={handleConfirm}
        confirmLabel={t('Continue_To_Stripe')}
        cancelLabel={t('Cancel')}
      />
      {isLoading && <LoadingSpinner />}
    </Box>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 2,
    marginTop: '20px',
    marginLeft: '25px'
  },
  accountNumberText: {
    padding: 1,
    marginTop: 2
  },
  accountNumber: {
    backgroundColor: '#f0f0f0',
    padding: '2px 4px',
    borderRadius: '4px',
    marginLeft: '8px'
  },
  copyButton: {
    marginLeft: '8px'
  },
  contentSection: {
    flex: 1,
    display: 'flex'
  },
  textSection: {
    flex: 1,
    padding: 2
  },
  imageSection: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  licenseInfoImage: {
    maxHeight: '300px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    padding: '10px',
    marginBottom: '20px'
  },
  acNioSoftwareImage: {
    width: '100%',
    height: '100%',
    maxHeight: '500px',
    marginTop: '50px'
  },
  form: {
    marginTop: '10px',
    width: '100%'
  },
  helperText: {
    minHeight: '1.5em'
  },
  errorMessage: {
    mb: 2
  }
};

export default ActivationStep;
