import { RootState } from 'store';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import AddMobileAppCard from './AddMobileAppCard';
import AddBatchAppsToUnitsDialog from './AddAppToUnit/AddBatchAppsToUnitsDialog';
import { useBatchCreateAppsMutation, useLazyGetAppWithSiteIdQuery } from 'services/aiphoneCloud';
import { generateAppList } from './AddAppToUnit/createAppsHelper';
import { MAX_APPS } from 'features/RemoteManagement/types/Types';
import { useParams } from 'react-router-dom';
import StringUtils from 'shared/utils/StringUtils';
interface IUnitsControlPanelProps {
  setIsOpen: (isOpen: boolean) => void;
  refetchUnits?: () => void;
}
interface IUnitTypeMenu {
  unitTypeMenu: string[];
}

const UnitsControlPanel = ({ setIsOpen }: IUnitsControlPanelProps) => {
  const { isAllowedTo } = usePermission();
  const siteId = useParams().id;

  const BuildingList = useSelector((state: RootState) => state.buildings.BuildingList);
  const UnitList = useSelector((state: RootState) => state.units.UnitList);
  const UnitListByType = useSelector((state: RootState) => state.units.UnitListByType);
  const sitePublicId = useSelector((state: RootState) => state.site.siteInfo.publicId);

  const canUnitCreate = isAllowedTo('unit:create', sitePublicId, PermissionsContextType.SITE);

  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showAddMobileApp, setShowAddMobileApp] = useState<boolean>(false);
  const [showAddMobileAppDialog, setShowAddMobileAppDialog] = useState<boolean>(false);
  const [unitTypeMenu, setUnitTypeMenu] = useState<IUnitTypeMenu['unitTypeMenu']>(['all eligible']);
  const [selectedUnitIds, setSelectedUnitIds] = useState<string[]>([]);
  const [unitLoadingState, setUnitLoadingState] = useState<{ [key: string]: boolean }>({});
  const [unitSuccessState, setUnitSuccessState] = useState<{ [key: string]: boolean }>({});
  const [unitProgress, setUnitProgress] = useState<{ [key: string]: number }>({});

  const [batchCreateApps] = useBatchCreateAppsMutation();
  const [getAppList] = useLazyGetAppWithSiteIdQuery();

  const { t } = useTranslation();
  const sharedAdd = t('Shared.AddItem');
  const unit = t('Unit.Unit', { count: 1 });
  const commercialStr = t('Unit.Types.Commercial.Name');
  const residentialStr = t('Unit.Types.Residential.Name');
  const guardStr = t('Unit.Types.Guard.Name');

  const addUnit = StringUtils.format(sharedAdd, unit);

  useEffect(() => {
    if (UnitList && Object.keys(UnitList).length > 0) {
      setShowAddMobileApp(true);
    }
  }, [UnitList]);

  useEffect(() => {
    const newUnitTypeMenu = [t('All_Eligible')];

    if (UnitListByType.Guard?.length > 0) newUnitTypeMenu.push(guardStr);
    if (UnitListByType.Commercial?.length > 0) newUnitTypeMenu.push(commercialStr);
    if (UnitListByType.Residential?.length > 0) newUnitTypeMenu.push(residentialStr);
    setUnitTypeMenu(newUnitTypeMenu);
  }, [UnitListByType]);

  const handleAdd = () => {
    setIsOpen(true);
  };

  const launchAddAppsProcess = async (unitIds: string[]) => {
    setSelectedUnitIds(unitIds);
    setShowAddMobileAppDialog(true);

    // iterate through the unitIds and use the helper function to generate the app list
    // then send the list to the backend
    const createAppsForUnit = async (unitId: string) => {
      setUnitLoadingState((prevState) => ({ ...prevState, [unitId]: true }));
      setUnitProgress((prevState) => ({ ...prevState, [unitId]: 0 }));

      const unit = UnitList[unitId];
      const existingAppsCount = unit?.appPublicIds?.length || 0;
      const appsToBeCreated = MAX_APPS - existingAppsCount;
      const appList = generateAppList(unitId, appsToBeCreated, UnitList, BuildingList, existingAppsCount, t);

      /** Proceeding requires at least one app in the list. */
      if (appList.length === 0) {
        setUnitLoadingState((prevState) => ({ ...prevState, [unitId]: false }));
        return;
      }

      const params = {
        apps: appList
      };

      const interval = setInterval(() => {
        setUnitProgress((prevState) => {
          const newProgress = prevState[unitId] + 1;
          if (newProgress >= 100) {
            clearInterval(interval);
          }
          return { ...prevState, [unitId]: newProgress };
        });
      }, 34);

      batchCreateApps(params)
        .unwrap()
        .then(() => {
          setSuccessMessage(t('Apps_added_successfully'));
          setShowAddMobileAppDialog(false);
          setUnitLoadingState((prevState) => ({ ...prevState, [unitId]: false }));
          setUnitSuccessState((prevState) => ({ ...prevState, [unitId]: true }));
          clearInterval(interval);
          setUnitProgress((prevState) => ({ ...prevState, [unitId]: 100 }));
          getAppList({
            sitePublicId: siteId || '',
            page: 0,
            qty: 500
          });
        })
        .catch(() => {
          setErrorMessage(t('Failed_To_Add_Apps'));
          setShowAddMobileAppDialog(false);
          setUnitLoadingState((prevState) => ({ ...prevState, [unitId]: false }));
          setUnitSuccessState((prevState) => ({ ...prevState, [unitId]: false }));
          clearInterval(interval);
        });
    };

    for (const unitId of unitIds) {
      await createAppsForUnit(unitId);
    }
  };

  return (
    <>
      <SnackbarAlert
        type="success"
        time={7000}
        text={`${successMessage}`}
        isOpen={!!successMessage}
        onClose={() => setSuccessMessage('')}
      />
      <SnackbarAlert
        type="error"
        time={7000}
        text={`${errorMessage}`}
        isOpen={!!errorMessage}
        onClose={() => setErrorMessage('')}
      />
      <Box>
        {canUnitCreate && (
          <Box style={styles.unitsControlPanelWrapper}>
            <Box sx={styles.descriptionWrapper}>
              <Box sx={styles.title}>{t('Unit.Unit', { count: 2 })}</Box>
              <Box sx={styles.description}> {t('Unit_AddEditDelete')}</Box>
            </Box>
            <Box style={styles.buttonsWrapper}>
              <LoadingButton variant="contained" onClick={handleAdd}>
                {addUnit}
              </LoadingButton>
            </Box>
          </Box>
        )}

        {showAddMobileApp && <AddMobileAppCard unitTypeMenu={unitTypeMenu} onApply={launchAddAppsProcess} />}

        {showAddMobileAppDialog && (
          <AddBatchAppsToUnitsDialog
            open={showAddMobileAppDialog}
            setIsOpen={setShowAddMobileAppDialog}
            selectedUnitIds={selectedUnitIds}
            unitLoadingState={unitLoadingState}
            unitSuccessState={unitSuccessState}
            unitProgress={unitProgress}
          />
        )}
      </Box>
    </>
  );
};

const styles = {
  unitsControlPanelWrapper: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  descriptionWrapper: {
    width: '50%'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  description: {},
  buttonsWrapper: {
    display: 'flex',
    width: '50%',
    justifyContent: 'end'
  }
};

export default UnitsControlPanel;
