import { MenuItem } from '@mui/material';
import { EnumList, IStateValue } from './EnumUtils';

const getStateList = (enumList: EnumList, countryId: string, selectCountryText: string) => {
  const stateList = Object.keys(enumList.state)
    .map((key) => {
      const stateWalker = enumList.state[key] as IStateValue;
      // If the state is not in the selected country, do not add the option
      // Also do not include the unknown option
      if (stateWalker.countryId.toString() !== countryId || stateWalker.value === 'Unknown') {
        return null;
      }
      return (
        <MenuItem key={stateWalker.value} value={key}>
          {stateWalker.value}
        </MenuItem>
      );
    })
    .filter((val) => val !== null);

  if (stateList.length === 0) {
    stateList.push(
      <MenuItem key={selectCountryText} value="" disabled={true}>
        {selectCountryText}
      </MenuItem>
    );
  }

  return stateList;
};

export default getStateList;
