import { Box, Typography, Grid, TextField, Alert } from '@mui/material';
import { FC } from 'react';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import { formatToMACAddress } from 'shared/utils/helperFunctions';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import GatewayErrorBox from '../GatewayErrorBox';
import { useTranslation } from 'react-i18next';
import useSharedStyles from 'styles/useSharedStyles';

interface Props {
  modelName: string;
  inputMacAddress: string;
  handleMacAddressChange: (inputMacAddres: string) => void;
  isGatewayOnline: boolean;
  gatewayErrorCode: string;
  gatewayErrorMessage: string;
  existingId: string | undefined;
  existingPw: string | undefined;
  setExistingId: (existingId: string | undefined) => void;
  setExistingPw: (existingPw: string | undefined) => void;
  isLoading: boolean;
  isUsingSim: boolean;
  macAddressConfirmed: boolean;
}

const GatewayMacInput: FC<Props> = ({
  modelName,
  inputMacAddress,
  handleMacAddressChange,
  isGatewayOnline,
  gatewayErrorCode,
  gatewayErrorMessage,
  existingId,
  existingPw,
  setExistingId,
  setExistingPw,
  isLoading,
  isUsingSim,
  macAddressConfirmed
}) => {
  const sharedStyle = useSharedStyles();
  const { t } = useTranslation();
  const modelNameObj = {
    modelName: modelName
  };
  const macAddressLocationStr = t('Site.Configure.RegisterGateway.FinishRegistration.MACAddress.Location');
  const enterMacAddressStr = t('Site.Configure.RegisterGateway.FinishRegistration.MACAddress.EnterLabel', modelNameObj);
  const connectionSecuredStr = t('Site.Configure.RegisterGateway.FinishRegistration.MACAddress.ConnectionSecured');

  const macAddressOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleMacAddressChange(formatToMACAddress(event.target.value));
  };

  return (
    <Grid container sx={[sharedStyle.gridContainer, sharedStyle.gridContainer.row]}>
      <Grid item>
        <Box
          component="img"
          sx={styles.macLocationImage}
          src={`${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/IXGW-Mac-Location.png`}
          alt={macAddressLocationStr}
        />
      </Grid>
      <Grid
        container
        item
        sx={[sharedStyle.gridContainer.row.fillWidthColumn, sharedStyle.gridContainer, sharedStyle.common.gap.sm]}
      >
        <TextField
          id="macAddress"
          fullWidth
          label={enterMacAddressStr}
          value={inputMacAddress}
          disabled={isGatewayOnline || isLoading}
          onChange={macAddressOnChange}
          InputLabelProps={{ shrink: true }}
          InputProps={{ endAdornment: isGatewayOnline && <CheckCircleIcon sx={styles.macFieldCheck} /> }}
        />
        {isUsingSim && macAddressConfirmed && (
          <Alert variant="outlined" severity="success">
            <Typography variant="success" gutterBottom>
              {connectionSecuredStr}
            </Typography>
          </Alert>
        )}
        {(gatewayErrorCode || gatewayErrorMessage) && (
          <GatewayErrorBox
            gatewayErrorCode={gatewayErrorCode}
            gatewayErrorMessage={gatewayErrorMessage}
            existingId={existingId}
            existingPw={existingPw}
            setExistingId={setExistingId}
            setExistingPw={setExistingPw}
            isLoading={isLoading}
          />
        )}
      </Grid>
    </Grid>
  );
};

const styles = {
  macLocationImage: {
    width: '11.75rem'
  },
  macFieldCheck: {
    color: 'primary.main'
  }
};

export default GatewayMacInput;
