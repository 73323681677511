import { Box, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { CA, US } from 'country-flag-icons/react/3x2';
import { Field } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetRemoteManagementUtilMutation } from 'services/aiphoneCloud';
import { EnumList, fetchLocalEnumList, ICountryValue } from 'shared/utils/EnumUtils';
import getStateList from 'shared/utils/GetStateList';
import { CreateSiteDialogProps } from './CreateSiteTypes';
import StringUtils from 'shared/utils/StringUtils';
import useSharedStyles from 'styles/useSharedStyles';
import { RegexZipCode } from '../types/Types';
import ZipPostalCodeField from 'shared/components/forms/fields/ZipPostalCodeField';

const SiteInformationStep: React.FC<CreateSiteDialogProps> = ({ formikProps }) => {
  const enumList: EnumList = fetchLocalEnumList();
  const [retrievedUsaLocations, setRetrievedUsaLocations] = useState(false);
  const canadaCountryId = Object.keys(enumList.country).find((key) => {
    return (enumList.country[key] as ICountryValue).alpha2Code === 'CA';
  });
  const usaCountryId = Object.keys(enumList.country).find((key) => {
    return (enumList.country[key] as ICountryValue).alpha2Code === 'US';
  });
  const manualStateCityCountryIds = [canadaCountryId];
  const sharedStyle = useSharedStyles();
  const { t } = useTranslation();
  const selectAItemStr = t('Shared.SelectAItem');
  const countryStr = t('Shared.Country');
  const stateStr = t('Shared.State');
  const provinceStr = t('Shared.Province');
  const headerStr = t('Site.CreateSiteDialog.Information.Header');
  const zipCodeStr = t('Shared.ZipCode');
  const postalCodeStr = t('Shared.PostalCode');
  const siteNameStr = t('Site.Name');
  const cityStr = t('Shared.City');
  const siteAddressStr = t('Site.SiteAddress');
  const AptSuiteBuildingStr = t('Site.AptSuiteBuilding');
  const phoneNumberStr = t('Shared.PhoneNumber');
  const noCitiesAvailableStr = t('Site.CreateSiteDialog.Information.NoCitiesAvailable');
  const selectACountryStr = StringUtils.format(selectAItemStr, countryStr);
  const selectAStateStr = StringUtils.format(selectAItemStr, stateStr);
  const selectAProvinceStr = StringUtils.format(selectAItemStr, provinceStr);

  const { values, handleBlur, handleChange, setFieldValue, setTouched, errors, touched } = formikProps;

  // Use the query hook with skip: true so it doesn't run automatically
  const [fetchLocationByZipCode] = useGetRemoteManagementUtilMutation();

  const getStateId = (stateName: string) => {
    const stateKeys = Object.keys(enumList.state);
    for (const key of stateKeys) {
      if (enumList.state[key].value.toLowerCase() === stateName.toLowerCase()) {
        return key;
      }
    }
    return null;
  };

  useEffect(() => {
    const fetchLocation = async () => {
      const countryId = values.countryId || usaCountryId;
      if (
        values.siteZip &&
        countryId === usaCountryId &&
        values.siteZip.length === 5 &&
        RegexZipCode.test(values.siteZip)
      ) {
        try {
          const response = await fetchLocationByZipCode({ country: countryId, zipCode: values.siteZip });
          const { data } = response;
          if (data) {
            setRetrievedUsaLocations(true);
            const stateId = getStateId(data.stateName);
            setFieldValue('countryId', countryId);
            setFieldValue('stateId', stateId);
            setFieldValue('cityOptions', data.city);
            setFieldValue('siteCity', data.city[0]);
          }
        } catch (error) {
          setFieldValue('siteZip', '');
        }
      }
    };
    fetchLocation();
  }, [values.siteZip, setFieldValue]);

  return (
    <>
      <Grid container sx={sharedStyle.gridContainer}>
        <Box>
          <Typography variant="h6">{headerStr}</Typography>
        </Box>

        <Grid container columnSpacing={1}>
          <Grid item xs={12} sx={sharedStyle.validatedFieldContainer.small}>
            <Field
              name="siteName"
              as={TextField}
              label={siteNameStr + ' *'}
              fullWidth
              size="small"
              helperText={touched.siteName && errors.siteName}
              error={touched.siteName && !!errors.siteName}
            />
          </Grid>
          <Grid item xs={6} sx={sharedStyle.validatedFieldContainer.small}>
            <Field
              name="siteAddress"
              as={TextField}
              label={siteAddressStr + ' *'}
              fullWidth
              size="small"
              helperText={touched.siteAddress && errors.siteAddress}
              error={touched.siteAddress && !!errors.siteAddress}
            />
          </Grid>
          <Grid item xs={6} sx={sharedStyle.validatedFieldContainer.small}>
            <Field
              name="siteAddress2"
              as={TextField}
              label={AptSuiteBuildingStr}
              fullWidth
              size="small"
              helperText={touched.siteAddress2 && errors.siteAddress2}
              error={touched.siteAddress2 && !!errors.siteAddress2}
            />
          </Grid>
          <Grid item xs={6} sx={sharedStyle.validatedFieldContainer.small}>
            <TextField
              name="countryId"
              id="countryId"
              select
              value={values.countryId ?? ''}
              label={selectACountryStr + ' *'}
              size="small"
              fullWidth
              helperText={touched.countryId && errors.countryId}
              error={touched.countryId && !!errors.countryId}
              onBlur={handleBlur}
              onChange={(e: any) => {
                if (values.countryId !== e.target.value) {
                  setFieldValue('siteCity', '');
                  setFieldValue('stateId', '');
                  setFieldValue('siteZip', '');
                  setTouched({
                    ...touched,
                    siteCity: false,
                    stateId: false,
                    siteZip: false
                  });
                  setRetrievedUsaLocations(false);
                }
                handleChange(e);
              }}
            >
              {Object.keys(enumList.country).map((key) => {
                const validCountries = ['CA', 'US'];
                const countryWalker = enumList.country[key] as ICountryValue;

                if (!validCountries.includes(countryWalker.alpha2Code)) {
                  return null;
                }

                const flagComponent: JSX.Element =
                  key === canadaCountryId ? (
                    <CA header={countryWalker.defaultLanguageName} style={sharedStyle.flagField.flagStyle} />
                  ) : (
                    <US header={countryWalker.defaultLanguageName} style={sharedStyle.flagField.flagStyle} />
                  );

                return (
                  <MenuItem key={key} value={key}>
                    <Grid sx={sharedStyle.flagField}>
                      {flagComponent}
                      <Typography sx={sharedStyle.flagField.textStyle}>{countryWalker.value}</Typography>
                    </Grid>
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid item xs={6} sx={sharedStyle.validatedFieldContainer.small}>
            <Field
              name="siteZip"
              as={TextField}
              label={(values.countryId !== canadaCountryId ? zipCodeStr : postalCodeStr) + ' *'}
              component={ZipPostalCodeField}
              countryId={values.countryId}
              fullWidth
              size="small"
              helperText={touched.siteZip && errors.siteZip}
              error={touched.siteZip && !!errors.siteZip}
              onChange={(e: any) => {
                handleChange(e);
                if (values.siteZip !== e.target.value) {
                  setRetrievedUsaLocations(false);
                }
              }}
            />
          </Grid>
          <Grid container item xs={12} columnSpacing={1} sx={sharedStyle.validatedFieldContainer.small}>
            {manualStateCityCountryIds.indexOf(values.countryId) > -1 || retrievedUsaLocations ? (
              <>
                <Grid item xs={6}>
                  <TextField
                    name="stateId"
                    id="stateId"
                    select
                    value={values.stateId ?? ''}
                    onBlur={handleBlur}
                    onChange={(e: any) => {
                      handleChange(e);
                    }}
                    label={(values.countryId !== canadaCountryId ? selectAStateStr : selectAProvinceStr) + ' *'}
                    size="small"
                    fullWidth
                    helperText={touched.stateId && errors.stateId}
                    error={touched.stateId && !!errors.stateId}
                    disabled={manualStateCityCountryIds.indexOf(values.countryId) === -1}
                  >
                    {getStateList(enumList, values.countryId, selectACountryStr)}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  {values.countryId === usaCountryId ? (
                    <TextField
                      name="siteCity"
                      id="siteCity"
                      select
                      value={values.siteCity ?? ''}
                      onBlur={handleBlur}
                      onChange={(e: any) => {
                        handleChange(e);
                      }}
                      label={cityStr + ' *'}
                      size="small"
                      fullWidth
                      helperText={touched.siteCity && errors.siteCity}
                      error={touched.siteCity && !!errors.siteCity}
                    >
                      {values.cityOptions && values.cityOptions.length > 0 ? (
                        (values.cityOptions || []).map((city: string, index: number) => (
                          <MenuItem key={index} value={city}>
                            {city}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="" disabled>
                          {noCitiesAvailableStr}
                        </MenuItem>
                      )}
                    </TextField>
                  ) : (
                    <Field
                      as={TextField}
                      name="siteCity"
                      label={cityStr + ' *'}
                      size="small"
                      fullWidth
                      helperText={touched.siteCity && errors.siteCity}
                      error={touched.siteCity && !!errors.siteCity}
                    />
                  )}
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={12} sx={sharedStyle.validatedFieldContainer.small}>
            <Field
              name="sitePhoneNumber"
              as={TextField}
              label={phoneNumberStr + ' *'}
              fullWidth
              size="small"
              helperText={touched.sitePhoneNumber && errors.sitePhoneNumber}
              error={touched.sitePhoneNumber && !!errors.sitePhoneNumber}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SiteInformationStep;
