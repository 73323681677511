import { Box, Breadcrumbs, Link, Typography } from '@mui/material';
import React from 'react';
import { ISiteInfo } from 'store/slices/siteSlice';
import useSharedStyles from 'styles/useSharedStyles';

interface PageTitleProps {
  featureName: string;
  site?: ISiteInfo;
  icon: JSX.Element;
}

const PageTitle = ({ featureName, site, icon }: PageTitleProps) => {
  const sharedStyle = useSharedStyles();
  const siteName = site?.siteName;
  const propertyId = site?.awsPropertyId;
  const featureHref = featureName.toLowerCase().replace(' ', '');

  return (
    <Box
      sx={{
        marginBottom: '1rem'
      }}
    >
      <Box sx={sharedStyle.centeredFlex}>
        <Box sx={sharedStyle.extraLargeIcon} color="primary.main">
          {icon}
        </Box>
        <Box>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href={`/${featureHref}`}>
              {featureName}
            </Link>
            <Link underline="hover" color="inherit" href={window.location.href}>
              <Typography color="text.primary">{siteName}</Typography>
            </Link>
          </Breadcrumbs>
          <Typography variant="h5">{siteName && siteName}</Typography>
          <Typography variant="body2" color="text.secondary">
            {propertyId && `Property ID: ${propertyId}`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PageTitle;
