import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  DeploymentDetailResponse,
  DeploymentType,
  LicenseStatus,
  SubscriptionStatus
} from 'features/LicenseManagement/components/common/Types';
import { useLicenseById } from 'features/LicenseManagement/hooks/useLicense';
import ErrorAlert from 'features/LicenseManagement/components/common/ErrorAlert';
import LoadingSpinner from 'features/LicenseManagement/components/common/LoadingSpinner';
import FieldDisplay from 'features/LicenseManagement/components/common/Fielddisplay';
import { formatDate } from 'features/LicenseManagement/components/common/utils';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';
import { Edit, Sync } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import UpdatePackagePriceDialog from './UpdatePackagePriceDialog';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import AddIcon from '@mui/icons-material/Add';
import { LicenseManagementCommonStyles } from 'features/LicenseManagement/styles/CommonStyles';
import { CancelLicenseDialog } from './CancelLicenseDialog';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import theme from 'styles/theme';
import LicenseAddOnDialog from './LicenseAddOnDialog';
import { useCurrentBranchWithReactQuery } from 'features/LicenseManagement/hooks/useCurrentBranchWithReactQuery';

interface ActivatedLicenseDetailProps {
  deployment: DeploymentDetailResponse;
}

export const ActivatedLicenseDetail = ({ deployment }: ActivatedLicenseDetailProps) => {
  //#region String Constants
  const { t } = useTranslation('acNioLicenseManagement');

  const licenseDetailsStr = t('License_Details');
  const cancelLicenseStr = t('Cancel_License');
  const renewLicenseStr = t('Renew_License');
  const rearmLicenseStr = t('Rearm_License');
  const editPackageStr = t('Edit_Package_Details');
  const modifyLicenseStr = t('Modify_License_Package');
  const packageDetailsStr = t('Package_Details');

  const acLocksetStr = t('AC_Locksets');
  const acLocksetDescStr = t('AC_Locksets_Description');
  const addOnLicenseStr = t('AddOn_Licenses');
  const startingAtStr = t('AC_Locksets_Starting_At');
  const acLocksetPriceStr = t('AC_Locksets_Price');
  const acLocksetPurchaseStr = t('AC_Locksets_Purchase');
  const acLocksetAdditionalPurchaseStr = t('AC_Locksets_Purchase_Additional');
  const errorLoadingLicenseStr = t('Error_Loading_License_Details');

  const acLicenseStr = t('AC_Locsket_License');
  const acLicenseSubtitleStr = t('AC_Lockset_License_Subtitle');
  const acLocksetWirelessLock = t('AC_Lockset_Wirless_Lock');
  const acLocksetWirelessLockSubtitle = t('AC_Lockset_Wireless_Lock_Subtitle');
  const acProductStr = 'AC_Product_Count';
  const acLocksetCode = t('AC_Lockset_Code_Subtitle');

  //#endregion
  const { isAllowedTo } = usePermission();
  const { data: currentBranch } = useCurrentBranchWithReactQuery();

  const navigate = useNavigate();
  const [openUpdatePackageDialog, setOpenUpdatePackageDialog] = useState(false);
  const [openCancelLicenseDialog, setOpenCancelLicenseDialog] = useState(false);
  const [openPurchaseAddOnDialog, setOpenPurchaseAddOnDialog] = useState(false);
  const [hasLicenseAddOns, setHasLicenseAddOns] = useState((deployment.licenseAddOns ?? []).length > 0);
  const [totalAddOnCount, setTotalAddOnCount] = useState(0);
  const [lastGeneratedAddOnResponseKey, setLastGeneratedAddOnResponseKey] = useState('');

  useEffect(() => {
    const licenseAddOns = deployment.licenseAddOns ?? [];
    setHasLicenseAddOns(licenseAddOns.length > 0);
    setTotalAddOnCount(licenseAddOns.reduce((acc, addOn) => acc + addOn.count, 0));
    setLastGeneratedAddOnResponseKey(licenseAddOns[licenseAddOns.length - 1]?.responseKey);
  }, [deployment.licenseAddOns]);

  const [message, setMessage] = useState<{
    type: 'success' | 'error' | 'warning';
    text: string;
    paymentUpdateUrl?: string;
  } | null>(null);

  const canEditBilling = isAllowedTo('deploymentBilling:edit', currentBranch?.publicId, PermissionsContextType.BRANCH);
  const s3ImageUrls = [`${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/AC-NIO-WirelessLock.png`];
  const isLocalDeployment = deployment.deploymentType === DeploymentType.Local;
  const bulletPoints = [
    'AC_Locksets_Bullet_Point_One',
    'AC_Locksets_Bullet_Point_Two',
    'AC_Locksets_Bullet_Point_Three'
  ];

  const {
    data: license,
    isLoading: isLoadingLicense,
    error: licenseError,
    isError: isFetchLicenseError,
    refetch: refetchLicense
  } = useLicenseById(deployment.licenseId);

  if (isFetchLicenseError) {
    return (
      <Box>
        <ErrorAlert
          errorMessage={licenseError.message}
          customMessage={errorLoadingLicenseStr}
          onRetry={refetchLicense}
        />
      </Box>
    );
  }

  if (isLoadingLicense) {
    return <LoadingSpinner />;
  }

  const renderField = (label: string, value: any, isLast?: boolean) => (
    <FieldDisplay label={t(label)} value={value} isLast={isLast} />
  );

  //#region Handlers
  const handleRenewLocalLicense = () => {
    navigate(`/acniolicensing/deployments/${deployment.deploymentId}/licenseDetails`, {
      state: { renewLocalLicense: true }
    });
  };

  const handleRearmLicense = () => {
    navigate(`/acniolicensing/deployments/${deployment.deploymentId}/licenseDetails`, {
      state: { rearmLicense: true }
    });
  };

  const handleEditPackageDetails = () => {
    if (deployment.deploymentType === DeploymentType.Local) {
      handleRenewLocalLicense();
    } else if (deployment.deploymentType === DeploymentType.Cloud) {
      setOpenUpdatePackageDialog(true);
    }
  };
  const handleCancelSubscription = () => {
    setOpenCancelLicenseDialog(true);
  };
  const handleOpenPurchaseAddOnDialog = () => {
    setOpenPurchaseAddOnDialog(true);
  };

  const handleClosePurchaseAddOnDialog = () => {
    setOpenPurchaseAddOnDialog(false);
  };

  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };
  //# endregion

  return (
    <Box>
      <SnackbarAlert
        type={message?.type || 'info'}
        time={5000}
        text={message?.text || ''}
        isOpen={!!message}
        onClose={() => setMessage(null)}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={styles.sectionContainerStyle}>
            <Typography variant="h6" gutterBottom>
              {licenseDetailsStr}
            </Typography>
            {canEditBilling &&
              deployment.licenseStatus === LicenseStatus.Activated &&
              deployment.deploymentType === DeploymentType.Local &&
              deployment.hasBeenRearmed !== true && (
                <Button
                  startIcon={<AccessTimeIcon />}
                  onClick={handleRearmLicense}
                  sx={LicenseManagementCommonStyles.editButton}
                  aria-label="edit"
                >
                  {rearmLicenseStr}
                </Button>
              )}
            {canEditBilling &&
              deployment.licenseStatus === LicenseStatus.Expired &&
              deployment.deploymentType === DeploymentType.Local && (
                <Button
                  startIcon={<Sync />}
                  onClick={handleRenewLocalLicense}
                  sx={LicenseManagementCommonStyles.editButton}
                  aria-label="edit"
                >
                  {renewLicenseStr}
                </Button>
              )}
            {canEditBilling &&
              deployment.licenseStatus === LicenseStatus.Activated &&
              deployment.deploymentType === DeploymentType.Cloud &&
              deployment.subscriptionStatus !== SubscriptionStatus.Canceled && (
                <Button
                  startIcon={<WarningAmberIcon />}
                  onClick={handleCancelSubscription}
                  sx={LicenseManagementCommonStyles.editButton}
                >
                  {cancelLicenseStr}
                </Button>
              )}
            {license && (
              <CancelLicenseDialog
                open={openCancelLicenseDialog}
                onClose={() => setOpenCancelLicenseDialog(false)}
                deploymentName={deployment.name}
                stripeSubscriptionId={license.stripeSubscriptionId}
                setMessage={setMessage}
                deploymentId={deployment.deploymentId}
              />
            )}

            <Box sx={styles.sectionStyle}>
              {deployment.deploymentType === DeploymentType.Local && (
                <>
                  {renderField('Account_No', deployment.hartmannAccountNumber)}
                  {renderField('License_Package', license?.name)}
                  {renderField('Response_Key', license?.responseKey)}
                </>
              )}
              {renderField('Activation_Date', formatDate(license?.activationDate ?? ''))}
              {renderField('Expiration_Date', formatDate(license?.expirationDate ?? ''), true)}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={isLocalDeployment ? 6 : 12}>
          <Box sx={styles.sectionContainerStyle}>
            <Typography variant="h6" gutterBottom>
              {packageDetailsStr}
            </Typography>

            {canEditBilling && deployment.licenseStatus === LicenseStatus.Activated && (
              <Button
                startIcon={<Edit />}
                onClick={handleEditPackageDetails}
                sx={LicenseManagementCommonStyles.editButton}
                aria-label="edit"
              >
                {deployment.deploymentType === DeploymentType.Local ? modifyLicenseStr : editPackageStr}
              </Button>
            )}

            {license && deployment.licenseStatus === LicenseStatus.Activated && (
              <UpdatePackagePriceDialog
                onClose={() => setOpenUpdatePackageDialog(false)}
                license={license}
                setMessage={setMessage}
                refetchLicense={refetchLicense}
                openUpdatePackageDialog={openUpdatePackageDialog}
              />
            )}

            <Box sx={styles.sectionStyle}>
              {renderField('Doors', license?.numOfDoors)}
              {deployment.deploymentType === DeploymentType.Local && (
                <>
                  {renderField('Cameras', license?.numOfCameras)}
                  {renderField('Partitions', t('Unlimited'))}
                  {renderField('Elevators', t('Available'))}
                  {renderField('IO', t('Available'))}
                  {renderField('LDAP', t('Available'))}
                  {renderField('Unmanaged_Doors', t('Available'), true)}
                </>
              )}
              {deployment.deploymentType === DeploymentType.Cloud && (
                <>
                  {renderField('I/O_Expanders', license?.numOfExpanders)}
                  {renderField('Elevator_Cabs', license?.numOfElevators, true)}
                </>
              )}
            </Box>
          </Box>
        </Grid>
        {isLocalDeployment && (
          <Grid item xs={6}>
            <Box sx={styles.sectionContainerStyle}>
              <Typography variant="h6" gutterBottom>
                {addOnLicenseStr}
              </Typography>
              <Typography variant="h6" color="text.secondary">
                {acLocksetDescStr}
              </Typography>

              <Box sx={styles.sectionStyle}>
                {hasLicenseAddOns ? (
                  <Grid container direction="column">
                    {renderField(acLicenseSubtitleStr, acLicenseStr)}
                    {renderField(acLocksetWirelessLockSubtitle, acLocksetWirelessLock)}
                    {renderField(acProductStr, totalAddOnCount)}
                    <Grid item container alignItems="center">
                      {renderField(acLocksetCode, lastGeneratedAddOnResponseKey)}
                      <Tooltip title={t('Copy_To_Clipboard')}>
                        <IconButton
                          onClick={() => handleCopyToClipboard(lastGeneratedAddOnResponseKey)}
                          size="small"
                          sx={styles.copyButton}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Button sx={styles.addOnAdditionalPurchase} size="small" onClick={handleOpenPurchaseAddOnDialog}>
                      <AddIcon />
                      {acLocksetAdditionalPurchaseStr}
                    </Button>
                  </Grid>
                ) : (
                  <Card>
                    <Grid container>
                      <Grid item xs={3}>
                        <CardMedia
                          component="img"
                          height="50%"
                          image={s3ImageUrls[0]}
                          alt="AddOn License Image"
                          sx={styles.cardMedia}
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <CardContent sx={styles.cardContent}>
                          <Typography variant="h5" color="text.primary">
                            {acLocksetStr}
                          </Typography>
                          {bulletPoints.map((point) => (
                            <Box key={point} sx={styles.bulletPoints}>
                              <CheckIcon sx={styles.checkIcon} />
                              <Typography variant="body2" color="text.secondary">
                                {t(point)}
                              </Typography>
                            </Box>
                          ))}
                        </CardContent>
                        <CardActions sx={styles.cardActions}>
                          <Box sx={styles.priceDescription}>
                            <Typography variant="body1" color="text.secondary">
                              {startingAtStr}
                              <Typography variant="h6" component="span" color="text.primary">
                                {acLocksetPriceStr}
                              </Typography>
                            </Typography>
                          </Box>
                          <Button
                            sx={license?.hartmannLicenseId ? styles.addOnButtonPurchase : styles.addOnButtonDisabled}
                            size="small"
                            onClick={handleOpenPurchaseAddOnDialog}
                            disabled={!license?.hartmannLicenseId}
                          >
                            {acLocksetPurchaseStr}
                          </Button>
                        </CardActions>
                      </Grid>
                    </Grid>
                  </Card>
                )}
              </Box>
            </Box>
            {license?.hartmannLicenseId && (
              <LicenseAddOnDialog
                openLicenseAddOnDialog={openPurchaseAddOnDialog}
                deployment={deployment}
                accountNumber={deployment.hartmannAccountNumber}
                hartmannLicenseId={deployment.licenseId}
                onClose={handleClosePurchaseAddOnDialog}
                setIsOpen={setOpenPurchaseAddOnDialog}
              ></LicenseAddOnDialog>
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

const styles = {
  sectionContainerStyle: {
    p: 2,
    position: 'relative'
  },
  sectionStyle: {
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    position: 'relative',
    mt: 2
  },
  checkIcon: {
    color: theme.palette.green.main,
    mr: 1
  },
  cardContent: {
    justifyContent: 'flex-end'
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    alignItems: 'center'
  },
  bulletPoints: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  addOnButtonPurchase: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.white.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main
    }
  },
  addOnButtonDisabled: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.grey[500],
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[500]
    }
  },
  addOnAdditionalPurchase: {
    width: '60%',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.white.main,
    border: `1px solid ${theme.palette.primary.main}`,
    pt: '4px',
    pr: '10px',
    pb: '4px',
    mb: 2,
    ml: 1
  },
  priceDescription: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& span': {
      ml: 1
    }
  },
  titleContainer: {
    padding: '1rem',
    title: {
      padding: 0
    }
  },
  header: {
    display: 'flex',
    minHeight: '5rem',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  cardMedia: {
    objectFit: 'contain',
    mt: 2
  },
  openNewIcon: {
    mr: 1
  },
  copyButton: {
    marginLeft: '8px'
  }
};
