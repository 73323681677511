import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogContent,
  FormControl,
  TextField,
  Select,
  SelectChangeEvent,
  InputLabel,
  MenuItem,
  Button,
  Box,
  Typography,
  CircularProgress,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// #region import LicenseManagement
// Styles
// Types
import { DeploymentType, CreateDeploymentRequest } from '../../../common/Types';
// #endregion

import { EnumList, fetchEnumList } from 'shared/utils/EnumUtils';
import { getFilteredStateList } from 'features/LicenseManagement/components/common/utils';
import { getCountryOptions } from '../../../common/GetCountryOptions';
import DeploymentNotificationsField from './NotificationField';
import { EMAIL_REGEX } from 'shared/constants/regex';
import { LicenseManagementCommonStyles } from 'features/LicenseManagement/styles/CommonStyles';
import PhoneField from 'shared/components/forms/fields/PhoneField';
import { convertPhoneNumberToE164Format, formatPhoneNumber } from 'shared/utils/helperFunctions';
import { formatAmericanZipCode, formatCanadianPostalCode } from 'shared/utils/helperFunctions';
import {
  PostalCodeValidCharacters,
  RegexPostalCode,
  RegexZipCode,
  ZipCodeValidCharacters
} from 'features/RemoteManagement/types/Types';

const HOSTED_DEPLOYMENT_DOMAIN = '.acnio.cloud';

// #region Interfaces
/**
 * Props interface for CreateDeploymentInformationModal
 */
interface CreateDeploymentInformationModalProps {
  /**
   * Indicates whether the modal is open.
   * This modal is for entering the deployment type information.
   */
  open: boolean;
  /**
   * Callback to open the previous modal (type modal).
   */
  onOpenTypeModal: () => void;
  /**
   * Callback to close the modal.
   * @param resetForm Whether to reset the form or not.
   */
  onClose: (resetForm: boolean) => void;
  /**
   * Form data entered by the user.
   */
  formData: CreateDeploymentRequest;
  /**
   * Function to update the form data.
   */
  setFormData: React.Dispatch<React.SetStateAction<CreateDeploymentRequest>>;
  /**
   * Callback to create the deployment.
   */
  onCreateLocalDeployment: () => void;
  /**
   * Indicates whether the deployment is being created.
   */
  isPending: boolean;
}
// #endregion

// #region Constants

export const initialErrors = {
  deployment: {
    name: false,
    emailRecipients: false
  },
  tenant: {
    subdomain: false
  },
  address: {
    street: false,
    city: false,
    postalCode: false
  },
  contact: {
    name: false,
    emailAddress: false,
    phoneNumber: false
  }
};

// #endregion
const CreateDeploymentInformationModal = (props: CreateDeploymentInformationModalProps) => {
  const { open, onOpenTypeModal, onClose, formData, setFormData, onCreateLocalDeployment, isPending } = props;
  const siteSelected = formData.linkedAiphoneSitePublicId !== null;

  // #region Translation
  const { t } = useTranslation('acNioLicenseManagement');
  const countryStr = t('Deployment_Address_Country');
  const stateStr = t('Deployment_Address_State');
  const provinceStr = t('Deployment_Address_Province');
  const cityStr = t('Deployment_Address_City');
  const postalCodeStr = t('Deployment_Address_Postal_Code');
  const postalCodeInvalidStr = t('Deployment_Postal_Code_Invalid');
  const requiredFieldStr = t('Required_Field');

  // #endregion

  // #region State Management
  const [enumList, setEnumList] = useState<EnumList>({ country: {}, state: {} });
  const [countryId, setCountryId] = useState('2');
  const [errors, setErrors] = useState(initialErrors);

  //Fetch enumlist when component mounts
  useEffect(() => {
    fetchEnumList().then((data) => {
      setEnumList(data);
    });
  }, []);

  // Update countryId when countryName changes
  useEffect(() => {
    if (formData.address.countryName === 'Canada') {
      setCountryId('3');
    } else {
      setCountryId('2');
    }
  }, [formData.address.countryName]);

  // #endregion

  // #region Handlers
  const validateField = (name: string, value: string) => {
    const [topLevelKey, nestedKey] = name.split('.') as [keyof typeof errors, string];
    let hasError = false;

    if (topLevelKey === 'contact' && nestedKey === 'emailAddress') {
      hasError = value.trim() === '' || !EMAIL_REGEX.test(value);
    } else if (topLevelKey === 'contact' && nestedKey === 'phoneNumber') {
      const cleanedValue = value.replace(/[^0-9]/g, '');
      hasError = cleanedValue.length < 8 || cleanedValue.length > 15;
    } else if (topLevelKey === 'address' && nestedKey === 'postalCode') {
      hasError =
        value.trim() === '' ||
        (countryId === '2' && !RegexZipCode.test(value)) ||
        (countryId === '3' && !RegexPostalCode.test(value));
    } else if (nestedKey !== 'description' && nestedKey !== 'aptSuiteBuilding') {
      hasError = value.trim() === '';
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [topLevelKey]: {
        ...prevErrors[topLevelKey],
        [nestedKey]: hasError
      }
    }));
  };

  const handleNestedFieldChange = (name: string, value: string) => {
    const [topLevelKey, nestedKey] = name.split('.') as [keyof CreateDeploymentRequest, string];
    setFormData((prev) => {
      const topLevelValue = prev[topLevelKey];
      if (typeof topLevelValue !== 'object' || topLevelValue === null) {
        return prev;
      }
      return {
        ...prev,
        [topLevelKey]: {
          ...topLevelValue,
          [nestedKey]: value
        }
      };
    });
  };

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let newValue = value;
    switch (name) {
      case 'contact.phoneNumber':
        newValue = value;
        break;
      case 'contact.emailAddress':
        newValue = value.replace(/[^\w\s@.+]/gi, '');
        break;
      case 'tenant.subdomain':
        newValue = value.replace(/[^a-zA-Z0-9]/g, '').replace(/\s+/g, '');
        break;
      case 'address.postalCode':
        if (countryId === '2') {
          newValue = value.slice(0, 5).replace(ZipCodeValidCharacters, '');
          newValue = formatAmericanZipCode(newValue);
        } else if (countryId === '3') {
          newValue = value.slice(0, 7).replace(PostalCodeValidCharacters, '');
          newValue = formatCanadianPostalCode(newValue);
        }
        break;
      case 'address.city':
        newValue = value.replace(/[^a-zA-Z\s]/g, '');
        break;
      default:
        newValue = value.replace(/[^\w\s@]/gi, '');
        break;
    }
    handleNestedFieldChange(name, newValue);
    validateField(name, newValue);
  };

  const handleCountryChange = (event: SelectChangeEvent) => {
    const selectedCountry = event.target.value as string;
    const newCountryId = selectedCountry === 'United States of America' ? '2' : '3';
    setCountryId(newCountryId);

    setFormData((prevData: CreateDeploymentRequest) => ({
      ...prevData,
      address: {
        ...prevData.address,
        countryName: selectedCountry,
        stateName: '-',
        postalCode: ''
      }
    }));
  };

  const handleStateChange = (event: SelectChangeEvent) => {
    const selectedState = event.target.value as string;

    setFormData((prevData: CreateDeploymentRequest) => ({
      ...prevData,
      address: {
        ...prevData.address,
        stateName: selectedState
      }
    }));
  };

  const handleBack = () => {
    onClose(false);
    onOpenTypeModal();
  };

  const handleCreateDeployment = () => {
    setFormData((prev) => ({
      ...prev,
      contact: {
        ...prev.contact,
        phoneNumber: convertPhoneNumberToE164Format(prev.contact.phoneNumber)
      }
    }));
    setTimeout(() => {
      onCreateLocalDeployment();
    }, 0);
  };

  const isFormValid = () => {
    const cleanedPhoneNumber = formData.contact.phoneNumber.replace(/[^0-9]/g, '');

    const isRequiredFieldsValid =
      formData.deployment.name.trim() !== '' &&
      formData.address.street.trim() !== '' &&
      formData.address.city.trim() !== '' &&
      formData.address.postalCode.trim() !== '' &&
      formData.contact.name.trim() !== '' &&
      formData.contact.emailAddress.trim() !== '' &&
      cleanedPhoneNumber.length >= 10;

    const isSubdomainValid =
      formData.deployment.type !== DeploymentType.Cloud || formData.tenant.subdomain.trim() !== '';

    const isStateNameValid = formData.address.stateName.trim() !== '-';

    const areAllErrorsFalse = Object.values(errors).every((nestedObj) =>
      Object.values(nestedObj).every((val) => val === false)
    );

    return isRequiredFieldsValid && isSubdomainValid && isStateNameValid && areAllErrorsFalse;
  };

  const getStateList = (countryId: string) => {
    return getFilteredStateList(countryId, enumList, (state) => (
      <MenuItem key={state.value} value={state.value}>
        {state.value}
      </MenuItem>
    ));
  };

  const handleEmailRecipientsChange = (newRecipients: string[]) => {
    setFormData((prev) => ({
      ...prev,
      deployment: {
        ...prev.deployment,
        emailRecipients: newRecipients
      }
    }));
  };

  // #endregion

  // #region Render
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (isPending && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
          // Do not close the modal while the API is executing
          return;
        }
        setErrors(initialErrors);
        onClose(true);
      }}
      disableEscapeKeyDown={isPending} // Prevent closing the modal with the escape key while API is executing
      PaperProps={{
        style: styles.modalContainer
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography style={styles.moduleTitle}>{t('Deployment_Details_Title')}</Typography>
        <IconButton aria-label="close" onClick={() => onClose(true)} sx={styles.closeContainer}>
          <CloseIcon color={'primary'} sx={styles.closeContainer.closeIcon} />
        </IconButton>
      </Box>
      <Typography style={styles.moduleSecondaryTitle}>{t('Deployment_Info_Subheader')}</Typography>
      <Box style={styles.divider} />
      <DialogContent style={styles.moduleContent}>
        <Typography style={{ ...styles.moduleSubTitle, marginBottom: '0.5rem' }}>
          {t('Deployment_Enter_Info')}
        </Typography>
        <FormControl fullWidth style={styles.inputBoxMarginBottom}>
          <TextField
            required
            label="Deployment Name"
            name="deployment.name"
            sx={styles.textField}
            InputLabelProps={{
              shrink: true
            }}
            value={formData.deployment.name}
            onChange={handleTextFieldChange}
            error={!!errors.deployment.name}
            helperText={errors.deployment.name ? t('Required_Field') : ''}
          />
        </FormControl>
        <FormControl fullWidth style={styles.inputBoxMarginBottom}>
          <TextField
            label="Description"
            name="deployment.description"
            sx={styles.textField}
            InputLabelProps={{
              shrink: true
            }}
            value={formData.deployment.description}
            onChange={handleTextFieldChange}
          />
        </FormControl>
        {formData.deployment.type === DeploymentType.Cloud && (
          <FormControl fullWidth style={styles.inputBoxMarginBottom}>
            <Box display="flex" alignItems="center">
              <TextField
                required
                label="Subdomain"
                name="tenant.subdomain"
                sx={{ ...styles.textField, width: '420px' }}
                InputProps={{
                  inputProps: {
                    maxLength: 10
                  }
                }}
                InputLabelProps={{
                  shrink: true
                }}
                value={formData.tenant.subdomain}
                onChange={handleTextFieldChange}
                error={!!errors.tenant.subdomain}
                helperText={errors.tenant.subdomain ? t('Required_Field') : ''}
              />
              <Typography variant="body1" sx={{ marginLeft: '0.5rem' }}>
                {HOSTED_DEPLOYMENT_DOMAIN}
              </Typography>
            </Box>
          </FormControl>
        )}

        <Box display="flex" gap="1rem" style={styles.inputBoxMarginBottom}>
          <FormControl fullWidth>
            <TextField
              required
              label="Address"
              name="address.street"
              sx={{
                ...styles.textField,
                ...(siteSelected && {
                  ...styles.textFieldReadOnly
                })
              }}
              InputLabelProps={{
                shrink: true
              }}
              value={formData.address.street}
              onChange={handleTextFieldChange}
              error={!!errors.address.street}
              helperText={errors.address.street ? t('Required_Field') : ''}
              InputProps={{
                readOnly: siteSelected
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="Apt/Suite/Building"
              name="address.aptSuiteBuilding"
              sx={styles.textField}
              InputLabelProps={{
                shrink: true
              }}
              value={formData.address.aptSuiteBuilding}
              onChange={handleTextFieldChange}
            />
          </FormControl>
        </Box>

        <Box display="flex" gap="1rem" style={styles.inputBoxMarginBottom}>
          <FormControl fullWidth>
            <InputLabel id="country-select-label">{countryStr} *</InputLabel>
            <Select
              labelId="country-select-label"
              value={formData.address.countryName}
              onChange={handleCountryChange}
              sx={{
                ...styles.textField,
                ...(siteSelected && {
                  ...styles.textFieldReadOnly
                })
              }}
              label="Country *"
              disabled={siteSelected}
            >
              {getCountryOptions(enumList).map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="state-select-label">{countryId === '2' ? stateStr : provinceStr} *</InputLabel>
            <Select
              labelId="state-select-label"
              value={formData.address.stateName}
              onChange={handleStateChange}
              sx={{
                ...styles.textField,
                ...(siteSelected && {
                  ...styles.textFieldReadOnly
                })
              }}
              label="State *"
              disabled={siteSelected}
            >
              <MenuItem key="Select_State" value="-" disabled={true}>
                -
              </MenuItem>
              {getStateList(countryId)}
            </Select>
          </FormControl>
        </Box>

        <Box display="flex" gap="1rem" style={styles.inputBoxMarginBottom}>
          <FormControl fullWidth>
            <TextField
              required
              label={cityStr}
              name="address.city"
              sx={{
                ...styles.textField,
                ...(siteSelected && {
                  ...styles.textFieldReadOnly
                })
              }}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                readOnly: siteSelected
              }}
              value={formData.address.city}
              onChange={handleTextFieldChange}
              error={!!errors.address.city}
              helperText={errors.address.city ? t('Required_Field') : ''}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              required
              label={postalCodeStr}
              name="address.postalCode"
              sx={{
                ...styles.textField,
                ...(siteSelected && {
                  ...styles.textFieldReadOnly
                })
              }}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                readOnly: siteSelected
              }}
              value={formData.address.postalCode}
              onChange={handleTextFieldChange}
              error={!!errors.address.postalCode}
              helperText={
                errors.address.postalCode
                  ? !RegexPostalCode.test(formData.address.postalCode)
                    ? postalCodeInvalidStr
                    : requiredFieldStr
                  : ''
              }
            />
          </FormControl>
        </Box>

        <Typography style={{ ...styles.moduleSubTitle, marginBottom: '0.5rem' }}>
          {t('Deployment_Enter_Contact_Info')}
        </Typography>
        <FormControl fullWidth style={styles.inputBoxMarginBottom}>
          <TextField
            label="Company Name"
            name="contact.companyName"
            sx={styles.textField}
            InputLabelProps={{
              shrink: true
            }}
            value={formData.contact.companyName}
            onChange={handleTextFieldChange}
          />
        </FormControl>
        <FormControl fullWidth style={styles.inputBoxMarginBottom}>
          <TextField
            required
            label="Representative Name"
            name="contact.name"
            sx={styles.textField}
            InputLabelProps={{
              shrink: true
            }}
            value={formData.contact.name}
            onChange={handleTextFieldChange}
            error={!!errors.contact.name}
            helperText={errors.contact.name ? t('Required_Field') : ''}
          />
        </FormControl>
        <Box display="flex" gap="1rem" style={styles.inputBoxMarginBottom}>
          <FormControl fullWidth>
            <TextField
              required
              label="Email"
              name="contact.emailAddress"
              sx={styles.textField}
              InputLabelProps={{
                shrink: true
              }}
              value={formData.contact.emailAddress}
              onChange={handleTextFieldChange}
              error={!!errors.contact.emailAddress}
              helperText={errors.contact.emailAddress ? t('Required_Field_Email') : ''}
            />
          </FormControl>
          <FormControl fullWidth>
            <PhoneField
              field={{
                name: 'contact.phoneNumber',
                value: formData.contact.phoneNumber,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                  handleNestedFieldChange('contact.phoneNumber', event.target.value),
                onBlur: () => validateField('contact.phoneNumber', formData.contact.phoneNumber)
              }}
              form={{
                values: formData,
                errors: errors,
                touched: errors,
                setFieldValue: async (name: string, value: string) => {
                  const formattedValue = formatPhoneNumber(value);
                  setFormData((prev) => ({
                    ...prev,
                    contact: { ...prev.contact, phoneNumber: formattedValue }
                  }));
                },
                setTouched: async (touched) => {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    contact: { ...prevErrors.contact, phoneNumber: touched.contact?.phoneNumber || false }
                  }));
                  return Promise.resolve();
                },
                isSubmitting: false,
                isValidating: false,
                submitCount: 0
              }}
              sx={styles.textField}
              InputLabelProps={{ shrink: true }}
              label="Phone Number"
              error={!!errors.contact.phoneNumber}
              helperText={errors.contact.phoneNumber ? t('Required_Field') : ''}
            />
          </FormControl>
        </Box>
        <Typography style={{ ...styles.moduleSubTitle, marginBottom: '0.5rem' }}>
          {t('Deployment_Notifications')}
        </Typography>
        <Typography style={{ ...styles.moduleSubTitleInfo, marginBottom: '0.5rem' }}>
          {t('Deployment_Notifications_Subheader')}
        </Typography>

        <DeploymentNotificationsField
          label={t('Notifications_To')}
          name="deployment.emailRecipients"
          value={formData.deployment.emailRecipients || []}
          onChange={handleEmailRecipientsChange}
          error={errors.deployment.emailRecipients}
          setErrors={setErrors}
          helperText={errors.deployment.emailRecipients ? t('Required_Field_Email') : ''}
        />

        <Box style={LicenseManagementCommonStyles.buttonContainer}>
          <Button
            variant="text"
            sx={LicenseManagementCommonStyles.buttonBack}
            onClick={handleBack}
            disabled={isPending}
          >
            {t('Back')}
          </Button>
          <Button
            variant="contained"
            sx={{ ...LicenseManagementCommonStyles.button, width: '170px' }}
            disabled={!isFormValid() || isPending}
            onClick={handleCreateDeployment}
          >
            {isPending ? <CircularProgress size={24} color="inherit" /> : t('Create_Deployment')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
  // #endregion
};

// #region Styles
const styles = {
  modalContainer: {
    width: '60%',
    minWidth: '750px',
    maxWidth: '1200px',
    margin: '0 auto'
  },
  moduleContent: {
    padding: '1.5rem'
  },
  closeContainer: {
    padding: '0',
    margin: '1rem 1rem 1rem 0rem',
    closeIcon: {
      fontSize: '3rem'
    }
  },
  moduleTitle: {
    fontSize: '1.5rem',
    margin: '0.8rem 0rem 0rem 1.5rem',
    fontWeight: 'bold'
  },
  moduleSecondaryTitle: {
    fontSize: '1rem',
    color: 'rgba(0, 0, 0, 0.6)',
    margin: '0rem 0rem 0.3rem 1.5rem'
  },
  divider: {
    height: '1px',
    backgroundColor: '#e0e0e0',
    margin: '0 0.5rem'
  },
  moduleSubTitle: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: '#444'
  },
  moduleSubTitleInfo: {
    fontSize: '1rem',
    color: '#444'
  },
  textField: {
    height: '40px',
    '& .MuiInputBase-root': {
      height: '40px',
      '&.Mui-focused fieldset': {
        borderColor: '#1CACFE'
      }
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1CACFE'
    },
    '& input:-webkit-autofill': {
      borderRadius: '10px',
      height: '9px'
    }
  },
  textFieldReadOnly: {
    backgroundColor: '#f5f5f5'
  },
  inputBoxMarginBottom: {
    marginBottom: '1.6rem'
  }
};
// #endregion
export default CreateDeploymentInformationModal;
