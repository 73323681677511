import { ArrowBack } from '@mui/icons-material';
import { Box, Divider } from '@mui/material';
import { Menu, MenuItem, Sidebar, SubMenu } from 'react-pro-sidebar';
import useSharedStyles from 'styles/useSharedStyles';
import useSidebarMenuItems, { MenuItemEnum } from './useSidebarMenuItems';
import { Link } from 'react-router-dom';

interface AppSidebarProps {
  collapsed: boolean;
  toggled: boolean;
}

/**
 * AppSidebar component renders a sidebar with menu items, a back button, and footer menu items.
 *
 * @example
 * // Example usage of AppSidebar component
 * <AppSidebar toggled={true} collapsed={false} />
 *
 * @remarks
 * This component uses `useSharedStyles` for styling and `useSidebarMenuItems` for fetching menu items.
 * It supports nested submenus and renders a back button if `backMenuItem` is provided.
 */
const AppSidebar = ({ toggled, collapsed }: AppSidebarProps) => {
  const sharedStyle = useSharedStyles();
  const { menuItems, footerMenuItems, backMenuItem } = useSidebarMenuItems();

  return (
    <Sidebar
      width={sharedStyle.sideBar.container.width}
      toggled={toggled}
      collapsed={collapsed}
      rootStyles={{
        [`.ps-sidebar-container`]: {
          backgroundColor: '#FFFFFF'
        }
      }}
    >
      <Menu
        menuItemStyles={{
          button: sharedStyle.sideBar.menuItem.button,
          icon: sharedStyle.sideBar.menuItem.icon,
          root: sharedStyle.sideBar.menuItem.root
        }}
      >
        {backMenuItem && (
          <Box sx={{ paddingY: 1 }}>
            <MenuItem icon={<ArrowBack />} component={<Link to={backMenuItem.to} />}>
              {backMenuItem.text}
            </MenuItem>
            <Divider />
          </Box>
        )}
        {menuItems.map((item, index) =>
          item.type === MenuItemEnum.SubMenu && 'children' in item ? (
            <SubMenu key={index} label={item.text} icon={item.icon}>
              {item.children.map((child, childIndex) => (
                <MenuItem key={childIndex} icon={child.icon} component={<Link to={child.to} />} active={child.active}>
                  {child.text}
                </MenuItem>
              ))}
            </SubMenu>
          ) : (
            <MenuItem key={index} icon={item.icon} component={<Link to={item.to} />} active={item.active}>
              {item.text}
            </MenuItem>
          )
        )}
        <Box sx={styles.absoluteBottom}>
          <Divider />
          {footerMenuItems.map((item, index) => (
            <MenuItem
              key={index}
              icon={item.icon}
              component={<Link to={item.to} target={item.target} />}
              active={item.active}
            >
              {item.text}
            </MenuItem>
          ))}
        </Box>
      </Menu>
    </Sidebar>
  );
};

const styles = {
  absoluteBottom: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%'
  }
};

export default AppSidebar;
