import { StripeProduct } from 'features/LicenseManagement/components/common/Types';

export const calculateDoorsPrice = (numDoors: number, stripeProducts: StripeProduct[]) => {
  const doorPriceDetails = [];
  let totalDoorCost = 0;
  let totalDiscount = 0;
  const stripeCheckoutItems = [];

  if (numDoors > 0 && stripeProducts?.length > 0) {
    // Separate products with valid maxDoorCount and the "25+ doors" product (null maxDoorCount)
    const sortedProducts = stripeProducts
      .filter((product) => product.maxDoorCount !== null)
      .sort((a, b) => a.maxDoorCount - b.maxDoorCount);

    const largestProduct = stripeProducts.find((p) => p.maxDoorCount === null);
    let remainingDoors = numDoors;

    // Process products with valid maxDoorCount
    for (const product of sortedProducts) {
      const applicableQty = Math.min(remainingDoors, product.maxDoorCount);
      if (applicableQty <= 0) continue;

      const unitPrice = product.unitAmount / 100;
      const discount = product.discount || 0;

      const rangeTotal = applicableQty * unitPrice;
      const rangeTotalDiscount = applicableQty * discount;

      doorPriceDetails.push({
        qty: applicableQty,
        price: unitPrice,
        total: rangeTotal,
        discount: discount,
        rangeTotalDiscount: rangeTotalDiscount
      });

      stripeCheckoutItems.push({
        priceId: product.priceId,
        quantity: applicableQty
      });

      totalDoorCost += rangeTotal;
      totalDiscount += rangeTotalDiscount;
      remainingDoors -= applicableQty;
    }

    // Handle remaining doors explicitly using the "25+ Door License"
    if (remainingDoors > 0 && largestProduct) {
      const unitPrice = largestProduct.unitAmount / 100;
      const discount = largestProduct.discount || 0;

      const rangeTotal = remainingDoors * unitPrice;
      const rangeTotalDiscount = remainingDoors * discount;

      doorPriceDetails.push({
        qty: remainingDoors,
        price: unitPrice,
        total: rangeTotal,
        discount: discount,
        rangeTotalDiscount: rangeTotalDiscount
      });

      stripeCheckoutItems.push({
        priceId: largestProduct.priceId,
        quantity: remainingDoors
      });

      totalDoorCost += rangeTotal;
      totalDiscount += rangeTotalDiscount;
    }
  }

  return { doorPriceDetails, totalDoorCost, totalDiscount, stripeCheckoutItems };
};

export const calculateGrandTotal = (stripeProducts: StripeProduct[], numExpanders: number, numCabs: number) => {
  let expandersCost = 0;
  let cabsCost = 0;
  const stripeCheckoutItems = [];
  // Find Stripe products for Expanders and Elevator Cabs
  const expanderProduct = stripeProducts.find((p) => p.name.includes('D-AC-NIO-CLD-IO'));
  const cabProduct = stripeProducts.find((p) => p.name.includes('D-AC-NIO-CLD-ELV'));

  // Calculate Expanders cost and add to Stripe Checkout
  if (numExpanders > 0 && expanderProduct) {
    expandersCost = (expanderProduct.unitAmount / 100) * numExpanders;
    stripeCheckoutItems.push({
      priceId: expanderProduct.priceId,
      quantity: numExpanders
    });
  }

  // Calculate Elevator Cabs cost and add to Stripe Checkout
  if (numCabs > 0 && cabProduct) {
    cabsCost = (cabProduct.unitAmount / 100) * numCabs;
    stripeCheckoutItems.push({
      priceId: cabProduct.priceId,
      quantity: numCabs
    });
  }

  const grandTotal = expandersCost + cabsCost;

  return { expandersCost, cabsCost, grandTotal, stripeCheckoutItems };
};
