import { useCallback } from 'react';
import type { Action, Dispatch } from 'redux';
import { getDeviceModelNumberFromModelType, getDeviceTypeFromModelNumber } from 'shared/utils/helperFunctions';
import { Status } from 'shared/utils/Status';
import { DeviceList, IDevice } from 'store/slices/devicesSlice';
import { DeviceTypesModel } from '../../types/Types';
import { getLatestFirmwareDownloadUrl } from 'shared/utils/firmwareFunctions';

export const getSetUsernameAndToken = (
  setIsLoginPanelOpen: React.Dispatch<React.SetStateAction<boolean>>,
  dispatch: Dispatch<Action>,
  closeDelay = 0
) => {
  return useCallback(
    async (userName: string, receivedToken: string) => {
      localStorage.setItem('acltoken', receivedToken);
      localStorage.setItem('aclUserName', userName);
      setTimeout(() => {
        setIsLoginPanelOpen(false);
      }, closeDelay);
    },
    [dispatch]
  );
};

export const getLatestFirmwareVersions = (modelNumber: string, latestFirmwareList: any) => {
  const deviceType = getDeviceTypeFromModelNumber(modelNumber);
  if (deviceType) {
    return {
      latestStandardFirmware: latestFirmwareList[deviceType]?.standard?.version,
      latestEnhancedFirmware: latestFirmwareList[deviceType]?.enhanced?.version
    };
  }
  return { latestStandardFirmware: null, latestEnhancedFirmware: null };
};

export const getLatestFirmwareFileName = (modelNumber: string, latestFirmwareList: any) => {
  const deviceType = getDeviceTypeFromModelNumber(modelNumber);
  if (deviceType) {
    return {
      latestStandardFirmwareFileName: latestFirmwareList[deviceType]?.standard?.name,
      latestEnhancedFirmwareFileName: latestFirmwareList[deviceType]?.enhanced?.name
    };
  }
  return { latestStandardFirmwareFileName: null, latestEnhancedFirmwareFileName: null };
};

export const getLatestFirmwareLink = (modelNumber: string, latestFirmwareList: any) => {
  const deviceType = getDeviceTypeFromModelNumber(modelNumber);
  if (deviceType) {
    return {
      latestStandardFirmwareLink: latestFirmwareList[deviceType]?.standard?.link,
      latestEnhancedFirmwareLink: latestFirmwareList[deviceType]?.enhanced?.link
    };
  }
  return { latestStandardFirmwareLink: null, latestEnhancedFirmwareLink: null };
};

export const isLatestFirmware = (
  currentFirmware: number,
  latestStandardFirmware: number | null,
  latestEnhancedFirmware: number | null
) => {
  return latestStandardFirmware === currentFirmware || latestEnhancedFirmware === currentFirmware;
};

/**
 * Get the device status based on the device's properties
 * @param device
 * @returns return the device status based on the device's properties
 */
export const getDeviceStatus = (device: IDevice, searchIpAddress?: string) => {
  // standardize the device date for comparison
  const lastSyncedOn = device.lastSyncedOn ? new Date(device.lastSyncedOn) : null;
  const lastUpdatedOn = device.lastUpdatedOn ? new Date(device.lastUpdatedOn) : null;
  const standardizedLastSyncedOn = lastSyncedOn ? lastSyncedOn.toISOString() : null;
  const standardizedLastUpdatedOn = lastUpdatedOn ? lastUpdatedOn.toISOString() : null;

  if (
    !device.networkSettings?.ipV4Address ||
    device.networkSettings?.ipV4Address === '0.0.0.0' ||
    searchIpAddress !== device.networkSettings?.ipV4Address
  ) {
    console.log(device.networkSettings?.ipV4Address);
    console.log(searchIpAddress);
    return Status.NeedsAssociation;
  } else if (
    device.unitPublicId === null &&
    device.basicInfo.deviceType !== 6 &&
    device.basicInfo.deviceType !== 17 &&
    device.basicInfo.deviceType !== 18
  ) {
    return Status.NeedsUnit;
  } else if (device.basicInfo?.stationNumber === '') {
    return Status.NeedsStationNumber;
  } else if (
    (device.basicInfo.deviceType === 4 ||
      device.basicInfo.deviceType === 14 ||
      device.basicInfo.deviceType === 15 ||
      device.basicInfo.deviceType === 16) &&
    device.systemInfo?.addressBook?.[0]?.targetDevicePublicId === undefined &&
    device.systemInfo?.addressBook?.[0]?.targetUnitPublicId === undefined
  ) {
    return Status.NeedsAddressBook;
  } else if (
    (device.basicInfo.deviceType === 5 ||
      device.basicInfo.deviceType === 8 ||
      device.basicInfo.deviceType === 9 ||
      device.basicInfo.deviceType === 10 ||
      device.basicInfo.deviceType === 11 ||
      device.basicInfo.deviceType === 12 ||
      device.basicInfo.deviceType === 20) &&
    device?.callSettings?.contactGroupList[1].targetList?.[0]?.targetDevicePublicId === undefined &&
    device?.callSettings?.contactGroupList[1].targetList?.[0]?.targetUnitPublicId === undefined
  ) {
    return Status.NeedsCallList;
  } else if (
    device.lastSyncedOn === null ||
    device.lastUpdatedOn === null ||
    (standardizedLastSyncedOn !== null &&
      standardizedLastUpdatedOn !== null &&
      standardizedLastSyncedOn < standardizedLastUpdatedOn)
  ) {
    return Status.NeedsSync;
  } else {
    return Status.StandBy;
  }
};

export const getDeviceSyncStatus = (device: IDevice) => {
  // standardize the device date for comparison
  const lastSyncedOn = device.lastSyncedOn ? new Date(device.lastSyncedOn) : null;
  const lastUpdatedOn = device.lastUpdatedOn ? new Date(device.lastUpdatedOn) : null;
  const standardizedLastSyncedOn = lastSyncedOn ? lastSyncedOn.toISOString() : null;
  const standardizedLastUpdatedOn = lastUpdatedOn ? lastUpdatedOn.toISOString() : null;

  if (
    device.lastSyncedOn === null ||
    device.lastUpdatedOn === null ||
    (standardizedLastSyncedOn !== null &&
      standardizedLastUpdatedOn !== null &&
      standardizedLastSyncedOn < standardizedLastUpdatedOn)
  ) {
    return Status.NeedsSync;
  } else {
    return Status.StandBy;
  }
};

export const generateFirmwareUpdatePayload = async (
  devicePublicId: string,
  deviceList: DeviceList,
  latestFirmwareList: any
) => {
  const device = deviceList[devicePublicId];
  const firmwareRequest = await getLatestFirmwareDownloadUrl(DeviceTypesModel[device.basicInfo.deviceType]);
  const modelNumber =
    getDeviceModelNumberFromModelType(device.basicInfo.deviceModel, device.basicInfo.deviceType) || '';
  const { latestStandardFirmwareFileName, latestEnhancedFirmwareFileName } = getLatestFirmwareFileName(
    modelNumber,
    latestFirmwareList
  );

  const payload = {
    [device.networkSettings?.ipV4Address as string]: {
      devicePublicId,
      mac_addr: device.basicInfo.macAddress,
      station_type: DeviceTypesModel[device.basicInfo.deviceType],
      firmware_file_name:
        modelNumber === 'IXGW-GW' || modelNumber === 'IXGW-TGW'
          ? latestEnhancedFirmwareFileName
          : latestStandardFirmwareFileName
    },
    [DeviceTypesModel[device.basicInfo.deviceType]]: {
      firmware_link: firmwareRequest?.downloadUrl,
      firmware_file_name:
        modelNumber === 'IXGW-GW' || modelNumber === 'IXGW-TGW'
          ? latestEnhancedFirmwareFileName
          : latestStandardFirmwareFileName
    }
  };
  return payload;
};

export const sortStationsByStatus = (
  a: [string, { statusCode: string }],
  b: [string, { statusCode: string }]
): number => {
  const statusA = a[1].statusCode.slice(0, 3); // Extract the status code from the first element
  const statusB = b[1].statusCode.slice(0, 3); // Extract the status code from the second element

  // Sort '200' status codes first
  if (statusA === '200' && statusB !== '200') {
    return -1; // Place '200' before others
  }
  if (statusA !== '200' && statusB === '200') {
    return 1; // Place others after '200'
  }
  return 0; // Keep original order for items with the same status
};

export const getStatusCodeString = (statusCode: string) => {
  switch (statusCode) {
    case '401':
      return 'Invalid Configuration';
    case '402':
      return 'Invalid Credentials';
    case '413':
      return 'Device Unreachable';
    case '500':
      return 'Internal Server Error';
    default:
      return 'Unknown Error';
  }
};

export const getStatusCodeButtonString = (statusCode: string) => {
  switch (statusCode) {
    case '402':
      return 'Sync Existing Credentials';
    case '413':
    case '401':
    case '500':
    default:
      return 'Try Again';
  }
};
