import React from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FrequentlyAskedQuestions = ({ title, faqs }) => {
  return (
    <>
      <Typography variant="h6" align="center" sx={styles.title}>
        {title}
      </Typography>
      <Box border={1} borderRadius={2} color="divider" width="100%">
        {faqs.map((faq, index) => (
          <Accordion key={index} disableGutters sx={styles.accordionContainer}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{faq.Question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={styles.accordionDetails}>{faq.Ans}</Typography>
            </AccordionDetails>
            <Divider />
          </Accordion>
        ))}
      </Box>
    </>
  );
};

export default FrequentlyAskedQuestions;

const styles = {
  title: {
    marginTop: '32px',
    marginBottom: '16px'
  },
  container: {
    maxWidth: '900px',
    margin: 'auto'
  },
  accordionContainer: {
    width: '100%'
  },
  accordionDetails: {
    marginBottom: 2
  }
};
