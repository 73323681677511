import { LoadingButton } from '@mui/lab';
import { Alert, Box, Checkbox, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { AuthContext } from 'context/AuthContext';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import React, { useContext, useState } from 'react';
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';
import { newPasswordRequiredChallenge } from 'shared/api/Aws/authApi';
import PasswordField from 'shared/components/forms/fields/PasswordField';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import * as yup from 'yup';
import { PRIVACY_URL, TERMS_URL } from '../../constants';
import { checkAndSetupC2Contact } from '../../utils/c2Operations';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/utils/StringUtils';

export const CreatePassword = () => {
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [hasMinChar, setHasMinChar] = useState(false);
  const [hasMatchingPassword, setHasMatchingPassword] = useState(false);
  const [passwordVerified, setPasswordVerified] = useState(false);
  const [terms, setTerms] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const { username, password, session } = useContext(AuthContext);
  const navigate: NavigateFunction = useNavigate();

  // Translation keys for strings
  const { t } = useTranslation();
  const tempPassword = t('TempPassword_Verified');
  const oneLower = t('Field.Shared.Password.OneLowerCaseLetter.Requirement');
  const oneUpper = t('Field.Shared.Password.OneUpperCaseLetter.Requirement');
  const oneNumber = t('Field.Shared.Password.OneNumber.Requirement');
  const oneSpecial = t('Field.Shared.Password.OneSpecialChar.Requirement');
  const atLeastEight = t('Field.Shared.Password.EightCharacters.Requirement');
  const mustMatch = t('Field.Shared.Password.PasswordMatch.Requirement');
  const phoneNumberStr = t('Shared.PhoneNumber');
  const firstNameStr = t('Shared.FirstName');
  const lastNameStr = t('Shared.LastName');
  const emailAddressStr = t('Shared.EmailAddress');
  const passwordStr = t('Shared.Password');
  const confirmItemStr = t('Shared.ConfirmItem');
  const register = t('Button_CloudAccount_Register');
  const byClickingRegister = t('Clicking_RegisterDescription');
  const termsAndConditions = t('Terms_And_Conditions');
  const privacyPolicy = t('PrivacyPolicy');
  const passwordChangeSuccessStr = t('Field.Shared.Password.ChangeSuccess');
  const passwordChangeFailedStr = t('Field.Shared.Password.ChangeFailed');
  const passwordEnterNewStr = t('Field.Shared.Password.EnterNew');
  const passwordConfirmNewStr = t('Field.Shared.Password.ConfirmNew');
  const confirmPasswordStr = StringUtils.format(confirmItemStr, passwordStr);
  const passwordRequiredStr = t('Field.Error.Required', { field: passwordStr });
  const confirmPasswordRequiredStr = t('Field.Error.Required', { field: confirmPasswordStr });
  const phoneNumberRequiredStr = t('Field.Error.Required', { field: phoneNumberStr });
  const phoneNumberInvalidStr = t('Field.Error.Invalid', { field: phoneNumberStr });
  const firstNameRequiredStr = t('Field.Error.Required', { field: firstNameStr });
  const lastNameRequiredStr = t('Field.Error.Required', { field: lastNameStr });
  const emailRequiredStr = t('Field.Error.Required', { field: emailAddressStr });

  const createPWDValidation = yup.object().shape({
    firstName: yup.string().required(firstNameRequiredStr),
    lastName: yup.string().required(lastNameRequiredStr),
    email: yup.string().required(emailRequiredStr),
    phoneNumber: yup
      .string()
      .required(phoneNumberRequiredStr)
      .matches(/^(\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$/, phoneNumberInvalidStr),
    password: yup.string().required(passwordRequiredStr),
    passwordConfirm: yup.string().required(confirmPasswordRequiredStr)
  });

  const initialValues = {
    firstName: '',
    lastName: '',
    email: username,
    phoneNumber: '',
    password: '',
    passwordConfirm: ''
  };

  function handlePasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;

    if (id === 'password') {
      const passwordConfirm = document.getElementById('passwordConfirm') as HTMLInputElement | null;
      if (passwordConfirm !== null) {
        checkPasswordRequirements(value, passwordConfirm.value);
      }
    } else {
      const password = document.getElementById('password') as HTMLInputElement | null;
      if (password !== null) {
        checkPasswordRequirements(password.value, value);
      }
    }
  }

  function checkPasswordRequirements(password: string, passwordConfirm: string) {
    const hasLowerCase = /[a-z]/g.test(password);
    const hasUpperCase = /[A-Z]/g.test(password);
    const hasNumber = /[0-9]/g.test(password);
    const hasSpecialChar = /[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]/g.test(password);
    const hasMinChar = password.length >= 8;
    const hasMatchingPassword = password === passwordConfirm;

    setHasLowerCase(hasLowerCase);
    setHasUpperCase(hasUpperCase);
    setHasNumber(hasNumber);
    setHasSpecialChar(hasSpecialChar);
    setHasMinChar(hasMinChar);
    setHasMatchingPassword(hasMatchingPassword);

    setPasswordVerified(
      hasLowerCase && hasUpperCase && hasNumber && hasSpecialChar && hasMinChar && hasMatchingPassword
    );
  }

  const PasswordRequirement = ({ isValid, children }: { isValid: boolean; children: React.ReactNode }) => {
    return (
      <Grid item xs={6}>
        <Typography sx={isValid ? styles.valid : styles.invalid}>&#x2022; {children}</Typography>
      </Grid>
    );
  };

  const handleTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTerms(event.target.checked);
  };

  const submitNewPassword = async (values: any, { setSubmitting }: FormikHelpers<any>) => {
    const formattedEmail = username.toLowerCase();
    const formattedPhoneNumber = values.phoneNumber.replace(/[^0-9]/g, '');
    try {
      /*C2 processes*/
      await checkAndSetupC2Contact(values);

      const request = {
        userData: {
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: `+1${formattedPhoneNumber}`,
          email: formattedEmail,
          password: password,
          newPassword: values.password,
          countryId: 2,
          languageId: 2,
          session: session
        }
      };
      await newPasswordRequiredChallenge(request);

      setSuccess(passwordChangeSuccessStr);

      setTimeout(() => {
        setSubmitting(false);
        navigate('/auth/login');
      }, 3000);
    } catch (error: unknown) {
      setError(passwordChangeFailedStr);
    }
  };

  return (
    <>
      <SnackbarAlert type="error" time={7000} text={`${error}`} isOpen={!!error} onClose={() => setError(null)} />
      <SnackbarAlert
        type="success"
        time={7000}
        text={`${success}`}
        isOpen={!!success}
        onClose={() => setSuccess(null)}
      />
      <Box sx={{ mb: 2 }}>
        <Alert severity="success">{tempPassword}</Alert>
      </Box>

      <Formik initialValues={initialValues} validationSchema={createPWDValidation} onSubmit={submitNewPassword}>
        {({ errors, touched, isSubmitting, handleChange }) => (
          <Form>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Field
                  name="firstName"
                  as={TextField}
                  sx={styles.inputField}
                  size="small"
                  label={firstNameStr}
                  helperText={touched.firstName && errors.firstName}
                  error={touched.firstName && !!errors.firstName}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="lastName"
                  as={TextField}
                  sx={styles.inputField}
                  size="small"
                  label={lastNameStr}
                  helperText={touched.lastName && errors.lastName}
                  error={touched.lastName && !!errors.lastName}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="email"
                  as={TextField}
                  sx={styles.inputField}
                  size="small"
                  label={emailAddressStr}
                  disabled
                  helperText={touched.email && errors.email}
                  error={touched.email && !!errors.email}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="phoneNumber"
                  as={TextField}
                  sx={styles.inputField}
                  size="small"
                  label={phoneNumberStr}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                  error={touched.phoneNumber && !!errors.phoneNumber}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="password"
                  id="password"
                  as={PasswordField}
                  sx={styles.inputField}
                  size="small"
                  autoComplete="new-password"
                  label={passwordEnterNewStr}
                  helperText={touched.password && errors.password}
                  error={Boolean(touched.password && errors.password)}
                  onChange={(e: any) => {
                    handleChange(e);
                    handlePasswordChange(e);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="passwordConfirm"
                  id="passwordConfirm"
                  as={PasswordField}
                  sx={styles.inputField}
                  size="small"
                  autoComplete="new-password"
                  label={passwordConfirmNewStr}
                  helperText={touched.passwordConfirm && errors.passwordConfirm}
                  error={Boolean(touched.passwordConfirm && errors.passwordConfirm)}
                  onChange={(e: any) => {
                    handleChange(e);
                    handlePasswordChange(e);
                  }}
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 2 }}>
              <Grid container columnSpacing={0}>
                <PasswordRequirement isValid={hasLowerCase}>{oneLower}</PasswordRequirement>
                <PasswordRequirement isValid={hasUpperCase}>{oneUpper}</PasswordRequirement>
                <PasswordRequirement isValid={hasNumber}>{oneNumber}</PasswordRequirement>
                <PasswordRequirement isValid={hasSpecialChar}>{oneSpecial}</PasswordRequirement>
                <PasswordRequirement isValid={hasMinChar}>{atLeastEight}</PasswordRequirement>
                <PasswordRequirement isValid={hasMatchingPassword}>{mustMatch}</PasswordRequirement>
              </Grid>
            </Box>
            <Grid container sx={styles.submitButtonContainer}>
              <Grid item xs={12} sm={5}>
                <Box sx={styles.centerContent}>
                  <LoadingButton
                    type="submit"
                    sx={styles.submitButton}
                    loading={isSubmitting}
                    disabled={isSubmitting || !(passwordVerified && terms)}
                    variant="aiphoneOrange"
                    loadingIndicator={<CircularProgress size="20px" color="white" />}
                  >
                    {register}
                  </LoadingButton>
                </Box>
              </Grid>
              <Grid item>
                <Checkbox checked={terms} sx={styles.termCheckbox} onChange={(e) => handleTerms(e)} />
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body1" sx={styles.registerText}>
                  {byClickingRegister}{' '}
                  <Box component={Link} to={TERMS_URL} sx={styles.link} target="_blank">
                    {termsAndConditions}
                  </Box>
                  {' and '}
                  <Box component={Link} to={PRIVACY_URL} sx={styles.link} target="_blank">
                    {privacyPolicy}
                  </Box>
                  .
                </Typography>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  inputField: {
    width: '100%',
    '& .MuiInputBase-input': {
      backgroundColor: '#ffffff'
    },
    '& .MuiInputLabel-root': {
      color: 'grey'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: '#003366'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0071ce'
      }
    },
    '.MuiFormHelperText-root': {
      color: '#d32f2f'
    }
  },
  invalid: {
    color: '#d32f2f'
  },
  valid: {
    color: '#00BB31'
  },
  submitButton: {
    fontSize: '1.5rem',
    fontWeight: '700',
    borderRadius: '0.25 rem',
    width: '80%'
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center'
  },
  submitButtonContainer: {
    mt: 4,
    mb: 3
  },
  registerText: {
    color: '#003366',
    float: 'right',
    fontFamily: "'Roboto Condensed', sans-serif"
  },
  termCheckbox: {
    top: -8
  },
  link: {
    textDecoration: 'none',
    color: '#0071CE',
    fontWeight: 'bold'
  }
};

export default CreatePassword;
