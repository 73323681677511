import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeploymentDoorSize, PaymentMethod, Product } from 'features/LicenseManagement/components/common/Types';
import { OnlinePaymentView } from './OnlinePaymentView';
import { PrepaidPaymentView } from './PrepaidPaymentView';

interface MethodStepProps {
  selectedPaymentMethod: PaymentMethod;
  setSelectedPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethod>>;
  planType: DeploymentDoorSize;
  setPlanType: React.Dispatch<React.SetStateAction<DeploymentDoorSize>>;
  selectedPlan: string;
  setSelectedPlan: React.Dispatch<React.SetStateAction<string>>;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  licenseCode: string;
  setLicenseCode: React.Dispatch<React.SetStateAction<string>>;
  setSelectedProduct: React.Dispatch<React.SetStateAction<Product>>; // Track selected product in parent
}

interface PaymentMethodSelectionProps {
  selectedPaymentMethod: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const MethodStep: React.FC<MethodStepProps> = ({
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  planType,
  setPlanType,
  selectedPlan,
  setSelectedPlan,
  activeStep,
  setActiveStep,
  licenseCode,
  setLicenseCode,
  setSelectedProduct
}) => {
  const { t } = useTranslation('acNioLicenseManagement');

  const handlePaymentMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPaymentMethod(event.target.value as PaymentMethod);
  };

  const handleProductSelection = (product: any) => {
    setSelectedProduct(product); // Pass selected product to parent
  };

  const PaymentMethodSelection: React.FC<PaymentMethodSelectionProps> = ({ selectedPaymentMethod, onChange }) => (
    <Box className="payment-method-selection">
      <Typography variant="h5" sx={styles.paymentMethodTextContainer}>
        {t('Choose_Payment_Method')}
      </Typography>
      <RadioGroup value={selectedPaymentMethod} onChange={onChange}>
        <FormControlLabel
          value="online"
          control={<Radio />}
          sx={styles.paymentMethodSelectionContainer}
          label={
            <Box sx={styles.paymentMethodSelection}>
              <Box sx={styles.iconContainer}>
                <LanguageIcon style={styles.icon} />
                <Typography>{t('Pay_Online')}</Typography>
              </Box>
              <Typography sx={styles.paymentMethodDescription}>{t('Pay_Online_Description')}</Typography>
            </Box>
          }
        />
        <FormControlLabel
          value="prepaid"
          control={<Radio />}
          sx={styles.paymentMethodSelectionContainer}
          label={
            <Box sx={styles.paymentMethodSelection}>
              <Box sx={styles.iconContainer}>
                <CreditCardIcon style={styles.icon} />
                <Typography>{t('Prepaid')}</Typography>
              </Box>
              <Typography sx={styles.paymentMethodDescription}>{t('Prepaid_Description')}</Typography>
            </Box>
          }
        />
      </RadioGroup>
    </Box>
  );

  const renderPaymentMethodView = () => {
    if (selectedPaymentMethod === PaymentMethod.Online) {
      return (
        <OnlinePaymentView
          planType={planType}
          setPlanType={setPlanType}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          onProductSelect={handleProductSelection} // Handle product selection in OnlinePaymentView
        />
      );
    }

    if (selectedPaymentMethod === PaymentMethod.Prepaid) {
      return (
        <PrepaidPaymentView
          setActiveStep={setActiveStep}
          licenseCode={licenseCode}
          setLicenseCode={setLicenseCode}
          setSelectedProduct={setSelectedProduct}
        />
      );
    }
  };

  return (
    <>
      <Box sx={styles.container}>
        <PaymentMethodSelection selectedPaymentMethod={selectedPaymentMethod} onChange={handlePaymentMethodChange} />
      </Box>
      {renderPaymentMethodView()}
    </>
  );
};

const styles = {
  container: {
    display: 'flex',
    padding: 2,
    marginTop: '20px',
    marginLeft: '25px'
  },
  paymentMethodSelectionContainer: {
    marginLeft: '10px'
  },
  paymentMethodTextContainer: {
    fontWeight: 500
  },
  paymentMethodSelection: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    marginTop: '10px'
  },
  paymentMethodDescription: {
    fontSize: '0.875rem',
    textAlign: 'center'
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: 8,
    color: '#003366'
  }
};

export default MethodStep;
