export default {
  itemContainer: {
    minHeight: '75px',
    marginBottom: '25px',
    display: 'flex',
    alignItems: 'center'
  },
  itemTitle: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  itemDesc: {
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.6)'
  },
  itemDefault: {
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.6)',
    margin: '4px 14px 0px 14px'
  },
  gridContainer: {
    padding: '15px',
    maxHeight: 'calc(100% - 60px)',
    marginBottom: '20px',
    overflow: 'auto'
  } as React.CSSProperties,
  submitBarButton: {
    marginLeft: '20px'
  },
  form: {
    width: '100%',
    height: '100%'
  },
  settingsPageContainer: {
    height: '100%'
  },
  selectField: {
    marginTop: '10px',
    width: '100%', // Adjusts the width
    maxWidth: '300px' // Set a maximum width
  },
  sliderBar: {
    marginTop: '35px'
  },
  fieldContainer: {
    width: '100%',
    margin: '0 auto',
    maxWidth: '300px' // Set a maximum width
  },
  checkboxFieldContainer: {
    width: '100%',
    margin: '0 auto',
    maxWidth: '300px',
    backgroundColor: 'transparent',
    background: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textField: {
    marginTop: '1rem',
    width: '100%',
    maxWidth: '300px' // Set a maximum width
  },
  toggleField: {
    margin: '0 auto'
  },
  flagStyle: {
    width: '20px',
    minWidth: '20px',
    paddingRight: '10px'
  },
  countryName: {
    width: 'calc(100% - 30px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  tosAdornment: {
    marginRight: '0px'
  },
  mainWrapper: {
    width: '100%',
    height: '100%'
  },
  settingsWrapper: {
    marginY: '30px',
    padding: '10px'
  },
  controlPanelWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px'
  }
};
