import React from 'react';
import { FormControlLabel, Radio } from '@mui/material';

interface RadioButtonProps {
  label: string;
  value: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const RadioButton = ({ label, value, checked, onChange, disabled = false }: RadioButtonProps) => {
  return (
    <FormControlLabel
      control={<Radio checked={checked} onChange={onChange} value={value} disabled={disabled} />}
      label={label}
    />
  );
};

export default RadioButton;
