import { resetGatewayState, RootState } from 'store';
import IXGLoginContainer from './Components/IXGLoginContainer';
import Sites from './Sites';
import { Box, Grid } from '@mui/material';
import { useGetSitesQuery, useLazyGetBranchWithPublicIdQuery } from 'services/aiphoneCloud';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { usePermission } from 'context/PermissionContext';
import 'styles/frontshine.css';
import { PermissionsContextType } from 'permissions/utils';
import RemoteManagementWithNoBranchRole from './RemoteManagementWithNoBranchRole';
import { useCanAccessRemoteManagement } from './Hooks/useCanAccessRemoteManagement';
import { clearSiteData, ISiteInfo } from 'store/slices/siteSlice';
import { clearDevices } from 'store/slices/devicesSlice';
import useSharedStyles from 'styles/useSharedStyles';

const RemoteManagement = () => {
  const sharedStyle = useSharedStyles();
  const { data: myOrganizationSites, isFetching } = useGetSitesQuery({});
  const branchData = useSelector((state: RootState) => state.branches);
  const sitePermissionsList = useSelector((state: RootState) => state.users.currentUser?.permissions?.site ?? {});
  const currentBranchPublicId = useSelector((state: RootState) => state.branches.currentBranch?.publicId ?? '');
  const [getBranchData] = useLazyGetBranchWithPublicIdQuery();
  const dispatch = useDispatch();
  const { isAllowedTo } = usePermission();
  const canSiteView = useCanAccessRemoteManagement();
  const [aclToken, setAclToken] = useState<string | null>(localStorage.getItem('acltoken'));

  const canSiteCreate = isAllowedTo('site:create', currentBranchPublicId, PermissionsContextType.BRANCH);
  const canViewMyOrganizationSites = isAllowedTo('site:view', currentBranchPublicId, PermissionsContextType.BRANCH);

  const filteredMySites = useMemo(() => {
    if (typeof myOrganizationSites !== 'object') return {};

    return Object.keys(myOrganizationSites).reduce((acc: ISiteInfo[] | any, siteId) => {
      if (sitePermissionsList?.[siteId] !== undefined) {
        acc[siteId] = myOrganizationSites[siteId];
      }
      return acc;
    }, {});
  }, [myOrganizationSites, sitePermissionsList]);

  useEffect(() => {
    if (branchData.currentBranch === null && Object.keys(branchData.Branches).length > 0) {
      getBranchData(currentBranchPublicId);
    }
    // Reset redux data to ensure we don't show the old site's state information
    dispatch(clearSiteData());
    dispatch(resetGatewayState());
    dispatch(clearDevices());
  }, [branchData.currentBranch, branchData.Branches, getBranchData, currentBranchPublicId]);

  return (
    <Box>
      {!canSiteView && <RemoteManagementWithNoBranchRole />}

      <Grid container sx={[sharedStyle.gridContainer, sharedStyle.common.width.full, sharedStyle.common.padding.md]}>
        {canSiteCreate && !aclToken && (
          <Grid item sx={sharedStyle.common.width.fit}>
            <IXGLoginContainer setAclToken={setAclToken} />
          </Grid>
        )}
        {canSiteView && (
          <Sites
            isFetching={isFetching}
            mySites={filteredMySites}
            myOrganizationSites={canViewMyOrganizationSites ? myOrganizationSites : []}
            canCreateSites={canSiteCreate}
          />
        )}
      </Grid>
    </Box>
  );
};

export default RemoteManagement;
