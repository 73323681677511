import { Card, CardContent, Grid, List, Typography, ListItem } from '@mui/material';
import PersonPinCircleOutlinedIcon from '@mui/icons-material/PersonPinCircleOutlined';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/utils/StringUtils';

interface IRoleDescriptionsProps {
  roleName: string;
}

const RoleDescriptions = ({ roleName }: IRoleDescriptionsProps) => {
  const { t } = useTranslation(); // Initialize the translation hook
  const adminStr = t('Role.Admin.Title');
  const managerStr = t('Role.Manager.Title');
  const memberStr = t('Role.Member.Title');
  const associateStr = t('Role.Associate.Title');
  const aiphoneAdminStr = t('Role.AiphoneAdmin.Title');
  const aiphoneSalesStr = t('Role.AiphoneSales.Title');
  const aiphoneTechStr = t('Role.AiphoneTech.Title');
  const branchInviteUsersAdminStr = t('Role.Permission.Branch.InviteUsersAdmin');
  const branchInviteUsersStr = t('Role.Permission.Branch.InviteUsers');
  const branchPromoteUsersAdminStr = t('Role.Permission.Branch.PromoteUsersAdmin');
  const branchManageRolesStr = t('Role.Permission.Branch.ManageRoles');
  const branchEditUserStr = t('Role.Permission.Branch.EditUser');
  const branchCreateQuikSpecsStr = t('Role.Permission.Branch.CreateQuikSpecs');
  const branchManageSIMBillingStr = t('Role.Permission.Branch.ManageSIMBilling');
  const branchManageSIMBillingSubsStr = t('Role.Permission.Branch.ManageSIMBillingSubs');
  const branchManageSitesStr = t('Role.Permission.Branch.ManageSites');
  const branchViewSitesStr = t('Role.Permission.Branch.ViewSites');
  const branchCreateSitesStr = t('Role.Permission.Branch.CreateSites');
  const branchPerSiteEditStr = t('Role.Permission.Branch.PerSiteEdit');
  const globalInviteAdmins = t('Role.Permission.Global.InviteAdmins');
  const globalCreateCompaniesBranchesStr = t('Role.Permission.Global.CreateCompaniesBranches');
  const globalPromoteUsersStr = t('Role.Permission.Global.PromoteUsers');
  const globalManageSitesStr = t('Role.Permission.Global.ManageSites');
  const globalCreateSitesStr = t('Role.Permission.Global.CreateSites');
  const permissionGrantedToStr = t('Role.Permission.GrantedTo');
  const permissionsGrantedToRoleStr = StringUtils.format(permissionGrantedToStr, roleName);

  const description: { [key: string]: any } = {
    Admin: {
      name: adminStr,
      permissions: [
        branchInviteUsersAdminStr,
        branchPromoteUsersAdminStr,
        branchManageRolesStr,
        branchEditUserStr,
        branchCreateQuikSpecsStr,
        branchManageSIMBillingStr,
        branchManageSitesStr
      ]
    },
    Manager: {
      name: managerStr,
      permissions: [
        branchInviteUsersStr,
        branchManageRolesStr,
        branchCreateQuikSpecsStr,
        branchManageSIMBillingSubsStr,
        branchManageSitesStr
      ]
    },
    Member: {
      name: memberStr,
      permissions: [
        branchCreateQuikSpecsStr,
        branchManageSIMBillingSubsStr,
        branchViewSitesStr,
        branchCreateSitesStr,
        branchPerSiteEditStr
      ]
    },
    Associate: {
      name: associateStr,
      permissions: [branchCreateQuikSpecsStr, branchPerSiteEditStr]
    },
    AiphoneAdmin: {
      name: aiphoneAdminStr,
      permissions: [globalInviteAdmins, globalCreateCompaniesBranchesStr, globalPromoteUsersStr, globalManageSitesStr]
    },
    AiphoneSales: {
      name: aiphoneSalesStr,
      permissions: [globalCreateCompaniesBranchesStr, globalPromoteUsersStr, globalCreateSitesStr]
    },
    AiphoneTech: {
      name: aiphoneTechStr,
      permissions: [globalCreateCompaniesBranchesStr, globalPromoteUsersStr, globalManageSitesStr]
    }
  };

  return (
    <Grid item xs={12}>
      {roleName && (
        <Card sx={styles.permissionContainer}>
          <Typography variant="h6" color="initial">
            {permissionsGrantedToRoleStr}
          </Typography>
          <CardContent>
            <List>
              {description[roleName]?.permissions?.map((desc: string, index: number) => (
                <ListItem key={index}>
                  <PersonPinCircleOutlinedIcon />
                  <Typography variant="body2" color="initial" sx={{ paddingX: 2 }}>
                    {desc}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      )}
    </Grid>
  );
};

const styles = {
  permissionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 1,
    paddingX: 1
  }
};

export default RoleDescriptions;
