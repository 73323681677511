import { Box, CircularProgress } from '@mui/material';
import { Outlet, useParams } from 'react-router-dom';
import { getSite } from 'store/slices/siteSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useSiteDataLoader } from '../Hooks/useSiteDataLoader';
import SiteActivityCommands from './SiteActivityCommands';
import { setSelectedDevice } from 'store/slices/devicesSlice';
import { RemoteManagementProvider } from 'context/RemoteManagementContext';

const SiteLoader = () => {
  const site = useAppSelector(getSite);
  const selectedDevice = useAppSelector((state) => state.devices.SelectedDevice);
  const dispatch = useAppDispatch();
  const params = useParams();
  const siteId = params.id;
  const devicePublicId = params.deviceid;

  if (devicePublicId && !selectedDevice) {
    dispatch(setSelectedDevice(devicePublicId));
  }

  if (!siteId) return null;

  useSiteDataLoader(siteId);

  return (
    <RemoteManagementProvider>
      <Box component={'main'} sx={styles.mainSection}>
        {site.Loading ? (
          <Box sx={styles.loaderContainer}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {site.Error ? (
              <Box>404 Site Not Found</Box>
            ) : (
              <>
                <Outlet />
                <SiteActivityCommands />
              </>
            )}
          </>
        )}
      </Box>
    </RemoteManagementProvider>
  );
};

const styles = {
  container: {
    display: 'flex',
    height: 'calc(100% - 64px)',
    overflow: 'auto'
  },
  mainSection: {
    p: 5,
    width: '100%',
    height: '100%'
  },
  fab: {
    display: 'relative',
    position: 'absolute',
    bottom: 16,
    left: 300
  },
  link: {
    textDecoration: 'none',
    color: 'white'
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    flexGrow: 1
  }
};

export default SiteLoader;
