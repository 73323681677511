import { Card, CardContent } from '@mui/material';
import HeaderSection from 'shared/components/home/registeredUser/HeaderSection';
import DealerFeatures from 'shared/components/home/registeredUser/DealerFeatures';
import { useTranslation } from 'react-i18next';
import FAQSection from 'shared/components/home/registeredUser/FAQSection';
import useSharedStyles from 'styles/useSharedStyles';

const SimBillingWithNoBranchRole = () => {
  const sharedStyle = useSharedStyles();
  const { t } = useTranslation('simbillings');
  const title = t('Sim_Management');
  const descStr = t('SimBillingWithNoBranch.Header.Desc');
  const actionTitle = t('SimBillingWithNoBranch.RequestBranch.Title');
  const actionDescStr = t('SimBillingWithNoBranch.RequestBranch.Desc');
  const supportUrl = 'https://www.aiphone.com/products/ixgs-te';
  const supportUrlDescStr = t('SimBillingWithNoBranch.Header.ExploreFeature');
  const dealerFeaturesTitle = t('SimBillingWithNoBranch.Dealerfeatures.Title');
  const dealerFeaturesList = t('SimBillingWithNoBranch.Dealerfeatures.FeatureList', {
    returnObjects: true
  }) as string[];
  const faqTitle = t('FAQTitle');
  const simBillingFAQs = t('SimBillingWithNoBranch.FAQs', { returnObjects: true }) as {
    Question: string;
    Ans: string;
  }[];
  const videoId = 'wb34rFSDTDQ';

  return (
    <Card sx={{ ...sharedStyle.cardContainer, boxShadow: 'none' }}>
      <CardContent sx={sharedStyle.cardContainer.cardContent}>
        <HeaderSection
          title={title}
          description={descStr}
          actionTitle={actionTitle}
          actionDescription={actionDescStr}
          featureUrl={supportUrl}
          featureUrlDesc={supportUrlDescStr}
        />
        <DealerFeatures
          dealerFeaturesList={dealerFeaturesList}
          dealerFeaturesTitle={dealerFeaturesTitle}
          videoId={videoId}
        />
        <FAQSection title={faqTitle} faqs={simBillingFAQs} />
      </CardContent>
    </Card>
  );
};

export default SimBillingWithNoBranchRole;
