import { MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from 'store';
import { fetchAndSetCurrentBranch } from 'store/slices/branchSlice';
import { getBranchList } from 'store/slices/usersSlice';

const CurrentBranchSelect = () => {
  const [selectedBranch, setSelectedBranch] = useState('');
  const currentBranch = useSelector((state: RootState) => state.branches.currentBranch);
  const location = useLocation();
  const navigate = useNavigate();
  const branches = useSelector(getBranchList);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (currentBranch) {
      setSelectedBranch(currentBranch.publicId);
    }
  }, [currentBranch]);

  const handleChange = (event: any) => {
    const branchPublicId: string = event.target.value;
    setSelectedBranch(branchPublicId);
    dispatch(fetchAndSetCurrentBranch(branchPublicId));

    if (location.pathname.includes('site')) {
      navigate(`/remotemanagement/`);
    }
  };

  return (
    <Select
      labelId="branch-select-label"
      id="branch-select"
      value={selectedBranch}
      onChange={handleChange}
      variant="outlined"
      size="small"
      sx={styles.select}
    >
      {branches?.map((branch) => (
        <MenuItem key={branch.branchId} value={branch.branchId}>
          {branch.branchName}
        </MenuItem>
      )) || (
        <MenuItem>
          <em>None</em>
        </MenuItem>
      )}
    </Select>
  );
};

const styles = {
  select: {
    color: '#FFFFFF',
    backgroundColor: '#FFFFFF10',
    width: '220px',
    '& fieldset': {
      borderWidth: '0px'
    },
    '& .MuiSvgIcon-root': {
      color: '#FFFFFF'
    }
  }
};

export default CurrentBranchSelect;
