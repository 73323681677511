import { useQuery } from '@tanstack/react-query';
import { fetchAiphoneToHartmannBranchMapping } from '../lib/api/LicenseManagementApi';
import { REACT_QUERY_ONE_HOURS_STALE_TIME } from '../components/common/Types';
import { Branch } from 'store/slices/branchSlice';

export const useFetchAiphoneToHartmannBranchMapping = (currentBranch: Branch | null) => {
  const isEnabled = !!currentBranch?.publicId;

  const { data, error, isLoading } = useQuery({
    queryKey: ['aiphoneBranchToHartmannBranchMapping', currentBranch?.publicId],
    queryFn: () => {
      if (!currentBranch?.publicId) {
        return Promise.reject(new Error('No branch public ID available'));
      }
      return fetchAiphoneToHartmannBranchMapping(currentBranch.publicId);
    },
    enabled: isEnabled,
    staleTime: REACT_QUERY_ONE_HOURS_STALE_TIME,
    retry: 3 // Retries are enabled for all errors except 404,
  });

  return { data, error, isLoading };
};
