/**
 * This file contains the EntranceDisplay component which is responsible for rendering the Entrance Display settings page.
 */
import { RootState } from 'store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import ControlPanel from './ControlPanel';
import DisplaySettings from './DisplaySettings';
import { getSelectedDevice, IEntrancePanelSettings } from 'store/slices/devicesSlice';
import { UUID } from 'crypto';

export interface IFormData {
  id: number;
  name: string;
  imageName: string;
  enabled: boolean;
  reduxKey: string | null;
  checkBoxLabel?: string;
  checked?: boolean;
  default: boolean;
}

export interface ICallDirectoryList {
  level: number;
  unitPublicId: UUID;
}

const EntranceDisplay = () => {
  const deviceList = useSelector((state: RootState) => state.devices).DeviceList;
  const selectedDevice = useSelector(getSelectedDevice);

  const [unitAddressBookIds, setUnitAddressBookIds] = useState<string[]>([]);
  const [callDirectoryList, setCallDirectoryList] = useState<ICallDirectoryList[]>([]);
  const [highestCallDirectoryId, setHighestCallDirectoryId] = useState(0);
  const [entrancePanelSettings, setEntrancePanelSettings] = useState<IEntrancePanelSettings | undefined>(
    selectedDevice?.entrancePanelSettings || ({} as IEntrancePanelSettings)
  );
  const [needDisplaySave, setNeedDisplaySave] = useState(false);
  const defaultScreenId = entrancePanelSettings?.displayScreenSelection;
  const displayDoorReleaseKeypad = entrancePanelSettings?.displayTenkeyUnlock ?? true;
  const displayCallByUnitNumber = entrancePanelSettings?.displayKeypad ?? false;
  const displaySearchByName = entrancePanelSettings?.displayNameSearch ?? false;
  const displayDoorReleaseQRCode = entrancePanelSettings?.displayQrCodeScan ?? true;
  const displayQuickSelection = entrancePanelSettings?.displayQuickSelection ?? false;

  // NOTE: id 2 is not used in the formData
  // id: 2 is the CALL BY LIST (currently not implemented)
  const [formData, setFormData] = useState<IFormData[]>([
    {
      id: 0,
      name: 'Door Release Keypad',
      imageName: 'DM7_DoorReleaseKeypad',
      enabled: displayDoorReleaseKeypad !== 2,
      reduxKey: 'displayTenkeyUnlock',
      checkBoxLabel: 'Scramble Pad',
      checked: displayDoorReleaseKeypad === 3,
      default: defaultScreenId === 0
    },
    {
      id: 1,
      name: 'Call By Unit Number',
      imageName: 'DM7_CallByUnitNumberScreen',
      enabled: displayCallByUnitNumber !== 2,
      reduxKey: 'displayKeypad',
      checkBoxLabel: 'Use text ‘Enter Call Code’',
      checked: displayCallByUnitNumber === 3,
      default: defaultScreenId === 1
    },
    {
      id: 3,
      name: 'Search By Name',
      imageName: 'DM7_SearchByNameScreen',
      enabled: displaySearchByName,
      reduxKey: 'displayNameSearch',
      default: defaultScreenId === 3
    },
    {
      id: 4,
      name: 'Call By Directory',
      imageName: 'DM7_CallByDirectoryScreen',
      enabled: displayQuickSelection,
      reduxKey: 'displayQuickSelection',
      default: defaultScreenId === 4
    },
    {
      id: 5,
      name: 'Door Release QR Code',
      imageName: 'DM7_DoorReleaseQRCodeScreen',
      enabled: displayDoorReleaseQRCode,
      reduxKey: 'displayQrCodeScan',
      default: defaultScreenId === 5
    }
  ]);

  const toggleEnable = (id: number) => {
    setNeedDisplaySave(true);
    setFormData((prevFormData) =>
      prevFormData.map((item) => (item.id === id ? { ...item, enabled: !item.enabled, default: false } : item))
    );
  };

  const clickCheckbox = (id: number, forceUnchek = false) => {
    setNeedDisplaySave(true);
    setFormData((prevFormData) =>
      prevFormData.map((item) =>
        item.id === id ? { ...item, checked: forceUnchek === false ? !item.checked : false } : item
      )
    );
  };

  const handleSelectDefaultScreen = (id: number) => {
    setNeedDisplaySave(true);
    // allow only one default screen
    if (formData.find((item) => item.default)?.id !== id) {
      setFormData((prevFormData) =>
        prevFormData.map((item) => (item.id === id ? { ...item, default: !item.default } : { ...item, default: false }))
      );
    }
  };

  const handleUpdateCallDirectoryList = (unitList: ICallDirectoryList[]) => {
    setCallDirectoryList(unitList);
  };

  useEffect(() => {
    if (!selectedDevice) return;

    setEntrancePanelSettings(selectedDevice.entrancePanelSettings);

    const updateAddressBookList = () => {
      const addressBookDeviceList =
        selectedDevice?.systemInfo?.addressBook?.map((device) => device.targetDevicePublicId) || [];

      // Extract unit public IDs from targetUnitPublicId directly
      const addressBookUnitPublicIdList =
        selectedDevice?.systemInfo?.addressBook?.map((device) => device.targetUnitPublicId) || [];

      // Find unitPublicId for each devicePublicId from deviceList
      const unitPublicIdListFromDevices = addressBookDeviceList.map((publicId) => {
        if (!publicId) return;

        return deviceList[publicId]?.unitPublicId;
      });

      const combinedUnitPublicIdList = [...addressBookUnitPublicIdList, ...unitPublicIdListFromDevices].filter(Boolean);

      // Remove duplicates from the combined list
      const uniqueUnitPublicIdList = combinedUnitPublicIdList.reduce((acc, current) => {
        if (current && !acc.includes(current)) {
          acc.push(current);
        }
        return acc;
      }, [] as string[]);
      setUnitAddressBookIds(uniqueUnitPublicIdList || []);
    };

    const updateCallDirectoryList = () => {
      const callDirectoryList = selectedDevice?.entrancePanelSettings?.quickSelectionGroupList || [];
      if (callDirectoryList.length > 0) {
        const unitList = callDirectoryList.map((callDirectory) => {
          if (!callDirectory) return;
          if (!callDirectory.unitPublicId) return;

          return {
            level: Number(callDirectory.groupId),
            unitPublicId: callDirectory.unitPublicId
          };
        });

        // sort the unitList by level to keep the same order as the UI
        const sortedUnitList = unitList
          .filter((item) => item !== undefined) // Filter out undefined values
          .sort((a, b) => (a?.level || 0) - (b?.level || 0));
        // Getting the highest number of id in unitList, 0 is for the root directory and should never be used
        const highestUnitId = sortedUnitList[sortedUnitList.length - 1]?.level || 1;
        setHighestCallDirectoryId(highestUnitId);
        setCallDirectoryList(sortedUnitList);
      }
    };
    updateCallDirectoryList();
    updateAddressBookList();
  }, []);

  return (
    <Box>
      <Box sx={styles.controlPanelWrapper}>
        <ControlPanel needDisplaySave={needDisplaySave} setNeedDisplaySave={setNeedDisplaySave} formData={formData} />
      </Box>
      <Box sx={styles.devicesAndSettingsWrapper}>
        <DisplaySettings
          needDisplaySave={needDisplaySave}
          highestCallDirectoryId={highestCallDirectoryId}
          callDirectoryList={callDirectoryList}
          unitAddressBookIds={unitAddressBookIds}
          formData={formData}
          toggleEnable={toggleEnable}
          clickCheckbox={clickCheckbox}
          handleSelectDefaultScreen={handleSelectDefaultScreen}
          handleUpdateCallDirectoryList={handleUpdateCallDirectoryList}
        />
      </Box>
    </Box>
  );
};

export const EntranceDisplayLabel = () => {
  return (
    <>
      <span>Entrance Display</span>
    </>
  );
};

const styles = {
  controlPanelWrapper: {
    display: 'flex',
    marginY: '20px'
  },
  devicesAndSettingsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginY: '50px',
    minHeight: '200px',
    overflow: 'auto',
    padding: '10px'
  }
};

export default EntranceDisplay;
