import React, { useState } from 'react';
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

import { useTranslation } from 'react-i18next';

// Import styled components
import {
  DeviceSettingsContainer,
  DeviceSettingsTitle,
  AddDeviceButtonContainer,
  StyledTableRow,
  StyledIconButton
} from '../Shared/Common.styled';

import { MAX_WIDTH_UNITS, getTextWidthUnits, ipRegex, extensionRegex } from './DeviceSettingValidation';

import QrScanner from 'react-qr-barcode-scanner';
import { VALID_MAC_ADDRESS_REGEX } from 'shared/constants/regex';

// Types
interface StationRow {
  id: number;
  stationName: string;
  ipAddress: string;
  extensionNumber: string;
  macAddress: string;
}
type RowErrors = Partial<Record<keyof StationRow, string>>;

const DeviceSettingsNext: React.FC = () => {
  const { t } = useTranslation('nextIX');

  const [rows, setRows] = useState<StationRow[]>([]);
  const [errors, setErrors] = useState<Record<number, RowErrors>>({});

  // QR scanner state
  const [showScanner, setShowScanner] = useState(false);
  const [scanningIndex, setScanningIndex] = useState<number | null>(null);
  const [qrError, setQrError] = useState('');

  const reindexRows = (rowsData: StationRow[]): StationRow[] => {
    return rowsData.map((row, index) => ({
      ...row,
      id: index + 1
    }));
  };

  const handleAddDevice = () => {
    const newRow: StationRow = {
      id: 0,
      stationName: '',
      ipAddress: '',
      extensionNumber: '',
      macAddress: ''
    };
    setRows((prev) => reindexRows([...prev, newRow]));
  };

  const handleDeleteRow = (rowId: number) => {
    setRows((prev) => reindexRows(prev.filter((r) => r.id !== rowId)));
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowIndex: number,
    fieldName: keyof StationRow
  ) => {
    let value = event.target.value;

    if (fieldName === 'extensionNumber') {
      value = value.replace(/\D/g, '').slice(0, 4);
    }

    setRows((prev) => {
      const updated = [...prev];
      updated[rowIndex] = {
        ...updated[rowIndex],
        [fieldName]: value
      };
      return updated;
    });

    // Validate the field
    validateField(value, fieldName, rowIndex);
  };

  const validateField = (value: string, fieldName: keyof StationRow, rowIndex: number) => {
    let errorMsg = '';

    // Station Name length
    if (fieldName === 'stationName' && value) {
      const widthUnits = getTextWidthUnits(value);
      if (widthUnits > MAX_WIDTH_UNITS) {
        errorMsg = t('DeviceSetting.stationNameError');
      }
    }

    // IP
    if (fieldName === 'ipAddress' && value) {
      if (!ipRegex.test(value)) {
        errorMsg = t('DeviceSetting.invalidIp');
      }
    }

    // MAC
    if (fieldName === 'macAddress' && value) {
      if (!VALID_MAC_ADDRESS_REGEX.test(value)) {
        errorMsg = t('DeviceSetting.invalidMac');
      }
    }

    // Extension
    if (fieldName === 'extensionNumber' && value) {
      if (value.length > 4 || !extensionRegex.test(value)) {
        errorMsg = t('DeviceSetting.invalidExtension');
      }
    }

    setErrors((prev) => ({
      ...prev,
      [rowIndex]: {
        ...prev[rowIndex],
        [fieldName]: errorMsg
      }
    }));
  };

  // QR Scanner logic
  const handleOpenScanner = (rowIndex: number) => {
    setScanningIndex(rowIndex);
    setShowScanner(true);
    setQrError('');
  };

  const handleCloseScanner = () => {
    setShowScanner(false);
    setScanningIndex(null);
    setQrError('');
  };

  const handleQrUpdate = (err: any, result: any) => {
    if (err) {
      // Usually "no QR found" or camera issue
      return;
    }
    if (!result) return;

    const scannedData: string = String(result.text || '').trim();

    // Validate as MAC
    if (!VALID_MAC_ADDRESS_REGEX.test(scannedData)) {
      setQrError(t('DeviceSetting.invalidMac'));
      return;
    }

    // If valid MAC, update the row
    if (scanningIndex !== null) {
      setRows((prev) => {
        const updated = [...prev];
        updated[scanningIndex].macAddress = scannedData;
        return updated;
      });
      validateField(scannedData, 'macAddress', scanningIndex);
    }

    // Close scanner after success
    handleCloseScanner();
  };

  return (
    <DeviceSettingsContainer>
      <DeviceSettingsTitle variant="h4">{t('DeviceSetting.deviceSettings')}</DeviceSettingsTitle>

      <AddDeviceButtonContainer>
        <Button variant="contained" onClick={handleAddDevice}>
          {t('DeviceSetting.addDevice')}
        </Button>
      </AddDeviceButtonContainer>

      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>{t('DeviceSetting.id')}</TableCell>
            <TableCell>{t('DeviceSetting.stationName')}</TableCell>
            <TableCell>{t('DeviceSetting.ipAddress')}</TableCell>
            <TableCell>{t('DeviceSetting.extensionNumber')}</TableCell>
            <TableCell>{t('DeviceSetting.macAddress')}</TableCell>
            <TableCell>{t('DeviceSetting.scanQr')}</TableCell>
            <TableCell>{t('DeviceSetting.actions')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => {
            const rowErrs = errors[rowIndex] || {};

            return (
              <StyledTableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                {/* Station Name */}
                <TableCell>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={row.stationName}
                    onChange={(e) => handleChange(e, rowIndex, 'stationName')}
                    error={Boolean(rowErrs.stationName)}
                    fullWidth
                  />
                  {rowErrs.stationName && <Typography style={{ color: 'red' }}>{rowErrs.stationName}</Typography>}
                </TableCell>

                {/* IP Address */}
                <TableCell>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={row.ipAddress}
                    onChange={(e) => handleChange(e, rowIndex, 'ipAddress')}
                    error={Boolean(rowErrs.ipAddress)}
                    fullWidth
                  />
                  {rowErrs.ipAddress && <Typography style={{ color: 'red' }}>{rowErrs.ipAddress}</Typography>}
                </TableCell>

                {/* Extension Number */}
                <TableCell>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={row.extensionNumber}
                    onChange={(e) => handleChange(e, rowIndex, 'extensionNumber')}
                    error={Boolean(rowErrs.extensionNumber)}
                    fullWidth
                  />
                  {rowErrs.extensionNumber && (
                    <Typography style={{ color: 'red' }}>{rowErrs.extensionNumber}</Typography>
                  )}
                </TableCell>

                {/* MAC Address */}
                <TableCell>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={row.macAddress}
                    onChange={(e) => handleChange(e, rowIndex, 'macAddress')}
                    error={Boolean(rowErrs.macAddress)}
                    fullWidth
                  />
                  {rowErrs.macAddress && <Typography style={{ color: 'red' }}>{rowErrs.macAddress}</Typography>}
                </TableCell>

                {/* Scan QR */}
                <TableCell>
                  <StyledIconButton onClick={() => handleOpenScanner(rowIndex)} aria-label="scan-qr">
                    <QrCodeScannerIcon fontSize="small" />
                  </StyledIconButton>
                </TableCell>

                {/* Delete */}
                <TableCell>
                  <StyledIconButton aria-label="delete" onClick={() => handleDeleteRow(row.id)}>
                    <DeleteIcon fontSize="small" />
                  </StyledIconButton>
                </TableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>

      {/* Dialog for QR Scanner */}
      <Dialog open={showScanner} onClose={handleCloseScanner} maxWidth="xs" fullWidth>
        <DialogTitle>{t('DeviceSetting.scanQr')}</DialogTitle>
        <DialogContent>
          {qrError && <Typography style={{ color: 'red' }}>{qrError}</Typography>}
          <QrScanner onUpdate={handleQrUpdate} width="100%" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseScanner}>{t('DeviceSetting.cancel')}</Button>
        </DialogActions>
      </Dialog>
    </DeviceSettingsContainer>
  );
};

export default DeviceSettingsNext;
