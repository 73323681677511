import { Box, Divider, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const FeatureSummaryItem = ({ hasAssociateRole = false }) => {
  const { t } = useTranslation();

  /* Translation strings */
  const numOfSitesStr = t('DashboardStats.SummaryData.NumOfSites');
  const numOfSimSubsStr = t('DashboardStats.SummaryData.NumOfSimSubsc');
  const numOfLicenseDeploymentsStr = t('DashboardStats.SummaryData.NumOfLicenseDeployments');
  const numOfQuickSpecsStr = t('DashboardStats.SummaryData.NumOfQuickSpecs');

  const summaryData = [
    { quantity: hasAssociateRole ? 'N/A' : '25', label: numOfSitesStr },
    { quantity: hasAssociateRole ? 'N/A' : '25', label: numOfSimSubsStr },
    { quantity: hasAssociateRole ? 'N/A' : '0', label: numOfLicenseDeploymentsStr },
    { quantity: '0', label: numOfQuickSpecsStr }
  ];

  return (
    <Box sx={styles.summaryContainer}>
      <Grid container alignItems="center">
        {summaryData.map((option, index) => (
          <Grid item key={index} display="flex" alignItems="center" gap={4}>
            <Box sx={styles.summaryItemBox}>
              <Typography variant="h4"> {option.quantity} </Typography>
              <Typography variant="body2"> {option.label} </Typography>
            </Box>

            {index !== summaryData.length - 1 && <Divider orientation="vertical" sx={styles.verticalDivider} />}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const styles = {
  summaryContainer: {
    border: 1,
    borderColor: 'divider',
    borderRadius: 2
  },
  summaryItemBox: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 12px',
    width: '240px',
    borderRadius: 2
  },
  verticalDivider: {
    height: '64px'
  }
};
