import React from 'react';
import { Box, Typography, Card, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PhoneForwardedOutlinedIcon from '@mui/icons-material/PhoneForwardedOutlined';
import CallGroup from './CallGroup';
import ContactInput from './ContactInput';
import CallPriority from './CallPriority';
import containerStyle from 'styles/advancedSettingContainerStyle';

interface IOptionType {
  value: string;
  label: string;
}

interface ICallPriorityOptionType {
  value: number;
  label: string;
}
interface IDestinationProps {
  isUpdating: boolean;
  needSave: boolean;
  callDropDownList: {
    contactGroupList: IOptionType[];
    callGroupList: IOptionType[];
    callPriorityList: ICallPriorityOptionType[];
  };
  setNeedSave: (value: boolean) => void;
  formData: {
    contactInputValue: string;
    callGroupValue: string;
    callPriorityValue: number;
  };
  onChange: (fieldName: string, value: number | string) => void;
  onSave: () => void;
  onReset: () => void;
}

const ControlPanel = ({
  needSave,
  setNeedSave,
  formData,
  onChange,
  callDropDownList,
  onSave,
  onReset
}: IDestinationProps) => {
  const isSubmitting = false;
  const buttonReset = 'Reset';
  const buttonSaveChanges = 'Save Changes';
  const handleUpdateCallDestination = () => {
    onSave();
  };

  const handleReset = () => {
    onReset();
  };

  return (
    <>
      <Box sx={styles.controlPanelWrapper}>
        <Box sx={containerStyle.controlPanelWrapper}>
          <LoadingButton variant="outlined" type="reset" disabled={!needSave} onClick={handleReset}>
            {buttonReset}
          </LoadingButton>
          <LoadingButton
            variant="outlined"
            type="submit"
            disabled={!needSave}
            onClick={handleUpdateCallDestination}
            startIcon={isSubmitting ? <CircularProgress size={14} /> : null}
          >
            {buttonSaveChanges}
          </LoadingButton>
        </Box>
        <Card sx={styles.leftPanelWrapper}>
          <Box sx={styles.callGroupWrapper}>
            <ContactInput
              setNeedSave={setNeedSave}
              fieldName="contactInputValue"
              value={formData.contactInputValue}
              contactGroupList={callDropDownList.contactGroupList}
              onChange={onChange}
            />
          </Box>
          <Typography>will call</Typography>
          <PhoneForwardedOutlinedIcon />
          <Box sx={styles.callGroupWrapper}>
            <CallGroup
              setNeedSave={setNeedSave}
              fieldName="callGroupValue"
              value={formData.callGroupValue}
              callGroupList={callDropDownList.callGroupList}
              onChange={onChange}
            />
          </Box>
          <Typography>with priority</Typography>
          <Box sx={styles.callGroupWrapper}>
            <CallPriority
              setNeedSave={setNeedSave}
              fieldName="callPriorityValue"
              value={formData.callPriorityValue}
              callPriorityList={callDropDownList.callPriorityList}
              onChange={onChange}
            />
          </Box>
        </Card>
      </Box>
    </>
  );
};

const styles = {
  controlPanelWrapper: {
    alignItems: 'center',
    width: '100%',
    height: '100px'
  },
  leftPanelWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '80%',
    overflow: 'auto',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#e9e9e9', // Background color on hover
      color: 'black' // Text color on hover
    }
  },
  callGroupWrapper: {
    width: '25%',
    minWidth: '150px',
    height: '60px',
    margin: '10px'
  },
  saveChangesWrapper: {
    display: 'flex',
    flexDirection: 'flex-end'
  }
};

export default ControlPanel;
