import { useEffect, useState } from 'react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Paper
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import { LoadingButton } from '@mui/lab';
import Spinner from 'features/SimBilling/Components/UiParts/Spinner';
import { useFetchSubscriptionPlans, usePropertyLogic, useUpdateSiteDetails } from 'features/SimBilling/Hooks';
import { GwAndSim, SimBillingSite } from 'features/SimBilling/Types/SimBillingTypes';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import { getSimCarrierDetails, validateICCID } from 'shared/api/Aws/awsApi';
import { FormValues, GwDetailsPayloadType, SimCarrierDetailsType } from './GWDevices.types';
import { useTranslation } from 'react-i18next';

/**Initial values*/
const validationSchema = yup.object().shape({
  iccid: yup
    .string()
    .matches(/^[0-9]{19,20}$/, 'ICCID must be 19 or 20 digits long and numeric')
    .required('ICCID is required')
});
const initialValues = {
  iccid: ''
};
const ActionsT = {
  iccid: {
    add: 'Add ICCID'
  }
};

/**
 * This function shows GW devices and key details about them.
 * It also allows for updating certain information, for now just the ICCID.
 * Before updating the ICCID, we must verify its validity through an additional API for ICCID validation.
 * Updating the ICCID is only possible if the current ICCID is null.
 * */
const GWDevices = () => {
  const { t } = useTranslation('simbillings');
  const cancelStr = t('Shared.Cancel');

  /**Hooks & Variables*/
  const { siteDetails, user, siteError } = usePropertyLogic();
  const { updateSiteDetailsCallback } = useUpdateSiteDetails();
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | null>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [gwAndSimDetails, setGwAndSimDetails] = useState<GwAndSim[] | null>(null);
  const [initProcess, setInitProcess] = useState(false);
  const [runningApi, setRunningApi] = useState(false);
  const isLoading = (!user || !siteDetails || !gwAndSimDetails) && !siteError;
  const tmpValue = '-';

  const countryCodeForSubscriptionPlans =
    siteDetails?.Payer === 'Dealer' ? user?.CompanyCountryCode : siteDetails?.CountryCode;

  const { subscriptionPlans } = useFetchSubscriptionPlans(countryCodeForSubscriptionPlans ?? null);

  const calculateTotalDataUsage = (site: SimBillingSite): number => {
    return site.GwAndSim.reduce((total, item) => total + item.CurrentDataUsage ?? 0, 0);
  };

  useEffect(() => {
    /**
     * The condition prevents us from additional rendering.
     * Run the nested function only once.
     * */
    if (!initProcess && siteDetails?.GwAndSim && !gwAndSimDetails) {
      (async () => {
        setInitProcess(true);
        await initialization(siteDetails);
      })();
    }
  }, [siteDetails]);

  /**
   * Make additional API request to AT&T by ICCID to get device details(Msisdn,SimStatus)
   * */
  const fetchSimCarrierDetails = async (siteDetails: SimBillingSite) => {
    const promises = siteDetails.GwAndSim.map(async (item: GwAndSim) => {
      /**
       * If the ICCID is null, it means that we can't get SIM carrier details.
       * Will return the item without any changes.
       * */
      if (!item.Iccid) {
        return item;
      }

      const simCarrierDetails: SimCarrierDetailsType | null = await getSimDetails(item.Iccid);
      /**
       * If simCarrierDetails is null, it indicates that an error occurred during the API request.
       * In this case, we will return the item without any changes.
       * */
      if (!simCarrierDetails) return item;

      return {
        ...item,
        Msisdn: simCarrierDetails.msisdn,
        SimStatus: simCarrierDetails.status
      };
    });

    const updatedData = await Promise.all(promises);
    setGwAndSimDetails(updatedData);
  };

  const initialization = async (siteDetails: SimBillingSite) => {
    await fetchSimCarrierDetails(siteDetails);
    setInitProcess(false);
  };

  /** Handler for the "Update ICCID" button action */
  const handleIccidAction = (iccid: string, deviceId: string) => {
    setSelectedDeviceId(deviceId);
    setDialogIsOpen(true);
    return;
  };

  /**Validate and build payload to update device details in DB */
  const handleDeviceUpdate = async (
    { iccid }: FormValues,
    setFieldError: FormikHelpers<FormValues>['setFieldError']
  ) => {
    const deviceDetails = siteDetails?.GwAndSim.find((device) => device.PublicId === selectedDeviceId);
    if (deviceDetails) {
      /**
       * Currently, update only the ICCID,
       */
      const payload = {
        ...deviceDetails,
        AclSitePublicId: siteDetails.PublicId,
        Iccid: iccid
      };

      await ICCIDValidation(iccid, setFieldError);
      await saveUpdatedDeviceDetails(payload);

      setDialogIsOpen(false);
      setShowAlert(true);
      location.reload();
    }
  };

  /**Save updated device details to the DB and redux store.*/
  const saveUpdatedDeviceDetails = async (payload: GwDetailsPayloadType) => {
    try {
      await updateSiteDetailsCallback(payload, siteDetails.AclSiteId);
    } catch (error) {
      setErrorMessage(t('Error_Message_Save_Updates'));
    }
  };

  /**Make API request to validate the ICCID*/
  const ICCIDValidation = async (iccid: string, setFieldError: FormikHelpers<FormValues>['setFieldError']) => {
    try {
      const response = await validateICCID(iccid);
      return response?.data.data;
    } catch (error) {
      setFieldError('iccid', t('ICCID_Error_Verify'));
      throw error;
    }
  };

  /**Make API request to SIM Activated*/
  const activateSIM = async (iccid: string, deviceId: string) => {
    setRunningApi(true);

    try {
      const params = {
        iccid,
        action: 'activateSim'
      };
      await getSimCarrierDetails(params);
      const deviceDetails = siteDetails?.GwAndSim.find((device) => device.PublicId === deviceId);
      if (deviceDetails) {
        const payload_updateDB = {
          ...deviceDetails,
          AclSitePublicId: siteDetails.PublicId,
          SimStatus: 'ACTIVATED'
        };
        await saveUpdatedDeviceDetails(payload_updateDB);
        setRunningApi(false);
        location.reload();
      }
    } catch (error) {
      setRunningApi(false);
      setErrorMessage(t('Error_Message_Activate_SIM'));
    }
  };

  /**Make API requests to the AT&T platform to get SIM Carrier details.*/
  const getSimDetails = async (iccid: string): Promise<SimCarrierDetailsType | null> => {
    try {
      const params = {
        iccid,
        action: 'getDetails'
      };

      const response = await getSimCarrierDetails(params);
      return response?.data || null;
    } catch (error) {
      setErrorMessage(t('Error_Message_Upload_Details'));
      return null;
    }
  };

  const isActivateButtonEnabled = (device: any): boolean => {
    const currentSubscription = subscriptionPlans?.find((item) => item.priceID === siteDetails.StripePriceId);
    const totalDataAvailableForSite = Number(currentSubscription?.dataAmount ?? 0) + Number(siteDetails.AdditionalData);

    return (
      device.iccid !== '-' &&
      device.simStatus !== 'ACTIVATED' &&
      totalDataAvailableForSite > calculateTotalDataUsage(siteDetails) &&
      siteDetails.SubscriptionId !== null &&
      siteDetails.SubscriptionStatus === 'active'
    );
  };

  const showSpinner = (isLoading || runningApi) && !errorMessage && !showAlert;

  return (
    <>
      {showSpinner && <Spinner />}
      <Box>
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
          <SnackbarAlert
            type="error"
            time={10000}
            text={`${errorMessage}`}
            isOpen={!!errorMessage}
            onClose={() => setErrorMessage(null)}
          />
          <SnackbarAlert
            type="success"
            time={3000}
            text={t('Success_Message_Updated_Device')}
            isOpen={showAlert}
            onClose={() => setShowAlert(false)}
          />
          <Dialog open={dialogIsOpen} onClose={() => setDialogIsOpen(false)}>
            <DialogTitle>{t('Update_ICCID')}</DialogTitle>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, { setFieldError }) => handleDeviceUpdate(values, setFieldError)}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form>
                  <DialogContent>
                    <DialogContentText>
                      <Field
                        name="iccid"
                        as={TextField}
                        sx={styles.inputField}
                        label={t('ICCID_Label')}
                        helperText={touched.iccid && errors.iccid ? errors.iccid : ''}
                        error={touched.iccid && Boolean(errors.iccid)}
                      />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setDialogIsOpen(false)}>{cancelStr}</Button>
                    <LoadingButton variant="contained" type="submit" color="primary" loading={isSubmitting}>
                      {t('Save_Button')}
                    </LoadingButton>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </Dialog>
          <Typography variant="sectionHeader" gutterBottom>
            {t('GW_Devices')}
          </Typography>
          <DataGrid
            sx={styles.root}
            columns={[
              { field: 'deviceName', headerName: t('Device_Name'), width: 175 },
              { field: 'macAddress', headerName: t('MAC_Address'), width: 200 },
              { field: 'iccid', headerName: t('SIM_Card_ID'), width: 250 },
              { field: 'simStatus', headerName: t('SIM_Status'), width: 150 },
              { field: 'msisdn', headerName: t('Phone_Number'), width: 150 },
              {
                field: 'actions',
                headerName: '',
                width: 250,
                renderCell: (params) => {
                  const { iccid } = ActionsT;
                  const iccidBtnType = iccid.add;

                  return (
                    <Grid sx={styles.btnsGroup}>
                      {params.row.iccid === '-' && (
                        <Button
                          sx={styles.btn}
                          variant="contained"
                          color="primary"
                          onClick={() => handleIccidAction(params.row.iccid, params.row.id)}
                        >
                          {iccidBtnType}
                        </Button>
                      )}
                      {isActivateButtonEnabled(params.row) && (
                        <Button
                          sx={styles.btn}
                          variant="contained"
                          color="primary"
                          onClick={() => activateSIM(params.row.iccid, params.row.id)}
                        >
                          {t('Activate_Button')}
                        </Button>
                      )}
                    </Grid>
                  );
                }
              }
            ]}
            rows={
              gwAndSimDetails
                ? gwAndSimDetails.map((device: GwAndSim) => ({
                    id: device.PublicId,
                    deviceName: device.GwClientName,
                    macAddress: device.GwMacAddress || tmpValue,
                    simStatus: device.SimStatus || tmpValue,
                    iccid: device.Iccid || tmpValue,
                    msisdn: device.Msisdn || tmpValue
                  }))
                : []
            }
            loading={isLoading}
          />
        </Paper>
      </Box>
    </>
  );
};

export default GWDevices;

const styles = {
  inputField: {
    marginBottom: 1,
    width: '400px',
    '& .MuiInputLabel-root': {
      color: 'grey',
      '&.Mui-focused': {
        color: 'black'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: '#003366'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0071ce'
      }
    },
    '&.MuiFormHelperText-root': {
      color: '#d32f2f'
    }
  },
  btnsGroup: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around'
  },
  btn: {
    '&:focus': { outline: 'none' }
  },
  root: {
    minHeight: '500px',
    '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
      outline: 'none'
    }
  }
};
