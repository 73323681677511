/**
 * Component that combines the Network and Device Info pages into one DataGrid.
 */
import React, { useEffect } from 'react';
import { DeviceList, IDevice, setDeviceNetworkSettings } from 'store/slices/devicesSlice';

import {
  DataGrid,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridInitialState,
  GridPreProcessEditCellProps,
  GridRenderEditCellParams,
  GridRowsProp,
  GridValidRowModel,
  GridValueFormatterParams,
  MuiEvent,
  useGridApiRef
} from '@mui/x-data-grid';
import { formatAsIPAddress, getDeviceModelNumberFromModelType } from 'shared/utils/helperFunctions';
import { useTranslation } from 'react-i18next';
import { Box, Button, Container } from '@mui/material';
import Spinner from 'features/SimBilling/Components/UiParts/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import TextInputDataGridCell, {
  NonEditableTextInputDataGridCell
} from 'shared/components/cells/input/TextInputDataGridCell';
import { fetchGatewayCommand, IDeviceInfoPayload, IGatewayInfoPayload } from 'shared/rmGateway/gwCommandProcessor';
import { gwCommand } from 'shared/rmGateway/gwCommand';
import {
  useBatchUpdateDevicesMutation,
  useGetDeviceListWithSitePublicIdQuery,
  useHandleGatewayCommandMutation,
  useUpdateDeviceMutation
} from 'services/aiphoneCloud';
import { Netmask } from 'netmask';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import { RegexIpV4, RegexSubnetMask } from 'features/RemoteManagement/types/Types';
import { LoadingProgressStepCounter } from 'features/RemoteManagement/NewSiteWizard/steps/components/LoadingProgressStepCounter';
import { SAVE_DATA_GRID_STATE_TO_LOCAL_STORAGE } from 'shared/constants/config';
import { VALID_STATION_NAME_REGEX } from 'shared/constants/regex';
import { DEFAULT_IP_ADDRESS, DEFAULT_PRIMARY_DNS } from 'shared/constants/ipaddresses';
import StringUtils from 'shared/utils/StringUtils';
import localEnumList from 'shared/utils/localEnumList';

// Define a max length for fields in the Text Input Cells
const MAX_LENGTH = 24;

interface GatewayRequiredProps {
  handlePreviousStep: () => void;
}

interface SaveDevicePayload {
  publicId: string;
  basicInfo: {
    macAddress: string;
    stationNumber: string;
    stationName: string;
    firmwareVersion: string;
  };
  networkSettings: {
    ipV4DefaultGateway?: string;
    ipV4Address: string;
    subnetMask: string;
  };
}

const GatewayRequiredView = (props: GatewayRequiredProps) => {
  const { t } = useTranslation();
  const gatewayStr = t('Gateway');
  const gatewayIsRequiredStr = t('Field.Error.Required', { field: gatewayStr });

  return (
    <Container
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box>
        <p>{gatewayIsRequiredStr}</p>
      </Box>
      <Button onClick={props.handlePreviousStep} variant={'contained'} color={'primary'}>
        {t('Button_Back')}
      </Button>
    </Container>
  );
};

interface DeviceInfoPayload {
  deviceMacAddress: string;
  deviceIpV4Address: string;
  deviceSubnetMask: string;
  deviceIpV4DefaultGateway?: string;
  deviceName: string;
}

type DeviceRow = {
  mac_addr: string;
  model_number: string;
  device_image: string;
  station_name: string;
  station_number: string;
  ip_address: string;
  subnet_mask: string;
  default_gateway: string;
  dns: string;
  id: string;
  is_valid: boolean;
  firmware_version: string;
  device_type: number;
  row_is_editable: boolean;
};

export interface DeviceConfigurationGridProps {
  handleNextStep: () => void;
  handlePreviousStep: () => void;
}

/**
 * Validates the given network configuration consisting of an IP address, default gateway, and subnet mask.
 *
 * This function checks if the default gateway is valid within the range specified by the given IP address
 * and subnet mask. It constructs a network address using the IP address and subnet mask, then verifies
 * whether the provided default gateway can be accommodated within this network range. If the default
 * gateway is empty, the function will still succeed, as empty gateways are considered acceptable.
 *
 * @param {string} ipAddress - The IP address to be validated against the subnet mask.
 * @param {string} defaultGateway - The default gateway to be checked for validity within the subnet mask range.
 * @param {string} subnetMask - The subnet mask to be used with the IP address to define a network range.
 * @returns {boolean} Returns true if the default gateway is valid within the network range defined by the IP address and subnet mask, or if the default gateway is empty; otherwise, returns false.
 */
const validateNetmask = (ipAddress: string, defaultGateway: string, subnetMask: string): boolean => {
  // Since the rows require the "Actions" column to update the value, it is acceptable for use to call this on the row update, and we don't need it on mount.

  try {
    // Immediately fail and do not construct a new Netmask object.
    if (!testIfValidRegexSubnetMask(subnetMask)) {
      return false;
    }
    const address = `${ipAddress}/${subnetMask}`;
    const newNetmask = new Netmask(address);

    // We can have empty values for the default gateway, which is default, so we must account for that.
    return defaultGateway.length === 0 || newNetmask.contains(defaultGateway);
  } catch (_) {
    // This means there is probably something wrong with crating the Netmask.
    return false;
  }
};

/**
 * Tests if a given station name is valid based on a predefined regex pattern.
 *
 * The regex pattern ensures that:
 * - The string begins and ends without extra characters.
 * - Only the following characters are allowed:
 *   Alphanumeric (A-Za-z0-9), the special characters (+ - _ ? . / ( ) % $ @ !),
 *   and spaces.
 * - The length of the string is between 1 and 24 characters.
 *
 * @param {string} stationName - The station name to be validated.
 * @returns {boolean} - Returns true if the station name is valid, otherwise false.
 */
const testIfValidStationName = (stationName: string): boolean => {
  // /^[A-Za-z0-9+\-_?./()%$@! ]{1,24}$/: This regex ensures that:
  // ^ and $: The string starts and ends respectively.
  // [A-Za-z0-9+\-_?./()%$@! ]: These are the valid characters. This includes:
  // Alphanumeric characters: A-Za-z0-9
  // Special characters listed: + - _ ? . / ( ) % $ @ !
  // Space:
  // {1,24}: The length of the string should be between 1 and 24 characters, inclusive.
  //
  // See: https://github.com/AiphoneCorporation/aiphone-cloud-app/pull/1205?notification_referrer_id=NT_kwDOCp9uK7UxMjY3MDU5MzkyMzoxNzgyMjA1ODc&notifications_query=is%3Aunread#issuecomment-2387253787
  return VALID_STATION_NAME_REGEX.test(stationName);
};

/**
 * Validates if the given IP address is a valid IPv4 address.
 * This function checks the following criteria:
 * 1. The IP address does not start or end with zero.
 * 2. The IP address is not equal to a predefined default IP address.
 * 3. The IP address must match a provided regex pattern for IPv4 addresses.
 *
 * @param {string} ipAddress - The IP address to be validated.
 * @returns {boolean} - True if the IP address is valid, otherwise false.
 */
const testIfValidRegexIPV4 = (ipAddress: string): boolean => {
  if (ipAddress === DEFAULT_IP_ADDRESS) {
    return false;
  }

  // The Regex is valid, however there is an error with how the browser parses it, so I manually have to check if the first and last octets are 0.
  const octets = ipAddress.split('.');
  if (octets[0] === '0' || octets[3] === '0') {
    return false;
  }
  return RegexIpV4.test(ipAddress);
};

/**
 * Function to test if a given subnet mask is valid based on a regular expression.
 *
 * @param {string} subnetMask - The subnet mask to be validated.
 * @returns {boolean} - Returns true if the subnet mask is valid, otherwise false.
 */
const testIfValidRegexSubnetMask = (subnetMask: string): boolean => {
  if (subnetMask === DEFAULT_IP_ADDRESS) {
    return false;
  }
  // There is an error with how the browser parses the string, so I manually have to check to see if 127 is in the first octet
  const octets = subnetMask.split('.');
  if (octets[0] === '127') {
    return false;
  }

  return RegexSubnetMask.test(subnetMask);
};

/**
 * Validates if a given gateway address is either an IPv4 address or a DNS gateway.
 *
 * @param {string} gateway - The gateway address to be tested.
 * @return {boolean} Returns `false` if the gateway is the default IP address. Otherwise, returns `true` if the gateway matches the regular expression for network settings IP address, and `false` otherwise.
 */
const testIfValidRegexIPV4OrDNSGateway = (gateway: string): boolean => {
  if (gateway === DEFAULT_IP_ADDRESS) {
    return false;
  }
  return RegexIpV4.test(gateway);
};

const DeviceConfigurationGrid = (props: DeviceConfigurationGridProps) => {
  const site = useSelector((state: RootState) => state.site);
  const awsPropertyId = site?.siteInfo?.awsPropertyId;
  const gateway = useSelector((state: RootState) => state.devices.DeviceList[site.siteInfo.registeredGatewayPublicId]);
  const gatewayLoading = useSelector((state: RootState) => state.gateway.loading);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const devices = useSelector((state: RootState) => state.devices).DeviceList;
  const [rows, setRows] = React.useState<GridRowsProp>([]);
  const [handleGatewayCommand, { isError: gwCommandError, isLoading: gwLoading }] = useHandleGatewayCommandMutation();
  const dataGridRef = React.useRef<HTMLDivElement>(null);
  const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();
  const [batchUpdateDevices] = useBatchUpdateDevicesMutation();
  const [error, setError] = React.useState<string | null>(null);
  const [updateDevice] = useUpdateDeviceMutation();
  const [canSubmit, setCanSubmit] = React.useState<boolean>(false);
  const [registrationStatusMessage, setRegistrationStatusMessage] = React.useState<string | undefined>(undefined);
  const [countTotalSteps, setCountTotalSteps] = React.useState<number>(0);
  const [currentStep, setCurrentStep] = React.useState<number>(0);
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [initialState, setInitialState] = React.useState<GridInitialState>({});
  const { refetch } = useGetDeviceListWithSitePublicIdQuery({
    sitePublicId: site.siteInfo.publicId,
    qty: -1,
    page: 0
  });

  const { t } = useTranslation();
  const deviceStr = t('Station.Station', { count: 1 });
  const subnetMaskStr = t('Station.NetworkSettings.SubnetMask');
  const ipv4AddressStr = t('Station.NetworkSettings.IpV4Address');
  const subnetMaskErrorInvalid = t('Field.Error.Invalid', { field: subnetMaskStr });
  const ipv4AddressInvalidStr = t('Field.Error.Invalid', { field: ipv4AddressStr });
  const associatingDeviceActionStr = t('API.Action.AssociatingDevice');
  const errorAssociatingDevicesStr = t('API.Error.Generic', { action: associatingDeviceActionStr });

  // Fetch devices after they have been added to the unit
  // Logic updated to handle multi-tenant selection in the wizard
  useEffect(() => {
    refetch();
  }, [refetch]);

  /**
   * Use this to iterate the devices and set the initial state of the grid
   * @param devices
   */
  const formatDevices = (devices: DeviceList) => {
    const gateway = site.siteInfo.registeredGatewayPublicId;
    const gatewayDevice = devices[gateway];

    const getStartingIPAddress = (device: IDevice | undefined): number => {
      if (device?.networkSettings?.ipV4Address) {
        const lastOctet = device.networkSettings.ipV4Address.split('.').slice(-1)[0];
        return parseInt(lastOctet, 10);
      }
      return 10; // Default to 10 if the IP address is not available
    };

    const getFirstThreeOctets = (device: IDevice | undefined): string => {
      if (device?.networkSettings?.ipV4Address) {
        return device.networkSettings.ipV4Address.split('.').slice(0, 3).join('.');
      }
      return '192.168.1'; // Default to '192.168.1' if the IP address is not available
    };

    const startingIPAddress = getStartingIPAddress(gatewayDevice);
    const firstThree = getFirstThreeOctets(gatewayDevice);

    if (Object.keys(devices).length === 0) {
      return [] as GridRowsProp;
    }

    // Store sets to check for duplicates on initial mount
    const deviceIPAddresses = new Set<string>();
    const deviceNames = new Set<string>();
    const deviceStationNumbers = new Set<string>();

    // TODO: Simplify
    /**
     * @function innerRowValidation
     *
     * @description Initial mount checking for row to determine if the is_valid should be valid or not
     *
     * @param stationName
     * @param stationNumber
     * @param ipAddress
     * @param subnetMask
     * @param defaultGateway
     * @param dns
     */
    const innerRowValidation = (
      stationName: string,
      stationNumber: string,
      defaultGateway: string,
      dns: string,
      ipAddress?: string,
      subnetMask?: string
    ): boolean => {
      if (!ipAddress || !subnetMask) {
        return false;
      }

      if (
        stationName === null ||
        stationName.length === 0 ||
        stationNumber === null ||
        stationNumber.length === 0 ||
        ipAddress.length === 0 ||
        subnetMask.length === 0 ||
        defaultGateway === null ||
        dns === null ||
        dns.length === 0
      ) {
        return false;
      }

      // Invalid because of a duplicate value
      if (deviceIPAddresses.has(ipAddress) || deviceNames.has(stationName) || deviceStationNumbers.has(stationNumber)) {
        return false;
      }

      // Add current row to local sets
      deviceIPAddresses.add(ipAddress);
      deviceNames.add(stationName);
      deviceStationNumbers.add(stationNumber);

      if (validateStationName(stationName) || validateStationNumber(stationNumber)) {
        return false;
      }

      if (isNaN(Number(stationNumber))) {
        return false;
      }

      if (!RegexIpV4.test(ipAddress)) {
        return false;
      }

      if (!RegexSubnetMask.test(subnetMask)) {
        return false;
      }

      if (defaultGateway.length > 0 && !RegexIpV4.test(defaultGateway)) {
        return false;
      }

      if (!RegexIpV4.test(dns)) {
        return false;
      }

      return validateNetmask(ipAddress, defaultGateway, subnetMask);
    };

    return Object.keys(devices).map((deviceId, index) => {
      const device = devices[deviceId];

      const ipAddress =
        device?.networkSettings?.ipV4Address && device?.networkSettings?.ipV4Address !== DEFAULT_IP_ADDRESS
          ? device?.networkSettings?.ipV4Address
          : `${firstThree}.${startingIPAddress + index + 1}`;
      const subnetMask = device?.networkSettings?.subnetMask;
      const macAddress: string = device?.basicInfo?.macAddress ?? '';
      const modelNumber =
        getDeviceModelNumberFromModelType(device?.basicInfo?.deviceModel, device?.basicInfo?.deviceType) ?? '';
      const deviceImage = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/${getDeviceModelNumberFromModelType(
        device?.basicInfo?.deviceModel,
        device?.basicInfo?.deviceType
      )}.png`;
      const stationName = device?.basicInfo?.stationName ?? '';
      const stationNumber = device?.basicInfo?.stationNumber ?? '';
      // Note: Someone needs to explain the logic to me for this Default Gateway. I have merely implemented what I am told
      //  I know it has to do with checking if this is equal to some backend value or something
      const defaultGateway =
        device?.networkSettings?.ipV4DefaultGateway &&
        device?.networkSettings?.ipV4DefaultGateway !== DEFAULT_IP_ADDRESS &&
        device?.basicInfo?.deviceType === localEnumList.deviceModel['59'].deviceType
          ? device?.networkSettings?.ipV4DefaultGateway
          : '';

      const dns = gatewayDevice?.networkSettings?.ipV4PrimaryDns ?? DEFAULT_PRIMARY_DNS;
      const id = device.publicId;
      const firmwareVersion = device?.basicInfo?.firmwareVersion ?? '';
      const deviceType = device?.basicInfo?.deviceType ?? 0;
      const rowIsEditable = true;
      const isValid = innerRowValidation(stationName, stationNumber, defaultGateway, dns, ipAddress, subnetMask);

      return {
        mac_addr: macAddress,
        model_number: modelNumber,
        device_image: deviceImage,
        station_name: stationName,
        station_number: stationNumber,
        ip_address: ipAddress,
        subnet_mask: subnetMask,
        // This should be empty for all devices except for IXGW-TGW devices
        default_gateway: defaultGateway,
        dns: dns,
        // These are hidden columns that will not be rendered for the user. They are needed internally, though.
        id,
        is_valid: isValid,
        firmware_version: firmwareVersion,
        device_type: deviceType,
        row_is_editable: rowIsEditable
      };
    }) as GridRowsProp<DeviceRow>;
  };

  /**
   * Custom function to preprocess the default gateway values for a grid cell.
   *
   * This function checks the value of the default gateway in the cell and validates it.
   * If the value does not match a valid IPv4 or DNS format, it sets an error message.
   *
   * @function
   * @name preProcessDefaultGateway
   * @param {GridPreProcessEditCellProps} params - The properties of the cell being edited.
   * @returns {GridPreProcessEditCellProps} - The updated properties with potential error status.
   */
  const preProcessDefaultGateway = React.useCallback(
    (params: GridPreProcessEditCellProps) => {
      const { props, id } = params;
      const value = props.value as string; // Our new updated value

      // Must call this when there is an error, as the row isn't getting updated.
      const updateDefaultGateway = () => {
        apiRef.current?.updateRows([{ id, default_gateway: value }]);
      };

      // No error if empty string
      if (value === '') {
        return { ...props, error: undefined };
      }

      if (!testIfValidRegexIPV4OrDNSGateway(value)) {
        updateDefaultGateway();
        apiRef.current?.updateRows([{ id, is_valid: false }]);
        setError(t('Invalid_Default_Gateway'));
        setCanSubmit(false);
        return { ...props, error: t('Invalid_Default_Gateway') };
      } else {
        return { ...props, error: undefined };
      }
    },
    [apiRef, t]
  );

  /**
   * Callback function to pre-process and validate the IP address input in a grid cell.
   *
   *
   * @param {GridPreProcessEditCellProps} params - The parameters passed to the function which
   *        include props and the id of the cell being edited.
   * @returns {object} An object containing the processed properties, which include either
   *          a validated value or an error message if validation fails.
   */
  const preProcessIPAddress = React.useCallback(
    (params: GridPreProcessEditCellProps) => {
      const { props, id } = params;
      const value = props.value as string;

      const updateIPAddress = () => {
        apiRef.current?.updateRows([{ id, ip_address: value }]);
      };

      // There cannot be duplicate IP addresses and these must be valid according to regex
      if (!testIfValidRegexIPV4(value)) {
        // Replacing with the exact error message as the DeviceNetworkInfoTabContent
        updateIPAddress();
        apiRef.current?.updateRows([{ id, is_valid: false }]);
        setError(StringUtils.format(t('Field_Error_Invalid'), t('AdvancedSettings_IpV4Address_Title')));
        setCanSubmit(false);
        return {
          ...props,
          error: ipv4AddressInvalidStr
        };
      } else {
        // Check if the IP address is unique
        const rows = apiRef.current?.getRowModels();
        let isUnique = true;

        for (const value of rows.values()) {
          if (value.ip_address === props.value && value.id !== id) {
            isUnique = false;
            break;
          }
        }

        if (!isUnique) {
          updateIPAddress();
          return { ...props, error: t('IP_Address_Must_be_Unique') };
        }
        return { ...props, value, error: undefined };
      }
    },
    [t, apiRef]
  );

  /**
   * Preprocesses the subnet mask for a given grid cell, validating and setting an error message if necessary.

   * @param {GridPreProcessEditCellProps} params - The properties of the grid cell to be preprocessed.
   * @returns {Object} The updated cell properties, including an error message if the subnet mask is invalid.
   */
  const preProcessSubnetMask = React.useCallback(
    (params: GridPreProcessEditCellProps) => {
      const { props, id } = params;
      const value = props.value as string; // Our new updated value
      const updateSubnetMask = () => {
        apiRef.current?.updateRows([{ id, subnet_mask: value }]);
      };

      if (!testIfValidRegexSubnetMask(value)) {
        // Replacing with the exact error message as the DeviceNetworkInfoTabContent
        updateSubnetMask();
        apiRef.current?.updateRows([{ id, is_valid: false }]);
        setError(subnetMaskErrorInvalid);
        setCanSubmit(false);
        return { ...props, error: subnetMaskErrorInvalid };
      } else {
        return { ...props, error: undefined };
      }
    },
    [t, apiRef]
  );

  /**
   * Callback to preprocess DNS values in a data grid cell before final validation.
   *
   * @param {GridPreProcessEditCellProps} params - The parameters containing the cell properties to be validated.
   * @returns {Object} The updated cell properties with or without error.
   */
  const preProcessDNS = React.useCallback(
    (params: GridPreProcessEditCellProps) => {
      const { props, id } = params;
      const value = props.value as string; // Our new updated value
      const updateDNS = () => {
        apiRef.current?.updateRows([{ id, dns: value }]);
      };

      if (!testIfValidRegexIPV4OrDNSGateway(params.props.value as string)) {
        updateDNS();
        apiRef.current?.updateRows([{ id, is_valid: false }]);
        setError(t('Invalid_DNS'));
        setCanSubmit(false);
        return { ...params.props, error: t('Invalid_DNS') };
      } else {
        return { ...params.props, error: undefined };
      }
    },
    [t, apiRef]
  );

  /**
   * A callback function to preprocess the station number during cell editing and validate the input.
   *
   * @param {GridPreProcessEditCellProps} params - Parameters including the props being edited and their surrounding context.
   * @returns {Object} The updated properties with potential errors based on validation checks.
   */
  const preProcessStationNumber = React.useCallback(
    (params: GridPreProcessEditCellProps) => {
      const { props, id } = params;

      let value = props.value as string;
      let error = undefined;
      if (value.length < 3) {
        error = t('Minimum_3_Digits');
      } else if (value.length > MAX_LENGTH) {
        value = value.substring(0, MAX_LENGTH);
        error = t('Maximum_24_characters');
      }

      // Check if the string can be a number
      if (isNaN(Number(value))) {
        return { ...props, value, error: t('Station_Number_must_be_a_number') };
      }

      const rows = apiRef.current?.getRowModels();

      let isUnique = true;

      // Check for duplicates
      for (const value of rows.values()) {
        if (value.station_number === props.value && value.id !== id) {
          isUnique = false;
          break;
        }
      }

      if (!error && !isUnique) {
        error = t('Must_be_unique');
        setCanSubmit(false);
      }

      return { ...props, value, error };
    },
    [t, apiRef]
  );

  /**
   * A function for preprocessing the station name during cell editing in a grid.
   * Uses React useCallback hook to memoize the callback function, optimizing performance.
   *
   * @param {GridPreProcessEditCellProps} params - The parameters for preprocessing the cell.
   * @returns {object} The processed properties, including any validation errors.
   */
  const preProcessStationName = React.useCallback(
    (params: GridPreProcessEditCellProps) => {
      const { props, id } = params;
      let value = props.value as string;
      let error;
      if (value.length > MAX_LENGTH) {
        value = value.substring(0, MAX_LENGTH);
        error = t('Maximum_24_characters');
      } else if (value.length === 0) {
        error = t('Station_Name_required');
      }

      // Check that the station name is valid
      if (!testIfValidStationName(value)) {
        // Test the regex pattern for valid station name, and set error if found
        error = t('Invalid_Station_Name');
      }

      let isUnique = true;

      // Check for duplicates
      for (const value of rows.values()) {
        if (value.station_name === props.value && value.id !== id) {
          isUnique = false;
          break;
        }
      }

      if (!error && !isUnique) {
        error = t('Must_be_unique');
        setCanSubmit(false);
      }

      // Removing station name duplicate check
      return { ...props, value, error };
    },
    [t, apiRef]
  );

  /**
   * Handles click events on grid cells.
   *
   * This callback function is used to manage the click events on cells within a data grid. It is designed to ignore clicks on portal elements and to prevent certain cells (like image cells) from becoming editable. Additionally, it ensures that any cells with errors do not change mode. For other cells, this function sets the clicked cell to edit mode and reverts other cells in the same or different rows back to view mode.
   *
   * @param {GridCellParams} params - The parameters of the clicked cell.
   * @param {MuiEvent<React.MouseEvent>} event - The mouse click event.
   */
  const handleCellClick = React.useCallback(
    (params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
      // Ignore click-out events that occur outside the bounds of this element.
      if ((event.target as any).nodeType === 1 && !event.currentTarget.contains(event.target as Element)) {
        return;
      }

      // Prevent the image cell from becoming editable
      // Previously this would render text with the image name/url as text input if we clicked on this
      if (params.field === 'device_image') {
        return;
      }
    },
    [apiRef]
  );

  /**
   * Handles changes to the cell modes model in a grid.
   *
   * @function
   * @param {GridCellModesModel} newModel - The updated cell modes model to be applied.
   * @description This function updates the state of the cell modes model by setting it
   * to a new model. It is typically used to manage the modes (e.g., edit, view) of the cells
   * in a data grid.
   */
  const handleCellModesModelChange = (newModel: GridCellModesModel) => {
    setCellModesModel(newModel);
  };

  /**
   * @function isCellOpenForRow
   * @description Check if any cell is open for a row
   */
  const isCellOpenForRow = (rowId: string) => {
    const state = apiRef.current.state;
    const row = state.editRows[rowId];
    return row ? Object.values(row).some((cell) => cell.isEditing) : false;
  };

  const isValidDevice = React.useCallback(
    (device: GridValidRowModel): boolean => {
      const rows = apiRef.current?.getRowModels();

      const isUnique = (field: string, value: string) => {
        return !Object.values(rows).some((row) => row[field] === value && row.id !== device.id);
      };

      let defaultGatewayIsValid = true;
      if (device.default_gateway.length > 0) {
        defaultGatewayIsValid = RegexIpV4.test(device.default_gateway);
      }

      return (
        // Question: Make these the same as initial mount checks?
        testIfValidRegexIPV4(device.ip_address) &&
        testIfValidRegexSubnetMask(device.subnet_mask) &&
        defaultGatewayIsValid &&
        testIfValidRegexIPV4OrDNSGateway(device.dns) &&
        // Check if the station number is actually a number
        !isNaN(Number(device.station_number)) &&
        // Check if the station number is at least 3 digits and less than 25 unique
        device.station_number.length >= 3 &&
        device.station_number.length <= MAX_LENGTH &&
        isUnique('station_number', device.station_number) &&
        // Check if the station name is between 1 and 24 characters and unique
        device.station_name.length > 0 &&
        device.station_name.length <= MAX_LENGTH &&
        // Check if the IP address is unique
        isUnique('ip_address', device.ip_address) &&
        // Verify netmask parsing
        validateNetmask(device.ip_address, device.default_gateway, device.subnet_mask)
      );
    },
    [apiRef]
  );

  // Associate with Static IP configuration - associate one device at a time
  /**
   * A callback function to handle static association of a device with the system.
   *
   * This function performs the necessary operations to associate a device
   * by communicating with the gateway and updating the device's network settings.
   * It sets the loading state during the operation, handles success and error states,
   * and updates the relevant device information in the application's state.
   *
   * @param {Object} device - The device to be associated, which should contain the necessary network and identification properties.
   */
  const staticAssociation = React.useCallback(
    async (device: DeviceRow) => {
      setIsLoading(true);

      // Pass in the device row data
      const deviceMacAddress = device.mac_addr;

      const isGatewayFirstSync = gateway?.lastSyncedOn === null;

      // Get the device from the deviceList slice
      const dataDevice = devices[deviceMacAddress];

      const gatewayInfo = {
        awsPropertyId,
        gwMacAddress: gateway?.basicInfo?.macAddress,
        gwId: isGatewayFirstSync ? 'admin' : gateway?.basicInfo.adminId,
        gwPassword: isGatewayFirstSync ? 'admin' : gateway?.basicInfo.adminPass,
        gwIpAddress: gateway?.networkSettings?.ipV4Address
      } as IGatewayInfoPayload;

      const deviceInfo = {
        deviceMacAddress,
        deviceIpV4Address: device.ip_address,
        deviceSubnetMask: device.subnet_mask,
        deviceName: device.station_name
      } as DeviceInfoPayload;

      // I don't believe the default gateway will be empty when associating, but I am implementing this check here.
      if (device.default_gateway.length > 0) {
        deviceInfo.deviceIpV4DefaultGateway = device.default_gateway;
      }

      try {
        const ioTPayload = fetchGatewayCommand(
          'sendCommand',
          gwCommand.ASSOCIATE,
          gatewayInfo,
          deviceInfo as IDeviceInfoPayload,
          'static'
        );
        if (!ioTPayload) {
          setError(errorAssociatingDevicesStr);
          setIsLoading(false);
        }
        await handleGatewayCommand(ioTPayload);
        const fetchPayload = fetchGatewayCommand(
          'fetchResult',
          gwCommand.ASSOCIATE,
          gatewayInfo,
          deviceInfo as IDeviceInfoPayload,
          'static'
        );

        // Make sure to await this unwrapped call
        const fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();
        if (
          gwCommandError ||
          fetchResponse.error ||
          // Check for the statusCode for device mac address
          !fetchResponse.data.payload[deviceMacAddress].statusCode.includes('200')
        ) {
          setError(errorAssociatingDevicesStr);
          setIsLoading(false);
        }

        updateDevice({
          device: {
            publicId: device.id,
            sitePublicId: dataDevice.sitePublicId,
            networkSettings: {
              ...dataDevice.networkSettings
            }
          }
        });

        const updatedDevice = {
          publicId: device.id,
          sitePublicId: dataDevice.sitePublicId,
          networkSettings: {
            ...dataDevice.networkSettings,
            ipV4Address: device.ip_address,
            subnetMask: device.subnet_mask,
            ipV4DefaultGateway: device.default_gateway,
            dns: device.dns
          }
        };

        dispatch(setDeviceNetworkSettings(updatedDevice));
        setIsLoading(false);
      } catch (error) {
        setError(errorAssociatingDevicesStr);
      }
    },
    [t, setError, setIsLoading, updateDevice]
  );

  // Send requests for registration.
  /**
   * Handles the process of saving and continuing to the next step.
   *
   * This function performs several tasks in sequence:
   * 1. Retrieves all rows from the `apiRef` data table.
   * 2. Validates each row using the `validateRow` function.
   * 3. Categorizes rows into two groups: devices to associate and devices to save
   * 4. Associates devices in the `devicesToAssociate` array with the cloud using the `staticAssociation` function.
   * 5. Saves information about the devices in the `devicesToSave` array using the `batchUpdateDevices` function.
   * 6. Advances to the next step using the `props.handleNextStep` function.
   *
   * If any row fails validation, an error is thrown and caught in the catch block. The error is then handled
   * and a friendly error message is displayed.
   *
   * The state variables `setIsSaving`, `setCountTotalSteps`, `setCurrentStep`, `setRegistrationStatusMessage`,
   * and `setError` are used to manage the status of the saving process and error handling.
   *
   * The dependencies for this callback function include the state setters `setIsSaving`, `setCountTotalSteps`,
   * `setCurrentStep`, and the localization function `t`.
   */
  const handleSaveAndContinue = React.useCallback(async () => {
    // First, get all rows
    const rows = apiRef.current?.getRowModels();

    // If all rows are valid, we continue on in this function
    // If any row is invalid, we throw an error
    for (const value of rows.values()) {
      const errorMessage = validateRow(value);
      if (errorMessage) {
        throw new Error(errorMessage);
      }
    }

    try {
      setIsSaving(true);

      // We need to make two arrays: one for all the devices to associate, and one for all the devices to save
      const devicesToAssociate: DeviceRow[] = [];
      const devicesToSave: SaveDevicePayload[] = [];

      // Loop through each row, and if the association status is 'ASSOCIATE', add it to the devicesToAssociate array
      // If the association status is 'SAVE', add it to the devicesToSave array. When adding to the devicesToSave array,
      // we need to make sure to create an IDevicesToAdd object, which is a subset of the IDevice object
      for (const value of rows.values()) {
        // Create a savePayload
        const savePayload = {
          publicId: value.id,
          basicInfo: {
            macAddress: value.mac_addr,
            stationNumber: value.station_number,
            stationName: value.station_name,
            firmwareVersion: value.firmware_version
          },
          networkSettings: {
            ipV4Address: value.ip_address,
            subnetMask: value.subnet_mask
          }
        } as SaveDevicePayload;

        // If we have a default gateway value, then add it to the save payload.
        if (value.default_gateway.length > 0) {
          savePayload.networkSettings.ipV4DefaultGateway = value.default_gateway;
        }

        // Add all devices to the association step payload for batch association
        devicesToAssociate.push(value as DeviceRow);

        // Add the SaveDevicePayload to the devicesToSave array
        devicesToSave.push(savePayload);
      }

      // Because the devicesToSave happens in a single batch call, we will count it as one step. This means at most,
      //   we will have devicesToAssociate.length + 1 steps if there are devices to save.
      setCountTotalSteps(devicesToAssociate.length + (devicesToSave.length > 0 ? 1 : 0));

      let localStepCounter = 1;

      // Associate devices
      // Go through each device in the devicesToAssociate array and associate it with the cloud
      // using the async function staticAssociation
      for (const device of devicesToAssociate) {
        setCurrentStep(localStepCounter);
        await staticAssociation(device);
        setRegistrationStatusMessage(
          `${t('Associating_Device')} ${device.station_name} ${device.mac_addr.toString()}...`
        );
        localStepCounter++;
      }

      // Save devices. This is done in one single batch call.
      const params = {
        sitePublicId: site.siteInfo.publicId,
        devices: devicesToSave
      };

      setCurrentStep(localStepCounter);
      setRegistrationStatusMessage(t('Now_Saving_Devices'));
      await batchUpdateDevices(params).unwrap();

      setCountTotalSteps(0);
      setCurrentStep(0);
      setRegistrationStatusMessage(undefined);
      setIsSaving(false);

      props.handleNextStep();
    } catch (error: any) {
      // Handle the error
      const stringError = error.toString();
      let friendlyErrorMessage = stringError;
      if (stringError.includes('device_basic_info_station_number_unique_per_site')) {
        friendlyErrorMessage = t('Duplicate_Station_Number_Detected');
      } else if (stringError.includes('device_basic_info_station_name_unique')) {
        friendlyErrorMessage = t('Duplicate_Station_Name_Detected');
      }
      setError(friendlyErrorMessage);
      setCountTotalSteps(0);
      setCurrentStep(0);
      setRegistrationStatusMessage(undefined);
      setIsSaving(false);
    } finally {
      setIsLoading(false);
      setCountTotalSteps(0);
      setCurrentStep(0);
    }
  }, [setIsSaving, setCountTotalSteps, setCurrentStep, t]);

  /**
   * Validates the given station name based on its length.
   *
   * @param {string} stationName - The name of the station to be validated.
   * @returns {boolean} - Returns `false` if the station name is valid, otherwise true.
   */
  const validateStationName = (stationName: string): boolean => {
    // If over 24 characters long or empty, this returns false
    return stationName.length > MAX_LENGTH || stationName.length === 0;
  };

  /**
   * Validates a given station number.
   *
   * This function checks if the provided station number is valid based on the following criteria:
   * - The station number must be between 3 and 24 characters in length.
   * - The station number must be a valid integer.
   *
   * @param {string} stationNumber - The station number to validate.
   * @return {boolean} - Returns `false` if the station number is valid, otherwise returns `true`.
   */
  const validateStationNumber = (stationNumber: string): boolean => {
    // If not 3-24 characters, or a number, this returns false
    return stationNumber.length < 3 || stationNumber.length > MAX_LENGTH || !Number.isInteger(parseInt(stationNumber));
  };

  /**
   * Determines if a specified field value in a given row is unique across a collection of rows.
   *
   * @param {Array} rows - The array of rows to check against.
   * @param {Object} row - The row containing the field value to be checked.
   * @param {string} field - The field name whose value is to be checked for uniqueness.
   * @return {boolean} True if the field value is unique within the collection of rows, otherwise false.
   */
  const isUnique = (rows: any, row: any, field: string): boolean => {
    for (const value of rows.values()) {
      if (value[field] === row[field] && value.id !== row.id) {
        return false;
      }
    }
    return true;
  };

  /**
   * @function validateRow
   * @description Returns a string if there is an error, otherwise returns null
   * @param {GridValidRowModel} row The row to validate
   * @return {string | null} Returns a string if there is an error, otherwise returns null
   */
  /**
   * Callback function to validate a row in the grid.
   *
   * This function performs several validation checks on the provided row:
   * - Ensures that no cells within the row are still in edit mode.
   * - Validates the station name to meet specific requirements.
   * - Ensures the station number meets minimum length requirements and is a number.
   * - Checks the uniqueness of the station number, IP address, and station name within the grid.
   * - Validates the format of IP addresses, subnet mask, default gateway, and DNS.
   *
   * @param {GridValidRowModel} row - The row to be validated.
   * @returns {string | null} A string that represents the validation error message, or null if the row passes validation.
   */
  const validateRow = React.useCallback(
    (row: GridValidRowModel): string | null => {
      // Tracking the editing/focus state of the cell
      // We shall not allow the user to save the data if the cell is still in edit mode
      if (isCellOpenForRow(row.id)) {
        return t('There_are_unsaved_changes');
      }

      if (validateStationName(row.station_name)) {
        return t('Station_Name_requirements');
      }

      if (validateStationNumber(row.station_number)) {
        return t('Station_Number_min_length');
      }

      if (isNaN(Number(row.station_number))) {
        return t('Station_Number_must_be_a_number');
      }

      const rows = apiRef.current?.getRowModels();

      if (!isUnique(rows, row, 'station_number')) {
        return t('Station_Number_must_be_unique');
      }

      if (!isUnique(rows, row, 'ip_address')) {
        return t('IP_Address_Must_be_Unique');
      }

      if (!isUnique(rows, row, 'station_name')) {
        return t('Station_Name_must_be_unique');
      }

      if (!RegexIpV4.test(row.ip_address)) {
        return t('Invalid_IP_Address');
      }

      if (!RegexSubnetMask.test(row.subnet_mask)) {
        return t('Invalid_Subnet_Mask');
      }

      if (row.default_gateway.length > 0) {
        if (!RegexIpV4.test(row.default_gateway)) {
          return t('Invalid_Default_Gateway');
        }
      }

      if (!RegexIpV4.test(row.dns)) {
        return t('Invalid_DNS');
      }

      if (!validateNetmask(row.ip_address, row.default_gateway, row.subnet_mask)) {
        return t('Invalid_Netmask');
      }

      // By default, return null if there are no errors
      return null;
    },
    [t]
  );

  // Initial Cell state validators
  /**
   * Validates the station number for the initial mount check.
   *
   * This callback function checks the given station number against multiple criteria:
   * - Must be a minimum of 3 digits long.
   * - Must be less than the maximum allowed length.
   * - Must be a numeric value.
   * - Must be unique among all other station numbers.
   *
   * @param {string | undefined} stationNumber The station number to validate.
   * @param {string} id The unique identifier for the current station row
   * @returns {string | null} Returns a translated error message if validation fails, or null if the station number is valid.
   */
  const _initialMountCheckStationNumber = React.useCallback(
    (stationNumber: string | undefined, id: string) => {
      // Require Station Number
      if (!stationNumber) {
        return t('Minimum_3_Digits');
      }

      // Validate the string by checking if it is 3 digits or more,
      // less than 24 characters, is a number, and is unique from the other station numbers
      if (stationNumber.length < 3) {
        return t('Minimum_3_Digits');
      } else if (stationNumber.length > MAX_LENGTH) {
        return t('Maximum_24_characters');
      } else if (isNaN(Number(stationNumber))) {
        return t('Station_Number_must_be_a_number');
      }

      // Check for Station Number Duplicates
      const rows = apiRef.current?.getRowModels();
      let isUnique = true;
      for (const value of rows.values()) {
        if (value.station_number === stationNumber && value.id !== id) {
          isUnique = false;
          break;
        }
      }
      if (!isUnique) {
        return t('Must_be_unique');
      } else {
        return null;
      }
    },
    [t, apiRef]
  );

  /**
   * Validates the initial mount check for the station name.
   *
   * This function performs several validation checks on the provided station name:
   * - Ensures the station name is defined, otherwise returns a translated required error message.
   * - Ensures the station name length is less than the maximum allowed length.
   * - Ensures the station name meets specific format requirements defined by `testIfValidStationName`.
   *
   * @param {string | undefined} stationName The Station Name to validate.
   * @param {string} id The unique identifier for the current station row
   * @returns {string | undefined} A translated error message if validation fails, otherwise undefined.
   */
  const _initialMountCheckStationName = React.useCallback(
    (stationName: string | undefined, id: string) => {
      // Require Station Name
      if (!stationName) {
        return t('Station_Name_required');
      }

      // Check if the station name is unique and if it is less than 24 characters
      if (stationName.length > MAX_LENGTH) {
        return t('Maximum_24_characters');
      } else if (stationName.length === 0) {
        return t('Station_Name_required');
      }

      // Check the regex station name
      if (!testIfValidStationName(stationName)) {
        return t('Invalid_Station_Name');
      }

      // Check for station name duplicates
      const rows = apiRef.current?.getRowModels();
      let isUnique = true;
      for (const value of rows.values()) {
        if (value.station_name === stationName && value.id !== id) {
          isUnique = false;
          break;
        }
      }
      if (!isUnique) {
        return t('Must_be_unique');
      } else {
        return null;
      }
    },
    [t, apiRef]
  );

  /**
   * Validates an IP address and ensures its uniqueness within a dataset.
   *
   * Checks if the provided IP address is not empty, follows the IPv4 format,
   * and confirms it's unique within existing dataset rows, excluding the row
   * being edited (identified by its ID).
   *
   * @param {string | undefined} ipAddress - The IP address to validate.
   * @param {string} id - The unique identifier for the current row being edited.
   * @returns {string | null} - A localized error message if the IP address is invalid or not unique, or null if validation passes.
   */
  const _initialMountCheckIPAddress = React.useCallback(
    (ipAddress: string | undefined, id: string) => {
      if (!ipAddress) {
        return t('IP_Address_Required');
      }

      // Check if the IP address is valid and if it is unique
      if (!testIfValidRegexIPV4(ipAddress)) {
        return t('Invalid_IP_Address');
      }

      const rows = apiRef.current?.getRowModels();
      let isUnique = true;
      for (const value of rows.values()) {
        if (value.ip_address === ipAddress && value.id !== id) {
          isUnique = false;
          break;
        }
      }

      if (!isUnique) {
        return t('Must_be_unique');
      } else {
        return null;
      }
    },
    [t, apiRef]
  );

  /**
   * A callback function used to validate a subnet mask.
   *
   * This function checks if the provided subnet mask is defined and valid. If the
   * subnet mask is not defined, it returns a message indicating that the subnet
   * mask is required. If the subnet mask is invalid, it returns an error message
   * indicating that the subnet mask is invalid. If the subnet mask is valid,
   * it returns null.
   *
   * @param {string | undefined} subnetMask - The subnet mask to be validated.
   * @returns {string | null} - Returns a message string indicating the validation
   *    result or null if the subnet mask is valid.
   */
  const _initialMountCheckSubnetMask = React.useCallback(
    (subnetMask: string | undefined) => {
      if (!subnetMask) {
        return t('Subnet_Mask_Required');
      }

      // Check if the subnet mask is valid
      if (!testIfValidRegexSubnetMask(subnetMask)) {
        return t('Invalid_Subnet_Mask');
      } else {
        return null;
      }
    },
    [t]
  );

  /**
   * Callback to validate the initial mount check of a default gateway.
   *
   * This function checks if a default gateway is provided and if it is valid based on
   * either an IPV4 or DNS format. It returns a translation key string indicating the
   * validation result.
   *
   * @param {string | undefined} defaultGateway - The default gateway to be validated.
   * @returns {string | null} Translation key string indicating either an error or null if valid.
   */
  const _initialMountCheckDefaultGateway = React.useCallback(
    (defaultGateway: string | undefined) => {
      // By default, this is empty, and we want to allow empty values
      if (!defaultGateway || defaultGateway.length === 0) {
        return null;
      }

      // Check if the default gateway is valid
      if (!testIfValidRegexIPV4OrDNSGateway(defaultGateway)) {
        return t('Invalid_Default_Gateway');
      } else {
        return null;
      }
    },
    [t]
  );

  /**
   * Callback function to validate the DNS input during the initial mount.
   *
   * @param {string | undefined} dns - The DNS string to be validated.
   * @returns {string | null} - Returns a validation message or null if the DNS is valid.
   */
  const _initialMountCheckDNS = React.useCallback(
    (dns: string | undefined) => {
      if (!dns) {
        return t('Shared.Required');
      }
      // Check if the DNS is valid
      if (!testIfValidRegexIPV4OrDNSGateway(dns)) {
        return t('Invalid_DNS');
      } else {
        return null;
      }
    },
    [t]
  );

  const handleKeyDown = (e: React.KeyboardEvent) => {
    // Allow: backspace, delete, tab, escape, enter, dots
    if (
      e.key === 'Backspace' ||
      e.key === 'Delete' ||
      e.key === 'Tab' ||
      e.key === 'Escape' ||
      e.key === 'Enter' ||
      e.key === '.'
    ) {
      // Allow the key if it's one of the above
      return;
    }

    // Allow: numbers 0-9
    if (/^\d$/.test(e.key)) {
      return;
    }

    // Prevent all other keys
    e.preventDefault();
  };

  const columns = React.useMemo(
    () => [
      {
        field: 'mac_addr',
        headerName: t('MAC_Address'),
        width: 160,
        editable: false,
        hideable: false,
        type: 'string',
        cellClassName: 'bold-text'
      } as GridColDef,
      {
        field: 'model_number',
        headerName: t('Model_Number'),
        width: 110,
        editable: false,
        hideable: false,
        type: 'string',
        cellClassName: 'bold-text'
      } as GridColDef,
      {
        field: 'device_image',
        headerName: deviceStr,
        align: 'center',
        hideable: true,
        headerAlign: 'center', // To match the centering of the image
        editable: false,
        display: 'flex',
        width: 100,
        cellClassName: 'flex justify-center items-center',
        renderCell: (params) => <img src={params.value} alt={params.value} style={{ maxWidth: 60, maxHeight: 40 }} />
      } as GridColDef,
      {
        field: 'station_name',
        headerName: t('Station_Name'),
        headerAlign: 'center',
        width: 180,
        editable: true,
        display: 'flex',
        align: 'center',
        hideable: false,
        type: 'string',
        cellClassName: 'cursor-pointer text-center station-name',
        renderEditCell: (props: GridRenderEditCellParams) => (
          <TextInputDataGridCell
            {...props}
            error={props.error || _initialMountCheckStationName(props.value, props.row.id)}
            checkForEmpty={true}
            readOnly={!props.row.row_is_editable}
            onChange={(e: any) => {
              const updatedValue = e.target.value;
              props.api.setEditCellValue({
                id: props.id,
                field: props.field,
                value: updatedValue
              });
            }}
          />
        ),
        renderCell: (props: GridRenderEditCellParams) => (
          <NonEditableTextInputDataGridCell
            {...props}
            error={props.error || _initialMountCheckStationName(props.value, props.row.id)}
            readOnly={!props.row.row_is_editable}
          />
        ),
        preProcessEditCellProps: preProcessStationName,
        // Need to limit the length of the station name to 24 characters with maxLength
        maxLength: MAX_LENGTH,
        minLength: 1,
        valueFormatter: (params: GridValueFormatterParams) => {
          return params.value.replace(VALID_STATION_NAME_REGEX, '');
        }
      } as GridColDef,
      {
        field: 'station_number',
        headerName: t('Station_Number'),
        headerAlign: 'center',
        width: 120,
        display: 'flex',
        align: 'center',
        editable: true,
        hideable: false,
        preProcessEditCellProps: preProcessStationNumber,
        cellClassName: 'cursor-pointer text-center',
        renderEditCell: (props: GridRenderEditCellParams) => (
          <TextInputDataGridCell
            {...props}
            error={props.error || _initialMountCheckStationNumber(props.value, props.row.id)}
            onKeyDown={(e: React.KeyboardEvent) => {
              // Allow: backspace, delete, tab, escape, enter, dots
              if (
                e.key === 'Backspace' ||
                e.key === 'Delete' ||
                e.key === 'Tab' ||
                e.key === 'Escape' ||
                e.key === 'Enter'
              ) {
                // Allow the key if it's one of the above
                return;
              }

              // Allow: numbers 0-9
              if (/^\d$/.test(e.key)) {
                return;
              }

              // Prevent all other keys
              e.preventDefault();
            }}
            checkForEmpty={true}
            readOnly={!props.row.row_is_editable}
            onChange={(e: any) => {
              const updatedValue = e.target.value;
              props.api.setEditCellValue({
                id: props.id,
                field: props.field,
                value: updatedValue
              });
            }}
          />
        ),
        renderCell: (props: GridRenderEditCellParams) => (
          <NonEditableTextInputDataGridCell
            {...props}
            error={props.error || _initialMountCheckStationNumber(props.value, props.row.id)}
            readOnly={!props.row.row_is_editable}
          />
        ),
        maxLength: MAX_LENGTH,
        minLength: 3,
        valueFormatter: (params: GridValueFormatterParams) => {
          // Only allow digits
          return params.value.replace(/\D/g, '');
        }
      } as GridColDef,
      {
        field: 'ip_address',
        headerName: t('Station.NetworkSettings.IPAddress'),
        headerAlign: 'center',
        type: 'string',
        minWidth: 180,
        display: 'flex',
        editable: true,
        valueParser: (value: string) => {
          // Remove all non-numeric and non-dot characters
          return value.replace(/[^0-9.]/g, '');
        },
        valueFormatter: (params: GridValueFormatterParams) => {
          return formatAsIPAddress(params.value);
        },
        preProcessEditCellProps: preProcessIPAddress,
        align: 'center',
        renderEditCell: (props: GridRenderEditCellParams) => (
          <TextInputDataGridCell
            {...props}
            error={props.error || _initialMountCheckIPAddress(props.value, props.row.id)}
            onKeyDown={handleKeyDown}
            checkForEmpty={true}
            maxLength={15}
            readOnly={!props.row.row_is_editable}
            onChange={(e: any) => {
              props.api.setEditCellValue({
                id: props.id,
                field: props.field,
                value: e.target.value
              });
            }}
          />
        ),
        renderCell: (props: GridRenderEditCellParams) => (
          <NonEditableTextInputDataGridCell
            {...props}
            error={props.error || _initialMountCheckIPAddress(props.value, props.row.id)}
            readOnly={!props.row.row_is_editable}
          />
        ),
        cellClassName: 'text-center'
      } as unknown as GridColDef,
      {
        field: 'subnet_mask',
        type: 'string',
        headerName: t('Subnet_Mask'),
        headerAlign: 'center',
        editable: true,
        width: 180,
        valueParser: (value: string) => {
          // Remove all non-numeric and non-dot characters
          return value.replace(/[^0-9.]/g, '');
        },
        valueFormatter: (params: GridValueFormatterParams) => {
          return formatAsIPAddress(params.value);
        },
        preProcessEditCellProps: preProcessSubnetMask,
        display: 'flex',
        align: 'center',
        renderEditCell: (props: GridRenderEditCellParams) => (
          <TextInputDataGridCell
            {...props}
            error={props.error || _initialMountCheckSubnetMask(props.value)}
            readOnly={!props.row.row_is_editable}
            checkForEmpty={true}
            onChange={(e: any) => {
              const updatedValue = e.target.value;
              props.api.setEditCellValue({
                id: props.id,
                field: props.field,
                value: updatedValue
              });
            }}
          />
        ),
        renderCell: (props: GridRenderEditCellParams) => (
          <NonEditableTextInputDataGridCell
            {...props}
            error={props.error || _initialMountCheckSubnetMask(props.value)}
            value={formatAsIPAddress(props.value)}
            readOnly={!props.row.row_is_editable}
          />
        )
      } as unknown as GridColDef,
      {
        width: 180,
        field: 'default_gateway',
        type: 'string',
        headerName: t('Default_Gateway'),
        headerAlign: 'center',
        editable: true,
        display: 'flex',
        preProcessEditCellProps: preProcessDefaultGateway,
        valueParser: (value: string) => {
          // Remove all non-numeric and non-dot characters
          return value.replace(/[^0-9.]/g, '');
        },
        valueFormatter: (params: GridValueFormatterParams) => {
          return formatAsIPAddress(params.value);
        },
        align: 'center',
        renderEditCell: (props: GridRenderEditCellParams) => (
          <TextInputDataGridCell
            {...props}
            error={props.error || _initialMountCheckDefaultGateway(props.value)}
            value={props.value === t('Invalid_IP_Address') ? '' : props.value}
            checkForEmpty={false}
            readOnly={!props.row.row_is_editable}
            onChange={(e: any) => {
              const updatedValue = e.target.value;
              props.api.setEditCellValue({
                id: props.id,
                field: props.field,
                value: updatedValue
              });
            }}
          />
        ),
        renderCell: (props: GridRenderEditCellParams) => (
          <NonEditableTextInputDataGridCell
            {...props}
            error={props.error || _initialMountCheckDefaultGateway(props.value)}
            value={formatAsIPAddress(props.value)}
            readOnly={!props.row.row_is_editable}
          />
        )
      } as unknown as GridColDef,
      {
        field: 'dns',
        type: 'string',
        headerName: t('DNS'),
        headerAlign: 'center',
        editable: true,
        display: 'flex',
        width: 180,
        preProcessEditCellProps: preProcessDNS,
        valueParser: (value: string) => {
          // Remove all non-numeric and non-dot characters
          return value.replace(/[^0-9.]/g, '');
        },
        valueFormatter: (params: GridValueFormatterParams) => {
          return formatAsIPAddress(params.value);
        },
        align: 'center',
        renderEditCell: (props: GridRenderEditCellParams) => (
          <TextInputDataGridCell
            {...props}
            error={props.error || _initialMountCheckDNS(props.value)}
            checkForEmpty={true}
            readOnly={!props.row.row_is_editable}
            onChange={(e: any) => {
              const updatedValue = e.target.value;
              props.api.setEditCellValue({
                id: props.id,
                field: props.field,
                value: updatedValue
              });
            }}
          />
        ),
        renderCell: (props: GridRenderEditCellParams) => (
          <NonEditableTextInputDataGridCell
            {...props}
            error={props.error || _initialMountCheckDNS(props.value)}
            readOnly={!props.row.row_is_editable}
            value={formatAsIPAddress(props.value)}
          />
        )
      } as unknown as GridColDef
    ],
    [rows, apiRef, gateway, t]
  );

  /**
   * Callback function to process the update of a row in a data grid.
   *
   * This function is used to validate the new row data against certain rules,
   * check the validity of the device, and update the 'canSubmitDevices' state accordingly.
   * If the new row passes all validations, it merges the old row data with the new row data
   * and sets the 'is_valid' field to true.
   *
   * @param {GridValidRowModel} newRow - The new data for the row being updated.
   * @param {GridValidRowModel} oldRow - The existing data for the row before the update.
   * @returns {Object} The updated row data, including the validity status.
   */
  const processRowUpdate = React.useCallback(
    (newRow: GridValidRowModel, oldRow: GridValidRowModel) => {
      let currentRowHasErrors = false;
      // Check if the newRow is valid, according to our validation rules
      let isInvalid = validateRow(newRow);
      if (typeof isInvalid === 'string') {
        currentRowHasErrors = true;
      }

      const rows = apiRef.current?.getRowModels();

      let canSubmitDevices = true;

      // Iterate through all of the devices and check to see if they are all submittable
      // This enables/disables the continuation button
      for (const value of rows.values()) {
        // Iterate and check if the current row that we are editing is valid
        if (value.id === newRow.id) {
          if (!isValidDevice(newRow)) {
            canSubmitDevices = false;

            // Make sure to update the state of this row (the one we are editing)
            isInvalid = t('Error_Detected');
            currentRowHasErrors = true;
            break;
          }
        } else if (!isValidDevice(value)) {
          canSubmitDevices = false;
          break;
        }
      }

      // Check the new row to see if it is a valid netmask
      if (!canSubmitDevices && !validateNetmask(newRow.ip_address, newRow.default_gateway, newRow.subnet_mask)) {
        setError(`${newRow.station_name} ${t('Has_a_Netmask_Error')}`);
        setCanSubmit(false);
      } else {
        setError(null);
        // Simplified the logic to set the canSubmitDevices state
        setCanSubmit(canSubmitDevices);
      }

      return { ...oldRow, ...newRow, is_valid: !currentRowHasErrors };
    },
    [t, apiRef, validateRow, isValidDevice, setError, setCanSubmit]
  );

  /**
   * A memoized callback to load grid rows data from the local storage.
   * The grid rows are fetched based on the `publicId` of the site information.
   * If data is found in local storage, it parses and sets the rows using the `setRows` function.
   *
   * @callback loadGridRowsData
   * @returns {GridValidRowModel[]} An array of valid grid row models. Returns an empty array if no data is found in local storage.
   */
  const loadGridRowsData = React.useCallback(() => {
    // Set the rows from the retrieved state
    const sessionKey = `wizard-setup-${site.siteInfo.publicId}`;
    const loadedRows = localStorage.getItem(sessionKey + '_rows');
    if (loadedRows) {
      const parsedRows = JSON.parse(loadedRows) as GridValidRowModel[];
      setRows(parsedRows);
      return parsedRows;
    } else {
      return [];
    }
  }, [site.siteInfo.publicId, setRows]);

  React.useEffect(() => {
    if (gatewayLoading) {
      setIsLoading(true);
    } else {
      // Load the grid rows data from local storage
      const cachedRows = loadGridRowsData();

      // Getting rid of duplicate rows based on MAC address
      const parsedRows = formatDevices(devices);
      // Remove all the rows with duplicate MAC addresses
      const uniqueRows = parsedRows.filter(
        (value, index, self) => self.findIndex((t) => t.mac_addr === value.mac_addr) === index
      );
      // Combine the cached rows with the unique rows from the network call, preferring cached rows
      // This allows the user to "pick up where they left off" if they navigate away from the page
      const combinedRows = [
        ...cachedRows,
        ...uniqueRows.filter((row) => !cachedRows.some((cachedRow) => cachedRow.mac_addr === row.mac_addr))
      ];
      setRows(combinedRows);

      // Check the combinedRows to see if all rows have is_valid equal to true. If so, set canSubmit to true
      const allRowsAreValid = combinedRows.every((row) => row.is_valid);
      if (allRowsAreValid) {
        // Only set this to true when the value is true (this avoids additional mounts when value is false)
        setCanSubmit(true);
      }

      setIsLoading(false);
    }
  }, [devices, gatewayLoading, loadGridRowsData]);

  React.useEffect(() => {
    const updateMaxLength = () => {
      const inputElements = dataGridRef.current?.querySelectorAll('input.station-name');
      inputElements?.forEach((input) => {
        input.setAttribute('maxLength', '24');
      });
    };

    updateMaxLength();
  }, []);

  // For managing cached DataGrid state
  /**
   * Load the grid state from the local storage
   * Strictly a state restoration function
   */
  const loadGridState = React.useCallback(() => {
    if (!SAVE_DATA_GRID_STATE_TO_LOCAL_STORAGE) {
      return {};
    }

    const sessionKey = `wizard-setup-${site.siteInfo.publicId}`;
    const loadedState = localStorage.getItem(sessionKey);

    if (loadedState) {
      const parsedState = JSON.parse(loadedState) as GridInitialState;
      setInitialState(parsedState);
      return parsedState;
    } else {
      return {};
    }
  }, [site.siteInfo.publicId]);

  /**
   * This callback function saves the current state of the data grid to local storage.
   * It first checks if the flag SAVE_DATA_GRID_STATE_TO_LOCAL_STORAGE is enabled.
   * If enabled, it exports the current state of the data grid using the apiRef and stores it in local storage.
   * The state is stored under a session-specific key based on the site's public ID.
   *
   * Dependencies:
   * - `apiRef`: A reference to the data grid API to export the current state.
   * - `site.siteInfo.publicId`: The public ID of the site to uniquely identify the session.
   *
   * @returns {Object} The exported state of the data grid if available, otherwise an empty object.
   */
  const saveDataGridState = React.useCallback(() => {
    if (!SAVE_DATA_GRID_STATE_TO_LOCAL_STORAGE) return {};

    const exportedState = apiRef.current?.exportState();
    if (exportedState) {
      const sessionKey = `wizard-setup-${site.siteInfo.publicId}`;
      // Keep a key to identify the data for the session
      localStorage.setItem(sessionKey, JSON.stringify(exportedState));
      return exportedState;
    } else {
      return {};
    }
  }, [apiRef, site.siteInfo.publicId]);

  /**
   * Function to save the current state of grid rows data to both the application state and local storage.
   *
   * The function checks if the constant SAVE_DATA_GRID_STATE_TO_LOCAL_STORAGE is enabled. If not, it returns an empty object.
   * It retrieves the current row models from the grid using the `apiRef`, and if there are any rows, it constructs an array
   * of these rows and updates the application state using `setRows`. The function then saves the constructed array to local
   * storage with a key that includes the site's public ID.
   *
   * Dependencies:
   * - React: Requires React hook `useCallback` for memoization.
   * - apiRef: Reference to the grid's API for retrieving current row models.
   * - site: Contains site information including the public ID used in the local storage key.
   * - setRows: Function to update the rows in the application's state.
   *
   * Data Storage:
   * - The rows data is stored in local storage under the key `wizard-setup-${site.siteInfo.publicId}_rows`.
   *
   * @returns {Object|Array} The array of row data if rows exist; otherwise, an empty array or object.
   */
  const saveGridRowsData = React.useCallback(() => {
    if (!SAVE_DATA_GRID_STATE_TO_LOCAL_STORAGE) return {};
    const currentRowValues = apiRef.current?.getRowModels();
    if (currentRowValues) {
      const rowsArray: GridValidRowModel[] = [];
      // Iterate rows and set the state data
      for (const value of currentRowValues.values()) {
        rowsArray.push(value);
      }
      if (rowsArray.length === 0) {
        return [];
      }
      setRows(rowsArray);
      // Save to local storage
      const sessionKey = `wizard-setup-${site.siteInfo.publicId}`;
      localStorage.setItem(sessionKey + '_rows', JSON.stringify(rowsArray));
      return rowsArray;
    } else {
      return [];
    }
  }, [apiRef, site.siteInfo.publicId, setRows]);

  React.useLayoutEffect(() => {
    if (SAVE_DATA_GRID_STATE_TO_LOCAL_STORAGE) {
      loadGridState();
      loadGridRowsData();
      // restoreGridState();
      // Set the rows from the retrieved state

      // Handle refresh and navigating away/refreshing the page
      window.addEventListener('beforeunload', saveDataGridState);
      window.addEventListener('beforeunload', saveGridRowsData);

      // Handle back and forward navigation
      window.addEventListener('popstate', saveDataGridState);
      window.addEventListener('popstate', saveGridRowsData);

      // Cleanup
      return () => {
        // Handle hard refresh
        window.removeEventListener('beforeunload', saveDataGridState);
        window.removeEventListener('beforeunload', saveGridRowsData);

        // Handle back and forward navigation
        window.removeEventListener('popstate', saveDataGridState);
        window.removeEventListener('popstate', saveGridRowsData);
        saveDataGridState();
        saveGridRowsData();
      };
    }
  }, [loadGridRowsData, loadGridState, saveDataGridState, saveGridRowsData]);

  if (isSaving) {
    return (
      <LoadingProgressStepCounter
        currentStep={currentStep}
        totalSteps={countTotalSteps}
        message={registrationStatusMessage}
      />
    );
  } else if (isLoading || gwLoading) {
    return <Spinner />;
  } else if (!gateway) {
    return <GatewayRequiredView handlePreviousStep={props.handlePreviousStep} />;
  } else {
    // Note: There will be an inspection error for "xxl" not being a valid Breakpoint value because it is not defined in
    // the Breakpoint enum. This is a known issue, and we can safely ignore it because we need this "xxl" value.
    return (
      <Container maxWidth={'xl'} sx={{ width: '90%' }}>
        <Box className={'flex flex-col my_20px'}>
          <Box className={'flex flex-col'}>
            <h2>{t('Assign_Station_Names')}</h2>
            <p>{t('Review_and_Make_Adjustments')}</p>
            <Box maxHeight={600} className={'flex flex-col'}>
              <DataGrid
                initialState={{ ...initialState }}
                apiRef={apiRef}
                ref={dataGridRef}
                processRowUpdate={processRowUpdate}
                columns={columns}
                rows={rows}
                getRowClassName={(params) => {
                  if (params.row.is_valid) {
                    return 'row-success';
                  } else {
                    return 'row-needs-editing';
                  }
                }}
                columnVisibilityModel={{
                  id: false,
                  is_valid: false,
                  firmware_version: false,
                  device_type: false,
                  row_is_editable: false
                }}
                cellModesModel={cellModesModel}
                onCellModesModelChange={handleCellModesModelChange}
                onCellClick={handleCellClick}
                getRowId={(row) => row.id}
              />
            </Box>
          </Box>
          <Box className={'flex flex-row justify-between items-center w-full mt-2'}>
            <Button onClick={props.handlePreviousStep} variant={'contained'} color={'primary'}>
              {t('Shared.Back')}
            </Button>
            {error && <span className={'text-red text-center text-sm mx_24px'}>{error}</span>}
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={async () => await handleSaveAndContinue()}
              disabled={!canSubmit}
            >
              {t('Continue')}
            </Button>
          </Box>
        </Box>
      </Container>
    );
  }
};

export default DeviceConfigurationGrid;
