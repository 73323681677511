import { Box, Card, CircularProgress, InputAdornment, MenuItem, Slider, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedDevice, updateSelectedDevice } from 'store/slices/devicesSlice';
import * as Yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { Select, TextField } from '@mui/material';
import { Grid } from '@mui/material';
import { useUpdateDeviceMutation } from 'services/aiphoneCloud';
import containerStyle from 'styles/advancedSettingContainerStyle';
import { useEffect, useState } from 'react';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import { EnumList, fetchEnumList } from 'shared/utils/EnumUtils';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import useTosValueValidationSchema from 'shared/utils/ValidationSchema/AdvancedSettings/Shared/TosValue';

export const AudioLabel = () => {
  const { t } = useTranslation();
  return (
    <Tooltip title="Audio, Packet Priority">
      <span>{t('AdvancedSettings_Tab_Audio')}</span>
    </Tooltip>
  );
};

const Audio = () => {
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [updateDevice, { isLoading: isUpdating }] = useUpdateDeviceMutation();
  const { t } = useTranslation();
  const selectedDevice = useSelector(getSelectedDevice);
  const [enumList, setEnumList] = useState<EnumList>({ country: {}, state: {} });
  const [fetchingEnums, setFetchingEnums] = useState(true);
  const [formikSchema, setFormikSchema] = useState<Yup.ObjectSchema<any> | null>(null);

  const audioEncoderTitle = t('AdvancedSettings_AudioEncoder_Title');
  const audioEncoderDesc = t('AdvancedSettings_AudioEncoder_Desc');
  const audioBufferStartingPacketsTitle = t('AdvancedSettings_AudioBufferStartingPackets_Title');
  const audioBufferStartingPacketsDesc = t('AdvancedSettings_AudioBufferStartingPackets_Desc');
  const audioBufferMaximumPacketsTitle = t('AdvancedSettings_AudioBufferMaximumPackets_Title');
  const audioBufferMaximumPacketsDesc = t('AdvancedSettings_AudioBufferMaximumPackets_Desc');
  const audioTosValueTitle = t('AdvancedSettings_AudioTosValue_Title');
  const audioTosValueDesc = t('AdvancedSettings_AudioTosValue_Desc');

  const errorUpdateDevice = t('AdvancedSettings_Error_UpdateDevice');
  const successUpdateDevice = t('AdvancedSettings_Success_UpdateDevice');
  const unauthorizedUser = t('AdvancedSettings_Unauthorized_User');

  const buttonSaveChanges = t('Shared.SaveChanges');
  const buttonReset = t('Button_Reset');

  const audioEncoderInvalidStr = t('Field.Error.Invalid', { field: audioEncoderTitle });
  const audioEncoderRequiredStr = t('Field.Error.Required', { field: audioEncoderTitle });
  const audioBufferStartingPacketsRequiredStr = t('Field.Error.Required', { field: audioBufferStartingPacketsTitle });
  const audioBufferMaximumPacketsRequiredStr = t('Field.Error.Required', { field: audioBufferMaximumPacketsTitle });

  const startPacketMin = 0;
  const startPacketMax = 4;
  const maxPacketMax = 10;
  const startPackMinErrorStr = t('Field.Error.Min', { field: audioBufferStartingPacketsTitle, min: startPacketMin });
  const startPackMaxErrorStr = t('Field.Error.Max', { field: audioBufferStartingPacketsTitle, max: startPacketMax });
  const macPacketMaxErrorStr = t('Field.Error.Max', { field: audioBufferMaximumPacketsTitle, max: maxPacketMax });

  const tosRegexOnChange = /^[0-9a-fA-F]{0,2}$/;

  const tosValueValidation = useTosValueValidationSchema(audioTosValueTitle);

  const formDevice = {
    networkSettings: {
      audioTosValue: selectedDevice.networkSettings?.audioTosValue.replace('0x', ''),
      audioEncoder: selectedDevice.networkSettings?.audioEncoder,
      audioBufferStartingPackets: selectedDevice.networkSettings?.audioBufferStartingPackets,
      audioBufferMaximumPackets: selectedDevice.networkSettings?.audioBufferMaximumPackets
    }
  };

  const [maxPacketsMin, setMaxPacketsMin] = useState<number>(
    formDevice.networkSettings.audioBufferStartingPackets
      ? Math.max(2, formDevice.networkSettings.audioBufferStartingPackets + 1)
      : 2
  );

  useEffect(() => {
    fetchEnumList().then((data) => {
      setEnumList(data);
      setFetchingEnums(false);
    });
  }, []);

  const getValidationSchema = (currentValues: any) => {
    const networkSchema: any = {};
    let newMaxPacketsMin: number;

    if (formDevice.networkSettings.audioTosValue !== undefined) {
      networkSchema.audioTosValue = tosValueValidation;
    }

    if (formDevice.networkSettings.audioEncoder !== undefined) {
      networkSchema.audioEncoder = Yup.string()
        .required(audioEncoderRequiredStr)
        .test('audioEncoder', audioEncoderInvalidStr, (value: string) => {
          return Object.keys(enumList.audioEncoder).includes(value);
        });
    }

    if (formDevice.networkSettings.audioBufferStartingPackets !== undefined) {
      networkSchema.audioBufferStartingPackets = Yup.number()
        .required(audioBufferStartingPacketsRequiredStr)
        .min(startPacketMin, startPackMinErrorStr)
        .max(startPacketMax, startPackMaxErrorStr);
    }

    if (formDevice.networkSettings.audioBufferMaximumPackets !== undefined) {
      newMaxPacketsMin = Math.max(2, currentValues.networkSettings.audioBufferStartingPackets + 1);
      setMaxPacketsMin(newMaxPacketsMin);
      networkSchema.audioBufferMaximumPackets = Yup.number()
        .required(audioBufferMaximumPacketsRequiredStr)
        .min(newMaxPacketsMin, t('Field.Error.Min', { field: fieldErrorMin, min: newMaxPacketsMin }))
        .max(maxPacketMax, macPacketMaxErrorStr);
    }

    return Yup.object({
      networkSettings: Yup.object().shape(networkSchema)
    });
  };

  const onSubmit = async (values: any, actions: any) => {
    const newVals = JSON.parse(JSON.stringify(values));
    newVals.networkSettings.audioTosValue = `0x${newVals.networkSettings.audioTosValue.toUpperCase()}`;
    const params = {
      device: {
        publicId: selectedDevice.publicId,
        ...newVals
      }
    };

    const newDevice = JSON.parse(JSON.stringify(selectedDevice));

    newDevice.networkSettings = {
      ...selectedDevice.networkSettings,
      ...newVals.networkSettings
    };

    updateDevice(params)
      .then((response) => {
        if ('error' in response) {
          throw response.error;
        }

        dispatch(updateSelectedDevice({ device: newDevice }));
        setShowAlert(true);
        actions.resetForm({
          values: values
        });
      })
      .catch((error: any) => {
        const err = JSON.parse(error.data);
        if (err.errorDetails.includes('Unauthorized user Id')) {
          setErrorMessage(unauthorizedUser);
        } else {
          setErrorMessage(errorUpdateDevice);
        }
      });
  };

  if (!fetchingEnums && !formikSchema) {
    setFormikSchema(getValidationSchema(formDevice));
  }

  return (
    <>
      <Box sx={containerStyle.mainWrapper}>
        <SnackbarAlert
          type="error"
          time={10000}
          text={`${errorMessage}`}
          isOpen={!!errorMessage}
          onClose={() => setErrorMessage(null)}
        />
        <SnackbarAlert
          type="success"
          time={3000}
          text={successUpdateDevice}
          isOpen={showAlert}
          onClose={() => setShowAlert(false)}
        />
        {fetchingEnums ? (
          <CircularProgress />
        ) : (
          <Formik initialValues={formDevice} onSubmit={onSubmit} validationSchema={formikSchema}>
            {({ values, dirty, touched, errors, isSubmitting, handleChange }) => (
              <Form style={containerStyle.form}>
                <Box sx={containerStyle.controlPanelWrapper}>
                  <LoadingButton variant="outlined" type="reset" disabled={!dirty || isSubmitting || isUpdating}>
                    {buttonReset}
                  </LoadingButton>
                  <LoadingButton
                    variant="outlined"
                    loading={isSubmitting}
                    type="submit"
                    disabled={!dirty || isSubmitting || isUpdating}
                  >
                    {buttonSaveChanges}
                  </LoadingButton>
                </Box>
                <Card sx={containerStyle.settingsWrapper}>
                  <Box sx={containerStyle.gridContainer}>
                    {formDevice.networkSettings.audioTosValue !== undefined ? (
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        style={containerStyle.itemContainer}
                      >
                        <Grid item xs={5} lg={7}>
                          <Box sx={containerStyle.itemTitle}>{audioTosValueTitle}</Box>
                          <Box>{audioTosValueDesc}</Box>
                        </Grid>
                        <Grid item xs={5} lg={3}>
                          <Box sx={containerStyle.fieldContainer}>
                            <Field
                              as={TextField}
                              type="text"
                              label={audioTosValueTitle}
                              name="networkSettings.audioTosValue"
                              style={containerStyle.textField}
                              helperText={
                                touched.networkSettings?.audioTosValue && errors.networkSettings?.audioTosValue
                              }
                              error={touched.networkSettings?.audioTosValue && errors.networkSettings?.audioTosValue}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start" sx={containerStyle.tosAdornment}>
                                    0x
                                  </InputAdornment>
                                )
                              }}
                              onChange={(e: any) => {
                                // Only allow valid values
                                if (tosRegexOnChange.test(e.target.value)) {
                                  handleChange(e);
                                }
                              }}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    ) : null}
                    {formDevice.networkSettings.audioEncoder !== undefined ? (
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        style={containerStyle.itemContainer}
                      >
                        <Grid item xs={5} lg={7}>
                          <Box sx={containerStyle.itemTitle}>{audioEncoderTitle}</Box>
                          <Box>{audioEncoderDesc}</Box>
                        </Grid>
                        <Grid item xs={5} lg={3}>
                          <Box sx={containerStyle.fieldContainer}>
                            <Field
                              as={Select}
                              label={audioEncoderTitle}
                              name="networkSettings.audioEncoder"
                              style={containerStyle.textField}
                              helperText={touched.networkSettings?.audioEncoder && errors.networkSettings?.audioEncoder}
                              error={touched.networkSettings?.audioEncoder && errors.networkSettings?.audioEncoder}
                            >
                              {Object.keys(enumList.audioEncoder).map((key) => {
                                return (
                                  <MenuItem key={key} value={key}>
                                    {enumList.audioEncoder[key].value}
                                  </MenuItem>
                                );
                              })}
                            </Field>
                          </Box>
                        </Grid>
                      </Grid>
                    ) : null}
                    {formDevice.networkSettings.audioBufferStartingPackets !== undefined ? (
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        style={containerStyle.itemContainer}
                      >
                        <Grid item xs={5} lg={7}>
                          <Box sx={containerStyle.itemTitle}>{audioBufferStartingPacketsTitle}</Box>
                          <Box>{audioBufferStartingPacketsDesc}</Box>
                        </Grid>
                        <Grid item xs={5} lg={3}>
                          <Box sx={containerStyle.fieldContainer}>
                            <Grid container spacing={2} alignItems={'center'}>
                              <Grid item xs={7.5}>
                                <Field
                                  as={Slider}
                                  type="range"
                                  min={startPacketMin}
                                  max={startPacketMax}
                                  label={audioBufferStartingPacketsTitle}
                                  name="networkSettings.audioBufferStartingPackets"
                                  onChange={(e: any) => {
                                    const newVals = { ...values };
                                    newVals.networkSettings.audioBufferStartingPackets = parseInt(e.target.value);
                                    handleChange(e);
                                    setFormikSchema(getValidationSchema(newVals));
                                    if (
                                      (values.networkSettings.audioBufferMaximumPackets as number) <=
                                      newVals.networkSettings.audioBufferStartingPackets
                                    ) {
                                      handleChange({
                                        target: {
                                          name: 'networkSettings.audioBufferMaximumPackets',
                                          value: (newVals.networkSettings.audioBufferStartingPackets as number) + 1
                                        }
                                      });
                                    }
                                  }}
                                />
                              </Grid>
                              <Grid item xs={4.5}>
                                <Field
                                  as={TextField}
                                  type="number"
                                  name="networkSettings.audioBufferStartingPackets"
                                  size="small"
                                  onChange={(e: any) => {
                                    const newVals = { ...values };
                                    e.target.value = parseInt(e.target.value);
                                    if (e.target.value > startPacketMax) {
                                      e.target.value = startPacketMax;
                                    } else if (e.target.value < startPacketMin) {
                                      e.target.value = startPacketMin;
                                    }
                                    newVals.networkSettings.audioBufferStartingPackets = parseInt(e.target.value);
                                    handleChange(e);
                                    setFormikSchema(getValidationSchema(newVals));
                                    if (
                                      (values.networkSettings.audioBufferMaximumPackets as number) <=
                                      newVals.networkSettings.audioBufferStartingPackets
                                    ) {
                                      handleChange({
                                        target: {
                                          name: 'networkSettings.audioBufferMaximumPackets',
                                          value: newVals.networkSettings.audioBufferStartingPackets + 1
                                        }
                                      });
                                    }
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    ) : null}
                    {formDevice.networkSettings.audioBufferMaximumPackets !== undefined ? (
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        style={containerStyle.itemContainer}
                      >
                        <Grid item xs={5} lg={7}>
                          <Box sx={containerStyle.itemTitle}>{audioBufferMaximumPacketsTitle}</Box>
                          <Box>{audioBufferMaximumPacketsDesc}</Box>
                        </Grid>
                        <Grid item xs={5} lg={3}>
                          <Box sx={containerStyle.fieldContainer}>
                            <Grid container spacing={2} alignItems={'center'}>
                              <Grid item xs={7.5}>
                                <Field
                                  as={Slider}
                                  type="range"
                                  name="networkSettings.audioBufferMaximumPackets"
                                  max={10}
                                  min={maxPacketsMin}
                                />
                              </Grid>
                              <Grid item xs={4.5}>
                                <Field
                                  as={TextField}
                                  type="number"
                                  name="networkSettings.audioBufferMaximumPackets"
                                  size="small"
                                  onChange={(e: any) => {
                                    // const newVals = { ...values };
                                    e.target.value = parseInt(e.target.value);
                                    if (e.target.value > maxPacketMax) {
                                      e.target.value = maxPacketMax;
                                    } else if (e.target.value < maxPacketsMin) {
                                      e.target.value = maxPacketsMin;
                                    }
                                    handleChange(e);
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    ) : null}
                  </Box>
                </Card>
              </Form>
            )}
          </Formik>
        )}
      </Box>
    </>
  );
};

export default Audio;
