import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const useIPictureIntervalValidationSchema = (fieldName: string): Yup.NumberSchema => {
  const { t } = useTranslation(); // Initialize the translation hook
  const formattedFieldRequired = t('Field.Error.Required', { field: fieldName });
  const iPictureIntervalMin = 1;
  const iPictureIntervalMax = 100;
  const fieldErrorMin = t('Field.Error.Min', { field: fieldName, min: iPictureIntervalMin });
  const fieldErrorMax = t('Field.Error.Max', { field: fieldName, max: iPictureIntervalMax });

  return Yup.number()
    .required(formattedFieldRequired)
    .min(iPictureIntervalMin, fieldErrorMin)
    .max(iPictureIntervalMax, fieldErrorMax);
};

export default useIPictureIntervalValidationSchema;
