import { Button, FormControlLabel, Typography, DialogActions, Grid, RadioGroup, Radio, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { fetchLocalEnumList, IDeviceModelValue } from 'shared/utils/EnumUtils';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import useSharedStyles from 'styles/useSharedStyles';

interface Props {
  handleNextStep: (gatewayType: number) => void;
  handleBackStep: () => void;
  gatewayType: number;
}

function GatewayTypeSelect({ handleNextStep, handleBackStep, gatewayType }: Readonly<Props>) {
  const sharedStyle = useSharedStyles();
  const enumList = fetchLocalEnumList();
  const gwDevice = enumList.deviceModel[
    Object.keys(enumList.deviceModel).find((key) => {
      return enumList.deviceModel[key].value === 'IXGW-GW';
    }) as string
  ] as IDeviceModelValue;
  const tgwDevice = enumList.deviceModel[
    Object.keys(enumList.deviceModel).find((key) => {
      return enumList.deviceModel[key].value === 'IXGW-TGW';
    }) as string
  ] as IDeviceModelValue;
  const gwImage = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/${gwDevice.value}.jpg`;
  const tgwImage = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/${tgwDevice.value}.jpg`;

  const { t } = useTranslation();
  const continueStr = t('Shared.Continue');
  const backStr = t('Shared.Back');
  const whichGatewayStr = t('Site.Configure.RegisterGateway.GatewayTypeSelect.WhichGateway');

  interface formValues {
    gatewayType: string;
  }

  const onSubmit = (values: formValues) => {
    handleNextStep(parseInt(values.gatewayType));
  };

  const formValues = {
    gatewayType: gatewayType.toString()
  };

  return (
    <Formik initialValues={formValues} onSubmit={onSubmit}>
      {({ handleChange, values }) => (
        <Form>
          <Grid container sx={[sharedStyle.gridContainer, sharedStyle.common.gap.md]}>
            <Grid
              container
              item
              sx={[sharedStyle.gridContainer, sharedStyle.common.gap.md, sharedStyle.common.width.fit]}
            >
              <Grid item>
                <Typography variant="h6">{whichGatewayStr}</Typography>
              </Grid>
              <Grid item>
                <RadioGroup
                  row
                  sx={[sharedStyle.imageLabelRadioGroup, sharedStyle.common.justifyContent.spaceBetween]}
                  name="gatewayType"
                  value={values.gatewayType}
                >
                  <FormControlLabel
                    sx={sharedStyle.radioItem}
                    key={tgwDevice.modelNumber}
                    value={tgwDevice.modelNumber}
                    control={<Radio />}
                    label={
                      <>
                        <Typography variant="body1">{tgwDevice.value}</Typography>
                        <Box
                          sx={[
                            sharedStyle.imageLabelRadioGroup.imageContainer,
                            sharedStyle.common.justifyContent.center
                          ]}
                        >
                          <Box component="img" sx={sharedStyle.imgThumbnail} src={tgwImage} alt={tgwDevice.value} />
                        </Box>
                      </>
                    }
                    onChange={handleChange}
                  />
                  <FormControlLabel
                    sx={sharedStyle.radioItem}
                    key={gwDevice.modelNumber}
                    value={gwDevice.modelNumber}
                    control={<Radio />}
                    label={
                      <>
                        <Typography variant="body1">{gwDevice.value}</Typography>
                        <Box
                          sx={[
                            sharedStyle.imageLabelRadioGroup.imageContainer,
                            sharedStyle.common.justifyContent.center
                          ]}
                        >
                          <Box component="img" sx={sharedStyle.imgThumbnail} src={gwImage} alt={gwDevice.value} />
                        </Box>
                      </>
                    }
                    onChange={handleChange}
                  />
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid item>
              <DialogActions sx={sharedStyle.common.padding.none}>
                <Button variant="text" onClick={handleBackStep}>
                  {backStr}
                </Button>
                <Button type="submit" variant="contained">
                  {continueStr}
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default GatewayTypeSelect;
