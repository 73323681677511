import { Box, Button, Card, CardContent, CardHeader, CardMedia, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import DownloadFilled from '@mui/icons-material/Download';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AddIcon from '@mui/icons-material/Add';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import theme from 'styles/theme';

interface NoDeploymentViewProps {
  createDeploymentHandler: () => void;
  canCreateDeployment: boolean;
}

export const NoDeploymentView = ({ canCreateDeployment, createDeploymentHandler }: NoDeploymentViewProps) => {
  const { t } = useTranslation('acNioLicenseManagement');
  //#region String Constants
  const title = t('Download_AcNio_Software.Title');
  const desc1 = t('Download_AcNio_Software.Desc1');
  const desc2 = t('Download_AcNio_Software.Desc2');
  const desc3 = t('Download_AcNio_Software.Desc3');
  const downloadAcNioSoftwareBtn = t('Download_AcNio_Software.Btn');
  const deploymentEmptyStateTitleStr = t('Deployment_Empty_State_Title');
  const deploymentEmptyStateDescriptionStr = t('Deployment_Empty_State_Description');
  const deployNewLicenseStr = t('Deploy_New_License');
  //#endregion
  const acNioImage = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/acNioImage.png`;

  return (
    <Box>
      <Paper sx={styles.paper}>
        <Card sx={styles.card}>
          <Box sx={styles.imageContainer}>
            <CardMedia component="img" sx={styles.cardMedia} image={acNioImage} alt="AC Nio" />
          </Box>

          <Box sx={styles.cardContent}>
            <CardHeader title={title} variant="h6" sx={styles.spacingTop} />
            <CardContent>
              {[desc1, desc2, desc3].map((desc, index) => (
                <Box display="flex" mb={2} key={index}>
                  <CheckIcon sx={styles.checkIcon} />
                  <Typography variant="body2">{desc}</Typography>
                </Box>
              ))}
            </CardContent>
          </Box>

          <Box sx={styles.downloadButtonContainer}>
            {/* TODO: Add onClick handler to download AC Nio Software */}
            <Button variant="outlined" color="primary" startIcon={<DownloadFilled />}>
              {downloadAcNioSoftwareBtn}
            </Button>
          </Box>
        </Card>
      </Paper>

      <Box sx={styles.emptyTableBox}>
        <AssignmentIcon sx={styles.emptyTableIcon} />
        <Typography variant="h6" sx={styles.spacingBottom}>
          {deploymentEmptyStateTitleStr}
        </Typography>
        <Typography variant="body2" sx={styles.spacingBottom}>
          {deploymentEmptyStateDescriptionStr}
        </Typography>
        {canCreateDeployment && (
          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<AddIcon />}
            sx={styles.spacingBottom}
            onClick={createDeploymentHandler}
          >
            {deployNewLicenseStr}
          </Button>
        )}
      </Box>
    </Box>
  );
};

const styles = {
  paper: {
    height: '200px',
    borderRadius: '4px',
    mb: 2,
    boxShadow: 'none',
    border: `1px solid ${theme.palette.neutral.medium}`
  },
  card: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    boxShadow: 'none'
  },
  imageContainer: {
    backgroundColor: '#FAFAFA',
    width: '270px',
    height: '100%',
    alignItems: 'center',
    display: 'flex'
  },
  cardMedia: {
    width: '228px',
    height: '112px',
    borderRadius: 2,
    objectFit: 'cover',
    marginLeft: 2
  },
  cardContent: {
    flex: 1
  },
  spacingTop: {
    mt: 2
  },
  checkIcon: {
    color: theme.palette.green.main,
    mr: 1
  },
  downloadButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    mr: 2,
    ml: 'auto'
  },
  emptyTableBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.neutral.medium}`
  },
  emptyTableIcon: {
    marginTop: 2,
    fontSize: 80,
    marginBottom: 1
  },
  spacingBottom: {
    mb: 2
  }
};
