import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { RegexIpV4 } from 'features/RemoteManagement/types/Types';

export const ipV4AddressBlacklist: string[] = ['0.0.0.0'];

const useIpV4AddressValidationSchema = (fieldName: string): Yup.Schema => {
  const { t } = useTranslation(); // Initialize the translation hook
  const formattedFieldInvalid = t('Field.Error.Invalid', { field: fieldName });

  return Yup.string()
    .nullable()
    .notOneOf(ipV4AddressBlacklist, formattedFieldInvalid)
    .matches(RegexIpV4, formattedFieldInvalid);
};

export default useIpV4AddressValidationSchema;
