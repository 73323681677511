import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { RootState } from 'store';
import { MAX_APPS } from 'features/RemoteManagement/types/Types';
import { useTranslation } from 'react-i18next';
import {
  Box,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Card,
  Typography
} from '@mui/material';

interface IAddBatchAppsToUnitsDialogProps {
  open: boolean;
  setIsOpen: (value: boolean) => void;
  selectedUnitIds: string[];
  unitLoadingState: { [key: string]: boolean };
  unitSuccessState: { [key: string]: boolean };
  unitProgress: { [key: string]: number };
}

const AddBatchAppsToUnitsDialog = ({
  open,
  setIsOpen,
  selectedUnitIds,
  unitSuccessState,
  unitProgress
}: IAddBatchAppsToUnitsDialogProps) => {
  const UnitList = useSelector((state: RootState) => state.units.UnitList);
  const [allAppsAdded, setAllAppsAdded] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    const allAdded = selectedUnitIds.every((unitId) => {
      const existingAppsCount = UnitList[unitId]?.appPublicIds?.length || 0;
      return existingAppsCount >= MAX_APPS;
    });
    setAllAppsAdded(allAdded);
  }, [selectedUnitIds, UnitList]);

  return (
    <Dialog open={open} onClose={() => setIsOpen(false)}>
      <DialogTitle>Progress: Adding Apps</DialogTitle>
      <DialogContent>
        {allAppsAdded ? (
          <Typography variant="subtitle1">
            {t('All_apps_are_already_added_and_no_additional_apps_can_be_added')}
          </Typography>
        ) : (
          <Box>
            {selectedUnitIds.map((unitId) => {
              const unit = UnitList[unitId];
              const existingAppsCount = unit?.appPublicIds?.length || 0;
              const availableAppsCount = MAX_APPS - existingAppsCount;
              if (availableAppsCount <= 0) return null;
              return (
                <Card key={unitId} sx={{ padding: 2, margin: 2, width: '500px' }}>
                  <Typography variant="h6">{unit?.unitName}</Typography>
                  <Typography variant="subtitle1">
                    {unitSuccessState[unitId]
                      ? `${availableAppsCount} ${t('Apps_successfully_added')}`
                      : `${availableAppsCount} ${t('Apps_will_be_added_to_the_unit')}`}
                  </Typography>
                  <LinearProgress variant="determinate" value={unitProgress[unitId] || 0} />
                </Card>
              );
            })}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsOpen(false)}>{t('Cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddBatchAppsToUnitsDialog;
