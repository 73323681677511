import { Card, CardContent } from '@mui/material';
import HeaderSection from 'shared/components/home/registeredUser/HeaderSection';
import DealerFeatures from 'shared/components/home/registeredUser/DealerFeatures';
import { useTranslation } from 'react-i18next';
import useSharedStyles from 'styles/useSharedStyles';
import FAQSection from 'shared/components/home/registeredUser/FAQSection';

const RemoteManagementWithNoBranchRole = () => {
  const sharedStyle = useSharedStyles();
  const { t } = useTranslation();
  const title = t('RemoteManagement.Title');
  const descStr = t('RemoteManagementWithNoBranch.Header.Desc');
  const actionTitle = t('RemoteManagementWithNoBranch.RequestBranch.Title');
  const actionDescStr = t('RemoteManagementWithNoBranch.RequestBranch.Desc');
  const faqTitle = t('FAQTitle');
  const remoteManagementFAQs = t('RemoteManagementWithNoBranch.FAQs', { returnObjects: true }) as {
    Question: string;
    Ans: string;
  }[];
  const supportUrl = 'https://www.aiphone.com/support/aiphonecloud';
  const supportUrlDescStr = t('RemoteManagementWithNoBranch.Header.ExploreFeature');
  const dealerFeaturesTitle = t('RemoteManagementWithNoBranch.Dealerfeatures.Title');
  const dealerFeaturesList = t('RemoteManagementWithNoBranch.Dealerfeatures.FeatureList', {
    returnObjects: true
  }) as string[];
  const videoId = '5Zc-EoyqgHw'; /* TODO: Replace with actual videoId*/

  return (
    <Card sx={{ ...sharedStyle.cardContainer, boxShadow: 'none' }}>
      <CardContent sx={sharedStyle.cardContainer.cardContent}>
        <HeaderSection
          title={title}
          description={descStr}
          actionTitle={actionTitle}
          actionDescription={actionDescStr}
          featureUrl={supportUrl}
          featureUrlDesc={supportUrlDescStr}
        />
        <DealerFeatures
          dealerFeaturesList={dealerFeaturesList}
          dealerFeaturesTitle={dealerFeaturesTitle}
          videoId={videoId}
        />
        <FAQSection title={faqTitle} faqs={remoteManagementFAQs} />
      </CardContent>
    </Card>
  );
};

export default RemoteManagementWithNoBranchRole;
