import React from 'react';
import { FieldProps } from 'formik';
import { TextField } from '@mui/material';

import { formatAmericanZipCode, formatCanadianPostalCode } from 'shared/utils/helperFunctions';
import { EnumList, fetchLocalEnumList, ICountryValue } from 'shared/utils/EnumUtils';
import { PostalCodeValidCharacters, ZipCodeValidCharacters } from 'features/RemoteManagement/types/Types';

// NOTE: The parent component shall control this one.

interface ZipPostalCodeInputFieldProps extends FieldProps {
  countryId: string;
}

const ZipPostalCodeField: React.FC<ZipPostalCodeInputFieldProps> = ({ field, form, countryId, ...props }) => {
  const enumList: EnumList = fetchLocalEnumList();
  const canadaCountryId = Object.keys(enumList.country).find((key) => {
    return (enumList.country[key] as ICountryValue).alpha2Code === 'CA';
  });

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let formattedValue = '';
    if (countryId === canadaCountryId) {
      // Format and restrict the input text

      const onlyValidCharacters = value.replace(PostalCodeValidCharacters, ''); // Keep only valid characters

      if (onlyValidCharacters.length > 7) {
        return;
      }

      formattedValue = formatCanadianPostalCode(onlyValidCharacters);
    } else {
      // Treat all other countries as USA

      // Format and restrict the input text
      const onlyNumbers = value.replace(ZipCodeValidCharacters, ''); // Keep only numbers

      // If it is a zipcode, then do not allow more text input
      if (onlyNumbers.length > 5) {
        return;
      }

      formattedValue = formatAmericanZipCode(onlyNumbers);
    }

    // Call Formik's default handleChange method
    // This is necessary for getting the top-level form validation to run
    field.onChange(event); // or use `props.handleChange(event)`

    // Manually set the field value to the formatted version
    await form.setFieldValue(field.name, formattedValue);
    await form.setTouched({ ...form.touched, [field.name]: true });
  };

  return <TextField {...field} {...props} value={field.value ?? ''} onChange={handleChange} />;
};

export default ZipPostalCodeField;
