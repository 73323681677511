import React, { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { Box, Button, ButtonGroup, Paper, Typography } from '@mui/material';
import { defaultLineChartOptions } from './chartConfig';
import { chartDataItem, TimeframeOptions } from 'shared/types/chartDataItem';
import { useTranslation } from 'react-i18next';
import { grey } from '@mui/material/colors';

export interface LineChartData {
  data: chartDataItem[];
  lineColor: string;
  datasetLabel: string;
}

interface LineChartGraphProps {
  title: string;
  dataItems: LineChartData[];
  onTimeframeChange: (timeframe: string) => void;
}

const LineChartGraph: React.FC<LineChartGraphProps> = ({ dataItems, title, onTimeframeChange }) => {
  const { t } = useTranslation();
  const timeFrameOptions = [
    { label: t('DashboardStats.TimeframeOptions.AllTime'), value: TimeframeOptions.ALL_TIME },
    { label: t('DashboardStats.TimeframeOptions.SixMonths'), value: TimeframeOptions.SIX_MONTHS },
    { label: t('DashboardStats.TimeframeOptions.ThisMonth'), value: TimeframeOptions.THIS_MONTH }
  ];
  const chartRef = useRef<ChartJSOrUndefined<'line', number[], string>>(null);
  const allLabels = dataItems.flatMap((item) => item.data.map((dataItem) => dataItem.label));
  const labels = Array.from(new Set(allLabels));
  const [selectedTimeframe, setSelectedTimeframe] = useState<string>(TimeframeOptions.ALL_TIME);

  const handleTimeframeChange = (timeframe: string) => {
    setSelectedTimeframe(timeframe);
    onTimeframeChange(timeframe);
  };

  const data = {
    labels,
    datasets: dataItems.map((set) => ({
      label: set.datasetLabel,
      data: set.data.map((item) => item.value),
      fill: false,
      borderColor: set.lineColor,
      backgroundColor: set.lineColor,
      pointBackgroundColor: 'white',
      tension: 0.1
    }))
  };
  const options = defaultLineChartOptions;

  useEffect(() => {
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, []);

  const getButtonStyles = (isSelected: boolean) => ({
    ...styles.button,
    ...(isSelected ? styles.selectedButton : {})
  });

  return (
    <Box sx={styles.container}>
      <Paper sx={styles.paper}>
        <Box sx={styles.header}>
          <Typography variant="body1" sx={styles.title}>
            {title}
          </Typography>
          <ButtonGroup variant="outlined" aria-label="Timeframe selection" sx={styles.buttonGroup}>
            {timeFrameOptions.map(({ label, value }) => (
              <Button
                key={value}
                color="inherit"
                onClick={() => handleTimeframeChange(value)}
                sx={getButtonStyles(selectedTimeframe === value)}
              >
                {label}
              </Button>
            ))}
          </ButtonGroup>
        </Box>
        <Box sx={styles.chartContainer}>
          <Line ref={chartRef} data={data} options={options} />
        </Box>
      </Paper>
    </Box>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '3px',
    width: '100%'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px'
  },
  paper: {
    padding: '12px',
    width: '100%',
    backgroundColor: 'white'
  },
  title: {
    marginBottom: '2px',
    fontSize: '1rem',
    padding: '2px',
    fontWeight: 'normal'
  },
  chartContainer: {
    position: 'relative',
    height: '300px',
    width: '100%',
    padding: '0 8px'
  },
  buttonGroup: {
    '& .MuiButtonGroup-grouped': {
      borderColor: grey[200],
      '&:hover': {
        borderColor: grey[200]
      }
    }
  },
  selectedButton: {
    backgroundColor: grey[300],
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: grey[300]
    }
  },
  button: {
    fontWeight: 'normal'
  }
};

export default LineChartGraph;
