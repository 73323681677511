import { FC, useEffect, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import 'styles/frontshine.css';

import DialogHeader from './components/DialogHeader';
import ConnectionTypeSelection from './components/ConnectionTypeSelection';
import GatewayMacInput from './components/forms/GatewayMacInput';
import RegisterGatewayButtons from './components/RegisterGatewayButtons';

import { VALID_MAC_ADDRESS_REGEX } from 'shared/constants/regex';
import { getSerialNumber } from 'shared/utils/helperFunctions';
import CONFIG from 'config';

import {
  checkIoTConnection,
  GatewayError,
  useGetGatewayStatus,
  useUnregisterGateway
} from 'shared/rmGateway/gwHelperFunction';
import { fetchGatewayCommand } from 'shared/rmGateway/gwCommandProcessor';
import { gwCommand } from 'shared/rmGateway/gwCommand';

import {
  useCreateDeviceMutation,
  useDeleteDeviceMutation,
  useHandleGatewayCommandMutation,
  useLazyGetDeviceListWithSitePublicIdQuery,
  useUpdateDeviceMutation,
  useUpdateSiteMutation
} from 'services/aiphoneCloud';

import { RootState, updateSite } from 'store';
import GatewayModelSelect from './components/GatewayModelSelect';
import GatewaySIMInfo from './components/GatewaySIMInfo';
import { useTranslation } from 'react-i18next';

const DEFAULT_ID = 'admin';
const DEFAULT_PW = 'admin';
const LOOPBACK_ADDRESS = '127.0.0.1';
const GOOGLE_DNS = '8.8.8.8';

interface IRegisterGatewayProps {
  isRegistered: boolean;
  setIsRegistered: (isRegistered: boolean) => void;
  handlePreviousStep: () => void;
  handleNextStep: () => void;
}

const RegisterGateway: FC<IRegisterGatewayProps> = ({
  isRegistered,
  setIsRegistered,
  handlePreviousStep,
  handleNextStep
}) => {
  const [isUsingSim, setIsUsingSim] = useState(false);
  const [isGatewayOnline, setIsGatewayOnline] = useState(false);
  const [inputMacAddress, setInputMacAddress] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [gatewayHasError, setGatewayHasError] = useState(false);
  const [gatewayErrorMessage, setGatewayErrorMessage] = useState('');
  const [gatewayErrorCode, setGatewayErrorCode] = useState('');
  const [existingId, setExistingId] = useState('');
  const [existingPw, setExistingPw] = useState('');
  const [registrationStep, setRegistrationStep] = useState('');
  const [gatewayModel, setGatewayModel] = useState('14');
  const [macAddressConfirmed, setMacAddressConfirmed] = useState(false);
  const [hasSIMSubscription, setHasSIMSubscription] = useState(false);
  const [isGatewayCreated, setIsGatewayCreated] = useState(false);
  const [gatewayPublicId, setGatewayPublicId] = useState('');
  const [handleGatewayCommand] = useHandleGatewayCommandMutation();
  const [createDevice] = useCreateDeviceMutation();
  const [updateDevice] = useUpdateDeviceMutation();
  const [deleteDevice] = useDeleteDeviceMutation();
  const [fetchDevices] = useLazyGetDeviceListWithSitePublicIdQuery();
  const [updateSiteInDB] = useUpdateSiteMutation();
  const unregisterGateway = useUnregisterGateway();
  const getGatewayStatus = useGetGatewayStatus();
  const site = useSelector((state: RootState) => state.site);
  const deviceList = useSelector((state: RootState) => state.devices.DeviceList);
  const deviceListByType = useSelector((state: RootState) => state.devices.DeviceListByType);
  const users = useSelector((state: RootState) => state.users);
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const registerGatewayStr = t('Site.RegisterGateway');

  useEffect(() => {
    if (isRegistered) {
      const registeredGateway = deviceList[site.siteInfo.registeredGatewayPublicId];
      if (registeredGateway) {
        setInputMacAddress(registeredGateway.basicInfo.macAddress);
      }
    }
  }, [isRegistered, deviceList, site.siteInfo.registeredGatewayPublicId]);

  useEffect(() => {
    if (deviceListByType['GatewayAdaptor'].length > 0) {
      setIsGatewayCreated(true);
    }
  }, [deviceListByType]);

  const handleCheckGatewayConnection = async () => {
    if (!inputMacAddress) {
      setErrorMessage(t('Please_Enter_Gateway_MAC'));
      return;
    }

    if (!VALID_MAC_ADDRESS_REGEX.test(inputMacAddress)) {
      setErrorMessage(t('AdvancedSettings_MacAddress_Error_Invalid'));
      return;
    }

    try {
      setErrorMessage('');
      setIsLoading(true);
      const results = await checkIoTConnection(inputMacAddress, site.siteInfo.awsPropertyId, getGatewayStatus);
      if (results?.readyToRegister) {
        if (results?.subscribedSite !== 'queue') {
          await unregisterGateway({
            awsPropertyId: site.siteInfo.awsPropertyId,
            gwMacAddress: inputMacAddress,
            gwId: DEFAULT_ID,
            gwPassword: DEFAULT_PW
          });

          if (site.siteInfo?.registeredGatewayPublicId) {
            await deleteDevice(site.siteInfo?.registeredGatewayPublicId);
            await updateSiteInDB({
              updateSite: {
                publicId: site.siteInfo.publicId,
                registeredGatewayPublicId: null
              }
            });
            dispatch(updateSite({ ...site.siteInfo, registeredGatewayPublicId: null }));
          }
        }
        setIsGatewayOnline(true);
        setGatewayHasError(false);
        setGatewayErrorMessage('');
      }
    } catch (error: any) {
      handleGatewayError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRegisterGateway = async () => {
    setIsLoading(true);

    /** Before gateway registration, confirm the gateway is online and then on the topic - gw/queue */
    try {
      await checkIoTConnection(inputMacAddress, site.siteInfo.awsPropertyId, getGatewayStatus);
    } catch (error: any) {
      handleGatewayError(error);
      return;
    }

    const gatewayInfo = {
      awsPropertyId: site.siteInfo.awsPropertyId,
      gwMacAddress: inputMacAddress,
      gwId: existingId ? existingId : DEFAULT_ID,
      gwPassword: existingPw ? existingPw : DEFAULT_PW
    };

    const ioTPayload = fetchGatewayCommand('sendCommand', gwCommand.REGISTER, gatewayInfo);

    //This will just send the command without waiting for the response
    await handleGatewayCommand(ioTPayload).unwrap();

    const fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.REGISTER, gatewayInfo);

    let gatewayRegistrationResponse;
    let gwFirmwareVersion;
    let newGatewayPublicId;

    try {
      setRegistrationStep(t('Gateway_Registration_Step_1'));
      gatewayRegistrationResponse = await handleGatewayCommand(fetchPayload).unwrap();
      const statusCode = gatewayRegistrationResponse.statusCode.slice(0, 3);

      if (statusCode !== '200') {
        if (statusCode === '420') {
          throw new GatewayError('RM-GW-005', statusCode);
        } else {
          throw new GatewayError('RM-GW-199', statusCode);
        }
      }
    } catch (error: any) {
      if (error.status === 504) {
        handleGatewayError(new GatewayError('RM-GW-003', error.data.message));
      } else {
        handleGatewayError(error);
      }
      return;
    }

    try {
      setRegistrationStep(t('Gateway_Registration_Step_2'));
      const ioTPayload = fetchGatewayCommand('sendCommand', gwCommand.STATION_SEARCH, gatewayInfo);
      await handleGatewayCommand(ioTPayload).unwrap();
      await new Promise((resolve) => setTimeout(resolve, 20000));

      // fetch response from dynamoDB
      const fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.STATION_SEARCH, gatewayInfo);
      const fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();
      const registerGWResponse = fetchResponse.payload.find(
        (device: { mac_addr: string }) => device.mac_addr === inputMacAddress
      );
      gwFirmwareVersion = registerGWResponse.fw_ver;
      setIsRegistered(true);
    } catch (error: any) {
      handleGatewayError(error);
      return;
    }
    if (!hasSIMSubscription) {
      if (!isGatewayCreated) {
        try {
          const deviceCreationPayload = {
            sitePublicId: site.siteInfo.publicId,
            devices: [
              {
                deviceType: 18,
                deviceModel: gatewayModel,
                macAddress: inputMacAddress,
                stationNumber: '9999',
                stationName: 'Cloud Gateway',
                advancedSettings: {
                  networkSettings: {
                    ipV4Address: gatewayRegistrationResponse.payload[0].ip_addr,
                    subnetMask: gatewayRegistrationResponse.payload[0].ip_subnet,
                    ipV4DefaultGateway: gatewayRegistrationResponse.payload[0].ip_gateway,
                    // Temporary fix until firmware responds with correct DNS
                    ipV4PrimaryDns:
                      gatewayRegistrationResponse.payload[0].dns_primary.toString() === LOOPBACK_ADDRESS
                        ? GOOGLE_DNS
                        : gatewayRegistrationResponse.payload[0].dns_primary
                  }
                }
              }
            ]
          };

          setRegistrationStep(t('Gateway_Registration_Step_3'));
          const createdDeviceResponse = await createDevice(deviceCreationPayload).unwrap();
          newGatewayPublicId = createdDeviceResponse[0].publicId;
          setIsGatewayCreated(true);
        } catch (error: any) {
          handleDBError(error);
          return;
        }
      }

      try {
        const updateDevicePayload = {
          device: {
            publicId: isGatewayCreated ? gatewayPublicId : newGatewayPublicId,
            sitePublicId: site.siteInfo?.publicId,
            basicInfo: {
              adminId: existingId ? existingId : undefined,
              adminPass: existingPw ? existingPw : undefined,
              firmwareVersion: gwFirmwareVersion
            },
            gatewaySettings: {
              gatewaySerialNumber: getSerialNumber(inputMacAddress),
              communicationMethod: 3
            }
          }
        };
        await updateDevice(updateDevicePayload).unwrap();
      } catch (error: any) {
        handleDBError(error);
        return;
      }

      try {
        const updateSitePayload = {
          updatedSite: {
            publicId: site.siteInfo.publicId,
            awsPropertyId: site.siteInfo?.awsPropertyId,
            registeredGatewayPublicId: isGatewayCreated ? gatewayPublicId : newGatewayPublicId,
            systemId: existingId ? existingId : undefined,
            systemPassword: existingPw ? existingPw : undefined
          }
        };

        setRegistrationStep(t('Gateway_Registration_Step_4'));
        await updateSiteInDB(updateSitePayload).unwrap();
        dispatch(updateSite(updateSitePayload.updatedSite));
      } catch (error: any) {
        handleDBError(error);
        return;
      }
    } else {
      try {
        const updateSitePayload = {
          updatedSite: {
            publicId: site.siteInfo.publicId,
            awsPropertyId: site.siteInfo?.awsPropertyId,
            registeredGatewayPublicId: gatewayPublicId,
            systemId: existingId ? existingId : undefined,
            systemPassword: existingPw ? existingPw : undefined
          }
        };

        setRegistrationStep(t('Gateway_Registration_Step_4'));
        await updateSiteInDB(updateSitePayload).unwrap();
        dispatch(updateSite(updateSitePayload.updatedSite));
      } catch (error: any) {
        handleDBError(error);
        return;
      }

      try {
        const updateDevicePayload = {
          device: {
            publicId: gatewayPublicId,
            sitePublicId: site.siteInfo?.publicId,
            basicInfo: {
              adminId: existingId ? existingId : undefined,
              adminPass: existingPw ? existingPw : undefined,
              firmwareVersion: gwFirmwareVersion
            },
            gatewaySettings: {
              gatewaySerialNumber: getSerialNumber(inputMacAddress),
              communicationMethod: 3
            },
            networkSettings: {
              ipV4Address: gatewayRegistrationResponse.payload[0].ip_addr,
              subnetMask: gatewayRegistrationResponse.payload[0].ip_subnet,
              ipV4DefaultGateway: gatewayRegistrationResponse.payload[0].ip_gateway,
              // Temporary fix until firmware responds with correct DNS
              ipV4PrimaryDns:
                gatewayRegistrationResponse.payload[0].dns_primary.toString() === '127.0.0.1'
                  ? '8.8.8.8'
                  : gatewayRegistrationResponse.payload[0].dns_primary
            }
          }
        };
        await updateDevice(updateDevicePayload).unwrap();
      } catch (error: any) {
        handleDBError(error);
        return;
      }
    }

    try {
      setRegistrationStep(t('Gateway_Registration_Step_5'));
      await fetchDevices({ qty: 50, page: 0, sitePublicId: site.siteInfo.publicId }).unwrap();
    } catch (error: any) {
      handleGatewayError(error);
      return;
    }

    setIsLoading(false);
    setIsRegistered(true);
    handleNextStep();
  };

  /**
   * @description This function is called when the user clicks "Confirm Mac Address" button
   * The purpose of the function is to create a new gateway device in the database and update the site with the new gateway public id
   * The function will also send a request to the ACL to upload the site data for SIM Billing to have a record of the site with the gateway
   *
   * @returns void
   */
  const handleEnableSIMSite = async () => {
    setIsLoading(true);

    const aclToken = localStorage.getItem('acltoken');
    const callingUserPublicId = users.currentUser?.publicId;
    let newGatewayPublicId;

    if (!isGatewayCreated) {
      try {
        const createdDeviceResponse = await createDevice({
          sitePublicId: site.siteInfo.publicId,
          devices: [
            {
              deviceType: 18,
              deviceModel: gatewayModel,
              macAddress: inputMacAddress,
              stationNumber: '9999',
              stationName: 'Cloud Gateway',
              advancedSettings: {
                networkSettings: {
                  ipV4Address: '192.168.1.160',
                  subnetMask: '255.255.255.0'
                }
              }
            }
          ]
        }).unwrap();
        newGatewayPublicId = createdDeviceResponse[0].publicId;
        setGatewayPublicId(newGatewayPublicId);
        setIsGatewayCreated(true);

        await updateDevice({
          device: {
            publicId: newGatewayPublicId,
            sitePublicId: site.siteInfo?.publicId,
            gatewaySettings: {
              gatewaySerialNumber: getSerialNumber(inputMacAddress),
              communicationMethod: 3
            }
          }
        }).unwrap();
      } catch (error: any) {
        setIsLoading(false);
        handleDBError(error);
        return;
      }

      try {
        await updateSiteInDB({
          updatedSite: {
            publicId: site.siteInfo.publicId,
            awsPropertyId: site.siteInfo?.awsPropertyId,
            registeredGatewayPublicId: newGatewayPublicId,
            systemId: existingId ? existingId : undefined,
            systemPassword: existingPw ? existingPw : undefined
          }
        });
      } catch (error: any) {
        setIsLoading(false);
        handleDBError(error);
        return;
      }

      try {
        await fetch(CONFIG.openApiEndpoint + '/syncAcl', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            action: 'upload',
            propertyId: site.siteInfo.awsPropertyId,
            token: aclToken,
            siteId: site.siteInfo.publicId,
            userId: callingUserPublicId
          })
        });
      } catch (error) {
        throw new Error('Error during upload site to ACL');
      }

      //Wait for 5 seconds for ACL to process the site data
      await new Promise((resolve) => setTimeout(resolve, 5000));

      try {
        await fetch(CONFIG.openApiEndpoint + '/syncAcl', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            action: 'getIntercomAccessDate',
            propertyId: site.siteInfo.awsPropertyId,
            token: aclToken,
            clientId: getSerialNumber(inputMacAddress),
            userId: callingUserPublicId
          })
        });
        setMacAddressConfirmed(true);
        setIsLoading(false);
      } catch (error) {
        throw new Error('Error during getIntercomAccessDate');
      }
    } else {
      try {
        const aclSiteConfirmResponse = await fetch(CONFIG.openApiEndpoint + '/syncAcl', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            action: 'getIntercomAccessDate',
            propertyId: site.siteInfo.awsPropertyId,
            token: aclToken,
            clientId: getSerialNumber(inputMacAddress),
            userId: callingUserPublicId
          })
        });
        if (aclSiteConfirmResponse.status !== 200) {
          throw new Error(t('IXG_Cloud_Portal_Error_Site_Validation'));
        } else {
          setMacAddressConfirmed(true);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleGatewayError = (error: GatewayError) => {
    setIsLoading(false);
    if (error.message) {
      switch (error.message) {
        case '420':
          setGatewayErrorCode('420');
          setGatewayErrorMessage(t('Gateway_Error_message.RM-GW-005'));
          break;
        case 'NO_PAYLOAD_FOUND':
          setGatewayErrorCode('NO_PAYLOAD_FOUND');
          setGatewayErrorMessage(t('Gateway_Error_message.RM-GW-001'));
          break;
        case 'Endpoint request timed out':
          setGatewayErrorCode('timeout');
          setGatewayErrorMessage(t('Gateway_Error_message.RM-GW-003'));
          break;
        case 'CONNECTION_LOST':
          setGatewayErrorCode('CONNECTION_LOST');
          setGatewayErrorMessage(t('Gateway_Error_message.RM-GW-002'));
          break;
        case 'IN_QUEUE':
          setGatewayErrorMessage(t('Gateway_Error_message.RM-GW-024'));
          break;
        case 'Gateway already registered to another site':
          setGatewayErrorMessage(t('Gateway_Error_message.RM-GW-023'));
      }
    } else if (error.code) {
      setGatewayErrorCode(error.code);
      setGatewayErrorMessage(t(`Gateway_Error_message.${error.code}`));
    }

    setRegistrationStep('');
    setGatewayHasError(true);
  };

  const handleDBError = (error: any) => {
    const parsedError = JSON.parse(error.data);
    if (parsedError.errorDetails.includes('unique')) {
      setErrorMessage(t('Gateway_Error_Device_Exists'));
    }
    setRegistrationStep('');
    setIsLoading(false);
  };

  return (
    <Container maxWidth="xl">
      <DialogHeader title={t('Configuring_Your_Site')} description={registerGatewayStr} />
      <GatewayModelSelect gatewayModel={gatewayModel} setGatewayModel={setGatewayModel} setIsUsingSim={setIsUsingSim} />
      <ConnectionTypeSelection isUsingSim={isUsingSim} setIsUsingSim={setIsUsingSim} gatewayModel={gatewayModel} />
      {isUsingSim ? null : (
        <Box sx={{ my: 2 }}>
          <Typography variant="wizardSectionHeader" gutterBottom>
            {t('Register_Gateway_With_Cloud')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {t('Register_Gateway_With_Cloud_Desc')}
          </Typography>
          {/* TODO: Removing until tech services can create a article for this */}
          {/* <Typography variant="dialogSubTitle" gutterBottom>
            {t('Register_Gateway_With_Cloud_Note')}
          </Typography> */}
        </Box>
      )}
      <GatewayMacInput
        inputMacAddress={inputMacAddress}
        setInputMacAddress={setInputMacAddress}
        isGatewayOnline={isGatewayOnline}
        gatewayHasError={gatewayHasError}
        gatewayErrorCode={gatewayErrorCode}
        gatewayErrorMessage={gatewayErrorMessage}
        existingId={existingId}
        existingPw={existingPw}
        setExistingId={setExistingId}
        setExistingPw={setExistingPw}
        isLoading={isLoading}
        isUsingSim={isUsingSim}
        handleEnableSIMSite={handleEnableSIMSite}
        isRegistered={isRegistered}
        macAddressConfirmed={macAddressConfirmed}
      />
      {isUsingSim && (
        <GatewaySIMInfo
          macAddressConfirmed={macAddressConfirmed}
          hasSIMSubscription={hasSIMSubscription}
          setHasSIMSubscription={setHasSIMSubscription}
        />
      )}
      <RegisterGatewayButtons
        handlePreviousStep={handlePreviousStep}
        handleNextStep={handleNextStep}
        handleCheckGatewayConnection={handleCheckGatewayConnection}
        handleRegisterGateway={handleRegisterGateway}
        inputMacAddress={inputMacAddress}
        errorMessage={errorMessage}
        isLoading={isLoading}
        isGatewayOnline={isGatewayOnline}
        registrationStep={registrationStep}
        isRegistered={isRegistered}
        isUsingSim={isUsingSim}
        hasSIMSubscription={hasSIMSubscription}
      />
    </Container>
  );
};

export default RegisterGateway;
