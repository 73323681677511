import { Box, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  fallback?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('ErrorBoundary caught an error', error, errorInfo);
    logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <Box sx={styles.authWrapper}>
            <Box sx={styles.authContainer}>
              <Box sx={styles.logoWrapper}>
                <img src="/AiphoneCloud-Logo_Blue.png" alt={t('Shared.AiphoneLogo')} width="80%" />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 2
                  }}
                >
                  <Typography variant="h5" sx={{ my: 2 }}>
                    Something has gone wrong
                  </Typography>
                  <Typography variant="body1">Please refresh the page to try your request again.</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      );
    }

    return this.props.children;
  }
}

function logErrorToMyService(error: Error, errorInfo: ErrorInfo) {
  console.error('Logged to external service:', error, errorInfo);
}

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  authWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    width: '100%',
    backgroundImage: `url('/login-background.jpg')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  logoWrapper: {
    padding: 3,
    textAlign: 'center'
  },
  authContainer: {
    borderRadius: '10px 10px 10px 10px',
    width: '98%',
    maxWidth: '800px',
    position: 'relative',
    boxShadow: '0 30px 60px 0 rgba(0,0,0,0.3)',
    backgroundColor: 'rgba(255, 255, 255, 0.55)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingX: 2,
    marginY: 1,
    marginBottom: '3rem'
  }
};

export default ErrorBoundary;
