import { useMutation } from '@tanstack/react-query';
import { cancelStripeSubscription } from '../lib/api/LicenseManagementApi';
import { useCurrentBranchWithReactQuery } from './useCurrentBranchWithReactQuery';

export const useCancelStripeSubscription = () => {
  const { data: currentBranch } = useCurrentBranchWithReactQuery();
  const branchId = currentBranch?.publicId;
  const mutation = useMutation({
    mutationFn: (subscriptionId: string) => cancelStripeSubscription(subscriptionId, branchId)
  });

  return mutation;
};
