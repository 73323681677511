import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import {
  useGetBranchWithPublicIdQuery,
  useLazyGetBranchWithC2IdQuery,
  useUpdateBranchMutation
} from 'services/aiphoneCloud';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { CA, US } from 'country-flag-icons/react/3x2';
import * as yup from 'yup';
import { EnumList, ICountryValue, IStateValue, fetchLocalEnumList } from 'shared/utils/EnumUtils';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/utils/StringUtils';
import getStateList from 'shared/utils/GetStateList';

interface IEditBranchDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setErrorMessage: (errorMessage: string) => void;
  setIsError: (isError: boolean) => void;
  setSuccessMessage: (successMessage: string) => void;
  setIsSuccess: (isSuccess: boolean) => void;
  selectedBranch: string;
}

type C2BranchData = {
  accountmanagername: string;
  annualsales: string;
  businesscategories: string;
  companyname: string;
  companyphone: string;
  customernumber: string;
  discountlvl: string;
  financialcondition: string;
  hasprofilepic: number;
  id: string;
  industrycategory: string;
  lasttaskcategory: string;
  lasttaskdate: string;
  lasttaskpurpose: string;
  lasttaskstatus: string;
  location: string;
  marketingcategories: string;
  pacity: string;
  papostalcode: string;
  parentcompany: string;
  pastate: string;
  physicaladdress: string;
  physicaladdress2: string;
  salesnum: string;
  status: string;
};

const EditBranchDialog = ({
  isOpen,
  setIsOpen,
  setErrorMessage,
  setIsError,
  setSuccessMessage,
  setIsSuccess,
  selectedBranch
}: IEditBranchDialogProps) => {
  const { data: branchData, isFetching } = useGetBranchWithPublicIdQuery(selectedBranch, { skip: !isOpen });
  const [c2Input, setC2Input] = useState<boolean>(false);
  const [updateBranch] = useUpdateBranchMutation();
  const [searchBranch] = useLazyGetBranchWithC2IdQuery();
  const [isC2BranchDialogOpen, setIsC2BranchDialogOpen] = useState<boolean>(false);
  const [c2BranchData, setC2BranchData] = useState<C2BranchData | null>(null);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const enumList: EnumList = fetchLocalEnumList();
  const canadaCountryId = Object.keys(enumList.country).find((key) => {
    return (enumList.country[key] as ICountryValue).alpha2Code === 'CA';
  }) as string;
  const { t } = useTranslation();
  const editBranchTitle = t('Title_EditBranch');
  const c2CompanyIDText = t('C2CompanyID_Dialog');
  const companyInfoText = t('CompanyInformation');
  const branchInfoText = t('BranchInformation_Dialog');
  const branchDataImportText = t('Branch.ImportData');
  const accountManagerName = t('AccountManagerName');
  const companyName = t('Company.Name');
  const phoneNumber = t('Shared.PhoneNumber');
  const addressStr = t('Shared.Address');
  const statusStr = t('Status');
  const cancelButton = t('Shared.Cancel');
  const saveButton = t('Button_Save');
  const importButton = t('Button_Import');
  const selectAItemStr = t('Shared.SelectAItem');
  const countryStr = t('Shared.Country');
  const stateStr = t('Shared.State');
  const provinceStr = t('Shared.Province');
  const cityStr = t('Shared.City');
  const zipCodeStr = t('Shared.ZipCode');
  const postalCodeStr = t('Shared.PostalCode');
  const zipOrPostalCodeStr = t('Shared.ZipOrPostalCode');
  const stateOrProvinceStr = t('Shared.StateOrProvince');
  const phoneNumberStr = t('Shared.PhoneNumber');
  const phoneNumberInvalidStr = t('Field.Error.Invalid', { field: phoneNumberStr });
  const phoneNumberRequiredStr = t('Field.Error.Required', { field: phoneNumberStr });
  const selectACountryStr = StringUtils.format(selectAItemStr, countryStr);
  const selectAStateStr = StringUtils.format(selectAItemStr, stateStr);
  const selectAProvinceStr = StringUtils.format(selectAItemStr, provinceStr);

  const handleUpdateBranch = (values: any) => {
    const updateRequest = {
      branchData: {
        address: values.address,
        address2: values.address2,
        address3: branchData?.address3,
        city: values.city,
        name: values.branchName,
        c2CompanyId: values.c2CompanyId,
        aiphoneCloudAccountNumber: branchData?.aiphoneCloudAccountNumber,
        phoneNumber: values.phoneNumber,
        stateId: parseInt(values.stateId),
        postalCode: values.postalCode,
        isDutyFree: branchData?.isDutyFree,
        publicId: selectedBranch
      }
    };
    updateBranch(updateRequest)
      .unwrap()
      .then(() => {
        setSuccessMessage(t('Branch_Updated_Successfully'));
        setIsSuccess(true);
        setIsOpen(false);
        setTimeout(() => {
          setIsSuccess(false);
        }, 3000);
      })
      .catch(() => {
        setErrorMessage(t('An_error_updating_branch'));
        setIsError(true);
        setTimeout(() => {
          setIsError(false);
        }, 3000);
      });
  };

  const validationSchema = yup.object().shape({
    address: yup.string().required(t('Validations.Address_Required')),
    countryId: yup.string().required(t('Validations.Country_Required')),
    stateId: yup.string().required(t('Validations.State_Required')),
    city: yup.string().required(t('Validations.City_Required')),
    postalCode: yup.string().required(t('Validations.Zip_Code_Required')),
    phoneNumber: yup
      .string()
      .required(phoneNumberRequiredStr)
      .matches(/^(\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$/, phoneNumberInvalidStr)
  });

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          branchName: !isFetching ? branchData?.branchName : '',
          address: !isFetching ? branchData?.address : '',
          address2: !isFetching ? branchData?.address2 : '',
          city: !isFetching ? branchData?.city : '',
          stateId: !isFetching ? branchData?.stateId : '',
          postalCode: !isFetching ? branchData?.postalCode : '',
          countryId:
            !isFetching && branchData?.stateId
              ? (enumList.state[branchData?.stateId] as IStateValue).countryId.toString()
              : '',
          phoneNumber: !isFetching ? branchData?.phoneNumber : '',
          c2CompanyId: !isFetching ? branchData?.c2CompanyId : '',
          companyName: !isFetching ? branchData?.company.name : '',
          companyState:
            !isFetching && branchData?.company?.stateId ? enumList.state[branchData?.company.stateId].value : '',
          companyCountry:
            !isFetching && branchData?.company?.stateId
              ? enumList.country[(enumList.state[branchData?.company.stateId] as IStateValue).countryId].value
              : '',
          companyPostalCode: !isFetching ? branchData?.company.postalCode : ''
        }}
        onSubmit={(values) => {
          handleUpdateBranch(values);
        }}
        validationSchema={validationSchema}
      >
        {({ errors, touched, isSubmitting, values, handleChange, setFieldValue }) => {
          if (values.c2CompanyId === '') {
            setC2Input(false);
          }
          const handleImportClick = () => {
            const stateId =
              Object.keys(enumList.state).find(
                (key) => (enumList.state[key] as IStateValue).isoCode === c2BranchData?.pastate
              ) || '';
            const countryId = stateId ? (enumList.state[stateId] as IStateValue).countryId.toString() : '';

            if (c2BranchData) {
              setFieldValue('countryId', countryId);
              setFieldValue('branchName', c2BranchData.companyname);
              setFieldValue('address', c2BranchData.physicaladdress);
              setFieldValue('address2', c2BranchData.physicaladdress2 || '');
              setFieldValue('city', c2BranchData.location);
              setFieldValue('stateId', stateId);
              setFieldValue('postalCode', c2BranchData.papostalcode);
              setFieldValue('phoneNumber', c2BranchData.companyphone);
            }
          };

          return (
            <>
              <Dialog
                onClose={() => setIsOpen(false)}
                open={isOpen}
                disableEscapeKeyDown={true}
                maxWidth="lg"
                fullWidth={true}
              >
                <Grid container spacing={8}>
                  <Grid item xs={11}>
                    <DialogTitle>{editBranchTitle}</DialogTitle>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton color="default" aria-label="cancel" onClick={() => setIsOpen(false)}>
                      <CancelIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Form>
                  <DialogContent>
                    {isFetching ? (
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <>
                        <Box sx={styles.branchInfoContainer}>
                          <Box>
                            <Typography variant="body1" sx={styles.my2}>
                              {c2CompanyIDText}
                            </Typography>
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Field
                                  name="c2CompanyId"
                                  as={TextField}
                                  sx={styles.inputField}
                                  label={t('C2CompanyID')}
                                  size="small"
                                  disabled={!c2Input}
                                  onChange={(e: any) => {
                                    handleChange(e);

                                    if (e.target.value.length === 32) {
                                      setIsSearching(true);
                                      searchBranch(e.target.value).then((res) => {
                                        if (res.data) {
                                          setIsC2BranchDialogOpen(true);
                                          setIsSearching(false);
                                          setC2BranchData(res.data);
                                        } else {
                                          setIsSearching(false);
                                          setErrorMessage(t('Branch_Not_Found'));
                                          setIsError(true);
                                          setTimeout(() => {
                                            setIsError(false);
                                          }, 3000);
                                        }
                                      });
                                    }
                                  }}
                                  InputProps={{
                                    endAdornment: !c2Input ? (
                                      <InputAdornment position="end">
                                        <IconButton onClick={() => setC2Input(!c2Input)}>
                                          <EditIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    ) : (
                                      <InputAdornment position="end">
                                        <IconButton onClick={() => setC2Input(!c2Input)}>
                                          {isSearching ? <CircularProgress size={20} /> : <SaveIcon color="primary" />}
                                        </IconButton>
                                      </InputAdornment>
                                    )
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                          <Typography variant="h5">{branchInfoText}</Typography>
                          <Box sx={styles.mt2}>
                            <Grid container spacing={1}>
                              <Grid item xs={6}>
                                <Field
                                  name="branchName"
                                  as={TextField}
                                  sx={styles.inputField}
                                  label={t('Branch_Name')}
                                  size="small"
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <Field
                                  name="address"
                                  as={TextField}
                                  sx={styles.inputField}
                                  label={addressStr}
                                  size="small"
                                  helperText={touched.address && errors.address ? errors.address : ''}
                                  error={touched.address && !!errors.address}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Field
                                  name="address2"
                                  as={TextField}
                                  sx={styles.inputField}
                                  label={t('Address_2')}
                                  size="small"
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <Field
                                  name="city"
                                  as={TextField}
                                  sx={styles.inputField}
                                  label={t('Shared.City')}
                                  size="small"
                                  helperText={touched.city && errors.city ? errors.city : ''}
                                  error={touched.city && !!errors.city}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <FormControl
                                  sx={styles.inputField}
                                  size="small"
                                  error={touched.countryId && !!errors.countryId}
                                >
                                  <InputLabel id="country-label">{selectACountryStr}</InputLabel>
                                  <Field
                                    name="countryId"
                                    id="countryId"
                                    as={Select}
                                    labelId="country-label"
                                    label={selectACountryStr}
                                    helperText={touched.countryId && errors.countryId ? errors.countryId : ''}
                                    onChange={(e: any) => {
                                      handleChange(e);
                                      setFieldValue('stateId', '');
                                    }}
                                  >
                                    {Object.keys(enumList.country).map((key) => {
                                      const validCountries = ['CA', 'US'];
                                      const countryWalker = enumList.country[key] as ICountryValue;

                                      if (!validCountries.includes(countryWalker.alpha2Code)) {
                                        return null;
                                      }

                                      const flagComponent: JSX.Element =
                                        countryWalker.alpha2Code === 'CA' ? (
                                          <CA header={countryWalker.defaultLanguageName} style={styles.flagStyle} />
                                        ) : (
                                          <US header={countryWalker.defaultLanguageName} style={styles.flagStyle} />
                                        );

                                      return (
                                        <MenuItem key={key} value={key}>
                                          <Grid sx={styles.field}>
                                            {flagComponent}
                                            {countryWalker.value}
                                          </Grid>
                                        </MenuItem>
                                      );
                                    })}
                                  </Field>
                                </FormControl>
                              </Grid>
                              <Grid item xs={2}>
                                <FormControl
                                  sx={styles.inputField}
                                  size="small"
                                  error={touched.stateId && !!errors.stateId}
                                >
                                  <InputLabel id="state-label">
                                    {values.countryId !== canadaCountryId ? selectAStateStr : selectAProvinceStr}
                                  </InputLabel>
                                  <Field
                                    name="stateId"
                                    as={Select}
                                    labelId="state-label"
                                    label={values.countryId !== canadaCountryId ? selectAStateStr : selectAProvinceStr}
                                    error={errors.stateId && touched.stateId}
                                    helperText={errors.stateId && touched.stateId ? errors.stateId : ''}
                                  >
                                    {getStateList(enumList, values.countryId, selectACountryStr)}
                                  </Field>
                                </FormControl>
                              </Grid>
                              <Grid item xs={2}>
                                <Field
                                  name="postalCode"
                                  as={TextField}
                                  sx={styles.inputField}
                                  label={values.countryId !== canadaCountryId ? zipCodeStr : postalCodeStr}
                                  size="small"
                                  helperText={touched.postalCode && errors.postalCode ? errors.postalCode : ''}
                                  error={touched.postalCode && !!errors.postalCode}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Field
                                  name="phoneNumber"
                                  as={TextField}
                                  sx={styles.inputField}
                                  label={phoneNumberStr}
                                  size="small"
                                  helperText={touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : ''}
                                  error={touched.phoneNumber && !!errors.phoneNumber}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                          <Typography variant="h5">{companyInfoText}</Typography>
                          <Box sx={styles.mt2}>
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Field
                                  name="companyName"
                                  as={TextField}
                                  sx={styles.inputField}
                                  label={t('Company_Name')}
                                  size="small"
                                  disabled
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <Field
                                  name="companyCountry"
                                  as={TextField}
                                  sx={styles.inputField}
                                  label={countryStr}
                                  size="small"
                                  disabled
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <Field
                                  name="companyState"
                                  as={TextField}
                                  sx={styles.inputField}
                                  label={t('Shared.State')}
                                  size="small"
                                  disabled
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <Field
                                  name="companyPostalCode"
                                  as={TextField}
                                  sx={styles.inputField}
                                  label={
                                    enumList.country[canadaCountryId].value !== values.companyCountry
                                      ? zipCodeStr
                                      : postalCodeStr
                                  }
                                  size="small"
                                  disabled
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                        <DialogActions>
                          <Button variant="contained" onClick={() => setIsOpen(false)}>
                            {cancelButton}
                          </Button>
                          <LoadingButton
                            type="submit"
                            loading={isSubmitting}
                            disabled={isSubmitting}
                            variant="contained"
                          >
                            {saveButton}
                          </LoadingButton>
                        </DialogActions>
                      </>
                    )}
                  </DialogContent>
                </Form>
              </Dialog>
              <Dialog open={isC2BranchDialogOpen} maxWidth="lg">
                <DialogTitle>{branchDataImportText}</DialogTitle>
                <DialogContent>
                  {c2BranchData ? (
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                          <TableRow>
                            <TableCell>{accountManagerName}</TableCell>
                            <TableCell>{companyName}</TableCell>
                            <TableCell>{phoneNumber}</TableCell>
                            <TableCell>{addressStr}</TableCell>
                            <TableCell>{cityStr}</TableCell>
                            <TableCell>{stateOrProvinceStr}</TableCell>
                            <TableCell>{zipOrPostalCodeStr}</TableCell>
                            <TableCell>{statusStr}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>{c2BranchData.accountmanagername}</TableCell>
                            <TableCell>{c2BranchData.companyname}</TableCell>
                            <TableCell>{c2BranchData.companyphone}</TableCell>
                            <TableCell>{c2BranchData.physicaladdress}</TableCell>
                            <TableCell>{c2BranchData.location}</TableCell>
                            <TableCell>{c2BranchData.pastate}</TableCell>
                            <TableCell>{c2BranchData.papostalcode}</TableCell>
                            <TableCell>{c2BranchData.status}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : null}
                  <DialogActions>
                    <Button onClick={() => setIsC2BranchDialogOpen(false)} variant="contained">
                      {cancelButton}
                    </Button>
                    <Button
                      onClick={() => {
                        setIsC2BranchDialogOpen(false);
                        handleImportClick();
                        setC2Input(false);
                      }}
                      variant="contained"
                    >
                      {importButton}
                    </Button>
                  </DialogActions>
                </DialogContent>
              </Dialog>{' '}
            </>
          );
        }}
      </Formik>
    </>
  );
};

const styles = {
  branchInfoContainer: {
    marginY: 2
  },
  mt2: {
    marginTop: 2
  },
  my2: {
    marginY: 2
  },
  inputField: {
    marginBottom: 1,
    width: '100%',
    '& .MuiInputBase-input': {
      backgroundColor: '#ffffff'
    },
    '&.MuiFormHelperText-root': {
      color: 'red'
    },
    '& .MuiInputLabel-root': {
      color: 'red',
      '&.Mui-focused': {
        color: 'black'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: '#003366'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0071ce'
      }
    }
  },
  field: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  flagStyle: {
    width: '33px',
    minWidth: '33px',
    height: '22px',
    paddingRight: '10px',
    paddingTop: 0,
    paddingBottom: 0,
    mt: 0,
    mb: 0
  }
};

export default EditBranchDialog;
