import React, { forwardRef, ReactElement, Ref, useEffect, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import SearchableDropdown from '../SearchableDropdown';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import { Button, DialogActions, Box, IconButton, Grid } from '@mui/material';
import { DataGrid, GridColDef, GridOverlay, GridRowsProp } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { IDevice } from '../../../../../../store/slices/devicesSlice';
import { IUnit } from 'store/slices/unitsSlice';
import SearchableDropdownApps from '../SearchableDropdownApps';
import { useTranslation } from 'react-i18next';

interface IAddNewDeviceProps {
  selectedDevice: IDevice;
  appUnits: IUnit[];
  devices: IDevice[];
  isOpen: boolean;
  handleClose: () => void;
  saveNewDevicesAndApps: (selectedDevices: IDevice[], selectedAppUnits: IUnit[]) => void;
}

interface IParams {
  row: IDevice;
}

const strings = {
  devicesListTitle: 'Select devices to add',
  appUnitsListTitle: 'Select apps to add',
  addedDevicesListTitle: 'Added devices',
  addedappUnitsListTitle: 'Added apps',
  selectAllBtn: 'Select All',
  unselectAllBtn: 'Unselect All'
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddNewDevice = (props: IAddNewDeviceProps) => {
  const displayAppUnits = props.selectedDevice.basicInfo.deviceType !== 5;
  const defaultValue = '-';
  const { appUnits, isOpen, handleClose, devices, saveNewDevicesAndApps } = props;
  const [devicesList, setDevicesList] = useState<IDevice[]>([]);
  const [appUnitsList, setAppUnitsList] = useState<IUnit[]>([]);
  const [selectedDevices, setSelectedDevices] = useState<GridRowsProp>([]);
  const [selectedAppUnits, setSelectedAppUnits] = useState<GridRowsProp>([]);

  const { t } = useTranslation();
  const noSelectedDevices = t('No_Selected_Devices');
  const buttonCancel = t('Shared.Cancel');
  const buttonSave = t('Button_Save');

  useEffect(() => {
    setDevicesList(devices);
    setAppUnitsList(appUnits);
  }, [devices, appUnits]);

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement>, params: IParams) => {
    event.stopPropagation();

    const updatedDevicesList = selectedDevices.filter((row) => row.publicId !== params.row.publicId);
    setDevicesList([...devicesList, params.row]);
    setSelectedDevices(updatedDevicesList);
  };

  const handleSelectDevice = (event: React.SyntheticEvent<IDevice>, value: IDevice) => {
    const updatedDevicesList = devicesList.filter((row: IDevice) => row.publicId !== value.publicId);
    setDevicesList(updatedDevicesList);
    setSelectedDevices([...selectedDevices, value]);
  };
  const handleSelectApp = (event: React.SyntheticEvent<IUnit>, value: IUnit) => {
    const updatedAppUnitsList = appUnitsList.filter((row: IUnit) => row.publicId !== value.publicId);
    setAppUnitsList(updatedAppUnitsList);
    setSelectedAppUnits([...selectedAppUnits, value]);
  };

  const handleSelectAllDevices = () => {
    setSelectedDevices([...selectedDevices, ...devicesList]);
    setDevicesList([]);
  };

  const handleSelectAllApps = () => {
    setSelectedAppUnits([...selectedAppUnits, ...appUnitsList]);
    setAppUnitsList([]);
  };

  const handleUnselectAll = () => {
    setDevicesList([...(selectedDevices as IDevice[]), ...devicesList]);
    setSelectedDevices([]);
  };

  const handleSave = () => {
    saveNewDevicesAndApps(selectedDevices as IDevice[], selectedAppUnits as IUnit[]);
    setSelectedDevices([]);
    setSelectedAppUnits([]);
    handleClose();
  };

  const handleCloseDialog = () => {
    setSelectedDevices([]);
    setSelectedAppUnits([]);
    handleClose();
  };

  const CustomNoRowsOverlay = () => (
    <GridOverlay>
      <Box sx={styles.gridOverlayBox}>{noSelectedDevices}</Box>
    </GridOverlay>
  );

  const columns: GridColDef[] = [
    {
      field: 'stationName',
      headerName: 'Station Name',
      width: 200,
      valueGetter: (params) => {
        return params.row.basicInfo.stationName || defaultValue;
      }
    },
    {
      field: 'stationNumber',
      headerName: 'Station Number',
      width: 200,
      valueGetter: (params) => {
        return params.row.basicInfo.stationNumber || defaultValue;
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params: IParams) => {
        return (
          <IconButton onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleDelete(event, params)}>
            <DeleteIcon color="error" />
          </IconButton>
        );
      }
    }
  ];

  const appColumns: GridColDef[] = [
    {
      field: 'unitName',
      headerName: 'App Name',
      width: 200,
      valueGetter: (params) => {
        return params.row.unitName || defaultValue;
      }
    },
    {
      field: 'unitNumber',
      headerName: 'App Number',
      width: 200,
      valueGetter: (params) => {
        params.row.unitNumber || defaultValue;
      }
    }
  ];

  return (
    <>
      <Dialog
        sx={styles.dialog}
        TransitionComponent={Transition}
        onClose={handleClose}
        open={isOpen}
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid container spacing={2}>
          <Grid item xs={6} sx={styles.selectDeviceWrapper}>
            <DialogTitle style={styles.dialogTitle}>{strings.devicesListTitle}</DialogTitle>
            <Button onClick={handleSelectAllDevices}>{strings.selectAllBtn}</Button>
            <Grid item style={styles.filterWrapper}>
              <SearchableDropdown selectDeviceHandler={handleSelectDevice} dropdownOptions={devicesList} />
            </Grid>
          </Grid>
          {displayAppUnits && (
            <Grid item xs={6} sx={styles.selectDeviceWrapper}>
              <DialogTitle style={styles.dialogTitle}>{strings.appUnitsListTitle}</DialogTitle>
              <Button onClick={handleSelectAllApps}>{strings.selectAllBtn}</Button>
              <Grid item style={styles.filterWrapper}>
                <SearchableDropdownApps selectDeviceHandler={handleSelectApp} dropdownOptions={appUnitsList} />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid container style={styles.selectedDevicesWrapper} spacing={2}>
          <Grid item xs={12}></Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={styles.selectDeviceWrapper}>
              <DialogTitle style={styles.dialogTitle}>{strings.addedDevicesListTitle}</DialogTitle>
              <Button onClick={handleUnselectAll} color="error">
                {strings.unselectAllBtn}
              </Button>
            </Box>
            {selectedDevices && (
              <DataGrid
                rows={selectedDevices}
                columns={columns}
                getRowId={(row) => row.publicId}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 }
                  }
                }}
                slots={{
                  noRowsOverlay: CustomNoRowsOverlay
                }}
                pageSizeOptions={[5, 10]}
                disableRowSelectionOnClick
                autoHeight
              />
            )}
          </Grid>
          {displayAppUnits && selectedAppUnits && (
            <Grid item xs={12} sm={6}>
              <Box sx={styles.selectDeviceWrapper}>
                <DialogTitle style={styles.dialogTitle}>{strings.addedappUnitsListTitle}</DialogTitle>
                <Button onClick={handleUnselectAll} color="error">
                  {strings.unselectAllBtn}
                </Button>
              </Box>
              <DataGrid
                rows={selectedAppUnits}
                columns={appColumns}
                getRowId={(row) => row.publicId}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 }
                  }
                }}
                slots={{
                  noRowsOverlay: CustomNoRowsOverlay
                }}
                pageSizeOptions={[5, 10]}
                disableRowSelectionOnClick
                autoHeight
              />
            </Grid>
          )}
        </Grid>
        <DialogActions sx={styles.dialogActionsWrapper}>
          <Button onClick={handleCloseDialog}>{buttonCancel}</Button>
          <Button onClick={handleSave}>{buttonSave}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const styles = {
  dialog: {
    '& .MuiDialog-paper': {
      width: '80%',
      height: '30%',
      minWidth: '400px',
      minHeight: '750px',
      maxWidth: '800px',
      maxHeight: '700px',
      padding: '0 15px',
      position: 'relative'
    }
  },
  filterWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  dialogTitle: {
    fontSize: '21px'
  },
  selectDeviceWrapper: {
    display: 'flex',
    //border: '1px solid #FF0000',
    flexDirection: 'column',
    alignItems: 'center'
  },
  selectedDevicesTitle: {
    fontSize: '21px',
    width: '100%'
  },
  selectedDevicesWrapper: {
    marginTop: '30px',
    height: '400px'
  },
  dialogActionsWrapper: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center'
  },
  gridOverlayBox: {
    textAlign: 'center',
    fontSize: '21px',
    color: 'silver'
  }
};

export default AddNewDevice;
