import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import {
  useBatchUpdateAppsMutation,
  useBatchUpdateDevicesMutation,
  useCreateAppMutation,
  useLazyGetAppWithSiteIdQuery,
  useLazyGetDeviceListWithSitePublicIdQuery,
  useLazyGetUnitListWithSitePublicIdQuery
} from 'services/aiphoneCloud';
import { LoadingButton } from '@mui/lab';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import DialogWrapper from 'shared/components/dialogs/DialogWrapper';
import { Trans, useTranslation } from 'react-i18next';
import useSharedStyles from 'styles/useSharedStyles';
import React, { SyntheticEvent, useState } from 'react';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { EnumList, fetchLocalEnumList } from 'shared/utils/EnumUtils';
import { Formik } from 'formik';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { DeviceGroups, GroupedDeviceTypes, MAX_APPS } from 'features/RemoteManagement/types/Types';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector } from 'store/hooks';
import { ThumbnailSize, useGenerateDeviceCard } from '../utils/GenerateDeviceCard';
import { IAppList } from 'store/slices/appsSlice';
import { DeviceUpdateHelper } from 'features/RemoteManagement/NewSiteWizard/utils/deviceUpdateHelpers';
import { DeviceList } from 'store/slices/devicesSlice';
import { IUnitList } from 'store/slices/unitsSlice';

interface IAssignDevicesToUnitsDialog {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleNextStep?: () => void;
}

const AssignDevicesToUnitsDialog = ({ isOpen, setIsOpen, handleNextStep }: IAssignDevicesToUnitsDialog) => {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const unitList = useAppSelector((state) => state.units.UnitList);
  const deviceList = useAppSelector((state) => state.devices.DeviceList);
  const tenantList = useAppSelector((state) => state.tenants.TenantList);
  const appList = useAppSelector((state) => state.apps.AppList);
  const appGroups = useAppSelector((state) => state.apps.AppGroup);
  const siteInfo = useAppSelector((state) => state.site.siteInfo);
  const users = useAppSelector((state) => state.users);
  const sitePublicId = useAppSelector((state) => state.site.siteInfo.publicId);
  const [fetchApps] = useLazyGetAppWithSiteIdQuery();
  const [fetchDevices] = useLazyGetDeviceListWithSitePublicIdQuery();
  const [fetchUnits] = useLazyGetUnitListWithSitePublicIdQuery();
  const [batchUpdateDevices] = useBatchUpdateDevicesMutation();
  const [batchUpdateApps] = useBatchUpdateAppsMutation();
  const [createApps] = useCreateAppMutation();
  const sharedStyle = useSharedStyles();
  const enumList: EnumList = fetchLocalEnumList();
  const generateDeviceCard = useGenerateDeviceCard();

  const lcDeviceType = parseInt(
    Object.keys(enumList.deviceType).find((key) => enumList.deviceType[key].value === 'IXGW-LC') as string
  );
  const gwDeviceType = parseInt(
    Object.keys(enumList.deviceType).find((key) => enumList.deviceType[key].value === 'IXGW-GW') as string
  );
  const maDeviceType = parseInt(
    Object.keys(enumList.deviceType).find((key) => enumList.deviceType[key].value === 'IXW-MA') as string
  );
  const faDeviceType = parseInt(
    Object.keys(enumList.deviceType).find((key) => enumList.deviceType[key].value === 'IX-FA') as string
  );
  const unsupportedDeviceTypes = [lcDeviceType, gwDeviceType, maDeviceType, faDeviceType];

  const insideAreaUnitType = parseInt(
    Object.keys(enumList.unitType).find((key) => enumList.unitType[key].value === 'InsideArea') as string
  );
  const unsupportedUnitTypes = [insideAreaUnitType];

  const unitListKeys = Object.keys(unitList)
    .filter((key) => !unsupportedUnitTypes.includes(unitList[key].unitType))
    .sort((a, b) => {
      return unitList[a].unitNumber.localeCompare(unitList[b].unitNumber);
    });
  const deviceListKeys = Object.keys(deviceList)
    .filter((key) => !unsupportedDeviceTypes.includes(deviceList[key].basicInfo.deviceType))
    .sort((a, b) => {
      return deviceList[a].basicInfo.stationNumber.localeCompare(deviceList[b].basicInfo.stationNumber);
    });
  const tenantListKeys = Object.keys(tenantList);
  const appListKeys = Object.keys(appList);

  interface unitRow {
    id: number;
    publicId: string;
    unitType: number;
    unitName: string;
    unitNumber: string;
    devices: string[];
    tenants: number;
    apps: number;
  }

  const [unitRows, setUnitRows] = useState<unitRow[]>(
    unitListKeys.map((key, index) => {
      return {
        id: index,
        publicId: key,
        unitType: unitList[key].unitType,
        unitName: unitList[key].unitName,
        unitNumber: unitList[key].unitNumber,
        devices: deviceListKeys.filter((deviceKey) => deviceList[deviceKey].unitPublicId === key),
        tenants: tenantListKeys.filter((tenantKey) => tenantList[tenantKey].unitPublicId === key).length,
        apps: appListKeys.filter((appKey) => appList[appKey].unitPublicId === key).length
      };
    })
  );

  const { t } = useTranslation();
  const dialogTitleStr = t('Site.Configure.Title');
  const dialogSubtitleStr = t('Site.Configure.AssignStations.Title');
  const availableDevicesStr = t('Site.Configure.AssignStations.AvailableStations');
  const chooseDevicesStr = t('Site.Configure.AssignStations.ChooseStations');
  const automaticallyAssignStr = t('Site.Configure.AssignStations.AutomaticallyAssigned');
  const removeDeviceStr = t('Site.Configure.AssignStations.RemoveStation');
  const assignSelectedDevicesStr = t('Site.Configure.AssignStations.AssignSelectedStations');
  const toggleSelectionStr = t('Site.Configure.AssignStations.ToggleSelection');
  const unitNameStr = t('Unit.Name');
  const unitNumberStr = t('Unit.Number');
  const devicesStr = t('Station.Station', { count: 2 });
  const tenantsStr = t('Tenant.Tenant', { count: 2 });
  const appsStr = t('App.App', { count: 2 });
  const assignStr = t('Shared.Assign');
  const unitsCountStr = t('Unit.Count', { count: unitListKeys.length });
  const saveChangesStr = t('Shared.SaveChanges');
  const unitTypesAllStr = t('Unit.Types.All');
  const unitTypeResidentialStr = t('Unit.Types.Residential.Name');
  const unitTypeCommercialStr = t('Unit.Types.Commercial.Name');
  const unitTypeGuardStr = t('Unit.Types.Guard.Name');
  const noStr = t('Shared.No');
  const createAppStr = t('API.Action.CreateApp');
  const fetchAppsActionStr = t('API.Action.FetchApps');
  const fetchDevicesActionStr = t('API.Action.FetchDevices');
  const createAppErrorStr = t('API.Error.Generic', { action: createAppStr });
  const actionSuccessStr = t('API.ActionSuccess', { action: dialogSubtitleStr });
  const fetchAppsErrorStr = t('API.Error.Generic', { action: fetchAppsActionStr });
  const fetchDevicesErrorStr = t('API.Error.Generic', { action: fetchDevicesActionStr });
  const mobileAppStr = t('App.IXG_Mobile_App');

  interface mobileAppTypeOption {
    unitTypes: number[];
    value: string;
  }

  interface unitRowParams {
    row: unitRow;
  }

  interface deviceCountPerUnitType {
    entrance: number;
    door: number;
    tenant: number;
    subMaster: number;
    master: number;
    guard: number;
  }

  const mobileAppTypeOptions: mobileAppTypeOption[] = [
    {
      unitTypes: Object.keys(enumList.unitType)
        .filter(
          (key) =>
            enumList.unitType[key].value === 'Residential' ||
            enumList.unitType[key].value === 'Commercial' ||
            enumList.unitType[key].value === 'Guard'
        )
        .map((key) => parseInt(key)),
      value: unitTypesAllStr.toLocaleLowerCase()
    },
    {
      unitTypes: Object.keys(enumList.unitType)
        .filter((key) => enumList.unitType[key].value === 'Residential')
        .map((key) => parseInt(key)),
      value: unitTypeResidentialStr.toLocaleLowerCase()
    },
    {
      unitTypes: Object.keys(enumList.unitType)
        .filter((key) => enumList.unitType[key].value === 'Commercial')
        .map((key) => parseInt(key)),
      value: unitTypeCommercialStr.toLocaleLowerCase()
    },
    {
      unitTypes: Object.keys(enumList.unitType)
        .filter((key) => enumList.unitType[key].value === 'Guard')
        .map((key) => parseInt(key)),
      value: unitTypeGuardStr.toLocaleLowerCase()
    },
    {
      unitTypes: [],
      value: noStr.toLocaleLowerCase()
    }
  ];

  const maxDevicesPerUnitType: {
    [key: string]: deviceCountPerUnitType;
  } = {};

  Object.keys(enumList.unitType).forEach((key) => {
    switch (enumList.unitType[key].value) {
      case 'Guard':
        maxDevicesPerUnitType[key] = {
          entrance: 0,
          door: 2,
          guard: 8,
          tenant: 0,
          master: 0,
          subMaster: 8
        };
        break;
      case 'Entrance':
        maxDevicesPerUnitType[key] = {
          entrance: 10,
          door: 0,
          guard: 0,
          tenant: 0,
          master: 0,
          subMaster: 0
        };
        break;
      case 'Residential':
        maxDevicesPerUnitType[key] = {
          entrance: 0,
          door: 2,
          guard: 0,
          tenant: 8,
          master: 0,
          subMaster: 8
        };
        break;
      case 'Commercial':
        maxDevicesPerUnitType[key] = {
          entrance: 0,
          door: 2,
          guard: 0,
          tenant: 0,
          master: 8,
          subMaster: 8
        };
        break;
      case 'OutsideArea':
        maxDevicesPerUnitType[key] = {
          entrance: 0,
          door: 10,
          guard: 0,
          tenant: 0,
          master: 0,
          subMaster: 8
        };
        break;
      case 'InsideArea':
      default:
        break;
    }
  });

  function canUnitFitDevices(selectedDevices: string[], unitInfo: unitRow): boolean {
    const deviceCount: deviceCountPerUnitType = {
      entrance: 0,
      door: 0,
      guard: 0,
      tenant: 0,
      master: 0,
      subMaster: 0
    };

    if (unsupportedUnitTypes.includes(unitInfo.unitType) || selectedDevices.length === 0) {
      return false;
    }

    [...selectedDevices, ...unitInfo.devices].forEach((deviceWalker) => {
      const deviceType = deviceList[deviceWalker].basicInfo.deviceType;

      if (GroupedDeviceTypes[DeviceGroups.EntranceStation].includes(deviceType)) {
        deviceCount.entrance++;
      }

      if (
        GroupedDeviceTypes[DeviceGroups.AudioDoorStation].includes(deviceType) ||
        GroupedDeviceTypes[DeviceGroups.VideoDoorStation].includes(deviceType)
      ) {
        deviceCount.door++;
      }

      if (GroupedDeviceTypes[DeviceGroups.TenantStation].includes(deviceType)) {
        deviceCount.tenant++;
      }

      if (GroupedDeviceTypes[DeviceGroups.MasterStation].includes(deviceType)) {
        deviceCount.master++;
      }

      if (GroupedDeviceTypes[DeviceGroups.GuardStation].includes(deviceType)) {
        deviceCount.guard++;
      }

      if (GroupedDeviceTypes[DeviceGroups.SubMasterStation].includes(deviceType)) {
        deviceCount.subMaster++;
      }
    });

    if (deviceCount.entrance > maxDevicesPerUnitType[unitInfo.unitType].entrance) {
      return false;
    }

    if (deviceCount.door > maxDevicesPerUnitType[unitInfo.unitType].door) {
      return false;
    }

    if (
      deviceCount.tenant &&
      deviceCount.tenant + deviceCount.subMaster > maxDevicesPerUnitType[unitInfo.unitType].tenant
    ) {
      return false;
    }

    if (
      deviceCount.master &&
      deviceCount.master + deviceCount.subMaster > maxDevicesPerUnitType[unitInfo.unitType].master
    ) {
      return false;
    }

    if (
      deviceCount.guard &&
      deviceCount.guard + deviceCount.subMaster > maxDevicesPerUnitType[unitInfo.unitType].guard
    ) {
      return false;
    }

    return true;
  }

  const assignButtonContainerStyle = [sharedStyle.common.padding.sm];

  interface formVals {
    mobileAppTypeOption: number;
    selectedDevices: string[];
  }

  const initialValues: formVals = {
    mobileAppTypeOption: mobileAppTypeOptions.findIndex((option) => option.unitTypes.length === 0),
    selectedDevices: []
  };

  async function fetchAppsAndDevices() {
    await fetchApps({
      sitePublicId: sitePublicId,
      qty: -1,
      page: 0
    }).catch(() => {
      setError(fetchAppsErrorStr);
    });
    await fetchDevices({
      sitePublicId: sitePublicId,
      qty: -1,
      page: 0
    }).catch(() => {
      setError(fetchDevicesErrorStr);
    });
  }

  async function createAppsForUnits(mobileAppTypeOption: number) {
    for (let unitWalker = 0; unitWalker < unitListKeys.length; unitWalker++) {
      if (mobileAppTypeOptions[mobileAppTypeOption].unitTypes.includes(unitList[unitListKeys[unitWalker]].unitType)) {
        const unitAppStationNumbers: string[] = [];
        appListKeys
          .filter((key) => appList[key].unitPublicId === unitListKeys[unitWalker])
          .forEach((key) => {
            unitAppStationNumbers.push(appList[key].stationNumber);
          });
        unitAppStationNumbers.sort();

        const startingStationNumber = unitList[unitListKeys[unitWalker]].unitNumber.padStart(10, '0');

        let numberAppend = 0;
        for (let appWalker = 0; appWalker < MAX_APPS - unitAppStationNumbers.length; appWalker++) {
          let appStationNumber: string;

          do {
            ++numberAppend;
            appStationNumber = startingStationNumber + numberAppend.toString().padStart(2, '0');
          } while (appListKeys.find((key) => appList[key].stationNumber === appStationNumber));

          const newApp = {
            appData: {
              stationNumber: appStationNumber,
              stationName: `MobileApp${numberAppend}`,
              unitPublicId: unitListKeys[unitWalker]
            }
          };
          try {
            await createApps(newApp).unwrap();
          } catch (error) {
            setError(createAppErrorStr);
            return;
          }
        }
      }
    }

    fetchAppsAndDevices();
  }

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  async function handleSubmit(values: formVals) {
    setIsLoading(true);

    // add the devices that were given new unit assignments
    const devicesToUpdate: {
      sitePublicId: string;
      publicId: string;
      unitPublicId: string | null;
      stationNumber: string;
    }[] = unitRows.flatMap((unit) => {
      return unit.devices.map((publicId) => {
        return {
          sitePublicId: sitePublicId,
          publicId: publicId,
          unitPublicId: unit.publicId,
          stationNumber: deviceList[publicId].basicInfo.stationNumber
        };
      });
    });

    // Assume any supported devices still in the available devices list are unassigned
    deviceListKeys
      .filter((key: string) => devicesToUpdate.find((device) => device.publicId === key) === undefined)
      .forEach((key: string) => {
        devicesToUpdate.push({
          sitePublicId: sitePublicId,
          publicId: key,
          unitPublicId: null,
          stationNumber: deviceList[key].basicInfo.stationNumber
        });
      });

    await batchUpdateDevices({ devices: devicesToUpdate });
    await createAppsForUnits(values.mobileAppTypeOption);

    //
    const devices = await fetchDevices({
      sitePublicId: sitePublicId,
      qty: -1,
      page: 0
    }).unwrap();

    const units = await fetchUnits({
      sitePublicId: sitePublicId,
      qty: -1,
      page: 0
    }).unwrap();

    const apps = await fetchApps({
      sitePublicId: sitePublicId,
      qty: 500,
      page: 0
    }).unwrap();

    await configureSystem(devices.deviceList, units.unitList, apps.appList);

    setSuccess(actionSuccessStr);
    setIsLoading(false);
    handleNextStep ? handleNextStep() : setIsOpen(false);
  }

  const configureSystem = async (deviceList: DeviceList, unitList: IUnitList, appList: IAppList) => {
    if (siteInfo.typeId === 2) {
      const system = new DeviceUpdateHelper(
        deviceList,
        unitList,
        appGroups,
        appList,
        siteInfo.typeId,
        siteInfo.publicId
      );

      system.initialize(siteInfo, users.currentUser?.publicId || '');

      try {
        await system.configureAddressBook();
        await system.configureCallSettings();
        await system.configureDoorRelease();
        await system.configureAppAddressBook();

        await batchUpdateDevices(system.getPayload()).unwrap();
        await batchUpdateApps(system.getAppPayload()).unwrap();

        fetchDevices({ sitePublicId: sitePublicId, qty: -1, page: 0 });
      } catch (error: any) {
        console.error('Error configuring system:', error);
      }
    }
  };

  const formContainerStyle = [
    sharedStyle.gridContainer,
    sharedStyle.gridContainer.row,
    sharedStyle.common.gap.xs,
    {
      height: '25rem',
      flexWrap: 'nowrap'
    }
  ];

  const mobileAppStrStyle = {
    display: 'flex',
    alignItems: 'center'
  };

  const mobileAppSelectStyle = {
    width: '8.5rem',
    px: '0.25rem'
  };

  const formStyle = [
    sharedStyle.infoBox,
    sharedStyle.infoBox.form,
    sharedStyle.common.height.full,
    {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    }
  ];

  const unitContainerStyle = [sharedStyle.common.height.full, sharedStyle.flexContainer.fillWithNoOverflow];

  const availableDevicesContentStyle = [
    sharedStyle.gridContainer,
    sharedStyle.common.gap.none,
    {
      overflowY: 'scroll',
      flexWrap: 'nowrap'
    }
  ];

  const unitGridStyle = [sharedStyle.flexContainer.fillWithNoOverflow];

  const availableDeviceOptionStyle = [
    sharedStyle.common.margin.none,
    sharedStyle.common.padding.sm,
    {
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      '& .MuiTypography-root': sharedStyle.flexContainer.fillWithNoOverflow
    }
  ];

  const dataGridStyle = {
    '& .MuiDataGrid-cell': {
      minHeight: '52px !important', // Datagrid is adding a min-height inline style, so make this important
      whiteSpace: 'nowrap'
    }
  };

  const assignedContainerStyle = [
    sharedStyle.gridContainer,
    sharedStyle.gridContainer.row,
    sharedStyle.common.gap.none,
    {
      flexWrap: 'nowrap',
      justifyContent: 'space-between'
    }
  ];

  const closeIconContainer = {
    display: 'flex',
    alignItems: 'center'
  };

  const closeIcon = {
    fontSize: '1.125rem'
  };

  const cardContainerStyle = [sharedStyle.flexContainer.fillWithNoOverflow];

  return (
    <>
      <DialogWrapper
        header={dialogTitleStr}
        subheader={dialogSubtitleStr}
        open={isOpen}
        setIsOpen={setIsOpen}
        onClose={handleCloseDialog}
        maxWidth="lg"
        fullWidth
      >
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ handleChange, handleBlur, values, setFieldValue, submitForm }) => {
            const mobileAppSelectElem = (
              <TextField
                select
                sx={mobileAppSelectStyle}
                name="mobileAppTypeOption"
                value={values.mobileAppTypeOption}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                size="small"
              >
                {mobileAppTypeOptions.map((option, index) => (
                  <MenuItem key={option.value} value={index}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
            );

            const mobileAppsSelectStr = (
              <Trans
                t={t}
                i18nKey="Site.Configure.AssignStations.MobileAppSelect"
                components={{ select: mobileAppSelectElem }}
              />
            );

            function handleDeviceSelect(event: SyntheticEvent<Element, Event>, checked: boolean) {
              const devicePublicId = (event.target as HTMLInputElement).name;

              if (checked) {
                setFieldValue('selectedDevices', [...values.selectedDevices, devicePublicId]);
              } else {
                setFieldValue(
                  'selectedDevices',
                  values.selectedDevices.filter((publicId) => publicId !== devicePublicId)
                );
              }
            }

            function handleAssign(event: React.MouseEvent<HTMLButtonElement>, params: unitRowParams) {
              if (canUnitFitDevices(values.selectedDevices, params.row)) {
                const newUnitRows = [...unitRows];
                newUnitRows[params.row.id].devices = [...params.row.devices, ...values.selectedDevices];
                setUnitRows(newUnitRows);
                setFieldValue('selectedDevices', []);
              }
            }

            function handleDeviceRemove(unitRow: unitRow, deviceKey: string) {
              const newUnitRows = [...unitRows];
              newUnitRows[unitRow.id].devices = unitRow.devices.filter((key) => key !== deviceKey);
              setUnitRows(newUnitRows);
            }

            const unitGridColumns: GridColDef[] = [
              { field: 'unitName', headerName: unitNameStr, flex: 0.43 },
              { field: 'unitNumber', headerName: unitNumberStr, flex: 0.47 },
              {
                field: 'devices',
                headerName: devicesStr,
                flex: 1,
                renderCell: (params: unitRowParams) => {
                  return (
                    <Grid container sx={[sharedStyle.gridContainer, sharedStyle.common.gap.sm]}>
                      {params.row.devices.map((deviceKey) => (
                        <Grid key={deviceKey} container sx={assignedContainerStyle}>
                          <Grid item sx={cardContainerStyle}>
                            {generateDeviceCard(deviceKey, deviceList, ThumbnailSize.sm)}
                          </Grid>
                          <Grid item sx={closeIconContainer}>
                            <IconButton
                              aria-label={removeDeviceStr}
                              onClick={() => {
                                handleDeviceRemove(params.row, deviceKey);
                              }}
                            >
                              <CloseIcon sx={closeIcon} />
                            </IconButton>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  );
                }
              },
              { field: 'tenants', headerName: tenantsStr, flex: 0.36 },
              { field: 'apps', headerName: appsStr, flex: 0.29 },
              {
                field: 'actions',
                type: 'actions',
                flex: 0.41,
                sortable: false,
                filterable: false,
                renderCell: (params: unitRowParams) => {
                  return (
                    <>
                      {canUnitFitDevices(values.selectedDevices, params.row) && (
                        <Box sx={assignButtonContainerStyle}>
                          <Button
                            variant="outlined"
                            aria-label={assignSelectedDevicesStr}
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleAssign(event, params)}
                          >
                            {assignStr}
                          </Button>
                        </Box>
                      )}
                    </>
                  );
                }
              }
            ];

            function generateUnitGrid() {
              return (
                <DataGrid
                  columns={unitGridColumns}
                  rows={unitRows}
                  getRowHeight={() => 'auto'}
                  sx={dataGridStyle}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 }
                    }
                  }}
                  pageSizeOptions={[5, 10, 25]}
                />
              );
            }

            return (
              <Grid container sx={[sharedStyle.gridContainer, sharedStyle.common.gap.sm]}>
                <Typography variant="h6">{chooseDevicesStr}</Typography>
                <Typography variant="body1" color="textPrimary">
                  {automaticallyAssignStr}
                </Typography>
                <Grid item sx={[sharedStyle.infoBox, sharedStyle.common.width.full]}>
                  <Grid
                    container
                    item
                    sx={[sharedStyle.gridContainer, sharedStyle.gridContainer.row, sharedStyle.common.gap.sm]}
                  >
                    <Box sx={[sharedStyle.imgThumbnail, sharedStyle.imgThumbnail.lg, sharedStyle.common.width.fit]}>
                      <Box
                        component="img"
                        src={`${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/ixg-mobile-app.png`}
                        alt={mobileAppStr}
                      />
                    </Box>
                    <Box sx={mobileAppStrStyle}>{mobileAppsSelectStr}</Box>
                  </Grid>
                </Grid>
                <Grid container item sx={formContainerStyle}>
                  <Grid item sm={4} md={3} sx={sharedStyle.common.height.full}>
                    <Box sx={formStyle}>
                      <Box sx={[sharedStyle.inlineBox, sharedStyle.infoBox.form.header, sharedStyle.common.padding.sm]}>
                        <PhoneIphoneIcon />
                        <Typography variant="body1">{availableDevicesStr}</Typography>
                      </Box>
                      <Grid container sx={availableDevicesContentStyle}>
                        {deviceListKeys
                          .filter((key) => {
                            let ret = true;
                            unitRows.forEach((unitRow) => {
                              if (unitRow.devices.includes(key)) {
                                ret = false;
                                return;
                              }
                            });

                            return ret;
                          })
                          .map((key) => (
                            <FormControlLabel
                              sx={availableDeviceOptionStyle}
                              key={key}
                              name={key}
                              control={<Checkbox />}
                              labelPlacement="start"
                              checked={values.selectedDevices.includes(key)}
                              aria-label={toggleSelectionStr}
                              onChange={handleDeviceSelect}
                              label={generateDeviceCard(key, deviceList, ThumbnailSize.md)}
                            />
                          ))}
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item sm={8} md={9} sx={unitContainerStyle}>
                    <Box sx={formStyle}>
                      <Box sx={[sharedStyle.inlineBox, sharedStyle.infoBox.form.header, sharedStyle.common.padding.sm]}>
                        <Typography variant="body1">{unitsCountStr}</Typography>
                      </Box>
                      <Grid container sx={unitGridStyle}>
                        {generateUnitGrid()}
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
                <DialogActions sx={sharedStyle.common.padding.none}>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={submitForm}
                    loading={isLoading}
                  >
                    {saveChangesStr}
                  </LoadingButton>
                </DialogActions>
              </Grid>
            );
          }}
        </Formik>
      </DialogWrapper>
      <SnackbarAlert type="error" time={3000} text={error ?? ''} isOpen={!!error} onClose={() => setError(null)} />
      <SnackbarAlert
        type="success"
        time={3000}
        text={success ?? ''}
        isOpen={!!success}
        onClose={() => setSuccess(null)}
      />
    </>
  );
};

export default AssignDevicesToUnitsDialog;
