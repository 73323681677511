import { useState } from 'react';
import { Box } from '@mui/material';
import AppHeader from '../../layouts/AppHeader';
import { Outlet } from 'react-router-dom';
import AppSidebar from 'layouts/sidebar/AppSidebar';

function Dashboard() {
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);

  if (document.getElementById('wholeSections') !== null) {
    const wholeSections = document.getElementById('wholeSections');
    if (wholeSections !== null) {
      wholeSections.scrollTop = 0;
    }
  }

  return (
    <>
      <AppHeader collapsed={collapsed} setCollapsed={setCollapsed} toggled={toggled} setToggled={setToggled} />
      <Box id="wholeSections" sx={styles.container}>
        <>
          <AppSidebar collapsed={collapsed} toggled={toggled} />
          <Box component={'main'} sx={styles.mainSection}>
            <Outlet />
          </Box>
        </>
      </Box>
    </>
  );
}

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  container: {
    display: 'flex',
    height: 'calc(100% - 64px)',
    overflow: 'auto'
  },
  mainSection: {
    width: '100%',
    height: '100%',
    overflowX: 'hidden'
  }
};

export default Dashboard;
