import { Box, CircularProgress, Stack } from '@mui/material';

const LoadingSpinner = () => {
  return (
    <Box sx={styles.loadingSpinnerContainer}>
      <Stack direction="row" spacing={2} alignItems="center">
        <CircularProgress color="primary" />
      </Stack>
    </Box>
  );
};

export default LoadingSpinner;
const styles = {
  loadingSpinnerContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    zIndex: 1300,
    cursor: 'wait'
  }
};
