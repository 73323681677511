import React from 'react';
import {
  DataGrid,
  GridCellModesModel,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridRowId,
  GridRowModel,
  GridRowsProp,
  MuiEvent,
  useGridApiRef
} from '@mui/x-data-grid';
import {
  DEFAULT_RELAYS,
  handleCellModesModelChange,
  handleCellClickToView,
  handleRowClick,
  TimeSelectorType,
  basicContactOutputs,
  defaultContactOutputState
} from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/common';
import { RelayDelayColHeader } from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/headers/RelayDelayColHeader';
import StationNameNumberCell from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/cells/StationNameNumberCell';
import CheckedCheckbox from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/cells/CheckedCheckbox';
import { useTranslation } from 'react-i18next';
import CustomGridToolbarWithToggle from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/headers/CustomGridToolbarWithToggle';
import { IDeviceContactOutput, ILiftControlUnitOperation } from 'store/slices/devicesSlice';
import { Check } from '@mui/icons-material';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { IUnit } from 'store/slices/unitsSlice';

interface ArrivalLCProps {
  isSubmitting: boolean;
  selectedDevicePublicId: string;
  updateLiftControlDevice: (payload: any) => Promise<void>;
  handleUpdateRowField: (
    id: GridRowId,
    field: string,
    newValue: any,
    setRows: (
      value: ((prevState: readonly GridRowModel[]) => readonly GridRowModel[]) | readonly GridRowModel[]
    ) => void | undefined
  ) => void;
  buildingPublicId: string;
  handleUpdateRelayDelay(
    relaysRef: React.MutableRefObject<IDeviceContactOutput[]>,
    params: GridColumnHeaderParams,
    newRelayMS: number
  ): void;
}

const ArrivalLC = ({
  isSubmitting,
  selectedDevicePublicId,
  updateLiftControlDevice,
  handleUpdateRowField,
  handleUpdateRelayDelay,
  buildingPublicId
}: ArrivalLCProps) => {
  const initialData = React.useRef<GridRowsProp>([]);
  const dataGridRef = React.useRef<HTMLDivElement>(null);
  const apiRef = useGridApiRef();
  const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const deviceList = useSelector((state: RootState) => state.devices.DeviceList);
  const unitsList = useSelector((state: RootState) => state.units.UnitList);
  const unitsListByType = useSelector((state: RootState) => state.units.UnitListByType) ?? {
    Guard: [],
    Residential: [],
    Commercial: [],
    Entrance: []
  };

  // Populate Arrival units array
  const guardUnits: string[] = unitsListByType['Guard'] ?? [];
  const residentialUnits: string[] = unitsListByType['Residential'] ?? [];
  const commercialUnits: string[] = unitsListByType['Commercial'] ?? [];
  const arrivalFloorUnits: string[] = [...guardUnits, ...residentialUnits, ...commercialUnits];
  const selectedDevice = deviceList[selectedDevicePublicId ?? ''];

  // Get the device type
  const deviceType: number = selectedDevice?.basicInfo?.deviceType;
  // Get the device model
  const deviceModel: number = selectedDevice?.basicInfo?.deviceModel;
  // Determine which regionCodeType of device we are working with here to properly render the text.
  const deviceIsRY20 = deviceType === 17 && deviceModel === 2;

  const relays = React.useMemo(() => {
    return (selectedDevice.contactOutputList as IDeviceContactOutput[]) ?? DEFAULT_RELAYS;
    // Force update should be triggered by the update to deviceList
  }, [selectedDevice.contactOutputList, deviceList]);

  const relaysRef = React.useRef<IDeviceContactOutput[]>(relays);
  const arrivalFloorData = (): ILiftControlUnitOperation[] => {
    const departureRoom: ILiftControlUnitOperation[] =
      deviceList[selectedDevicePublicId].liftControlSettings?.operation.departureRoom ?? [];

    // Sort the arrivalFloorUnits array based on unit numbers
    const sortedArrivalFloorUnits = arrivalFloorUnits.sort((a, b) => {
      const unitA = unitsList[a];
      const unitB = unitsList[b];
      return unitA.unitNumber.localeCompare(unitB.unitNumber, undefined, { numeric: true });
    });

    return sortedArrivalFloorUnits
      .map((unitId) => {
        const unit: IUnit = unitsList[unitId];

        const unitPublicId = unit.publicId;

        // For units that do not have the same buildingPublicId, return null
        // Then we will filter out the values that do have units (non-null values) to return
        if (unit.buildingPublicId !== buildingPublicId) {
          return null;
        }

        const rowUnit = departureRoom.find((liftControlUnit) => liftControlUnit.targetUnitPublicId === unitPublicId);
        if (rowUnit) {
          return {
            ...rowUnit,
            id: unitPublicId,
            unitName: unit.unitName,
            unitNumber: unit.unitNumber
          };
        } else {
          return {
            ...defaultContactOutputState,
            targetUnitPublicId: unitPublicId,
            id: unitPublicId,
            unitName: unit.unitName,
            unitNumber: unit.unitNumber
          };
        }
      })
      .filter((unit) => unit !== null) as ILiftControlUnitOperation[];
  };

  // Make sure to explicitly call this before assigning in the useState function.
  const floors = arrivalFloorData();

  const { t } = useTranslation();
  const saveChangesStr = t('Shared.SaveChanges');

  const [timeSelectorType, setTimeSelectorType] = React.useState<TimeSelectorType>('s');

  const [rows, setRows] = React.useState<GridRowsProp>(floors);

  const columns: GridColDef[] = React.useMemo(() => {
    const firstColumn: GridColDef = {
      field: 'Units',
      editable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 160,
      pinnable: true,
      groupable: false,
      sortable: false,
      disableReorder: true,
      hideSortIcons: true,
      filterable: false,
      headerClassName: 'font-bold unselectable pl_16px',
      headerName: t('Unit.Unit', { count: 2 }),
      renderCell: (params: GridRenderCellParams) => {
        return <StationNameNumberCell title={params.row.unitName} subtitle={params.row.unitNumber} />;
      },
      renderEditCell: (params: GridRenderCellParams) => {
        return <StationNameNumberCell title={params.row.unitName} subtitle={params.row.unitNumber} />;
      },
      cellClassName: 'unselectable m-0 p-0'
    };

    const relayCols: GridColDef[] = relays.map((relay, index) => {
      const relayNum = index + 1;
      return {
        field: `contactOutput${relayNum}Enabled`,
        sortable: false,
        disableReorder: true,
        hideSortIcons: true,
        filterable: false,
        pinnable: true,
        groupable: false,
        disableColumnMenu: true,
        type: 'boolean',
        editable: true,
        headerClassName: 'm-0 p-0',
        hideable: false,
        cellClassName: 'm-0 p-0',
        renderHeader: (params: GridColumnHeaderParams) => {
          return (
            <RelayDelayColHeader
              params={params}
              timeType={timeSelectorType}
              headerLabel={`${t('Relay')} ${relayNum}`}
              value={relay.timer}
              onValueChange={(newValue: number) => {
                handleUpdateRelayDelay(relaysRef, params, newValue);
              }}
              suffix={timeSelectorType.toString()}
            />
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CheckedCheckbox
              key={params.id}
              initialChecked={params.row[params.field] || false}
              onToggle={async (newChecked: boolean) => {
                // Update the state of rows
                handleUpdateRowField(params.id, params.field, newChecked, setRows);
                // Update the DataGrid state
                await params.api.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: newChecked
                });
              }}
              {...params}
            />
          );
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return (
            <CheckedCheckbox
              key={params.id}
              initialChecked={params.row[params.field] || false}
              onToggle={async (newChecked: boolean) => {
                // Update the state of rows

                handleUpdateRowField(params.id, params.field, newChecked, setRows);
                // Update the DataGrid state
                await params.api.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: newChecked
                });
              }}
              {...params}
            />
          );
        }
      };
    });

    return [firstColumn, ...relayCols];
  }, [handleUpdateRelayDelay, handleUpdateRowField, t, timeSelectorType, relays]);

  const submitUpdateDevice = React.useCallback(async () => {
    const departureRoom = [];
    // Go through the rows and get the Unit Public Id, and the contactOutput<n>Enabled setting
    const rows = apiRef.current?.getRowModels();

    // Prep payloads
    if (rows) {
      for (const row of rows.values()) {
        const targetUnitPublicId = row.targetUnitPublicId as string;
        const contactOutputs: Record<string, string | boolean> = {};
        for (const contactOutputKey of basicContactOutputs) {
          contactOutputs[contactOutputKey] = row[contactOutputKey] as boolean;
        }
        contactOutputs['targetUnitPublicId'] = targetUnitPublicId;

        departureRoom.push(contactOutputs);
      }
    }

    const existingData = deviceList[selectedDevicePublicId];
    const payload = {
      device: {
        publicId: selectedDevicePublicId,
        contactOutputList: relaysRef.current,
        liftControlSettings: {
          operation: {
            ...existingData.liftControlSettings.operation,
            departureRoom
          },
          devicePublicId: selectedDevicePublicId
        }
      }
    };

    // Handling try catch in updateLiftControlDevice
    await updateLiftControlDevice(payload);
  }, [updateLiftControlDevice, selectedDevicePublicId, relaysRef, rows]);

  React.useEffect(() => {
    setIsLoading(true);
    const initialRowData = arrivalFloorData();
    // Use this to compare the state on component dismount
    initialData.current = initialRowData;
    setRows(initialRowData);
    setIsLoading(false);
  }, [deviceList]);

  return (
    <>
      <DataGrid
        sx={{
          overflowX: 'scroll'
        }}
        components={{
          Toolbar: () => (
            <CustomGridToolbarWithToggle
              isSubmitting={isSubmitting}
              description={
                deviceIsRY20 ? t('ARRIVAL_LC_IXGW_LC_RY20_text_description') : t('ARRIVAL_LC_IXGW_LC_text_description')
              }
              valueToggle={
                // We need to set the opposite value of the current timeSelectorType
                // because the toggle will change the value after the state is updated
                [
                  { value: 's', label: t('Seconds_Text') },
                  { value: 'ms', label: t('Milliseconds_Text') }
                ]
              }
              buttonLeftIcon={<Check />}
              label={''}
              buttonText={saveChangesStr}
              submitHandler={async () => await submitUpdateDevice()}
              selectedValue={timeSelectorType === 's' ? t('Seconds_Text') : t('Milliseconds_Text')}
              handleSelectChange={(event) => {
                setTimeSelectorType(event.target.value as TimeSelectorType);
              }}
            />
          )
        }}
        cellModesModel={cellModesModel}
        onRowClick={(params, event) => handleRowClick(params, event as unknown as MuiEvent<MouseEvent>)}
        onCellModesModelChange={(cellModesModel) => handleCellModesModelChange(cellModesModel, setCellModesModel)}
        showCellVerticalBorder={true}
        showColumnVerticalBorder={true}
        columns={columns}
        autoHeight={true}
        rows={rows}
        ref={dataGridRef}
        apiRef={apiRef}
        onCellClick={(params, event) => handleCellClickToView(params, event as React.MouseEvent, setCellModesModel)}
        loading={isLoading}
      />
    </>
  );
};

export default ArrivalLC;
