import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { toast } from 'react-toastify';

// Load translations from common.json and simbillings.json files in each language folder
import enTranslation from './localization/locales/en/common.json';
import frTranslation from './localization/locales/fr/common.json';
import esTranslation from './localization/locales/es/common.json';
import jaTranslation from './localization/locales/ja/common.json';

import enSimbillings from './localization/locales/en/simbillings.json';
import frSimbillings from './localization/locales/fr/simbillings.json';
import esSimbillings from './localization/locales/es/simbillings.json';
import jaSimbillings from './localization/locales/ja/simbillings.json';

import enAcNioLicenseManagement from './localization/locales/en/acNioLicenseManagement.json';
import frAcNioLicenseManagement from './localization/locales/fr/acNioLicenseManagement.json';
import esAcNioLicenseManagement from './localization/locales/es/acNioLicenseManagement.json';
import jaAcNioLicenseManagement from './localization/locales/ja/acNioLicenseManagement.json';

import enQuikspec from './localization/locales/en/quikspec.json'
import frQuikspec from './localization/locales/fr/quikspec.json'
import esQuikspec from './localization/locales/es/quikspec.json'
import jaQuikspec from './localization/locales/ja/quikspec.json'

//Next-IX
import enNextIX from './localization/locales/en/next-ix.json'
import frNextIX from './localization/locales/fr/next-ix.json'
import esNextIX from './localization/locales/es/next-ix.json'
import jaNextIX from './localization/locales/ja/next-ix.json'

// Language code to name mapping
const languageNames = {
  en: 'English',
  fr: 'French',
  es: 'Spanish',
  ja: 'Japanese'
};

// Retrieve email from the user object in session storage
function getUserEmail() {
  const userString = sessionStorage.getItem('user');
  if (userString) {
    const user = JSON.parse(userString);
    return user.email || ''; // Return the email or an empty string if not found
  }
  return '';
}

function checkIfAiphoneUser() {
  const userEmail = getUserEmail();
  return userEmail.toLowerCase().endsWith('@aiphone.com'); // Check if the user is from Aiphone
}

// Initialize i18next
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation, simbillings: enSimbillings, quikspec: enQuikspec, acNioLicenseManagement : enAcNioLicenseManagement, nextIX: enNextIX},
    fr: { translation: frTranslation, simbillings: frSimbillings, quikspec: frQuikspec, acNioLicenseManagement : frAcNioLicenseManagement, nextIX: frNextIX},
    es: { translation: esTranslation, simbillings: esSimbillings, quikspec: esQuikspec, acNioLicenseManagement : esAcNioLicenseManagement, nextIX: esNextIX},
    ja: { translation: jaTranslation, simbillings: jaSimbillings, quikspec: jaQuikspec, acNioLicenseManagement : jaAcNioLicenseManagement, nextIX: jaNextIX}
  },
  ns: ['translation', 'simbillings', 'quikspec', 'licenseManagement', 'nextIX'], // Define namespaces
  defaultNS: 'translation', // Set the default namespace
  fallbackNS: 'translation', // Set fallback to common translations if key is missing in simbillings
  lng: 'en', // Default language
  fallbackLng: 'en', // Fallback language
  saveMissing: true, // Enable saveMissing to trigger missingKey event
  debug: false, // Enable debug mode for more detailed logs
  interpolation: { escapeValue: false }
});

// Handle failed loading of translations
i18n.on('failedLoading', (lng) => {
  if (checkIfAiphoneUser()) {
    toast.error(`Error loading translations for ${languageNames[lng] || lng}. Please try again later.`);
  }
});

// Handle language change
i18n.on('languageChanged', (lng) => {
  if (checkIfAiphoneUser()) {
    localStorage.setItem('preferredLanguage', lng); // Save language preference to local storage
    toast.info(`Language changed to ${languageNames[lng] || lng}`); // Show notification with full name
  }
});

// Handle missing translation key
i18n.on('missingKey', (lngs, namespace, key) => {
  if (checkIfAiphoneUser()) {
    const languages = lngs.map((lng) => languageNames[lng] || lng).join(', ');
    toast.error(`Missing translation key '${key}' in namespace '${namespace}' for language(s) ${languages}.`);
  }
});

// Handle general errors silently
i18n.on('error', () => {
  if (checkIfAiphoneUser()) {
    toast.error('An unexpected error occurred. Please try again later.');
  }
});

export default i18n;
