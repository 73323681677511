import { Box } from '@mui/material';
import PageTitle from '../Components/PageTitle';
import { CorporateFare } from '@mui/icons-material';
import { useAppSelector } from 'store/hooks';
import GatewayActionCenter from './GatewayActionCenter';
import SiteDataGrid from './SiteDatagrid';
import { useTranslation } from 'react-i18next';
import SiteWizard from './SiteInfo/Site/CardComponents/SiteWizard';
import AddDevicesModal from './Dialogs/AddStationsDialog';
import { useContext, useState } from 'react';
import UpdateGatewayFirmware from './UpdateGatewayFirmware';
import { RemoteManagementContext } from 'context/RemoteManagementContext';

const SiteDashboard = () => {
  const { gatewayFirmwareVersion, gatewayPublicId, setGatewayFirmwareVersion } = useContext(RemoteManagementContext);
  const [isAddDeviceModalOpen, setIsAddDeviceModalOpen] = useState(false);
  const siteInfo = useAppSelector((state) => state.site.siteInfo);
  const deviceList = useAppSelector((state) => state.devices.DeviceList);
  const unitList = useAppSelector((state) => state.units.UnitList);
  const tenantList = useAppSelector((state) => state.tenants.TenantList);
  const latestFirmwareList = useAppSelector((state) => state.devices.latestFirmwareList);

  if (siteInfo.registeredGatewayPublicId && deviceList[siteInfo.registeredGatewayPublicId]?.basicInfo.firmwareVersion) {
    setGatewayFirmwareVersion(deviceList[siteInfo.registeredGatewayPublicId]?.basicInfo.firmwareVersion);
  }

  const { t } = useTranslation();
  const remoteManagementStr = t('RemoteManagement.Title');

  return (
    <Box>
      <PageTitle
        featureName={remoteManagementStr}
        site={siteInfo}
        icon={<CorporateFare color="inherit" fontSize="inherit" />}
      />
      {!gatewayPublicId ? (
        <SiteWizard />
      ) : !gatewayFirmwareVersion || Number(gatewayFirmwareVersion) < 4.82 ? (
        <UpdateGatewayFirmware />
      ) : (
        <SiteWizard />
      )}
      <GatewayActionCenter latestFirmwareList={latestFirmwareList} />
      <SiteDataGrid
        deviceList={deviceList}
        unitList={unitList}
        tenantList={tenantList}
        latestFirmwareList={latestFirmwareList}
        setIsAddDeviceModalOpen={setIsAddDeviceModalOpen}
      />
      <AddDevicesModal isOpen={isAddDeviceModalOpen} setIsOpen={setIsAddDeviceModalOpen} />
    </Box>
  );
};

export default SiteDashboard;
