import { useState } from 'react';
import Introduction from './components/Introduction';
import { fetchLocalEnumList, IDeviceModelValue } from 'shared/utils/EnumUtils';
import GatewayTypeSelect from './components/GatewayTypeSelect';
import DialogWrapper from 'shared/components/dialogs/DialogWrapper';
import { useTranslation } from 'react-i18next';
import FinishRegistration from './components/FinishRegistration';
import DownloadFirmware from './components/DownloadFirmware';
import { useDeleteDeviceMutation } from 'services/aiphoneCloud';
import { useAppSelector } from 'store/hooks';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleNextStep: () => void;
}

const enum RegisterGatewaySteps {
  Introduction,
  SelectType,
  Firmware,
  Finish
}

function RegisterGatewayDialog({ isOpen, setIsOpen, handleNextStep }: Readonly<Props>) {
  const enumList = fetchLocalEnumList();
  const [deleteDevice] = useDeleteDeviceMutation();
  const [gatewayPublicId, setGatewayPublicId] = useState<string | undefined>(undefined);
  const siteRegisteredGatewayPublicId = useAppSelector((state) => state.site.siteInfo.registeredGatewayPublicId);
  const { t } = useTranslation();
  const configuringSiteStr = t('Site.Configure.Title');
  const registerGatewayStr = t('Site.Configure.RegisterGateway.Title');

  const gwDeviceModel: number = (
    enumList.deviceModel[
      Object.keys(enumList.deviceModel).find((key) => {
        return enumList.deviceModel[key].value === 'IXGW-GW';
      }) as string
    ] as IDeviceModelValue
  ).modelNumber;
  const tgwDeviceModel: number = (
    enumList.deviceModel[
      Object.keys(enumList.deviceModel).find((key) => {
        return enumList.deviceModel[key].value === 'IXGW-TGW';
      }) as string
    ] as IDeviceModelValue
  ).modelNumber;

  const [activeStep, setActiveStep] = useState(RegisterGatewaySteps.Introduction);
  const [gatewayType, setGatewayType] = useState<number>(gwDeviceModel);

  const handleNextSubStep = () => {
    setActiveStep((prevStep) => {
      return prevStep + 1;
    });
  };

  async function handleBackSubStep() {
    if (gatewayPublicId && gatewayPublicId !== siteRegisteredGatewayPublicId) {
      await deleteDevice(gatewayPublicId);
      setGatewayPublicId(undefined);
    }
    setActiveStep((prevStep) => {
      if (prevStep === RegisterGatewaySteps.Finish && gatewayType === tgwDeviceModel) {
        return RegisterGatewaySteps.SelectType;
      } else {
        return prevStep - 1;
      }
    });
  }

  async function handleClose() {
    if (gatewayPublicId && gatewayPublicId !== siteRegisteredGatewayPublicId) {
      await deleteDevice(gatewayPublicId);
      setGatewayPublicId(undefined);
    }
    setIsOpen(false);
  }

  const handleGatewayTypeSubStep = (gatewayModel: number) => {
    if (gatewayModel === gwDeviceModel) {
      setGatewayType(gwDeviceModel);
      setActiveStep(RegisterGatewaySteps.Firmware);
    } else if (gatewayModel === tgwDeviceModel) {
      setGatewayType(tgwDeviceModel);
      setActiveStep(RegisterGatewaySteps.Finish);
    }
  };

  function renderContent() {
    switch (activeStep) {
      case RegisterGatewaySteps.SelectType:
        return (
          <GatewayTypeSelect
            gatewayType={gatewayType}
            handleBackStep={handleBackSubStep}
            handleNextStep={handleGatewayTypeSubStep}
          />
        );
      case RegisterGatewaySteps.Firmware:
        return <DownloadFirmware handleBackStep={handleBackSubStep} handleNextStep={handleNextSubStep} />;
      case RegisterGatewaySteps.Finish:
        return (
          <FinishRegistration
            gatewayType={gatewayType}
            handleBackStep={handleBackSubStep}
            handleNextStep={handleNextStep}
            gatewayPublicId={gatewayPublicId}
            setGatewayPublicId={setGatewayPublicId}
          />
        );
      case RegisterGatewaySteps.Introduction:
      default:
        return <Introduction handleNextStep={handleNextSubStep} />;
    }
  }

  return (
    <DialogWrapper
      header={configuringSiteStr}
      subheader={registerGatewayStr}
      setIsOpen={setIsOpen}
      open={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      {renderContent()}
    </DialogWrapper>
  );
}

export default RegisterGatewayDialog;
