import { useState, useEffect } from 'react';
import { EnumList, fetchEnumList, ICountryValue, IStateValue } from 'shared/utils/EnumUtils';
import { useCurrentBranchWithReactQuery } from './useCurrentBranchWithReactQuery';

export const useCurrentBranchAddress = () => {
  const { data: currentBranch } = useCurrentBranchWithReactQuery();
  const [enumList, setEnumList] = useState<EnumList>({ country: {}, state: {} });

  useEffect(() => {
    fetchEnumList().then((data) => {
      setEnumList(data);
    });
  }, []);

  const state = enumList.state[currentBranch?.stateId ?? ''] as IStateValue;
  const country = enumList.country[state?.countryId ?? ''] as ICountryValue;

  return {
    line1: currentBranch?.address ?? '',
    city: currentBranch?.city ?? '',
    state: state?.value ?? '',
    country: country?.alpha2Code ?? '',
    countryNumericCode: country?.numericCode ?? '',
    postalCode: currentBranch?.postalCode ?? ''
  };
};
