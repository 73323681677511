import { Check } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';

interface SiteActivityItemBatchProps {
  stationName?: string;
}

const SiteActivityItemBatch = ({ stationName }: SiteActivityItemBatchProps) => {
  return (
    <>
      <Grid container sx={{ marginY: '8px' }}>
        <Grid item xs={4}>
          <Typography variant="body2" color="text.secondary">
            {stationName}:
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body2"
            color="success.main"
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Check color="success" sx={{ fontSize: '1rem', marginRight: '.25rem' }} />
            Success
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default SiteActivityItemBatch;
