import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import LoadingSpinner from 'features/LicenseManagement/components/common/LoadingSpinner';
import { useCancelStripeSubscription } from 'features/LicenseManagement/hooks/useCancelStripSubscription';
import { LicenseManagementCommonStyles } from 'features/LicenseManagement/styles/CommonStyles';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { SubscriptionStatus, DeploymentDetailResponse } from 'features/LicenseManagement/components/common/Types';

interface CancelLicenseDialogProps {
  open: boolean;
  onClose: () => void;
  stripeSubscriptionId: string;
  setMessage: (message: { type: 'success' | 'error'; text: string }) => void;
  deploymentName: string;
  deploymentId: string;
}

export const CancelLicenseDialog = ({
  open,
  onClose,
  stripeSubscriptionId,
  setMessage,
  deploymentName,
  deploymentId
}: CancelLicenseDialogProps) => {
  const { t } = useTranslation('acNioLicenseManagement');
  const titleStr = t('Cancel_License_Dialog.Title');
  const descStr = t('Cancel_License_Dialog.Description');
  const cancelLicenseStr = t('Cancel_License_Dialog.Btn');
  const successMsg = t('Cancel_License_Dialog.SuccessMsg');
  const errorMsg = t('Cancel_License_Dialog.ErrorMsg');

  const queryClient = useQueryClient();
  const { mutate: cancelStripeSubscription, isPending } = useCancelStripeSubscription();

  if (isPending) {
    return <LoadingSpinner />;
  }

  const handleCancelSubscription = () => {
    cancelStripeSubscription(stripeSubscriptionId, {
      onSuccess: () => {
        queryClient.setQueryData(['deployment', deploymentId], (oldData?: DeploymentDetailResponse) =>
          oldData ? { ...oldData, subscriptionStatus: SubscriptionStatus.Canceled } : oldData
        );
        onClose();
        setMessage({ type: 'success', text: successMsg });
      },
      onError: () => {
        setMessage({ type: 'error', text: errorMsg });
      }
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Typography variant="h5">
          {titleStr} {deploymentName}
        </Typography>
        <Typography variant="body1" sx={styles.description}>
          {descStr}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box style={LicenseManagementCommonStyles.buttonContainer}>
          <Button variant="text" sx={LicenseManagementCommonStyles.buttonBack} onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button variant="contained" sx={LicenseManagementCommonStyles.button} onClick={handleCancelSubscription}>
            {cancelLicenseStr}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const styles = {
  description: {
    marginTop: 2
  }
};
