import { EnumList, fetchLocalEnumList, ICountryValue } from './EnumUtils';

const isUsaOrCanada = (countryId: string): boolean => {
  const enumList: EnumList = fetchLocalEnumList();

  return (
    Object.keys(enumList.country)
      .filter((key) => {
        const countryValue = enumList.country[key] as ICountryValue;
        return countryValue.alpha2Code === 'US' || countryValue.alpha2Code === 'CA';
      })
      ?.includes(countryId) ?? false
  );
};

export default isUsaOrCanada;
