import { Box, MenuItem, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface FieldDisplayProps {
  label: string;
  value: string | React.ReactNode;
  isEditing?: boolean;
  readonly?: boolean;
  onChange?: (value: string) => void;
  isLast?: boolean;
  options?: { label: string | React.ReactNode; value: string }[];
  errors?: any;
}

const getDisplayValue = (val: string) => {
  return val === 'United States' ? 'United States of America' : val;
};

const FieldDisplay = ({
  label,
  value,
  isEditing,
  readonly,
  onChange,
  isLast = false,
  options,
  errors
}: FieldDisplayProps) => {
  const { t } = useTranslation('acNioLicenseManagement');
  //#region string constants
  const stateStr = t('Deployment_Address_State');
  const provinceStr = t('Deployment_Address_Province');
  const cityStr = t('Deployment_Address_City');
  const postalCodeStr = t('Deployment_Address_Postal_Code');
  const postalCodeInvalidStr = t('Deployment_Postal_Code_Invalid');
  const requiredFieldStr = t('Required_Field');

  const getErrorMessage = () => {
    if (label === postalCodeStr) {
      if (value == '') {
        return requiredFieldStr;
      } else if (errors?.address?.postalCode) {
        return postalCodeInvalidStr;
      }
    } else if (label === cityStr && value === '') {
      return requiredFieldStr;
    } else if ((label === stateStr || label === provinceStr) && value === '') {
      return requiredFieldStr;
    }
    return '';
  };

  //#endregion
  return (
    <Box mt={2} sx={styles.field(isLast)}>
      {isEditing && !readonly && options && options.length > 0 ? (
        <TextField
          select
          value={getDisplayValue(value as string) || ''}
          onChange={(e) => {
            if (onChange) onChange(e.target.value);
          }}
          fullWidth
          error={!!getErrorMessage()}
          helperText={getErrorMessage()}
        >
          {options?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      ) : isEditing && !readonly ? (
        <TextField
          value={getDisplayValue(value as string)}
          onChange={(e) => {
            if (onChange) onChange(e.target.value);
          }}
          fullWidth
          error={!!getErrorMessage()}
          helperText={getErrorMessage()}
        />
      ) : (
        <Typography variant="body1">{getDisplayValue(value as string)}</Typography>
      )}
      <Typography variant="body1" style={{ color: 'gray' }}>
        {label}
      </Typography>
    </Box>
  );
};

const styles = {
  field: (isLast: boolean) => ({
    pb: 2,
    ml: 1,
    borderBottom: isLast ? 'none' : '1px solid #e0e0e0'
  })
};

export default FieldDisplay;
