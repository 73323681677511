export const MarketType = [
  { value: 'Commercial/Office' },
  { value: 'Commercial/Retail' },
  { value: 'Correctional' },
  { value: 'Education/School' },
  { value: 'Government Federal' },
  { value: 'Government State' },
  { value: 'Healthcare' },
  { value: 'Industrial' },
  { value: 'Recruiting Center' },
  { value: 'Religious' },
  { value: 'Residential' },
  { value: 'Other' }
];

export enum DeviceCategory {
  MasterStation = 'Master Station',
  SubMasterStation = 'Sub Master Station',
  EntranceStation = 'Entrance Station',
  TenantStation = 'Tenant Station',
  GuardStation = 'Guard Station',
  VideoDoorStation = 'Video Door Station',
  AudioDoorStation = 'Audio Door Station',
  EmergencyStation = 'Emergency Station',
  IOAdaptor = 'IO Adaptor',
  GatewayAdaptor = 'Gateway Adaptor',
  LiftControl = 'Lift Control'
}

export enum DeviceGroups {
  MasterStation = 'MasterStation',
  SubMasterStation = 'SubMasterStation',
  EntranceStation = 'EntranceStation',
  TenantStation = 'TenantStation',
  GuardStation = 'GuardStation',
  VideoDoorStation = 'VideoDoorStation',
  AudioDoorStation = 'AudioDoorStation',
  EmergencyStation = 'EmergencyStation',
  IOAdaptor = 'IOAdaptor',
  GatewayAdaptor = 'GatewayAdaptor',
  LiftControl = 'LiftControl'
}

const GroupedDeviceTypes: {
  [key: string]: number[];
} = {};
GroupedDeviceTypes[DeviceGroups.MasterStation] = [4];
GroupedDeviceTypes[DeviceGroups.SubMasterStation] = [5];
GroupedDeviceTypes[DeviceGroups.EntranceStation] = [15];
GroupedDeviceTypes[DeviceGroups.TenantStation] = [14];
GroupedDeviceTypes[DeviceGroups.GuardStation] = [16];
GroupedDeviceTypes[DeviceGroups.VideoDoorStation] = [8, 11, 20];
GroupedDeviceTypes[DeviceGroups.AudioDoorStation] = [9, 10];
GroupedDeviceTypes[DeviceGroups.IOAdaptor] = [6];
GroupedDeviceTypes[DeviceGroups.GatewayAdaptor] = [18];
GroupedDeviceTypes[DeviceGroups.LiftControl] = [17];

export { GroupedDeviceTypes };

export interface DeviceType {
  deviceCategory: DeviceCategory;
  deviceList: string[];
  stationNumberStartingIndex: number;
  deviceGroup: string;
  assignedIndex: number | null;
  allowedUnitType: number[];
}

export interface SearchDevice {
  id: string;
  mac_addr: string;
  ip_ver: string;
  ip_addr: string;
  ip_subnet: string;
  ip_gateway: string;
  conn_port: string;
  device_type: string;
  model_number: string;
  station_name: string;
  station_number: string;
  station_type: string;
  location: string;
  fw_ver: string;
  sub_cpu_ver: string;
}

export const MAX_APPS = 8;

export const DeviceTypes: { [key: number]: string } = {
  4: 'Answering',
  5: 'Audio Sub',
  6: 'IO Adaptor',
  8: 'Video Door',
  9: 'Audio Door',
  10: 'Audio Door',
  11: 'Video Door',
  14: 'Tenant',
  15: 'Entrance',
  16: 'Guard',
  17: 'Lift Control Adaptor',
  18: 'Gateway Adaptor',
  20: 'Video Door'
};

export const DeviceTypesModel: { [key: number]: string } = {
  4: 'IX-MV7',
  5: 'IX-RS',
  6: 'IXW-MA',
  8: 'IX-DV',
  9: 'IX-SSA',
  10: 'IX-SS-2G',
  11: 'IX-EA',
  14: 'IXG-2C7',
  15: 'IXG-DM7',
  16: 'IXG-MK',
  17: 'IXGW-LC',
  18: 'IXGW-GW',
  20: 'IX-DVM'
};

export const MasterStations = [
  'IX-MV7-*',
  'IX-MV7',
  'IX-MV7-B',
  'IX-MV7-W',
  'IX-MV7-HB',
  'IX-MV7-HB-L',
  'IX-MV7-HW-JP',
  'IX-SOFT',
  'IX-RS',
  'IX-RS-*',
  'IX-RS-B',
  'IX-RS-W',
  'IXG-MK'
];

export const DoorStations = [
  'IX-DV*',
  'IX-DV',
  'IX-DVM',
  'IX-DVF',
  'IX-DVF-PR',
  'IX-DVF-4A',
  'IX-DVF-6',
  'IX-DVF-L',
  'IX-DVF-HW',
  'IX-EA',
  'IX-SS-2G',
  'IX-SSA',
  'IX-NVP',
  'IX-NVP2',
  'IX-FA',
  'IX-DVF-10KP',
  'IX-NVP'
];

export const UnitTypeNames = {
  1: {
    name: 'Guard',
    description:
      'Guard Units consist of up to eight IXG-MK Guard Stations or IX-RS-* Handset Sub Stations, up to eight IXG Apps, or a combination of both. The two private Door Stations will only call to stations within their Unit.'
  },
  2: { name: 'Entrance', description: 'Entrance Units consist of IXG-DM7-* Entrance Stations.' },
  3: {
    name: 'InsideArea',
    description:
      'Inside Area Units consist of up to eight IX-MV7-* Master Stations or IX-RS-* Handset Sub Stations, and two IX Series Door Stations.'
  },
  4: {
    name: 'Residential',
    description:
      'Residential Units may consist of up to eight IXG-2C7 tenant stations or IX-RS-* Handset Sub Stations, eight IXG Apps, or a combination of both.'
  },
  5: {
    name: 'Commercial',
    description:
      'Commercial Units consist of up to eight IX-MV7-* Master Stations or IX-RS-* Handset Sub Stations, eight IXG Apps, or a combination of both.'
  },
  6: {
    name: 'OutsideArea',
    description:
      'Outside Area Units consist of up to 10 Door or Emergency Stations that can call Residential, Guard, Commercial, and Inside Area units.'
  }
};

export enum UnitTypesEnum {
  Guard = 1,
  Entrance = 2,
  InsideArea = 3,
  Residential = 4,
  Commercial = 5,
  OutsideArea = 6
}

export const EmergencyStations = ['IX-DVF-RA', 'IX-DVF-2RA', 'IX-SSA-RA', 'IX-SSA-2RA'];

export const IOAdapter = ['IXW-MA', 'IXW-MAA'];

export const GatewayAdaptor = ['IXGW-GW', 'IXGW-TGW'];

// TODO: Replace this dummy value with a real Lift Control
export const LiftControls = ['IXGW-LC-RY20', 'IXGW-LC'];

export const EntranceStations = ['IXG-DM7-HID', 'IXG-DM7', 'IXG-DM7-*'];

export const TenantStations = ['IXG-2C7', 'IXG-2C7-L'];

export const GuardStations = ['IXG-MK'];

export const DeviceCategories: DeviceType[] = [
  {
    deviceCategory: DeviceCategory.MasterStation,
    deviceList: MasterStations,
    stationNumberStartingIndex: 1,
    deviceGroup: DeviceGroups.MasterStation,
    assignedIndex: null,
    allowedUnitType: [3, 5, 6]
  },
  {
    deviceCategory: DeviceCategory.SubMasterStation,
    deviceList: MasterStations,
    stationNumberStartingIndex: 1,
    deviceGroup: DeviceGroups.SubMasterStation,
    assignedIndex: null,
    allowedUnitType: [1, 3, 4, 5]
  },
  {
    deviceCategory: DeviceCategory.EntranceStation,
    deviceList: EntranceStations,
    stationNumberStartingIndex: 2,
    deviceGroup: DeviceGroups.EntranceStation,
    assignedIndex: null,
    allowedUnitType: [2]
  },
  {
    deviceCategory: DeviceCategory.TenantStation,
    deviceList: TenantStations,
    stationNumberStartingIndex: 1,
    deviceGroup: DeviceGroups.TenantStation,
    assignedIndex: null,
    allowedUnitType: [4]
  },
  {
    deviceCategory: DeviceCategory.GuardStation,
    deviceList: GuardStations,
    stationNumberStartingIndex: 1,
    deviceGroup: DeviceGroups.GuardStation,
    assignedIndex: null,
    allowedUnitType: [1, 3]
  },
  {
    deviceCategory: DeviceCategory.VideoDoorStation,
    deviceList: DoorStations,
    stationNumberStartingIndex: 2,
    deviceGroup: DeviceGroups.VideoDoorStation,
    assignedIndex: null,
    allowedUnitType: [1, 3, 4, 5, 6]
  },
  {
    deviceCategory: DeviceCategory.AudioDoorStation,
    deviceList: DoorStations,
    stationNumberStartingIndex: 2,
    deviceGroup: DeviceGroups.AudioDoorStation,
    assignedIndex: null,
    allowedUnitType: [1, 3, 4, 5, 6]
  },
  {
    deviceCategory: DeviceCategory.EmergencyStation,
    deviceList: EmergencyStations,
    stationNumberStartingIndex: 4,
    deviceGroup: DeviceGroups.EmergencyStation,
    assignedIndex: null,
    allowedUnitType: [6]
  },
  {
    deviceCategory: DeviceCategory.GatewayAdaptor,
    deviceList: GatewayAdaptor,
    stationNumberStartingIndex: 3,
    deviceGroup: DeviceGroups.GatewayAdaptor,
    assignedIndex: null,
    allowedUnitType: []
  },
  {
    deviceCategory: DeviceCategory.LiftControl,
    deviceList: LiftControls,
    stationNumberStartingIndex: 5,
    deviceGroup: DeviceGroups.LiftControl,
    assignedIndex: null,
    allowedUnitType: []
  },
  {
    deviceCategory: DeviceCategory.IOAdaptor,
    deviceList: IOAdapter,
    stationNumberStartingIndex: 3,
    deviceGroup: DeviceGroups.IOAdaptor,
    assignedIndex: null,
    allowedUnitType: []
  }
];

export const EntranceDevices = ['IXG-DM7-HID', 'IXG-DM7', 'IXG-DM7-*'];
export const DoorDevices = [
  'IX-DV',
  'IX-DVF',
  'IX-DVF-PR',
  'IX-SSA-RA',
  'IX-SSA-2RA',
  'IX-SS-2G',
  'IX-EA',
  'IX-FA',
  'IX-DVF-10KP',
  'IX-NVP'
];
export const ResidentialDevices = [...DoorDevices, 'IXG-2C7', 'IXG-2C7-L', 'IX-RS-B', 'IX-RS-W'];

export const GuardDevices = ['IXG-MK', 'IX-RS-B', 'IX-RS-W', ...DoorDevices];
export const CommercialDevices = [
  'IX-MV7-*',
  'IX-MV7',
  'IX-MV7-B',
  'IX-MV7-W',
  'IX-MV7-HB',
  'IX-MV7-HB-L',
  'IX-MV7-HW-JP',
  'IX-RS-B',
  'IX-RS-W',
  ...DoorDevices
];

export const InsideAreaDevices = [
  'IXG-MK',
  'IX-MV7-*',
  'IX-MV7',
  'IX-MV7-B',
  'IX-MV7-W',
  'IX-MV7-HB',
  'IX-MV7-HB-L',
  'IX-MV7-HW-JP',
  'IX-RS-B',
  'IX-RS-W',
  'IX-RS-*',
  ...DoorDevices
];
export const OutsideAreaDevices = [...DoorDevices];

export enum GatewayStatus {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  CHECKING = 'CHECKING',
  UPDATING = 'UPDATING',
  UNREGISTERED = 'UNREGISTERED'
}

export enum GatewayCommands {
  INFO = 'info',
  REBOOT = 'reboot',
  SEARCH = 'search',
  SYNC = 'sync',
  ASSOCIATE = 'associate',
  REGISTER = 'register',
  FIRMWARE_UPDATE = 'firmware-update',
  TEST = 'test'
}

export enum GatewayCommandStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  PENDING = 'pending',
  FAILED = 'failed',
  COMPLETED = 'completed'
}

export interface Firmware {
  name: string;
  version: number;
  updateLog: string[];
}

//**** REGEX TEMPLATES ****\\

// ZIP Code
const RegexZipCodeString = '\\d{5}(?:[-\\s]\\d{4})?';
const RegexPostalCodeString = '[ABCEGHJ-NPRSTVXY]\\d[ABCEGHJ-NPRSTV-Z][ -]?\\d[ABCEGHJ-NPRSTV-Z]\\d';

export const ZipCodeValidCharacters = '/\\D/g';
export const PostalCodeValidCharacters = '/[ABCEGHJNPRSTVXYZ0-9]/g';

export const RegexZipCode = new RegExp(`^${RegexZipCodeString}$`);
export const RegexPostalCode = new RegExp(`^${RegexPostalCodeString}$`, 'i');

// IP Address - Validated here: https://regex101.com/r/GGIPUi/3
const RegexIpV4String =
  '(?:(?:[1-9]|[1-9]\\d|1\\d\\d|2[0-1]\\d|22[0-3])\\.(?:(?:25[0-5]|2[0-4]\\d|[01]?\\d\\d?)\\.){2}(?:[1-9]|[1-9]\\d|1\\d\\d|2[0-4]\\d|25[0-4]))';

// TODO: This needs tested for all cases.
const RegexIpV6String =
  '(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|' + // TEST: 1:2:3:4:5:6:7:8
  '([0-9a-fA-F]{1,4}:){1,7}:|' + // TEST: 1::                              1:2:3:4:5:6:7::
  '([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|' + // TEST: 1::8             1:2:3:4:5:6::8  1:2:3:4:5:6::8
  '([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|' + // TEST: 1::7:8           1:2:3:4:5::7:8  1:2:3:4:5::8
  '([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|' + // TEST: 1::6:7:8         1:2:3:4::6:7:8  1:2:3:4::8
  '([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|' + // TEST: 1::5:6:7:8       1:2:3::5:6:7:8  1:2:3::8
  '([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|' + // TEST: 1::4:5:6:7:8     1:2::4:5:6:7:8  1:2::8
  '[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|' + // TEST: 1::3:4:5:6:7:8   1::3:4:5:6:7:8  1::8
  ':((:[0-9a-fA-F]{1,4}){1,7}|:)|' + // TEST: ::2:3:4:5:6:7:8  ::2:3:4:5:6:7:8 ::8       ::
  'fe08:(:[0-9a-fA-F]{1,4}){2,2}%[0-9a-zA-Z]{1,}|' + // TEST: fe08::7:8%eth0      fe08::7:8%1                                      (link-local IPv6 addresses with zone index)
  `::(ffff(:0{1,4}){0,1}:){0,1}"${RegexIpV4String}|` + // TEST: ::255.255.255.255   ::ffff:255.255.255.255  ::ffff:0:255.255.255.255 (IPv4-mapped IPv6 addresses and IPv4-translated addresses)
  `([0-9a-fA-F]{1,4}:){1,4}:${RegexIpV4String})`; // TEST: 2001:db8:3:4::192.0.2.33  64:ff9b::192.0.2.33                        (IPv4-Embedded IPv6 Address)

const RegexDomainNameString =
  '(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\\-]*[a-zA-Z0-9])\\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\\-]*[A-Za-z0-9])';

const RegexIpV4OrDomainString = `(${RegexIpV4String}|${RegexDomainNameString})`;
const RegexIpV6OrDomainString = `(${RegexIpV6String}|${RegexDomainNameString})`;

// Subnet Mask - Validated here: https://regex101.com/r/Pv1kZq/2
const RegexSubnetMaskString =
  '(((255\\.){3}(255|254|252|248|240|224|192|128|0+))|' +
  '((255\\.){2}(255|254|252|248|240|224|192|128|0+)\\.0)|' +
  '((255\\.)(255|254|252|248|240|224|192|128|0+)(\\.0+){2})|' +
  '((255|254|252|248|240|224|192|128|0+)(\\.0+){3}))';

export const RegexIpV4 = new RegExp(`^${RegexIpV4String}$`);
export const RegexIpV6 = new RegExp(`^${RegexIpV6String}$`);
export const RegexDomainName = new RegExp(`^${RegexDomainNameString}$`);
export const RegexIpV4OrDomain = new RegExp(`^${RegexIpV4OrDomainString}$`);
export const RegexIpV6OrDomain = new RegExp(`^${RegexIpV6OrDomainString}$`);
export const RegexSubnetMask = new RegExp(`^${RegexSubnetMaskString}$`);
