import { Box, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { DeploymentType, LicenseItem } from 'features/LicenseManagement/components/common/Types';
import { useDeploymentById } from 'features/LicenseManagement/hooks/useDeployments';
import ErrorAlert from 'features/LicenseManagement/components/common/ErrorAlert';
import LoadingSpinner from 'features/LicenseManagement/components/common/LoadingSpinner';
import { LicenseManagementCommonStyles as CommonStyles } from 'features/LicenseManagement/styles/CommonStyles';
import { formatDate, getFormattedDuration } from 'features/LicenseManagement/components/common/utils';
import Header from 'features/LicenseManagement/components/common/Header';
import { useCurrentBranchWithReactQuery } from 'features/LicenseManagement/hooks/useCurrentBranchWithReactQuery';

const LicenseHistory = () => {
  const { t } = useTranslation('acNioLicenseManagement');
  const { data: currentBranch } = useCurrentBranchWithReactQuery();
  const { deploymentId } = useParams();
  const { isAllowedTo } = usePermission();
  const emptyValue = '-';

  if (!deploymentId) {
    return <Typography>{t('Error_Loading_Deployment_ID')}</Typography>;
  }

  const canViewDeploymentBilling = isAllowedTo(
    'deploymentBilling:view',
    currentBranch?.publicId,
    PermissionsContextType.BRANCH
  );

  const { data: deployment, isLoading, isError, error, refetch } = useDeploymentById(deploymentId);
  const licenses: LicenseItem[] = deployment?.licenses ?? [];
  const defaultPageSize = 50;
  const [pagination, setPagination] = useState({ page: 0, pageSize: defaultPageSize });

  if (!currentBranch) return <LoadingSpinner />;

  if (!canViewDeploymentBilling) {
    return <Typography>{t('Missing_Permission_To_View_License_History')}</Typography>;
  }

  if (isError) {
    return (
      <Box>
        <ErrorAlert errorMessage={error.message} customMessage={t('Error_Loading_License_History')} onRetry={refetch} />
      </Box>
    );
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const renderStyledHeader = (params: GridColumnHeaderParams) => (
    <Typography variant="body2" sx={CommonStyles.gridColumnHeader}>
      {params.colDef.headerName}
    </Typography>
  );

  const computeRows = (licenses: LicenseItem[]) => {
    return licenses.map((license) => ({
      id: license.licenseId,
      name: license.name ?? emptyValue,
      duration: license.duration ? getFormattedDuration(license.duration) : emptyValue,
      activationDate: license.activationDate ? formatDate(license.activationDate) : emptyValue,
      expirationDate: license.expirationDate ? formatDate(license.expirationDate) : emptyValue
    }));
  };

  const computeColumns = (): GridColDef[] => [
    ...(deployment?.deploymentType === DeploymentType.Local
      ? [{ field: 'name', headerName: t('Deployment_Name'), flex: 1, renderHeader: renderStyledHeader }]
      : []),
    { field: 'duration', headerName: t('License_Term'), flex: 1, renderHeader: renderStyledHeader },
    { field: 'activationDate', headerName: t('Activation_Date'), flex: 1, renderHeader: renderStyledHeader },
    { field: 'expirationDate', headerName: t('Expiration_Date'), flex: 1, renderHeader: renderStyledHeader }
  ];

  return (
    <Box sx={CommonStyles.pageHeaderContainer}>
      <Header deployment={deployment} status={deployment?.licenseStatus} />
      <Box sx={styles.sectionContainerStyle}>
        <Typography variant="h6" gutterBottom>
          {t('License_History')}
        </Typography>
        {licenses.length === 0 ? (
          <Box sx={styles.sectionStyle}>
            <Typography>{t('No_Prior_License_History')}</Typography>
          </Box>
        ) : (
          <Box sx={styles.container}>
            <DataGrid
              rows={computeRows(licenses)}
              columns={computeColumns()}
              disableRowSelectionOnClick
              pageSizeOptions={[25, 50, 100]}
              paginationModel={pagination}
              onPaginationModelChange={(model) => setPagination(model)}
              pagination
              autoHeight={licenses.length > 0}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

const styles = {
  sectionContainerStyle: {
    p: 2,
    position: 'relative'
  },
  sectionStyle: {
    p: 1,
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    position: 'relative',
    mt: 2
  },
  container: {
    height: '25rem',
    width: '70%',
    '& .MuiDataGrid-cell': {
      borderBottom: '0.0625rem solid #e0e0e0'
    },
    marginTop: '1rem'
  }
};

export default LicenseHistory;
