import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PackageDetail from '../Unactivated/Cloud/PackageDetail';
import ErrorAlert from 'features/LicenseManagement/components/common/ErrorAlert';
import LoadingSpinner from 'features/LicenseManagement/components/common/LoadingSpinner';
import { useEffect, useState } from 'react';
import { useCloudDeploymentPackagePricing } from 'features/LicenseManagement/hooks/useCloudDeploymentPackagePricing';
import { Add, Remove } from '@mui/icons-material';
import { useUpdateCloudDeploymentSubscription } from 'features/LicenseManagement/hooks/useUpdateCloudDeploymentSubscription';
import {
  CloudDeploymentUpdateSubscriptionRequest,
  LicenseDetailResponse,
  SelectedStripeProduct
} from 'features/LicenseManagement/components/common/Types';
import useReduxUsers from 'features/Dashboard/Hooks/useReduxUsers';
import { useCurrentBranchAddress } from 'features/LicenseManagement/hooks/useCurrentBranchAddress';
import CloseIcon from '@mui/icons-material/Close';
import {
  LicenseManagementCommonStyles as CommonStyles,
  LicenseManagementCommonStyles
} from 'features/LicenseManagement/styles/CommonStyles';
import { QueryObserverResult } from '@tanstack/react-query';

interface UpdatePackagePriceDialogProps {
  onClose: () => void;
  license: LicenseDetailResponse;
  setMessage: (message: { type: 'success' | 'error' | 'warning'; text: string }) => void;
  refetchLicense: (options?: any) => Promise<QueryObserverResult<LicenseDetailResponse, Error>>;
  openUpdatePackageDialog: boolean;
}

interface PackageItemRowProps {
  label: string;
  value: number;
  setValue: (value: number) => void;
  prevValue: number;
}

const UpdatePackagePriceDialog = ({
  onClose,
  license,
  setMessage,
  refetchLicense,
  openUpdatePackageDialog
}: UpdatePackagePriceDialogProps) => {
  const { t } = useTranslation('acNioLicenseManagement');

  const [numDoors, setNumDoors] = useState(license?.numOfDoors ?? 0);
  const [numExpanders, setNumExpanders] = useState(license?.numOfExpanders ?? 0);
  const [numCabs, setNumCabs] = useState(license?.numOfElevators ?? 0);
  const [selectedStripeProducts, setSelectedStripeProducts] = useState<SelectedStripeProduct[] | null>(null);
  const { currentUser } = useReduxUsers();
  const { line1, city, state, country, countryNumericCode, postalCode } = useCurrentBranchAddress();
  const [isRefetching, setIsRefetching] = useState<boolean>(false);
  const [paymentUpdateUrl, setPaymentUpdateUrl] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const theme = useTheme();

  useEffect(() => {
    if (openUpdatePackageDialog && license) {
      setNumDoors(license?.numOfDoors ?? 0);
      setNumExpanders(license?.numOfExpanders ?? 0);
      setNumCabs(license?.numOfElevators ?? 0);
    }
  }, [openUpdatePackageDialog, license]);

  const { mutateAsync: updateCloudDeploymentSubscription, isPending: isPendingUpdateSubscription } =
    useUpdateCloudDeploymentSubscription();

  const {
    doorPriceDetails,
    expandersCost,
    cabsCost,
    totalDiscount,
    grandTotal,
    error,
    isFetchStripeProductsError,
    isLoading,
    refetchStripeProducts,
    combinedStripeCheckoutItems
  } = useCloudDeploymentPackagePricing(numDoors, numExpanders, numCabs);

  useEffect(() => {
    setSelectedStripeProducts((prev) => {
      if (JSON.stringify(prev) === JSON.stringify(combinedStripeCheckoutItems)) {
        return prev;
      }
      return combinedStripeCheckoutItems;
    });
  }, [combinedStripeCheckoutItems, setSelectedStripeProducts]);

  if (isLoading || isRefetching) {
    return <LoadingSpinner />;
  }

  const isChangeMade =
    numDoors !== license.numOfDoors || numExpanders !== license.numOfExpanders || numCabs !== license.numOfElevators;

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, setValue: (value: number) => void) => {
    setValue(Math.max(0, Number(event.target.value)));
  };

  // Function to get difference display
  const getChangeValue = (current: number, previous: number) => {
    const diff = current - previous;
    return diff !== 0 ? (
      <Typography component="span" sx={styles.changeText(theme, diff)}>
        {diff > 0 ? `+${diff}` : diff}
      </Typography>
    ) : null;
  };

  const isLicenseUpdated = (
    currentLicense: LicenseDetailResponse,
    updatedParams: CloudDeploymentUpdateSubscriptionRequest
  ) => {
    return (
      currentLicense?.numOfDoors === updatedParams.numOfDoors &&
      currentLicense?.numOfExpanders === updatedParams.numOfExpanders &&
      currentLicense?.numOfElevators === updatedParams.numOfElevators
    );
  };

  const pollForUpdate = async (updatedParams: CloudDeploymentUpdateSubscriptionRequest) => {
    let attempts = 0;
    const maxAttempts = 6;

    while (attempts < maxAttempts) {
      attempts++;
      const { data: updatedLicense } = await refetchLicense();

      if (updatedLicense && isLicenseUpdated(updatedLicense, updatedParams)) {
        setIsRefetching(false);
        setMessage({ type: 'success', text: t('License_Updated_Successfully') });
        return;
      }

      await new Promise((resolve) => setTimeout(resolve, 5000)); // Wait 5 seconds before retrying
    }

    // If polling fails after maxAttempts
    setIsRefetching(false);
    setMessage({ type: 'warning', text: t('Error_Fetching_Latest_License') });
  };

  const handleDialogClose = () => {
    onClose();
    setErrorMessage(null);
    setPaymentUpdateUrl(null);
  };

  const handleUpdateLicense = async () => {
    setPaymentUpdateUrl(null);
    setErrorMessage(null);
    const params: CloudDeploymentUpdateSubscriptionRequest = {
      numOfDoors: numDoors,
      numOfExpanders: numExpanders,
      numOfElevators: numCabs,
      selectedProducts: selectedStripeProducts || [],
      licenseId: license.licenseId,
      deploymentId: license.deploymentId,
      reactAppStage: 'localhost', // TODO: Change back to dev
      cloudUserPublicId: currentUser?.publicId || '',
      stripeSubscriptionId: license.stripeSubscriptionId,
      addressForTax: { line1, city, state, country, countryNumericCode, postalCode },
      taxExempt: false,
      callingUserStripeCustomerId: currentUser?.stripeCustomerId ?? null
    };

    try {
      const response = await updateCloudDeploymentSubscription({
        aiphoneBranchPublicId: license?.aiphoneBranchPublicId ?? '',
        deploymentId: license.deploymentId ?? '',
        data: params
      });

      if (response?.invoice) {
        onClose();
        setIsRefetching(true);
        pollForUpdate(params);
      } else if (response?.paymentUpdateUrl !== undefined) {
        if (response.paymentUpdateUrl === null) {
          setErrorMessage(t('Update_Payment_Method_Error_Message'));
        } else {
          setPaymentUpdateUrl(response.paymentUpdateUrl);
        }
      } else {
        setErrorMessage(t('Error_Updating_License'));
      }
    } catch (error) {
      setErrorMessage(t('Error_Updating_License'));
    }
  };

  const PackageItemRow = ({ label, value, setValue, prevValue }: PackageItemRowProps) => (
    <TableRow>
      <TableCell>{label}</TableCell>
      <TableCell sx={styles.TableBody}>
        <IconButton size="small" onClick={() => setValue(Math.max(0, value - 1))} sx={styles.iconButton}>
          <Remove fontSize="small" />
        </IconButton>
        <TextField
          type="number"
          value={value}
          onChange={(e) => handleInputChange(e, setValue)}
          inputProps={{ min: 0 }}
          sx={styles.textField}
        />
        <IconButton size="small" onClick={() => setValue(value + 1)} sx={styles.iconButton}>
          <Add fontSize="small" />
        </IconButton>
        {getChangeValue(value, prevValue)}
      </TableCell>
    </TableRow>
  );

  return (
    <Dialog open={openUpdatePackageDialog} onClose={onClose} maxWidth="md" fullWidth>
      <Box sx={styles.header}>
        <Box sx={styles.titleContainer}>
          <DialogTitle>
            <Typography variant="h5" component="h5" sx={styles.titleContainer.title}>
              {t('Update_License_Title')}
            </Typography>
            <Typography variant="h6" component="p">
              {t('Update_License_Subtitle')}
            </Typography>
          </DialogTitle>
        </Box>
        <IconButton aria-label="close" onClick={handleDialogClose} sx={CommonStyles.closeContainer}>
          <CloseIcon color={'primary'} sx={CommonStyles.closeContainer.closeIcon} />
        </IconButton>
      </Box>
      <DialogContent dividers sx={styles.content}>
        {paymentUpdateUrl && (
          <Box sx={styles.errorBox}>
            <Typography color="error">{t('Payment_Failed')}</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.open(paymentUpdateUrl, '_blank')}
              sx={{ mt: 1 }}
            >
              {t('Update_Payment_Method')}
            </Button>
          </Box>
        )}

        {errorMessage && (
          <Box sx={styles.errorBox}>
            <Typography color="error">{errorMessage}</Typography>
          </Box>
        )}
        <Typography variant="body2" sx={styles.formSection}>
          {t('Update_License_Info')}
        </Typography>
        <Box sx={styles.formContainer}>
          <Box sx={styles.formWrapper}>
            <TableContainer sx={styles.tableWrapper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={styles.tableHeading}>{t('Item')}</TableCell>
                    <TableCell sx={styles.tableHeading}>{t('Item_Count')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[
                    { label: t('Doors'), value: numDoors, setValue: setNumDoors, prevValue: license?.numOfDoors },
                    {
                      label: t('I/O_Expanders'),
                      value: numExpanders,
                      setValue: setNumExpanders,
                      prevValue: license?.numOfExpanders
                    },
                    {
                      label: t('Elevator_Cabs'),
                      value: numCabs,
                      setValue: setNumCabs,
                      prevValue: license?.numOfElevators
                    }
                  ].map((item) => (
                    <PackageItemRow key={item.label} {...item} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          {(numDoors > 0 || numExpanders > 0 || numCabs > 0) && (
            <PackageDetail
              doorPriceDetails={doorPriceDetails}
              numCabs={numCabs}
              numExpanders={numExpanders}
              expandersCost={expandersCost}
              cabsCost={cabsCost}
              totalDiscount={totalDiscount}
              grandTotal={grandTotal}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box style={LicenseManagementCommonStyles.buttonContainer}>
          <Button onClick={onClose} variant="text" sx={LicenseManagementCommonStyles.buttonBack}>
            {t('Cancel')}
          </Button>
          <Button
            onClick={handleUpdateLicense}
            variant="contained"
            sx={LicenseManagementCommonStyles.button}
            disabled={!isChangeMade || isLoading}
          >
            {isPendingUpdateSubscription ? <CircularProgress size={24} color="inherit" /> : t('Update_License')}
          </Button>
        </Box>
      </DialogActions>
      {isFetchStripeProductsError && (
        <ErrorAlert
          errorMessage={error?.message || ''}
          customMessage={t('Error_Loading_Data')}
          onRetry={refetchStripeProducts}
        />
      )}
    </Dialog>
  );
};

const styles = {
  header: {
    display: 'flex',
    minHeight: '5rem',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  errorBox: { mb: 2, p: 2, backgroundColor: '#ffebee', borderRadius: '4px' },
  titleContainer: {
    padding: '1rem',
    title: {
      padding: 0
    }
  },
  content: {
    padding: '1rem'
  },
  tableWrapper: {
    flex: 2,
    marginTop: -2,
    border: '1px solid lightgray',
    borderRadius: 2,
    padding: 1
  },
  tableHeading: {
    textAlign: 'center',
    fontWeight: 'bold'
  },
  TableBody: {
    display: 'flex',
    alignItems: 'center'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
    gap: 4,
    padding: 2,
    marginTop: 2,
    width: '100%'
  },
  formWrapper: {
    flexGrow: 1,
    minWidth: '45%',
    maxWidth: '50%',
    display: 'flex',
    flexDirection: 'column',
    gap: 3
  },
  packageDetailWrapper: {
    flexGrow: 1,
    minWidth: '45%',
    maxWidth: '80%',
    padding: '20px'
  },
  inputField: {
    width: '100%'
  },
  container: {
    padding: 4,
    fontFamily: 'Arial, sans-serif',
    minWidth: '600px'
  },
  formSection: {
    marginLeft: 2
  },
  iconButton: {
    border: '1px solid #ccc',
    borderRadius: '5px',
    mx: 1,
    width: 30,
    height: 30
  },
  numberDisplay: {
    minWidth: '40px',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  textField: {
    width: '50px',
    '& input': { textAlign: 'center', fontWeight: 'bold' },
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': { WebkitAppearance: 'none', margin: 0 }
  },
  changeText: (theme, diff) => ({
    color: diff > 0 ? theme.palette.success.main : theme.palette.error.main,
    fontWeight: 'bold',
    fontSize: '0.875rem',
    marginLeft: theme.spacing(1)
  })
};

export default UpdatePackagePriceDialog;
