import { useDispatch, useSelector } from 'react-redux';
import { getSelectedDevice, setDeviceBasicInfo } from 'store/slices/devicesSlice';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { Box, Card } from '@mui/material';
import { useUpdateDeviceMutation } from 'services/aiphoneCloud';
import containerStyle from 'styles/advancedSettingContainerStyle';
import { useTranslation } from 'react-i18next';

/* Units & Stations tab stub */
export const UnitsStationsLabel = () => {
  return <span>Units & Stations</span>;
};

const UnitsStations = () => {
  const dispatch = useDispatch();
  const [updateDevice, { isLoading: isUpdating }] = useUpdateDeviceMutation();
  const selectedDevice = useSelector(getSelectedDevice);

  const { t } = useTranslation();
  const saveChangesStr = t('Shared.SaveChanges');
  const buttonReset = t('Button_Reset');

  const getValidationSchema = () => {
    const basicInfoSchema: any = {};

    return Yup.object({
      basicInfo: Yup.object(basicInfoSchema)
    });
  };

  const onSubmit = async (values: any) => {
    const params = {
      device: {
        basicInfo: values.basicInfo,
        publicId: values.publicId,
        unitPublicId: values.unitPublicId
      }
    };
    updateDevice(params);

    dispatch(setDeviceBasicInfo(params));
  };

  return (
    <>
      <Box sx={containerStyle.mainWrapper}>
        <Formik
          initialValues={selectedDevice}
          onSubmit={onSubmit}
          validationSchema={getValidationSchema()}
          enableReinitialize
        >
          {({ dirty, isSubmitting }) => (
            <Form style={containerStyle.form}>
              <Box sx={containerStyle.controlPanelWrapper}>
                <LoadingButton
                  variant="outlined"
                  loading={isSubmitting}
                  type="reset"
                  disabled={!dirty || isSubmitting || isUpdating}
                >
                  {buttonReset}
                </LoadingButton>
                <LoadingButton
                  variant="outlined"
                  loading={isSubmitting}
                  type="submit"
                  disabled={!dirty || isSubmitting || isUpdating}
                >
                  {saveChangesStr}
                </LoadingButton>
              </Box>
              <Card sx={containerStyle.settingsWrapper}>
                <Box sx={containerStyle.gridContainer}></Box>
              </Card>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default UnitsStations;
