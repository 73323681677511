import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { Field, Form, Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { CA, US } from 'country-flag-icons/react/3x2';
import { useUpdateCompanyMutation } from 'services/aiphoneCloud';
import * as yup from 'yup';
import { IStateValue, ICountryValue, EnumList, fetchEnumList } from 'shared/utils/EnumUtils';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import StringUtils from 'shared/utils/StringUtils';
import getStateList from 'shared/utils/GetStateList';

export type Company = {
  id: string;
  name: string;
  postalCode: string;
  stateId: number;
  companyTypeId: number;
  dealerCompanyPublicId: string;
};

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  company: Company;
  setIsError: (isError: boolean) => void;
  setErrorMessage: (errorMessage: string) => void;
  setIsSuccess: (isSuccess: boolean) => void;
  setSuccessMessage: (successMessage: string) => void;
}

const EditCompanyDialog = ({
  isOpen,
  setIsOpen,
  company,
  setIsError,
  setErrorMessage,
  setIsSuccess,
  setSuccessMessage
}: Props) => {
  const [updateCompany] = useUpdateCompanyMutation();
  const [enumList, setEnumList] = useState<EnumList>({ country: {}, state: {} });
  const { t } = useTranslation(); // Initialize translation hook
  const canadaCountryId = Object.keys(enumList.country).find((key) => {
    return (enumList.country[key] as ICountryValue).alpha2Code === 'CA';
  });
  const selectAItemStr = t('Shared.SelectAItem');
  const countryStr = t('Shared.Country');
  const stateStr = t('Shared.State');
  const provinceStr = t('Shared.Province');
  const zipCodeStr = t('Shared.ZipCode');
  const postalCodeStr = t('Shared.PostalCode');
  const cancelStr = t('Shared.Cancel');
  const selectACountryStr = StringUtils.format(selectAItemStr, countryStr);
  const selectAStateStr = StringUtils.format(selectAItemStr, stateStr);
  const selectAProvinceStr = StringUtils.format(selectAItemStr, provinceStr);

  useEffect(() => {
    fetchEnumList().then((data) => {
      setEnumList(data);
    });
  }, []);

  const handleCloseImportClick = () => {
    setIsOpen(false);
  };

  const initialForm = () => ({
    companyName: company?.name ?? '',
    stateId: company?.stateId ?? '',
    postalCode: company?.postalCode ?? '',
    countryId: company?.stateId ? (enumList.state[company.stateId] as IStateValue).countryId.toString() : ''
  });

  const handleUpdateCompany = (values: any) => {
    const updateRequest = {
      companyData: {
        targetCompanyPublicId: company.id,
        name: values.companyName,
        stateId: parseInt(values.stateId),
        postalCode: values.postalCode,
        dealerCompanyPublicId: company.dealerCompanyPublicId,
        type: company.companyTypeId
      }
    };

    updateCompany(updateRequest)
      .unwrap()
      .then(() => {
        setIsSuccess(true);
        setSuccessMessage(t('Company_Update_Success'));
        setIsOpen(false);
      })
      .catch(() => {
        setIsError(true);
        setErrorMessage(t('AdvancedSettings_Error_UpdateDevice'));
      });
  };

  const validationSchema = yup.object().shape({
    companyName: yup.string().required(t('Validations.Company_Name_Required'))
  });

  return (
    <Dialog onClose={handleCloseImportClick} open={isOpen} disableEscapeKeyDown={true} maxWidth="sm" fullWidth={true}>
      <Paper>
        <Grid container spacing={1}>
          <Grid item xs={11}>
            <DialogTitle>{t('Title_Edit_Company')}</DialogTitle>
          </Grid>
          <Grid item xs={1}>
            <IconButton color="default" aria-label="cancel" onClick={handleCloseImportClick}>
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Box>
          <Formik
            initialValues={initialForm()}
            onSubmit={(values) => {
              handleUpdateCompany(values);
            }}
            validationSchema={validationSchema}
            validateOnBlur={true}
          >
            {(props) => {
              const { isSubmitting, handleChange, values, errors, touched, setFieldValue } = props;
              return (
                <Form>
                  <DialogContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Field
                          name="companyName"
                          as={TextField}
                          sx={styles.inputField}
                          label={t('Company_Name')}
                          size="small"
                          helperText={touched.companyName && errors.companyName ? errors.companyName : ''}
                          error={touched.companyName && !!errors.companyName}
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <FormControl sx={styles.inputField} size="small">
                          <InputLabel id="country-label">{selectACountryStr}</InputLabel>
                          <Field
                            name="countryId"
                            id="countryId"
                            as={Select}
                            labelId="country-label"
                            label={selectACountryStr}
                            onChange={(e: any) => {
                              handleChange(e);
                              setFieldValue('stateId', '');
                            }}
                          >
                            {Object.keys(enumList.country).map((key) => {
                              const validCountries = ['CA', 'US'];
                              const currentCountry = enumList.country[key] as ICountryValue;

                              if (!validCountries.includes(currentCountry.alpha2Code)) {
                                return null;
                              }

                              const flagComponent: JSX.Element =
                                currentCountry.alpha2Code === 'CA' ? (
                                  <CA header={currentCountry.defaultLanguageName} style={styles.flagStyle} />
                                ) : (
                                  <US header={currentCountry.defaultLanguageName} style={styles.flagStyle} />
                                );

                              return (
                                <MenuItem key={key} value={key}>
                                  <Grid sx={styles.field}>
                                    {flagComponent}
                                    {currentCountry.value}
                                  </Grid>
                                </MenuItem>
                              );
                            })}
                          </Field>
                        </FormControl>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <FormControl sx={styles.inputField} size="small">
                          <InputLabel id="state-label">
                            {values.countryId !== canadaCountryId ? selectAStateStr : selectAProvinceStr}
                          </InputLabel>
                          <Field
                            name="stateId"
                            id="stateId"
                            as={Select}
                            labelId="state-label"
                            label={values.countryId !== canadaCountryId ? selectAStateStr : selectAProvinceStr}
                          >
                            {getStateList(enumList, values.countryId, selectACountryStr)}
                          </Field>
                        </FormControl>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Field
                          name="postalCode"
                          as={TextField}
                          sx={styles.inputField}
                          label={values.countryId !== canadaCountryId ? zipCodeStr : postalCodeStr}
                          size="small"
                          helperText={touched.postalCode && errors.postalCode ? errors.postalCode : ''}
                          error={touched.postalCode && !!errors.postalCode}
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button variant="contained" onClick={handleCloseImportClick}>
                      {cancelStr}
                    </Button>
                    <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                      {t('Button_Update_Company')}
                    </LoadingButton>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Paper>
    </Dialog>
  );
};

const styles = {
  inputField: {
    marginBottom: 1,
    width: '100%',
    '& .MuiInputBase-input': {
      backgroundColor: '#ffffff'
    },
    '&.MuiFormHelperText-root': {
      color: 'red'
    },
    '& .MuiInputLabel-root': {
      color: 'red',
      '&.Mui-focused': {
        color: 'black'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: '#003366'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0071ce'
      }
    }
  },
  field: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  flagStyle: {
    width: '33px',
    minWidth: '33px',
    height: '22px',
    paddingRight: '10px',
    paddingTop: 0,
    paddingBottom: 0,
    mt: 0,
    mb: 0
  }
};

export default EditCompanyDialog;
