import { Box, Button, Grid, List, ListItem, Typography } from '@mui/material';
import { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import theme from 'styles/theme';
import { useTranslation } from 'react-i18next';
import { PlayArrowRounded } from '@mui/icons-material';
import useSharedStyles from 'styles/useSharedStyles';

interface DealerFeaturesProps {
  videoId: string;
  dealerFeaturesTitle: string;
  dealerFeaturesList: string[];
}

const DealerFeatures = ({ videoId, dealerFeaturesTitle, dealerFeaturesList }: DealerFeaturesProps) => {
  const sharedStyle = useSharedStyles();
  const { t } = useTranslation();
  const videoPlayBtn = t('VideoPlayButton');
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  return (
    <Grid container justifyContent="space-between" spacing={8}>
      <Grid item xs={12} md={6}>
        <Box sx={sharedStyle.videoContainer}>
          {isVideoPlaying ? (
            <iframe
              src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
              title={videoPlayBtn}
              allowFullScreen
              style={sharedStyle.videoContainer.thumbnail as React.CSSProperties}
            ></iframe>
          ) : (
            <>
              <Box
                component="img"
                src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`}
                alt="Video Thumbnail"
                sx={sharedStyle.videoContainer.thumbnail}
              />
              <Button
                variant="contained"
                sx={sharedStyle.videoContainer.playButton}
                size="large"
                startIcon={<PlayArrowRounded />}
                onClick={() => setIsVideoPlaying(true)}
              >
                {videoPlayBtn}
              </Button>
            </>
          )}
        </Box>
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography variant="h6">{dealerFeaturesTitle}</Typography>

        <List>
          {dealerFeaturesList.map((item, index) => (
            <ListItem key={index} sx={styles.listItem}>
              <Box sx={styles.listItemContainer}>
                <CheckIcon sx={styles.checkIcon} />
                <Typography variant="body1" color="text.secondary">
                  {item}
                </Typography>
              </Box>
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default DealerFeatures;

const styles = {
  listItem: {
    gap: 1,
    paddingLeft: 0
  },
  listItemContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 1
  },
  checkIcon: {
    color: theme.palette.green.main
  }
};
