import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  LinearProgress,
  Tab,
  Typography
} from '@mui/material';
import { Portal } from '@mui/base/Portal';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { SiteContext } from 'context/SiteContext';
import { useContext, useState } from 'react';
import { TabContext, TabList } from '@mui/lab';
import { updateSite } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';
import NewSiteDialog from './CreateNewSite/NewSiteDialog';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import useSharedStyles from 'styles/useSharedStyles';
import { getColorFromStatus } from 'shared/utils/Status';
import { ISiteInfo } from 'store/slices/siteSlice';
import { EnumList, fetchLocalEnumList, IStateValue } from 'shared/utils/EnumUtils';
import DialogWrapper from 'shared/components/dialogs/DialogWrapper';
import LoginPanel from './SiteDashboard/Dialogs/LoginPanel';
import { getSetUsernameAndToken } from './SiteDashboard/utils/utils';

interface Props {
  isFetching: boolean;
  mySites: ISiteInfo[] | [];
  myOrganizationSites: ISiteInfo[] | [];
  canCreateSites: boolean;
}

//TODO: Create tabs for each branch the user has access to and display the sites for each branch. Right now there is no API to get the sites for each branch.
const Sites = ({ isFetching, mySites, myOrganizationSites, canCreateSites }: Props) => {
  const [isLoginPanelOpen, setIsLoginPanelOpen] = useState(false);
  const [isNewSiteDialogOpen, setIsNewSiteDialogOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState('1');
  const { setSiteName, setPublicId } = useContext(SiteContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sharedStyle = useSharedStyles();
  const setUsernameAndToken = getSetUsernameAndToken(setIsLoginPanelOpen, dispatch);
  const { t } = useTranslation();
  const cityStr = t('Shared.City');
  const createNewSiteStr = t('Site.CreateNewSite');
  const deviceTabSettingStr = t('Shared.StationTabSetting');
  const errorDescriptionStr = t('Site.ErrorDescription');
  const errorStr = t('Shared.Error');
  const lastUpdatedStr = t('Shared.LastUpdated');
  const myOrganizationSitesStr = t('Site.MyOrganizationSites');
  const mySitesStr = t('Site.MySites');
  const registeredGatewayStr = t('Site.RegisteredGateway');
  const registeredStr = t('Shared.Registered');
  const searchPlaceholderStr = t('Site.SearchPlaceholder');
  const siteIdStr = t('Site.ID');
  const siteNameStr = t('Site.Name');
  const siteTypeStr = t('Site.SiteType');
  const stateStr = t('Shared.State');
  const unregisteredStr = t('Shared.Unregistered');
  const loginPanelTitle = t('IXGLogin.LoginPanel.ManageSitesTitle');
  const enumList: EnumList = fetchLocalEnumList();
  const aclToken = localStorage.getItem('acltoken');

  const branchPermissionsList = useSelector((state: RootState) => state.users.currentUser?.permissions?.branch ?? {});
  const primaryBranchPublicId = Object.keys(branchPermissionsList)[0];

  // Move the permission check logic here
  const { isAllowedTo } = usePermission();
  const canViewMyOrganizationSites = isAllowedTo('site:view', primaryBranchPublicId, PermissionsContextType.BRANCH);

  const mySitesError = false;
  const myOrganizationSitesError = false;

  const handleClose = () => {
    setIsLoginPanelOpen(false);
  };

  const handleTabChange = (event: any, newValue: string) => {
    setTabIndex(newValue);
  };

  const openSiteInfo = (params: any) => {
    if (tabIndex === '2' && !canViewMyOrganizationSites) return;

    dispatch(
      updateSite({
        publicId: params.row.id,
        siteName: params.row.siteName
      })
    );
    setSiteName(params.row.siteName);
    setPublicId(params.row.publicId);
    navigate(`/site/${params.id}/dashboard`);
  };

  function CustomSearchToolbar() {
    return (
      <>
        <Portal container={() => document.getElementById('filter-panel')}>
          <Box sx={sharedStyle.toolbarContainer}>
            <GridToolbarQuickFilter
              variant="outlined"
              size="small"
              sx={sharedStyle.toolbarContainer.searchInput}
              placeholder={searchPlaceholderStr}
            />
            {canCreateSites && (
              <Button
                variant="contained"
                size="medium"
                onClick={() => (aclToken ? setIsNewSiteDialogOpen(true) : setIsLoginPanelOpen(true))}
              >
                <Box sx={sharedStyle.inlineBox}>
                  <AddIcon sx={sharedStyle.inlineBox.buttonIcon} />
                  {createNewSiteStr}
                </Box>
              </Button>
            )}
          </Box>
        </Portal>
      </>
    );
  }

  const mySitesRecords = mySites
    ? Object.entries(mySites).map(([key, value]) => ({
        id: key,
        siteId: value.aclSiteId,
        siteName: value.siteName,
        siteType: enumList.siteType[value.typeId].value,
        state: (enumList.state[value.stateId] as IStateValue).isoCode,
        city: value.siteCity,
        registeredGateway: value.registeredGatewayPublicId ? registeredStr : unregisteredStr,
        lastUpdated: value.lastUpdatedOn
      }))
    : [];

  const myOrganizationSitesRecords = myOrganizationSites
    ? Object.entries(myOrganizationSites).map(([key, value]) => ({
        id: key,
        siteId: value.aclSiteId,
        siteName: value.siteName,
        siteType: enumList.siteType[value.typeId].value,
        state: (enumList.state[value.stateId] as IStateValue).isoCode,
        city: value.siteCity,
        registeredGateway: value.registeredGatewayPublicId ? registeredStr : unregisteredStr,
        lastUpdated: value.lastUpdatedOn
      }))
    : [];

  const renderDataGrid = (rows: any, error: boolean, disableClick: boolean) => (
    <Box>
      {error ? (
        <Alert severity="error" sx={sharedStyle.spacingBottom}>
          <AlertTitle>{errorStr}</AlertTitle>
          {errorDescriptionStr}
        </Alert>
      ) : (
        <Grid container sx={[sharedStyle.gridContainer, sharedStyle.common.gap.xs]}>
          <Grid item xs={12}>
            <Box id="filter-panel" />
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              initialState={{
                sorting: {
                  sortModel: [{ field: 'lastUpdated', sort: 'desc' }]
                }
              }}
              onRowClick={!disableClick ? openSiteInfo : undefined}
              columns={[
                { field: 'siteId', headerName: siteIdStr, flex: 1 },
                { field: 'siteName', headerName: siteNameStr, flex: 1 },
                { field: 'siteType', headerName: siteTypeStr, flex: 0.75 },
                { field: 'state', headerName: stateStr, flex: 0.55 },
                { field: 'city', headerName: cityStr, flex: 0.69 },
                {
                  field: 'registeredGateway',
                  headerName: registeredGatewayStr,
                  flex: 1,
                  renderCell: (params) => (
                    <Chip
                      sx={sharedStyle.chip}
                      color={getColorFromStatus(params.value)}
                      size="medium"
                      variant="outlined"
                      label={<Typography variant="body2">{params.value}</Typography>}
                    />
                  )
                },
                { field: 'lastUpdated', headerName: lastUpdatedStr, flex: 1, sortingOrder: ['asc', 'desc'] }
              ]}
              slots={{
                loadingOverlay: LinearProgress,
                toolbar: CustomSearchToolbar
              }}
              rows={rows}
              loading={isFetching}
              autoHeight
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );

  const cardStyle = [sharedStyle.common.width.full];

  return (
    <>
      <Card sx={cardStyle}>
        <CardContent>
          <TabContext value={tabIndex}>
            <Box sx={[sharedStyle.gridContainer, sharedStyle.common.gap.xs]}>
              <TabList onChange={handleTabChange} aria-label={deviceTabSettingStr}>
                <Tab label={mySitesStr} value="1" />
                <Tab label={myOrganizationSitesStr} value="2" />
              </TabList>
              <Box>
                {tabIndex === '1' && renderDataGrid(mySitesRecords, mySitesError, false)}
                {tabIndex === '2' &&
                  renderDataGrid(myOrganizationSitesRecords, myOrganizationSitesError, !canViewMyOrganizationSites)}
              </Box>
            </Box>
          </TabContext>
        </CardContent>
      </Card>
      <DialogWrapper
        header={loginPanelTitle}
        setIsOpen={setIsLoginPanelOpen}
        open={isLoginPanelOpen}
        onClose={handleClose}
      >
        <LoginPanel
          setUsernameAndToken={setUsernameAndToken}
          setIsLoginPanelOpen={setIsLoginPanelOpen}
          setIsNewSiteDialogOpen={setIsNewSiteDialogOpen}
        />
      </DialogWrapper>
      <NewSiteDialog isOpen={isNewSiteDialogOpen} setIsOpen={setIsNewSiteDialogOpen} />
    </>
  );
};

export default Sites;
