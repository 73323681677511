import React from 'react';
import {
  Box,
  TextField,
  Button,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import DownloadFilled from '@mui/icons-material/Download';
import { LicenseManagementCommonStyles } from 'features/LicenseManagement/styles/CommonStyles';
import AddIcon from '@mui/icons-material/Add';
import { useGetBranchesWithCompanyPublicIdQuery } from 'services/aiphoneCloud';

interface ActionContainerProps {
  canCreateDeployment: boolean;
  searchQuery: string;
  hasDeployments: boolean;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDeploymentTypeModalOpen: () => void;
  isAdminWithNoBranches: boolean;
  companyList: any[];
  setAdminSelectedBranchPublicId: (branchId: string) => void; // Added to handle branch selection
}

const ActionContainer = ({
  canCreateDeployment,
  searchQuery,
  hasDeployments,
  handleSearchChange,
  handleDeploymentTypeModalOpen,
  isAdminWithNoBranches,
  companyList,
  setAdminSelectedBranchPublicId
}: ActionContainerProps) => {
  const { t } = useTranslation('acNioLicenseManagement');
  const { t: tCommon } = useTranslation('common');

  //#region Strings
  const companyStr = tCommon('Company.Singular');
  const branchStr = tCommon('Branch.Singular');
  const downloadAcNioBtn = t('Download_AcNio_Software.Btn');
  const deployNewLicenseBtn = t('Deploy_New_License');
  //#endregion

  const [selectedCompany, setSelectedCompany] = React.useState('');
  const [selectedBranch, setSelectedBranch] = React.useState('');

  const { data: branches, isFetching: isFetchingBranches } = useGetBranchesWithCompanyPublicIdQuery(selectedCompany, {
    skip: !selectedCompany
  });

  const formattedOptions = Object.entries(companyList)
    .map(([key, value]) => {
      return { publicId: key, name: value.name };
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  const formattedBranches = Object.entries(branches || {})
    .map(([key, value]) => {
      const branch = value as { branchName: string };
      return { publicId: key, branchName: branch.branchName };
    })
    .sort((a, b) => a.branchName.localeCompare(b.branchName));

  const handleCompanyChange = (event: SelectChangeEvent<string>) => {
    const companyId = event.target.value as string;
    setSelectedCompany(companyId);
    setSelectedBranch('');
  };

  const handleBranchChange = (event: SelectChangeEvent<string>) => {
    setSelectedBranch(event.target.value as string);
    setAdminSelectedBranchPublicId(event.target.value as string);
  };

  return (
    <Box>
      {isAdminWithNoBranches && (
        <Box sx={styles.selectContainer}>
          <FormControl fullWidth>
            <InputLabel id="company-select-label">{companyStr}</InputLabel>
            <Select
              labelId="company-select-label"
              value={selectedCompany}
              label={companyStr}
              onChange={handleCompanyChange}
            >
              {formattedOptions.map((company) => (
                <MenuItem key={company.publicId} value={company.publicId}>
                  {company.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="branch-select-label">{branchStr}</InputLabel>
            <Select
              labelId="branch-select-label"
              value={selectedBranch}
              label={branchStr}
              onChange={handleBranchChange}
              disabled={!selectedCompany || isFetchingBranches}
            >
              {formattedBranches?.map((branch) => (
                <MenuItem key={branch.publicId} value={branch.publicId}>
                  {branch.branchName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      <Box sx={styles.actionContainer}>
        {hasDeployments && (
          <TextField
            variant="outlined"
            placeholder={t('Search_Placeholder')}
            sx={styles.searchField}
            value={searchQuery}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        )}
        <Box sx={styles.btnContainer}>
          {/* TODO: Add onClick handler to download AC Nio Software */}
          <Button
            variant="outlined"
            color="primary"
            size="small"
            sx={LicenseManagementCommonStyles.button}
            startIcon={<DownloadFilled />}
          >
            {downloadAcNioBtn}
          </Button>
          {canCreateDeployment && (
            <Button
              variant="contained"
              color="primary"
              sx={LicenseManagementCommonStyles.button}
              onClick={handleDeploymentTypeModalOpen}
              startIcon={<AddIcon />}
            >
              {deployNewLicenseBtn}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  selectContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    marginBottom: '1rem',
    width: '25.625rem'
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1rem',
    marginBottom: '2rem',
    height: '2.5rem'
  },
  searchField: {
    width: '25.625rem',
    '& .MuiInputBase-root': {
      height: '2.8rem',
      '&.Mui-focused fieldset': {
        borderColor: '#1CACFE'
      }
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1CACFE'
    }
  },
  btnContainer: {
    display: 'flex',
    gap: '10px'
  }
};

export default ActionContainer;
