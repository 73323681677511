import { Alert, AlertTitle, Button, Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CachedIcon from '@mui/icons-material/Cached';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorType, ErrorTypeValues } from 'shared/api/ApiError';
import { selectAclSite, resetAclSites } from 'store';
import { useAppDispatch } from 'store/hooks';
import Spinner from 'features/SimBilling/Components/UiParts/Spinner';
import { useAclAuth, useFetchACLSites, useReduxSites } from 'features/SimBilling/Hooks';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { t } = useTranslation('simbillings');
  const siteNameStr = t('Site.Name');
  const siteIdStr = t('Site.ID');
  const addressStr = t('Shared.Address');

  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { aclToken } = useAclAuth();
  const { aclSites, errorFetchAclSites } = useReduxSites();
  const { fetchSites } = useFetchACLSites();

  useEffect(() => {
    const fetchData = async () => {
      if (!aclSites && aclToken !== null) {
        await fetchSites(aclToken);
      }
    };
    fetchData();
  }, [aclSites, aclToken, fetchSites]);

  useEffect(() => {
    if (errorFetchAclSites === undefined || errorFetchAclSites === null) {
      return;
    }
    const isValidErrorKey = (key: any): key is keyof typeof ErrorTypeValues => {
      return key in ErrorTypeValues;
    };

    if (isValidErrorKey(errorFetchAclSites)) {
      setErrorMessage(ErrorTypeValues[errorFetchAclSites].message2);
    } else {
      setErrorMessage(ErrorTypeValues[ErrorType.UNEXPECTED].message2);
    }
  }, [errorFetchAclSites]);

  const openSiteInfo = async (params: any) => {
    dispatch(selectAclSite(params.id));
    navigate(`/simbilling/sites/${params.id}/site`);
  };

  const handleSyncDataWithAcl = async () => {
    setLoading(true);
    dispatch(resetAclSites());
    if (aclToken !== null) {
      await fetchSites(aclToken);
    }
    setLoading(false);
  };

  const getStatusStyles = (status: string | undefined) => {
    switch (status) {
      case 'active':
        return { color: '#4caf50', fontWeight: 'bold' };
      case 'canceled':
        return { color: '#f44336', fontWeight: 'bold' };
      case 'past_due':
        return { color: '#ff9800', fontWeight: 'bold' };
      default:
        return { color: 'black', fontWeight: 'bold' };
    }
  };

  const renderSites = () => {
    if (errorMessage.trim() !== '') {
      return (
        <Alert severity="error" sx={styles.error}>
          <AlertTitle>{t('Home.Error')}</AlertTitle>
          {errorMessage}
        </Alert>
      );
    } else if (aclSites === undefined) {
      return (
        <>
          <Spinner />
        </>
      );
    } else if (aclSites && Array.isArray(aclSites) && aclSites.length > 0) {
      return (
        <DataGrid
          initialState={{ sorting: { sortModel: [{ field: 'subscriptionStatus', sort: 'desc' }] } }}
          onRowClick={openSiteInfo}
          columns={[
            { field: 'id', headerName: siteIdStr, width: 100 },
            { field: 'siteName', headerName: siteNameStr, width: 200 },
            { field: 'siteAddress', headerName: addressStr, width: 300 },
            {
              field: 'subscriptionStatus',
              headerName: 'Subscription Status',
              width: 300,
              renderCell: (params) => <Typography style={getStatusStyles(params.value)}>{params.value}</Typography>
            }
          ]}
          rows={aclSites.map((site) => ({
            id: site.PropertyID,
            siteName: site.PropertyName && site.PropertyName.length > 0 ? site.PropertyName[0] : '-',
            siteAddress:
              (site.PropertyAddressDetail?.Address1 || '-') +
              ', ' +
              (site.PropertyAddressDetail?.City || '-') +
              ', ' +
              (site.PropertyAddressDetail?.State || '-') +
              ' ' +
              (site.PropertyAddressDetail?.ZipCode || '-'),
            subscriptionStatus: site.SubscriptionStatus
          }))}
        />
      );
    } else {
      return <Typography sx={{ mt: 2 }}>{t('Home.No_Sites')}</Typography>;
    }
  };

  return (
    <>
      {loading && <Spinner />}
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Typography variant="sectionHeader" sx={styles.pageTitle}>
                {t('Home.Title')}
              </Typography>
              <Typography sx={styles.pageTitle}>{t('Home.Description')}</Typography>
            </Grid>
          </Grid>
          <Typography variant="h5" sx={styles.tableTitle}>
            {t('Home.My_Sites')}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button startIcon={<CachedIcon />} onClick={handleSyncDataWithAcl}>
              {t('Home.Sync_Data')}
            </Button>
          </Box>
          <Box sx={styles.featuredAppContainer}>{renderSites()}</Box>
        </CardContent>
      </Card>
    </>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  featuredAppContainer: {
    width: '100%',
    position: 'relative',
    padding: 1
  },
  error: {
    mb: 1
  },
  pageTitle: {
    padding: 1
  },
  tableTitle: {
    paddingTop: 1,
    paddingLeft: 1,
    marginBottom: -1
  }
};

export default Dashboard;
