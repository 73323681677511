import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import InfoScreen from '../../../layouts/components/InfoScreen';
import GppBadIcon from '@mui/icons-material/GppBad';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/utils/StringUtils';

export const LoginAttemptLimitReached: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const loginStr = t('Shared.Login');
  const returnToItemStr = t('Shared.ReturnToItem');
  const returnToLoginStr = StringUtils.format(returnToItemStr, loginStr);

  return (
    <>
      <InfoScreen
        title={t('WAF_Login_Limit_Title')}
        message={t('WAF_Login_Limit_Description')}
        iconComponent={<GppBadIcon sx={styles.icon} />}
      />
      <Grid container justifyContent="center">
        <Grid item>
          <Button sx={styles.returnToLogin} onClick={() => navigate('/auth/login')}>
            {returnToLoginStr}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const styles = {
  returnToLogin: {
    color: '#0071CE',
    fontFamily: "'Roboto Condensed', sans-serif",
    fontWeight: 'bold',
    marginTop: 2
  },
  icon: { fontSize: '48px', color: 'grey' }
};
