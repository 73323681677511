import { useQuery, useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  createActivatedLicenseWithCard,
  fetchLicenseById,
  validateLicenseCard,
  rearmLicense,
  fetchLicenseByIdUsingLongPolling
} from '../lib/api/LicenseManagementApi';
import {
  CreateActivatedLicenseWithCardRequest,
  LicenseDetailResponse,
  RearmLicenseRequest,
  RearmLicenseResponse,
  ValidateLicenseCardRequest,
  ValidateLicenseCardResponse,
  REACT_QUERY_TEN_MINUTES_STALE_TIME
} from '../components/common/Types';
import { useCurrentBranchWithReactQuery } from './useCurrentBranchWithReactQuery';

export const useLicenseById = (licenseId: string) => {
  const { data: currentBranch } = useCurrentBranchWithReactQuery();
  const branchId = currentBranch?.publicId;
  return useQuery<LicenseDetailResponse>({
    queryKey: ['license', licenseId],
    queryFn: () => fetchLicenseById(branchId ?? '', licenseId),
    staleTime: REACT_QUERY_TEN_MINUTES_STALE_TIME,
    enabled: !!licenseId
  });
};

export const useLicenseByIdByLongPolling = (licenseId: string, aiphoneBranchPublicId: string | null) => {
  let branchId = null;
  if (!aiphoneBranchPublicId) {
    const { data: currentBranch } = useCurrentBranchWithReactQuery();
    branchId = currentBranch?.publicId;
  } else {
    branchId = aiphoneBranchPublicId;
  }

  return useQuery<LicenseDetailResponse>({
    queryKey: ['license', licenseId],
    queryFn: () => fetchLicenseByIdUsingLongPolling(branchId ?? '', licenseId),
    staleTime: REACT_QUERY_TEN_MINUTES_STALE_TIME,
    enabled: !!licenseId
  });
};

export const useValidateLicenseCard = (
  onSuccessCallback?: (data: ValidateLicenseCardResponse) => void,
  onErrorCallback?: (error: unknown) => void
) => {
  const mutationOptions: UseMutationOptions<
    ValidateLicenseCardResponse,
    Error,
    {
      aiphoneBranchPublicId: string;
      data: ValidateLicenseCardRequest;
    }
  > = {
    mutationFn: async ({ aiphoneBranchPublicId, data }) => {
      return await validateLicenseCard(aiphoneBranchPublicId, data);
    },
    onSuccess: (data) => {
      if (onSuccessCallback) {
        onSuccessCallback(data);
      }
    },
    onError: (error) => {
      if (onErrorCallback) {
        onErrorCallback(error);
      }
    }
  };

  return useMutation(mutationOptions);
};

export const useCreateActivatedLicenseWithCard = (
  onSuccessCallback?: (response: LicenseDetailResponse, aiphoneBranchPublicId: string) => void,
  onErrorCallback?: (error: unknown) => void
) => {
  const mutationOptions: UseMutationOptions<
    LicenseDetailResponse,
    Error,
    {
      aiphoneBranchPublicId: string;
      deploymentId: string;
      data: CreateActivatedLicenseWithCardRequest;
    }
  > = {
    mutationFn: async ({ aiphoneBranchPublicId, deploymentId, data }) => {
      return await createActivatedLicenseWithCard(aiphoneBranchPublicId, deploymentId, data);
    },
    onSuccess: (response, { aiphoneBranchPublicId }) => {
      if (onSuccessCallback) {
        onSuccessCallback(response, aiphoneBranchPublicId);
      }
    },
    onError: (error) => {
      if (onErrorCallback) {
        onErrorCallback(error);
      }
    }
  };

  return useMutation(mutationOptions);
};

export const useRearmLicense = (
  onSuccessCallback?: (data: RearmLicenseResponse) => void,
  onErrorCallback?: (error: unknown) => void
) => {
  const mutationOptions: UseMutationOptions<
    RearmLicenseResponse,
    Error,
    {
      aiphoneBranchPublicId: string;
      data: RearmLicenseRequest;
    }
  > = {
    mutationFn: async ({ aiphoneBranchPublicId, data }) => {
      return await rearmLicense(aiphoneBranchPublicId, data);
    },
    onSuccess: (data) => {
      if (onSuccessCallback) {
        onSuccessCallback(data);
      }
    },
    onError: (error) => {
      if (onErrorCallback) {
        onErrorCallback(error);
      }
    }
  };

  return useMutation(mutationOptions);
};
