import { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  useGetSiteWithPublicIdQuery,
  useGetTenantListWithSiteIdQuery,
  useGetDeviceListWithSitePublicIdQuery,
  useGetBuildingsQuery,
  useLazyGetUnitListWithSitePublicIdQuery,
  useGetAppWithSiteIdQuery
} from 'services/aiphoneCloud';
import { clearSiteData, setSiteLoading } from 'store/slices/siteSlice';
import CONFIG from 'config';
import { setLatestFirmwareList } from 'store/slices/devicesSlice';

function useFetchFirmware() {
  const dispatch = useDispatch();
  const [isFirmwareLoading, setFirmwareLoading] = useState(true);

  useEffect(() => {
    const fetchLatestFirmwareList = async () => {
      try {
        const response = await fetch(`${CONFIG.openApiEndpoint}/firmware/latest`);
        if (response.ok) {
          const data = await response.json();
          dispatch(setLatestFirmwareList(data));
        }
      } catch (error) {
        console.error('Failed to fetch latest firmware list:', error);
      } finally {
        setFirmwareLoading(false);
      }
    };

    fetchLatestFirmwareList();
  }, [dispatch]);

  return isFirmwareLoading;
}

export function useSiteDataLoader(siteId: string) {
  const dispatch = useDispatch();
  const isFirmwareLoading = useFetchFirmware();

  // Clear previous site data when siteId changes
  useEffect(() => {
    if (siteId) dispatch(clearSiteData());
  }, [siteId, dispatch]);

  // Trigger RTK queries
  const { isLoading: isSiteLoading } = useGetSiteWithPublicIdQuery(siteId, { skip: !siteId });

  const { isLoading: isAppsLoading } = useGetAppWithSiteIdQuery(
    { sitePublicId: siteId, qty: 500, page: 0 },
    { skip: !siteId }
  );

  const { isLoading: isTenantLoading } = useGetTenantListWithSiteIdQuery(
    { sitePublicId: siteId, qty: 500, page: 0 },
    { skip: !siteId }
  );
  const { isLoading: isDeviceLoading } = useGetDeviceListWithSitePublicIdQuery(
    { sitePublicId: siteId, qty: 500, page: 0 },
    { skip: !siteId }
  );
  const {
    data: buildingData,
    isLoading: isBuildingLoading,
    isSuccess: hasBuildingData
  } = useGetBuildingsQuery(siteId, { skip: !siteId });
  const [fetchUnits, { isLoading: isUnitLoading }] = useLazyGetUnitListWithSitePublicIdQuery();

  // Fetch units for primary building
  useEffect(() => {
    if (hasBuildingData && buildingData) {
      const primaryBuilding = Array.isArray(buildingData)
        ? buildingData.find((b) => b.buildingNumber === '0001')
        : Object.values(buildingData).find((b: any) => b.buildingNumber === '0001');

      if (primaryBuilding) {
        fetchUnits({ sitePublicId: siteId, qty: 500, page: 0 });
      }
    }
  }, [hasBuildingData, buildingData, siteId, fetchUnits]);

  // Aggregate loading state
  const isLoading = useMemo(
    () =>
      isFirmwareLoading ||
      isSiteLoading ||
      isAppsLoading ||
      isTenantLoading ||
      isDeviceLoading ||
      isBuildingLoading ||
      isUnitLoading,
    [
      isFirmwareLoading,
      isSiteLoading,
      isAppsLoading,
      isTenantLoading,
      isDeviceLoading,
      isBuildingLoading,
      isUnitLoading
    ]
  );

  // Update Redux loading state
  useEffect(() => {
    dispatch(setSiteLoading(isLoading));
  }, [isLoading, dispatch]);
}
