import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Card, Box, TextField, MenuItem, Button } from '@mui/material';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { useTranslation } from 'react-i18next';

interface IAddMobileAppCardProps {
  unitTypeMenu: string[];
  onApply: (selectedUnitIds: string[]) => void;
}

const AddMobileAppCard = ({ unitTypeMenu, onApply }: IAddMobileAppCardProps) => {
  const { t } = useTranslation();
  const applyStr = t('Shared.Apply');
  const UnitListByType = useSelector((state: RootState) => state.units.UnitListByType);
  const [selectedUnitType, setSelectedUnitType] = useState<string>(t('All_Eligible'));

  const [selectedUnitIds, setSelectedUnitIds] = useState<string[]>([]);
  const selectedUnitIdsRef = useRef(selectedUnitIds);

  const handleSelectedUnitType = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    setSelectedUnitType(value);

    switch (value) {
      case 'All eligible': {
        const guardUnitIds = UnitListByType.Guard;
        const commercialUnitIds = UnitListByType.Commercial;
        const residentialUnitIds = UnitListByType.Residential;
        setSelectedUnitIds([...guardUnitIds, ...commercialUnitIds, ...residentialUnitIds]);
        selectedUnitIdsRef.current = [...guardUnitIds, ...commercialUnitIds, ...residentialUnitIds];
        break;
      }
      case 'Guard':
        setSelectedUnitIds(UnitListByType.Guard);
        break;
      case 'Commercial':
        setSelectedUnitIds(UnitListByType.Commercial);
        break;
      case 'Residential':
        setSelectedUnitIds(UnitListByType.Residential);
        break;
      default:
        setSelectedUnitIds([]);
        break;
    }
  };

  const handleApplySelection = () => {
    /**
     * By default, it is set to include `all eligible` units, which will automatically add all units to the selectedUnitIds list.
     */
    if (selectedUnitIds.length === 0) {
      handleSelectedUnitType({ target: { value: selectedUnitType } } as React.ChangeEvent<HTMLInputElement>);
      onApply(selectedUnitIdsRef.current);
    } else {
      onApply(selectedUnitIds);
    }
  };

  return (
    <Card variant="outlined" sx={styles.mainCardContainer}>
      <Box sx={styles.iconContainer}>
        <PhoneIphoneIcon color="primary" />
      </Box>
      <Box sx={styles.fieldTextContainer}>
        {t('Add_IXG_Mobile_App_To')}
        <TextField
          select
          label={t('Select_Unit_Type')}
          size="small"
          value={selectedUnitType}
          onChange={(e) => handleSelectedUnitType(e)}
          sx={styles.fieldMenuContainer}
        >
          {unitTypeMenu.map((unitType, index) => (
            <MenuItem key={index} value={unitType}>
              {unitType}
            </MenuItem>
          ))}
        </TextField>
        {t('Unit_UpTo8')}
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Button variant="outlined" color="primary" sx={styles.buttonContainer} onClick={handleApplySelection}>
        {applyStr}
      </Button>
    </Card>
  );
};

const styles = {
  mainCardContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px',
    marginY: '15px'
  },
  iconContainer: {
    border: 'solid #d3d3d3 1px',
    padding: '5px',
    borderRadius: '50%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '5px'
  },
  fieldTextContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  fieldMenuContainer: {
    width: '200px',
    marginX: '10px'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
};
export default AddMobileAppCard;
