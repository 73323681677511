import { useSelector } from 'react-redux';
import { RootState } from 'store';

interface Unit {
  unitNumber: string;
  unitName: string;
  unitType: number;
  devicePublicIds: (string | null)[];
}

interface IWizardDevice {
  deviceModel: number;
  deviceType: number;
  firmwareVersion: string;
  macAddress: string;
  stationName: string;
  stationNumber: string;
}

interface ICreateDevicesMap {
  macAddress: string;
  publicId: string;
}

const useConfigureWizardCommUnits = () => {
  const buildingPublicId = useSelector((state: RootState) => Object.keys(state.buildings.BuildingList)[0]);
  const units = useSelector((state: RootState) => state.units);
  const newUnits: Unit[] = [];
  let totalUnitCount = units.UnitList ? Object.keys(units.UnitList).length + 1 : 1;
  let commercialUnitCount = units.UnitListByType[5] ? units.UnitListByType[5].length + 1 : 1;
  let outsideAreaUnitCount = units.UnitListByType[6] ? units.UnitListByType[6].length + 1 : 1;
  let guardUnitCount = units.UnitListByType[1] ? units.UnitListByType[1].length + 1 : 1;
  let entranceUnitCount = units.UnitListByType[2] ? units.UnitListByType[2].length + 1 : 1;
  let residentialUnitCount = units.UnitListByType[4] ? units.UnitListByType[4].length + 1 : 1;

  const filterDevices = (devices: IWizardDevice[], types: number[]) => {
    return devices.filter((device) => types.includes(device.deviceType));
  };

  const getDevicePublicId = (createDevicesMap: ICreateDevicesMap[], macAddress: string) => {
    const device = createDevicesMap.find((device) => device.macAddress === macAddress);
    return device ? device.publicId : null;
  };

  const generateUnits = (
    devices: any,
    createDevicesMap: ICreateDevicesMap[]
  ): {
    newUnitsArray: Unit[];
    buildingPublicId: string;
  } => {
    const masterStations = filterDevices(devices, [4, 5]);
    const doorStations = filterDevices(devices, [8, 9, 10, 11, 12, 20]);
    const guardStations = filterDevices(devices, [16]);
    const entranceStations = filterDevices(devices, [15]);
    const residentialStations = filterDevices(devices, [14]);

    // Create commercial units for every 8 master stations
    for (let i = 0; i < masterStations.length; i += 8) {
      const unitDevices = masterStations.slice(i, i + 8);
      const newUnit: Unit = {
        unitNumber: `${totalUnitCount}`.padStart(4, '0'),
        unitName: `Commercial ${commercialUnitCount}`,
        unitType: 5,
        devicePublicIds: unitDevices.map((device: IWizardDevice) =>
          getDevicePublicId(createDevicesMap, device.macAddress)
        )
      };
      totalUnitCount++;
      commercialUnitCount++;
      newUnits.push(newUnit);
    }

    // Create an outside area unit for every 10 door stations
    for (let i = 0; i < doorStations.length; i += 10) {
      const unitDevices = doorStations.slice(i, i + 10);
      const newUnit: Unit = {
        unitNumber: `${totalUnitCount}`.padStart(4, '0'),
        unitName: `OutsideArea ${outsideAreaUnitCount}`,
        unitType: 6,
        devicePublicIds: unitDevices.map((device: IWizardDevice) =>
          getDevicePublicId(createDevicesMap, device.macAddress)
        )
      };
      totalUnitCount++;
      outsideAreaUnitCount++;
      newUnits.push(newUnit);
    }

    // Create guard units for every 8 guard stations
    for (let i = 0; i < guardStations.length; i += 8) {
      const unitDevices = guardStations.slice(i, i + 8);
      const newUnit: Unit = {
        unitNumber: `${totalUnitCount}`.padStart(4, '0'),
        unitName: `Guard ${guardUnitCount}`,
        unitType: 1,
        devicePublicIds: unitDevices.map((device: IWizardDevice) =>
          getDevicePublicId(createDevicesMap, device.macAddress)
        )
      };
      totalUnitCount++;
      guardUnitCount++;
      newUnits.push(newUnit);
    }

    // Create entrance units for every 10 entrance stations
    for (let i = 0; i < entranceStations.length; i += 10) {
      const unitDevices = entranceStations.slice(i, i + 10);
      const newUnit: Unit = {
        unitNumber: `${totalUnitCount}`.padStart(4, '0'),
        unitName: `Entrance ${entranceUnitCount}`,
        unitType: 2,
        devicePublicIds: unitDevices.map((device: IWizardDevice) =>
          getDevicePublicId(createDevicesMap, device.macAddress)
        )
      };
      totalUnitCount++;
      entranceUnitCount++;
      newUnits.push(newUnit);
    }

    // Create residential units for every 8 residential stations
    for (let i = 0; i < residentialStations.length; i += 8) {
      const unitDevices = residentialStations.slice(i, i + 8);
      const newUnit: Unit = {
        unitNumber: `${totalUnitCount}`.padStart(4, '0'),
        unitName: `Residential ${residentialUnitCount}`,
        unitType: 4,
        devicePublicIds: unitDevices.map((device: IWizardDevice) =>
          getDevicePublicId(createDevicesMap, device.macAddress)
        )
      };
      totalUnitCount++;
      residentialUnitCount++;
      newUnits.push(newUnit);
    }

    // If we have no app capable units then we should create 1 extra commercial unit to house apps.
    // There start by 1 so 1 means we have no app capable units.
    if (commercialUnitCount === 1 && residentialUnitCount === 1 && guardUnitCount === 1) {
      const newUnit: Unit = {
        unitNumber: `${totalUnitCount}`.padStart(4, '0'),
        unitName: `Commercial ${commercialUnitCount}`,
        unitType: 5,
        devicePublicIds: []
      };
      newUnits.push(newUnit);
    }

    const params = {
      newUnitsArray: newUnits,
      buildingPublicId: buildingPublicId
    };

    return params;
  };

  return { generateUnits };
};

export default useConfigureWizardCommUnits;
