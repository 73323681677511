export interface chartDataItem {
  label: string;
  value: number;
  color?: string;
}

export interface barChartDataItem {
  category: string;
  labels: string[];
  values: number[];
  colors: string[];
}

export enum TimeframeOptions {
  ALL_TIME = 'All Time',
  SIX_MONTHS = '6 Months',
  THIS_MONTH = 'This Month'
}
