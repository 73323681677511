import { Alert, AlertTitle, Box, Button, List, ListItem, Typography } from '@mui/material';
import GeneralInfo from '../../UiParts/GeneralInfo';
import { updateFormValuesGT } from 'features/QuikSpec/Utils/QuikspecUpdateFormValuesGT';
import {
  gtEntranceAndGuard,
  gtOptionalAccessories,
  gtSystemConstraints,
  gtTenantStations,
  gtHidden
} from '../../../Types/GTTypes';
import { acControlKits, acLicenses, acReaderKits, acHidden, acSystemConstraints } from '../../../Types/ACTypes';
import { useState } from 'react';
import QuikspecCategoryTabs from '../../UiParts/QuikspecCategoryTabs';
import QuikspecCategoryGtEntranceGuard from '../../UiParts/QuikspecCategoryGtEntranceGuard';
import QuikspecCategoryAcControllers from '../../UiParts/QuikspecCategoryAcControllers';
import QuikspecCategoryAcLicenses from '../../UiParts/QuikspecCategoryAcLicenses';
import { Form, Formik } from 'formik';
import QuikspecSubmitButton from '../../UiParts/QuikspecSubmitButton';
import QuikspecSystemConstraints from '../../UiParts/QuikspecSystemConstraints';
import { useNavigate, useLocation } from 'react-router-dom';
import QuikspecNote from 'features/QuikSpec/Components/UiParts/QuikspecNote';
import { FormValues } from '../../../Types/QuikspecFormTypes';
import getInitialFormValues from 'features/QuikSpec/Utils/QuikspecInitialFormValues';
import { createQuikSpecAndNavigate, updateQuikspecAndNavigate } from '../../../Utils/QuikspecActionAndNavigate';
import useQuikSpecFormValidationSchema from 'features/QuikSpec/Hooks/useQuikSpecFormValidationSchema';
import { useTranslation } from 'react-i18next';
import { useQuikspecFeaturedSystems } from 'features/QuikSpec/Hooks/quikSpecDevices';
import useC2 from 'features/QuikSpec/Hooks/useC2';

const QuikspecGT = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [acOpen, setACOpen] = useState(false);
  const location = useLocation();
  const state = location.state || {};
  const siteName: string = location.state.siteName as string;
  const FeaturedSystems = useQuikspecFeaturedSystems();
  const { C2ApiFailed } = useC2();
  const siteIndex = FeaturedSystems.findIndex((system) => system.siteName === siteName);
  const systemName = FeaturedSystems[siteIndex].seriesName;
  const systemforC2 = FeaturedSystems[siteIndex].systemforC2;
  const gtAlertError = t('Shared.Error');
  const additionalAccessControlOptions = t('Additional_AccessControl_Options_Dialog');
  const addAcSeries = t('Button_Add_Ac_Series_To_Gt_Spec');
  const goBack = t('Need_To_Go_Back_Dialog');
  const returnToGt = t('Button_Return_To_System_Page', { page: 'GT' });

  const categories = [
    ...gtEntranceAndGuard,
    ...gtTenantStations,
    ...gtOptionalAccessories,
    ...gtHidden,
    ...acControlKits,
    ...acLicenses,
    ...acReaderKits,
    ...acHidden
  ];
  const stateFormValues = state && Object.keys(state).length > 1 ? state.formValues : undefined;
  const initialValues = getInitialFormValues(systemName, categories, stateFormValues, systemforC2);

  const handleSubmit = async (values: FormValues, actions: any) => {
    try {
      actions.setSubmitting(true);
      //filter out items with quantity set to zero in order to submit quikspec
      values = updateFormValuesGT(values);

      //determine if form is in edit mode
      const editing = stateFormValues != undefined;
      if (editing) {
        //update QuikSpec and navigate to QuikSpec Summary page
        await updateQuikspecAndNavigate(values, actions, navigate);
        actions.setSubmitting(false);
        return;
      } else {
        //create QuikSpec and navigate to QuikSpec Summary page
        await createQuikSpecAndNavigate(values, actions, navigate);
        actions.setSubmitting(false);
      }
    } catch (error) {
      actions.setSubmitting(false);
      actions.setErrors({ submit: t('Something_went_wrong_try_again') });
    }
  };

  const { gt } = useQuikSpecFormValidationSchema();

  // Check if at least one elevator or input/output item has quantity > 0 in the form values
  const elevatorOrIoSelectionCheck = (values: any) => {
    const items = Object.values(values?.items);
    // Check if at least one controller has quantity > 0 for relevant items
    const hasElevatorOrIo = items.some(
      (item: any) =>
        (item.itemName === 'NUMBER_OF_FLOORS' ||
          item.itemName === 'NUMBER_OF_INPUTS' ||
          item.itemName === 'NUMBER_OF_OUTPUTS') &&
        item.quantity > 0
    );
    return hasElevatorOrIo;
  };

  return (
    <Box alignItems={'center'}>
      {C2ApiFailed && (
        <Alert severity="warning">
          <AlertTitle>{t('Missing_C2_Data')}</AlertTitle>
          {t('C2_Data_Missing_Detail')}
        </Alert>
      )}
      <Formik initialValues={initialValues} validationSchema={gt} onSubmit={handleSubmit}>
        {({ values, errors, touched, isSubmitting }) => {
          return (
            <Form>
              <GeneralInfo editing={state.formValues != undefined} />
              {/* Render QuikspecCategoryTabs for each category */}
              {!acOpen && (
                <Box>
                  <QuikspecSystemConstraints systemConstraints={gtSystemConstraints} />
                  <QuikspecCategoryGtEntranceGuard categories={gtEntranceAndGuard} />
                  <QuikspecCategoryTabs categories={gtTenantStations} />
                  <QuikspecCategoryTabs categories={gtOptionalAccessories} />
                  <Box>
                    <Typography variant="h4" align="center">
                      {additionalAccessControlOptions}
                    </Typography>
                    <Button variant="contained" color="secondary" onClick={() => setACOpen(true)}>
                      {addAcSeries}
                    </Button>
                  </Box>
                </Box>
              )}
              {acOpen && (
                <Box>
                  <QuikspecSystemConstraints systemConstraints={acSystemConstraints} />
                  <QuikspecCategoryAcControllers categories={acControlKits} />
                  <QuikspecCategoryAcLicenses
                    categories={acLicenses}
                    isESSDisabled={elevatorOrIoSelectionCheck(values)}
                  />
                  <QuikspecCategoryTabs categories={acReaderKits} />
                  <Box alignItems={'center'}>
                    <Typography variant="h4" align="center">
                      {goBack}
                    </Typography>
                    <Button variant="contained" color="secondary" onClick={() => setACOpen(false)}>
                      {returnToGt}
                    </Button>
                  </Box>
                </Box>
              )}
              {(stateFormValues
                ? Object.keys(errors).length > 0
                : Object.keys(touched).length > 0 && Object.keys(errors).length > 0) && (
                <Alert severity="error">
                  <AlertTitle>{gtAlertError}</AlertTitle>
                  <List>
                    {Object.keys(errors).map((field) => (
                      <ListItem key={field}>
                        <Typography variant="body2" color="error">
                          {errors[field]}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Alert>
              )}
              <QuikspecSubmitButton
                editing={state.formValues != undefined}
                errors={errors}
                submitting={isSubmitting}
                C2ApiFailed={C2ApiFailed}
              />
              <QuikspecNote systemName={initialValues.systemName} />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default QuikspecGT;
