import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  Checkbox,
  Grid,
  Pagination,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import theme from 'styles/theme';
import ProductCard from 'features/QuikSpec/Components/UiParts/ProductCard';
import { QuikspecCategory } from 'features/QuikSpec/Types/QuikSpecTypes';
import ExpandedProductCard from './ExpandedProductCard';
import useQuantityChange from '../../Hooks/useQuantityChange';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import ProductPopover from 'features/QuikSpec/Components/UiParts/ProductPopover';
import QuantityInputForm from 'features/QuikSpec/Components/UiParts/QuantityInputForm';
import { useQuikSpecViewDetail } from 'features/QuikSpec/Hooks/quikSpecDevices';

import { useTranslation } from 'react-i18next';

interface Props {
  categories: QuikspecCategory[];
}

const QuikspecCategoryAcControllers = ({ categories }: Props) => {
  const defaultItemsPerPage = 4;
  const { t } = useTranslation();
  const { description } = useQuikSpecViewDetail();

  const [page, setPage] = React.useState(1);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedRadioItem, setSelectedRadioItem] = useState<string>('');
  const { handleQuantityChange, directChangeQuantity, directGetQuantity } = useQuantityChange(
    selectedRadioItem,
    false,
    1
  );

  /**
   * 1)If any item within a category has its multipleAllowed set to false, we will handle it using RadioButton.
   * We'll assume there are multiple items with multipleAllowed set to false.
   * In such cases, we'll take the first item as the default value.
   *
   * 2) The handleQuantityChange function should be executed within a useEffect hook because we need to wait until selectedRadioItem is updated.
   * This ensures that changes are handled correctly after the state has been updated.
   * */
  useEffect(() => {
    if (!selectedRadioItem) {
      categories[selectedTab].items.forEach((item) => {
        /**
         * If 'selectedRadioItem' is not empty, it means we have already found the default value.
         * Therefore, we just need to skip the loop.
         * */
        if (!selectedRadioItem && item?.multipleAllowed === false) {
          return setSelectedRadioItem(item.deviceName);
        }
      });
    }
    handleQuantityChange('1');
  }, [selectedRadioItem]);

  /**
   * When the radio button component updates,
   * it implies that the previous value will always be false ('0'),
   * and the selected value will always be true ('1').
   * In this function, we set the previous value to false.
   * The current value is set in the useEffect component after the state has been updated.
   * */
  const handleRadioBtnChange = (itemName: string) => {
    selectedRadioItem && handleQuantityChange('0');
    setSelectedRadioItem(itemName);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setPage(1);
    setSelectedTab(newValue);
  };

  const [acca1CheckBox, setAcca1CheckBox] = useState<boolean>(false);
  const handleCheckBoxChange = () => {
    if (acca1CheckBox) {
      const temp = directGetQuantity('NUMBER_OF_READERS_AC_TROVE');
      setAcca1CheckBox(false);
      directChangeQuantity('NUMBER_OF_READERS_AC_TROVE', '0');
      directChangeQuantity('NUMBER_OF_READERS_AC', temp.toString());
    } else {
      const temp = directGetQuantity('NUMBER_OF_READERS_AC');
      setAcca1CheckBox(true);
      directChangeQuantity('NUMBER_OF_READERS_AC', '0');
      directChangeQuantity('NUMBER_OF_READERS_AC_TROVE', temp.toString());
    }
  };

  useEffect(() => {
    if (!acca1CheckBox) {
      if (directGetQuantity('NUMBER_OF_READERS_AC_TROVE') > 0) {
        return setAcca1CheckBox(true);
      }
    }
    handleQuantityChange('1');
  }, [setAcca1CheckBox]);

  const displayController = (id: number) => {
    switch (id) {
      // Access Controller Kit
      case 1:
        return (
          <>
            <Grid item sm={12} md={4} alignItems="center">
              {!acca1CheckBox ? (
                <Card sx={styles.cardWithImage}>
                  <CardHeader
                    title="ACS-2DR-C"
                    sx={styles.header}
                    titleTypographyProps={{ fontSize: '1.3rem', fontWeight: '500' }}
                  />
                  <CardContent sx={styles.cardContent}>
                    <CardMedia
                      component="img"
                      image={`${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/ACS-2DR-C.jpg`}
                      sx={styles.deviceImage}
                    />
                    {description && <ProductPopover description={description.item['ACS2DRC']} />}
                  </CardContent>
                </Card>
              ) : (
                <Card sx={styles.cardWithImage}>
                  <CardHeader
                    title="ACS-2DR with AC-CA1"
                    sx={styles.header}
                    titleTypographyProps={{ fontSize: '1.3rem', fontWeight: '500' }}
                  />
                  <CardContent sx={styles.cardContent}>
                    <CardMedia
                      component="img"
                      image={`${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/AC-CA1.jpg`}
                      sx={styles.deviceImage}
                    />
                    {description && <ProductPopover description={description.item['ACCA1']} />}
                  </CardContent>
                </Card>
              )}
            </Grid>
            <Grid item sm={12} md={8} sx={styles.form}>
              <Grid item xs={12}>
                {t('How_many_card_readers')}
              </Grid>
              <Grid item xs={12} sx={styles.quantity}>
                {!acca1CheckBox ? (
                  <QuantityInputForm
                    itemName={'NUMBER_OF_READERS_AC'}
                    multipleAllowed={false}
                    maxQuantityPerItem={80}
                    accessories={undefined}
                  />
                ) : (
                  <QuantityInputForm
                    itemName={'NUMBER_OF_READERS_AC_TROVE'}
                    multipleAllowed={false}
                    maxQuantityPerItem={80}
                    accessories={undefined}
                  />
                )}
              </Grid>
              <Grid item xs={12} sx={styles.secondQuestion}>
                <Checkbox checked={acca1CheckBox} onChange={() => handleCheckBoxChange()} sx={styles.orgCheckBox} />
                {t('Would_power_supply')}
              </Grid>
            </Grid>
          </>
        );
        break;
      // Over the Door Controller
      case 2:
        return categories[selectedTab].items
          .slice(
            (page - 1) * (categories[selectedTab].itemsPerPage || defaultItemsPerPage),
            page * (categories[selectedTab].itemsPerPage || defaultItemsPerPage)
          )
          .map((item, index) =>
            item?.multipleAllowed === false ? (
              <ExpandedProductCard
                item={item}
                key={index}
                index={index}
                selectedItem={selectedRadioItem}
                changeSelectedItem={handleRadioBtnChange}
              />
            ) : (
              <ProductCard item={item} key={index} index={index} />
            )
          );
        break;
      // Elevator Controler Kit
      case 3:
        return (
          <>
            <Grid item sm={12} md={4} alignItems="center">
              <Card sx={styles.cardWithImage}>
                <CardHeader
                  title="ACS-ELV"
                  sx={styles.header}
                  titleTypographyProps={{ fontSize: '1.3rem', fontWeight: '500' }}
                />
                <CardContent sx={styles.cardContent}>
                  <CardMedia
                    component="img"
                    image={`${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/ACS-ELV.jpg`}
                    sx={styles.deviceImage}
                  />
                  {description && <ProductPopover description={description.item['ACSELV']} />}
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={12} md={8} sx={styles.form}>
              <Grid item xs={12}>
                {t('How_many_floors')}
              </Grid>
              <Grid item xs={12} sx={styles.quantity}>
                <QuantityInputForm
                  itemName={'NUMBER_OF_FLOORS'}
                  multipleAllowed={false}
                  maxQuantityPerItem={5120}
                  accessories={undefined}
                />
              </Grid>
            </Grid>
          </>
        );
        break;
      // I/O Kit
      case 4:
        return (
          <>
            <Grid item sm={12} md={4} alignItems="center">
              <Card sx={styles.cardWithImage}>
                <CardHeader
                  title="ACS-IO"
                  sx={styles.header}
                  titleTypographyProps={{ fontSize: '1.3rem', fontWeight: '500' }}
                />
                <CardContent sx={styles.cardContent}>
                  <CardMedia
                    component="img"
                    image={`${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/ACS-IO.jpg`}
                    sx={styles.deviceImage}
                  />
                  <ProductPopover description={description.item['ACSIO']} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={12} md={8} sx={styles.form}>
              <Grid item xs={12}>
                {t('How_many_inputs')}
              </Grid>
              <Grid item xs={12} sx={styles.quantity}>
                <QuantityInputForm
                  itemName={'NUMBER_OF_INPUTS'}
                  multipleAllowed={false}
                  maxQuantityPerItem={undefined}
                  accessories={undefined}
                />
              </Grid>
              <Grid item xs={12} sx={styles.secondQuestion}>
                {t('How_many_outputs')}
              </Grid>
              <Grid item xs={12} sx={styles.quantity}>
                <QuantityInputForm
                  itemName={'NUMBER_OF_OUTPUTS'}
                  multipleAllowed={false}
                  maxQuantityPerItem={undefined}
                  accessories={undefined}
                />
              </Grid>
            </Grid>
          </>
        );
        break;
      default:
        break;
    }
  };

  return (
    <Box sx={styles.mainStationsBox}>
      <Grid container justifyContent="center" flexDirection={'column'} sx={styles.mainStationsTabGrid}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="fullWidth"
          TabIndicatorProps={{ style: { display: 'none' } }}
        >
          {categories.map((product) => (
            <Tab key={product.id} label={product.title} sx={styles.mainStationsTab} />
          ))}
        </Tabs>

        <Grid container justifyContent="center">
          <Grid item xs={12} sx={styles.categoryDescription}>
            <Typography variant="h6" component={'h6'} sx={styles.descriptionContainer}>
              {categories[selectedTab].description}
            </Typography>
          </Grid>
          {displayController(categories[selectedTab].id)}
        </Grid>

        <Grid container justifyContent={'center'}>
          <Pagination
            count={Math.ceil(
              categories[selectedTab].items.length / (categories[selectedTab].itemsPerPage || defaultItemsPerPage)
            )}
            page={page}
            onChange={handleChange}
            color="secondary"
            sx={styles.pagination}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  mainStationsBox: {
    my: 4,
    boxShadow: '0 4px 16px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
    borderRadius: '8px',
    overflow: 'hidden'
  },
  mainStationsTabGrid: {
    my: 2,
    width: '95%',
    mx: 'auto'
  },
  mainStationsTab: {
    flexGrow: 1,
    flexBasis: 0,
    borderRadius: '0',
    '&:first-of-type': {
      borderTopLeftRadius: '7px',
      borderBottomLeftRadius: '7px'
    },
    '&:last-of-type': {
      borderTopRightRadius: '7px',
      borderBottomRightRadius: '7px'
    },
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: '#f0f0f0',
    '&.Mui-selected': {
      color: '#ffffff',
      backgroundColor: theme.palette.primary.main
    },
    '&:hover': {
      backgroundColor: '#d0d0d0',
      opacity: 1
    }
  },
  categoryDescription: {
    my: 2,
    py: 2
  },
  descriptionContainer: {
    color: '#2c3e50',
    textAlign: 'center',
    fontSize: '1.1rem',
    fontWeight: 500
  },
  pagination: {
    justifyContent: 'center',
    position: 'relative'
  },
  cardWithImage: {
    margin: '2px',
    width: 'auto',
    height: '320px',
    boxShadow: '3',
    position: 'relative'
  },
  header: {
    textAlign: 'center',
    background: 'linear-gradient(to right, #1e3c72, #2a5298)',
    color: '#fff',
    padding: '4px',
    boxShadow: 'inset 0 -1px 0 0 #e0e0e0',
    fontSize: '1.1rem',
    fontWeight: '200'
  },
  cardContent: {
    position: 'relative',
    textAlign: 'center'
  },
  cardComp: {
    textAlign: 'center'
  },
  deviceImage: {
    objectFit: 'contain',
    maxWidth: '200px',
    maxHeight: '200px',
    margin: 'auto',
    display: 'block'
  },
  secondQuestion: {
    marginTop: '2rem'
  },
  orgCheckBox: {
    position: 'relative',
    left: -10
  },
  quantity: {
    marginTop: '0.5rem'
  },
  form: {
    paddingLeft: 3
  }
};

export default QuikspecCategoryAcControllers;
