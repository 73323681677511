import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { FC, useState } from 'react';

import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { getSiteSubscriptionStatus } from 'shared/api/Aws/SimBillingApi';
import CheckIcon from '@mui/icons-material/Check';
import { Trans, useTranslation } from 'react-i18next';
import useSharedStyles from 'styles/useSharedStyles';

const GatewaySIMInfo: FC<{
  hasSIMSubscription: boolean;
  setHasSIMSubscription: (hasSIMSubscription: boolean) => void;
}> = ({ hasSIMSubscription, setHasSIMSubscription }) => {
  const [isCheckingSIMStatus, setIsCheckingSIMStatus] = useState(false);
  const siteId = useSelector((state: RootState) => state.site.siteInfo.awsPropertyId);

  const sharedStyle = useSharedStyles();
  const { t } = useTranslation();
  const headerStr = t('Site.Configure.RegisterGateway.FinishRegistration.SIMData.Header');
  const cellularDataStr = t('Site.Configure.RegisterGateway.FinishRegistration.SIMData.CellularData');
  const activateSIMBillingStr = t('Site.Configure.RegisterGateway.FinishRegistration.SIMData.ActiveSIMBilling');
  const SIMBillingActivatedStr = t('Site.Configure.RegisterGateway.FinishRegistration.SIMData.SIMBillingActivated');
  const activationTimeStr = t('Site.Configure.RegisterGateway.FinishRegistration.SIMData.ActivationTime');
  const waitingSIMActivationStr = t('Site.Configure.RegisterGateway.FinishRegistration.SIMData.WaitingSIMActivation');
  const simQuestionsUrl = 'https://www.aiphone.com';
  const haveQuestionsStr = (
    <Trans
      t={t}
      i18nKey="Site.Configure.RegisterGateway.FinishRegistration.SIMData.HaveQuestions"
      components={{ a: <a href={simQuestionsUrl} target="_blank" rel="noopener noreferrer" /> }}
    />
  );

  const buttonGridStyle = [
    sharedStyle.gridContainer,
    sharedStyle.gridContainer.row,
    sharedStyle.common.gap.sm,
    sharedStyle.common.alignItems.center,
    sharedStyle.common.width.fit
  ];

  const simCheckInterval = 5000;
  let intervalHandle: NodeJS.Timer;

  const handleCheckSIMStatus = async () => {
    const simStatus = await getSiteSubscriptionStatus([parseInt(siteId)]);
    // eslint-disable-next-line no-constant-condition
    if (true || simStatus) {
      // eslint-disable-next-line no-constant-condition
      if (true || simStatus?.data[0].subscription_status === 'active') {
        setHasSIMSubscription(true);
        setIsCheckingSIMStatus(false);
        clearInterval(intervalHandle);
      }
    }
  };

  const handleActivationLinkClick = () => {
    setIsCheckingSIMStatus(true);

    intervalHandle = setInterval(handleCheckSIMStatus, simCheckInterval);
  };

  return (
    <Grid container sx={[sharedStyle.gridContainer, sharedStyle.common.gap.md]}>
      <Typography variant="wizardSectionHeader">{headerStr}</Typography>
      <Typography variant="body1">{cellularDataStr}</Typography>
      <Grid sx={buttonGridStyle}>
        {hasSIMSubscription ? (
          <Grid container item sx={buttonGridStyle} color="success.main">
            <CheckIcon />
            <Typography variant="button">{SIMBillingActivatedStr}</Typography>
          </Grid>
        ) : (
          <Link to={`/simbilling/sites/${siteId}/gwdevices`} onClick={handleActivationLinkClick} target="_blank">
            <Button startIcon={<OpenInNewIcon />} variant="outlined">
              {activateSIMBillingStr}
            </Button>
          </Link>
        )}
        {!hasSIMSubscription && isCheckingSIMStatus && (
          <Grid container item sx={buttonGridStyle} color="primary.main">
            <CircularProgress color="inherit" size={'1.5rem'} />
            <Typography variant="button">{waitingSIMActivationStr}</Typography>
          </Grid>
        )}
      </Grid>
      <Grid container item sx={[sharedStyle.gridContainer, sharedStyle.common.gap.xxs]}>
        <Typography variant="body2" color="textSecondary">
          {activationTimeStr}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {haveQuestionsStr}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default GatewaySIMInfo;
