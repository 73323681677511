// src/components/LoadingSpinner.tsx
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { LicenseManagementCommonStyles } from 'features/LicenseManagement/styles/CommonStyles';
import { useTranslation } from 'react-i18next';

const LoadingSpinner = () => {
  // #region Translation
  const { t } = useTranslation('acNioLicenseManagement');
  // #endregion

  return (
    <Box sx={LicenseManagementCommonStyles.loadingSpinnerContainer}>
      <Stack direction="row" spacing={2} alignItems="center">
        <CircularProgress color="primary" />
        <Typography variant="h6" color="textPrimary">
          {t('Loading')}
        </Typography>
      </Stack>
    </Box>
  );
};

export default LoadingSpinner;
