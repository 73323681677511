import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';
import { Box, Link, Typography } from '@mui/material';

// #region import LicenseManagement
// Components
import EditableSection from 'features/LicenseManagement/components/deployment/DeploymentInformation/EditableSection';
// Hooks
import { useDeploymentById } from 'features/LicenseManagement/hooks/useDeployments';
// SharedComponents
import LoadingSpinner from 'features/LicenseManagement/components/common/LoadingSpinner';
import ErrorAlert from 'features/LicenseManagement/components/common/ErrorAlert';
import { getFilteredStateList } from 'features/LicenseManagement/components/common/utils';
import { getCountryOptions } from 'features/LicenseManagement/components/common/GetCountryOptions';
import { LicenseManagementCommonStyles } from 'features/LicenseManagement/styles/CommonStyles';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import { EnumList, fetchEnumList } from 'shared/utils/EnumUtils';
import Header from 'features/LicenseManagement/components/common/Header';
//#region Types
import {
  DeploymentType,
  LicenseStatus,
  initializeUpdateDeploymentRequest
} from 'features/LicenseManagement/components/common/Types';
import { useCurrentBranchWithReactQuery } from 'features/LicenseManagement/hooks/useCurrentBranchWithReactQuery';
import { formatAmericanZipCode, formatCanadianPostalCode } from 'shared/utils/helperFunctions';
import {
  PostalCodeValidCharacters,
  RegexPostalCode,
  RegexZipCode,
  ZipCodeValidCharacters
} from 'features/RemoteManagement/types/Types';
// #endregion

export const initialErrors = {
  deployment: {
    name: false,
    emailRecipients: false
  },
  tenant: {
    subdomain: false
  },
  address: {
    street: false,
    city: false,
    stateName: false,
    postalCode: false
  },
  contact: {
    name: false,
    emailAddress: false,
    phoneNumber: false
  },
  emailRecipients: false
};

const DeploymentInformation = () => {
  const HOSTED_DEPLOYMENT_DOMAIN = '.acnio.cloud';
  const { deploymentId } = useParams();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [updatedDeploymentData, setUpdatedDeploymentData] = useState<any>(null);
  const [errors, setErrors] = useState(initialErrors);

  const { isAllowedTo } = usePermission();
  const { data: currentBranch } = useCurrentBranchWithReactQuery();
  const user = useSelector((state: RootState) => state.users.currentUser);

  const canViewDeployment = isAllowedTo('deployment:view', currentBranch?.publicId, PermissionsContextType.BRANCH);
  const canEditDeployment = isAllowedTo('deployment:edit', currentBranch?.publicId, PermissionsContextType.BRANCH);
  const [hasLinkedAiphoneSite, setHasLinkedAiphoneSite] = useState(false);
  const [countryId, setCountryId] = useState('');
  const [enumList, setEnumList] = useState<EnumList>({ country: {}, state: {} });
  // #region Strings
  const { t } = useTranslation('acNioLicenseManagement');
  const deploymentNotificationTitleStr = t('Deployment_Notifications_Title');
  const deploymentNotificationSubtitleStr = t('Deployment_Notifications_Subtitle');
  const countryStr = t('Deployment_Address_Country');
  const stateStr = t('Deployment_Address_State');
  const provinceStr = t('Deployment_Address_Province');
  const cityStr = t('Deployment_Address_City');
  const postalCodeStr = t('Deployment_Address_Postal_Code');

  //#endregion

  const {
    data: deployment,
    isLoading,
    error,
    isError: isFetchDeploymentsError,
    refetch: refetchDeployments
  } = useDeploymentById(deploymentId || '');

  const formatPhoneNumber = (phoneNumber: string) => {
    if (!phoneNumber) return '';
    const cleaned = phoneNumber.replace(/\D/g, '');
    let countryCode = '';
    let mainNumber = cleaned;
    if (cleaned.length > 10) {
      countryCode = `+${cleaned.slice(0, cleaned.length - 10)}`;
      mainNumber = cleaned.slice(-10);
    }
    if (mainNumber.length === 10) {
      const formattedNumber = `(${mainNumber.slice(0, 3)})-${mainNumber.slice(3, 6)}-${mainNumber.slice(6)}`;
      return countryCode ? `${countryCode} ${formattedNumber}` : formattedNumber;
    }
    return phoneNumber;
  };

  if (!deploymentId) {
    return <Typography>{t('Error_Loading_Deployment_ID')}</Typography>;
  }

  // Initialize deployment data when loaded
  useEffect(() => {
    if (!isLoading && deployment && user?.publicId) {
      const initializedData = initializeUpdateDeploymentRequest(deployment, deploymentId, user.publicId);
      setUpdatedDeploymentData({
        ...initializedData,
        address: {
          ...initializedData.address,
          countryName: initializedData?.address?.countryName === 'United States' ? 'United States of America' : 'Canada'
        }
      });
      setCountryId(initializedData?.address?.countryName === 'Canada' ? '3' : '2');
      if (deployment?.linkedAiphoneSitePublicId) {
        setHasLinkedAiphoneSite(true);
      }
    }
  }, [deployment, isLoading, user, deploymentId]);

  // Fetch country list when the component mounts
  useEffect(() => {
    fetchEnumList().then((data) => {
      setEnumList(data);
    });
  }, []);
  // #endregion

  // #region Conditional Logic for Rendering
  if (!currentBranch?.publicId) {
    return <LoadingSpinner />;
  }

  if (!canViewDeployment) {
    return <Typography>{t('Missing_Permission_To_View_Deployment')}</Typography>;
  }

  if (!deploymentId) {
    return <Typography>{t('Error_Loading_Deployment_ID')}</Typography>;
  }

  if (isFetchDeploymentsError) {
    return (
      <Box>
        <ErrorAlert
          errorMessage={error?.message}
          customMessage={t('Error_Loading_Data')}
          onRetry={refetchDeployments}
        />
      </Box>
    );
  }

  if (isLoading || !updatedDeploymentData) {
    return <LoadingSpinner />;
  }
  // #endregion

  // #region Handlers
  const validateField = (section: string, field: string, value: any) => {
    let hasError = false;
    if (field === 'city') {
      hasError = value.trim() === '' || /[^a-zA-Z\s]/.test(value);
    }
    if (field === 'postalCode') {
      hasError =
        value.trim() === '' ||
        (countryId === '2' && !RegexZipCode.test(value)) ||
        (countryId === '3' && !RegexPostalCode.test(value));
    }
    if (field === 'stateName') {
      hasError = value.trim() === '';
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [section]: {
        ...prevErrors[section],
        [field]: hasError
      }
    }));
  };

  const handleFieldChange = (section: string, field: string, value: any) => {
    let newValue = value;
    switch (field) {
      case 'postalCode':
        if (countryId === '2') {
          newValue = value.slice(0, 5).replace(ZipCodeValidCharacters, '');
          newValue = formatAmericanZipCode(newValue);
        } else if (countryId === '3') {
          newValue = value.slice(0, 7).replace(PostalCodeValidCharacters, '');
          newValue = formatCanadianPostalCode(newValue);
        }
        break;
      case 'city':
        newValue = value.replace(/[^a-zA-Z\s]/g, '');
        break;
      default:
        newValue = value.replace(/[^\w\s@]/gi, '');
        break;
    }

    setUpdatedDeploymentData((prevState: { [x: string]: any }) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [field]: newValue
      }
    }));
    validateField(section, field, newValue);
  };

  const handleEmailRecipientsChange = (newRecipients: string[]) => {
    setUpdatedDeploymentData((prev) => ({
      ...prev,
      emailRecipients: newRecipients
    }));
  };
  // #endregion

  const renderEditableSection = (title: string, fields: any[], canEdit: boolean) => {
    return (
      <EditableSection
        formData={updatedDeploymentData}
        title={title}
        fields={fields}
        setSuccessMessage={setSuccessMessage}
        setErrorMessage={setErrorMessage}
        canUserEdit={canEdit}
        handleEmailRecipientsChange={handleEmailRecipientsChange}
        errors={errors}
        setErrors={setErrors}
        refetchDeployments={refetchDeployments}
      />
    );
  };

  const handleCountryChange = (newCountry: string) => {
    const newCountryId = newCountry === 'Canada' ? '3' : '2';
    setCountryId(newCountryId);
    setUpdatedDeploymentData((prevState) => ({
      ...prevState,
      address: {
        ...prevState.address,
        countryName: newCountry
      }
    }));
    handleFieldChange('address', 'postalCode', '');
    handleFieldChange('address', 'stateName', '');
  };

  const renderStateOptions = () => {
    return getFilteredStateList(countryId, enumList, (state) => ({
      value: state.value,
      label: state.value
    }));
  };

  const handleStateChange = (newState: string) => {
    setUpdatedDeploymentData((prevState: { address: any }) => ({
      ...prevState,
      address: {
        ...prevState.address,
        stateName: newState
      }
    }));
    validateField('address', 'stateName', newState);
  };
  // #endregion

  // #region Render
  return (
    <Box sx={LicenseManagementCommonStyles.pageHeaderContainer}>
      <SnackbarAlert
        type={errorMessage ? 'error' : 'success'}
        time={5000}
        text={errorMessage || successMessage || ''}
        isOpen={!!errorMessage || !!successMessage}
        onClose={() => {
          setErrorMessage(null);
          setSuccessMessage(null);
        }}
      />
      <Header deployment={deployment} status={deployment?.licenseStatus} />
      {renderEditableSection(
        t('Deployment_Section_Title'),
        [
          {
            label: t('Deployment_Name'),
            value: updatedDeploymentData?.deployment.name,
            onChange: (value: any) => handleFieldChange('deployment', 'name', value)
          },
          {
            label: t('Deployment_Description'),
            value: updatedDeploymentData?.deployment.description,
            onChange: (value: any) => handleFieldChange('deployment', 'description', value)
          },
          ...(deployment?.deploymentType === DeploymentType.Local
            ? [
                {
                  label: t('Account_No'),
                  value: updatedDeploymentData?.accountNumber,
                  readonly: true
                }
              ]
            : []),
          {
            label: t('Deployment_Type'),
            value: updatedDeploymentData?.deployment.type,
            readonly: true,
            isLast: deployment?.deploymentType === DeploymentType.Local
          },
          ...(deployment?.deploymentType === DeploymentType.Cloud
            ? [
                {
                  label: t('Deployment_Subdomain_Title'),
                  value:
                    deployment?.licenseStatus === LicenseStatus.Activated ? (
                      <Link
                        href={`https://${deployment?.tenant?.subdomain ?? ''}${HOSTED_DEPLOYMENT_DOMAIN}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {`${deployment?.tenant?.subdomain ?? ''}${HOSTED_DEPLOYMENT_DOMAIN}`}
                      </Link>
                    ) : (
                      deployment?.tenant?.subdomain ?? ''
                    ),
                  readonly: true,
                  isLast: true
                }
              ]
            : [])
        ],
        canEditDeployment
      )}

      {renderEditableSection(
        deploymentNotificationTitleStr,
        [
          {
            label: deploymentNotificationSubtitleStr,
            value: updatedDeploymentData?.emailRecipients.join(', ') || '',
            isLast: true
          }
        ],
        canEditDeployment
      )}

      {renderEditableSection(
        t('Deployment_Contact_Title'),
        [
          {
            label: t('Deployment_Contact_Name'),
            value: updatedDeploymentData?.contact.name,
            onChange: (value: any) => handleFieldChange('contact', 'name', value)
          },
          {
            label: t('Deployment_Contact_Email'),
            value: updatedDeploymentData?.contact.emailAddress,
            onChange: (value: any) => handleFieldChange('contact', 'emailAddress', value)
          },
          {
            label: t('Deployment_Contact_Phone'),
            value: formatPhoneNumber(updatedDeploymentData?.contact.phoneNumber),
            onChange: (value: any) => handleFieldChange('contact', 'phoneNumber', value),
            isLast: true
          }
        ],
        canEditDeployment
      )}

      {renderEditableSection(
        t('Deployment_Address_Section_Title'),
        [
          {
            label: t('Deployment_Address_Street'),
            value: updatedDeploymentData?.address.street,
            onChange: (value: any) => handleFieldChange('address', 'street', value)
          },
          {
            label: t('Deployment_Address_Apt'),
            value: updatedDeploymentData?.address.aptSuiteBuilding,
            onChange: (value: any) => handleFieldChange('address', 'aptSuiteBuilding', value)
          },
          {
            label: cityStr,
            value: updatedDeploymentData?.address.city,
            onChange: (value: any) => handleFieldChange('address', 'city', value)
          },
          {
            label: postalCodeStr,
            value: updatedDeploymentData?.address.postalCode,
            onChange: (value: any) => handleFieldChange('address', 'postalCode', value)
          },
          {
            label: countryStr,
            value: updatedDeploymentData?.address.countryName,
            onChange: handleCountryChange,
            options: getCountryOptions(enumList)
          },
          {
            label: countryId === '2' ? stateStr : provinceStr,
            value: updatedDeploymentData?.address.stateName,
            onChange: handleStateChange,
            options: renderStateOptions(),
            isLast: true
          }
        ],
        canEditDeployment && !hasLinkedAiphoneSite
      )}
    </Box>
  );
};

export default DeploymentInformation;
