import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FeatureSummaryItem } from 'shared/components/home/statsView/FeatureSummaryView';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LineChartGraph from 'shared/components/graphs/LineChartGraph';
import { filterDataByTimeframe } from './DashboardStatsView';
import { TimeframeOptions } from 'shared/types/chartDataItem';
import { useState } from 'react';
import useSharedStyles from 'styles/useSharedStyles';
import { useNavigate } from 'react-router-dom';

export const BranchAssociateDashboardView = () => {
  const sharedStyle = useSharedStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const title = t('DashboardStats.QuickSpecs.Title');
  const buttonStr = t('DashboardStats.QuickSpecs.ButtonText');
  const chartTitle = t('DashboardStats.QuickSpecs.ChartTitle');
  const [timeframe, setTimeframe] = useState(TimeframeOptions.ALL_TIME);
  const handleTimeframeChange = (newTimeframe) => {
    setTimeframe(newTimeframe);
  };

  const handleQuickSpecClick = () => {
    navigate('/quikspec');
  };

  return (
    <>
      <FeatureSummaryItem hasAssociateRole={true} />
      <Box display="flex" justifyContent="space-between" width="100%" sx={sharedStyle.spacingTopBottom}>
        <Typography variant="h6">{title}</Typography>
        <Button size="small" variant="text" endIcon={<ArrowForwardIosIcon />} onClick={handleQuickSpecClick}>
          {buttonStr}
        </Button>
      </Box>

      <LineChartGraph
        title={chartTitle}
        dataItems={filterDataByTimeframe(t, 'quickSpec', timeframe)}
        onTimeframeChange={(newTimeframe) => handleTimeframeChange(newTimeframe)}
      />
    </>
  );
};
