import { Box, Button, Dialog, DialogActions, Grid, TextField, Typography } from '@mui/material';
import { useLazyGetDeviceListWithSitePublicIdQuery, useUpdateDeviceMutation } from 'services/aiphoneCloud';
import { LoadingButton } from '@mui/lab';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import DialogWrapper from 'shared/components/dialogs/DialogWrapper';
import { useTranslation } from 'react-i18next';
import useSharedStyles from 'styles/useSharedStyles';
import React, { useContext, useEffect, useState } from 'react';
import { EnumList, fetchLocalEnumList, IDeviceModelValue } from 'shared/utils/EnumUtils';
import { Formik, getIn } from 'formik';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useAppSelector } from 'store/hooks';
import * as yup from 'yup';
import {
  GatewayCommands,
  GatewayCommandStatus,
  RegexIpV4,
  RegexSubnetMask
} from 'features/RemoteManagement/types/Types';
import { BorderLinearProgress } from 'shared/components/progressBars/BorderLinearProgress';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import {
  AssociateDevicePayload,
  QueuedStatus,
  QueueThrottleTime,
  RemoteManagementContext
} from 'context/RemoteManagementContext';

interface IAssociateDevicesDialog {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleNextStep?: () => void;
}

export interface DeviceAssociationInfo {
  id: number;
  modelName: string;
  publicId: string;
  basicInfo: {
    stationName: string;
    stationNumber: string;
    macAddress: string;
  };
  networkSettings: {
    ipV4Address: string;
    subnetMask: string;
    defaultGateway: string;
    needsAssociation: boolean;
  };
}

const AssociateDevicesDialog = ({ isOpen, setIsOpen, handleNextStep }: IAssociateDevicesDialog) => {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const deviceList = useAppSelector((state) => state.devices.DeviceList);
  const sitePublicId = useAppSelector((state) => state.site.siteInfo.publicId);
  const [fetchDevices] = useLazyGetDeviceListWithSitePublicIdQuery();
  const [updateDevice] = useUpdateDeviceMutation();
  const sharedStyle = useSharedStyles();
  const enumList: EnumList = fetchLocalEnumList();
  const { associateCommand, lastJsonMessage } = useContext(RemoteManagementContext);
  const [isAssociating, setIsAssociating] = useState<boolean>(false);
  const [updateFailed, setUpdateFailed] = useState<boolean>(false);
  const [associationDevices, setAssociationDevices] = useState<DeviceAssociationInfo[]>([]);
  const [currentProgress, setCurrentProgress] = useState<number>(0);
  const [totalProgress, setTotalProgress] = useState<number>(1);
  const [isRebooting, setIsRebooting] = useState<boolean>(false);
  const [startTime, setStartTime] = useState<number>(0);
  const rebootWaitTime = 120;
  let associationTimes: number[] = [];

  const { t } = useTranslation();
  const dialogTitleStr = t('Site.Configure.Title');
  const associateDevicesStr = t('Site.Configure.AssociateStations.Title');
  const headerStr = t('Site.Configure.AssociateStations.Header');
  const fillInStr = t('Site.Configure.AssociateStations.FillIn');
  const batchAssignStr = t('Site.Configure.AssociateStations.BatchAssign');
  const batchFillInStr = t('Site.Configure.AssociateStations.BatchFillIn');
  const deviceNameStr = t('Shared.Name');
  const deviceNumberStr = t('Shared.Number');
  const deviceModelStr = t('Station.BasicInfo.Model');
  const ipAddressStr = t('Station.NetworkSettings.IPAddress');
  const startingIpAddressStr = t('Station.NetworkSettings.StartingIPAddress');
  const defaultGatewayStr = t('Station.NetworkSettings.DefaultGateway');
  const subnetMaskStr = t('Station.NetworkSettings.SubnetMask');
  const dnsStr = t('Station.NetworkSettings.DNS');
  const fetchDevicesStr = t('API.Action.FetchDevices');
  const fetchDevicesErrorStr = t('API.Error.Generic', { action: fetchDevicesStr });
  const applyStr = t('Shared.Apply');
  const ipv4AddressStr = t('Station.NetworkSettings.IPV4Address');
  const ipv4ErrorInvalidStr = t('Field.Error.Invalid', { field: ipv4AddressStr });
  const subnetMaskErrorInvalidStr = t('Field.Error.Invalid', { field: subnetMaskStr });
  const ipAddressRequiredStr = t('Field.Error.Required', { field: ipv4AddressStr });
  const subnetMaskRequiredStr = t('Field.Error.Required', { field: subnetMaskStr });
  const stationsBeingAssociatedStr = t('Site.Configure.AssociateStations.StationsBeingAssociated');
  const stationsRebootingStr = t('Site.Configure.AssociateStations.StationsRebooting');

  const gwDeviceType = parseInt(
    Object.keys(enumList.deviceType).find((key) => enumList.deviceType[key].value === 'IXGW-GW') as string
  );
  const unsupportedDeviceTypes = [gwDeviceType];

  const deviceListKeys = Object.keys(deviceList)
    .filter((key) => !unsupportedDeviceTypes.includes(deviceList[key].basicInfo.deviceType))
    .sort((a, b) => {
      return deviceList[a].basicInfo.stationNumber.localeCompare(deviceList[b].basicInfo.stationNumber);
    });

  const defaultMinuteEstimate = Math.max(Math.round((5 * deviceListKeys.length) / 60), 3);
  const [minutesEstimate, setMinutesEstimate] = useState(defaultMinuteEstimate);
  const timeEstimateStr = t('Site.Configure.AssociateStations.TimeEstimate', { minutes: minutesEstimate });
  const countdownStr = t('Site.Configure.AssociateStations.SecondsCountdown', {
    seconds: totalProgress - currentProgress
  });

  const ipV4Version = Object.keys(enumList.ipVersion).find((key) => enumList.ipVersion[key].value === 'IPV4') as string;

  interface formVals {
    batchIpAddress: string;
    batchSubnetMask: string;
    batchDefaultGateway: string;
    devices: DeviceAssociationInfo[];
  }

  function clearAssociationData() {
    setIsAssociating(false);
    setAssociationDevices([]);
    setCurrentProgress(0);
    associationTimes = [];
    setMinutesEstimate(defaultMinuteEstimate);
  }

  async function handleUpdateDevice(updatedDevice: DeviceAssociationInfo) {
    const updateResponse = await updateDevice({ device: updatedDevice }).unwrap();
    if ('error' in updateResponse) {
      setError(updateResponse.error);
      setUpdateFailed(true);
      clearAssociationData();
    }
  }

  // Trigger initial association
  useEffect(() => {
    if (associationDevices.length > 0 && currentProgress === 0) {
      const payload = buildAssociationPayload(associationDevices[0]);
      setCurrentProgress(0);
      setTotalProgress(associationDevices.length);

      setStartTime(performance.now());
      const queuedStatus: QueuedStatus = associateCommand(payload);
      // TODO Improve how this is handled
      if (queuedStatus === QueuedStatus.THROTTLED) {
        setTimeout(() => {
          associateCommand(payload);
        }, QueueThrottleTime);
      }
    }
  }, [associationDevices]);

  useEffect(() => {
    if (updateFailed) {
      clearAssociationData();
    } else if (lastJsonMessage) {
      if (lastJsonMessage.action === GatewayCommands.ASSOCIATE) {
        if (lastJsonMessage.status === GatewayCommandStatus.COMPLETED) {
          const currDevice = {
            ...associationDevices[currentProgress],
            networkSettings: {
              ...associationDevices[currentProgress]?.networkSettings,
              needsAssociation: false
            }
          };
          handleUpdateDevice(currDevice);

          const endTime = performance.now();
          associationTimes.push((endTime - startTime) / 1000);
          setStartTime(endTime);

          const averageTime = associationTimes.reduce((a, b) => a + b) / associationTimes.length;
          setMinutesEstimate(Math.round((averageTime * (associationDevices.length - currentProgress)) / 60));

          const nextDeviceIndex = currentProgress + 1;
          setCurrentProgress(nextDeviceIndex);

          if (nextDeviceIndex < associationDevices.length) {
            const payload = buildAssociationPayload(associationDevices[nextDeviceIndex]);
            const queuedStatus: QueuedStatus = associateCommand(payload);
            // TODO Improve how this is handled
            if (queuedStatus === QueuedStatus.THROTTLED) {
              setTimeout(() => {
                associateCommand(payload);
              }, QueueThrottleTime);
            }
          } else {
            setCurrentProgress(0);
            setTotalProgress(rebootWaitTime);
            setIsRebooting(true);
            const rebootTimerHandle = setInterval(() => {
              setCurrentProgress((prev) => {
                if (prev >= rebootWaitTime) {
                  clearInterval(rebootTimerHandle);
                  fetchAllDevices().then(() => {
                    handleNextStep ? handleNextStep() : setIsOpen(false);
                    clearAssociationData();
                  });
                  return prev;
                }
                return prev + 1;
              });
            }, 1000);
          }
        } else if (lastJsonMessage.status === GatewayCommandStatus.FAILED) {
          setError(lastJsonMessage.message as string);
          clearAssociationData();
        }
      }
    }
  }, [lastJsonMessage, updateFailed]);

  function buildAssociationPayload(device: DeviceAssociationInfo): AssociateDevicePayload {
    const payload: AssociateDevicePayload = {};
    payload[deviceList[device.publicId].basicInfo.macAddress] = {
      ip_ver: ipV4Version,
      ip_addr: device.networkSettings.ipV4Address,
      ip_subnet: device.networkSettings.subnetMask,
      ip_gateway: device.networkSettings.defaultGateway || undefined,
      station_name: device.basicInfo.stationName,
      station_number: device.basicInfo.stationNumber,
      timeout_sec: '60',
      devicePublicId: device.publicId
    };
    return payload;
  }

  async function fetchAllDevices() {
    await fetchDevices({
      sitePublicId: sitePublicId,
      qty: -1,
      page: 0
    }).catch(() => {
      setError(fetchDevicesErrorStr);
    });
  }

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  async function handleSubmit(formValues: formVals) {
    // reset any previously failed update.
    setUpdateFailed(false);

    setIsAssociating(true);
    setAssociationDevices(formValues.devices);
  }

  const formContainerStyle = [
    sharedStyle.gridContainer,
    sharedStyle.gridContainer.row,
    sharedStyle.common.gap.xs,
    {
      height: '25rem',
      flexWrap: 'nowrap'
    }
  ];

  const batchAssignGridStyle = [
    sharedStyle.gridContainer,
    sharedStyle.gridContainer.row,
    sharedStyle.common.justifyContent.spaceBetween,
    sharedStyle.common.alignItems.center,
    sharedStyle.common.wrap.noWrap
  ];

  const batchAssignActionsStyle = [
    sharedStyle.gridContainer,
    sharedStyle.gridContainer.row,
    sharedStyle.common.gap.xs,
    sharedStyle.common.alignItems.center,
    sharedStyle.common.wrap.noWrap
  ];

  const batchAssignFieldsStyle = [
    sharedStyle.gridContainer,
    sharedStyle.gridContainer.row,
    sharedStyle.common.gap.xs,
    sharedStyle.common.alignItems.flexStart,
    sharedStyle.common.padding.top.lg,
    sharedStyle.common.wrap.noWrap
  ];

  const applyButtonStyle = [sharedStyle.common.padding.x.lg];

  const stationNameStyle = [
    sharedStyle.gridContainer,
    sharedStyle.common.gap.none,
    sharedStyle.flexContainer.fillWithNoOverflow,
    sharedStyle.common.padding.y.md
  ];

  const ipInputStyle = [sharedStyle.flexContainer.fillWithNoOverflow, sharedStyle.common.padding.top.md];

  const associatingGridContentStyle = [
    sharedStyle.gridContainer,
    sharedStyle.gridContainer.row,
    sharedStyle.common.wrap.noWrap,
    sharedStyle.common.alignItems.center
  ];

  const associatingIconStyle = {
    fontSize: '4.25rem',
    color: 'primary.main'
  };

  const progressBarStyle = {
    flex: 1,
    '& .MuiLinearProgress-root': {
      width: '100%'
    }
  };

  const progressBarGridStyle = [
    sharedStyle.gridContainer,
    sharedStyle.gridContainer.row,
    sharedStyle.common.gap.none,
    sharedStyle.common.wrap.noWrap,
    sharedStyle.common.alignItems.center
  ];

  const initialValues: formVals = {
    batchIpAddress: '',
    batchSubnetMask: '',
    batchDefaultGateway: '',
    devices: deviceListKeys.map((key, index) => {
      return {
        id: index,
        modelName:
          enumList.deviceModel[
            Object.keys(enumList.deviceModel).find((modelKey: string) => {
              const model = enumList.deviceModel[modelKey] as IDeviceModelValue;
              return (
                model.deviceType === deviceList[key].basicInfo.deviceType &&
                model.modelNumber === deviceList[key].basicInfo.deviceModel
              );
            }) as string
          ].value,
        publicId: key,
        basicInfo: {
          stationName: deviceList[key].basicInfo.stationName,
          stationNumber: deviceList[key].basicInfo.stationNumber,
          macAddress: deviceList[key].basicInfo.macAddress
        },
        networkSettings: {
          ipV4Address: deviceList[key].networkSettings?.ipV4Address ?? '',
          subnetMask: deviceList[key].networkSettings?.subnetMask ?? '',
          defaultGateway: deviceList[key].networkSettings?.ipV4DefaultGateway ?? '',
          needsAssociation: deviceList[key].networkSettings?.needsAssociation ?? true
        }
      };
    })
  };

  const associationValidation = yup.object().shape({
    batchIpAddress: yup.string().matches(RegexIpV4, ipv4ErrorInvalidStr),
    batchSubnetMask: yup.string().matches(RegexSubnetMask, subnetMaskErrorInvalidStr),
    batchDefaultGateway: yup.string().matches(RegexIpV4, ipv4ErrorInvalidStr),
    devices: yup.array().of(
      yup.object().shape({
        networkSettings: yup.object().shape({
          ipV4Address: yup.string().required(ipAddressRequiredStr).matches(RegexIpV4, ipv4ErrorInvalidStr),
          subnetMask: yup.string().required(subnetMaskRequiredStr).matches(RegexSubnetMask, subnetMaskErrorInvalidStr),
          defaultGateway: yup.string().matches(RegexIpV4, ipv4ErrorInvalidStr),
          dns: yup.string().matches(RegexIpV4, ipv4ErrorInvalidStr)
        })
      })
    )
  });

  return (
    <>
      <DialogWrapper
        header={dialogTitleStr}
        subheader={associateDevicesStr}
        open={isOpen}
        setIsOpen={setIsOpen}
        onClose={handleCloseDialog}
        maxWidth="lg"
        fullWidth
      >
        <Formik initialValues={initialValues} validationSchema={associationValidation} onSubmit={handleSubmit}>
          {({ handleChange, handleBlur, touched, errors, values, setFieldValue, submitForm, validateField }) => {
            const associateGridColumns: GridColDef[] = [
              {
                field: 'stationName',
                headerName: deviceNameStr,
                flex: 1,
                valueGetter: (params) => params.row.basicInfo.stationName,
                renderCell: (params) => {
                  return (
                    <Grid sx={stationNameStyle}>
                      <Typography noWrap variant="body2" color="textPrimary" display="block" fontWeight={500}>
                        {params.value}
                      </Typography>
                      <Typography noWrap variant="body2" color="textPrimary" display="block">
                        {deviceList[params.row.publicId].basicInfo.macAddress}
                      </Typography>
                    </Grid>
                  );
                }
              },
              {
                field: 'stationNumber',
                headerName: deviceNumberStr,
                flex: 0.5,
                valueGetter: (params) => params.row.basicInfo.stationNumber
              },
              {
                field: 'stationModel',
                headerName: deviceModelStr,
                flex: 1,
                valueGetter: (params) => params.row.modelName
              },
              {
                field: 'ipV4Address',
                headerName: ipAddressStr,
                flex: 1,
                valueGetter: (params) => params.row.networkSettings.ipV4Address,
                renderCell: (params) => {
                  return (
                    <TextField
                      placeholder={ipAddressStr}
                      name={`devices[${params.row.id}].networkSettings.ipV4Address`}
                      value={params.row.networkSettings.ipV4Address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="standard"
                      size="small"
                      sx={ipInputStyle}
                      error={
                        getIn(touched, `devices[${params.row.id}].networkSettings.ipV4Address`) &&
                        !!getIn(errors, `devices[${params.row.id}].networkSettings.ipV4Address`)
                      }
                      helperText={
                        (getIn(touched, `devices[${params.row.id}].networkSettings.ipV4Address`) &&
                          getIn(errors, `devices[${params.row.id}].networkSettings.ipV4Address`)) ||
                        ' '
                      }
                    />
                  );
                }
              },
              {
                field: 'subnetMask',
                headerName: subnetMaskStr,
                flex: 1,
                valueGetter: (params) => params.row.networkSettings.subnetMask,
                renderCell: (params) => {
                  return (
                    <TextField
                      placeholder={subnetMaskStr}
                      name={`devices[${params.row.id}].networkSettings.subnetMask`}
                      value={params.row.networkSettings.subnetMask}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="standard"
                      size="small"
                      sx={ipInputStyle}
                      error={
                        getIn(touched, `devices[${params.row.id}].networkSettings.subnetMask`) &&
                        !!getIn(errors, `devices[${params.row.id}].networkSettings.subnetMask`)
                      }
                      helperText={
                        (getIn(touched, `devices[${params.row.id}].networkSettings.subnetMask`) &&
                          getIn(errors, `devices[${params.row.id}].networkSettings.subnetMask`)) ||
                        ' '
                      }
                    />
                  );
                }
              },
              {
                field: 'defaultGateway',
                headerName: defaultGatewayStr,
                flex: 1,
                valueGetter: (params) => params.row.networkSettings.defaultGateway,
                renderCell: (params) => {
                  return (
                    <TextField
                      placeholder={defaultGatewayStr}
                      name={`devices[${params.row.id}].networkSettings.defaultGateway`}
                      value={params.row.networkSettings.defaultGateway}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="standard"
                      size="small"
                      sx={ipInputStyle}
                      error={
                        getIn(touched, `devices[${params.row.id}].networkSettings.defaultGateway`) &&
                        !!getIn(errors, `devices[${params.row.id}].networkSettings.defaultGateway`)
                      }
                      helperText={
                        (getIn(touched, `devices[${params.row.id}].networkSettings.defaultGateway`) &&
                          getIn(errors, `devices[${params.row.id}].networkSettings.defaultGateway`)) ||
                        ' '
                      }
                    />
                  );
                }
              },
              {
                field: 'dns',
                headerName: dnsStr,
                flex: 0.66,
                valueGetter: (params) => params.row.networkSettings.dns,
                renderCell: (params) => {
                  return (
                    <TextField
                      placeholder={dnsStr}
                      name={`devices[${params.row.id}].networkSettings.dns`}
                      value={params.row.networkSettings.dns}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="standard"
                      size="small"
                      sx={ipInputStyle}
                      error={
                        getIn(touched, `devices[${params.row.id}].networkSettings.dns`) &&
                        !!getIn(errors, `devices[${params.row.id}].networkSettings.dns`)
                      }
                      helperText={
                        (getIn(touched, `devices[${params.row.id}].networkSettings.dns`) &&
                          getIn(errors, `devices[${params.row.id}].networkSettings.dns`)) ||
                        ' '
                      }
                    />
                  );
                }
              }
            ];

            function generateAssociationGrid() {
              return (
                <DataGrid
                  columns={associateGridColumns}
                  rows={values.devices}
                  getRowHeight={() => 'auto'}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 }
                    }
                  }}
                  pageSizeOptions={[5, 10, 25]}
                />
              );
            }

            function handleBatchFillIn() {
              validateField('batchIpAddress');
              validateField('batchSubnetMask');
              validateField('batchDefaultGateway');

              if (!errors.batchIpAddress && !errors.batchSubnetMask && !errors.batchDefaultGateway) {
                const newDevices = values.devices.map((device, index) => {
                  const ipParts = values.batchIpAddress.split('.');
                  const newIp = values.batchIpAddress
                    ? `${ipParts[0]}.${ipParts[1]}.${ipParts[2]}.${parseInt(ipParts[3]) + index}`
                    : device.networkSettings.ipV4Address;

                  return {
                    ...device,
                    networkSettings: {
                      ipV4Address: newIp,
                      subnetMask: values.batchSubnetMask ? values.batchSubnetMask : device.networkSettings.subnetMask,
                      defaultGateway: values.batchDefaultGateway
                        ? values.batchDefaultGateway
                        : device.networkSettings.defaultGateway
                    }
                  };
                });

                setFieldValue('devices', newDevices);
              }
            }

            return (
              <>
                <Grid container sx={[sharedStyle.gridContainer, sharedStyle.common.gap.sm]}>
                  <Typography variant="h6">{headerStr}</Typography>
                  <Typography variant="body1" color="textPrimary">
                    {fillInStr}
                  </Typography>
                  <Grid
                    item
                    sx={[sharedStyle.infoBox, sharedStyle.common.width.full, sharedStyle.common.padding.y.none]}
                  >
                    <Grid container item sx={batchAssignGridStyle}>
                      <Grid sx={[sharedStyle.gridContainer, sharedStyle.common.gap.xxs]}>
                        <Typography variant="body1">{batchAssignStr}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {batchFillInStr}
                        </Typography>
                      </Grid>
                      <Grid sx={batchAssignActionsStyle}>
                        <Grid sx={batchAssignFieldsStyle}>
                          <Grid item>
                            <TextField
                              label={startingIpAddressStr}
                              name="batchIpAddress"
                              value={values.batchIpAddress}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              variant="outlined"
                              size="small"
                              error={touched.batchIpAddress && !!errors.batchIpAddress}
                              helperText={(touched.batchIpAddress && errors.batchIpAddress) || ' '}
                            />
                          </Grid>
                          <Grid item>
                            <TextField
                              label={subnetMaskStr}
                              name="batchSubnetMask"
                              value={values.batchSubnetMask}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              variant="outlined"
                              size="small"
                              error={touched.batchSubnetMask && !!errors.batchSubnetMask}
                              helperText={(touched.batchSubnetMask && errors.batchSubnetMask) || ' '}
                            />
                          </Grid>
                          <Grid item>
                            <TextField
                              label={defaultGatewayStr}
                              name="batchDefaultGateway"
                              value={values.batchDefaultGateway}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              variant="outlined"
                              size="small"
                              error={touched.batchDefaultGateway && !!errors.batchDefaultGateway}
                              helperText={(touched.batchDefaultGateway && errors.batchDefaultGateway) || ' '}
                            />
                          </Grid>
                        </Grid>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="medium"
                          sx={applyButtonStyle}
                          onClick={handleBatchFillIn}
                        >
                          {applyStr}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item sx={formContainerStyle}>
                    {generateAssociationGrid()}
                  </Grid>
                  <DialogActions sx={sharedStyle.common.padding.none}>
                    <LoadingButton variant="contained" color="primary" type="submit" onClick={submitForm}>
                      {associateDevicesStr}
                    </LoadingButton>
                  </DialogActions>
                </Grid>
                <Dialog
                  open={isAssociating}
                  onClose={() => {
                    return;
                  }}
                  maxWidth="sm"
                  fullWidth
                >
                  <Box sx={sharedStyle.common.padding.md}>
                    <Grid container sx={associatingGridContentStyle}>
                      {isRebooting ? (
                        <RestartAltOutlinedIcon sx={associatingIconStyle} />
                      ) : (
                        <CloudSyncIcon sx={associatingIconStyle} />
                      )}
                      <Grid container sx={[sharedStyle.gridContainer, sharedStyle.common.gap.md]}>
                        <Typography variant="body1">
                          {isRebooting ? stationsRebootingStr : stationsBeingAssociatedStr}
                        </Typography>
                        <Grid container item sx={progressBarGridStyle}>
                          <Grid item sx={progressBarStyle}>
                            <BorderLinearProgress
                              variant="determinate"
                              value={(currentProgress / totalProgress) * 100}
                            />
                          </Grid>
                          {!isRebooting && (
                            <Typography variant="body2" sx={sharedStyle.common.padding.x.lg}>
                              {`${currentProgress} / ${totalProgress}`}
                            </Typography>
                          )}
                        </Grid>
                        <Typography variant="body2">{isRebooting ? countdownStr : timeEstimateStr}</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Dialog>
              </>
            );
          }}
        </Formik>
      </DialogWrapper>
      <SnackbarAlert type="error" time={3000} text={error ?? ''} isOpen={!!error} onClose={() => setError(null)} />
      <SnackbarAlert
        type="success"
        time={3000}
        text={success ?? ''}
        isOpen={!!success}
        onClose={() => setSuccess(null)}
      />
    </>
  );
};

export default AssociateDevicesDialog;
