import { InfoOutlined, ChevronRight } from '@mui/icons-material';
import { Box, Typography, Button } from '@mui/material';
import { useState } from 'react';
import SyncExistingCredentials from './Dialogs/SyncExistingCredentials';
import useSharedStyles from 'styles/useSharedStyles';
import { getStatusCodeButtonString, getStatusCodeString } from './utils/utils';

interface SiteActivityErrorItemProps {
  item: any;
  stationName?: string;
  stationIp?: string;
}

const SiteActivityErrorItem = ({ item, stationName, stationIp }: SiteActivityErrorItemProps) => {
  const [isSyncExistingCredentialsOpen, setIsSyncExistingCredentialsOpen] = useState(false);
  const statusCode = Object.values(item.payload as { [key: string]: { statusCode: string } })[0].statusCode.slice(0, 3);
  const statusCodeStr = getStatusCodeString(statusCode);
  const statusButtonStr = getStatusCodeButtonString(statusCode);
  const sharedStyles = useSharedStyles();

  return (
    <>
      <Box sx={sharedStyles.deviceStatusBox}>
        <Box>
          <Typography variant="body1">{stationName}</Typography>
          <Typography
            variant="body2"
            color={item.status === 'failure' ? 'error.main' : 'warning.main'}
            sx={sharedStyles.flexCenterSmGap}
          >
            <InfoOutlined color={item.status === 'failure' ? 'error' : 'warning'} />
            {statusCodeStr}
          </Typography>
        </Box>
        {statusCode === '402' && (
          <Box sx={sharedStyles.deviceStatusDescription}>
            <Button
              variant="outlined"
              endIcon={<ChevronRight />}
              onClick={() => setIsSyncExistingCredentialsOpen(true)}
            >
              {statusButtonStr}
            </Button>
          </Box>
        )}
      </Box>
      <SyncExistingCredentials
        item={item}
        stationIp={stationIp}
        isSyncExistingCredentialsOpen={isSyncExistingCredentialsOpen}
        setIsSyncExistingCredentialsOpen={setIsSyncExistingCredentialsOpen}
      />
    </>
  );
};

export default SiteActivityErrorItem;
