import {
  AddressBookOutlineIcon,
  CallSettingsOutlineIcon,
  EntranceStationsOutlineIcon,
  GatewaySettingsIcon,
  InputOutputOutlineIcon,
  IntegrationsOutlineIcon,
  LiftControlOutlineIcon,
  MaintenanceOutlineIcon,
  NetworkInfoLANPortOutlineIcon,
  PadlockOutlineIcon,
  PagingOutlineIcon,
  SiteInfoOutlineIcon,
  SoundSettingsOutlineIcon,
  TransferOutlineIcon
} from 'shared/components/icons/icons';
import { getDeviceCategoryFromDeviceTypeModel } from 'shared/utils/helperFunctions';
import { DeviceCategory } from 'features/RemoteManagement/types/Types';
import { IDevice } from 'store/slices/devicesSlice';
import { MenuItemEnum, SidebarMenuItem } from './useSidebarMenuItems';
import { useTranslation } from 'react-i18next';

const generateAllMenuItems = (t: any, devicePublicId: string, sitePublicId: string) => ({
  StationInfo: {
    text: t('Station_Info'),
    icon: <SiteInfoOutlineIcon viewBox={'0 0 26 26'} />,
    type: MenuItemEnum.MenuItem,
    to: `/site/${sitePublicId}/devices/${devicePublicId}/stationinfo`
  },
  NetworkInfo: {
    text: t('Network_Info'),
    icon: <NetworkInfoLANPortOutlineIcon viewBox={'0 0 26 26'} />,
    type: MenuItemEnum.MenuItem,
    to: `/site/${sitePublicId}/devices/${devicePublicId}/networkinfo`
  },
  SecuritySettings: {
    text: t('Security_Settings'),
    icon: <PadlockOutlineIcon viewBox={'0 0 26 26'} />,
    type: MenuItemEnum.MenuItem,
    to: `/site/${sitePublicId}/devices/${devicePublicId}/securitysettings`
  },
  CallSettings: {
    text: t('Call_Settings'),
    icon: <CallSettingsOutlineIcon viewBox={'0 0 26 26'} />,
    type: MenuItemEnum.MenuItem,
    to: `/site/${sitePublicId}/devices/${devicePublicId}/callsettings`
  },
  AddressBook: {
    text: t('Address_Book'),
    icon: <AddressBookOutlineIcon viewBox={'0 0 26 26'} />,
    type: MenuItemEnum.MenuItem,
    to: `/site/${sitePublicId}/devices/${devicePublicId}/addressbook`
  },
  EntranceSettings: {
    text: t('Entrance_Settings'),
    icon: <EntranceStationsOutlineIcon viewBox={'0 0 26 26'} />,
    type: MenuItemEnum.MenuItem,
    to: `/site/${sitePublicId}/devices/${devicePublicId}/entrancesettings`
  },
  PhoneSettings: {
    text: t('AdvancedSettings_Tab_PhoneSettings'),
    icon: <GatewaySettingsIcon viewBox="0 0 26 26" />,
    type: MenuItemEnum.MenuItem,
    to: `/site/${sitePublicId}/devices/${devicePublicId}/phonesettings`
  },
  SoundSettings: {
    text: t('Sound_Settings'),
    icon: <SoundSettingsOutlineIcon viewBox={'0 0 26 26'} />,
    type: MenuItemEnum.MenuItem,
    to: `/site/${sitePublicId}/devices/${devicePublicId}/soundsettings`
  },
  InputOutput: {
    text: t('Input_Output'),
    icon: <InputOutputOutlineIcon viewBox={'0 0 26 26'} />,
    type: MenuItemEnum.MenuItem,
    to: `/site/${sitePublicId}/devices/${devicePublicId}/inputoutput`
  },
  Integrations: {
    text: t('Integrations'),
    icon: <IntegrationsOutlineIcon viewBox={'0 0 26 26'} />,
    type: MenuItemEnum.MenuItem,
    to: `/site/${sitePublicId}/devices/${devicePublicId}/integrations`
  },
  Maintenance: {
    text: t('Maintenance'),
    icon: <MaintenanceOutlineIcon viewBox={'0 0 26 26'} />,
    type: MenuItemEnum.MenuItem,
    to: `/site/${sitePublicId}/devices/${devicePublicId}/maintenance`
  },
  Transfer: {
    text: t('Transfer'),
    icon: <TransferOutlineIcon viewBox={'0 0 26 26'} />,
    type: MenuItemEnum.MenuItem,
    to: `/site/${sitePublicId}/devices/${devicePublicId}/transfer`
  },
  Paging: {
    text: t('Paging'),
    icon: <PagingOutlineIcon viewBox={'0 0 26 26'} />,
    type: MenuItemEnum.MenuItem,
    to: `/site/${sitePublicId}/devices/${devicePublicId}/paging`
  },
  LiftControl: {
    text: t('Lift_Control'),
    icon: <LiftControlOutlineIcon viewBox={'0 0 26 26'} />,
    type: MenuItemEnum.MenuItem,
    to: `/site/${sitePublicId}/devices/${devicePublicId}/liftcontrol`
  },
  DoorRelease: {
    text: t('Door_Release'),
    icon: <AddressBookOutlineIcon viewBox={'0 0 26 26'} />,
    type: MenuItemEnum.MenuItem,
    to: `/site/${sitePublicId}/devices/${devicePublicId}/doorrelease`
  }
});

/**
 * Creates an array of sidebar menu items for a given device and site.
 *
 * @param {IDevice} device - The device object containing information about the device which is being viewed.
 * @returns {SidebarMenuItem[]} An array of sidebar menu items specific to the device category.
 *
 * The function uses the device's type and model to determine its category and then generates
 * a list of menu items accordingly. The menu items are generated using the `generateAllMenuItems`
 * function and are filtered based on the device category.
 *
 * Device categories and their corresponding menu items:
 * - VideoDoorStation: StationInfo, NetworkInfo, SecuritySettings, CallSettings, SoundSettings, InputOutput, Maintenance
 * - AudioDoorStation: StationInfo, NetworkInfo, SecuritySettings, CallSettings, SoundSettings, InputOutput, Maintenance
 * - EmergencyStation, TenantStation: StationInfo, NetworkInfo, SecuritySettings, CallSettings, AddressBook, SoundSettings, InputOutput, Maintenance
 * - EntranceStation: StationInfo, NetworkInfo, SecuritySettings, CallSettings, EntranceSettings, AddressBook, SoundSettings, InputOutput, Maintenance
 * - GuardStation, MasterStation: StationInfo, NetworkInfo, SecuritySettings, CallSettings, AddressBook, SoundSettings, InputOutput, Transfer, Maintenance
 * - SubMasterStation: StationInfo, NetworkInfo, SecuritySettings, CallSettings, DoorRelease, SoundSettings, InputOutput, Transfer, Maintenance
 * - IOAdaptor: StationInfo, NetworkInfo, SecuritySettings, InputOutput, Maintenance
 * - GatewayAdaptor: StationInfo, NetworkInfo, SecuritySettings, Maintenance (with optional PhoneSettings if device model is 14/TGW)
 * - LiftControl: StationInfo, NetworkInfo, SecuritySettings, LiftControl, Maintenance
 */
export const createDeviceMenuItems = (device: IDevice): SidebarMenuItem[] => {
  const { t } = useTranslation();

  if (!device) {
    return [];
  }

  const devicePublicId = device.publicId;
  const sitePublicId = device.sitePublicId;
  const allMenuItems = generateAllMenuItems(t, devicePublicId, sitePublicId);

  const deviceCategory = getDeviceCategoryFromDeviceTypeModel(
    device?.basicInfo?.deviceType,
    device?.basicInfo?.deviceModel
  );

  let deviceMenuItems: SidebarMenuItem[] = [];

  switch (deviceCategory) {
    case DeviceCategory.VideoDoorStation:
      (deviceMenuItems = [
        allMenuItems.StationInfo,
        allMenuItems.NetworkInfo,
        allMenuItems.SecuritySettings,
        allMenuItems.CallSettings,
        allMenuItems.SoundSettings,
        allMenuItems.InputOutput,
        allMenuItems.Maintenance
      ]),
        location;

      break;
    case DeviceCategory.AudioDoorStation:
      (deviceMenuItems = [
        allMenuItems.StationInfo,
        allMenuItems.NetworkInfo,
        allMenuItems.SecuritySettings,
        allMenuItems.CallSettings,
        allMenuItems.SoundSettings,
        allMenuItems.InputOutput,
        allMenuItems.Maintenance
      ]),
        location;

      break;
    case DeviceCategory.EmergencyStation:
    case DeviceCategory.TenantStation:
      (deviceMenuItems = [
        allMenuItems.StationInfo,
        allMenuItems.NetworkInfo,
        allMenuItems.SecuritySettings,
        allMenuItems.CallSettings,
        allMenuItems.AddressBook,
        allMenuItems.SoundSettings,
        allMenuItems.InputOutput,
        allMenuItems.Maintenance
      ]),
        location;

      break;
    case DeviceCategory.EntranceStation:
      (deviceMenuItems = [
        allMenuItems.StationInfo,
        allMenuItems.NetworkInfo,
        allMenuItems.SecuritySettings,
        allMenuItems.CallSettings,
        allMenuItems.EntranceSettings,
        allMenuItems.AddressBook,
        allMenuItems.SoundSettings,
        allMenuItems.InputOutput,
        allMenuItems.Maintenance
      ]),
        location;

      break;
    case DeviceCategory.GuardStation:
    case DeviceCategory.MasterStation:
      (deviceMenuItems = [
        allMenuItems.StationInfo,
        allMenuItems.NetworkInfo,
        allMenuItems.SecuritySettings,
        allMenuItems.CallSettings,
        allMenuItems.AddressBook,
        allMenuItems.SoundSettings,
        allMenuItems.InputOutput,
        allMenuItems.Transfer,
        allMenuItems.Maintenance
      ]),
        location;

      break;
    case DeviceCategory.SubMasterStation:
      (deviceMenuItems = [
        allMenuItems.StationInfo,
        allMenuItems.NetworkInfo,
        allMenuItems.SecuritySettings,
        allMenuItems.CallSettings,
        allMenuItems.DoorRelease,
        allMenuItems.SoundSettings,
        allMenuItems.InputOutput,
        allMenuItems.Transfer,
        allMenuItems.Maintenance
      ]),
        location;

      break;
    case DeviceCategory.IOAdaptor:
      (deviceMenuItems = [
        allMenuItems.StationInfo,
        allMenuItems.NetworkInfo,
        allMenuItems.SecuritySettings,
        allMenuItems.InputOutput,
        allMenuItems.Maintenance
      ]),
        location;

      break;
    case DeviceCategory.GatewayAdaptor:
      (deviceMenuItems = [
        allMenuItems.StationInfo,
        allMenuItems.NetworkInfo,
        allMenuItems.SecuritySettings,
        allMenuItems.Maintenance
      ]),
        location;

      if (Number(device?.basicInfo.deviceModel) === 14) {
        const securitySettingsIndex = deviceMenuItems.indexOf(allMenuItems.SecuritySettings);
        if (securitySettingsIndex !== -1) {
          deviceMenuItems.splice(securitySettingsIndex + 1, 0, allMenuItems.PhoneSettings);
        } else {
          deviceMenuItems.push(allMenuItems.PhoneSettings);
        }
      }
      break;
    case DeviceCategory.LiftControl:
      (deviceMenuItems = [
        allMenuItems.StationInfo,
        allMenuItems.NetworkInfo,
        allMenuItems.SecuritySettings,
        allMenuItems.LiftControl,
        allMenuItems.Maintenance
      ]),
        location;

      break;
  }

  return deviceMenuItems;
};
