import React from 'react';
import { useTranslation } from 'react-i18next';
import { CreateSiteDialogProps } from './CreateSiteTypes';
import {
  Alert,
  AlertTitle,
  Box,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import { ErrorMessage, Field } from 'formik';
import ApartmentIcon from '@mui/icons-material/Apartment';
import BusinessIcon from '@mui/icons-material/Business';
import CheckIcon from '@mui/icons-material/Check';
import { EnumList, fetchLocalEnumList } from 'shared/utils/EnumUtils';
import useSharedStyles from 'styles/useSharedStyles';
import { useGetSitesQuery } from 'services/aiphoneCloud';

const SiteTypeStep: React.FC<CreateSiteDialogProps> = ({ formikProps, aclSites }) => {
  const { values, touched, errors, setFieldValue, handleChange, setTouched } = formikProps;
  const enumList: EnumList = fetchLocalEnumList();
  const sharedStyle = useSharedStyles();
  const commercialId = parseInt(
    Object.keys(enumList.siteType).find((key) => {
      return enumList.siteType[key].value === 'Commercial';
    }) as string
  );
  const multiTenantId = parseInt(
    Object.keys(enumList.siteType).find((key) => {
      return enumList.siteType[key].value === 'Multi-tenant';
    }) as string
  );

  const { t } = useTranslation();
  const commercialTitle = t('Site.Types.Commercial.Title');
  const commercialDesc = t('Site.Types.Commercial.Desc');
  const commercialDoorStationPreset = t('Site.Types.Commercial.DoorStationPreset');
  const commercialAnsweringStationPreset = t('Site.Types.Commercial.AnsweringStationPreset');
  const multiTenantTitle = t('Site.Types.MultiTenant.Title');
  const multiTenantDesc = t('Site.Types.MultiTenant.Desc');
  const chooseTypeStr = t('Site.CreateSiteDialog.Type.ChooseType');
  const isPreexistingStr = t('Site.CreateSiteDialog.Type.IsPreexisting');
  const notPreexistingStr = t('Site.CreateSiteDialog.Type.NotPreexisting');
  const preexistingStr = t('Site.CreateSiteDialog.Type.Preexisting');
  const preexistingReprogramStr = t('Site.CreateSiteDialog.Type.PreexistingReprogram');
  const importantStr = t('Shared.Important');
  const ixgPropertyIdStr = t('Site.IXGPropertyID');
  const systemIdStr = t('Site.SystemID');
  const systemPasswordStr = t('Site.SystemPassword');

  // The option refetchOnMountOrArgChange set to true can force this data to refetch on mount, meaning any newly created sites will populate.
  // Pass it as the second argument in useGetSitesQuery({}, { refetchOnMountOrArgChange: true })
  const { data: sites } = useGetSitesQuery({});

  const iterate = (obj: Record<string, { aclSiteId: string }> | null) => {
    if (!obj) {
      return [];
    }
    return Object.values(obj).map((site) => site.aclSiteId);
  };

  // It's recommended that this is wrapped in a useMemo call because of the hundreds and soon thousands of site IDs that need to be computed.
  const siteIds = React.useMemo(() => iterate(sites), [sites]);

  const siteTypes = [
    {
      value: commercialId,
      iconElem: BusinessIcon,
      title: commercialTitle,
      desc: commercialDesc,
      features: [commercialDoorStationPreset, commercialAnsweringStationPreset]
    },
    {
      value: multiTenantId,
      iconElem: ApartmentIcon,
      title: multiTenantTitle,
      desc: multiTenantDesc
    }
  ];

  const handleSiteTypeSelection = (selectedId: number) => {
    return () => {
      setFieldValue('typeId', selectedId);
    };
  };

  const handleIsPreexistingSelection = (selectedId: boolean) => {
    return () => {
      setFieldValue('newProperty', selectedId);

      if (selectedId) {
        setFieldValue('newPropertyId', '');
        setFieldValue('systemId', '');
        setFieldValue('systemPassword', '');
        setTouched({
          newPropertyId: false,
          systemId: false,
          systemPassword: false
        });
      }
    };
  };

  return (
    <Grid container sx={sharedStyle.gridContainer}>
      <Grid item>
        <Typography variant="h6">{chooseTypeStr}</Typography>
      </Grid>
      <Grid item>
        <RadioGroup name="typeId" value={values.typeId}>
          <Grid container sx={styles.siteTypeRadioGrid}>
            {siteTypes.map((siteType) => (
              <Grid item key={siteType.value}>
                <FormControlLabel
                  sx={[sharedStyle.radioItem]}
                  value={siteType.value}
                  onClick={handleSiteTypeSelection(siteType.value)}
                  control={<Radio />}
                  label={
                    <>
                      <Box color="text.secondary">
                        <Box sx={sharedStyle.inlineBox}>
                          {values.typeId === siteType.value ? (
                            <siteType.iconElem sx={sharedStyle.inlineBox.iconSecondary} color="primary" />
                          ) : (
                            <siteType.iconElem sx={sharedStyle.inlineBox.iconSecondary} />
                          )}
                          <Typography color="text.primary">{siteType.title}</Typography>
                        </Box>
                        <Typography variant="body2">{siteType.desc}</Typography>
                      </Box>
                      {siteType.features ? (
                        <Box
                          sx={[
                            sharedStyle.infoBox,
                            sharedStyle.common.padding.xs,
                            values.typeId === siteType.value ? sharedStyle.common.showItem : sharedStyle.common.hideItem
                          ]}
                        >
                          {siteType.features.map((feature, index) => (
                            <Box key={index} sx={sharedStyle.inlineBox}>
                              <CheckIcon color="success" sx={sharedStyle.inlineBox.iconSecondary} />
                              <Typography>{feature}</Typography>
                            </Box>
                          ))}
                        </Box>
                      ) : null}
                    </>
                  }
                />
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
        <Box sx={[sharedStyle.errorMessage, sharedStyle.errorMessage.fixedHeight]}>
          <ErrorMessage name="typeId" />
        </Box>
      </Grid>
      <Grid item>
        <Typography variant="h6">{isPreexistingStr}</Typography>
      </Grid>
      <Grid item>
        <RadioGroup name="newProperty" value={values.newProperty}>
          <FormControlLabel
            sx={sharedStyle.radioItem}
            key={'existingTrue'}
            value={true}
            control={<Radio />}
            label={notPreexistingStr}
            onChange={handleIsPreexistingSelection(true)}
          />
          <FormControlLabel
            sx={sharedStyle.radioItem}
            key={'existingFalse'}
            value={false}
            control={<Radio />}
            label={preexistingStr}
            onChange={handleIsPreexistingSelection(false)}
          />
        </RadioGroup>
      </Grid>
      <Grid item sx={values.newProperty ? sharedStyle.common.hideItem : sharedStyle.common.showItem}>
        <Grid container sx={sharedStyle.gridContainer}>
          <Grid item>
            <Alert severity="info">
              <AlertTitle>{importantStr}</AlertTitle>
              {preexistingReprogramStr}
            </Alert>
          </Grid>
          <Grid container spacing={1.5}>
            <Grid item xs={4}>
              <TextField
                name="newPropertyId"
                id="newPropertyId"
                select
                label={ixgPropertyIdStr}
                disabled={values.newProperty}
                value={values.newPropertyId}
                size="medium"
                fullWidth
                helperText={(touched.newPropertyId && errors.newPropertyId) || ' '}
                error={touched.newPropertyId && !!errors.newPropertyId}
                onChange={handleChange}
              >
                {aclSites &&
                  siteIds &&
                  siteIds.length > 0 &&
                  aclSites
                    .filter((id) => !siteIds.includes(id.toString()))
                    .map((id) => (
                      <MenuItem key={id} value={id}>
                        {id}
                      </MenuItem>
                    ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <Field
                name="systemId"
                as={TextField}
                label={systemIdStr}
                fullWidth
                size="medium"
                variant="outlined"
                disabled={values.newProperty}
                helperText={(touched.systemId && errors.systemId) || ' '}
                error={touched.systemId && !!errors.systemId}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="systemPassword"
                as={TextField}
                label={systemPasswordStr}
                fullWidth
                size="medium"
                variant="outlined"
                disabled={values.newProperty}
                helperText={(touched.systemPassword && errors.systemPassword) || ' '}
                error={touched.systemPassword && !!errors.systemPassword}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SiteTypeStep;

const styles = {
  siteTypeRadioGrid: {
    gap: 2
  }
};
