import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { RegexIpV4OrDomain } from 'features/RemoteManagement/types/Types';
import { ipV4AddressBlacklist } from './IpV4Address';

const useNtpIpV4AddressValidationSchema = (fieldName: string): Yup.StringSchema => {
  const { t } = useTranslation(); // Initialize the translation hook
  const formattedFieldInvalid = t('Field.Error.Invalid', { field: fieldName });

  return Yup.string()
    .notOneOf(ipV4AddressBlacklist, formattedFieldInvalid)
    .matches(RegexIpV4OrDomain, formattedFieldInvalid);
};

export default useNtpIpV4AddressValidationSchema;
