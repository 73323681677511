import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { fetchPaymentInfo } from '../lib/api/LicenseManagementApi';
import { PaymentInfo, REACT_QUERY_TEN_MINUTES_STALE_TIME } from '../components/common/Types';
import useReduxUsers from 'features/Dashboard/Hooks/useReduxUsers';
import { useCurrentBranchWithReactQuery } from './useCurrentBranchWithReactQuery';

export const useFetchPaymentInfo = (subscriptionId: string): UseQueryResult<PaymentInfo> => {
  const { data: currentBranch } = useCurrentBranchWithReactQuery();
  const { currentUser } = useReduxUsers();
  const branchId = currentBranch?.publicId;

  return useQuery<PaymentInfo>({
    queryKey: ['paymentInfo', subscriptionId],
    queryFn: () => fetchPaymentInfo(branchId ?? '', subscriptionId, currentUser?.stripeCustomerId ?? ''),
    enabled: !!subscriptionId,
    staleTime: REACT_QUERY_TEN_MINUTES_STALE_TIME
  });
};
