import { EnumList, IStateValue } from 'shared/utils/EnumUtils';
import { LicenseStatus, DeploymentType } from './Types';

export const mapDeploymentType = (type: DeploymentType): string => {
  return type === DeploymentType.Cloud ? 'Cloud' : 'Local';
};

export const formatDate = (isoDate: string) => {
  const date = new Date(isoDate);
  return date.toLocaleDateString();
};

export const getFormattedDuration = (months: number): string => {
  const years = months / 12;
  const yearsText = years === 1 ? `${years} year` : `${years} years`;
  return yearsText;
};

export const getColorFromStatus = (status: LicenseStatus) => {
  switch (status) {
    case LicenseStatus.Activated:
      return 'success';
    case LicenseStatus.Unactivated:
      return 'warning';
    case LicenseStatus.Expired:
      return 'error';
    case LicenseStatus.Unknown:
    case LicenseStatus.Error:
    default:
      return 'error';
  }
};

export const getFilteredStateList = <T>(
  countryId: string,
  enumList: EnumList,
  mapFn: (state: IStateValue) => T | null
): T[] => {
  return Object.keys(enumList.state)
    .map((key) => {
      const state = enumList.state[key] as IStateValue;
      if (state.countryId.toString() === countryId && state.value !== 'Unknown') {
        return mapFn(state);
      }
      return null;
    })
    .filter((state) => state !== null) as T[];
};

export const formatCurrency = (currencyCode: string, amount: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
    maximumFractionDigits: 2
  }).format(amount);
};
