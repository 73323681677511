import { useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/utils/StringUtils';

function PasswordField(props: any) {
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const passwordStr = t('Shared.Password');
  const toggleItemVisibilityStr = t('Shared.ToggleItemVisibility');
  const togglePasswordVisibilityStr = StringUtils.format(toggleItemVisibilityStr, passwordStr);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <TextField
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label={togglePasswordVisibilityStr}
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              tabIndex={-1}
              edge="end"
            >
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        )
      }}
      type={showPassword ? 'input' : 'password'}
      {...props}
      sx={{
        ...props.sx,
        ...styles.inputStyle
      }}
    />
  );
}

const styles = {
  inputStyle: {
    '.MuiInputBase-adornedEnd': {
      bgcolor: 'background.default'
    }
  }
};

export default PasswordField;
