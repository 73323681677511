import { Box, IconButton, Typography } from '@mui/material';
import { GatewayStatus } from '../types/Types';

interface ActionCenterButtonProps {
  icon: JSX.Element;
  text: string;
  handler?: () => void;
  gatewayStatus?: string;
  isFirmwareLatest?: boolean;
  disabled?: boolean;
}

const ActionCenterButton = ({
  icon,
  text,
  handler,
  gatewayStatus,
  isFirmwareLatest,
  disabled
}: ActionCenterButtonProps) => {
  const isDisabled =
    gatewayStatus === GatewayStatus.OFFLINE ||
    gatewayStatus === GatewayStatus.CHECKING ||
    gatewayStatus === GatewayStatus.UNREGISTERED ||
    isFirmwareLatest ||
    disabled;

  return (
    <Box sx={styles.actionCenterButtonContainer}>
      <IconButton onClick={handler} sx={styles.actionCenterButton} disabled={isDisabled}>
        {icon}
      </IconButton>
      <Typography variant="body2">{text}</Typography>
    </Box>
  );
};

const styles = {
  actionCenterButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0',
    gap: '8px'
  },
  actionCenterButton: {
    border: '1px solid',
    borderColor: 'divider',
    borderRadius: '100px',
    color: 'primary.main'
  }
};

export default ActionCenterButton;
