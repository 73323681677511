import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { LoadingButton } from '@mui/lab';
import { CA, US } from 'country-flag-icons/react/3x2';
import { Field, Form, Formik } from 'formik';
import { useCreateCompanyMutation } from 'services/aiphoneCloud';
import * as yup from 'yup';
import { EnumList, ICountryValue, fetchEnumList } from 'shared/utils/EnumUtils';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import getStateList from 'shared/utils/GetStateList';
import ZipPostalCodeField from 'shared/components/forms/fields/ZipPostalCodeField';
import StringUtils from 'shared/utils/StringUtils';
import { CompanyRecord } from 'features/DealerCompanies/Components/Datagrids/CompaniesDataGrid';
import { RegexPostalCode, RegexZipCode } from 'features/RemoteManagement/types/Types';
import isUsaOrCanada from 'shared/utils/IsUsaOrCanada';

interface IAddCompanyDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setIsError: (isError: boolean) => void;
  setErrorMessage: (errorMessage: string) => void;
  setIsSuccess: (isSuccess: boolean) => void;
  setSuccessMessage: (successMessage: string) => void;
  setCompaniesList: React.Dispatch<
    React.SetStateAction<{
      [key: string]: CompanyRecord;
    }>
  >;
}

const AddCompanyDialog = ({
  isOpen,
  setIsOpen,
  setIsError,
  setErrorMessage,
  setIsSuccess,
  setSuccessMessage,
  setCompaniesList
}: IAddCompanyDialogProps) => {
  const [createCompany, { isLoading: isCompanyLoading }] = useCreateCompanyMutation();
  const [enumList, setEnumList] = useState<EnumList>({ country: {}, state: {} });
  const canadaCountryId = Object.keys(enumList.country).find((key) => {
    return (enumList.country[key] as ICountryValue).alpha2Code === 'CA';
  });
  const { t } = useTranslation();
  const cancelStr = t('Shared.Cancel');
  const selectAItemStr = t('Shared.SelectAItem');
  const countryStr = t('Shared.Country');
  const stateStr = t('Shared.State');
  const provinceStr = t('Shared.Province');
  const zipCodeStr = t('Shared.ZipCode');
  const postalCodeStr = t('Shared.PostalCode');
  const selectACountryStr = StringUtils.format(selectAItemStr, countryStr);
  const selectAStateStr = StringUtils.format(selectAItemStr, stateStr);
  const selectAProvinceStr = StringUtils.format(selectAItemStr, provinceStr);
  const postalCodeInvalidStr = t('Field.Error.Invalid', { field: postalCodeStr });
  const zipCodeInvalidStr = t('Field.Error.Invalid', { field: zipCodeStr });
  const postalCodeRequiredStr = t('Field.Error.Required', { field: postalCodeStr });
  const zipCodeRequiredStr = t('Field.Error.Required', { field: zipCodeStr });

  useEffect(() => {
    fetchEnumList().then((data) => {
      setEnumList(data);
    });
  }, []);

  const initialValues = {
    companyName: '',
    countryId: '',
    stateId: '',
    postalCode: ''
  };

  const handleCreateCompany = async (values: typeof initialValues) => {
    const companyData = {
      name: values.companyName,
      stateId: values.stateId,
      postalCode: values.postalCode,
      type: 1,
      statusId: 1,
      dealerCompanyPublicId: null
    };

    createCompany({ companyData })
      .unwrap()
      .then((response) => {
        if (response.error) {
          setIsError(true);
          setErrorMessage(response.error.message);
          return;
        }
        setIsOpen(false);
        setIsSuccess(true);
        const newlyCreatedPublicId = response[0].newly_created_company_public_id;
        setCompaniesList((prevCompaniesList) => {
          return {
            ...prevCompaniesList,
            [newlyCreatedPublicId]: {
              id: newlyCreatedPublicId,
              name: values.companyName,
              stateId: values.stateId,
              postalCode: values.postalCode,
              companyTypeId: 1,
              dealerCompanyPublicId: null
            }
          };
        });
        setSuccessMessage(t('Company_Created_Successfully'));
      })
      .catch((error) => {
        setErrorMessage(JSON.parse(error.data)?.errorDetails || t('Unexpected_Error'));
        setIsError(true);
      });
  };

  const validationSchema = yup.object().shape({
    companyName: yup.string().required(t('Validations.Company_Name_Required')),
    stateId: yup.number().optional(),
    countryId: yup.number().optional(),
    postalCode: yup.string().when('countryId', {
      is: 2,
      then: (schema) =>
        schema
          .min(5, t('Dynamic_min', { min: 5 }))
          .max(5, t('Dynamic_max', { max: 5 }))
          .matches(RegexZipCode, zipCodeInvalidStr)
          .required(zipCodeRequiredStr),
      otherwise: (schema) =>
        schema
          .min(6, t('Dynamic_min', { min: 6 }))
          .max(7, t('Dynamic_max', { max: 7 }))
          .matches(RegexPostalCode, postalCodeInvalidStr)
          .required(postalCodeRequiredStr)
    })
  });

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <Grid container spacing={1}>
        <Grid item xs={11}>
          <DialogTitle>{t('Enter_CompanyDetails')}</DialogTitle> {/* Localization */}
        </Grid>
        <Grid item xs={1}>
          <IconButton color="default" aria-label="cancel" onClick={() => setIsOpen(false)}>
            <CancelIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Formik initialValues={initialValues} onSubmit={handleCreateCompany} validationSchema={validationSchema}>
        {({ handleChange, errors, isSubmitting, touched, isValid, values, setFieldValue }) => (
          <Form>
            <DialogContent>
              <Box sx={styles.my2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      label={t('Company_Name')}
                      name="companyName"
                      as={TextField}
                      error={errors.companyName && touched.companyName}
                      helperText={errors.companyName && touched.companyName ? errors.companyName : ''}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel id="country-label">{selectACountryStr}</InputLabel> {/* Localization */}
                      <Field
                        name="countryId"
                        as={Select}
                        labelId="country-label"
                        label={selectACountryStr}
                        onChange={async (e: any) => {
                          handleChange(e);
                          await setFieldValue('stateId', '');
                          await setFieldValue('postalCode', '');
                        }}
                        error={errors.countryId && touched.countryId}
                        helperText={errors.countryId && touched.countryId ? errors.countryId : ''}
                      >
                        {Object.keys(enumList.country).map((key) => {
                          const validCountries = ['CA', 'US'];
                          const countryWalker = enumList.country[key] as ICountryValue;

                          if (!validCountries.includes(countryWalker.alpha2Code)) {
                            return null;
                          }

                          const flagComponent: JSX.Element =
                            countryWalker.alpha2Code === 'CA' ? (
                              <CA header={countryWalker.defaultLanguageName} style={styles.flagStyle} />
                            ) : (
                              <US header={countryWalker.defaultLanguageName} style={styles.flagStyle} />
                            );

                          return (
                            <MenuItem key={key} value={key}>
                              <Grid sx={styles.field}>
                                {flagComponent}
                                {countryWalker.value}
                              </Grid>
                            </MenuItem>
                          );
                        })}
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel id="state-label">
                        {values.countryId !== canadaCountryId ? selectAStateStr : selectAProvinceStr}
                      </InputLabel>
                      <Field
                        name="stateId"
                        as={Select}
                        labelId="state-label"
                        label={values.countryId !== canadaCountryId ? selectAStateStr : selectAProvinceStr}
                        disabled={!isUsaOrCanada(values.countryId)}
                        error={errors.stateId && touched.stateId}
                        helperText={errors.stateId && touched.stateId ? errors.stateId : ''}
                      >
                        {getStateList(enumList, values.countryId, selectACountryStr)}
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      fullWidth
                      countryId={values.countryId}
                      label={values.countryId !== canadaCountryId ? zipCodeStr : postalCodeStr}
                      placeholder={values.countryId !== canadaCountryId ? zipCodeStr : postalCodeStr}
                      name="postalCode"
                      disabled={!isUsaOrCanada(values.countryId)}
                      component={ZipPostalCodeField}
                      helperText={errors.postalCode && touched.postalCode ? errors.postalCode : ''}
                      error={errors.postalCode && touched.postalCode}
                    />
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={() => setIsOpen(false)} color="primary">
                {cancelStr}
              </Button>
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                loading={isCompanyLoading}
                disabled={
                  isSubmitting ||
                  isCompanyLoading ||
                  values.companyName.length === 0 ||
                  !!errors.companyName ||
                  !!errors.stateId ||
                  !!errors.postalCode ||
                  !!errors.countryId ||
                  !isValid
                }
              >
                {t('Button_CreateCompany')} {/* Localization */}
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

const styles = {
  my2: {
    marginY: 2
  },
  field: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  flagStyle: {
    width: '30px',
    minWidth: '30px',
    height: '20px',
    paddingRight: '10px'
  }
};

export default AddCompanyDialog;
