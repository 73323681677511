import { Box, DialogActions, Grid, TextField, Typography } from '@mui/material';
import { RemoteManagementContext } from 'context/RemoteManagementContext';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import DialogWrapper from 'shared/components/dialogs/DialogWrapper';
import useSharedStyles from 'styles/useSharedStyles';
import { ActivityItem } from '../SiteActivityCommands';
import { CustomMessageEvent } from 'context/SiteContext';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

interface SyncExistingCredentialsProps {
  item: any;
  stationIp?: string;
  isSyncExistingCredentialsOpen: boolean;
  setIsSyncExistingCredentialsOpen: Dispatch<SetStateAction<boolean>>;
}

export const getInitialCommandPayload = (messageHistory: CustomMessageEvent<any>[], transactionId: string) => {
  const parsedMessages: ActivityItem[] = [];
  messageHistory.forEach((message: any) => {
    const messageData = JSON.parse(message.data);
    parsedMessages.push(messageData);
  });

  const transactionMessages = parsedMessages.filter((message: any) => message.transactionId === transactionId);
  const initialCommandPayload = transactionMessages[0].commandPayload;

  return initialCommandPayload;
};

const SyncExistingCredentials = ({
  item,
  stationIp,
  isSyncExistingCredentialsOpen,
  setIsSyncExistingCredentialsOpen
}: SyncExistingCredentialsProps) => {
  const [existingId, setExistingId] = React.useState('');
  const [existingPassword, setExistingPassword] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const sharedStyle = useSharedStyles();
  const { syncCommand, messageHistory, isSyncing } = useContext(RemoteManagementContext);

  const { t } = useTranslation();
  const dialogDescriptionStr = t('RemoteManagement.SiteActivity.Modals.InvalidCredentials.Description');
  const dialogButtonStr = t('RemoteManagement.SiteActivity.Sync.Name');

  const handleClose = () => {
    setIsSyncExistingCredentialsOpen(false);
  };

  const handleSync = () => {
    if (!existingId || !existingPassword) {
      setErrorMessage('Please enter existing credentials.');
      return;
    } else {
      setErrorMessage('');
    }
    const payload = getInitialCommandPayload(messageHistory, item.transactionId);
    const deviceIp = stationIp || Object.keys(payload)[0];
    payload[deviceIp].id = existingId;
    payload[deviceIp].pw = existingPassword;

    //TODO: Remove previous transactionId from message history
    syncCommand({ [deviceIp]: payload[deviceIp] });
  };

  return (
    <DialogWrapper
      header="Sync Existing Credentials"
      setIsOpen={setIsSyncExistingCredentialsOpen}
      open={isSyncExistingCredentialsOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
    >
      <Box>
        <Typography variant="body1">{dialogDescriptionStr}</Typography>
      </Box>
      <Box>
        <TextField
          label="Existing ID"
          value={existingId}
          onChange={(e) => setExistingId(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Existing Password"
          value={existingPassword}
          onChange={(e) => setExistingPassword(e.target.value)}
          fullWidth
          margin="normal"
        />
      </Box>
      <DialogActions sx={sharedStyle.common.padding.none}>
        <Grid
          container
          sx={[
            sharedStyle.gridContainer,
            sharedStyle.common.padding.none,
            sharedStyle.common.gap.xs,
            sharedStyle.gridContainer.action
          ]}
        >
          <Grid item>
            <Typography sx={sharedStyle.errorMessage.fixedHeight} variant="error">
              {errorMessage}
            </Typography>
          </Grid>
          <Grid item>
            <LoadingButton variant="contained" type="submit" onClick={handleSync} loading={isSyncing}>
              {dialogButtonStr}
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogActions>
    </DialogWrapper>
  );
};

export default SyncExistingCredentials;
