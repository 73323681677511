import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';
import {
  cloudDeploymentsteps,
  DeploymentDetailResponse,
  SelectedStripeProduct
} from 'features/LicenseManagement/components/common/Types';
import PackageStep from './PackageStep';
import { DeploymentSteps } from 'features/LicenseManagement/components/common/DeploymentSteps';
import LicenseAgreementStep from './LicenseAgreementStep';
import { useCurrentBranchWithReactQuery } from 'features/LicenseManagement/hooks/useCurrentBranchWithReactQuery';

interface UnactivatedCloudLicenseProps {
  deployment: DeploymentDetailResponse;
}

export const UnactivatedCloudLicense: React.FC<UnactivatedCloudLicenseProps> = ({ deployment }) => {
  const { t } = useTranslation('acNioLicenseManagement');
  const { data: currentBranch } = useCurrentBranchWithReactQuery();

  const [activeStep, setActiveStep] = useState(0);
  const [numDoors, setNumDoors] = useState(0);
  const [numExpanders, setNumExpanders] = useState(0);
  const [numCabs, setNumCabs] = useState(0);
  const [selectedStripeProducts, setSelectedStripeProducts] = useState<SelectedStripeProduct[] | null>(null);

  const { isAllowedTo } = usePermission();
  // TODO update to correct permission
  const hasManageBillingPermission = isAllowedTo(
    'deploymentBilling:edit',
    currentBranch?.publicId,
    PermissionsContextType.BRANCH
  );

  // Function to render content based on the current step
  const renderStepContent = () => {
    const steps = [
      <PackageStep
        {...{
          activeStep,
          setActiveStep,
          numDoors,
          setNumDoors,
          numExpanders,
          setNumExpanders,
          numCabs,
          setNumCabs,
          setSelectedStripeProducts
        }}
      />,
      <LicenseAgreementStep
        {...{ activeStep, setActiveStep, deployment, numDoors, numExpanders, numCabs, selectedStripeProducts }}
      />
    ];
    return steps[activeStep] || steps[0];
  };

  return (
    <Box sx={styles.container}>
      {hasManageBillingPermission ? (
        <>
          <Typography variant="h6">{t('Finalize_Cloud_Deployment')}</Typography>
          <DeploymentSteps steps={cloudDeploymentsteps} activeStep={activeStep} />
          {renderStepContent()}
        </>
      ) : (
        <Typography variant="body2" sx={{ marginTop: 2 }}>
          {t('Deployment_Has_No_Associated_License')}
        </Typography>
      )}
    </Box>
  );
};

const styles = {
  container: {
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    padding: 2,
    backgroundColor: '#f9f9f9',
    marginTop: '20px',
    alignItems: 'flex-start'
  }
};
