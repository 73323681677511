import React, { useState } from 'react';
import { Box, TextField, Chip, FormControl, InputAdornment, IconButton, FormHelperText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { initialErrors } from './CreateDeploymentInformationModal';
import { EMAIL_REGEX } from 'shared/constants/regex';
import { useTranslation } from 'react-i18next';

interface DeploymentNotificationsFieldProps {
  label: string;
  name: string;
  value?: string[];
  onChange: (newValue: string[]) => void;
  error?: boolean;
  helperText?: string;
  setErrors: React.Dispatch<React.SetStateAction<typeof initialErrors>>;
}

const DeploymentNotificationsField = ({
  label,
  name,
  value,
  onChange,
  error,
  setErrors,
  helperText
}: DeploymentNotificationsFieldProps) => {
  const { t } = useTranslation('acNioLicenseManagement');
  const [inputValue, setInputValue] = useState('');

  // Helper function to validate email
  const isValidEmail = (email: string) => EMAIL_REGEX.test(email);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newInputValue = e.target.value;
    setInputValue(newInputValue);

    const trimmedValue = newInputValue.trim();

    // Reset error if input is empty or email is valid
    setErrors((prevErrors) => ({
      ...prevErrors,
      deployment: {
        ...prevErrors.deployment,
        emailRecipients: trimmedValue && !isValidEmail(trimmedValue) ? true : false
      }
    }));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const trimmedValue = inputValue.trim();

      if (trimmedValue && isValidEmail(trimmedValue) && !value.includes(trimmedValue)) {
        onChange([...value, trimmedValue]);
        setInputValue(''); // Clear input after adding valid email
      }
    }
  };

  // Handle chip delete
  const handleDelete = (emailToDelete: string) => {
    onChange(value.filter((email) => email !== emailToDelete));
  };

  return (
    <FormControl fullWidth style={styles.inputBoxMarginBottom}>
      <TextField
        required
        label={label}
        name={name}
        sx={styles.textField}
        InputLabelProps={{ shrink: true }}
        error={!!error}
        helperText={error ? helperText : ''}
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={t('Enter_Email')}
        InputProps={{
          startAdornment: (
            <Box sx={styles.chipContainer}>
              {value.map((email, index) => (
                <Chip
                  key={index}
                  label={email}
                  onDelete={() => handleDelete(email)}
                  color="primary"
                  size="small"
                  sx={styles.chipText}
                />
              ))}
            </Box>
          ),
          endAdornment: value.length > 0 && (
            <InputAdornment position="end">
              <IconButton onClick={() => onChange([])}>
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      {helperText && !error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

const styles = {
  inputBoxMarginBottom: {
    marginBottom: '1rem'
  },
  textField: {
    height: '40px',
    '& .MuiInputBase-root': {
      height: '40px',
      '&.Mui-focused fieldset': {
        borderColor: '#1CACFE'
      }
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1CACFE'
    },
    '& input:-webkit-autofill': {
      borderRadius: '10px',
      height: '9px'
    }
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 1,
    justifyContent: 'flex-start'
  },
  chipText: {
    marginRight: '4px',
    marginBottom: '4px',
    backgroundColor: '#f0f0f0',
    '& .MuiChip-label': {
      color: 'black'
    }
  }
};

export default DeploymentNotificationsField;
