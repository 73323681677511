import React from 'react';
import {
  Grid,
  TextField,
  Typography,
  Box,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  FormControlLabel,
  FormGroup,
  Switch,
  Link
} from '@mui/material';
import { UserInfo } from 'shared/utils/UserUtils';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import ChangePasswordDialog from 'shared/components/dialogs/ChangePasswordDialog';
import { useUpdateUserMutation } from 'services/aiphoneCloud';
import { getCurrentUser, IUserPermissions, setUserProfile } from 'store/slices/usersSlice';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { JSX } from 'react/jsx-runtime';
import { convertPhoneNumberToE164Format, formatPhoneNumber } from 'shared/utils/helperFunctions';
import MFASettingsDialog from 'shared/components/dialogs/MFASettingsDialog';
import DeleteAccountDialog from 'shared/components/dialogs/DeleteAccountDialog';
import PhoneField from 'shared/components/forms/fields/PhoneField';
import StringUtils from 'shared/utils/StringUtils';
import { NANP_PHONE_REGEX } from 'shared/constants/regex';

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  marketingOptIn: boolean;
}

const emailMaxLength = 254;
const nameMaxLength = 50;

const UserProfile = () => {
  const userObj = useSelector(getCurrentUser);
  const [userInfo, setUserInfo] = useState(new UserInfo(userObj));
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isChangePasswordDialogOpen, setIsChangePasswordDialogOpen] = useState(false);
  const [isMFASettingsDialogOpen, setIsMFASettingsDialogOpen] = useState(false);
  const [isDeleteAccountDialogOpen, setIsDeleteAccountDialogOpen] = useState(false);
  const [updateUser] = useUpdateUserMutation();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const phoneNumberStr = t('Shared.PhoneNumber');
  const emailAddressStr = t('Shared.EmailAddress');
  const firstNameStr = t('Shared.FirstName');
  const lastNameStr = t('Shared.LastName');
  const companyStr = t('Company.Singular');
  const branchStr = t('Branch.Singular');
  const roleStr = t('Shared.Role');
  const saveChangesStr = t('Shared.SaveChanges');
  const phoneNumberRequired = t('Field.Error.Required', { field: phoneNumberStr });
  const phoneNumberInvalid = t('Field.Error.Invalid', { field: phoneNumberStr });
  const emailStr = t('Shared.Email');
  const fieldErrorMaxChars = t('Field.Error.MaxLen');
  const emailMaxLengthStr = StringUtils.format(fieldErrorMaxChars, emailStr, emailMaxLength);
  const firstNameMaxLengthStr = StringUtils.format(fieldErrorMaxChars, firstNameStr, nameMaxLength);
  const lastNameMaxLengthStr = StringUtils.format(fieldErrorMaxChars, lastNameStr, nameMaxLength);
  const emailRequiredStr = t('Field.Error.Required', { field: emailStr });
  const invalidEmailStr = t('Field.Error.Invalid', { field: emailStr });
  const firstNameRequiredStr = t('Field.Error.Required', { field: firstNameStr });
  const lastNameRequiredStr = t('Field.Error.Required', { field: lastNameStr });

  const currentUser = useSelector(getCurrentUser);
  const permissions: IUserPermissions | undefined = currentUser?.permissions;
  const branchRoles = permissions?.branch;
  const registeredUser = currentUser ? !permissions || (!permissions.global && !permissions.branch) : true;
  const hasGlobalRole =
    permissions && permissions.global?.roleList !== null && permissions.global?.roleList !== undefined;

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(firstNameRequiredStr).max(nameMaxLength, firstNameMaxLengthStr),
    lastName: Yup.string().required(lastNameRequiredStr).max(nameMaxLength, lastNameMaxLengthStr),
    email: Yup.string().email(invalidEmailStr).required(emailRequiredStr).max(emailMaxLength, emailMaxLengthStr),
    phoneNumber: Yup.string().required(phoneNumberRequired).matches(NANP_PHONE_REGEX, phoneNumberInvalid)
  });

  const handleUpdateUser = (values: FormValues) => {
    const formattedEmail = values.email.toLowerCase();
    const updateUserRequest = {
      userData: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: formattedEmail,
        phoneNumber: convertPhoneNumberToE164Format(values.phoneNumber),
        phoneExtension: userInfo.phoneExtension,
        countryId: userInfo.countryId,
        language: userInfo.language,
        marketingOptIn: values.marketingOptIn
      }
    };
    setIsLoading(true);
    setUserInfo(new UserInfo(values));
    updateUser(updateUserRequest)
      .unwrap()
      .then(() => {
        setSuccessMessage(t('User_Updated_Successfully'));
        setIsSuccess(true);
        setIsLoading(false);
        const updatedUser = new UserInfo(values);
        updatedUser.saveUser();

        // Store updated user information to user slice
        // This will keep the user slice up to date until reloading
        const newUserProfile = {
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: convertPhoneNumberToE164Format(values.phoneNumber),
          marketingOptIn: values.marketingOptIn
        };
        dispatch(setUserProfile(newUserProfile));
      })
      .catch(() => {
        setErrorMessage(t('User_Update_Failed'));
        setIsError(true);
        setIsLoading(false);
      });
  };

  const generateBranchRoleRows = () => {
    const rows: JSX.Element[] = [];
    if (branchRoles) {
      Object.entries(branchRoles).forEach(([branchId, role]) => {
        rows.push(
          <TableRow key={branchId}>
            <TableCell>{role.companyName}</TableCell>
            <TableCell>{role.branchName}</TableCell>
            <TableCell>{role.roleList[0].roleName}</TableCell>
          </TableRow>
        );
      });
    }
    return rows;
  };

  // The following variable was added to hide the delete option from production temporarily.
  // Once the test is completed, we will remove this limitation.
  const url = new URL(window.location.href); //TODO: will remove later
  const shouldBeVisible = !(url?.hostname === 'aiphone.cloud' || url?.hostname === 'prod.aiphone.cloud'); //TODO: will remove later

  const initialValues = {
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    email: userInfo.email,
    phoneNumber: formatPhoneNumber(userInfo.phoneNumber),
    marketingOptIn: userInfo.marketingOptIn
  } as FormValues;

  const shouldDisableSubmit = (dirty: boolean, isValid: boolean, values: typeof initialValues): boolean => {
    return !(
      dirty &&
      isValid &&
      (initialValues.firstName !== values.firstName ||
        initialValues.lastName !== values.lastName ||
        initialValues.phoneNumber !== values.phoneNumber ||
        initialValues.marketingOptIn !== values.marketingOptIn)
    );
  };

  const ViewUser = () => {
    return (
      <>
        <Box sx={styles.userContainer}>
          <Typography variant="h5" color="initial">
            {t('User_Profile')}
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleUpdateUser(values);
            }}
            enableReinitialize
          >
            {({ errors, touched, values, handleChange, isValid, dirty }) => {
              return (
                <Form>
                  <Box sx={styles.formContainer}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Field
                          as={TextField}
                          label={firstNameStr}
                          name="firstName"
                          size="small"
                          fullWidth={true}
                          helperText={touched.firstName && errors.firstName ? errors.firstName : ''}
                          error={touched.firstName && !!errors.firstName}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          as={TextField}
                          label={lastNameStr}
                          name="lastName"
                          size="small"
                          fullWidth={true}
                          helperText={touched.lastName && errors.lastName ? errors.lastName : ''}
                          error={touched.lastName && !!errors.lastName}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          as={TextField}
                          label={emailAddressStr}
                          name="email"
                          size="small"
                          fullWidth={true}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={PhoneField}
                          label={phoneNumberStr}
                          name={'phoneNumber'}
                          size="small"
                          fullWidth={true}
                          helperText={touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : ''}
                          error={touched.phoneNumber && !!errors.phoneNumber}
                          placeholder={formatPhoneNumber(values.phoneNumber)}
                          maxDigits={11}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="h6" color="initial">
                          {t('Notifications')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormGroup>
                          <FormControlLabel
                            control={<Field as={Switch} name="marketingOptIn" checked={values.marketingOptIn} />}
                            label={t('Marketing_Promotions')}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={6}>
                            <Button variant="contained" onClick={() => setIsChangePasswordDialogOpen(true)}>
                              {t('Field.Shared.Password.Change')}
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <LoadingButton
                              loading={isLoading}
                              type="submit"
                              variant="contained"
                              disabled={shouldDisableSubmit(dirty, isValid, values)}
                              sx={styles.floatRight}
                            >
                              {saveChangesStr}
                            </LoadingButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} sx={styles.mfaBtn}>
                      <Button variant="contained" onClick={() => setIsMFASettingsDialogOpen(true)}>
                        {t('Configure_MFA_Btn')}
                      </Button>
                    </Grid>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
        <Divider sx={{ marginY: 4 }} />
        <Box sx={styles.rolesContainer}>
          <Typography variant="h5" color="initial">
            {t('My_Roles')}
          </Typography>
          <TableContainer component={Paper}>
            <Table aria-label="roles table">
              <TableHead>
                <TableRow>
                  <TableCell>{companyStr}</TableCell>
                  <TableCell>{branchStr}</TableCell>
                  <TableCell>{roleStr}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hasGlobalRole ? (
                  <TableRow key="global">
                    <TableCell>{t('Aiphone')}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>{permissions?.global?.roleList[0]?.roleName}</TableCell>
                  </TableRow>
                ) : null}
                {!registeredUser ? generateBranchRoleRows() : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <SnackbarAlert
          type="error"
          time={6000}
          text={errorMessage}
          isOpen={isError}
          onClose={() => setIsError(false)}
        />
        <SnackbarAlert
          type="success"
          time={6000}
          text={successMessage}
          isOpen={isSuccess}
          onClose={() => {
            setIsSuccess(false);
          }}
        />
        <ChangePasswordDialog
          isChangePasswordDialogOpen={isChangePasswordDialogOpen}
          setIsChangePasswordDialogOpen={setIsChangePasswordDialogOpen}
          setErrorMessage={setErrorMessage}
          setIsError={setIsError}
          setSuccessMessage={setSuccessMessage}
          setIsSuccess={setIsSuccess}
        />
        <MFASettingsDialog dialogIsOpen={isMFASettingsDialogOpen} setDialogIsOpen={setIsMFASettingsDialogOpen} />
        <DeleteAccountDialog isOpen={isDeleteAccountDialogOpen} onClose={() => setIsDeleteAccountDialogOpen(false)} />
        {shouldBeVisible && (
          <Box sx={styles.deleteAccountWrap}>
            <Box sx={{ marginRight: '7px' }}>{t('Delete_User_Account_p1')}</Box>
            <Link href="#" underline="always" onClick={() => setIsDeleteAccountDialogOpen(true)}>
              {t('Delete_User_Account_p2')}
            </Link>
          </Box>
        )}
      </>
    );
  };

  // NOTE: Incase we want to add a feature to edit user profile, we can add a button to toggle the isEditing state and show the form fields for editing the user profile.
  return <ViewUser />;
};

const styles = {
  formContainer: {
    mt: 2
  },
  userContainer: {
    mt: 2
  },
  rolesContainer: {
    mt: 2
  },
  floatRight: {
    float: 'right'
  },
  mfaBtn: {
    marginTop: 2
  },
  deleteAccountWrap: { position: 'absolute', bottom: '15px', left: '50%', display: 'flex' }
};

export default UserProfile;
