import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
import LoadingSpinner from 'features/LicenseManagement/components/common/LoadingSpinner';
import {
  CreateStripeCheckoutSessionRequest,
  DeploymentDetailResponse,
  DeploymentType,
  Product
} from 'features/LicenseManagement/components/common/Types';
import { formatCurrency } from 'features/LicenseManagement/components/common/utils';
import { useFetchStripeProducts } from 'features/LicenseManagement/hooks/useFetchStripeProducts';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DialogWrapper from 'shared/components/dialogs/DialogWrapper';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { LicenseManagementCommonStyles } from 'features/LicenseManagement/styles/CommonStyles';
import theme from 'styles/theme';
import ErrorAlert from 'features/LicenseManagement/components/common/ErrorAlert';
import { useCreateStripeCheckoutSession } from 'features/LicenseManagement/hooks/useCreateStripeCheckoutSession';
import { useCurrentBranchAddress } from 'features/LicenseManagement/hooks/useCurrentBranchAddress';
import useReduxUsers from 'features/Dashboard/Hooks/useReduxUsers';

interface LicenseAddOnDialogProps {
  openLicenseAddOnDialog: boolean;
  accountNumber: number;
  hartmannLicenseId: string;
  deployment: DeploymentDetailResponse;
  setIsOpen: any;
  onClose: () => void;
}

const LicenseAddOnDialog = (props: LicenseAddOnDialogProps) => {
  const { openLicenseAddOnDialog, accountNumber, hartmannLicenseId, deployment, setIsOpen, onClose } = props;
  //#region String Constants
  const { t } = useTranslation('acNioLicenseManagement');
  const addOnDialogTitle = t('AddOn_Purchase_Dialog_Title');
  const addOnDialogSubtitle = t('AddOn_Purchase_Dialog_Subtitle');
  const addOnPurchaseContinue = t('Continue_To_Payment');
  const backButtonStr = t('Back');
  const wirelessLockStr = t('AC_Lockset');
  const itemStr = t('Items');
  const unitPriceStr = t('Unit_Price');
  const qtyStr = t('QTY');
  const totalStr = t('Total');
  const orderSummaryStr = t('Package_Value');
  const orderSummaryHeaderStr = t('Package_Value_Desc');
  const wirelessLocksCountStr = t('AC_Lockset_Count');
  const packageConentStr = t('AddOn_Purchase_Dialog_Content');
  const grandTotalStr = t('Grand_Total');
  const checkoutSessionError = t('Error_Creating_Checkout_Session');
  const productError = t('Error_Loading_Data');
  //#endregion

  const { currentUser } = useReduxUsers();
  const { line1, city, state, country, countryNumericCode, postalCode } = useCurrentBranchAddress();

  const [wirelessLocksCount, setWirelessLocksCount] = useState(1);
  const [initialWirelessLocksCount] = useState(1);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorStatus, setErrorStatus] = useState(false);

  const handleWirelessLocksCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);
    if (value > 0) {
      setWirelessLocksCount(value);
    }
  };
  const incrementWirelessLocksCount = () => {
    setWirelessLocksCount(wirelessLocksCount + 1);
  };

  const decrementWirelessLocksCount = () => {
    setWirelessLocksCount(Math.max(1, wirelessLocksCount - 1));
  };

  const handleClosePurchaseAddOnDialog = () => {
    setWirelessLocksCount(initialWirelessLocksCount);
    onClose();
  };

  const { mutate: createStripeCheckoutSession, isPending: isPendingCreateStripeSession } =
    useCreateStripeCheckoutSession(
      () => {
        setErrorStatus(false);
        setErrorMessage('');
      },
      (error: any) => {
        setErrorStatus(true);
        setErrorMessage(error.message);
      }
    );

  const {
    data: products = [],
    isLoading: isLoadingProducts,
    isError: isFetchStripeProductsError,
    error,
    refetch: refetchStripeProducts
  } = useFetchStripeProducts(countryNumericCode, DeploymentType.Local);

  const wirelessLockProduct = products.find((product: Product) => product.isAddOn || product.name === 'AC Lockset');
  const wirelessLockPrice = wirelessLockProduct?.unitAmount ? wirelessLockProduct.unitAmount / 100 : 240;
  const wirelessLockTotalPrice = formatCurrency(
    wirelessLockProduct?.currency ?? 'usd',
    wirelessLocksCount * wirelessLockPrice
  );

  useEffect(() => {
    if (isFetchStripeProductsError) {
      setWirelessLocksCount(initialWirelessLocksCount);
    }
  }, [isFetchStripeProductsError]);

  const orderSummaryHeaders = [
    { label: itemStr, xs: 3 },
    { label: qtyStr, xs: 3 },
    { label: unitPriceStr, xs: 3 },
    { label: totalStr, xs: 3 }
  ];

  const orderSummaryData = [
    { value: wirelessLockStr, xs: 3 },
    { value: wirelessLocksCount, xs: 3 },
    { value: formatCurrency(wirelessLockProduct?.currency ?? 'usd', wirelessLockPrice), xs: 3 },
    { value: formatCurrency(wirelessLockProduct?.currency ?? 'usd', wirelessLocksCount * wirelessLockPrice), xs: 3 }
  ];

  if (isLoadingProducts || isPendingCreateStripeSession) {
    return <LoadingSpinner />;
  }
  const handleContinue = async () => {
    try {
      const params: CreateStripeCheckoutSessionRequest = {
        priceId: wirelessLockProduct.priceId,
        stripeCustomerId: currentUser?.stripeCustomerId ?? null,
        customerEmail: currentUser?.email ?? '',
        licenseId: deployment.licenseId,
        addressForTax: {
          line1: line1 ?? '',
          city: city ?? '',
          state: state,
          //If branch creation supports more countries in the future, this will have to be updated
          countryNumericCode: countryNumericCode,
          country: country ?? 'US',
          postalCode: postalCode ?? ''
        },
        taxExempt: false, // TODO: Fetch from DB
        cloudUserPublicId: currentUser?.publicId,
        reactAppStage: 'localhost', // TODO: Will change back to dev
        deploymentType: DeploymentType.Local,
        licenseName: wirelessLockProduct.name,
        duration: wirelessLockProduct.duration,
        hartmannLicenseId: hartmannLicenseId,
        hartmannDeploymentId: deployment.hartmannDeploymentId,
        hartmannBranchId: deployment.hartmannBranchId,
        hartmannAccountNumber: accountNumber,
        count: wirelessLocksCount,
        isAddOn: true
      };

      createStripeCheckoutSession(
        {
          aiphoneBranchPublicId: deployment.aiphoneBranchPublicId ?? '',
          deploymentId: deployment.deploymentId ?? '',
          deploymentType: DeploymentType.Local,
          data: params
        },
        {
          onSuccess: (response: any) => {
            if (response?.data?.message) {
              window.location.href = response.data.message;
            } else {
              setErrorMessage(checkoutSessionError);
            }
          },
          onError: (error: any) => {
            setErrorMessage(error.message || checkoutSessionError);
          }
        }
      );
    } catch (err) {
      setErrorMessage(checkoutSessionError);
    }
  };

  if (isFetchStripeProductsError) {
    return (
      <Box>
        <ErrorAlert errorMessage={error.message} customMessage={productError} onRetry={refetchStripeProducts} />
      </Box>
    );
  }

  return (
    <DialogWrapper
      setIsOpen={setIsOpen}
      title={addOnDialogTitle}
      handleDialogClose={handleClosePurchaseAddOnDialog}
      open={openLicenseAddOnDialog}
      fullWidth
      maxWidth="lg"
    >
      <Typography variant="h6" sx={styles.contentHeader}>
        {packageConentStr}
      </Typography>
      <Typography variant="body1" sx={styles.contentHeader}>
        {addOnDialogSubtitle}
      </Typography>
      <DialogContent sx={styles.content}>
        <Grid container sx={styles.contentContainer}>
          <Grid item xs={4} container justifyContent="flex-start">
            <TextField
              label={wirelessLocksCountStr}
              type="number"
              value={wirelessLocksCount}
              onChange={handleWirelessLocksCountChange}
              inputProps={{ min: 1 }}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Box sx={styles.arrowContainer}>
                      <IconButton onClick={incrementWirelessLocksCount} size="small">
                        <KeyboardArrowUpIcon fontSize="small" />
                      </IconButton>
                      <IconButton onClick={decrementWirelessLocksCount} size="small">
                        <KeyboardArrowDownIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </InputAdornment>
                ),
                inputMode: 'numeric'
              }}
              sx={styles.textInput}
            />
          </Grid>
          <Grid item xs={8} sx={styles.packageGridItem}>
            <Box sx={styles.orderSummaryTitle}>
              <Typography variant="h6">{orderSummaryStr}</Typography>
              <Typography variant="subtitle1">{orderSummaryHeaderStr}</Typography>
            </Box>
            <Grid container sx={styles.orderSummaryHeader}>
              {orderSummaryHeaders.map((header) => (
                <Grid item xs={header.xs} key={header.label}>
                  <Typography variant="h6">{header.label}</Typography>
                </Grid>
              ))}
            </Grid>
            <Grid container sx={styles.orderSummaryRow}>
              {orderSummaryData.map((data, index) => (
                <Grid item xs={data.xs} key={`${data.value}-${index}`}>
                  <Typography variant="body2">{data.value}</Typography>
                </Grid>
              ))}
            </Grid>
            <Divider />
            <Grid container sx={styles.grandTotalContainer}>
              <Grid item xs={9}>
                <Typography variant="h6">{grandTotalStr}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h6">{wirelessLockTotalPrice}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box style={LicenseManagementCommonStyles.buttonContainer}>
          <Button onClick={handleClosePurchaseAddOnDialog} variant="text" sx={LicenseManagementCommonStyles.buttonBack}>
            {backButtonStr}
          </Button>

          <Button onClick={handleContinue} variant="contained" sx={LicenseManagementCommonStyles.button}>
            <OpenInNewIcon sx={styles.openNewIcon} />
            {addOnPurchaseContinue}
          </Button>
        </Box>
      </DialogActions>
      {isPendingCreateStripeSession && <LoadingSpinner />}
      {errorStatus && <ErrorAlert errorMessage={errorMessage} customMessage={checkoutSessionError} />}
    </DialogWrapper>
  );
};

const styles = {
  openNewIcon: {
    mr: 1
  },
  cardContentTitle: {
    mb: 1
  },
  content: {
    pl: 0
  },

  contentHeader: {
    mb: 2
  },
  contentContainer: {
    display: 'flex'
  },
  countGridItem: {
    display: 'flex',
    pr: 2
  },
  textInput: {
    justifyContent: 'flex-start',
    width: '90%'
  },
  packageGridItem: {
    border: '1px solid #e0e0e0'
  },
  orderSummaryTitle: {
    fontWeight: 'bold',
    mt: 2,
    mb: 6,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  orderSummaryHeader: {
    p: 2,
    backgroundColor: theme.palette.grey[200],
    display: 'flex',
    justifyContent: 'space-between',
    mt: 1,
    fontWeight: 'bold'
  },
  orderSummaryRow: {
    p: 2,
    display: 'flex',
    justifyContent: 'space-between',
    mt: 1
  },
  arrowContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  grandTotalContainer: {
    p: 2,
    display: 'flex',
    justifyContent: 'space-between',
    mt: 1,
    mb: 4
  }
};

export default LicenseAddOnDialog;
