import { Card, CardContent, Grid, List, Typography, ListItem } from '@mui/material';
import PersonPinCircleOutlinedIcon from '@mui/icons-material/PersonPinCircleOutlined';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/utils/StringUtils';

interface ISiteRoleDescriptionsProps {
  roleName: string;
}

interface IDescription {
  [key: string]: {
    name: string;
    permissions: string[];
  };
}

const SiteRoleDescriptions = ({ roleName }: ISiteRoleDescriptionsProps) => {
  // Function to format the role name
  const formatRoleName = (name: string) => {
    return name.replace(/([A-Z])/g, ' $1').trim();
  };

  const { t } = useTranslation();
  const grantedToStr = t('Role.Permission.GrantedTo');
  const adminStr = t('Role.Admin.Title');
  const managerStr = t('Role.Manager.Title');
  const memberStr = t('Role.Member.Title');
  const aiphoneAdminStr = t('Role.AiphoneAdmin.Title');
  const aiphoneManagerStr = t('Role.AiphoneManager.Title');
  const aiphoneMemberStr = t('Role.AiphoneMember.Title');
  const manageSiteStr = t('Role.Permission.Site.Manage');
  const manageSiteSettingsStr = t('Role.Permission.Site.ManageSettings');
  const manageDevicesAppsStr = t('Role.Permission.Site.ManageStationsApps');
  const manageUnitsTenants = t('Role.Permission.Site.ManageUnitsTenants');
  const viewSiteStr = t('Role.Permission.Site.View');
  const viewSiteSettingsStr = t('Role.Permission.Site.ViewSettings');
  const viewDevicesAppsStr = t('Role.Permission.Site.ViewStationsApps');
  const viewUnitsTenants = t('Role.Permission.Site.ViewUnitsTenants');
  const inviteUsersAdminStr = t('Role.Permission.Site.InviteUsersAdmin');
  const inviteUsersStr = t('Role.Permission.Site.InviteUsers');
  const deleteSiteStr = t('Role.Permission.Site.Delete');
  const grantedToRoleNameStr = StringUtils.format(grantedToStr, formatRoleName(roleName));

  const description: IDescription = {
    Admin: {
      name: adminStr,
      permissions: [manageSiteStr, manageDevicesAppsStr, manageUnitsTenants]
    },
    Manager: {
      name: managerStr,
      permissions: [manageSiteStr, manageDevicesAppsStr, manageUnitsTenants]
    },
    Member: {
      name: memberStr,
      permissions: [viewSiteStr, viewDevicesAppsStr, viewUnitsTenants]
    },
    AiphoneAdmin: {
      name: aiphoneAdminStr,
      permissions: [inviteUsersAdminStr, manageSiteSettingsStr, deleteSiteStr]
    },
    AiphoneManager: {
      name: aiphoneManagerStr,
      permissions: [inviteUsersStr, manageSiteSettingsStr]
    },
    AiphoneMember: {
      name: aiphoneMemberStr,
      permissions: [viewSiteSettingsStr]
    }
  };

  return (
    <Grid item xs={12}>
      {roleName && description[roleName] && (
        <Card sx={styles.permissionContainer}>
          <Typography variant="h6" color="initial">
            {grantedToRoleNameStr}:
          </Typography>
          <CardContent>
            <List>
              {description[roleName].permissions.map((desc, index) => (
                <ListItem key={index}>
                  <PersonPinCircleOutlinedIcon />
                  <Typography variant="body2" color="initial" sx={{ paddingX: 2 }}>
                    {desc}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      )}
    </Grid>
  );
};

const styles = {
  permissionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 1,
    paddingX: 1
  }
};

export default SiteRoleDescriptions;
