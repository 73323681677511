import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';

// #region import LicenseManagement
// Components
// Hooks
import { useFetchEula, useSaveEula } from 'features/LicenseManagement/hooks/useFetchEula';
import { useCreateStripeCheckoutSession } from 'features/LicenseManagement/hooks/useCreateStripeCheckoutSession';
import useReduxUsers from 'features/Dashboard/Hooks/useReduxUsers';
import { handleBack } from 'features/LicenseManagement/components/common/navigationUtils';
import LoadingSpinner from 'features/LicenseManagement/components/common/LoadingSpinner';
import ErrorAlert from 'features/LicenseManagement/components/common/ErrorAlert';
// Styles
import { LicenseManagementCommonStyles } from 'features/LicenseManagement/styles/CommonStyles';
// Types
import {
  CreateStripeCheckoutSessionRequest,
  DeploymentDetailResponse,
  DeploymentType,
  SaveEulaRequest,
  SelectedStripeProduct
} from 'features/LicenseManagement/components/common/Types';
import { useCurrentBranchAddress } from 'features/LicenseManagement/hooks/useCurrentBranchAddress';
import ConfirmationDialog from 'features/LicenseManagement/components/common/ConfirmationDialog';
// #endregion

interface LicenseAgreementStepProps {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  deployment: DeploymentDetailResponse;
  numDoors: number;
  numExpanders: number;
  numCabs: number;
  selectedStripeProducts: SelectedStripeProduct[] | null;
}

const LicenseAgreementStep: React.FC<LicenseAgreementStepProps> = ({
  activeStep,
  setActiveStep,
  deployment,
  numCabs,
  numDoors,
  numExpanders,
  selectedStripeProducts
}) => {
  const { t } = useTranslation('acNioLicenseManagement');
  const [isChecked, setIsChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState(false);

  const { currentUser } = useReduxUsers();
  const { line1, city, state, country, countryNumericCode, postalCode } = useCurrentBranchAddress();
  const [errorStatus, setErrorStatus] = useState(false);
  const { data: eulaData, isLoading, isError, error } = useFetchEula();

  const { mutateAsync: saveEula, isPending: isUpdatingEula } = useSaveEula(
    () => {
      setErrorStatus(false);
      setErrorMessage('');
    },
    (error: any) => {
      setErrorStatus(true);
      setErrorMessage(error.message);
    }
  );

  const { mutate: createStripeCheckoutSession, isPending: isPendingCreateStripeSession } =
    useCreateStripeCheckoutSession(
      () => {
        setErrorStatus(false);
        setErrorMessage('');
      },
      (error: any) => {
        setErrorStatus(true);
        setErrorMessage(error.message);
      }
    );

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const handleContinue = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmPayment = async () => {
    setOpenDialog(false);

    const saveEulaRequest: SaveEulaRequest = {
      version: eulaData?.version as number,
      createdByUserPublicId: eulaData?.createdByUserPublicId ?? '',
      signedOn: new Date().toISOString()
    };

    try {
      // Step 0: Update EULA status and save the selected EULA version
      await saveEula({
        aiphoneBranchPublicId: deployment.aiphoneBranchPublicId ?? '',
        deploymentId: deployment.deploymentId ?? '',
        data: saveEulaRequest
      });

      const params: CreateStripeCheckoutSessionRequest = {
        selectedProducts: selectedStripeProducts ?? [],
        stripeCustomerId: currentUser?.stripeCustomerId ?? '',
        customerEmail: currentUser?.email ?? '',
        addressForTax: { line1, city, state, country, countryNumericCode, postalCode },
        taxExempt: false, // TODO: Fetch from DB
        cloudUserPublicId: currentUser?.publicId,
        reactAppStage: 'localhost', //TODO: Will change back to dev
        deploymentType: DeploymentType.Cloud,
        numOfDoors: numDoors,
        numOfExpanders: numExpanders,
        numOfElevators: numCabs,
        hartmannBranchId: deployment.hartmannBranchId ?? '',
        hartmannDeploymentId: deployment.hartmannDeploymentId ?? ''
      };

      // Step 3: Create Stripe Checkout Session
      await createStripeCheckoutSession(
        {
          aiphoneBranchPublicId: deployment.aiphoneBranchPublicId ?? '',
          deploymentId: deployment.deploymentId ?? '',
          deploymentType: DeploymentType.Cloud,
          data: params
        },
        {
          onSuccess: (response: any) => {
            if (response?.data?.message) {
              window.location.href = response.data.message;
            } else {
              setErrorMessage(t('Error_Creating_Checkout_Session'));
              setErrorStatus(true);
            }
          },
          onError: (error: any) => {
            setErrorMessage(error.message || t('Error_Creating_Checkout_Session'));
            setErrorStatus(true);
          }
        }
      );
    } catch (err) {
      setErrorMessage(t('Error_Creating_Checkout_Session'));
      setErrorStatus(true);
    }
  };

  if (isError) {
    return <ErrorAlert errorMessage={error?.message} customMessage={t('Error_Fetching_Eula')} />;
  }

  return (
    <Box sx={styles.container}>
      <Typography variant="h5">{t('License_Agreement_Title')}</Typography>
      {errorStatus && errorMessage && (
        <ErrorAlert errorMessage={errorMessage} customMessage={t('Error_Creating_Checkout_Session')} />
      )}
      <Box sx={styles.licenseText}>
        <Typography variant="body1">{eulaData?.eulaText}</Typography>
      </Box>
      <Box sx={styles.checkboxContainer}>
        <FormControlLabel
          control={<Checkbox checked={isChecked} color="primary" size="medium" onChange={handleCheckboxChange} />}
          labelPlacement="end"
          label={t('Checkbox_Text')}
        />
      </Box>

      <Box sx={LicenseManagementCommonStyles.buttonContainer}>
        <Button
          variant="text"
          sx={LicenseManagementCommonStyles.buttonBack}
          onClick={() => handleBack(activeStep, setActiveStep)}
        >
          {t('Back')}
        </Button>
        <Button
          variant="contained"
          sx={LicenseManagementCommonStyles.button}
          onClick={handleContinue}
          disabled={!isChecked}
        >
          {t('Continue_To_Payment')}
        </Button>
      </Box>
      {(isUpdatingEula || isPendingCreateStripeSession || isLoading) && <LoadingSpinner />}
      <ConfirmationDialog
        open={openDialog}
        title={t('Stripe_Dialog_Title')}
        content={t('Stripe_Dialog_Content')}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmPayment}
        confirmLabel={t('Continue_To_Stripe')}
        cancelLabel={t('Cancel')}
      />
    </Box>
  );
};

const styles = {
  container: {
    marginTop: 4
  },
  licenseText: {
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
    marginTop: 2
  },
  checkboxContainer: {
    border: '1px solid lightgrey',
    borderRadius: ' 0.25rem',
    marginTop: 2,
    padding: '0 .50rem'
  },
  errorMessage: {
    mb: 2
  }
};

export default LicenseAgreementStep;
