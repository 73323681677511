import React, { useState } from 'react';
import { Alert, Box, Button, Grid, TextField } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Login from 'shared/api/Acl/Login';
import { areInputsEmpty } from '../../../../shared/utils/helperFunctions';
import CircularProgress from '@mui/material/CircularProgress';
import StringUtils from '../../../../shared/utils/StringUtils';
import { useTranslation } from 'react-i18next';
import useSharedStyles from 'styles/useSharedStyles';

interface ActivationPanelProps {
  // This should hopefully be the easiest way to get past linting errors
  onClose: (() => void) | ((event: React.SyntheticEvent, reason: string | void) => void);
}

const ActivationPanel = ({ onClose }: ActivationPanelProps) => {
  const sharedStyle = useSharedStyles();
  const { t } = useTranslation();
  const fieldErrorMaxLen = t('Field.Error.MaxLen');
  const adminIdStr = t('IXGLogin.AdministratorId');
  const verificationCodeStr = t('Shared.VerificationCode');
  const activationSuccessStr = t('IXGLogin.ActivationPanel.Success');
  const activateStr = t('IXGLogin.ActivationPanel.Activate');
  const adminIdMaxLen = 22;
  const adminIdRequiredStr = t('Field.Error.Required', { field: adminIdStr });
  const adminIdMaxLenStr = StringUtils.format(fieldErrorMaxLen, adminIdStr, adminIdMaxLen);
  const verificationCodeRequiredStr = t('Field.Error.Required', { field: verificationCodeStr });

  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  const validationSchema = Yup.object({
    id: Yup.string().required(adminIdRequiredStr).max(adminIdMaxLen, adminIdMaxLenStr),
    code: Yup.string().required(verificationCodeRequiredStr)
    //   To handle correct verification code, we must check on the backend
  });

  const [result, setResult] = useState('');
  const handleVerification = async (values: { id: string; code: string }) => {
    try {
      const result = await Login.confirm(values.id, values.code);
      setResult(result);
      // Convert result to all caps
      const upperCaseResult = result.toUpperCase();
      // If the result is 'SUCCESS', close the dialog after 3 seconds
      if (upperCaseResult === 'SUCCESS') {
        timeoutRef.current = setTimeout(() => {
          const blankEvent = new Event('synthetic') as unknown as React.SyntheticEvent;
          onClose(blankEvent, 'successful-close');
        }, 3000);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setResult(error.message);
      } else {
        setResult(String(error));
      }
    }
  };

  React.useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <>
      <Formik
        initialValues={{ id: '', code: '' }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          await handleVerification(values);
        }}
      >
        {({ handleChange, handleBlur, values, errors, isValid, isSubmitting, touched }) => (
          <Form>
            <Grid container direction={'column'}>
              <Grid item>
                <Field
                  as={TextField}
                  sx={sharedStyle.validatedFieldContainer}
                  fullWidth
                  value={values.id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label={adminIdStr}
                  id="id"
                  error={touched.id && Boolean(errors.id)}
                  helperText={touched.id && errors.id}
                  required
                />
              </Grid>
              <Grid item>
                <Field
                  as={TextField}
                  sx={sharedStyle.validatedFieldContainer}
                  fullWidth
                  value={values.code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label={verificationCodeStr}
                  id="code"
                  error={touched.code && Boolean(errors.code)}
                  helperText={touched.code && errors.code}
                  required
                />
              </Grid>
            </Grid>
            <Box sx={sharedStyle.dialogContainer.fullWidthActionsContainer}>
              <Button
                variant="contained"
                type="submit"
                disabled={!isValid || isSubmitting || result.toLowerCase() === 'success' || areInputsEmpty(values)}
              >
                {isSubmitting ? <CircularProgress size="25px" /> : activateStr}
              </Button>
              {result.length > 0 && (
                <Alert severity={result.toLowerCase() === 'success' ? 'success' : 'error'}>
                  {result.toLowerCase() === 'success' ? activationSuccessStr : result}
                </Alert>
              )}
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ActivationPanel;
