import { Box, Typography, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateDeploymentByDeploymentId } from '../../../hooks/useDeployments';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import FieldDisplay from '../../common/Fielddisplay';
import { LicenseManagementCommonStyles } from 'features/LicenseManagement/styles/CommonStyles';
import { EMAIL_REGEX } from 'shared/constants/regex';
import DeploymentNotificationsField from '../DeploymentList/CreateDeploymentModal/NotificationField';
import theme from 'styles/theme';

interface EditableSectionProps {
  formData: any;
  title: string;
  fields: any;
  setSuccessMessage: (message: string) => void;
  setErrorMessage: (message: string) => void;
  canUserEdit: boolean;
  handleEmailRecipientsChange?: (newRecipients: string[]) => void;
  errors: any;
  setErrors: any;
  refetchDeployments: () => void;
}

const EditableSection = ({
  formData,
  title,
  fields,
  setSuccessMessage,
  setErrorMessage,
  canUserEdit,
  handleEmailRecipientsChange,
  errors,
  setErrors,
  refetchDeployments
}: EditableSectionProps) => {
  const { t } = useTranslation('acNioLicenseManagement');
  const { t: tCommon } = useTranslation('common');
  const [isEditing, setIsEditing] = useState(false);
  const [originalFields, setOriginalFields] = useState(fields);
  const [currentFields, setCurrentFields] = useState(fields);
  const [errorStatus, setErrorStatus] = useState(false);

  const deploymentNotificationTitleStr = t('Deployment_Notifications_Title');
  const invalidEmailStr = tCommon('Email_Invalid');
  const minEmailStr = t('Deployment_Notifications_Min_Email_Required');

  useEffect(() => {
    setCurrentFields(errorStatus ? originalFields : fields);
  }, [fields, errorStatus]);

  const handleEditClick = () => {
    setErrorStatus(false);
    setIsEditing(true);
  };

  const updateDeploymentByDeploymentIdMutation = useUpdateDeploymentByDeploymentId(formData.deploymentId, formData, {
    onSuccess: () => {
      setErrorStatus(false);
      setOriginalFields(fields);
      setSuccessMessage(t('Deployment_Update_Success'));
    },
    onError: () => {
      setErrorStatus(true);
      setErrorMessage(t('Deployment_Update_Failed'));
    }
  });

  const handleSaveClick = async () => {
    const updatedFormData = { ...formData };

    // Check if country is 'United States of America' and update it to 'United States' as we get error while updating country with name as 'United States of America'
    if (updatedFormData.address.countryName === 'United States of America') {
      updatedFormData.address.countryName = 'United States';
    }

    if (title === deploymentNotificationTitleStr) {
      const emailArray = updatedFormData.emailRecipients;
      if (!validateEmails(emailArray)) {
        return;
      }
      updatedFormData.emailRecipients = emailArray;
    }

    setIsEditing(false);

    try {
      await updateDeploymentByDeploymentIdMutation.mutateAsync(updatedFormData);
      refetchDeployments();
    } catch (error) {
      if (updatedFormData.address.countryName === 'United States') {
        updatedFormData.address.countryName = 'United States of America';
      }
      setErrorStatus(true);
    }
  };

  const handleDiscardClick = () => {
    setCurrentFields(originalFields);
    setIsEditing(false);
  };

  const validateEmails = (emails: string[]) => {
    if (formData.deployment.type === 'Cloud') {
      if (emails.length === 0) {
        setErrorMessage(minEmailStr);
        setErrors({ ...errors, emailRecipients: true });
        return false;
      }
    }

    const emailRegex = EMAIL_REGEX;
    const validEmails = emails.every((email) => emailRegex.test(email));
    if (!validEmails) {
      setErrorMessage(invalidEmailStr);
      setErrors({ ...errors, emailRecipients: true });
      return false;
    }

    return true;
  };
  const isFormValid = () => {
    const areAllErrorsFalse = Object.values(errors).every((section) =>
      Object.values(section).every((error) => error === false)
    );
    return areAllErrorsFalse;
  };

  return (
    <Box sx={styles.sectionContainerStyle}>
      <Box sx={styles.titleContainer}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        {canUserEdit && isEditing ? (
          <Box sx={styles.editBox}>
            <Button onClick={handleDiscardClick} sx={styles.discardButtonStyle}>
              <ClearIcon sx={styles.clearIconStyle}></ClearIcon>
              {t('Discard')}
            </Button>

            <Button disabled={!isFormValid()} onClick={handleSaveClick} sx={styles.saveButtonStyle}>
              <CheckIcon
                sx={{
                  ...styles.checkIconStyle,
                  color: isFormValid() ? theme.palette.primary.main : theme.palette.grey
                }}
              ></CheckIcon>
              {t('Save')}
            </Button>
          </Box>
        ) : canUserEdit ? (
          <Button
            startIcon={<EditIcon />}
            onClick={handleEditClick}
            sx={LicenseManagementCommonStyles.editButton}
            aria-label="edit"
          >
            {t('Edit')}
          </Button>
        ) : null}
      </Box>

      <Box sx={styles.sectionStyle}>
        {title === deploymentNotificationTitleStr && isEditing ? (
          <Box>
            <DeploymentNotificationsField
              value={formData.emailRecipients}
              onChange={handleEmailRecipientsChange}
              error={errors.emailRecipients}
              setErrors={setErrors}
              helperText={errors.emailRecipients ? t('Required_Field_Email') : ''}
              label={t('Notifications_To')}
              name="deployment.emailRecipients"
            />
            <Typography variant="body1" style={styles.fieldSubtitle}>
              {currentFields[0].label}
            </Typography>
          </Box>
        ) : (
          currentFields.map((field: any, index: number) => (
            <FieldDisplay key={index} {...field} isEditing={isEditing} errors={errors} />
          ))
        )}
      </Box>
    </Box>
  );
};

const styles = {
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 2,
    flexDirection: 'row'
  },
  sectionStyle: {
    p: 1,
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    position: 'relative',
    mt: 2
  },
  sectionContainerStyle: {
    pb: 2,
    position: 'relative'
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 2
  },
  fieldLabel: {
    marginBottom: 1
  },
  fieldSubtitle: {
    color: theme.palette.grey
  },
  fieldValue: {
    marginBottom: 1
  },
  saveButtonStyle: {
    color: '#0071CE',
    textTransform: 'uppercase'
  },
  discardButtonStyle: {
    color: 'grey',
    textTransform: 'uppercase'
  },
  editBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 1,
    alignItems: 'center'
  },
  checkIconStyle: {
    color: theme.palette.primary.main,
    marginRight: 1
  },
  clearIconStyle: {
    color: 'grey',
    marginRight: 1
  }
};

export default EditableSection;
