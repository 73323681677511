import { Box, ListItem, ListItemButton, ListItemIcon, Typography } from '@mui/material';
import { IContactOutput, IDeviceContactInput } from 'store/slices/devicesSlice';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { getString } from 'shared/utils/LocalizationUtils';

interface IDeviceRelayItemProps {
  relay: IContactOutput | IDeviceContactInput;
  isSelected: boolean;
  handleSelectRelay: (id: string) => void;
}

const getRelayDetails = (relay: IContactOutput | IDeviceContactInput) => {
  if ('inputNumber' in relay) {
    return { name: relay.inputName, number: relay.inputNumber };
  }
  return { name: relay.outputName, number: relay.outputNumber };
};

const DeviceRelayItem = ({ relay, handleSelectRelay, isSelected }: IDeviceRelayItemProps) => {
  const relayName = getString('Relay_Name');
  const relayNumner = getString('Relay_Number');
  const { name, number } = getRelayDetails(relay);

  const handleSelect = () => handleSelectRelay(relay.publicId);

  return (
    <ListItem key={relay.publicId} disablePadding>
      <ListItemButton onClick={handleSelect} selected={isSelected}>
        <Box sx={styles.iconWrapper}>
          <ListItemIcon>{'inputNumber' in relay ? <ArrowCircleDownIcon /> : <ArrowCircleUpIcon />}</ListItemIcon>
        </Box>
        <Box>
          <Typography variant="body2">
            <Typography variant="h7" sx={styles.itemTitle}>
              {relayName}:
            </Typography>
            {name || '-'}
          </Typography>
          <Typography variant="body2">
            <Typography variant="h7" sx={styles.itemTitle}>
              {relayNumner}:
            </Typography>
            {number || '-'}
          </Typography>
        </Box>
      </ListItemButton>
    </ListItem>
  );
};

const styles = {
  iconWrapper: {
    width: '15%',
    cursor: 'pointer'
  },
  deviceDetailsItem: {},
  itemTitle: {
    marginRight: '5px',
    fontWeight: 'bold'
  }
};

export default DeviceRelayItem;
