import { Alert, AlertTitle, Box, List, ListItem, Typography } from '@mui/material';
import GeneralInfo from '../../UiParts/GeneralInfo';
import { accessories, doorStations, subStations, masterStations, lefHidden } from '../../../Types/LEFTypes';
import QuikspecCategoryTabs from '../../UiParts/QuikspecCategoryTabs';
import { updateFormValuesLEF } from 'features/QuikSpec/Utils/QuikspecUpdateFormValuesLEF';
import { Form, Formik } from 'formik';
import QuikspecSubmitButton from '../../UiParts/QuikspecSubmitButton';
import { useNavigate, useLocation } from 'react-router-dom';
import QuikspecNote from 'features/QuikSpec/Components/UiParts/QuikspecNote';
import { FormValues } from '../../../Types/QuikspecFormTypes';
import getInitialFormValues from 'features/QuikSpec/Utils/QuikspecInitialFormValues';
import { createQuikSpecAndNavigate, updateQuikspecAndNavigate } from '../../../Utils/QuikspecActionAndNavigate';
import useQuikSpecFormValidationSchema from 'features/QuikSpec/Hooks/useQuikSpecFormValidationSchema';
import { useTranslation } from 'react-i18next';
import { useQuikSpecStandardSystems } from 'features/QuikSpec/Hooks/quikSpecDevices';
import useC2 from 'features/QuikSpec/Hooks/useC2';

const QuikspecLEF = () => {
  const navigate = useNavigate();
  const StandardSystems = useQuikSpecStandardSystems();
  const { C2ApiFailed } = useC2();
  const location = useLocation();
  const { t } = useTranslation();
  const state = location.state || {};
  const siteName: string = location.state.siteName as string;
  const siteIndex = StandardSystems.findIndex((system) => system.siteName === siteName);
  const systemName = StandardSystems[siteIndex].seriesName;
  const systemforC2 = StandardSystems[siteIndex].systemforC2;

  const categories = [...masterStations, ...subStations, ...doorStations, ...accessories, ...lefHidden];
  const stateFormValues = state && Object.keys(state).length > 1 ? state.formValues : undefined;
  const initialValues = getInitialFormValues(systemName, categories, stateFormValues, systemforC2);
  const alertError = t('Shared.Error');

  const { lef } = useQuikSpecFormValidationSchema();

  /**
   * Adjusts the quantity of RY-PA items based on the number of door stations in the form.
   * If the quantity of RY-PA is less than the number of door stations, increases the quantity of RY-PA.
   * If the quantity of RY-PA is greater than the maximum allowed door stations for the highest priority master station,
   * it reduces the quantity of RY-PA to the maximum allowed.
   * @param {FormValues} allItems - The object containing all items and their quantities in the form.
   * @returns {FormValues} The updated object with the adjusted quantity of RY-PA items.
   */
  const adjustRyPaCount = (allItems: FormValues): FormValues => {
    let doorStationsQuantity = 0;
    let ryPaQuantity = 0;

    const maxDoorStationsPerTitle: Record<string, number> = {
      '3-Call Master Stations': 3, // Least preferred
      '5-Call Master Stations': 5,
      '10-Call Master Stations': 10 // Most preferred
    };

    // Get all master station names mapped to their titles
    const masterStationEntries = masterStations.flatMap((station) =>
      station.items.map((item) => ({
        deviceName: item.deviceName,
        title: station.title
      }))
    );

    const selectedMasterTitles: string[] = [];
    let maxAllowedDoorStations = 0;

    const devices = Object.values(allItems.items);

    // Loop through all selected items
    devices.forEach((item: any) => {
      // Check if the item is a master station and get its title
      const matchedMasterStations = masterStationEntries.filter((ms) => ms.deviceName === item.itemName);

      if (matchedMasterStations.length > 0) {
        matchedMasterStations.forEach((ms) => {
          if (item.quantity > 0) {
            selectedMasterTitles.push(ms.title);
          }
        });
      }
    });

    // Sort titles based on preference (10 > 5 > 3)
    selectedMasterTitles.sort((a, b) => {
      const priority = ['10-Call Master Stations', '5-Call Master Stations', '3-Call Master Stations'];
      return priority.indexOf(a) - priority.indexOf(b);
    });

    // Assign max allowed door stations based on highest priority master station
    if (selectedMasterTitles.length > 0) {
      maxAllowedDoorStations = maxDoorStationsPerTitle[selectedMasterTitles[0]];
    }

    // Calculate total quantity for Door Stations and RY-PA
    devices.forEach((device: any) => {
      if (device.category === 'Door Stations') {
        if (device.accessories && device.accessories.length > 0) {
          device.accessories.forEach((acc: any) => {
            doorStationsQuantity += acc.quantity;
          });
        } else {
          doorStationsQuantity += device.quantity;
        }
      }
      if (device.itemName === 'RY-PA') {
        ryPaQuantity += device.quantity;
      }
    });

    // If RY-PA quantity is less than Door Stations quantity, adjust it
    if (ryPaQuantity < doorStationsQuantity) {
      const difference = doorStationsQuantity - ryPaQuantity;
      const existingRyPa: any = devices.find((device: any) => device.itemName === 'RY-PA');
      const isAnyLeF = devices.some((device: any) => {
        return ['LEF-3', 'LEF-3C'].includes(device.itemName) && device.quantity > 0;
      });
      if (existingRyPa && !isAnyLeF) {
        // Increase the quantity of the existing RY-PA
        existingRyPa.quantity += difference;
      }
    } else {
      const existingRyPa: any = devices.find((device: any) => device.itemName === 'RY-PA');
      if (existingRyPa.quantity > maxAllowedDoorStations) {
        // Reduce the quantity of the existing RY-PA
        existingRyPa.quantity = maxAllowedDoorStations;
      }
    }
    const newItems = { ...allItems, items: devices };

    return newItems;
  };

  const handleSubmit = async (values: FormValues, actions: any) => {
    try {
      actions.setSubmitting(true);

      const updatedValues = adjustRyPaCount(values);
      //filter out items with quantity set to zero in order to submit quikspec
      values = updateFormValuesLEF(updatedValues);

      //determine if form is in edit mode
      const editing = stateFormValues != undefined;

      if (editing) {
        //update QuikSpec and navigate to QuikSpec Summary page
        await updateQuikspecAndNavigate(values, actions, navigate);
        actions.setSubmitting(false);
        return;
      } else {
        //create QuikSpec and navigate to QuikSpec Summary page
        await createQuikSpecAndNavigate(values, actions, navigate);
        actions.setSubmitting(false);
      }
    } catch (error) {
      console.error(JSON.stringify(error));
      actions.setSubmitting(false);
      actions.setErrors({ submit: error.message });
    }
  };

  return (
    <Box>
      {C2ApiFailed && (
        <Alert severity="warning">
          <AlertTitle>{t('Missing_C2_Data')}</AlertTitle>
          {t('C2_Data_Missing_Detail')}
        </Alert>
      )}
      <Formik initialValues={initialValues} validationSchema={lef} onSubmit={handleSubmit}>
        {({ errors, touched, isSubmitting }) => {
          return (
            <Form>
              <GeneralInfo editing={state.formValues != undefined} />
              {/* Render QuikspecCategoryTabs for each category */}
              <QuikspecCategoryTabs categories={masterStations} />
              <QuikspecCategoryTabs categories={subStations} />
              <QuikspecCategoryTabs categories={doorStations} />
              <QuikspecCategoryTabs categories={accessories} />
              {(stateFormValues
                ? Object.keys(errors).length > 0
                : Object.keys(touched).length > 0 && Object.keys(errors).length > 0) && (
                <Alert severity="error">
                  <AlertTitle>{alertError}</AlertTitle>
                  <List>
                    {Object.keys(errors).map((field) => (
                      <ListItem key={field}>
                        <Typography variant="body2" color="error">
                          {errors[field]}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Alert>
              )}
              <QuikspecSubmitButton
                editing={state.formValues != undefined}
                errors={errors}
                submitting={isSubmitting}
                C2ApiFailed={C2ApiFailed}
              />
              <QuikspecNote systemName={initialValues.systemName} />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default QuikspecLEF;
