import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const useTosValueValidationSchema = (fieldName: string): Yup.StringSchema => {
  const { t } = useTranslation(); // Initialize the translation hook
  const formattedFieldRequired = t('Field.Error.Required', { field: fieldName });
  const formattedFieldInvalid = t('Field.Error.Invalid', { field: fieldName });
  const tosRegexValidate = /^[0-9a-fA-F]{2}$/;

  return Yup.string().required(formattedFieldRequired).matches(tosRegexValidate, formattedFieldInvalid);
};

export default useTosValueValidationSchema;
