import { Box, Card, Checkbox, Select, TextField, Grid, MenuItem, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedDevice, updateSelectedDevice } from 'store/slices/devicesSlice';
import * as Yup from 'yup';
import { Form, Formik, Field } from 'formik';

import { useUpdateDeviceMutation } from 'services/aiphoneCloud';
import containerStyle from 'styles/advancedSettingContainerStyle';
import { useState } from 'react';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import { EnumList, IConditionalDefaultValue, fetchLocalEnumList } from 'shared/utils/EnumUtils';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import useVideoValidationSchema, {
  IVideoFields
} from 'shared/utils/ValidationSchema/AdvancedSettings/NetworkInfo/Video';
import StringUtils from 'shared/utils/StringUtils';

export const VideoLabel = () => {
  const { t } = useTranslation();
  const videoTooltipTitle = t('Video_Multicast_Camera');
  const videoTabTitle = t('AdvancedSettings_Tab_Video');

  return (
    <Tooltip title={videoTooltipTitle}>
      <span>{videoTabTitle}</span>
    </Tooltip>
  );
};

const Video = () => {
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [updateDevice, { isLoading: isUpdating }] = useUpdateDeviceMutation();
  const { t } = useTranslation();

  const videoEncoder1IPictureIntervalTitle = t('AdvancedSettings_VideoEncoder1IPictureInterval_Title');

  const videoEncoder1FramerateTitle = t('AdvancedSettings_VideoEncoder1Framerate_Title');

  const videoEncoder1BitrateTitle = t('AdvancedSettings_VideoEncoder1Bitrate_Title');

  const videoEncoder2MethodTitle = t('AdvancedSettings_VideoEncoder2Method_Title');

  const videoEncoder2ResolutionTitle = t('AdvancedSettings_VideoEncoder2Resolution_Title');

  const videoEncoder2FramerateTitle = t('AdvancedSettings_VideoEncoder2Framerate_Title');

  const videoEncoder2H264QualityTitle = t('AdvancedSettings_VideoEncoder2H264Quality_Title');

  const videoEncoder2IPictureIntervalTitle = t('AdvancedSettings_VideoEncoder2IPictureInterval_Title');

  const videoEncoder2BitrateTitle = t('AdvancedSettings_VideoEncoder2Bitrate_Title');

  const videoEncoder2JpegQualityTitle = t('AdvancedSettings_VideoEncoder2JpegQuality_Title');

  const videoEncoder2RtpStartPortTitle = t('AdvancedSettings_VideoEncoder2RtpStartPort_Title');

  const videoEncoder2RtpEndPortTitle = t('AdvancedSettings_VideoEncoder2RtpEndPort_Title');

  const masterStationVideoFramerateTitle = t('AdvancedSettings_MasterStationVideoFramerate_Title');

  const masterStationVideoIPictureIntervalTitle = t('AdvancedSettings_MasterStationVideoIPictureInterval_Title');

  const masterStationVideoBitrateTitle = t('AdvancedSettings_MasterStationVideoBitrate_Title');

  // Updating text for https://aiphone-corporation.atlassian.net/browse/RM-954, as per Ben's directions
  const videoEncoderTitle = t('Video.Encoder');
  const videoEncoder2Title = StringUtils.format(videoEncoderTitle, '2');
  const videoEncoder2Desc = t('Video.DualStreamDescription');

  const saveChangesStr = t('Shared.SaveChanges');
  const buttonReset = t('Button_Reset');

  const errorUpdateDevice = t('AdvancedSettings_Error_UpdateDevice');
  const unauthorizedUser = t('AdvancedSettings_Unauthorized_User');
  const successUpdateDevice = t('AdvancedSettings_Success_UpdateDevice');

  const selectedDevice = useSelector(getSelectedDevice);
  const [formikSchema, setFormikSchema] = useState<Yup.ObjectSchema<any> | null>(null);
  const enumList: EnumList = fetchLocalEnumList();
  const formDevice: IVideoFields = {
    networkSettings: {
      videoTosValue: selectedDevice.networkSettings?.videoTosValue.replace('0x', ''),
      videoEncoder1Framerate: selectedDevice.networkSettings?.videoEncoder1Framerate,
      videoEncoder1IPictureInterval: selectedDevice.networkSettings?.videoEncoder1IPictureInterval,
      videoEncoder1Bitrate: selectedDevice.networkSettings?.videoEncoder1Bitrate,
      videoDualStream: selectedDevice.networkSettings?.videoDualStream,
      videoEncoder2Method: selectedDevice.networkSettings?.videoEncoder2Method,
      videoEncoder2Resolution: selectedDevice.networkSettings?.videoEncoder2Resolution,
      videoEncoder2Framerate: selectedDevice.networkSettings?.videoEncoder2Framerate,
      videoEncoder2H264Quality: selectedDevice.networkSettings?.videoEncoder2H264Quality,
      videoEncoder2IPictureInterval: selectedDevice.networkSettings?.videoEncoder2IPictureInterval,
      videoEncoder2Bitrate: selectedDevice.networkSettings?.videoEncoder2Bitrate,
      videoEncoder2JpegQuality: selectedDevice.networkSettings?.videoEncoder2JpegQuality,
      videoEncoder2RtpStartPort: selectedDevice.networkSettings?.videoEncoder2RtpStartPort,
      videoEncoder2RtpEndPort: selectedDevice.networkSettings?.videoEncoder2RtpEndPort,
      masterStationVideoFramerate: selectedDevice.networkSettings?.masterStationVideoFramerate,
      masterStationVideoIPictureInterval: selectedDevice.networkSettings?.masterStationVideoIPictureInterval,
      masterStationVideoBitrate: selectedDevice.networkSettings?.masterStationVideoBitrate
    }
  };

  const getVideoValidationSchema = useVideoValidationSchema();

  const onSubmit = async (values: any, actions: any) => {
    const newVals = JSON.parse(JSON.stringify(values));
    newVals.networkSettings.videoTosValue = `0x${values.networkSettings.videoTosValue.toUpperCase()}`;
    const params = {
      device: {
        publicId: selectedDevice.publicId,
        networkSettings: {
          videoTosValue: newVals.networkSettings.videoTosValue,
          videoEncoder1Framerate: newVals.networkSettings.videoEncoder1Framerate,
          videoEncoder1IPictureInterval: newVals.networkSettings.videoEncoder1IPictureInterval,
          videoEncoder1Bitrate: newVals.networkSettings.videoEncoder1Bitrate,
          videoDualStream: newVals.networkSettings.videoDualStream,
          masterStationVideoFramerate: newVals.networkSettings.masterStationVideoFramerate,
          masterStationVideoIPictureInterval: newVals.networkSettings.masterStationVideoIPictureInterval,
          masterStationVideoBitrate: newVals.networkSettings.masterStationVideoBitrate
        }
      }
    };

    if (newVals.networkSettings.videoDualStream) {
      const dualStreamParams = {
        videoEncoder2Method: newVals.networkSettings.videoEncoder2Method,
        videoEncoder2Resolution: newVals.networkSettings.videoEncoder2Resolution,
        videoEncoder2Framerate: newVals.networkSettings.videoEncoder2Framerate,
        videoEncoder2H264Quality: newVals.networkSettings.videoEncoder2H264Quality,
        videoEncoder2IPictureInterval: newVals.networkSettings.videoEncoder2IPictureInterval,
        videoEncoder2Bitrate: newVals.networkSettings.videoEncoder2Bitrate,
        videoEncoder2JpegQuality: newVals.networkSettings.videoEncoder2JpegQuality,
        videoEncoder2RtpStartPort: newVals.networkSettings.videoEncoder2RtpStartPort,
        videoEncoder2RtpEndPort: newVals.networkSettings.videoEncoder2RtpEndPort
      };

      params.device.networkSettings = {
        ...params.device.networkSettings,
        ...dualStreamParams
      };
    }

    const newDevice = JSON.parse(JSON.stringify(selectedDevice));

    newDevice.networkSettings = {
      ...newDevice.networkSettings,
      ...newVals.networkSettings
    };

    updateDevice(params)
      .then((response) => {
        if ('error' in response) {
          throw response.error;
        }

        dispatch(updateSelectedDevice({ device: newDevice }));
        setShowAlert(true);
        actions.resetForm({
          values: values
        });
      })
      .catch((error: any) => {
        const err = JSON.parse(error.data);
        if (err.errorDetails.includes('Unauthorized user Id')) {
          setErrorMessage(unauthorizedUser);
        } else {
          setErrorMessage(errorUpdateDevice);
        }
      });
  };

  if (!formikSchema) {
    setFormikSchema(getVideoValidationSchema(formDevice, formDevice));
  }

  return (
    <>
      <Box sx={containerStyle.mainWrapper}>
        <SnackbarAlert
          type="error"
          time={10000}
          text={`${errorMessage}`}
          isOpen={!!errorMessage}
          onClose={() => setErrorMessage(null)}
        />
        <SnackbarAlert
          type="success"
          time={3000}
          text={successUpdateDevice}
          isOpen={showAlert}
          onClose={() => setShowAlert(false)}
        />
        <Formik initialValues={formDevice} onSubmit={onSubmit} validationSchema={formikSchema}>
          {({
            values,
            dirty,
            touched,
            resetForm,
            errors,
            isSubmitting,
            handleChange,
            setFieldValue,
            setFieldTouched
          }) => (
            <Form style={containerStyle.form}>
              {' '}
              <Box sx={containerStyle.controlPanelWrapper}>
                <LoadingButton
                  variant="outlined"
                  type="button"
                  disabled={!dirty || isSubmitting || isUpdating}
                  onClick={() => resetForm()}
                >
                  {buttonReset}
                </LoadingButton>
                <LoadingButton
                  variant="outlined"
                  loading={isSubmitting}
                  type="submit"
                  disabled={!dirty || isSubmitting || isUpdating}
                >
                  {saveChangesStr}
                </LoadingButton>
              </Box>
              <Card sx={containerStyle.settingsWrapper}>
                <Box sx={containerStyle.gridContainer}>
                  {formDevice.networkSettings.videoEncoder1Framerate !== undefined ? (
                    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                      <Grid item xs={5} lg={7}>
                        <Box sx={containerStyle.itemTitle}>{videoEncoder1FramerateTitle}</Box>
                      </Grid>
                      <Grid item xs={5} lg={3}>
                        <Box sx={containerStyle.fieldContainer}>
                          <Field
                            as={Select}
                            label={videoEncoder1FramerateTitle}
                            name="networkSettings.videoEncoder1Framerate"
                            style={containerStyle.selectField}
                            helperText={
                              touched.networkSettings?.videoEncoder1Framerate &&
                              errors.networkSettings?.videoEncoder1Framerate
                            }
                            error={
                              touched.networkSettings?.videoEncoder1Framerate &&
                              errors.networkSettings?.videoEncoder1Framerate
                            }
                          >
                            {Object.keys(enumList.videoEncoderFramerate).map((key) => {
                              return !(enumList.videoEncoderFramerate[key] as IConditionalDefaultValue)
                                .supportedDeviceTypeList ||
                                (
                                  enumList.videoEncoderFramerate[key] as IConditionalDefaultValue
                                ).supportedDeviceTypeList.includes(selectedDevice.basicInfo.deviceType) ? (
                                <MenuItem key={key} value={key}>
                                  {enumList.videoEncoderFramerate[key].value}
                                </MenuItem>
                              ) : null;
                            })}
                          </Field>
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                  {formDevice.networkSettings.videoEncoder1IPictureInterval !== undefined ? (
                    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                      <Grid item xs={5} lg={7}>
                        <Box sx={containerStyle.itemTitle}>{videoEncoder1IPictureIntervalTitle}</Box>
                      </Grid>
                      <Grid item xs={5} lg={3}>
                        <Box sx={containerStyle.fieldContainer}>
                          <Field
                            as={TextField}
                            type="number"
                            label={videoEncoder1IPictureIntervalTitle}
                            name="networkSettings.videoEncoder1IPictureInterval"
                            style={containerStyle.textField}
                            helperText={
                              touched.networkSettings?.videoEncoder1IPictureInterval &&
                              errors.networkSettings?.videoEncoder1IPictureInterval
                            }
                            error={
                              touched.networkSettings?.videoEncoder1IPictureInterval &&
                              errors.networkSettings?.videoEncoder1IPictureInterval
                            }
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                  {formDevice.networkSettings.videoEncoder1Bitrate !== undefined ? (
                    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                      <Grid item xs={5} lg={7}>
                        <Box sx={containerStyle.itemTitle}>{videoEncoder1BitrateTitle}</Box>
                      </Grid>
                      <Grid item xs={5} lg={3}>
                        <Box sx={containerStyle.fieldContainer}>
                          <Field
                            as={Select}
                            label={videoEncoder1BitrateTitle}
                            name="networkSettings.videoEncoder1Bitrate"
                            style={containerStyle.textField}
                            helperText={
                              touched.networkSettings?.videoEncoder1Bitrate &&
                              errors.networkSettings?.videoEncoder1Bitrate
                            }
                            error={
                              touched.networkSettings?.videoEncoder1Bitrate &&
                              errors.networkSettings?.videoEncoder1Bitrate
                            }
                          >
                            {Object.keys(enumList.videoBitrate).map((key) => {
                              return (
                                <MenuItem key={key} value={key}>
                                  {enumList.videoBitrate[key].value}
                                </MenuItem>
                              );
                            })}
                          </Field>
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                  {formDevice.networkSettings.videoDualStream !== undefined ? (
                    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                      <Grid item xs={5} lg={7}>
                        <Box sx={containerStyle.itemTitle}>{videoEncoder2Title}</Box>
                        <Box>{videoEncoder2Desc}</Box>
                      </Grid>
                      <Grid item xs={5} lg={3}>
                        {/* Remove the circular "out of bounds area" for the checkbox */}
                        <Box sx={containerStyle.checkboxFieldContainer}>
                          <Field
                            as={Checkbox}
                            label={videoEncoder2Title}
                            name="networkSettings.videoDualStream"
                            // Remove the circular "out of bounds area" for the checkbox
                            sx={{
                              padding: 0
                            }}
                            helperText={
                              touched.networkSettings?.videoDualStream && errors.networkSettings?.videoDualStream
                            }
                            error={touched.networkSettings?.videoDualStream && errors.networkSettings?.videoDualStream}
                            onChange={async (e: any) => {
                              const checked = e.target.checked;
                              // Explicitly set the checkbox value for Video Encoder 2
                              // This allows Formik to register that there is an update, so the "Save" button is enabled if there is a change to this checkbox.
                              // Otherwise we would have to update another field value.
                              await setFieldValue('networkSettings.videoDualStream', checked, true);
                              const newVals = { ...values };
                              newVals.networkSettings.videoDualStream = e.target.checked;
                              handleChange(e);
                              await setFieldTouched('networkSettings.videoDualStream', true, true);
                              setFormikSchema(getVideoValidationSchema(formDevice, newVals));
                            }}
                            checked={values.networkSettings.videoDualStream}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                  {values.networkSettings.videoDualStream ? (
                    <>
                      {formDevice.networkSettings.videoEncoder2Method !== undefined ? (
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-evenly"
                          style={containerStyle.itemContainer}
                        >
                          <Grid item xs={5} lg={7}>
                            <Box sx={containerStyle.itemTitle}>{videoEncoder2MethodTitle}</Box>
                          </Grid>
                          <Grid item xs={5} lg={3}>
                            <Box sx={containerStyle.fieldContainer}>
                              <Field
                                as={Select}
                                label={videoEncoder2MethodTitle}
                                name="networkSettings.videoEncoder2Method"
                                style={containerStyle.textField}
                                helperText={
                                  touched.networkSettings?.videoEncoder2Method &&
                                  errors.networkSettings?.videoEncoder2Method
                                }
                                error={
                                  touched.networkSettings?.videoEncoder2Method &&
                                  errors.networkSettings?.videoEncoder2Method
                                }
                              >
                                {Object.keys(enumList.videoEncoderMethod).map((key) => {
                                  return (
                                    <MenuItem key={key} value={key}>
                                      {enumList.videoEncoderMethod[key].value}
                                    </MenuItem>
                                  );
                                })}
                              </Field>
                            </Box>
                          </Grid>
                        </Grid>
                      ) : null}
                      {formDevice.networkSettings.videoEncoder2Resolution !== undefined ? (
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-evenly"
                          style={containerStyle.itemContainer}
                        >
                          <Grid item xs={5} lg={7}>
                            <Box sx={containerStyle.itemTitle}>{videoEncoder2ResolutionTitle}</Box>
                          </Grid>
                          <Grid item xs={5} lg={3}>
                            <Box sx={containerStyle.fieldContainer}>
                              <Field
                                as={Select}
                                label={videoEncoder2ResolutionTitle}
                                name="networkSettings.videoEncoder2Resolution"
                                style={containerStyle.textField}
                                helperText={
                                  touched.networkSettings?.videoEncoder2Resolution &&
                                  errors.networkSettings?.videoEncoder2Resolution
                                }
                                error={
                                  touched.networkSettings?.videoEncoder2Resolution &&
                                  errors.networkSettings?.videoEncoder2Resolution
                                }
                              >
                                {Object.keys(enumList.videoEncoderResolution).map((key) => {
                                  return (
                                    <MenuItem key={key} value={key}>
                                      {enumList.videoEncoderResolution[key].value}
                                    </MenuItem>
                                  );
                                })}
                              </Field>
                            </Box>
                          </Grid>
                        </Grid>
                      ) : null}
                      {formDevice.networkSettings.videoEncoder2Framerate !== undefined ? (
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-evenly"
                          style={containerStyle.itemContainer}
                        >
                          <Grid item xs={5} lg={7}>
                            <Box sx={containerStyle.itemTitle}>{videoEncoder2FramerateTitle}</Box>
                          </Grid>
                          <Grid item xs={5} lg={3}>
                            <Box sx={containerStyle.fieldContainer}>
                              <Field
                                as={Select}
                                label={videoEncoder2FramerateTitle}
                                name="networkSettings.videoEncoder2Framerate"
                                style={containerStyle.textField}
                                helperText={
                                  touched.networkSettings?.videoEncoder2Framerate &&
                                  errors.networkSettings?.videoEncoder2Framerate
                                }
                                error={
                                  touched.networkSettings?.videoEncoder2Framerate &&
                                  errors.networkSettings?.videoEncoder2Framerate
                                }
                              >
                                {Object.keys(enumList.videoEncoderFramerate).map((key) => {
                                  return !(enumList.videoEncoderFramerate[key] as IConditionalDefaultValue)
                                    .supportedDeviceTypeList ||
                                    (
                                      enumList.videoEncoderFramerate[key] as IConditionalDefaultValue
                                    ).supportedDeviceTypeList.includes(selectedDevice.basicInfo.deviceType) ? (
                                    <MenuItem key={key} value={key}>
                                      {enumList.videoEncoderFramerate[key].value}
                                    </MenuItem>
                                  ) : null;
                                })}
                              </Field>
                            </Box>
                          </Grid>
                        </Grid>
                      ) : null}
                      {formDevice.networkSettings.videoEncoder2H264Quality !== undefined ? (
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-evenly"
                          style={containerStyle.itemContainer}
                        >
                          <Grid item xs={5} lg={7}>
                            <Box sx={containerStyle.itemTitle}>{videoEncoder2H264QualityTitle}</Box>
                          </Grid>
                          <Grid item xs={5} lg={3}>
                            <Box sx={containerStyle.fieldContainer}>
                              <Field
                                as={Select}
                                label={videoEncoder2H264QualityTitle}
                                name="networkSettings.videoEncoder2H264Quality"
                                style={containerStyle.textField}
                                helperText={
                                  touched.networkSettings?.videoEncoder2H264Quality &&
                                  errors.networkSettings?.videoEncoder2H264Quality
                                }
                                error={
                                  touched.networkSettings?.videoEncoder2H264Quality &&
                                  errors.networkSettings?.videoEncoder2H264Quality
                                }
                              >
                                {Object.keys(enumList.videoEncoderQuality).map((key) => {
                                  return (
                                    <MenuItem key={key} value={key}>
                                      {enumList.videoEncoderQuality[key].value}
                                    </MenuItem>
                                  );
                                })}
                              </Field>
                            </Box>
                          </Grid>
                        </Grid>
                      ) : null}
                      {formDevice.networkSettings.videoEncoder2IPictureInterval !== undefined ? (
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-evenly"
                          style={containerStyle.itemContainer}
                        >
                          <Grid item xs={5} lg={7}>
                            <Box sx={containerStyle.itemTitle}>{videoEncoder2IPictureIntervalTitle}</Box>
                          </Grid>
                          <Grid item xs={5} lg={3}>
                            <Box sx={containerStyle.fieldContainer}>
                              <Field
                                as={TextField}
                                type="number"
                                label={videoEncoder2IPictureIntervalTitle}
                                name="networkSettings.videoEncoder2IPictureInterval"
                                style={containerStyle.textField}
                                helperText={
                                  touched.networkSettings?.videoEncoder2IPictureInterval &&
                                  errors.networkSettings?.videoEncoder2IPictureInterval
                                }
                                error={
                                  touched.networkSettings?.videoEncoder2IPictureInterval &&
                                  errors.networkSettings?.videoEncoder2IPictureInterval
                                }
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      ) : null}
                      {formDevice.networkSettings.videoEncoder2Bitrate !== undefined ? (
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-evenly"
                          style={containerStyle.itemContainer}
                        >
                          <Grid item xs={5} lg={7}>
                            <Box sx={containerStyle.itemTitle}>{videoEncoder2BitrateTitle}</Box>
                          </Grid>
                          <Grid item xs={5} lg={3}>
                            <Box sx={containerStyle.fieldContainer}>
                              <Field
                                as={Select}
                                label={videoEncoder2BitrateTitle}
                                name="networkSettings.videoEncoder2Bitrate"
                                style={containerStyle.textField}
                                helperText={
                                  touched.networkSettings?.videoEncoder2Bitrate &&
                                  errors.networkSettings?.videoEncoder2Bitrate
                                }
                                error={
                                  touched.networkSettings?.videoEncoder2Bitrate &&
                                  errors.networkSettings?.videoEncoder2Bitrate
                                }
                              >
                                {Object.keys(enumList.videoBitrate).map((key) => {
                                  return (
                                    <MenuItem key={key} value={key}>
                                      {enumList.videoBitrate[key].value}
                                    </MenuItem>
                                  );
                                })}
                              </Field>
                            </Box>
                          </Grid>
                        </Grid>
                      ) : null}
                      {formDevice.networkSettings.videoEncoder2JpegQuality !== undefined ? (
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-evenly"
                          style={containerStyle.itemContainer}
                        >
                          <Grid item xs={5} lg={7}>
                            <Box sx={containerStyle.itemTitle}>{videoEncoder2JpegQualityTitle}</Box>
                          </Grid>
                          <Grid item xs={5} lg={3}>
                            <Box sx={containerStyle.fieldContainer}>
                              <Field
                                as={Select}
                                label={videoEncoder2JpegQualityTitle}
                                name="networkSettings.videoEncoder2JpegQuality"
                                style={containerStyle.textField}
                                helperText={
                                  touched.networkSettings?.videoEncoder2JpegQuality &&
                                  errors.networkSettings?.videoEncoder2JpegQuality
                                }
                                error={
                                  touched.networkSettings?.videoEncoder2JpegQuality &&
                                  errors.networkSettings?.videoEncoder2JpegQuality
                                }
                              >
                                {Object.keys(enumList.videoEncoderQuality).map((key) => {
                                  return (
                                    <MenuItem key={key} value={key}>
                                      {enumList.videoEncoderQuality[key].value}
                                    </MenuItem>
                                  );
                                })}
                              </Field>
                            </Box>
                          </Grid>
                        </Grid>
                      ) : null}
                      {formDevice.networkSettings.videoEncoder2RtpStartPort !== undefined ? (
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-evenly"
                          style={containerStyle.itemContainer}
                        >
                          <Grid item xs={5} lg={7}>
                            <Box sx={containerStyle.itemTitle}>{videoEncoder2RtpStartPortTitle}</Box>
                          </Grid>
                          <Grid item xs={5} lg={3}>
                            <Box sx={containerStyle.fieldContainer}>
                              <Field
                                as={TextField}
                                type="number"
                                label={videoEncoder2RtpStartPortTitle}
                                name="networkSettings.videoEncoder2RtpStartPort"
                                style={containerStyle.textField}
                                helperText={
                                  touched.networkSettings?.videoEncoder2RtpStartPort &&
                                  errors.networkSettings?.videoEncoder2RtpStartPort
                                }
                                error={
                                  touched.networkSettings?.videoEncoder2RtpStartPort &&
                                  errors.networkSettings?.videoEncoder2RtpStartPort
                                }
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      ) : null}
                      {formDevice.networkSettings.videoEncoder2RtpEndPort !== undefined ? (
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-evenly"
                          style={containerStyle.itemContainer}
                        >
                          <Grid item xs={5} lg={7}>
                            <Box sx={containerStyle.itemTitle}>{videoEncoder2RtpEndPortTitle}</Box>
                          </Grid>
                          <Grid item xs={5} lg={3}>
                            <Box sx={containerStyle.fieldContainer}>
                              <Field
                                as={TextField}
                                type="number"
                                label={videoEncoder2RtpEndPortTitle}
                                name="networkSettings.videoEncoder2RtpEndPort"
                                style={containerStyle.textField}
                                helperText={
                                  touched.networkSettings?.videoEncoder2RtpEndPort &&
                                  errors.networkSettings?.videoEncoder2RtpEndPort
                                }
                                error={
                                  touched.networkSettings?.videoEncoder2RtpEndPort &&
                                  errors.networkSettings?.videoEncoder2RtpEndPort
                                }
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      ) : null}
                    </>
                  ) : null}
                  {formDevice.networkSettings.masterStationVideoFramerate !== undefined ? (
                    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                      <Grid item xs={5} lg={7}>
                        <Box sx={containerStyle.itemTitle}>{masterStationVideoFramerateTitle}</Box>
                      </Grid>
                      <Grid item xs={5} lg={3}>
                        <Box sx={containerStyle.fieldContainer}>
                          <Field
                            as={Select}
                            label={masterStationVideoFramerateTitle}
                            name="networkSettings.masterStationVideoFramerate"
                            style={containerStyle.textField}
                            helperText={
                              touched.networkSettings?.masterStationVideoFramerate &&
                              errors.networkSettings?.masterStationVideoFramerate
                            }
                            error={
                              touched.networkSettings?.masterStationVideoFramerate &&
                              errors.networkSettings?.masterStationVideoFramerate
                            }
                          >
                            {Object.keys(enumList.videoEncoderFramerate).map((key) => {
                              return !(enumList.videoEncoderFramerate[key] as IConditionalDefaultValue)
                                .supportedDeviceTypeList ||
                                (
                                  enumList.videoEncoderFramerate[key] as IConditionalDefaultValue
                                ).supportedDeviceTypeList.includes(selectedDevice.basicInfo.deviceType) ? (
                                <MenuItem key={key} value={key}>
                                  {enumList.videoEncoderFramerate[key].value}
                                </MenuItem>
                              ) : null;
                            })}
                          </Field>
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                  {formDevice.networkSettings.masterStationVideoIPictureInterval !== undefined ? (
                    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                      <Grid item xs={5} lg={7}>
                        <Box sx={containerStyle.itemTitle}>{masterStationVideoIPictureIntervalTitle}</Box>
                      </Grid>
                      <Grid item xs={5} lg={3}>
                        <Box sx={containerStyle.fieldContainer}>
                          <Field
                            as={TextField}
                            type="number"
                            label={masterStationVideoIPictureIntervalTitle}
                            name="networkSettings.masterStationVideoIPictureInterval"
                            style={containerStyle.textField}
                            helperText={
                              touched.networkSettings?.masterStationVideoIPictureInterval &&
                              errors.networkSettings?.masterStationVideoIPictureInterval
                            }
                            error={
                              touched.networkSettings?.masterStationVideoIPictureInterval &&
                              errors.networkSettings?.masterStationVideoIPictureInterval
                            }
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                  {formDevice.networkSettings.masterStationVideoBitrate !== undefined ? (
                    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                      <Grid item xs={5} lg={7}>
                        <Box sx={containerStyle.itemTitle}>{masterStationVideoBitrateTitle}</Box>
                      </Grid>
                      <Grid item xs={5} lg={3}>
                        <Box sx={containerStyle.fieldContainer}>
                          <Field
                            as={Select}
                            label={masterStationVideoBitrateTitle}
                            name="networkSettings.masterStationVideoBitrate"
                            style={containerStyle.textField}
                            helperText={
                              touched.networkSettings?.masterStationVideoBitrate &&
                              errors.networkSettings?.masterStationVideoBitrate
                            }
                            error={
                              touched.networkSettings?.masterStationVideoBitrate &&
                              errors.networkSettings?.masterStationVideoBitrate
                            }
                          >
                            {Object.keys(enumList.videoBitrate).map((key) => {
                              return (
                                <MenuItem key={key} value={key}>
                                  {enumList.videoBitrate[key].value}
                                </MenuItem>
                              );
                            })}
                          </Field>
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                </Box>
              </Card>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default Video;
