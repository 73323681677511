import { useTranslation } from 'react-i18next';

/* Email tab stub */
export const EmailLabel = () => {
  const { t } = useTranslation();
  const emailLabel = t('Shared.Email');
  return <span>{emailLabel}</span>;
};

const Email = () => {
  const { t } = useTranslation();
  const emailLabel = t('Shared.Email');
  return <span>{emailLabel}</span>;
};

export default Email;
