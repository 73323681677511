import React, { useState } from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import QrCodeIcon from '@mui/icons-material/QrCode';
import MailIcon from '@mui/icons-material/Mail';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { UserInfo } from 'shared/utils/UserUtils';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser, setCurrentUser } from 'store/slices/usersSlice';
import { EnumList, fetchLocalEnumList } from 'shared/utils/EnumUtils';
import { updateMfaSettings } from 'shared/api/Aws/authApi';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import { useTranslation } from 'react-i18next';
import DialogWrapper from './DialogWrapper';
import useSharedStyles from 'styles/useSharedStyles';

interface MFASettingsDialogProps {
  dialogIsOpen: boolean;
  setDialogIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setBaseCondition?: boolean;
}

const MFASettingsDialog = ({ dialogIsOpen, setDialogIsOpen, setBaseCondition = false }: MFASettingsDialogProps) => {
  const defaultMfaType = 'email';

  const sharedStyle = useSharedStyles();
  const { t } = useTranslation();
  const phoneNumberStr = t('Shared.PhoneNumber');
  const emailStr = t('Shared.Email');
  const cancelStr = t('Shared.Cancel');
  const saveChangesStr = t('Shared.SaveChanges');
  const dialogTitle = t('MFA.DialogTitle');
  const dialogSubtitle = t('MFA.DialogSubtitle');

  const userObj = useSelector(getCurrentUser);
  const enumList: EnumList = fetchLocalEnumList();
  const dispatch = useDispatch();

  const user = new UserInfo(userObj);

  const { email, phoneNumber, mfaTypeId, mfaEnabled } = user;

  const mfaTypeValue = enumList.mfaType[mfaTypeId || '']?.value || '';

  const [isButtonDisabled, setIsButtonDisabled] = useState(!setBaseCondition);
  const [mfaCondition, setMfaCondition] = useState(mfaEnabled || false);
  const [selectedMfaType, setSelectedMfaType] = useState(mfaTypeValue);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSaveChanges = async () => {
    try {
      setIsLoading(true);

      const selectedMfaTypeId = getSelectedMfaTypeId();

      await updateMfaSettings({
        userData: {
          email: user.email,
          userPublicId: user.publicId,
          mfaEnabled: mfaCondition,
          mfaTypeId: selectedMfaTypeId
        }
      });

      setSuccessMessage(t('MFA_Settings_Success_Message'));
      setIsSuccess(true);
    } catch (error: any) {
      if (error.message === 'User does not have delivery config set to turn on SMS_MFA') {
        setErrorMessage(t('MFA_Settings_SMS_Error_Message'));
      } else {
        setErrorMessage(t('MFA_Settings_Error_Message'));
      }
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMfaToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isMfaEnabled = event.target.checked;
    const mfaType = isMfaEnabled ? defaultMfaType : '';

    setMfaCondition(isMfaEnabled);
    handleMfaTypeChange(mfaType);

    setIsButtonDisabled(isMfaEnabled === mfaEnabled);
  };

  const handleMfaTypeChange = (type: string) => {
    setSelectedMfaType(type);
    setIsButtonDisabled(false);
    setMfaCondition(type.length > 0);
  };

  const getMfaTypeDetailsContent = () => {
    if (selectedMfaType === 'sms') {
      return (
        <Box>
          <Box>{t('MFA_SMS_Details_Content')}</Box>
          <TextField
            disabled
            id="phone-mfa-details"
            fullWidth
            variant="filled"
            label={phoneNumberStr}
            value={phoneNumber}
          />
        </Box>
      );
    } else if (selectedMfaType === 'email') {
      return (
        <Box>
          <Box>{t('MFA_Email_Details_Content')}</Box>
          <TextField disabled id="email-mfa-details" fullWidth variant="filled" label={emailStr} value={email} />
        </Box>
      );
    } else if (selectedMfaType === 'app') {
      return (
        <Box>
          <Box>{t('MFA_App_Details_Content')}</Box>
          <Button variant="outlined">{t('MFA_App_Details_Btn')}</Button>
        </Box>
      );
    } else {
      return <Box></Box>;
    }
  };

  const getSelectedMfaTypeId = () => {
    return parseInt(
      Object.keys(enumList.mfaType).find((key) => enumList.mfaType[key].value === selectedMfaType) || '1'
    );
  };

  const updateLocalUserData = () => {
    const selectedMfaTypeId = getSelectedMfaTypeId();

    const changesWasSaved = successMessage.length > 0;

    const userDataToUpdate = {
      ...user,
      email: user.email,
      userPublicId: user.publicId,
      mfaEnabled: mfaCondition,
      mfaTypeId: selectedMfaTypeId
    };

    if (
      changesWasSaved &&
      (user.email !== userDataToUpdate.email ||
        user.publicId !== userDataToUpdate.userPublicId ||
        user.mfaEnabled !== userDataToUpdate.mfaEnabled ||
        user.mfaTypeId !== userDataToUpdate.mfaTypeId)
    ) {
      new UserInfo(userDataToUpdate).saveUser();
      dispatch(setCurrentUser(userDataToUpdate));
    }
  };

  const handleDialogClose = () => {
    updateLocalUserData();
    setDialogIsOpen(false);
  };

  return (
    <DialogWrapper
      header={dialogTitle}
      subheader={dialogSubtitle}
      setIsOpen={setDialogIsOpen}
      open={dialogIsOpen}
      onClose={() => handleDialogClose()}
    >
      <SnackbarAlert type="error" time={6000} text={errorMessage} isOpen={isError} onClose={() => setIsError(false)} />
      <SnackbarAlert
        type="success"
        time={6000}
        text={successMessage}
        isOpen={isSuccess}
        onClose={() => setIsSuccess(false)}
      />
      <DialogContent sx={sharedStyle.common.padding.none}>
        <Grid container sx={sharedStyle.gridContainer}>
          <Grid item>
            <FormControlLabel
              control={<Switch checked={mfaCondition} onChange={handleMfaToggle} name="mfa" />}
              label={t('MFA_Settings_Dialog_Switch_Label')}
            />
          </Grid>
          <Grid item>
            <Typography variant="h6">{t('MFA_Settings_Dialog_Select_Type')}</Typography>
            <Box sx={styles.mfaTypesWrapper}>
              <Box>
                <Box
                  sx={{
                    ...styles.mfaTypeIsSelectable,
                    ...styles.mfaTypeBox,
                    ...(selectedMfaType === 'email' ? styles.selected : {})
                  }}
                  onClick={() => handleMfaTypeChange('email')}
                >
                  <MailIcon sx={styles.mfaTypeIcon} color="primary" /> {emailStr}
                </Box>
                <Box style={styles.successIconBox}>
                  {selectedMfaType === 'email' ? <CheckCircleIcon color="success" /> : null}
                </Box>
              </Box>

              <Box>
                <Box
                  sx={{
                    ...styles.mfaTypeIsSelectable,
                    ...styles.mfaTypeBox,
                    ...(selectedMfaType === 'sms' && mfaCondition ? styles.selected : {})
                  }}
                  onClick={() => handleMfaTypeChange('sms')}
                >
                  <PhoneAndroidIcon sx={styles.mfaTypeIcon} color="primary" /> {t('MFA_Settings_Dialog_Type_SMS')}
                </Box>
                <Box style={styles.successIconBox}>
                  {selectedMfaType === 'sms' && mfaCondition ? <CheckCircleIcon color="success" /> : null}
                </Box>
              </Box>
              <Box style={styles.hideMfaType}>
                <Box
                  sx={{
                    ...styles.mfaTypeBox,
                    ...(selectedMfaType === 'app' ? styles.selected : {})
                  }}
                >
                  <QrCodeIcon sx={styles.mfaTypeIcon} color="disabled" /> {t('MFA_Settings_Dialog_Type_App')}
                </Box>
                <Box style={styles.successIconBox}>
                  {selectedMfaType === 'app' ? <CheckCircleIcon color="success" /> : null}
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item sx={styles.mfaTypeDetailsContainer}>
            {mfaCondition && (
              <Box>
                <Typography variant="h6">{t('MFA_Settings_Dialog_Selected_Type_Title')}</Typography>
                <Box>{getMfaTypeDetailsContent()}</Box>
              </Box>
            )}
          </Grid>
          <Grid item>
            <DialogActions sx={sharedStyle.common.padding.none}>
              {!setBaseCondition && (
                <Button variant="contained" onClick={() => handleDialogClose()}>
                  {cancelStr}
                </Button>
              )}
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                disabled={isButtonDisabled}
                loading={isLoading}
                onClick={handleSaveChanges}
              >
                {saveChangesStr}
              </LoadingButton>
            </DialogActions>
          </Grid>
        </Grid>
      </DialogContent>
    </DialogWrapper>
  );
};

const styles = {
  mfaTypesWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '0.25rem'
  },
  mfaTypeBox: {
    width: '150px',
    height: '150px',
    borderRadius: '5%',
    border: '1px solid silver',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  mfaTypeIsSelectable: {
    cursor: 'pointer',
    '&:hover': {
      border: '1px solid #1976d2',
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)'
    }
  },
  mfaTypeDetailsContainer: {
    minHeight: '7rem',
    minWidth: '28.625rem'
  },
  selected: {
    border: '2px solid #1976d2', // Highlight the border on selection
    backgroundColor: '#e3f2fd' // Subtle background change on selection
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: 'grey'
  },
  successIconBox: {
    height: '20px',
    display: 'flex',
    justifyContent: 'center'
  },
  mfaTypeIcon: {
    fontSize: '75px'
    // marginBottom: '10px',
  },
  invalid: {
    color: '#d32f2f',
    fontSize: '0.8rem'
  },
  valid: {
    color: '#00BB31',
    fontSize: '0.8rem'
  },
  hideMfaType: {
    display: 'none'
  }
};

export default MFASettingsDialog;
