import { LinearProgress, linearProgressClasses, styled } from '@mui/material';

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'primary.light',
    ...theme.applyStyles('dark', {
      backgroundColor: 'primary.main'
    })
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: 'primary.main',
    ...theme.applyStyles('dark', {
      backgroundColor: 'primary.light'
    })
  }
}));
