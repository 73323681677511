import { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTranslation } from 'react-i18next';

const RegisterGatewayButtons: FC<{
  handlePreviousStep: () => void;
  handleNextStep: () => void;
  handleCheckGatewayConnection: () => void;
  handleRegisterGateway: () => void;
  inputMacAddress: string;
  errorMessage: string;
  isLoading: boolean;
  isGatewayOnline: boolean;
  registrationStep: string;
  isRegistered: boolean;
  isUsingSim: boolean;
  hasSIMSubscription: boolean;
}> = ({
  handlePreviousStep,
  handleNextStep,
  handleCheckGatewayConnection,
  handleRegisterGateway,
  inputMacAddress,
  errorMessage,
  isLoading,
  isGatewayOnline,
  registrationStep,
  isRegistered,
  isUsingSim,
  hasSIMSubscription
}) => {
  const { t } = useTranslation();
  const registerGatewayStr = t('Site.Configure.RegisterGateway');

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'end', gap: '10px', mt: 2 }}>
        <Button onClick={handleNextStep}>{t('Skip')}</Button>
        <Button onClick={handlePreviousStep}>{t('Shared.Back')}</Button>
        {isGatewayOnline ? (
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={handleRegisterGateway}
            loading={isLoading}
            loadingPosition="start"
            startIcon={<CheckCircleOutlineIcon />}
          >
            {registrationStep ? registrationStep : registerGatewayStr}
          </LoadingButton>
        ) : isRegistered ? (
          <LoadingButton variant="contained" color="primary" onClick={handleNextStep} loading={isLoading}>
            {t('Continue')}
          </LoadingButton>
        ) : (
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={handleCheckGatewayConnection}
            loading={isLoading}
            disabled={isUsingSim && !hasSIMSubscription}
          >
            {t('Check_Gateway_Connection')}
          </LoadingButton>
        )}
      </Box>

      {!inputMacAddress && (
        <Box sx={{ display: 'flex', justifyContent: 'end', mt: 1.5 }}>
          <Typography variant="error" gutterBottom>
            {t('Please_Enter_Gateway_MAC')}s
          </Typography>
        </Box>
      )}

      {inputMacAddress && errorMessage && (
        <Box sx={{ display: 'flex', justifyContent: 'end', mt: 1.5 }}>
          <Typography variant="error" gutterBottom>
            {errorMessage}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default RegisterGatewayButtons;
