import React, { useState, useMemo, useEffect } from 'react';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridPaginationModel,
  GridRowParams
} from '@mui/x-data-grid';
import {
  Box,
  Chip,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';
import useReduxUsers from 'features/Dashboard/Hooks/useReduxUsers';
import { LicenseManagementCommonStyles as CommonStyles } from '../../../styles/CommonStyles';
import { formatDate, getColorFromStatus, mapDeploymentType } from '../../common/utils';
import { Deployment, LicenseStatus, DeploymentType, DeploymentPage, SubscriptionStatus } from '../../common/Types';
import { useDeployments } from 'features/LicenseManagement/hooks/useDeployments';
import { useDeleteDeployment } from 'features/LicenseManagement/hooks/useDeployments';
import { useUserAccessibleAiphoneSites } from 'features/LicenseManagement/hooks/useUserAccessibleAiphoneSites';
import { useCancelStripeSubscription } from 'features/LicenseManagement/hooks/useCancelStripSubscription';
import LoadingSpinner from '../../common/LoadingSpinner';
import ActionContainer from './ActionContainer';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import ErrorAlert from '../../common/ErrorAlert';
import { useCurrentBranchWithReactQuery } from 'features/LicenseManagement/hooks/useCurrentBranchWithReactQuery';
import { CloudRole } from 'shared/utils/UserUtils';
import { useGetBranchWithPublicIdQuery, useGetCompaniesQuery } from 'services/aiphoneCloud';
import { NoDeploymentView } from './NoDeploymentView';

export const DEPLOYMENTS_PAGE_SIZE_DEFAULT = 25;

interface DeploymentDataGridProps {
  addNewDeployment: () => void;
}

const DeploymentDataGrid = ({ addNewDeployment }: DeploymentDataGridProps) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation('acNioLicenseManagement');
  // #region  translation strings
  const siteIdStr = t('Site_ID');
  const licenseStatusStr = t('License_Status');
  const licenseCategoryStr = t('License_Category');
  const domainStr = t('Deployment_Domain');
  const deploymentNameStr = t('Deployment_Name');
  const licenseExpirationStr = t('License_Expiration');
  const accountNumberStr = t('Account_No');
  const deleteSuccessStr = t('Delete_Success');
  const deleteFailedStr = t('Delete_Failed');
  const deploymentTypeStr = t('Deployment_Type');
  const viewDeploymentDetailsStr = t('View_Deployment_Details');
  const deleteDeploymentStr = t('Delete_Deployment');
  const errorLoadingDeploymentsStr = t('Error_Loading_Deployments');
  const confirmDeleteMessageStr = t('Confirm_Delete_Message');
  const cancelSubscriptionWarningStr = t('Cancel_Subscription_Warning');
  const cancelStr = t('Cancel');
  // #endregion translation strings

  const { currentUser } = useReduxUsers();
  const isAdminWithNoBranches =
    currentUser?.permissions.global?.roleList?.find((role) => role.roleName === CloudRole.AIPHONE_ADMIN) &&
    currentUser?.permissions.branch === null
      ? true
      : false;

  const [adminSelectedBranchPublicId, setAdminSelectedBranchPublicId] = useState<string>('');

  const { mutate: deleteDeployment } = useDeleteDeployment();
  const { mutate: cancelStripeSubscription } = useCancelStripeSubscription();

  const { data: currentBranch } = useCurrentBranchWithReactQuery();

  const { data: branchData } = useGetBranchWithPublicIdQuery(adminSelectedBranchPublicId, {
    skip: !adminSelectedBranchPublicId
  });

  useEffect(() => {
    if (branchData) {
      const cachedBranchData = queryClient.getQueryData(['currentBranch']);
      const isSameData = JSON.stringify(cachedBranchData) === JSON.stringify(branchData);

      if (!isSameData) {
        queryClient.setQueryData(['currentBranch'], branchData);
      }
    }
  }, [branchData, queryClient]);

  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: DEPLOYMENTS_PAGE_SIZE_DEFAULT });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedRow, setSelectedRow] = useState<Deployment | null>(null);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [message, setMessage] = useState<{ type: 'success' | 'error'; text: string } | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const filteredMySites = useUserAccessibleAiphoneSites(currentBranch ?? null, currentUser);

  // #region permissions
  const { isAllowedTo } = usePermission();

  const canViewUsersGlobal = isAllowedTo('user:view', null, PermissionsContextType.GLOBAL);
  const canCreateDeployment = isAllowedTo('deployment:create', currentBranch?.publicId, PermissionsContextType.BRANCH);
  const canViewDeployment = isAllowedTo('deployment:view', currentBranch?.publicId, PermissionsContextType.BRANCH);
  const canDeleteDeployment = isAllowedTo('deployment:delete', currentBranch?.publicId, PermissionsContextType.BRANCH);
  // #endregion

  // Fetch Deployments

  const {
    data: queryData,
    fetchNextPage,
    isLoading: isFetchingDeployments = false,
    isError: isFetchDeploymentsError = false,
    refetch: refetchDeployments = null
  } = useDeployments();

  /* Fetch companies */
  const { data: companies } = useGetCompaniesQuery(
    {
      qty: '-1',
      page: '0'
    },
    { skip: !canViewUsersGlobal }
  );
  const companyList = companies ? companies.companyList : {};

  const deployments: Deployment[] = useMemo(() => {
    if (!queryData) return [];
    if (!('pages' in queryData)) return [];

    const data = queryData as InfiniteData<DeploymentPage>;

    return data.pages.flatMap((page) => page.deployments || []);
  }, [queryData]);

  const totalCount = queryData?.pages?.[0]?.totalCount || 0;

  const filteredDeployments = useMemo(() => {
    if (!searchQuery.trim()) {
      return deployments;
    }

    const result = deployments.filter((deployment) =>
      deployment.name?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return result;
  }, [searchQuery, deployments]);

  const currentPageDeployments = useMemo(() => {
    return (filteredDeployments ?? []).slice(
      paginationModel.page * paginationModel.pageSize,
      (paginationModel.page + 1) * paginationModel.pageSize
    );
  }, [filteredDeployments, paginationModel]);

  const handlePaginationModelChange = async (model: GridPaginationModel) => {
    const { page } = model;

    // Only fetch the next page when moving forward in pagination
    if (page > paginationModel.page) {
      await fetchNextPage();
    }

    setPaginationModel(model);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, row: Deployment) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleViewDetails = () => {
    if (selectedRow) {
      navigate(`/acniolicensing/deployments/${selectedRow.deploymentId}/deploymentInformation`);
    }
    handleMenuClose();
  };

  const handleOpenDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleConfirmDelete = () => {
    if (selectedRow && currentUser?.publicId) {
      deleteDeployment(
        {
          deploymentId: selectedRow.deploymentId,
          aiphoneBranchPublicId: currentBranch?.publicId ?? '',
          hartmannDeploymentId: selectedRow.hartmannDeploymentId,
          cloudUserPublicId: currentUser.publicId,
          hartmannBranchId: selectedRow.hartmannBranchId
        },
        {
          onSuccess: () => setMessage({ type: 'success', text: deleteSuccessStr }),
          onError: () => setMessage({ type: 'error', text: deleteFailedStr })
        }
      );

      if (selectedRow.licenseStripeSubscriptionId && selectedRow?.subscriptionStatus !== SubscriptionStatus.Canceled) {
        cancelStripeSubscription(selectedRow.licenseStripeSubscriptionId);
      }
    }

    setDeleteDialogOpen(false);
    handleMenuClose();
  };

  const computeRows = (deployments: Deployment[]) => {
    return deployments.map((deployment) => ({
      id: deployment.deploymentId,
      deploymentId: deployment.deploymentId,
      name: deployment.name,
      site: deployment.linkedAiphoneSitePublicId ?? '-',
      accountNumber: deployment.licenses?.[0]?.hartmannAccountNumber ?? '-',
      category: 'AC Nio',
      type: mapDeploymentType(deployment.deploymentType),
      licenseStatus: deployment.licenseStatus,
      licenseExpirationDate: deployment.licenseExpirationDate ? formatDate(deployment.licenseExpirationDate) : '',
      domain: deployment.deploymentType === DeploymentType.Cloud ? deployment?.tenant?.subdomain : '-',
      hartmannDeploymentId: deployment.hartmannDeploymentId,
      hartmannBranchId: deployment.hartmannBranchId,
      licenseStripeSubscriptionId: deployment.licenseStripeSubscriptionId,
      subscriptionStatus: deployment?.subscriptionStatus
    }));
  };

  const computeColumns = (): GridColDef[] => [
    {
      field: 'name',
      headerName: deploymentNameStr,
      flex: 1,
      renderHeader: renderStyledHeader
    },
    {
      field: 'site',
      headerName: siteIdStr,
      flex: 1,
      width: 200,
      renderHeader: renderStyledHeader,
      renderCell: (params) =>
        params.value !== '-' && filteredMySites[params.value] ? (
          <Link to={`/site/${params.value}/siteinfo`} onClick={(event) => event.stopPropagation()}>
            {filteredMySites[params.value].siteName}
          </Link>
        ) : (
          '-'
        )
    },
    {
      field: 'accountNumber',
      headerName: accountNumberStr,
      flex: 1,
      renderHeader: renderStyledHeader
    },
    {
      field: 'category',
      headerName: licenseCategoryStr,
      flex: 1,
      renderHeader: renderStyledHeader
    },
    {
      field: 'type',
      headerName: deploymentTypeStr,
      flex: 1,
      renderHeader: renderStyledHeader
    },
    {
      field: 'licenseStatus',
      headerName: licenseStatusStr,
      flex: 1,
      renderHeader: renderStyledHeader,
      renderCell: (params: GridCellParams) =>
        params.value ? (
          <Box display="flex" alignItems="center">
            <Chip
              sx={styles.tableButtons}
              label={<Typography variant="body2">{params.value as string}</Typography>}
              color={getColorFromStatus(params.value as LicenseStatus)}
              size="medium"
              variant="outlined"
            />
          </Box>
        ) : null
    },
    {
      field: 'licenseExpirationDate',
      headerName: licenseExpirationStr,
      flex: 1,
      renderHeader: renderStyledHeader
    },
    {
      field: 'domain',
      headerName: domainStr,
      flex: 1,
      renderHeader: renderStyledHeader
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <>
          <IconButton onClick={(event) => handleMenuOpen(event, params.row as Deployment)}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            {canViewDeployment && <MenuItem onClick={handleViewDetails}>{viewDeploymentDetailsStr}</MenuItem>}
            {canDeleteDeployment && <MenuItem onClick={handleOpenDeleteDialog}>{deleteDeploymentStr}</MenuItem>}
          </Menu>
        </>
      )
    }
  ];

  const renderStyledHeader = (params: GridColumnHeaderParams) => (
    <Typography variant="body2" sx={CommonStyles.gridColumnHeader}>
      {params.colDef.headerName}
    </Typography>
  );

  return (
    <Box sx={styles.container}>
      <SnackbarAlert
        type={message?.type || 'info'}
        time={5000}
        text={message?.text || ''}
        isOpen={!!message}
        onClose={() => setMessage(null)}
      />
      {isFetchingDeployments && <LoadingSpinner />}
      {isFetchDeploymentsError && (
        <ErrorAlert
          errorMessage={errorLoadingDeploymentsStr}
          {...(refetchDeployments ? { onRetry: () => refetchDeployments() } : {})}
        />
      )}

      {(isAdminWithNoBranches || (deployments && deployments.length > 0)) && (
        <Box>
          <ActionContainer
            canCreateDeployment={canCreateDeployment}
            hasDeployments={deployments && deployments.length > 0}
            searchQuery={searchQuery}
            handleSearchChange={handleSearchChange}
            handleDeploymentTypeModalOpen={addNewDeployment}
            isAdminWithNoBranches={isAdminWithNoBranches}
            companyList={companyList}
            setAdminSelectedBranchPublicId={setAdminSelectedBranchPublicId}
          />
        </Box>
      )}

      <Box>
        {!isFetchingDeployments && (!filteredDeployments || filteredDeployments.length === 0) ? (
          <NoDeploymentView createDeploymentHandler={addNewDeployment} canCreateDeployment={canCreateDeployment} />
        ) : (
          <DataGrid
            rows={computeRows(currentPageDeployments)}
            columns={computeColumns()}
            paginationMode="server"
            rowCount={searchQuery.trim() ? filteredDeployments?.length ?? 0 : totalCount}
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationModelChange}
            pageSizeOptions={[25, 50, 100]}
            pagination
            autoHeight
            sx={styles.container}
            onRowClick={(params: GridRowParams) => {
              if (canViewDeployment) {
                navigate(`/acniolicensing/deployments/${params.id}/deploymentInformation`);
              }
            }}
          />
        )}
        <Dialog open={isDeleteDialogOpen} onClose={handleCloseDeleteDialog}>
          <DialogTitle>{deleteDeploymentStr}</DialogTitle>
          <DialogContent>
            <Typography>{confirmDeleteMessageStr}</Typography>
            <Typography>{cancelSubscriptionWarningStr}</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              {cancelStr}
            </Button>
            <Button onClick={handleConfirmDelete} color="primary">
              {deleteDeploymentStr}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    height: '25rem',
    width: '100%',
    '& .MuiDataGrid-root': {
      border: 'none'
    },
    '& .MuiDataGrid-cell': {
      borderBottom: '0.0625rem solid #e0e0e0'
    }
  },
  subheading: {
    color: 'text.secondary'
  },
  spinnerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tableButtons: {
    height: 'auto',
    borderRadius: '6.25rem',
    padding: '0.25rem',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    marginTop: '0.625rem'
  }
};

export default DeploymentDataGrid;
