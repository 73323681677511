import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';
import { DeploymentDoorSize, PaymentMethod, steps } from 'features/LicenseManagement/components/common/Types';
import MethodStep from './MethodStep';
import ActivationStep from './ActivationStep';
import { DeploymentSteps } from 'features/LicenseManagement/components/common/DeploymentSteps';
import { useCurrentBranchWithReactQuery } from 'features/LicenseManagement/hooks/useCurrentBranchWithReactQuery';

interface UnactivatedLocalLicenseProps {
  accountNumber: number;
  deployment: any;
  renewLicense?: boolean;
  rearmLicense?: boolean;
}

export const UnactivatedLocalLicense: React.FC<UnactivatedLocalLicenseProps> = ({
  accountNumber,
  deployment,
  renewLicense = false,
  rearmLicense
}) => {
  const { t } = useTranslation('acNioLicenseManagement');

  // Detect if this is a Rearm License flow
  const isRearmFlow = !!rearmLicense;

  const [activeStep, setActiveStep] = useState(0);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(PaymentMethod.Online);
  const [planType, setPlanType] = useState(DeploymentDoorSize.Basic);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [licenseCode, setLicenseCode] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { isAllowedTo } = usePermission();
  const { data: currentBranch } = useCurrentBranchWithReactQuery();
  // TODO update to correct permission
  const hasManageBillingPermission = isAllowedTo(
    'deploymentBilling:edit',
    currentBranch?.publicId,
    PermissionsContextType.BRANCH
  );

  // Function to render content based on the current step
  const renderStepContent = () => {
    const stepComponents = [
      // Only show MethodStep if NOT in Rearm flow
      !isRearmFlow ? (
        <MethodStep
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          planType={planType}
          setPlanType={setPlanType}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          licenseCode={licenseCode}
          setLicenseCode={setLicenseCode}
          setSelectedProduct={setSelectedProduct}
          renewLicense={renewLicense}
          deploymentId={deployment.deploymentId}
        />
      ) : null,

      // ActivationStep will handle Request Key for both activation and rearm flows
      <ActivationStep
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        accountNumber={accountNumber}
        selectedProduct={selectedProduct}
        deployment={deployment}
        selectedPaymentMethod={selectedPaymentMethod}
        licenseCode={licenseCode}
        renewLicense={renewLicense}
        isRearmFlow={isRearmFlow}
      />
    ];

    return stepComponents[isRearmFlow ? 1 : activeStep] || stepComponents[0];
  };

  return (
    <Box sx={styles.container}>
      {hasManageBillingPermission ? (
        <>
          <Typography variant="h6" sx={styles.title}>
            {isRearmFlow ? t('Rearm_License_Deployment') : t('Finalize_License_Deployment')}
          </Typography>
          <DeploymentSteps steps={steps(selectedPaymentMethod, isRearmFlow)} activeStep={activeStep} />
          {renderStepContent()}
        </>
      ) : (
        <Typography variant="body2" sx={{ marginTop: 2 }}>
          {t('Deployment_Has_No_Associated_License')}
        </Typography>
      )}
    </Box>
  );
};

const styles = {
  container: {
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    padding: 2,
    backgroundColor: '#f9f9f9',
    marginTop: '20px',
    alignItems: 'flex-start',
    height: '100%'
  },
  title: {
    margin: 0,
    padding: 0
  },
  stepLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  }
};
