import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AddPanelRequest, PanelDetailsItem, RemovePanelRequest } from '../components/common/Types';
import { addPanel, removePanel } from '../lib/api/LicenseManagementApi';

export const useAddPanel = (
  onSuccessCallback?: (response: PanelDetailsItem) => void,
  onErrorCallback?: (error: unknown) => void
) => {
  const queryClient = useQueryClient();
  const mutationOptions: UseMutationOptions<
    PanelDetailsItem,
    Error,
    {
      aiphoneBranchPublicId: string;
      deploymentId: string;
      data: AddPanelRequest;
    }
  > = {
    mutationFn: async ({ aiphoneBranchPublicId, deploymentId, data }) => {
      return await addPanel(aiphoneBranchPublicId, deploymentId, data);
    },
    onSuccess: (response, { deploymentId }) => {
      queryClient.invalidateQueries({ queryKey: ['deployment', deploymentId] });

      if (onSuccessCallback) {
        onSuccessCallback(response);
      }
    },
    onError: (error) => {
      if (onErrorCallback) {
        onErrorCallback(error);
      }
    }
  };

  return useMutation(mutationOptions);
};

export const useRemovePanel = () => {
  const queryClient = useQueryClient();

  const mutationOptions: UseMutationOptions<
    void,
    Error,
    { aiphoneBranchPublicId: string; deploymentId: string; hartmannPanelId: number; data: RemovePanelRequest }
  > = {
    mutationFn: async ({ aiphoneBranchPublicId, deploymentId, hartmannPanelId, data }) => {
      return await removePanel(aiphoneBranchPublicId, deploymentId, hartmannPanelId, data);
    },
    onSuccess: (_, { deploymentId }) => {
      queryClient.invalidateQueries({ queryKey: ['deployment', deploymentId] });
    }
  };

  return useMutation(mutationOptions);
};
