import React from 'react';
import { FieldProps } from 'formik';
import { TextField } from '@mui/material';
import { formatPhoneNumber } from 'shared/utils/helperFunctions';

interface PhoneInputProps extends FieldProps {
  maxDigits?: number; // Limit for phone number digits
}

const PhoneField: React.FC<PhoneInputProps> = ({
  field, // Formik field (name, value, onChange)
  form, // Formik state (errors, touched, etc.)
  maxDigits = 11, // Default max digits for phone numbers; Need 11 due to the country code
  ...props
}) => {
  const naPhoneCountryPrefix = '+1';

  const handleFocus = async (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value === '' || value.length === 0) {
      await form.setFieldValue(field.name, '+1');
      await form.setTouched({ ...form.touched, [field.name]: true });
    }
  };

  const handleBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value === '+1' || value.length === 2 || value.length === 3) {
      await form.setFieldValue(field.name, '');
      return;
    }
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value.length === 0 || value === naPhoneCountryPrefix) {
      // Set to +1. Previously, there would be no way to enter digits.
      await form.setFieldValue(field.name, naPhoneCountryPrefix);

      await form.setTouched({ ...form.touched, [field.name]: true });
      return;
    }

    const onlyNumbers = value.replace(/\D/g, ''); // Keep only numbers

    // Restrict input to max digits
    if (onlyNumbers.length > maxDigits) return;

    // Format the value into "pretty format".
    const formattedValue = formatPhoneNumber(onlyNumbers);

    // Manually set the field value to the formatted version
    await form.setFieldValue(field.name, formattedValue);
    await form.setTouched({ ...form.touched, [field.name]: true });
  };

  return (
    <TextField
      {...field} // Formik field props
      {...props} // Additional props (e.g., fullWidth, size)
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      value={field.value ?? naPhoneCountryPrefix}
    />
  );
};

export default PhoneField;
