import { Box, Button, DialogActions, Grid } from '@mui/material';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, resetGatewayState, setCurrentUser, updateGWId, updateSite } from 'store';
import { fetchAclSiteId } from 'shared/utils/fetchAclSiteId';
import { useCreateSiteMutation, useLazyGetUserWithPublicIdQuery } from 'services/aiphoneCloud';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useAclAuth } from 'features/SimBilling/Hooks';
import { GetUserInfo } from 'shared/api/Acl/IxgAclApi';
import { useTranslation } from 'react-i18next';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import DialogWrapper from 'shared/components/dialogs/DialogWrapper';
import { ICreateSiteFormValues } from './CreateSiteTypes';
import SiteInformationStep from './SiteInformationStep';
import SiteTypeStep from './SiteTypeStep';
import { EnumList, fetchLocalEnumList, ICountryValue } from 'shared/utils/EnumUtils';
import { RegexPostalCode, RegexZipCode } from '../types/Types';
import StringUtils from 'shared/utils/StringUtils';
import { ALPHANUMERIC_REGEX } from 'shared/constants/regex';
import { useGetSitesQuery } from 'services/aiphoneCloud';
import useSharedStyles from 'styles/useSharedStyles';

interface INewSiteDialog {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialValues: ICreateSiteFormValues = {
  siteName: '',
  siteAddress: '',
  siteAddress2: '',
  siteCity: '',
  cityOptions: [] as string[],
  stateId: '',
  siteZip: '',
  timezone: 7,
  countryId: '',
  sitePhoneNumber: '',
  typeId: 0,
  newProperty: true,
  newPropertyId: '',
  systemId: '',
  systemPassword: ''
};

const steps = ['Site Information', 'Site Type', 'Confirmation'];

enum Steps {
  SiteInformation,
  SiteType
}

const NewSiteDialog = ({ isOpen, setIsOpen }: INewSiteDialog) => {
  const sharedStyle = useSharedStyles();
  const { t } = useTranslation();
  const buttonBack = t('Shared.Back');
  const buttonCancel = t('Shared.Cancel');
  const buttonContinue = t('Shared.Continue');
  const siteInformationStr = t('Site.Information');
  const siteStr = t('Site.Singular');
  const createANewItem = t('Shared.CreateANewItem');
  const createItem = t('Shared.CreateItem');
  const fieldErrorMaxLen = t('Field.Error.MaxLen');
  const zipCodeStr = t('Shared.ZipCode');
  const postalCodeStr = t('Shared.PostalCode');
  const errorFetchingItemSte = t('API.Error.FetchingItem');
  const siteIdStr = t('Site.ID');
  const siteNameStr = t('Site.Name');
  const siteAddressStr = t('Site.SiteAddress');
  const cityStr = t('Shared.City');
  const stateStr = t('Shared.State');
  const countryStr = t('Shared.Country');
  const phoneNumberStr = t('Shared.PhoneNumber');
  const AptSuiteBuildingStr = t('Site.AptSuiteBuilding');
  const chooseSiteTypeStr = t('Site.CreateSiteDialog.Error.ChooseType');
  const siteIdNotFoundStr = t('Site.CreateSiteDialog.Error.SiteIDNotFound');
  const ixgPropertyIdStr = t('Site.IXGPropertyID');
  const systemIdStr = t('Site.SystemID');
  const systemPasswordStr = t('Site.SystemPassword');
  const createSiteAction = t('API.Action.CreateSite');
  const siteNameMaxLen = 128;
  const siteAddressMaxLen = 100;
  const aptSuitBuildingMaxLen = 100;
  const cityMaxLen = 100;
  const systemIdMaxLen = 32;
  const systemPasswordMaxLen = 32;
  const siteNameRequiredStr = t('Field.Error.Required', { field: siteNameStr });
  const errorFetchingSiteIdStr = StringUtils.format(errorFetchingItemSte, siteIdStr);
  const zipCodeRequiredStr = t('Field.Error.Required', { field: zipCodeStr });
  const postalCodeRequiredStr = t('Field.Error.Required', { field: postalCodeStr });
  const zipCodeInvalidStr = t('Field.Error.Invalid', { field: zipCodeStr });
  const postalCodeInvalid = t('Field.Error.Invalid', { field: postalCodeStr });
  const buttonCreateSite = StringUtils.format(createItem, siteStr);
  const createSiteDialogTitle = StringUtils.format(createANewItem, siteStr);
  const siteAddressRequiredStr = t('Field.Error.Required', { field: siteAddressStr });
  const cityRequiredStr = t('Field.Error.Required', { field: cityStr });
  const stateRequiredStr = t('Field.Error.Required', { field: stateStr });
  const countryRequiredStr = t('Field.Error.Required', { field: countryStr });
  const phoneNumberRequiredStr = t('Field.Error.Required', { field: phoneNumberStr });
  const aptSuiteBuildingMaxLenStr = StringUtils.format(fieldErrorMaxLen, AptSuiteBuildingStr, aptSuitBuildingMaxLen);
  const cityMaxLenStr = StringUtils.format(fieldErrorMaxLen, cityStr, cityMaxLen);
  const siteNameMaxLenStr = StringUtils.format(fieldErrorMaxLen, siteNameStr, siteNameMaxLen);
  const siteAddressMaxLenStr = StringUtils.format(fieldErrorMaxLen, siteAddressStr, siteAddressMaxLen);
  const phoneNumberInvalidStr = t('Field.Error.Invalid', { field: phoneNumberStr });
  const ixgPropertyIdRequiredStr = t('Field.Error.Required', { field: ixgPropertyIdStr });
  const systemIdRequiredStr = t('Field.Error.Required', { field: systemIdStr });
  const systemIdInvalidStr = t('Field.Error.Invalid', { field: systemIdStr });
  const systemIdMaxLenStr = StringUtils.format(fieldErrorMaxLen, systemIdStr, systemIdMaxLen);
  const systemPasswordRequiredStr = t('Field.Error.Required', { field: systemPasswordStr });
  const systemPasswordInvalidStr = t('Field.Error.Invalid', { field: systemPasswordStr });
  const systemPasswordMaxLenStr = StringUtils.format(fieldErrorMaxLen, systemPasswordStr, systemPasswordMaxLen);
  const createSiteGenericErrorStr = t('API.Error.Generic', { action: createSiteAction });
  const [activeStep, setActiveStep] = React.useState(Steps.SiteInformation);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [aclUserPropertyIDList, setAclUserPropertyIDList] = React.useState<string[]>([]);
  const [createSite, { isLoading: isCreating }] = useCreateSiteMutation();
  const [getUser] = useLazyGetUserWithPublicIdQuery();
  const branchData = useSelector((state: RootState) => state.branches.currentBranch);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const enumList: EnumList = fetchLocalEnumList();
  const canadaCountryId = Object.keys(enumList.country).find((key) => {
    return (enumList.country[key] as ICountryValue).alpha2Code === 'CA';
  });
  const usaCountryId = Object.keys(enumList.country).find((key) => {
    return (enumList.country[key] as ICountryValue).alpha2Code === 'US';
  });

  const { aclToken, aclUserName } = useAclAuth();

  const { refetch } = useGetSitesQuery({});

  useEffect(() => {
    const fetchData = async () => {
      const userInfo = await GetUserInfo(aclUserName, aclToken);
      setAclUserPropertyIDList(userInfo.Data.PropertyIDList ?? []);
    };
    if (aclToken && aclUserName) {
      fetchData();
    }
  }, [aclToken, aclUserName]);

  const totalSteps = () => steps.length;

  const completedSteps = () => Object.keys(completed).length;

  const isLastStep = () => activeStep === totalSteps() - 1;

  const allStepsCompleted = () => completedSteps() === totalSteps();

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted() ? steps.findIndex((_, i) => !(i in completed)) : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    if (activeStep === Steps.SiteInformation) {
      setIsOpen(false);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const handleClose = () => {
    handleReset();
    setIsOpen(false);
  };

  const prepareGateway = async () => {
    setIsLoading(true);
    dispatch(resetGatewayState());
    try {
      const aclSiteId = await fetchAclSiteId();
      dispatch(updateGWId(aclSiteId));
      return aclSiteId;
    } catch (error) {
      console.error(errorFetchingSiteIdStr, error);
      setErrorMessage(errorFetchingSiteIdStr);
      setIsLoading(false);
      return;
    }
  };

  const validationSchema = Yup.object().shape({
    siteName: Yup.string().required(siteNameRequiredStr).max(siteNameMaxLen, siteNameMaxLenStr),
    siteAddress: Yup.string().required(siteAddressRequiredStr).max(siteAddressMaxLen, siteAddressMaxLenStr),
    siteAddress2: Yup.string().max(aptSuitBuildingMaxLen, aptSuiteBuildingMaxLenStr),
    siteCity: Yup.string().required(cityRequiredStr).max(cityMaxLen, cityMaxLenStr),
    stateId: Yup.string().required(stateRequiredStr),
    countryId: Yup.string().required(countryRequiredStr),
    siteZip: Yup.string().when('countryId', {
      is: usaCountryId,
      then: (schema) => schema.required(zipCodeRequiredStr).matches(RegexZipCode, zipCodeInvalidStr),
      otherwise: (schema) =>
        schema.when('countryId', {
          is: canadaCountryId,
          then: (schema) => schema.required(postalCodeRequiredStr).matches(RegexPostalCode, postalCodeInvalid),
          otherwise: (schema) => schema.required(zipCodeRequiredStr)
        })
    }),
    sitePhoneNumber: Yup.string()
      .required(phoneNumberRequiredStr)
      .matches(/^(\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$/, phoneNumberInvalidStr),
    typeId: Yup.number()
      .required(chooseSiteTypeStr)
      .test('is-selected', chooseSiteTypeStr, (value) => value !== 0),
    newPropertyId: Yup.string().when('newProperty', {
      is: false,
      then: (schema) => schema.required(ixgPropertyIdRequiredStr),
      otherwise: (schema) => schema.notRequired()
    }),
    systemId: Yup.string().when('newProperty', {
      is: false,
      then: (schema) =>
        schema
          .required(systemIdRequiredStr)
          .max(systemIdMaxLen, systemIdMaxLenStr)
          .matches(ALPHANUMERIC_REGEX, systemIdInvalidStr),
      otherwise: (schema) => schema.notRequired()
    }),
    systemPassword: Yup.string().when('newProperty', {
      is: false,
      then: (schema) =>
        schema
          .required(systemPasswordRequiredStr)
          .max(systemPasswordMaxLen, systemPasswordMaxLenStr)
          .matches(ALPHANUMERIC_REGEX, systemPasswordInvalidStr),
      otherwise: (schema) => schema.notRequired()
    })
  });

  const handleSubmit = async (values: ICreateSiteFormValues, { setSubmitting }: FormikHelpers<any>) => {
    const awsPropertyId = values.newProperty ? await prepareGateway() : values.newPropertyId;
    const systemId = values.newProperty ? null : values.systemId;
    const systemPassword = values.newProperty ? null : values.systemPassword;

    const createSitePayload = {
      siteData: {
        siteName: values.siteName,
        siteAddress: values.siteAddress || '',
        siteCity: values.siteCity || '',
        stateId: values.stateId || branchData?.stateId,
        siteZip: values.siteZip || '',
        timezone: values.timezone || 7,
        sitePhoneNumber: values.sitePhoneNumber || '',
        multiBuilding: true,
        awsPropertyId: awsPropertyId,
        systemId: systemId,
        systemPassword: systemPassword,
        statusId: 1,
        branchPublicId: branchData?.publicId,
        typeId: values.typeId
      }
    };

    dispatch(updateSite(createSitePayload));
    createSite(createSitePayload)
      .then(async (result) => {
        setSubmitting(false);
        setIsLoading(false);
        if ('error' in result) {
          throw new Error(createSiteGenericErrorStr);
        } else if (result.data) {
          // Refetch the useGetSitesQuery so we update the array with the latest values that we don't want to render.
          await refetch();
          const userId = localStorage.getItem('userId');
          if (userId) {
            getUser(userId).then(({ data }) => {
              if (data) {
                dispatch(setCurrentUser(data));
              }
            });
          }
          navigate(`/site/${result.data}`);
        } else {
          throw new Error(siteIdNotFoundStr);
        }
      })
      .catch((error) => {
        setSubmitting(false);
        setIsLoading(false);
        setErrorMessage(error);
      });
  };

  const renderStep = (step: Steps, formikProps: FormikProps<ICreateSiteFormValues>) => {
    switch (step) {
      case Steps.SiteType:
        return <SiteTypeStep formikProps={formikProps} aclSites={aclUserPropertyIDList} />;
      case Steps.SiteInformation:
      default:
        return <SiteInformationStep formikProps={formikProps} />;
    }
  };

  return (
    <>
      <DialogWrapper
        header={createSiteDialogTitle}
        subheader={siteInformationStr}
        setIsOpen={handleClose}
        open={isOpen}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <Box sx={styles.newSiteDialogContainer}>
          <Grid container spacing={1}>
            <Grid item xs={11}></Grid>
          </Grid>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            validateOnBlur={true}
            validateOnChange={true}
          >
            {(formikProps) => {
              const handleComplete = () => () => {
                let unexpectedErrorKeys = [
                  'siteName',
                  'siteAddress',
                  'countryId',
                  'stateId',
                  'siteCity',
                  'siteZip',
                  'sitePhoneNumber'
                ];

                if (activeStep === Steps.SiteType) {
                  unexpectedErrorKeys = [...unexpectedErrorKeys, 'typeId'];
                }

                formikProps.validateForm().then((errors) => {
                  const foundUnexpectedError = Object.keys(errors).some((walker) =>
                    unexpectedErrorKeys.includes(walker)
                  );
                  if (!foundUnexpectedError) {
                    const newCompleted = completed;
                    newCompleted[activeStep] = true;
                    setCompleted(newCompleted);

                    handleNext();
                  } else {
                    unexpectedErrorKeys.forEach((key) => {
                      formikProps.setFieldTouched(key, true);
                    });
                  }
                });
              };

              return (
                <Form>
                  <Box>{renderStep(activeStep, formikProps)}</Box>
                  <DialogActions sx={sharedStyle.common.padding.none}>
                    <Button onClick={handleBack} sx={{ mr: 1 }}>
                      {activeStep === Steps.SiteInformation ? buttonCancel : buttonBack}
                    </Button>
                    {activeStep === Steps.SiteType ? (
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        type="submit"
                        loading={isLoading || isCreating}
                      >
                        {buttonCreateSite}
                      </LoadingButton>
                    ) : (
                      <Button variant="contained" color="primary" type="button" onClick={handleComplete()}>
                        {buttonContinue}
                      </Button>
                    )}
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </DialogWrapper>
      <SnackbarAlert
        type="error"
        time={3000}
        text={errorMessage}
        isOpen={errorMessage !== ''}
        onClose={() => setErrorMessage('')}
      />
    </>
  );
};

const styles = {
  newSiteDialogContainer: {
    width: '100%',
    height: '100%'
  }
};

export default NewSiteDialog;
