import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { NANP_PHONE_REGEX, VALID_PASSWORD_REGEX } from 'shared/constants/regex';
import StringUtils from 'shared/utils/StringUtils';
import { RegexPostalCode, RegexZipCode } from 'features/RemoteManagement/types/Types';

const useAuthValidationSchemas = () => {
  // Values
  const nameMaxLength = 50;
  const companyNameMaxLength = 100;
  const addressMaxLength = 512;
  const emailMaxLength = 254;
  const address2MaxLength = 255;
  const cityMaxLength = 100;
  const passwordMinLength = 8;

  // Strings
  const { t } = useTranslation(); // Initialize the translation hook
  const firstNameStr = t('Shared.FirstName');
  const lastNameStr = t('Shared.LastName');
  const emailStr = t('Shared.Email');
  const phoneStr = t('Shared.PhoneNumber');
  const countryStr = t('Shared.Country');
  const addressStr = t('Shared.Address');
  const addressStr2 = t('Shared.Address2');
  const companyNameStr = t('Company.Name');
  const stateStr = t('Shared.State');
  const cityStr = t('Shared.City');
  const zipCodeStr = t('Shared.ZipCode');
  const fieldErrorMaxChars = t('Field.Error.MaxLen');
  const fieldErrorMinChars = t('Field.Error.MinLen');
  const postalCodeStr = t('Shared.PostalCode');
  const passwordStr = t('Password');
  const invalidPhoneNumberStr = t('Field.Error.Invalid', { field: phoneStr });
  const passwordMinLengthStr = StringUtils.format(fieldErrorMinChars, passwordStr, passwordMinLength);
  const phoneNumberRequiredStr = t('Field.Error.Required', { field: phoneStr });
  const companyNameRequiredStr = t('Field.Error.Required', { field: companyNameStr });
  const passwordRequiredStr = t('Field.Error.Required', { field: passwordStr });
  const addressRequiredStr = t('Field.Error.Required', { field: addressStr });
  const emailRequiredStr = t('Field.Error.Required', { field: emailStr });
  const stateRequiredStr = t('Field.Error.Required', { field: stateStr });
  const cityMaxLengthStr = StringUtils.format(fieldErrorMaxChars, cityStr, cityMaxLength);
  const addressMaxLengthStr = StringUtils.format(fieldErrorMaxChars, addressStr, addressMaxLength);
  const address2MaxLengthStr = StringUtils.format(fieldErrorMaxChars, addressStr2, address2MaxLength);
  const companyNameMaxLengthStr = StringUtils.format(fieldErrorMaxChars, companyNameStr, companyNameMaxLength);
  const firstNameMaxLengthStr = StringUtils.format(fieldErrorMaxChars, firstNameStr, nameMaxLength);
  const lastNameMaxLengthStr = StringUtils.format(fieldErrorMaxChars, lastNameStr, nameMaxLength);
  const zipCodeInvalidStr = t('Field.Error.Invalid', { field: zipCodeStr });
  const postalCodeInvalidStr = t('Field.Error.Invalid', { field: postalCodeStr });
  const firstNameRequiredStr = t('Field.Error.Required', { field: firstNameStr });
  const lastNameRequiredStr = t('Field.Error.Required', { field: lastNameStr });
  const emailMaxLengthStr = StringUtils.format(fieldErrorMaxChars, emailStr, emailMaxLength);
  const countryRequiredStr = t('Field.Error.Required', { field: countryStr });
  const cityRequiredStr = t('Field.Error.Required', { field: cityStr });
  const postalCodeMinLengthStr = StringUtils.format(fieldErrorMinChars, zipCodeStr, 6);
  const postalCodeMaxLengthStr = StringUtils.format(fieldErrorMaxChars, zipCodeStr, 7);
  const zipCodeMinLengthStr = StringUtils.format(fieldErrorMinChars, zipCodeStr, 5);
  const zipCodeMaxLengthStr = StringUtils.format(fieldErrorMaxChars, zipCodeStr, 5);
  const zipCodeRequiredStr = t('Field.Error.Required', { field: zipCodeStr });
  const postalCodeRequiredStr = t('Field.Error.Required', { field: postalCodeStr });
  const invalidEmailStr = t('Field.Error.Invalid', { field: emailStr });

  const USA_COUNTRY_ID = '2';

  // Login Validation Schema
  const loginValidation = yup.object().shape({
    email: yup.string().email(t('Validations.Enter_Valid_Email')).required(t('Validations.Email_Required')),
    password: yup.string().required(t('Validations.Password_Required'))
  });

  const mfaValidation = yup.object().shape({
    verificationCode: yup.string().required(t('Validations.Required'))
  });

  // Registration Validation Schema
  const registerValidation = yup.object().shape({
    firstName: yup.string().required(firstNameRequiredStr).max(nameMaxLength, firstNameMaxLengthStr),
    lastName: yup.string().required(lastNameRequiredStr).max(nameMaxLength, lastNameMaxLengthStr),
    email: yup.string().email(invalidEmailStr).required(emailRequiredStr).max(emailMaxLength, emailMaxLengthStr),
    phoneNumber: yup.string().required(phoneNumberRequiredStr).matches(NANP_PHONE_REGEX, invalidPhoneNumberStr),
    companyName: yup.string().required(companyNameRequiredStr).max(companyNameMaxLength, companyNameMaxLengthStr),
    countryId: yup.string().required(countryRequiredStr),
    address: yup.string().required(addressRequiredStr).max(addressMaxLength, addressMaxLengthStr),
    address2: yup.string().optional().nullable().max(address2MaxLength, address2MaxLengthStr),
    city: yup.string().required(cityRequiredStr).max(cityMaxLength, cityMaxLengthStr),
    stateId: yup.number().required(stateRequiredStr),
    // This watches the value of 'countryId' and updates the validation for each different country
    zipCode: yup.string().when('countryId', {
      is: USA_COUNTRY_ID,
      then: (schema) =>
        schema
          .min(5, zipCodeMinLengthStr)
          .max(5, zipCodeMaxLengthStr)
          .matches(RegexZipCode, zipCodeInvalidStr)
          .required(zipCodeRequiredStr),
      otherwise: (schema) =>
        schema
          .min(6, postalCodeMinLengthStr)
          .max(7, postalCodeMaxLengthStr)
          .matches(RegexPostalCode, postalCodeInvalidStr)
          .required(postalCodeRequiredStr)
    }),
    password: yup.string().min(passwordMinLength, passwordMinLengthStr).required(passwordRequiredStr),
    passwordConfirm: yup
      .string()
      .min(passwordMinLength, passwordMinLengthStr)
      .required(passwordRequiredStr)
      .oneOf([yup.ref('password')], t('Validations.Password_Match'))
  });

  // Confirm Email Validation Schema
  const confirmEmailValidation = yup.object().shape({
    verificationCode: yup.string().required(t('Validations.Required'))
  });

  // Create Password Validation Schema
  const createPWDValidation = yup.object().shape({
    password: yup.string().required(t('Validations.Required')),
    passwordConfirm: yup
      .string()
      .required(t('Validations.Required'))
      .oneOf([yup.ref('password')], t('Validations.Password_Match'))
  });

  // Reset Password Validation Schema
  const resetPWDValidation = yup.object().shape({
    email: yup.string().email(t('Validations.Enter_Valid_Email')).required(t('Validations.Email_Required'))
  });

  return {
    loginValidation,
    registerValidation,
    confirmEmailValidation,
    createPWDValidation,
    resetPWDValidation,
    mfaValidation
  };
};

export default useAuthValidationSchemas;

/**
 * @function useYupValidators
 * Custom hook `useYupValidators` for defining and managing Yup validation schemas.
 *
 * This hook provides predefined Yup validators with localized error messages using translation functionality.
 * It is particularly designed for validating user input fields like password fields.
 *
 * @returns {Object} An object containing pre-defined Yup validation schemas:
 * - `validatePassword`: Validation schema for passwords. It checks for required passwords and validates against a regex pattern. Error messages are localized.
 */
export const useYupValidators = (): object => {
  const { t } = useTranslation();

  const validatePassword = yup
    .string()
    .required(t('Password_Required'))
    .matches(VALID_PASSWORD_REGEX, t('Invalid_Password'));

  return {
    validatePassword
  };
};
