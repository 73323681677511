import { Alert, Box, Button, CircularProgress, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Login from 'shared/api/Acl/Login';
import { areInputsEmpty, debounce } from 'shared/utils/helperFunctions';
import StringUtils from 'shared/utils/StringUtils';
import * as Yup from 'yup';
import { checkemail } from 'shared/api/Acl/IxgAclApi';
import PasswordField from 'shared/components/forms/fields/PasswordField';
import useSharedStyles from 'styles/useSharedStyles';

interface Props {
  setIsActivationPanelOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsRegisterDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreatePanel = ({ setIsActivationPanelOpen, setIsRegisterDialogOpen }: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [result, setResult] = useState('');
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);
  const [emailError, setEmailError] = useState<string | undefined>(undefined);
  const [createSuccess, setCreateSuccess] = useState(false);
  const sharedStyle = useSharedStyles();
  const { t } = useTranslation();
  const adminIdStr = t('IXGLogin.AdministratorId');
  const fieldErrorAlreadyExists = t('Field.Error.AlreadyExists');
  const fieldErrorMaxLen = t('Field.Error.MaxLen');
  const fieldErrorMinLen = t('Field.Error.MinLen');
  const fieldErrorNoSpecialChars = t('Field.Error.NoSpecialChars');
  const emailAddressStr = t('Shared.EmailAddress');
  const passwordStr = t('Shared.Password');
  const confirmItemStr = t('Shared.ConfirmItem');

  const passwordUpperCaseError = t('Field.Shared.Password.OneUpperCaseLetter.Error');
  const passwordLowerCaseError = t('Field.Shared.Password.OneLowerCaseLetter.Error');
  const passwordNumberError = t('Field.Shared.Password.OneNumber.Error');
  const passwordUpperCaseRequirement = t('Field.Shared.Password.OneUpperCaseLetter.Requirement');
  const passwordLowerCaseRequirement = t('Field.Shared.Password.OneLowerCaseLetter.Requirement');
  const passwordNumberRequirement = t('Field.Shared.Password.OneNumber.Requirement');
  const passwordMatchError = t('Field.Shared.Password.PasswordMatch.Error');
  const passwordRequirementsDesc = t('Field.Shared.Password.RequirementsDesc');
  const emailAlreadyExistsStr = StringUtils.format(fieldErrorAlreadyExists, emailAddressStr);
  const confirmPasswordStr = StringUtils.format(confirmItemStr, passwordStr);
  const confirmPasswordRequired = t('Field.Error.Required', { field: confirmPasswordStr });
  const adminIdMaxLen = 22;
  const adminIdMaxLenStr = StringUtils.format(fieldErrorMaxLen, adminIdStr, adminIdMaxLen);
  const adminIdNoSpecialChars = StringUtils.format(fieldErrorNoSpecialChars, adminIdStr);
  const adminIdRequired = t('Field.Error.Required', { field: adminIdStr });
  const passwordMinLen = 8;
  const passwordMinLenStr = StringUtils.format(fieldErrorMinLen, passwordStr, passwordMinLen);
  const passwordRequired = t('Field.Error.Required', { field: passwordStr });
  const passwordUppercaseErrorStr = StringUtils.format(passwordUpperCaseError, passwordStr);
  const passwordLowercaseErrorStr = StringUtils.format(passwordLowerCaseError, passwordStr);
  const passwordOneNumberErrorStr = StringUtils.format(passwordNumberError, passwordStr);
  const emailInvalidStr = t('Field.Error.Invalid', { field: emailAddressStr });
  const emailRequiredStr = t('Field.Error.Required', { field: emailAddressStr });
  const passwordRequirementsDescStr = StringUtils.format(passwordRequirementsDesc, passwordStr);
  const userSuccessFullyCreatedStr = t('IXGLogin.CreatePanel.UserSuccessfullyCreated');

  const validationSchema = Yup.object({
    id: Yup.string()
      .max(22, adminIdMaxLenStr)
      .matches(/^[a-zA-Z0-9]*$/, adminIdNoSpecialChars)
      .required(adminIdRequired),
    password: Yup.string()
      .min(8, passwordMinLenStr)
      .matches(/[A-Z]/, passwordUppercaseErrorStr)
      .matches(/[a-z]/, passwordLowercaseErrorStr)
      .matches(/\d/, passwordOneNumberErrorStr)
      .required(passwordRequired),
    confirmPW: Yup.string()
      .oneOf([Yup.ref('password')], passwordMatchError)
      .required(confirmPasswordRequired),
    email: Yup.string().email(emailInvalidStr).required(emailRequiredStr)
  });

  const handleSignup = async (values: { id: string; password: string; email: string }) => {
    try {
      setIsSubmitting(true);

      // Double-check if the email is already in the system
      const emailResult = await checkemail(values.email);
      const resultJson: boolean = emailResult['Data']['IsDuplicated'];
      if (resultJson) {
        setEmailError(emailAlreadyExistsStr);
        setIsSubmitting(false);
        return;
      }

      await Login.signup(values.id, values.password, values.email)
        .then(() => {
          setResult(userSuccessFullyCreatedStr);
          setCreateSuccess(true);

          if (setIsRegisterDialogOpen) {
            setIsRegisterDialogOpen(false);
          }
          if (setIsActivationPanelOpen) {
            setIsActivationPanelOpen(true);
          }
        })
        .catch((err) => {
          setResult(err);
          setCreateSuccess(false);
        });

      setIsSubmitting(false);
    } catch (e: any) {
      setIsSubmitting(false);
      setResult(e.toString());
    }
  };

  const checkEmailAndSetError = debounce(
    async (email: string, setFieldError: (field: string, message: string | undefined) => void) => {
      setIsCheckingEmail(true);
      if (email && email.length > 0) {
        const emailResult = await checkemail(email);
        const resultJson: boolean = emailResult['Data']['IsDuplicated'];
        if (resultJson) {
          setEmailError(emailAlreadyExistsStr);
          setFieldError('email', emailAlreadyExistsStr);
        }
      }
      setIsCheckingEmail(false);
    },
    500 // 500ms debounce makes it feel more responsive
  );

  return (
    <Formik
      initialValues={{ id: '', password: '', confirmPW: '', email: '' }}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSignup(values)}
    >
      {({ handleChange, handleBlur, values, errors, setFieldError, touched, isValid }) => {
        function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
          setEmailError(undefined);
          handleChange(event);
        }

        return (
          <Form>
            <Grid container direction={'column'}>
              <Grid item sx={sharedStyle.validatedFieldContainer}>
                <Field
                  as={TextField}
                  fullWidth
                  value={values.id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={adminIdStr}
                  label={adminIdStr}
                  id="id"
                  margin="dense"
                  error={touched.id && Boolean(errors.id)}
                  helperText={touched.id && errors.id}
                  required
                />
              </Grid>
              <Grid item sx={sharedStyle.validatedFieldContainer}>
                <Field
                  as={TextField}
                  fullWidth
                  onBlurCapture={() => {
                    checkEmailAndSetError(values.email, setFieldError);
                  }}
                  value={values.email}
                  onChange={handleEmailChange}
                  onBlur={handleBlur}
                  placeholder={emailAddressStr}
                  label={emailAddressStr}
                  id="email"
                  margin="dense"
                  type="email"
                  error={touched.email && Boolean(errors.email || emailError)}
                  helperText={touched.email && (errors.email || emailError)}
                  required
                  InputProps={{
                    endAdornment: isCheckingEmail ? (
                      <InputAdornment position={'end'}>
                        <CircularProgress size="25px" />
                      </InputAdornment>
                    ) : undefined
                  }}
                />
              </Grid>
              <Grid item sx={sharedStyle.validatedFieldContainer}>
                <Field
                  as={PasswordField}
                  fullWidth
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={passwordStr}
                  label={passwordStr}
                  id="password"
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  required
                />
              </Grid>
              <Grid item sx={sharedStyle.validatedFieldContainer}>
                <Field
                  as={PasswordField}
                  fullWidth
                  onChange={handleChange}
                  value={values.confirmPW}
                  onBlur={handleBlur}
                  placeholder={confirmPasswordStr}
                  label={confirmPasswordStr}
                  id="confirmPW"
                  margin="dense"
                  error={touched.confirmPW && Boolean(errors.confirmPW)}
                  helperText={touched.confirmPW && errors.confirmPW}
                  required
                />
              </Grid>
              <Grid item sx={sharedStyle.validatedFieldContainer.subtext}>
                <Typography variant="caption" color="textSecondary">
                  {passwordRequirementsDescStr}
                  <ul style={sharedStyle.common.margin.none}>
                    <li>{passwordUpperCaseRequirement}</li>
                    <li>{passwordLowerCaseRequirement}</li>
                    <li>{passwordNumberRequirement}</li>
                  </ul>
                </Typography>
              </Grid>
            </Grid>

            <Button
              variant="contained"
              type="submit"
              sx={sharedStyle.common.width.full}
              disabled={!isValid || isSubmitting || areInputsEmpty(values) || emailError !== undefined}
            >
              {isSubmitting ? <CircularProgress size="25px" sx={{ color: 'white' }} /> : 'Create'}
            </Button>
            {
              // Display result message
              result.length > 0 && (
                <Box sx={sharedStyle.common.padding.top.md}>
                  <Alert severity={createSuccess ? 'success' : 'error'}>{result}</Alert>
                </Box>
              )
            }
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreatePanel;
