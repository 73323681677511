import React, { useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { Box, Paper, Typography } from '@mui/material';
import { defaultBarChartOptions } from './chartConfig';
import { barChartDataItem } from 'shared/types/chartDataItem';

interface BarChartGraphProps {
  dataItems: barChartDataItem[];
  title: string;
}

const BarChartGraph: React.FC<BarChartGraphProps> = ({ dataItems, title }) => {
  const chartRef = useRef<ChartJSOrUndefined<'bar', number[], string>>(null);

  const categories = dataItems.map((item) => item.category);
  const datasets = dataItems.flatMap((item) =>
    item.labels.map((stackLabel, index) => ({
      label: stackLabel,
      data: categories.map((category) => (category === item.category ? item.values[index] : 0)),
      backgroundColor: item.colors[index],
      barThickness: 40
    }))
  );
  const data = { labels: categories, datasets };
  const options = defaultBarChartOptions;

  useEffect(() => {
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, []);

  return (
    <Box sx={styles.container}>
      <Paper sx={styles.paper}>
        <Typography variant="h6" align="center" sx={styles.title}>
          {title}
        </Typography>
        <Box sx={styles.chartContainer}>
          <Bar ref={chartRef} data={data} options={options} />
        </Box>
      </Paper>
    </Box>
  );
};

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '3px'
  },
  paper: {
    padding: '12px',
    width: '100%',
    maxWidth: '600px',
    backgroundColor: 'white'
  },
  title: {
    marginBottom: '2px',
    fontSize: '1rem',
    padding: '2px',
    fontWeight: 'normal'
  },
  chartContainer: {
    position: 'relative',
    height: '300px',
    width: '100%',
    padding: '0 8px',
    marginBottom: '8px'
  }
};

export default BarChartGraph;
