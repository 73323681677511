import { InfoOutlined } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import SyncExistingCredentials from './Dialogs/SyncExistingCredentials';
import { getStatusCodeString, getStatusCodeButtonString } from './utils/utils';

interface SiteActivityErrorItemBatchProps {
  item: any;
  stationName?: string;
  stationIp?: string;
  statusCode: string;
}

const SiteActivityErrorItemBatch = ({ item, stationName, stationIp, statusCode }: SiteActivityErrorItemBatchProps) => {
  const [isSyncExistingCredentialsOpen, setIsSyncExistingCredentialsOpen] = useState(false);
  return (
    <>
      <Grid
        container
        sx={{
          marginY: '8px'
        }}
      >
        <Grid
          item
          xs={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Typography variant="body2" color="text.secondary">
            {stationName}:
          </Typography>
        </Grid>

        <Grid
          item
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box>
            <Typography
              variant="body2"
              color="error.main"
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <InfoOutlined color="error" sx={{ fontSize: '1rem', marginRight: '.25rem' }} />
              {getStatusCodeString(statusCode)}
            </Typography>
          </Box>
          <Box>
            <Button variant="outlined" onClick={() => setIsSyncExistingCredentialsOpen(true)} size="small">
              {getStatusCodeButtonString(statusCode)}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <SyncExistingCredentials
        item={item}
        stationIp={stationIp}
        isSyncExistingCredentialsOpen={isSyncExistingCredentialsOpen}
        setIsSyncExistingCredentialsOpen={setIsSyncExistingCredentialsOpen}
      />
    </>
  );
};

export default SiteActivityErrorItemBatch;
