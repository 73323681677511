import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from '../index';
import { getSiteById } from 'shared/api/Aws/awsApi';
import { aiphoneCloudApi } from 'services/aiphoneCloud';

// Define a type for the slice state
interface SiteState {
  siteInfo: ISiteInfo;
  applicationType: string;
  Loading: boolean;
  Error: string | undefined;
}

// Define the initial state using that type
const initialState: SiteState = {
  siteInfo: {
    awsPropertyId: '',
    cloudOrganizationPublicId: '',
    createdBy: '',
    createdOn: '',
    isWizardCompleted: false,
    lastUpdatedBy: '',
    lastUpdatedOn: '',
    multiBuilding: true,
    publicId: '',
    registeredGatewayPublicId: '',
    siteAddress: '',
    siteAddress2: '',
    siteCity: '',
    siteName: '',
    sitePhoneNumber: '',
    stateId: '',
    siteZip: '',
    statusId: 0,
    systemId: '',
    systemPassword: '',
    timezone: 7,
    typeId: 1
  },
  applicationType: '',
  Loading: false,
  Error: undefined
};

export interface ISiteInfo {
  aclSiteId?: string;
  awsPropertyId: string;
  cloudOrganizationPublicId: string;
  createdBy: string;
  createdOn: string;
  isWizardCompleted: boolean;
  lastUpdatedBy: string;
  lastUpdatedOn: string;
  multiBuilding: boolean;
  publicId: string;
  registeredGatewayPublicId: string | null;
  siteAddress: string;
  siteAddress2: string;
  siteCity: string;
  stateId: string;
  siteName: string;
  sitePhoneNumber: string;
  siteZip: string;
  timezone: number;
  statusId: number;
  systemId: string;
  systemPassword: string;
  typeId: number;
}

export const fetchSiteData = createAsyncThunk(
  'site/fetchSiteData',
  async ({ sitePublicId }: { sitePublicId: string }, thunkAPI) => {
    try {
      const response = await getSiteById({ sitePublicId: sitePublicId });
      if (response !== undefined) {
        if (response.status !== 200) {
          return thunkAPI.rejectWithValue(response);
        }
        if (response.data.rows.length === 0) {
          return thunkAPI.rejectWithValue(response);
        }
        if (response.data.rows.length > 1) {
          return thunkAPI.rejectWithValue(response);
        }
        if (response.data.rows.length === 1) {
          const siteData = response.data.rows[0];
          return siteData;
        }
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const siteSlice = createSlice({
  name: 'siteInfo',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    selectSite: (state, action) => {
      state.siteInfo = action.payload;
    },
    addSite: (state, action) => {
      state.siteInfo = action.payload;
      state.Loading = false;
    },
    updateSite: (state, action) => {
      state.siteInfo = { ...state.siteInfo, ...action.payload };
    },
    setSiteData: (state, action) => {
      state.siteInfo = action.payload;
      state.Loading = false;
    },
    setSiteLoading: (state, action) => {
      state.Loading = action.payload;
    },
    setIsWizardComplete: (state, action) => {
      state.siteInfo.isWizardCompleted = action.payload;
    },
    addAwsPropertyId: (state, action) => {
      state.siteInfo = { ...state.siteInfo, ...action.payload };
    },
    completeWizard: (state) => {
      state.siteInfo.isWizardCompleted = true;
    },
    // Add clearSiteData action to reset the state
    clearSiteData: (state) => {
      state.siteInfo = initialState.siteInfo; // Reset siteInfo to the initial state
      state.Loading = false;
      state.Error = undefined;
    },
    setRegisteredGateway: (state, action) => {
      state.siteInfo.registeredGatewayPublicId = action.payload;
    },
    removeRegisteredGateway: (state) => {
      state.siteInfo.registeredGatewayPublicId = null;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(aiphoneCloudApi.endpoints.getSiteWithPublicId.matchFulfilled, (state, { payload }) => {
      state.siteInfo = payload;
    });
  }
});

export const {
  addSite,
  updateSite,
  setSiteLoading,
  setIsWizardComplete,
  addAwsPropertyId,
  setSiteData,
  clearSiteData,
  setRegisteredGateway,
  removeRegisteredGateway
} = siteSlice.actions;

// Selectors
export const getSite = (state: RootState) => {
  return state.site;
};
export const getSitePublicId = (state: RootState) => state.site.siteInfo.publicId;
export const completeWizardSelector = (state: RootState) => state.site.siteInfo.isWizardCompleted;

// Reducer
export const siteReducer = siteSlice.reducer;
