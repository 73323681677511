import { useTheme } from '@mui/material';
import { GatewayStatus } from 'features/RemoteManagement/types/Types';
import { darken, lighten } from '@mui/system/colorManipulator';
import theme from './theme';

//const getColor = theme.palette.mode === 'light' ? darken : lighten;
const getBackgroundColor = theme.palette.mode === 'light' ? lighten : darken;

// Put styles that are used in multiple definitions here
const outlineFieldSelected = {
  backgroundColor: theme.palette.primary.light,
  '.MuiButtonBase-root': {
    color: theme.palette.primary.main
  }
};

const useSharedStyles = () => {
  const theme = useTheme();

  return {
    alert: {
      mb: 2,
      display: 'flex',
      alignItems: 'center',
      borderRadius: '8px'
    },
    error: {
      mb: 2
    },
    errorMessage: {
      color: 'red.main',
      fontSize: '0.75rem',
      margin: 0,

      fixedHeight: {
        height: '1rem'
      }
    },
    radioItem: {
      alignItems: 'flex-start',
      margin: '0rem 0.5rem',
      '.MuiRadio-root': {
        paddingTop: '0'
      }
    },
    inlineBox: {
      display: 'flex',
      alignItems: 'center',

      iconSecondary: {
        fontSize: '1.5rem',
        m: '0 0.25rem 0 0'
      },
      buttonIcon: {
        fontSize: '1.25rem',
        marginRight: '0.25rem',
        marginLeft: '-0.25rem'
      }
    },
    validatedFieldContainer: {
      minHeight: '5.6875rem',

      small: {
        minHeight: '4.25rem',
        paddingTop: '0.25rem'
      },

      subtext: {
        padding: '0 0 1rem 0 !important'
      }
    },
    flagField: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      flagStyle: {
        width: '33px',
        minWidth: '33px',
        height: '22px',
        paddingRight: '10px'
      },
      textStyle: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    },
    cardContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1.25rem',

      cardContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.75rem'
      }
    },
    dialogContainer: {
      fullWidthActionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 3
      },
      footerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        gap: 1
      }
    },
    advancedSettings: {
      panelContainer: {
        width: '100%',
        height: '90%',
        zIndex: 0
      }
    },
    // children classes
    toolbarContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',

      // class modifiers
      rightAligned: {
        justifyContent: 'flex-end'
      },
      padded: {
        paddingY: '0.75rem',
        paddingX: '1rem'
      },

      // children classes
      searchInput: {
        width: '25.625rem',
        paddingBottom: '0'
      }
    },
    chip: {
      height: 'auto',
      borderRadius: '6.25rem',
      padding: '0.25rem',
      textAlign: 'left',
      whiteSpace: 'nowrap',
      marginTop: '0.625rem'
    },
    gridContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 3,
      'Grid[item]': {
        width: '100%'
      },

      // class modifiers
      row: {
        flexDirection: 'row',

        //children classes
        fillWidthColumn: {
          flex: 1
        }
      },
      action: {
        display: 'flex',
        alignItems: 'flex-end',
        gap: '0.25rem'
      },
      centeredImgCell: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }
    },
    infoBox: {
      width: 'fit-content',
      maxWidth: '100%',
      border: '1px solid',
      borderRadius: '4px',
      borderColor: 'divider',
      padding: '1rem',

      // class modifiers
      form: {
        padding: 0,

        header: {
          backgroundColor: 'neutral.light',
          padding: '0.75rem'
        },
        content: {
          padding: '0.75rem'
        }
      }
    },
    flexContainer: {
      fillWithNoOverflow: {
        flex: 1,
        overflow: 'hidden'
      }
    },
    listBox: {
      backgroundColor: 'primary.hover',
      borderRadius: '4px',
      padding: '0.5rem',

      list: {
        paddingLeft: '0.5rem'
      },
      action: {
        alignSelf: 'flex-start',
        padding: '0 0.3125rem'
      }
    },

    // class modifiers
    downloadButton: {
      padding: '0.25rem 1rem',
      border: '1px solid',
      borderColor: 'divider',
      backgroundColor: 'neutral.semiLight',
      textTransform: 'none',

      '&:hover': {
        backgroundColor: 'neutral.light'
      },

      // children classes
      grid: {
        display: 'flex',
        flexDirection: 'row',
        gap: '0.5rem',
        textAlign: 'left',

        iconContainer: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: '-0.3125rem',

          '.MuiSvgIcon-root': {
            color: 'primary.main',
            fontSize: '1.5rem'
          }
        }
      }
    },
    imgThumbnail: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '4.6875rem',
      height: '4.6875rem',
      img: {
        maxWidth: '4.6875rem',
        maxHeight: '4.6875rem'
      },

      // class modifiers
      sm: {
        width: '2.1875rem',
        height: '2.1875rem',
        img: {
          maxWidth: '2.1875rem',
          maxHeight: '2.1875rem'
        }
      },
      md: {
        width: '2.5rem',
        height: '2.5rem',
        img: {
          maxWidth: '2.5rem',
          maxHeight: '2.5rem'
        }
      },
      lg: {
        width: '6.25rem',
        height: '6.25rem',
        img: {
          maxWidth: '6.25rem',
          maxHeight: '6.25rem'
        }
      }
    },
    outlinedField: {
      border: '1px solid',
      borderColor: 'divider',
      borderRadius: '4px',
      '&:hover': outlineFieldSelected,

      // class modifiers
      selected: outlineFieldSelected,
      success: {
        backgroundColor: getBackgroundColor(theme.palette.success.light, 0.9),
        '.MuiButtonBase-root': {
          color: theme.palette.success.main
        }
      }
    },
    imageLabelRadioGroup: {
      '.MuiFormControlLabel-label': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      },

      imageContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
      }
    },
    common: {
      showItem: { visibility: 'visible', opacity: 1 },
      hideItem: { visibility: 'hidden', opacity: 0 },
      divider: {
        top: {
          borderTop: '1px solid',
          borderColor: 'divider'
        },
        bottom: {
          borderBottom: '1px solid',
          borderColor: 'divider'
        }
      },
      alignItems: {
        center: { alignItems: 'center' },
        flexStart: { alignItems: 'flex-start' }
      },
      wrap: {
        noWrap: {
          flexWrap: 'nowrap',
          whiteSpace: 'nowrap'
        }
      },
      backgroundColor: {
        info: {
          backgroundColor: 'primary.hover'
        }
      },
      width: {
        full: { width: '100%' },
        fit: { width: 'fit-content' }
      },
      height: {
        full: { height: '100%' }
      },
      gap: {
        none: {
          gap: 0
        },
        xxs: {
          gap: 0.5
        },
        xs: {
          gap: 1
        },
        sm: {
          gap: 1.5
        },
        md: {
          gap: 2
        }
      },
      margin: {
        none: {
          margin: 0
        }
      },
      padding: {
        none: {
          padding: 0
        },
        xs: {
          padding: '0.5rem'
        },
        sm: {
          padding: '0.75rem'
        },
        md: {
          padding: '1rem'
        },
        x: {
          lg: {
            px: '1.5rem'
          }
        },
        y: {
          none: {
            py: 0
          },
          xs: {
            py: '0.5rem'
          },
          md: {
            py: '1rem'
          }
        },
        top: {
          xs: {
            paddingTop: '0.5rem'
          },
          md: {
            paddingTop: '1rem'
          },
          lg: {
            paddingTop: '1.5rem'
          }
        }
      },
      justifyContent: {
        spaceBetween: {
          justifyContent: 'space-between'
        },
        spaceAround: {
          justifyContent: 'space-around'
        },
        center: {
          justifyContent: 'center'
        }
      }
    },
    sideBar: {
      container: { width: '320px', minWidth: '320px' },
      menuItem: {
        button: {
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
            [`& .ps-menu-icon`]: {
              color: theme.palette.secondary.main
            }
          }
        },
        root: {
          '&.ps-active': {
            backgroundColor: theme.palette.primary.light,
            [`& .ps-menu-icon`]: {
              color: theme.palette.secondary.main
            }
          }
        },
        icon: {
          color: theme.palette.action.active
        }
      }
    },
    centeredFlex: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem'
    },
    extraLargeIcon: {
      fontSize: '50px',
      display: 'flex',
      alignItems: 'center'
    },
    iconContainerRoundBorder: {
      width: '2rem',
      height: '2rem',
      fontSize: '1.2rem',
      color: 'primary.main',
      border: '1px solid',
      borderRadius: '50%',
      borderColor: 'divider',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    flexCenterSmGap: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px'
    },
    deviceStatusBox: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      marginTop: '8px'
    },
    deviceStatusDescription: {
      display: 'flex',
      justifyContent: 'end',
      marginTop: '8px',
      gap: '8px'
    },
    gatewayStatusColors: {
      [GatewayStatus.ONLINE]: theme.palette.success.main,
      [GatewayStatus.OFFLINE]: theme.palette.error.main,
      [GatewayStatus.CHECKING]: theme.palette.warning.main,
      [GatewayStatus.UPDATING]: theme.palette.info.main,
      [GatewayStatus.UNREGISTERED]: theme.palette.info.main
    },
    spacingBottom: {
      mb: 2
    },
    spacingTop: {
      mt: 2
    },
    spacingTopBottom: {
      mt: 2,
      mb: 2
    },
    videoContainer: {
      position: 'relative',
      maxWidth: '1120px',
      height: '400px',
      leftPadding: '40px',
      rightPadding: '40px',

      thumbnail: {
        position: 'relative',
        width: '100%',
        height: '400px',
        borderRadius: '10px'
      },

      playButton: {
        position: 'absolute',
        bottom: '50%',
        left: '50%',
        borderRadius: '4px',
        transform: 'translateX(-50%)',
        backgroundColor: '#FFF',
        color: 'primary.main',
        border: '1px solid primary.main'
      }
    },
    icon: {
      color: theme.palette.primary.main,
      fontSize: '3rem'
    },
    mainContainer: {
      maxWidth: '1250px',
      padding: '20px'
    }
  };
};

export default useSharedStyles;
