// 1) Station Name width check (half-width = 1, full-width = 2)
export const MAX_WIDTH_UNITS = 24;
export function getTextWidthUnits(value: string): number {
  let length = 0;
  for (const char of value) {
    const codePoint = char.codePointAt(0) ?? 0;
    // Up to U+024F => 1; otherwise => 2
    if (codePoint <= 0x024f) {
      length += 1;
    } else {
      length += 2;
    }
  }
  return length;
}

// 2) IP (IPv4) - simplified
export const ipRegex = new RegExp('^(25[0-5]|2[0-4]\\d|[0-1]?\\d?\\d)' + '(\\.(25[0-5]|2[0-4]\\d|[0-1]?\\d?\\d)){3}$');

// 4) Extension number (up to 4 digits)
export const extensionRegex = /^\d{0,4}$/;
